var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FestivalLineupScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _FestivalEventItem = _interopRequireDefault(require("../helpers/FestivalEventItem"));

var _events = _interopRequireDefault(require("../../../core/events"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var FestivalLineupScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(FestivalLineupScreenInternal, _React$PureComponent);

  var _super = _createSuper(FestivalLineupScreenInternal);

  function FestivalLineupScreenInternal() {
    (0, _classCallCheck2.default)(this, FestivalLineupScreenInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(FestivalLineupScreenInternal, [{
    key: "render",
    value: function render() {
      return React.createElement(React.Fragment, null, this.props.events.length > 0 ? React.createElement(_reactNative.FlatList, {
        horizontal: false,
        directionalLockEnabled: true,
        pinchGestureEnabled: false,
        showsHorizontalScrollIndicator: false,
        contentContainerStyle: {
          flexGrow: 1,
          justifyContent: 'center',
          paddingHorizontal: 16
        },
        data: this.props.events,
        renderItem: function renderItem(_ref) {
          var item = _ref.item;
          return React.createElement(_FestivalEventItem.default, {
            eventId: item.get('id'),
            key: item.get('id')
          });
        },
        initialNumToRender: 5,
        removeClippedSubviews: true,
        keyExtractor: function keyExtractor(item, index) {
          return index.toString();
        }
      }) : React.createElement(_reactNative.Text, {
        style: {
          color: 'white',
          textAlign: 'center',
          fontWeight: 'bold'
        }
      }, "To be determined"));
    }
  }]);
  return FestivalLineupScreenInternal;
}(React.PureComponent);

exports.FestivalLineupScreenInternal = FestivalLineupScreenInternal;

function mapStateToProps(state, ownProps) {
  var id = ownProps.festivalId;
  return {
    events: _events.default.selectors.getFestivalLineup(state, id)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(FestivalLineupScreenInternal);

exports.default = _default;