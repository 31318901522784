var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = _interopRequireDefault(require("react"));

var _formik = require("formik");

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _Input = _interopRequireDefault(require("../../../../theme/components/inputs/Input"));

var Yup = _interopRequireWildcard(require("yup"));

var _reactFeather = require("react-feather");

var _baseSelect = _interopRequireDefault(require("../../../../theme/components/inputs/baseSelect.web"));

var _actions = require("../../../../core/polls/actions");

var _reactRedux = require("react-redux");

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _OutlinedButton = _interopRequireDefault(require("../../../../theme/components/buttons/OutlinedButton"));

var _RegExp = require("../../../../utils/RegExp");

var _pollsStyles = _interopRequireDefault(require("../polls-styles"));

var _reactNative = require("react-native");

var _selectors = require("../../../../core/polls/selectors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _ = require('lodash');

var NewPollContainer = function NewPollContainer(_ref) {
  var backToPollsList = _ref.backToPollsList,
      location = _ref.location;
  var Styles = (0, _pollsStyles.default)();
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectPollsIsLoading);
  var dispatch = (0, _reactRedux.useDispatch)();

  function onChangeOptions(e, values, setValues) {
    var options = (0, _toConsumableArray2.default)(values.options);
    var numberOfOptions = parseInt(e);
    var previousNumberOfOptions = values.numberOfOptions;

    if (previousNumberOfOptions < numberOfOptions) {
      for (var i = previousNumberOfOptions; i < numberOfOptions; i++) {
        options.push('');
      }
    } else {
      for (var _i = previousNumberOfOptions; _i >= numberOfOptions; _i--) {
        options.splice(_i, 1);
      }
    }

    setValues(_objectSpread(_objectSpread({}, values), {}, {
      options: options,
      numberOfOptions: numberOfOptions
    }));
  }

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 'l', 'l', 'l'],
    justifyContent: "center"
  }, _react.default.createElement(_OutlinedButton.default, {
    style: Styles.borderlessOutlineButton,
    color: "alternativeText",
    onPress: backToPollsList,
    spacing: 2,
    iconLeft: _react.default.createElement(_reactFeather.ArrowLeftCircle, null)
  }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('polls.backToPollsList')))), _react.default.createElement(_formik.Formik, {
    initialValues: {
      title: '',
      numberOfOptions: 2,
      options: ['', '']
    },
    onSubmit: function onSubmit(values) {
      dispatch((0, _actions.savePoll)({
        pollData: {
          title: values.title,
          options: values.options
        },
        location: location
      })).then(function () {
        backToPollsList();
      }).catch(function (error) {
        console.log(error);
      });
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().min(2, _i18n.default.t('polls.new.titleMin2')).max(150, _i18n.default.t('polls.new.titleMax150')).matches(_RegExp.pollTitleRegExp, _i18n.default.t('polls.new.invalidFormatTitle')).required(_i18n.default.t('polls.new.titleRequired')),
      numberOfOptions: Yup.string().required(_i18n.default.t('polls.new.numberOfOptionsRequired')),
      options: Yup.array().of(Yup.string().trim().min(2, _i18n.default.t('polls.new.minOptionLength2')).max(100, _i18n.default.t('polls.new.maxOptionLength100')).matches(_RegExp.pollOptionRegExp, _i18n.default.t('polls.new.invalidFormatOption')).required(_i18n.default.t('polls.new.minOptionLength2'))).test('options', _i18n.default.t('polls.new.containsDuplicates'), function (value) {
        return _.uniq(value).length === value.length;
      }).min(2, _i18n.default.t('polls.new.minOptions2')).max(10, _i18n.default.t('polls.new.maxOptions10'))
    })
  }, function (_ref2) {
    var values = _ref2.values,
        setValues = _ref2.setValues,
        handleChange = _ref2.handleChange,
        errors = _ref2.errors,
        setFieldTouched = _ref2.setFieldTouched,
        touched = _ref2.touched,
        isValid = _ref2.isValid,
        submitForm = _ref2.submitForm,
        isSubmitting = _ref2.isSubmitting;
    return _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'm', 0],
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'base', 0]
    }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('polls.new.title'))), _react.default.createElement(_Input.default, {
      shaped: true,
      color: "alternativeText",
      type: "text",
      value: values.title,
      onChange: handleChange,
      name: "title",
      error: !!errors.title && touched.title,
      onBlur: function onBlur() {
        return setFieldTouched('title');
      },
      placeholder: _i18n.default.translate('polls.new.titlePlaceholder')
    }), !!errors.title && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_core.Typography, {
      variant: "caption",
      color: "error"
    }, errors.title))), _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'm', 0],
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'base', 0]
    }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('polls.new.numberOfOptions'))), _react.default.createElement(_baseSelect.default, {
      rightIcon: _react.default.createElement(_reactFeather.ChevronDown, null),
      options: [2, 3, 4, 5, 6, 7, 8, 9, 10].map(function (i) {
        return {
          id: i,
          value: i
        };
      }),
      value: values.numberOfOptions,
      onChange: function onChange(e) {
        return onChangeOptions(e, values, setValues);
      },
      name: "numberOfOptions",
      hasEmptyOption: false
    })), values.options.map(function (op, i) {
      return _react.default.createElement(_Spacing.default, {
        key: "option-".concat(i),
        mSpacing: [0, 0, 'm', 0],
        pSpacing: "s",
        shaping: "cards",
        direction: "column",
        alignItems: "stretch",
        style: Styles.optionContainer
      }, _react.default.createElement(_Spacing.default, {
        mSpacing: [0, 0, 'base', 0]
      }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('polls.new.op'), " ", i + 1)), _react.default.createElement(_Input.default, {
        shaped: true,
        color: "alternativeText",
        type: "text",
        value: op,
        onChange: handleChange,
        name: "options.".concat(i),
        error: !!errors.options && !!errors.options[i],
        onBlur: function onBlur() {
          return setFieldTouched("options.".concat(i));
        },
        placeholder: "".concat(_i18n.default.translate('polls.new.op'), " ").concat(i + 1)
      }), !!errors.options && typeof errors.options === 'object' && !!errors.options[i] && _react.default.createElement(_Spacing.default, {
        mSpacing: "base"
      }, _react.default.createElement(_core.Typography, {
        variant: "caption",
        color: "error"
      }, typeof errors.options === 'string' ? errors.options : errors.options[i])));
    }), !!errors.options && typeof errors.options === 'string' && _react.default.createElement(_Spacing.default, {
      mSpacing: "base"
    }, _react.default.createElement(_core.Typography, {
      variant: "caption",
      color: "error"
    }, errors.options)), _react.default.createElement(_ContainedButton.default, {
      disabled: isLoading || isSubmitting,
      onPress: function onPress() {
        if (isValid) {
          submitForm();
        }
      }
    }, isLoading || isSubmitting ? _react.default.createElement(_reactNative.ActivityIndicator, {
      size: "small",
      color: "#fff"
    }) : _i18n.default.t('polls.new.save')));
  }));
};

var _default = NewPollContainer;
exports.default = _default;