var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var ScrollListener = function () {
  (0, _createClass2.default)(ScrollListener, [{
    key: "onScroll",
    value: function onScroll(y, lastSelected) {
      var currentSection = null;
      Object.entries(this.sectionMap).sort(function (_ref, _ref2) {
        var _ref3 = (0, _slicedToArray2.default)(_ref, 2),
            _ = _ref3[0],
            aValue = _ref3[1];

        var _ref4 = (0, _slicedToArray2.default)(_ref2, 2),
            __ = _ref4[0],
            bValue = _ref4[1];

        return aValue.y - bValue.y;
      }).map(function (_ref5) {
        var _ref6 = (0, _slicedToArray2.default)(_ref5, 2),
            key = _ref6[0],
            section = _ref6[1];

        if (y >= section.y) {
          currentSection = key;
        }
      });

      if (lastSelected) {
        currentSection = Object.values(this.sectionMap).sort(function (_ref7, _ref8) {
          var aY = _ref7.y;
          var bY = _ref8.y;
          return aY - bY;
        }).pop().key;
      }

      this._sections.next({
        sectionMap: this.sectionMap,
        currentSection: currentSection,
        scrollTo: this.scrollTo
      });
    }
  }, {
    key: "onSectionLayout",
    value: function onSectionLayout(_ref9) {
      var _this = this;

      var y = _ref9.y,
          key = _ref9.key,
          index = _ref9.index,
          title = _ref9.title,
          type = _ref9.type;
      this.sectionMap = _objectSpread(_objectSpread({}, this.sectionMap), {}, (0, _defineProperty2.default)({}, key, {
        key: key,
        index: index,
        title: title,
        type: type,
        y: y,
        scrollTo: function scrollTo() {
          return _this.scrollTo({
            y: y - _this.offset,
            x: 0,
            animated: true
          });
        }
      }));

      this._sections.next({
        sectionMap: this.sectionMap,
        currentSection: String(index),
        scrollTo: this.scrollTo
      });
    }
  }, {
    key: "setScrollTo",
    value: function setScrollTo(scrollTo) {
      this.scrollTo = scrollTo;
    }
  }, {
    key: "onContainerLayout",
    value: function onContainerLayout(y, offset) {
      this.offset = offset;
      var currentSection = null;
      Object.entries(this.sectionMap).sort(function (a, b) {
        return a.y - b.y;
      }).map(function (section) {
        if (y >= section.y) {
          currentSection = section.key;
        }
      });

      this._sections.next({
        sectionMap: this.sectionMap,
        currentSection: currentSection,
        scrollTo: this.scrollTo
      });
    }
  }]);

  function ScrollListener() {
    (0, _classCallCheck2.default)(this, ScrollListener);
    this._sections = null;
    this.sections$ = null;
    this.sectionMap = {};
    this.offset = 0;
    this.scrollTo = void 0;
    this._sections = new _rxjs.BehaviorSubject({
      sectionMap: {},
      currentSection: ''
    });
    this.sections$ = this._sections.asObservable().pipe((0, _operators.distinctUntilChanged)(function (prev, current) {
      return prev.currentSection === current.currentSection;
    }));
  }

  (0, _createClass2.default)(ScrollListener, null, [{
    key: "getInstance",
    value: function getInstance() {
      if (!ScrollListener.instance) {
        ScrollListener.instance = new ScrollListener();
      }

      return ScrollListener.instance;
    }
  }]);
  return ScrollListener;
}();

ScrollListener.instance = null;
var instance = ScrollListener.getInstance();
var _default = instance;
exports.default = _default;