var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Booth;

var _react = _interopRequireDefault(require("react"));

var _reactNativeSvg = _interopRequireWildcard(require("react-native-svg"));

function Booth(_ref) {
  var _ref$height = _ref.height,
      height = _ref$height === void 0 ? 28 : _ref$height,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? 28 : _ref$width;
  return _react.default.createElement(_reactNativeSvg.default, {
    width: width,
    height: height,
    viewBox: "0 0 18 16",
    enableBackground: "new 0 0 18 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, _react.default.createElement(_reactNativeSvg.G, null, _react.default.createElement(_reactNativeSvg.Path, {
    d: "M14 15.125H4C3.50272 15.125 3.02581 14.9275 2.67417 14.5758C2.32254 14.2242 2.125 13.7473 2.125 13.25V7C2.125 6.83424 2.19085 6.67527 2.30806 6.55806C2.42527 6.44085 2.58424 6.375 2.75 6.375C2.91576 6.375 3.07473 6.44085 3.19194 6.55806C3.30915 6.67527 3.375 6.83424 3.375 7V13.25C3.375 13.4158 3.44085 13.5747 3.55806 13.6919C3.67527 13.8092 3.83424 13.875 4 13.875H14C14.1658 13.875 14.3247 13.8092 14.4419 13.6919C14.5592 13.5747 14.625 13.4158 14.625 13.25V7C14.625 6.83424 14.6908 6.67527 14.8081 6.55806C14.9253 6.44085 15.0842 6.375 15.25 6.375C15.4158 6.375 15.5747 6.44085 15.6919 6.55806C15.8092 6.67527 15.875 6.83424 15.875 7V13.25C15.875 13.7473 15.6775 14.2242 15.3258 14.5758C14.9742 14.9275 14.4973 15.125 14 15.125Z",
    fill: "currentColor"
  }), _react.default.createElement(_reactNativeSvg.Path, {
    d: "M8.375 15.125H5.25C5.08424 15.125 4.92527 15.0592 4.80806 14.9419C4.69085 14.8247 4.625 14.6658 4.625 14.5V10.75C4.625 10.2527 4.82254 9.77581 5.17417 9.42417C5.52581 9.07254 6.00272 8.875 6.5 8.875H7.125C7.62228 8.875 8.09919 9.07254 8.45083 9.42417C8.80246 9.77581 9 10.2527 9 10.75V14.5C9 14.6658 8.93415 14.8247 8.81694 14.9419C8.69973 15.0592 8.54076 15.125 8.375 15.125ZM5.875 13.875H7.75V10.75C7.75 10.5842 7.68415 10.4253 7.56694 10.3081C7.44973 10.1908 7.29076 10.125 7.125 10.125H6.5C6.33424 10.125 6.17527 10.1908 6.05806 10.3081C5.94085 10.4253 5.875 10.5842 5.875 10.75V13.875Z",
    fill: "currentColor"
  }), _react.default.createElement(_reactNativeSvg.Path, {
    d: "M14.625 7.625C13.962 7.625 13.3261 7.36161 12.8572 6.89277C12.3884 6.42393 12.125 5.78804 12.125 5.125C12.125 4.95924 12.1908 4.80027 12.3081 4.68306C12.4253 4.56585 12.5842 4.5 12.75 4.5C12.9158 4.5 13.0747 4.56585 13.1919 4.68306C13.3092 4.80027 13.375 4.95924 13.375 5.125C13.375 5.45652 13.5067 5.77446 13.7411 6.00888C13.9755 6.2433 14.2935 6.375 14.625 6.375C14.9565 6.375 15.2745 6.2433 15.5089 6.00888C15.7433 5.77446 15.875 5.45652 15.875 5.125V4.65L14.2375 1.375H3.7625L2.125 4.65V5.125C2.125 5.45652 2.2567 5.77446 2.49112 6.00888C2.72554 6.2433 3.04348 6.375 3.375 6.375C3.70652 6.375 4.02446 6.2433 4.25888 6.00888C4.4933 5.77446 4.625 5.45652 4.625 5.125C4.625 4.95924 4.69085 4.80027 4.80806 4.68306C4.92527 4.56585 5.08424 4.5 5.25 4.5C5.41576 4.5 5.57473 4.56585 5.69194 4.68306C5.80915 4.80027 5.875 4.95924 5.875 5.125C5.875 5.78804 5.61161 6.42393 5.14277 6.89277C4.67393 7.36161 4.03804 7.625 3.375 7.625C2.71196 7.625 2.07607 7.36161 1.60723 6.89277C1.13839 6.42393 0.875 5.78804 0.875 5.125V4.5C0.875587 4.40216 0.899135 4.30583 0.94375 4.21875L2.81875 0.46875C2.87069 0.365679 2.95015 0.279002 3.04833 0.218327C3.14652 0.157652 3.25958 0.12535 3.375 0.125H14.625C14.7404 0.12535 14.8535 0.157652 14.9517 0.218327C15.0498 0.279002 15.1293 0.365679 15.1812 0.46875L17.0562 4.21875C17.1009 4.30583 17.1244 4.40216 17.125 4.5V5.125C17.125 5.78804 16.8616 6.42393 16.3928 6.89277C15.9239 7.36161 15.288 7.625 14.625 7.625Z",
    fill: "currentColor"
  }), _react.default.createElement(_reactNativeSvg.Path, {
    d: "M7.125 7.625C6.46196 7.625 5.82607 7.36161 5.35723 6.89277C4.88839 6.42393 4.625 5.78804 4.625 5.125C4.625 4.95924 4.69085 4.80027 4.80806 4.68306C4.92527 4.56585 5.08424 4.5 5.25 4.5C5.41576 4.5 5.57473 4.56585 5.69194 4.68306C5.80915 4.80027 5.875 4.95924 5.875 5.125C5.875 5.45652 6.0067 5.77446 6.24112 6.00888C6.47554 6.2433 6.79348 6.375 7.125 6.375C7.45652 6.375 7.77446 6.2433 8.00888 6.00888C8.2433 5.77446 8.375 5.45652 8.375 5.125C8.375 4.95924 8.44085 4.80027 8.55806 4.68306C8.67527 4.56585 8.83424 4.5 9 4.5C9.16576 4.5 9.32473 4.56585 9.44194 4.68306C9.55915 4.80027 9.625 4.95924 9.625 5.125C9.625 5.78804 9.36161 6.42393 8.89277 6.89277C8.42393 7.36161 7.78804 7.625 7.125 7.625Z",
    fill: "currentColor"
  }), _react.default.createElement(_reactNativeSvg.Path, {
    d: "M10.875 7.625C10.212 7.625 9.57607 7.36161 9.10723 6.89277C8.63839 6.42393 8.375 5.78804 8.375 5.125C8.375 4.95924 8.44085 4.80027 8.55806 4.68306C8.67527 4.56585 8.83424 4.5 9 4.5C9.16576 4.5 9.32473 4.56585 9.44194 4.68306C9.55915 4.80027 9.625 4.95924 9.625 5.125C9.625 5.45652 9.7567 5.77446 9.99112 6.00888C10.2255 6.2433 10.5435 6.375 10.875 6.375C11.2065 6.375 11.5245 6.2433 11.7589 6.00888C11.9933 5.77446 12.125 5.45652 12.125 5.125C12.125 4.95924 12.1908 4.80027 12.3081 4.68306C12.4253 4.56585 12.5842 4.5 12.75 4.5C12.9158 4.5 13.0747 4.56585 13.1919 4.68306C13.3092 4.80027 13.375 4.95924 13.375 5.125C13.375 5.78804 13.1116 6.42393 12.6428 6.89277C12.1739 7.36161 11.538 7.625 10.875 7.625Z",
    fill: "currentColor"
  }), _react.default.createElement(_reactNativeSvg.Path, {
    d: "M12.75 10.75H10.875C10.7092 10.75 10.5503 10.6842 10.4331 10.5669C10.3158 10.4497 10.25 10.2908 10.25 10.125C10.25 9.95924 10.3158 9.80027 10.4331 9.68306C10.5503 9.56585 10.7092 9.5 10.875 9.5H12.75C12.9158 9.5 13.0747 9.56585 13.1919 9.68306C13.3092 9.80027 13.375 9.95924 13.375 10.125C13.375 10.2908 13.3092 10.4497 13.1919 10.5669C13.0747 10.6842 12.9158 10.75 12.75 10.75Z",
    fill: "currentColor"
  })));
}