var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Question;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _core = require("@material-ui/core");

var _reactFeather = require("react-feather");

var _reactRedux = require("react-redux");

var _UserSection = _interopRequireDefault(require("./UserSection"));

var _actions = require("../../../../core/QAndA/actions");

var _selectors = require("../../../../core/user/selectors");

var _selectors2 = require("../../../../core/event-info/selectors");

var _selectors3 = require("../../../../core/team/selectors");

var _ReactionsSection = _interopRequireDefault(require("./ReactionsSection"));

var _ResponsesList = _interopRequireDefault(require("./ResponsesList"));

var _i18nJs = _interopRequireDefault(require("i18n-js"));

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    mainQuestionContainer: {
      width: '90%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      border: "1px solid ".concat(theme.palette.borderColor.main),
      borderRadius: 5
    },
    questionContainer: {
      width: '100%',
      height: '100%'
    },
    userSectionContainer: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    questionBox: {
      backgroundColor: theme.palette.buttonText.main,
      borderRadius: 5,
      textAlign: 'start'
    },
    repliesContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around'
    },
    reply: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.pageText.main,
      cursor: 'pointer',
      '& span': {
        marginLeft: 5
      }
    }
  };
});

function Question(_ref) {
  var question = _ref.question;
  var dispatch = (0, _reactRedux.useDispatch)();
  var userId = (0, _reactRedux.useSelector)(_selectors.getFirebaseUserUid);

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      showAnswers = _useState2[0],
      setShowAnswers = _useState2[1];

  var createdBy = (0, _reactRedux.useSelector)(_selectors2.eventCreatorIdSelector);
  var adminAccess = (0, _reactRedux.useSelector)(_selectors3.isStaffMember);
  var isAdmin = createdBy === userId || adminAccess;

  var handlePin = function handlePin() {
    dispatch((0, _actions.togglePinQuestion)({
      questionId: question.id,
      pinState: question.isPinned
    }));
  };

  var handleDelete = function handleDelete() {
    dispatch((0, _actions.deleteQuestion)(question.id));
  };

  var handleAnswer = function handleAnswer(message) {
    dispatch((0, _actions.answerQuestion)({
      questionId: question.id,
      message: message,
      userId: userId
    }));
  };

  var handleToggleShowReplies = function handleToggleShowReplies() {
    setShowAnswers(function (prevState) {
      return !prevState;
    });
  };

  var classes = useStyles();
  return _react.default.createElement(_core.Box, {
    className: classes.mainQuestionContainer,
    my: 2
  }, _react.default.createElement(_core.Box, {
    className: classes.userSectionContainer,
    px: 1,
    mt: 2
  }, _react.default.createElement(_ReactionsSection.default, {
    question: question
  }), _react.default.createElement(_core.Box, {
    className: classes.questionContainer
  }, _react.default.createElement(_UserSection.default, {
    createdByAdmin: createdBy === question.createdBy,
    isQuestion: true,
    question: question,
    userInfo: question.userInfo,
    isAdmin: isAdmin,
    onPin: handlePin,
    onDelete: handleDelete,
    isPinned: question.isPinned
  }), _react.default.createElement(_core.Box, {
    className: classes.questionBox,
    pt: 1,
    px: 1
  }, _react.default.createElement(_core.Typography, {
    variant: "h4"
  }, question.message)))), _react.default.createElement(_core.Box, {
    my: 2
  }, _react.default.createElement(_core.Divider, null)), _react.default.createElement(_core.Box, {
    className: classes.repliesContainer,
    px: 1,
    mb: 2
  }, _react.default.createElement(_core.Box, {
    className: classes.reply,
    onClick: handleToggleShowReplies
  }, _react.default.createElement(_reactFeather.MessageSquare, {
    size: 20
  }), _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, _i18nJs.default.t('qa.reply'))), _react.default.createElement(_core.Box, {
    className: classes.reply,
    onClick: handleToggleShowReplies
  }, _react.default.createElement(_reactFeather.Triangle, {
    size: 20,
    style: {
      transform: "rotate(".concat(showAnswers ? '180' : '90', "deg)"),
      transitionDuration: '0.2s'
    }
  }), _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, question.answers.length, " ", _i18nJs.default.t('qa.replies')))), showAnswers && _react.default.createElement(_ResponsesList.default, {
    question: question,
    isAdmin: isAdmin,
    onAnswer: handleAnswer
  }));
}