var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BackstageLive;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _useAccessToken3 = _interopRequireDefault(require("../../../../utils/hooks/useAccessToken"));

var _useClient = _interopRequireDefault(require("../../../../utils/hooks/useClient"));

var _useClientAutoConnect = _interopRequireDefault(require("../../../../utils/hooks/useClientAutoConnect"));

var _SubscriptionsByRoom = _interopRequireDefault(require("../../../common/audio-video/SubscriptionsByRoom"));

var _RegisterParticipantAudioInStream = _interopRequireDefault(require("../../../common/audio-video/RegisterParticipantAudioInStream"));

var _ParticipantsCanvasStream = _interopRequireDefault(require("../../../common/audio-video/canvas/ParticipantsCanvasStream"));

var _useParticipants = require("../../../../utils/hooks/useParticipants");

var _AudioVideoProvider = require("../../../common/audio-video/AudioVideoProvider");

var _selectors = require("../../../../core/user-rooms/selectors");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _CanvasLiveIndicator = _interopRequireDefault(require("../../../common/audio-video/canvas/CanvasLiveIndicator"));

var _CanvasViewersCount = _interopRequireDefault(require("../../../common/audio-video/canvas/CanvasViewersCount"));

var _CanvasElapsedTime = _interopRequireDefault(require("../../../common/audio-video/canvas/CanvasElapsedTime"));

var _IsPlayerSupported = _interopRequireDefault(require("./IsPlayerSupported"));

var _actions = require("../../../../core/user-rooms/actions");

var _agoraConstants = require("../../../../constants/agora-constants");

function BackstageLive(_ref) {
  var room = _ref.room,
      _children = _ref.children,
      eventId = _ref.eventId;
  var client = (0, _useClient.default)();

  var _useAccessToken = (0, _useAccessToken3.default)(room, client),
      _useAccessToken2 = (0, _slicedToArray2.default)(_useAccessToken, 2),
      accessToken = _useAccessToken2[0],
      uid = _useAccessToken2[1];

  (0, _useClientAutoConnect.default)(client, uid, accessToken, room);

  var stream = _react.default.useMemo(function () {
    return new MediaStream();
  }, []);

  var audioContext = _react.default.useMemo(function () {
    return new AudioContext();
  }, []);

  var audioDestinationStream = _react.default.useMemo(function () {
    return audioContext.createMediaStreamDestination();
  }, [audioContext]);

  var dispatch = (0, _reactRedux.useDispatch)();
  var participants = (0, _useParticipants.useParticipants)(client);
  var stageParticipants = (0, _reactRedux.useSelector)((0, _selectors.liveParticipantsSelector)(room));
  var roomParticipantIds = Object.keys(stageParticipants).map(Number);
  var isLive = (0, _reactRedux.useSelector)((0, _selectors.isRoomLiveStartedSelector)(room));
  var countdown = (0, _reactRedux.useSelector)((0, _selectors.startLiveCountDownSelector)(room));
  var audienceCount = (0, _reactRedux.useSelector)((0, _selectors.audienceCountSelector)(room));
  var startedAt = (0, _reactRedux.useSelector)((0, _selectors.liveStartedAtSelector)(room));
  var screenSharer = (0, _reactRedux.useSelector)((0, _selectors.roomScreenSharerSelector)(room));

  _react.default.useEffect(function () {
    dispatch((0, _actions.watchRoom)({
      path: "events/".concat(eventId, "/backstages/").concat(room),
      roomId: room,
      type: _agoraConstants.TYPES.BACKSTAGE,
      event: _agoraConstants.WATCH_EVENT
    }));
    return function () {
      dispatch((0, _actions.unwatchRoom)(room));
    };
  }, [dispatch, eventId, room]);

  _react.default.useEffect(function () {
    if (audioContext.state === 'suspended') {
      audioContext.resume();
    }

    var interval = setInterval(function () {
      if (audioContext.state === 'suspended') {
        audioContext.resume();
      }
    }, 2500);
    return function () {
      clearInterval(interval);
    };
  }, [audioContext]);

  _react.default.useEffect(function () {
    return stream.addTrack(audioDestinationStream.stream.getTracks()[0]);
  }, [stream, audioDestinationStream]);

  var streamStarted = isLive && roomParticipantIds.length;
  var screenShareParticipant = participants.get(screenSharer.screenShareId);
  return _react.default.createElement(_IsPlayerSupported.default, null, _children(stream, streamStarted), _react.default.createElement(_AudioVideoProvider.AudioVideoContext.Provider, {
    value: {
      client: client
    }
  }, _react.default.createElement(_SubscriptionsByRoom.default, {
    screenSharer: screenSharer,
    roomParticipantIds: roomParticipantIds,
    shouldSubscribe: isLive
  })), roomParticipantIds.map(function (id) {
    var _participants$get;

    return _react.default.createElement(_RegisterParticipantAudioInStream.default, {
      key: id,
      audioTrack: (_participants$get = participants.get(id)) === null || _participants$get === void 0 ? void 0 : _participants$get.audioTrack,
      context: audioContext,
      destinationStream: audioDestinationStream
    });
  }), (screenShareParticipant === null || screenShareParticipant === void 0 ? void 0 : screenShareParticipant.audioTrack) && _react.default.createElement(_RegisterParticipantAudioInStream.default, {
    key: screenSharer.id,
    audioTrack: screenShareParticipant === null || screenShareParticipant === void 0 ? void 0 : screenShareParticipant.audioTrack,
    context: audioContext,
    destinationStream: audioDestinationStream
  }), _react.default.createElement(_ParticipantsCanvasStream.default, {
    webRtcParticipants: participants,
    stream: stream,
    screenSharer: screenSharer,
    participantsInfo: isLive ? stageParticipants : {},
    customMessage: countdown ? countdown === 'Start' ? String(countdown).toUpperCase() : "".concat(_i18n.default.translate('backstage.sessionStarting'), "\n\n").concat(countdown) : undefined,
    extraGraphics: isLive && _react.default.createElement(_CanvasLiveIndicator.default, {
      key: "live-indicator",
      children: function children(x) {
        return _react.default.createElement(_CanvasViewersCount.default, {
          count: audienceCount,
          x: x,
          children: function children(xx) {
            if (!startedAt) {
              return null;
            }

            return _react.default.createElement(_CanvasElapsedTime.default, {
              startDate: startedAt,
              x: xx + 12
            });
          }
        });
      }
    })
  }));
}