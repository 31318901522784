var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var ColoredIconButton = (0, _core.withStyles)({
  disabled: {
    backgroundColor: function backgroundColor(_ref) {
      var customColor = _ref.customColor;
      return "".concat(customColor, "66 !important");
    }
  },
  root: {
    backgroundColor: function backgroundColor(_ref2) {
      var customColor = _ref2.customColor;
      return customColor;
    },
    '&:hover': {
      backgroundColor: function backgroundColor(_ref3) {
        var customColor = _ref3.customColor;
        return "".concat(customColor, "88 !important");
      }
    }
  }
})(function (_ref4) {
  var customColor = _ref4.customColor,
      props = (0, _objectWithoutProperties2.default)(_ref4, ["customColor"]);
  return _react.default.createElement(_core.IconButton, props);
});
var _default = ColoredIconButton;
exports.default = _default;