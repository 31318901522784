var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CustomModal;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _styles = _interopRequireDefault(require("./styles"));

function CustomModal(_ref) {
  var children = _ref.children,
      onClose = _ref.onClose;
  var classes = (0, _styles.default)();
  return _react.default.createElement(_core.Modal, {
    className: classes.modal,
    open: true,
    onClose: onClose
  }, children);
}