var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactNavigationHooks = require("react-navigation-hooks");

var _routesConsts = require("../../navigation/client/routes-consts");

var _queryParameter = require("../queryParameter");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useNavigationExtended = function useNavigationExtended() {
  var _eventId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.EVENT_ID);

  var eventId = _eventId || (0, _queryParameter.getEventIdParam)(window.location.href);

  var navigation = (0, _reactNavigationHooks.useNavigation)();
  return _objectSpread(_objectSpread({}, navigation), {}, {
    navigate: eventId ? function (routeName, params) {
      return navigation.navigate(routeName, _objectSpread({
        eventId: eventId
      }, params));
    } : undefined
  });
};

var _default = useNavigationExtended;
exports.default = _default;