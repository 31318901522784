var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RightSideMenuContainer;
exports.MenuBuilder = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _OutlinedButton = _interopRequireDefault(require("../../../theme/components/buttons/OutlinedButton"));

var _Divider = _interopRequireDefault(require("../../../theme/components/divider/Divider"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _rightSideMenuStyles = _interopRequireDefault(require("./right-side-menu-styles"));

var _toaster = _interopRequireDefault(require("../../common/toaster/toaster"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _selectors = require("../../../core/event-info/selectors");

var _actions = require("../../../core/QAndA/actions");

var _selectors2 = require("../../../core/user/selectors");

var _i18nJs = _interopRequireDefault(require("i18n-js"));

var _core = require("@material-ui/core");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function RightSideMenuContainer(_ref) {
  var items = _ref.items,
      contents = _ref.contents,
      _ref$backstage = _ref.backstage,
      backstage = _ref$backstage === void 0 ? false : _ref$backstage;

  var _React$useState = _react.default.useState(0),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      selected = _React$useState2[0],
      setSelected = _React$useState2[1];

  var _React$useState3 = _react.default.useState(false),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      collapsed = _React$useState4[0],
      setCollapsed = _React$useState4[1];

  var Styles = (0, _rightSideMenuStyles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      showToaster = _useState2[0],
      setShowToaster = _useState2[1];

  var userId = (0, _reactRedux.useSelector)(_selectors2.getFirebaseUserUid);
  var match = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.bg;
  });
  var eventId = (0, _reactRedux.useSelector)(_selectors.selectEventInfoId);
  var dispatch = (0, _reactRedux.useDispatch)();
  (0, _react.useEffect)(function () {
    var unsubscribe = _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/questionsAndAnswers")).onSnapshot(function (querySnapshot) {
      var questions = [];
      var newQuestion = querySnapshot.docChanges().some(function (change) {
        return change.type === 'added' && change.doc.data().createdBy !== userId;
      });

      if (newQuestion) {
        setShowToaster(true);
      }

      querySnapshot.forEach(function (doc) {
        questions.push(_objectSpread(_objectSpread({}, doc.data()), {}, {
          id: doc.id
        }));
      });
      dispatch((0, _actions.setQuestions)(questions));
    });

    return function () {
      unsubscribe();
    };
  }, [dispatch, setShowToaster]);
  return _react.default.createElement(_core.Box, {
    my: 1
  }, _react.default.createElement(_Spacing.default, {
    color: "containerBackground",
    shaping: "cards",
    style: [Styles.container, match ? {} : {
      width: collapsed ? spacing.rightSidebarMenu : spacing.rightSidebar
    }, match ? {} : {}],
    direction: "row",
    wrap: "nowrap",
    alignItems: "stretch",
    justifyContent: "flex-start"
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.itemContainer,
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.collapse,
    mSpacing: "s"
  }, _react.default.createElement(_OutlinedButton.default, {
    color: "alternativeText",
    onPress: function onPress() {
      return setCollapsed(function (old) {
        return !old;
      });
    },
    spacing: 3,
    iconLeft: collapsed ? _react.default.createElement(_reactFeather.ArrowLeft, {
      size: "0.875rem"
    }) : _react.default.createElement(_reactFeather.ArrowRight, {
      size: "0.875rem"
    })
  })), _react.default.createElement(_reactNative.ScrollView, {
    horizontal: match,
    showsVerticalScrollIndicator: match
  }, items.map(function (item, i) {
    return _react.default.createElement(_Spacing.default, {
      key: i,
      style: Styles.menuItem
    }, item({
      isSelected: selected === i,
      onSelect: function onSelect() {
        return selected !== i && setSelected(i);
      }
    }));
  }))), _react.default.createElement(_toaster.default, {
    visible: showToaster,
    onClose: function onClose() {
      return setShowToaster(false);
    },
    text: _i18nJs.default.t('qa.newQuestion'),
    severity: "success"
  }), (!collapsed || match) && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Divider.default, {
    spacing: 0,
    width: 1,
    direction: "vertical"
  }), _react.default.createElement(_Spacing.default, {
    style: Styles.content,
    flex: 1,
    direction: "column",
    alignItems: "stretch"
  }, contents[selected] || null))));
}

var MenuBuilder = function () {
  function MenuBuilder() {
    (0, _classCallCheck2.default)(this, MenuBuilder);
    this.menuItems = new Map();
    this.contents = new Map();
  }

  (0, _createClass2.default)(MenuBuilder, [{
    key: "add",
    value: function add(type, key, content) {
      if (type === MenuBuilder.TYPES.CONTENT) {
        this.contents.set(key, content);
      }

      if (type === MenuBuilder.TYPES.MENU_ITEM) {
        this.menuItems.set(key, content);
      }

      return this;
    }
  }, {
    key: "buildClientMenu",
    value: function buildClientMenu(_ref3) {
      var chatDisabled = _ref3.chatDisabled,
          peopleDisabled = _ref3.peopleDisabled,
          watchMode = _ref3.watchMode;
      this.menuItems.delete(MenuBuilder.MENUS.BLOCKED_PARTICIPANTS);
      this.contents.delete(MenuBuilder.MENUS.BLOCKED_PARTICIPANTS);

      if (chatDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
        this.contents.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
        this.menuItems.delete(MenuBuilder.MENUS.BLOCKED_PARTICIPANTS);
        this.contents.delete(MenuBuilder.MENUS.BLOCKED_PARTICIPANTS);
      }

      if (peopleDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
        this.contents.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
      }

      if (watchMode) {
        this.menuItems.delete(MenuBuilder.MENUS.CHAT);
        this.contents.delete(MenuBuilder.MENUS.CHAT);
      }

      return _react.default.createElement(RightSideMenuContainer, {
        items: Array.from(this.menuItems.values()),
        contents: Array.from(this.contents.values())
      });
    }
  }, {
    key: "buildAdminMenu",
    value: function buildAdminMenu(_ref4) {
      var chatDisabled = _ref4.chatDisabled,
          peopleDisabled = _ref4.peopleDisabled,
          watchMode = _ref4.watchMode;

      if (chatDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
        this.contents.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
        this.menuItems.delete(MenuBuilder.MENUS.BLOCKED_PARTICIPANTS);
        this.contents.delete(MenuBuilder.MENUS.BLOCKED_PARTICIPANTS);
      }

      if (peopleDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
        this.contents.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
      }

      if (watchMode) {
        this.menuItems.delete(MenuBuilder.MENUS.CHAT);
        this.contents.delete(MenuBuilder.MENUS.CHAT);
      }

      return _react.default.createElement(RightSideMenuContainer, {
        items: Array.from(this.menuItems.values()),
        contents: Array.from(this.contents.values())
      });
    }
  }, {
    key: "buildBackstageMenu",
    value: function buildBackstageMenu(_ref5) {
      var chatDisabled = _ref5.chatDisabled,
          requestsDisabled = _ref5.requestsDisabled,
          separatorDisabled = _ref5.separatorDisabled,
          peopleDisabled = _ref5.peopleDisabled,
          watchMode = _ref5.watchMode;

      if (chatDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
        this.contents.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
        this.menuItems.delete(MenuBuilder.MENUS.BLOCKED_PARTICIPANTS);
        this.contents.delete(MenuBuilder.MENUS.BLOCKED_PARTICIPANTS);
      }

      if (peopleDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
        this.contents.delete(MenuBuilder.MENUS.CHAT_PARTICIPANTS);
      }

      if (requestsDisabled) {
        this.menuItems.delete(MenuBuilder.BACKSTAGE_MENUS.REQUESTS);
        this.contents.delete(MenuBuilder.BACKSTAGE_MENUS.REQUESTS);
      }

      if (separatorDisabled) {
        this.menuItems.delete(MenuBuilder.BACKSTAGE_MENUS.SEPARATOR);
        this.contents.delete(MenuBuilder.BACKSTAGE_MENUS.SEPARATOR);
      }

      if (watchMode) {
        this.menuItems.delete(MenuBuilder.MENUS.CHAT);
        this.contents.delete(MenuBuilder.MENUS.CHAT);
      }

      return _react.default.createElement(RightSideMenuContainer, {
        items: Array.from(this.menuItems.values()),
        contents: Array.from(this.contents.values()),
        backstage: true
      });
    }
  }]);
  return MenuBuilder;
}();

exports.MenuBuilder = MenuBuilder;
MenuBuilder.MENUS = {
  BOOTH: 1,
  CHAT: 2,
  QA: 3,
  CHAT_PARTICIPANTS: 4,
  ROOM_PARTICIPANTS: 5,
  POLLS: 6,
  FILES: 7,
  BLOCKED_PARTICIPANTS: 8
};
MenuBuilder.BACKSTAGE_MENUS = {
  REQUESTS: 20,
  INTERNAL_CHAT: 21,
  SEPARATOR: 22
};
MenuBuilder.TYPES = {
  CONTENT: 'CONTENT',
  MENU_ITEM: 'MENU_ITEM'
};