var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = withDeferredDeepLink;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _DeferredDeepLinkProvider = require("./DeferredDeepLinkProvider");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

function withDeferredDeepLink(WrappedNavigation) {
  var Wrapper = function (_React$Component) {
    (0, _inherits2.default)(Wrapper, _React$Component);

    var _super = _createSuper(Wrapper);

    function Wrapper() {
      (0, _classCallCheck2.default)(this, Wrapper);
      return _super.apply(this, arguments);
    }

    (0, _createClass2.default)(Wrapper, [{
      key: "componentDidMount",
      value: function componentDidMount() {
        var releaseDeferredDeepLink = this.context.releaseDeferredDeepLink;
        releaseDeferredDeepLink();
      }
    }, {
      key: "render",
      value: function render() {
        var _this$props = this.props,
            forwardedRef = _this$props.forwardedRef,
            rest = (0, _objectWithoutProperties2.default)(_this$props, ["forwardedRef"]);
        return _react.default.createElement(WrappedNavigation, (0, _extends2.default)({
          ref: forwardedRef
        }, rest));
      }
    }]);
    return Wrapper;
  }(_react.default.Component);

  Wrapper.contextType = _DeferredDeepLinkProvider.DeferredDeepLinkContext;

  function forwardRef(props, ref) {
    return _react.default.createElement(Wrapper, (0, _extends2.default)({}, props, {
      forwardedRef: ref
    }));
  }

  forwardRef.displayName = "WithDeferredDeepLink(".concat(WrappedNavigation.displayName || WrappedNavigation.name, ")");
  return _react.default.forwardRef(forwardRef);
}