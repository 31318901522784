var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CheckoutItemsList;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _core = require("@material-ui/core");

var _eventRegisterProvider = require("../../event-register-provider");

var _DesignSystem = require("../../../../theme/DesignSystem");

var _clsx = _interopRequireDefault(require("clsx"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    checkoutItemsList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      padding: 0,
      gap: '16px'
    },
    checkoutItem: {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderRadius: 10,
      padding: '16px 8px',
      backgroundColor: theme.palette.pageBackground.main,
      cursor: 'pointer'
    },
    selectedTicket: {
      backgroundColor: "".concat(theme.palette.button.main, "20"),
      color: theme.palette.button.main
    }
  };
});
var DEFAULT_VISIBLE_ADDON_INDEX = 0;

var generateTogglableState = function generateTogglableState(array, visibleItemIndex) {
  var visibilityObject = {};

  if (!(array === null || array === void 0 ? void 0 : array.length)) {
    return visibilityObject;
  }

  for (var i = 0; i < (array === null || array === void 0 ? void 0 : array.length); i++) {
    if (i === visibleItemIndex) {
      visibilityObject[i] = true;
    } else {
      visibilityObject[i] = false;
    }
  }

  return visibilityObject;
};

var toggleVisibilityState = function toggleVisibilityState(visibilityObject, visibleItemIndex) {
  return _objectSpread(_objectSpread({}, visibilityObject), {}, (0, _defineProperty2.default)({}, visibleItemIndex, !visibilityObject[visibleItemIndex]));
};

function CheckoutItemsList(_ref) {
  var checkoutItems = _ref.checkoutItems,
      children = _ref.children,
      _ref$defaultVisibile = _ref.defaultVisibile,
      defaultVisibile = _ref$defaultVisibile === void 0 ? DEFAULT_VISIBLE_ADDON_INDEX : _ref$defaultVisibile,
      _ref$isTogglable = _ref.isTogglable,
      isTogglable = _ref$isTogglable === void 0 ? false : _ref$isTogglable;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      selectedTicketId = _useEventRegisterCont.selectedTicketId;

  var _useState = (0, _react.useState)(generateTogglableState(checkoutItems, defaultVisibile)),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      checkoutItemsVisibility = _useState2[0],
      setcheckoutItemsVisibility = _useState2[1];

  (0, _react.useEffect)(function () {
    if (checkoutItems.length) {
      setcheckoutItemsVisibility(generateTogglableState(checkoutItems, defaultVisibile));
    }
  }, [checkoutItems, defaultVisibile]);

  var toggleVisibility = function toggleVisibility(index) {
    setcheckoutItemsVisibility(toggleVisibilityState(checkoutItemsVisibility, index));
  };

  var _useStyles = useStyles(),
      checkoutItemsList = _useStyles.checkoutItemsList,
      checkoutItem = _useStyles.checkoutItem,
      selectedTicket = _useStyles.selectedTicket;

  return !!checkoutItems && _react.default.createElement(_core.List, {
    className: checkoutItemsList
  }, checkoutItems.map(function (item, index) {
    return _react.default.createElement(_core.ListItem, {
      className: (0, _clsx.default)(checkoutItem, selectedTicketId === item.id && selectedTicket),
      key: index
    }, !!children && _react.default.cloneElement(children, {
      checkoutItemsVisibility: checkoutItemsVisibility,
      checkoutItem: item,
      index: index,
      isTogglable: isTogglable,
      toggleVisibility: toggleVisibility
    }));
  }));
}