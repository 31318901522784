Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    innerContainer: {
      border: '1px solid',
      borderColor: theme.palette.borderColor.main
    },
    infoContainer: {
      flexDirection: 'row',
      flex: 1,
      '@media(max-width: 600px)': {
        flexDirection: 'column'
      }
    },
    infoDescription: {
      flex: 1
    },
    small: {
      width: '286px',
      '@media(max-width: 600px)': {
        width: '100%',
        minHeight: 450
      },
      '@media(max-width: 425px)': {
        width: '100%',
        minHeight: 350
      }
    },
    medium: {
      width: '350px',
      '@media(max-width: 600px)': {
        width: '100%',
        minHeight: 450
      },
      '@media(max-width: 425px)': {
        width: '100%',
        minHeight: 350
      }
    },
    large: {
      width: '430px',
      '@media(max-width: 600px)': {
        width: '100%',
        minHeight: 450
      },
      '@media(max-width: 425px)': {
        width: '100%',
        minHeight: 350
      }
    },
    smallinfoContainer: {
      flexDirection: 'column'
    },
    cardBtn: {
      color: 'inherit',
      '@media(max-width: 600px)': {
        fontSize: '0.875rem',
        lineHeight: '1.5'
      }
    },
    mediumBtn: {
      fontSize: '0.875rem',
      lineHeight: '1.5'
    },
    smallBtn: {
      fontSize: '0.875rem',
      lineHeight: '1.5'
    },
    cardTitle: {
      '@media(max-width: 600px)': {
        fontSize: '1.5rem'
      }
    }
  };
});
var _default = styles;
exports.default = _default;