var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = UserSection;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _UserImageModal = _interopRequireDefault(require("./UserImageModal"));

var _reactFeather = require("react-feather");

var _AdminOptions = _interopRequireDefault(require("./AdminOptions"));

var _i18nJs = _interopRequireDefault(require("i18n-js"));

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    mainUserContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    userContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    text: {
      flexGrow: 1,
      color: theme.palette.pageText.main,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      opacity: 0.75
    },
    adminBadge: {
      fontSize: 10,
      backgroundColor: theme.palette.alternativeBackground.main,
      padding: '2px 5px',
      borderRadius: 5
    },
    options: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(0.5),
      '& svg': {
        color: theme.palette.alternativeText.main,
        fill: theme.palette.alternativeText.main
      }
    }
  };
});

function UserSection(_ref) {
  var createdByAdmin = _ref.createdByAdmin,
      isQuestion = _ref.isQuestion,
      isAdmin = _ref.isAdmin,
      userInfo = _ref.userInfo,
      isPinned = _ref.isPinned,
      onPin = _ref.onPin,
      onDelete = _ref.onDelete;
  var classes = useStyles();
  return _react.default.createElement(_core.Box, {
    className: classes.mainUserContainer
  }, _react.default.createElement(_core.Box, {
    className: classes.userContainer
  }, !isQuestion && _react.default.createElement(_UserImageModal.default, {
    userInfo: userInfo
  }), _react.default.createElement(_core.Typography, {
    variant: "overline",
    className: classes.text
  }, isQuestion && _i18nJs.default.t('qa.askedBy'), " ", userInfo.userName), createdByAdmin && _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: classes.adminBadge
  }, _i18nJs.default.t('qa.organizer'))), _react.default.createElement(_core.Box, {
    className: classes.options
  }, isPinned ? _react.default.createElement(_reactFeather.Star, {
    size: 20
  }) : null, isAdmin ? _react.default.createElement(_AdminOptions.default, {
    onPin: onPin,
    onDelete: onDelete,
    isPinned: isPinned
  }) : null));
}