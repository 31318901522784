var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocalAudioTrack = useLocalAudioTrack;
exports.useLocalVideoTrack = useLocalVideoTrack;
exports.AGORA_ERRORS = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _agoraRtcSdkNg = _interopRequireDefault(require("agora-rtc-sdk-ng"));

var _useSafeState13 = _interopRequireDefault(require("./useSafeState"));

var _agoraConstants = require("../../constants/agora-constants");

var _asyncUtils = require("../async-utils");

var _logger = _interopRequireDefault(require("../../core/services/logger"));

var _agoraVideoProfiles = require("../agoraVideoProfiles");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var AGORA_ERRORS = {
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  DEFAULT_DEVICE_IN_USE: 'DEFAULT_DEVICE_IN_USE',
  ALL_DEVICES_IN_USE: 'ALL_DEVICES_IN_USE'
};
exports.AGORA_ERRORS = AGORA_ERRORS;

function useLocalAudioTrack() {
  var onError = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};

  var _useSafeState = (0, _useSafeState13.default)(_react.default.useState(null)),
      _useSafeState2 = (0, _slicedToArray2.default)(_useSafeState, 2),
      audioTrack = _useSafeState2[0],
      setAudioTrack = _useSafeState2[1];

  var _useSafeState3 = (0, _useSafeState13.default)(_react.default.useState(false)),
      _useSafeState4 = (0, _slicedToArray2.default)(_useSafeState3, 2),
      isQuerying = _useSafeState4[0],
      setIsQuerying = _useSafeState4[1];

  var _useSafeState5 = (0, _useSafeState13.default)(_react.default.useState(false)),
      _useSafeState6 = (0, _slicedToArray2.default)(_useSafeState5, 2),
      hasQueriedError = _useSafeState6[0],
      setHasQueriedError = _useSafeState6[1];

  var getLocalAudioTrack = _react.default.useCallback(function () {
    var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(config) {
      var track, _yield$handle, _yield$handle2, _track, trackError;

      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              setHasQueriedError(null);
              setIsQuerying(true);
              _context.next = 5;
              return _agoraRtcSdkNg.default.createMicrophoneAudioTrack(_objectSpread(_objectSpread({}, config), {}, {
                microphoneId: localStorage.getItem(_agoraConstants.DEFAULT_AUDIO_INPUT_DEVICE)
              }));

            case 5:
              track = _context.sent;

              _logger.default.debug("Local audio track created, isPlaying: ".concat(track.isPlaying));

              setAudioTrack(track);
              setIsQuerying(false);
              return _context.abrupt("return", track);

            case 12:
              _context.prev = 12;
              _context.t0 = _context["catch"](0);

              if (!(_context.t0.code === AGORA_ERRORS.UNEXPECTED_ERROR)) {
                _context.next = 26;
                break;
              }

              onError({
                code: AGORA_ERRORS.DEFAULT_DEVICE_IN_USE
              });
              _context.next = 18;
              return (0, _asyncUtils.handle)(_agoraRtcSdkNg.default.createMicrophoneAudioTrack(_objectSpread({}, config)));

            case 18:
              _yield$handle = _context.sent;
              _yield$handle2 = (0, _slicedToArray2.default)(_yield$handle, 2);
              _track = _yield$handle2[0];
              trackError = _yield$handle2[1];

              if (_track) {
                setAudioTrack(_track);
              }

              if (trackError) {
                onError(_context.t0);
              }

              _context.next = 27;
              break;

            case 26:
              onError(_context.t0);

            case 27:
              setHasQueriedError(true);
              setIsQuerying(false);

            case 29:
            case "end":
              return _context.stop();
          }
        }
      }, _callee, null, [[0, 12]]);
    }));

    return function (_x) {
      return _ref.apply(this, arguments);
    };
  }(), [setAudioTrack, setIsQuerying, setHasQueriedError, onError]);

  return {
    getLocalAudioTrack: getLocalAudioTrack,
    audioTrack: audioTrack,
    isQuerying: isQuerying,
    hasQueriedError: hasQueriedError
  };
}

function useLocalVideoTrack() {
  var onError = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};

  var _useSafeState7 = (0, _useSafeState13.default)(_react.default.useState(null)),
      _useSafeState8 = (0, _slicedToArray2.default)(_useSafeState7, 2),
      videoTrack = _useSafeState8[0],
      setVideoTrack = _useSafeState8[1];

  var _useSafeState9 = (0, _useSafeState13.default)(_react.default.useState(false)),
      _useSafeState10 = (0, _slicedToArray2.default)(_useSafeState9, 2),
      isQuerying = _useSafeState10[0],
      setIsQuerying = _useSafeState10[1];

  var _useSafeState11 = (0, _useSafeState13.default)(_react.default.useState(false)),
      _useSafeState12 = (0, _slicedToArray2.default)(_useSafeState11, 2),
      hasQueriedError = _useSafeState12[0],
      setHasQueriedError = _useSafeState12[1];

  var getLocalVideoTrack = _react.default.useCallback(function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(config) {
      var track, _yield$handle3, _yield$handle4, _track2, trackError;

      return _regenerator.default.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.prev = 0;
              setHasQueriedError(null);
              setIsQuerying(true);
              _context2.next = 5;
              return _agoraRtcSdkNg.default.createCameraVideoTrack(_objectSpread({
                encoderConfig: _agoraVideoProfiles.VIDEO_ENCODER_CONFIGURATIONS[0].values,
                cameraId: localStorage.getItem(_agoraConstants.DEFAULT_VIDEO_INPUT_DEVICE),
                optimizationMode: 'motion'
              }, config));

            case 5:
              track = _context2.sent;

              _logger.default.debug("Local video track created, isPlaying: ".concat(track.isPlaying));

              setVideoTrack(track);
              setIsQuerying(false);
              return _context2.abrupt("return", track);

            case 12:
              _context2.prev = 12;
              _context2.t0 = _context2["catch"](0);

              if (!(_context2.t0.code === AGORA_ERRORS.UNEXPECTED_ERROR)) {
                _context2.next = 26;
                break;
              }

              onError({
                code: AGORA_ERRORS.DEFAULT_DEVICE_IN_USE
              });
              _context2.next = 18;
              return (0, _asyncUtils.handle)(_agoraRtcSdkNg.default.createCameraVideoTrack(_objectSpread({}, config)));

            case 18:
              _yield$handle3 = _context2.sent;
              _yield$handle4 = (0, _slicedToArray2.default)(_yield$handle3, 2);
              _track2 = _yield$handle4[0];
              trackError = _yield$handle4[1];

              if (_track2) {
                setVideoTrack(_track2);
              }

              if (trackError) {
                onError(_context2.t0);
              }

              _context2.next = 27;
              break;

            case 26:
              onError(_context2.t0);

            case 27:
              setHasQueriedError(true);
              setIsQuerying(false);

            case 29:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2, null, [[0, 12]]);
    }));

    return function (_x2) {
      return _ref2.apply(this, arguments);
    };
  }(), [setVideoTrack, setIsQuerying, setHasQueriedError, onError]);

  return {
    getLocalVideoTrack: getLocalVideoTrack,
    videoTrack: videoTrack,
    isQuerying: isQuerying,
    hasQueriedError: hasQueriedError
  };
}