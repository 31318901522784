var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasGrid;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

var _canvasUtils = require("../../../../utils/canvas-utils");

function CanvasGrid(_ref) {
  var children = _ref.children,
      _ref$cornerRadius = _ref.cornerRadius,
      cornerRadius = _ref$cornerRadius === void 0 ? 0 : _ref$cornerRadius,
      width = _ref.width,
      height = _ref.height,
      x = _ref.x,
      y = _ref.y,
      props = (0, _objectWithoutProperties2.default)(_ref, ["children", "cornerRadius", "width", "height", "x", "y"]);
  return _react.default.createElement(_reactKonva.Group, (0, _extends2.default)({
    width: width,
    height: height,
    x: x,
    y: y
  }, props, {
    clipFunc: function clipFunc(ctx) {
      return (0, _canvasUtils.crop)(ctx, {
        width: width,
        height: height
      }, cornerRadius);
    }
  }), _react.default.createElement(_reactKonva.Rect, {
    width: width,
    height: height,
    fill: "#000"
  }), children);
}