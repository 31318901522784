var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Dialog;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Section = _interopRequireDefault(require("../section/Section"));

var _Spacing = _interopRequireDefault(require("../spacing/Spacing"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function Dialog(_ref) {
  var children = _ref.children,
      _ref$onBackdropClick = _ref.onBackdropClick,
      onBackdropClick = _ref$onBackdropClick === void 0 ? function () {} : _ref$onBackdropClick;
  return _react.default.createElement(_Spacing.default, {
    style: _objectSpread({}, _reactNative.StyleSheet.absoluteFillObject),
    direction: "column",
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_reactNative.TouchableWithoutFeedback, {
    onPress: onBackdropClick
  }, _react.default.createElement(_Section.default, {
    style: _objectSpread({
      backgroundColor: 'rgba(0,0,0,0.82)',
      cursor: 'default'
    }, _reactNative.StyleSheet.absoluteFillObject)
  })), children);
}