var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNativeGestureHandler = require("react-native-gesture-handler");

var _reactNavigationHooks = require("react-navigation-hooks");

var _core = require("@material-ui/core");

var _reactRedux = require("react-redux");

var _footer = _interopRequireDefault(require("../common/footer"));

var _useHeader = _interopRequireDefault(require("../../utils/hooks/useHeader"));

var _routesConsts = require("../../navigation/client/routes-consts");

var _selectors = require("../../core/event-info/selectors");

var _backstageView = _interopRequireDefault(require("./backstage-view"));

var _actions = require("../../core/chat/actions");

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _AudioVideoProvider = _interopRequireDefault(require("../common/audio-video/AudioVideoProvider"));

var _HandleError = _interopRequireDefault(require("../common/audio-video/HandleError"));

var _ReleaseDevicesOnLeave = _interopRequireDefault(require("../common/audio-video/ReleaseDevicesOnLeave"));

var _HandleRoomChange = _interopRequireDefault(require("../common/audio-video/HandleRoomChange"));

var _Subscriptions = _interopRequireDefault(require("../common/audio-video/Subscriptions"));

var _BackstageHeaderStepper = _interopRequireDefault(require("./components/BackstageHeaderStepper"));

var _actions2 = require("../../core/user-rooms/actions");

var _MobilePreventionView = _interopRequireDefault(require("./components/MobilePreventionView"));

var _DesignSystem = require("../../theme/DesignSystem");

var _agoraConstants = require("../../constants/agora-constants");

var _selectors2 = require("../../core/user-rooms/selectors");

var _websocketConstants = require("../../constants/websocket-constants");

var Backstage = function Backstage() {
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: false
  });
  var backstageId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.BACKSTAGE_ID);
  var eventId = (0, _reactRedux.useSelector)(_selectors.selectEventInfoId);
  var eventInfo = (0, _reactRedux.useSelector)(_selectors.selectEventInfo);
  var roomsConnectionStatus = (0, _reactRedux.useSelector)(_selectors2.roomsConnectionStatusSelector);
  var dispatch = (0, _reactRedux.useDispatch)();
  var mobileMatch = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
    var breakpoints = _ref.breakpoints;
    return breakpoints.md;
  });

  _react.default.useEffect(function () {
    if (roomsConnectionStatus === _websocketConstants.WEBSOCKET_STATUS.CONNECTED) {
      if (eventId && backstageId) {
        dispatch((0, _actions2.joinRoom)({
          path: "events/".concat(eventId, "/backstages/").concat(backstageId),
          roomId: backstageId,
          type: _agoraConstants.TYPES.BACKSTAGE,
          event: _agoraConstants.JOIN_EVENT
        }));
        dispatch((0, _actions.sendRoomChange)(backstageId));
        return function () {
          dispatch((0, _actions2.leaveRoom)(backstageId));
        };
      }
    }
  }, [dispatch, backstageId, eventId, roomsConnectionStatus]);

  return _react.default.createElement(_reactNativeGestureHandler.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%',
      flexGrow: 1
    }
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    flex: 1,
    style: {
      zIndex: 2
    }
  }, mobileMatch ? _react.default.createElement(_MobilePreventionView.default, null) : _react.default.createElement(_AudioVideoProvider.default, null, _react.default.createElement(_HandleError.default, null), _react.default.createElement(_ReleaseDevicesOnLeave.default, null), _react.default.createElement(_Subscriptions.default, {
    roomId: backstageId
  }), _react.default.createElement(_HandleRoomChange.default, {
    roomId: backstageId
  }), _react.default.createElement(_backstageView.default, {
    eventId: eventId,
    backstageId: backstageId,
    dateFormat: eventInfo.dateFormat
  }), backstageId && _react.default.createElement(_core.Portal, {
    container: document.getElementById('BackstageStepper')
  }, _react.default.createElement(_BackstageHeaderStepper.default, {
    backstageId: backstageId
  })))), _react.default.createElement(_footer.default, null));
};

var _default = Backstage;
exports.default = _default;