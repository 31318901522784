var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.MainEventInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _reactNavigation = require("react-navigation");

var _constants = require("../../../../core/events/constants");

var _Paragraph = _interopRequireDefault(require("../../../common/typography_v1/Paragraph"));

var _Caption = _interopRequireDefault(require("../../../common/typography_v1/Caption"));

var _Button = _interopRequireDefault(require("../../../common/Button"));

var _PulseDot = _interopRequireDefault(require("../../../common/pulse/PulseDot"));

var _FollowUserButton = _interopRequireDefault(require("../../../common/follow/FollowUserButton"));

var _SmartDate = _interopRequireDefault(require("../../../common/SmartDate"));

var _LiveTimeCounter = require("../../../common/LiveTimeCounter");

var _CustomCachedImage = _interopRequireDefault(require("../../../common/CustomCachedImage"));

var _moment = _interopRequireDefault(require("moment"));

var _events = _interopRequireDefault(require("../.././../../core/events"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var MainEventInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(MainEventInternal, _React$PureComponent);

  var _super = _createSuper(MainEventInternal);

  function MainEventInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, MainEventInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      screenWidth: 0,
      stages: null,
      timeCount: (0, _LiveTimeCounter.TimeCount)((0, _moment.default)(_this.props.startDate))
    };
    _this.liveTimeCounter = undefined;

    _this.updateLiveTimeCounter = function () {
      var date = (0, _moment.default)(_this.props.startDate);

      _this.setState({
        timeCount: (0, _LiveTimeCounter.TimeCount)(date)
      });
    };

    _this.onPress = function (id) {
      _this.props.navigation.navigate('EventLive', {
        liveId: id
      });
    };

    return _this;
  }

  (0, _createClass2.default)(MainEventInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.liveTimeCounter = setInterval(this.updateLiveTimeCounter, 1000);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.liveTimeCounter) {
        clearInterval(this.liveTimeCounter);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var countOneLiveEvent = this.props.countOneLiveEvent;
      var date = new Date(this.props.startDate);
      var status = this.props.status === _constants.LIVE_STATUS.PLAYING;
      var smallDeviceWidth = this.props.screenWidth < 375 ? true : false;
      var itemWidth = countOneLiveEvent ? this.state.screenWidth - 32 : smallDeviceWidth ? 250 : 300;
      return React.createElement(_reactNative.View, {
        key: this.props.eventId,
        style: [styles.itemContainer, {
          width: itemWidth
        }]
      }, React.createElement(_reactNative.TouchableHighlight, {
        testID: 'TapImageLiveNow' + this.props.eventId,
        onPress: function onPress() {
          status ? _this2.onPress(_this2.props.eventId) : _this2.props.navigation.push('EventDetail', {
            eventId: _this2.props.eventId
          });
        }
      }, React.createElement(_CustomCachedImage.default, {
        style: styles.itemImage,
        source: {
          uri: this.props.coverImage
        },
        resizeMode: "cover"
      })), status && React.createElement(_reactNative.View, {
        style: styles.livelabelContainer,
        testID: 'LiveLabel' + this.props.eventId
      }, React.createElement(_reactNative.View, {
        style: styles.liveLabel
      }, React.createElement(_PulseDot.default, null), React.createElement(_Caption.default, {
        style: styles.liveLabelText
      }, "LIVE"))), React.createElement(_reactNative.View, {
        style: styles.eventDetails
      }, React.createElement(_reactNative.View, {
        style: styles.itemTitleSection
      }, React.createElement(_reactNativePaper.TouchableRipple, {
        testID: 'TapDj' + this.props.djId,
        onPress: function onPress() {
          return _this2.props.navigation.navigate('Profile', {
            userId: _this2.props.djId.toString(),
            djName: _this2.props.djName,
            avatar: _this2.props.djPicture
          });
        }
      }, React.createElement(_Paragraph.default, {
        numberOfLines: 1,
        ellipsizeMode: "tail",
        style: styles.itemTitle
      }, this.props.isContinuousStreaming ? this.props.shortDescription : this.props.djName)), !status ? this.props.isContinuousStreaming ? React.createElement(_Paragraph.default, {
        style: styles.itemPeriod
      }, "Coming Soon") : React.createElement(_SmartDate.default, {
        date: date,
        displayDirection: "row",
        fontSize: 13,
        color: "rgba(255,255,255,0.5)",
        style: {
          lineHeight: 15
        }
      }) : React.createElement(_reactNative.View, null, React.createElement(_reactNative.Text, {
        style: {
          color: 'rgba(255,255,255,0.6)',
          fontSize: 13,
          lineHeight: 15
        }
      }, this.state.timeCount))), React.createElement(_reactNative.View, {
        style: {}
      }, status ? React.createElement(_Button.default, {
        testID: 'TapLiveNow' + this.props.eventId,
        mode: "contained",
        dark: false,
        color: "#ffffff",
        buttonSize: "normal",
        disabled: false,
        onPress: function onPress() {
          return _this2.onPress(_this2.props.eventId);
        }
      }, "Join") : React.createElement(_FollowUserButton.default, {
        eventId: this.props.eventId,
        id: this.props.djId,
        isFixedButton: true,
        style: {
          width: 76
        }
      }))));
    }
  }]);
  return MainEventInternal;
}(React.PureComponent);

exports.MainEventInternal = MainEventInternal;

var styles = _reactNative.StyleSheet.create({
  itemContainer: {
    marginRight: 16
  },
  itemImage: {
    aspectRatio: 16 / 9,
    width: '100%',
    borderRadius: 4,
    minHeight: _reactNative.Platform.OS === 'web' ? _reactNative.Dimensions.get('screen').height / 3.5 : 0
  },
  eventDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12
  },
  itemTitleSection: {
    maxWidth: 200,
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  itemTitle: {
    lineHeight: 15,
    marginBottom: 4
  },
  itemPeriod: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: 13,
    lineHeight: 13
  },
  livelabelContainer: {
    position: 'absolute',
    top: 8,
    left: 8,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  liveLabel: {
    backgroundColor: 'red',
    paddingLeft: 2,
    paddingRight: 4,
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 20
  },
  liveLabelText: {
    color: '#fff',
    lineHeight: 11,
    height: 11
  }
});

function mapStateToProps(state, ownProps) {
  var eventId = ownProps.eventId;
  return {
    coverImage: _events.default.selectors.getCoverImage(state, eventId),
    numFollowers: _events.default.selectors.getNumFollowers(state, eventId),
    startDate: _events.default.selectors.getStartDate(state, eventId),
    status: _events.default.selectors.getStatus(state, eventId),
    djId: _events.default.selectors.getDjId(state, eventId),
    djName: _events.default.selectors.getDjName(state, eventId),
    djPicture: _events.default.selectors.getDjPicture(state, eventId),
    shortDescription: _events.default.selectors.getShortDesc(state, eventId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(MainEventInternal)));

exports.default = _default;