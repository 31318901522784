var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.NetworkingEvents = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var NetworkingEvents = function () {
  function NetworkingEvents() {
    (0, _classCallCheck2.default)(this, NetworkingEvents);
  }

  (0, _createClass2.default)(NetworkingEvents, null, [{
    key: "watch",
    value: function watch() {
      return 'WATCH';
    }
  }, {
    key: "unwatch",
    value: function unwatch(key) {
      return "UNWATCH/".concat(key);
    }
  }, {
    key: "join",
    value: function join() {
      return 'JOIN';
    }
  }, {
    key: "startBreakoutRooms",
    value: function startBreakoutRooms(key) {
      return "START_BREAKOUT_ROOMS/".concat(key);
    }
  }, {
    key: "stopBreakoutRooms",
    value: function stopBreakoutRooms(key) {
      return "STOP_BREAKOUT_ROOMS/".concat(key);
    }
  }, {
    key: "requestToJoinRoom",
    value: function requestToJoinRoom() {
      return 'REQUEST_TO_JOIN_ROOM';
    }
  }, {
    key: "cancelRequestToJoinRoom",
    value: function cancelRequestToJoinRoom(key) {
      return "CANCEL_REQUEST_TO_JOIN_ROOM/".concat(key);
    }
  }, {
    key: "allowRequestToJoinRoom",
    value: function allowRequestToJoinRoom(key) {
      return "ALLOW_REQUEST_TO_JOIN_ROOM/".concat(key);
    }
  }, {
    key: "denyRequestToJoinRoom",
    value: function denyRequestToJoinRoom(key) {
      return "DENY_REQUEST_TO_JOIN_ROOM/".concat(key);
    }
  }, {
    key: "deniedAccessToRoom",
    value: function deniedAccessToRoom(key) {
      return "DENIED_ACCESS_TO_ROOM/".concat(key);
    }
  }, {
    key: "kickParticipantFromRoom",
    value: function kickParticipantFromRoom(key) {
      return "KICK_PARTICIPANT_FROM_ROOM/".concat(key);
    }
  }, {
    key: "kickedParticipantFromRoom",
    value: function kickedParticipantFromRoom(key) {
      return "KICKED_PARTICIPANT_FROM_ROOM/".concat(key);
    }
  }, {
    key: "allowedAccessToRoom",
    value: function allowedAccessToRoom(key) {
      return "ALLOW_ACCESS_TO_ROOM/".concat(key);
    }
  }, {
    key: "leave",
    value: function leave(key) {
      return "LEAVE/".concat(key);
    }
  }, {
    key: "participantsChanged",
    value: function participantsChanged(key) {
      return "PARTICIPANTS_CHANGED/".concat(key);
    }
  }, {
    key: "participantRequestsChanged",
    value: function participantRequestsChanged(key) {
      return "PARTICIPANT_REQUESTS_CHANGED/".concat(key);
    }
  }, {
    key: "roomChanged",
    value: function roomChanged(key) {
      return "ROOM_CHANGED/".concat(key);
    }
  }, {
    key: "joinLiveRequest",
    value: function joinLiveRequest(key) {
      return "JOIN_LIVE_REQUEST/".concat(key);
    }
  }, {
    key: "cancelLiveRequest",
    value: function cancelLiveRequest(key) {
      return "CANCEL_LIVE_REQUEST/".concat(key);
    }
  }, {
    key: "leaveLive",
    value: function leaveLive(key) {
      return "LEAVE_LIVE/".concat(key);
    }
  }, {
    key: "startLive",
    value: function startLive(key) {
      return "START_LIVE/".concat(key);
    }
  }, {
    key: "stopLive",
    value: function stopLive(key) {
      return "STOP_LIVE/".concat(key);
    }
  }, {
    key: "allowLiveRequest",
    value: function allowLiveRequest(key) {
      return "ALLOW_LIVE_REQUEST/".concat(key);
    }
  }, {
    key: "declineLiveRequest",
    value: function declineLiveRequest(key) {
      return "DECLINE_LIVE_REQUEST/".concat(key);
    }
  }, {
    key: "kickSpeakerFromLive",
    value: function kickSpeakerFromLive(key) {
      return "KICK_SPEAKER_FROM_LIVE/".concat(key);
    }
  }, {
    key: "startScreenShare",
    value: function startScreenShare(key) {
      return "START_SCREEN_SHARE/".concat(key);
    }
  }, {
    key: "stopScreenShare",
    value: function stopScreenShare(key) {
      return "STOP_SCREEN_SHARE/".concat(key);
    }
  }, {
    key: "muteUnmuteSpeaker",
    value: function muteUnmuteSpeaker(key) {
      return "MUTE_UNMUTE_SPEAKER/".concat(key);
    }
  }, {
    key: "breakoutRoomsStarted",
    value: function breakoutRoomsStarted(key) {
      return "BREAKOUT_ROOMS_STARTED/".concat(key);
    }
  }, {
    key: "breakoutRoomsEnded",
    value: function breakoutRoomsEnded(key) {
      return "BREAKOUT_ROOMS_ENDED/".concat(key);
    }
  }, {
    key: "disconnect",
    value: function disconnect() {
      return 'disconnect';
    }
  }, {
    key: "reconnect",
    value: function reconnect() {
      return 'reconnect';
    }
  }, {
    key: "connect",
    value: function connect() {
      return 'connect';
    }
  }]);
  return NetworkingEvents;
}();

exports.NetworkingEvents = NetworkingEvents;

var NetworkingService = function () {
  function NetworkingService(socket) {
    (0, _classCallCheck2.default)(this, NetworkingService);
    this.socket = null;
    this.socket = socket;
  }

  (0, _createClass2.default)(NetworkingService, [{
    key: "watch",
    value: function () {
      var _watch = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(data) {
        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.watch(), _objectSpread(_objectSpread({}, data), {}, {
                  socketId: this.socket.id
                }));

              case 2:
                return _context.abrupt("return", _context.sent);

              case 3:
              case "end":
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function watch(_x) {
        return _watch.apply(this, arguments);
      }

      return watch;
    }()
  }, {
    key: "unwatch",
    value: function () {
      var _unwatch = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(id) {
        return _regenerator.default.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.unwatch(id), null);

              case 2:
                return _context2.abrupt("return", _context2.sent);

              case 3:
              case "end":
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function unwatch(_x2) {
        return _unwatch.apply(this, arguments);
      }

      return unwatch;
    }()
  }, {
    key: "joinRoom",
    value: function () {
      var _joinRoom = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(data) {
        return _regenerator.default.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.join(), _objectSpread(_objectSpread({}, data), {}, {
                  socketId: this.socket.id
                }));

              case 2:
                return _context3.abrupt("return", _context3.sent);

              case 3:
              case "end":
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function joinRoom(_x3) {
        return _joinRoom.apply(this, arguments);
      }

      return joinRoom;
    }()
  }, {
    key: "startBreakoutRooms",
    value: function () {
      var _startBreakoutRooms = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(roomId) {
        return _regenerator.default.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _context4.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.startBreakoutRooms(roomId), null);

              case 2:
                return _context4.abrupt("return", _context4.sent);

              case 3:
              case "end":
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function startBreakoutRooms(_x4) {
        return _startBreakoutRooms.apply(this, arguments);
      }

      return startBreakoutRooms;
    }()
  }, {
    key: "stopBreakoutRooms",
    value: function () {
      var _stopBreakoutRooms = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(roomId) {
        return _regenerator.default.wrap(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                _context5.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.stopBreakoutRooms(roomId), null);

              case 2:
                return _context5.abrupt("return", _context5.sent);

              case 3:
              case "end":
                return _context5.stop();
            }
          }
        }, _callee5, this);
      }));

      function stopBreakoutRooms(_x5) {
        return _stopBreakoutRooms.apply(this, arguments);
      }

      return stopBreakoutRooms;
    }()
  }, {
    key: "kickParticipantFromRoom",
    value: function () {
      var _kickParticipantFromRoom = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee6(_ref) {
        var roomId, participantId;
        return _regenerator.default.wrap(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                roomId = _ref.roomId, participantId = _ref.participantId;
                _context6.next = 3;
                return this.socket.asyncEmit(NetworkingEvents.kickParticipantFromRoom(roomId), participantId);

              case 3:
                return _context6.abrupt("return", _context6.sent);

              case 4:
              case "end":
                return _context6.stop();
            }
          }
        }, _callee6, this);
      }));

      function kickParticipantFromRoom(_x6) {
        return _kickParticipantFromRoom.apply(this, arguments);
      }

      return kickParticipantFromRoom;
    }()
  }, {
    key: "kickedFromRoom",
    value: function () {
      var _kickedFromRoom = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee7(id, participantId) {
        return _regenerator.default.wrap(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                _context7.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.leave(id), participantId);

              case 2:
                return _context7.abrupt("return", _context7.sent);

              case 3:
              case "end":
                return _context7.stop();
            }
          }
        }, _callee7, this);
      }));

      function kickedFromRoom(_x7, _x8) {
        return _kickedFromRoom.apply(this, arguments);
      }

      return kickedFromRoom;
    }()
  }, {
    key: "requestToJoinRoom",
    value: function () {
      var _requestToJoinRoom = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee8(data) {
        return _regenerator.default.wrap(function _callee8$(_context8) {
          while (1) {
            switch (_context8.prev = _context8.next) {
              case 0:
                _context8.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.requestToJoinRoom(), _objectSpread(_objectSpread({}, data), {}, {
                  socketId: this.socket.id
                }));

              case 2:
                return _context8.abrupt("return", _context8.sent);

              case 3:
              case "end":
                return _context8.stop();
            }
          }
        }, _callee8, this);
      }));

      function requestToJoinRoom(_x9) {
        return _requestToJoinRoom.apply(this, arguments);
      }

      return requestToJoinRoom;
    }()
  }, {
    key: "allowRequestToJoinRoom",
    value: function () {
      var _allowRequestToJoinRoom = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee9(id, participantId) {
        return _regenerator.default.wrap(function _callee9$(_context9) {
          while (1) {
            switch (_context9.prev = _context9.next) {
              case 0:
                _context9.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.allowRequestToJoinRoom(id), participantId);

              case 2:
                return _context9.abrupt("return", _context9.sent);

              case 3:
              case "end":
                return _context9.stop();
            }
          }
        }, _callee9, this);
      }));

      function allowRequestToJoinRoom(_x10, _x11) {
        return _allowRequestToJoinRoom.apply(this, arguments);
      }

      return allowRequestToJoinRoom;
    }()
  }, {
    key: "denyRequestToJoinRoom",
    value: function () {
      var _denyRequestToJoinRoom = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee10(id, participantId) {
        return _regenerator.default.wrap(function _callee10$(_context10) {
          while (1) {
            switch (_context10.prev = _context10.next) {
              case 0:
                _context10.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.denyRequestToJoinRoom(id), participantId);

              case 2:
                return _context10.abrupt("return", _context10.sent);

              case 3:
              case "end":
                return _context10.stop();
            }
          }
        }, _callee10, this);
      }));

      function denyRequestToJoinRoom(_x12, _x13) {
        return _denyRequestToJoinRoom.apply(this, arguments);
      }

      return denyRequestToJoinRoom;
    }()
  }, {
    key: "requestToJoinRoom",
    value: function () {
      var _requestToJoinRoom2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee11(data) {
        return _regenerator.default.wrap(function _callee11$(_context11) {
          while (1) {
            switch (_context11.prev = _context11.next) {
              case 0:
                _context11.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.requestToJoinRoom(), _objectSpread(_objectSpread({}, data), {}, {
                  socketId: this.socket.id
                }));

              case 2:
                return _context11.abrupt("return", _context11.sent);

              case 3:
              case "end":
                return _context11.stop();
            }
          }
        }, _callee11, this);
      }));

      function requestToJoinRoom(_x14) {
        return _requestToJoinRoom2.apply(this, arguments);
      }

      return requestToJoinRoom;
    }()
  }, {
    key: "cancelRequestToJoinRoom",
    value: function () {
      var _cancelRequestToJoinRoom = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee12(id) {
        return _regenerator.default.wrap(function _callee12$(_context12) {
          while (1) {
            switch (_context12.prev = _context12.next) {
              case 0:
                _context12.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.cancelRequestToJoinRoom(id), null);

              case 2:
                return _context12.abrupt("return", _context12.sent);

              case 3:
              case "end":
                return _context12.stop();
            }
          }
        }, _callee12, this);
      }));

      function cancelRequestToJoinRoom(_x15) {
        return _cancelRequestToJoinRoom.apply(this, arguments);
      }

      return cancelRequestToJoinRoom;
    }()
  }, {
    key: "allowRequestToJoinRoom",
    value: function () {
      var _allowRequestToJoinRoom2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee13(id, participantId) {
        return _regenerator.default.wrap(function _callee13$(_context13) {
          while (1) {
            switch (_context13.prev = _context13.next) {
              case 0:
                _context13.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.allowRequestToJoinRoom(id), participantId);

              case 2:
                return _context13.abrupt("return", _context13.sent);

              case 3:
              case "end":
                return _context13.stop();
            }
          }
        }, _callee13, this);
      }));

      function allowRequestToJoinRoom(_x16, _x17) {
        return _allowRequestToJoinRoom2.apply(this, arguments);
      }

      return allowRequestToJoinRoom;
    }()
  }, {
    key: "leaveRoom",
    value: function () {
      var _leaveRoom = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee14(id) {
        return _regenerator.default.wrap(function _callee14$(_context14) {
          while (1) {
            switch (_context14.prev = _context14.next) {
              case 0:
                _context14.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.leave(id), null);

              case 2:
                return _context14.abrupt("return", _context14.sent);

              case 3:
              case "end":
                return _context14.stop();
            }
          }
        }, _callee14, this);
      }));

      function leaveRoom(_x18) {
        return _leaveRoom.apply(this, arguments);
      }

      return leaveRoom;
    }()
  }, {
    key: "joinLiveRequest",
    value: function () {
      var _joinLiveRequest = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee15(id) {
        return _regenerator.default.wrap(function _callee15$(_context15) {
          while (1) {
            switch (_context15.prev = _context15.next) {
              case 0:
                _context15.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.joinLiveRequest(id), null);

              case 2:
                return _context15.abrupt("return", _context15.sent);

              case 3:
              case "end":
                return _context15.stop();
            }
          }
        }, _callee15, this);
      }));

      function joinLiveRequest(_x19) {
        return _joinLiveRequest.apply(this, arguments);
      }

      return joinLiveRequest;
    }()
  }, {
    key: "cancelLiveRequest",
    value: function () {
      var _cancelLiveRequest = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee16(id) {
        return _regenerator.default.wrap(function _callee16$(_context16) {
          while (1) {
            switch (_context16.prev = _context16.next) {
              case 0:
                _context16.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.cancelLiveRequest(id), null);

              case 2:
                return _context16.abrupt("return", _context16.sent);

              case 3:
              case "end":
                return _context16.stop();
            }
          }
        }, _callee16, this);
      }));

      function cancelLiveRequest(_x20) {
        return _cancelLiveRequest.apply(this, arguments);
      }

      return cancelLiveRequest;
    }()
  }, {
    key: "leaveLive",
    value: function () {
      var _leaveLive = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee17(id) {
        return _regenerator.default.wrap(function _callee17$(_context17) {
          while (1) {
            switch (_context17.prev = _context17.next) {
              case 0:
                _context17.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.leaveLive(id), null);

              case 2:
                return _context17.abrupt("return", _context17.sent);

              case 3:
              case "end":
                return _context17.stop();
            }
          }
        }, _callee17, this);
      }));

      function leaveLive(_x21) {
        return _leaveLive.apply(this, arguments);
      }

      return leaveLive;
    }()
  }, {
    key: "startLive",
    value: function () {
      var _startLive = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee18(id) {
        return _regenerator.default.wrap(function _callee18$(_context18) {
          while (1) {
            switch (_context18.prev = _context18.next) {
              case 0:
                _context18.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.startLive(id), null);

              case 2:
                return _context18.abrupt("return", _context18.sent);

              case 3:
              case "end":
                return _context18.stop();
            }
          }
        }, _callee18, this);
      }));

      function startLive(_x22) {
        return _startLive.apply(this, arguments);
      }

      return startLive;
    }()
  }, {
    key: "stopLive",
    value: function () {
      var _stopLive = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee19(id) {
        return _regenerator.default.wrap(function _callee19$(_context19) {
          while (1) {
            switch (_context19.prev = _context19.next) {
              case 0:
                _context19.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.stopLive(id), null);

              case 2:
                return _context19.abrupt("return", _context19.sent);

              case 3:
              case "end":
                return _context19.stop();
            }
          }
        }, _callee19, this);
      }));

      function stopLive(_x23) {
        return _stopLive.apply(this, arguments);
      }

      return stopLive;
    }()
  }, {
    key: "allowLiveRequest",
    value: function () {
      var _allowLiveRequest = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee20(id, speakerId) {
        return _regenerator.default.wrap(function _callee20$(_context20) {
          while (1) {
            switch (_context20.prev = _context20.next) {
              case 0:
                _context20.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.allowLiveRequest(id), speakerId);

              case 2:
                return _context20.abrupt("return", _context20.sent);

              case 3:
              case "end":
                return _context20.stop();
            }
          }
        }, _callee20, this);
      }));

      function allowLiveRequest(_x24, _x25) {
        return _allowLiveRequest.apply(this, arguments);
      }

      return allowLiveRequest;
    }()
  }, {
    key: "declineLiveRequest",
    value: function () {
      var _declineLiveRequest = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee21(id, speakerId) {
        return _regenerator.default.wrap(function _callee21$(_context21) {
          while (1) {
            switch (_context21.prev = _context21.next) {
              case 0:
                _context21.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.declineLiveRequest(id), speakerId);

              case 2:
                return _context21.abrupt("return", _context21.sent);

              case 3:
              case "end":
                return _context21.stop();
            }
          }
        }, _callee21, this);
      }));

      function declineLiveRequest(_x26, _x27) {
        return _declineLiveRequest.apply(this, arguments);
      }

      return declineLiveRequest;
    }()
  }, {
    key: "kickSpeakerFromLive",
    value: function () {
      var _kickSpeakerFromLive = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee22(id, speakerId) {
        return _regenerator.default.wrap(function _callee22$(_context22) {
          while (1) {
            switch (_context22.prev = _context22.next) {
              case 0:
                _context22.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.kickSpeakerFromLive(id), speakerId);

              case 2:
                return _context22.abrupt("return", _context22.sent);

              case 3:
              case "end":
                return _context22.stop();
            }
          }
        }, _callee22, this);
      }));

      function kickSpeakerFromLive(_x28, _x29) {
        return _kickSpeakerFromLive.apply(this, arguments);
      }

      return kickSpeakerFromLive;
    }()
  }, {
    key: "startScreenShare",
    value: function () {
      var _startScreenShare = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee23(id) {
        return _regenerator.default.wrap(function _callee23$(_context23) {
          while (1) {
            switch (_context23.prev = _context23.next) {
              case 0:
                _context23.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.startScreenShare(id), null);

              case 2:
                return _context23.abrupt("return", _context23.sent);

              case 3:
              case "end":
                return _context23.stop();
            }
          }
        }, _callee23, this);
      }));

      function startScreenShare(_x30) {
        return _startScreenShare.apply(this, arguments);
      }

      return startScreenShare;
    }()
  }, {
    key: "stopScreenShare",
    value: function () {
      var _stopScreenShare = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee24(id) {
        return _regenerator.default.wrap(function _callee24$(_context24) {
          while (1) {
            switch (_context24.prev = _context24.next) {
              case 0:
                _context24.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.stopScreenShare(id), null);

              case 2:
                return _context24.abrupt("return", _context24.sent);

              case 3:
              case "end":
                return _context24.stop();
            }
          }
        }, _callee24, this);
      }));

      function stopScreenShare(_x31) {
        return _stopScreenShare.apply(this, arguments);
      }

      return stopScreenShare;
    }()
  }, {
    key: "muteUnmuteSpeaker",
    value: function () {
      var _muteUnmuteSpeaker = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee25(key, data) {
        return _regenerator.default.wrap(function _callee25$(_context25) {
          while (1) {
            switch (_context25.prev = _context25.next) {
              case 0:
                _context25.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.muteUnmuteSpeaker(key), data);

              case 2:
                return _context25.abrupt("return", _context25.sent);

              case 3:
              case "end":
                return _context25.stop();
            }
          }
        }, _callee25, this);
      }));

      function muteUnmuteSpeaker(_x32, _x33) {
        return _muteUnmuteSpeaker.apply(this, arguments);
      }

      return muteUnmuteSpeaker;
    }()
  }, {
    key: "connect",
    value: function () {
      var _connect = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee26(params) {
        return _regenerator.default.wrap(function _callee26$(_context26) {
          while (1) {
            switch (_context26.prev = _context26.next) {
              case 0:
                _context26.next = 2;
                return this.socket.asyncConnect(params);

              case 2:
                return _context26.abrupt("return", _context26.sent);

              case 3:
              case "end":
                return _context26.stop();
            }
          }
        }, _callee26, this);
      }));

      function connect(_x34) {
        return _connect.apply(this, arguments);
      }

      return connect;
    }()
  }, {
    key: "disconnect",
    value: function () {
      var _disconnect = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee27() {
        return _regenerator.default.wrap(function _callee27$(_context27) {
          while (1) {
            switch (_context27.prev = _context27.next) {
              case 0:
                _context27.next = 2;
                return this.socket.asyncDisconnect();

              case 2:
                return _context27.abrupt("return", _context27.sent);

              case 3:
              case "end":
                return _context27.stop();
            }
          }
        }, _callee27, this);
      }));

      function disconnect() {
        return _disconnect.apply(this, arguments);
      }

      return disconnect;
    }()
  }, {
    key: "onRoomChange",
    value: function onRoomChange(key, listener) {
      var _this = this;

      this.socket.on(NetworkingEvents.roomChanged(key), listener);
      return function () {
        return _this.socket.off(NetworkingEvents.roomChanged(key));
      };
    }
  }, {
    key: "onParticipantsChange",
    value: function onParticipantsChange(key, listener) {
      var _this2 = this;

      this.socket.on(NetworkingEvents.participantsChanged(key), listener);
      return function () {
        return _this2.socket.off(NetworkingEvents.participantsChanged(key));
      };
    }
  }, {
    key: "onParticipantRequestsChange",
    value: function onParticipantRequestsChange(key, listener) {
      var _this3 = this;

      this.socket.on(NetworkingEvents.participantRequestsChanged(key), listener);
      return function () {
        return _this3.socket.off(NetworkingEvents.participantRequestsChanged(key));
      };
    }
  }, {
    key: "onAllowedAccessToRoom",
    value: function onAllowedAccessToRoom(key, listener) {
      var _this4 = this;

      this.socket.on(NetworkingEvents.allowedAccessToRoom(key), listener);
      return function () {
        return _this4.socket.off(NetworkingEvents.allowedAccessToRoom(key));
      };
    }
  }, {
    key: "onDeniedAccessToRoom",
    value: function onDeniedAccessToRoom(key, listener) {
      var _this5 = this;

      this.socket.on(NetworkingEvents.deniedAccessToRoom(key), listener);
      return function () {
        return _this5.socket.off(NetworkingEvents.deniedAccessToRoom(key));
      };
    }
  }, {
    key: "onKickedFromRoom",
    value: function onKickedFromRoom(key, listener) {
      var _this6 = this;

      this.socket.on(NetworkingEvents.kickedParticipantFromRoom(key), listener);
      return function () {
        return _this6.socket.off(NetworkingEvents.kickedParticipantFromRoom(key));
      };
    }
  }, {
    key: "onBreakoutRoomsStarted",
    value: function onBreakoutRoomsStarted(key, listener) {
      var _this7 = this;

      this.socket.on(NetworkingEvents.breakoutRoomsStarted(key), listener);
      return function () {
        return _this7.socket.off(NetworkingEvents.breakoutRoomsStarted(key));
      };
    }
  }, {
    key: "onBreakoutRoomsEnded",
    value: function onBreakoutRoomsEnded(key, listener) {
      var _this8 = this;

      this.socket.on(NetworkingEvents.breakoutRoomsEnded(key), listener);
      return function () {
        return _this8.socket.off(NetworkingEvents.breakoutRoomsEnded(key));
      };
    }
  }, {
    key: "onDisconnect",
    value: function onDisconnect(l) {
      var _this9 = this;

      this.socket.on(NetworkingEvents.disconnect(), l);
      return function () {
        return _this9.socket.off(NetworkingEvents.disconnect());
      };
    }
  }, {
    key: "onReconnect",
    value: function onReconnect(l) {
      var _this10 = this;

      this.socket.on(NetworkingEvents.reconnect(), l);
      return function () {
        return _this10.socket.off(NetworkingEvents.reconnect());
      };
    }
  }, {
    key: "onConnect",
    value: function onConnect(l) {
      var _this11 = this;

      this.socket.on(NetworkingEvents.connect(), l);
      return function () {
        return _this11.socket.off(NetworkingEvents.connect());
      };
    }
  }]);
  return NetworkingService;
}();

exports.default = NetworkingService;