var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LoginBackstageContainer;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _authStyles = _interopRequireDefault(require("../../auth-styles"));

var _selectors = require("../../../../core/auth/selectors");

var _selectors2 = require("../../../../core/event-info/selectors");

var _Redirect = _interopRequireDefault(require("../../../../navigation/client/Redirect"));

var _useHeader = _interopRequireDefault(require("../../../../utils/hooks/useHeader"));

var _useNavigationExtended = _interopRequireDefault(require("../../../../utils/hooks/useNavigationExtended"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _actions = require("../../../../core/user/actions");

var _loginFinalizeView = _interopRequireDefault(require("../finalize/login-finalize-view"));

var _Section = _interopRequireDefault(require("../../../../theme/components/section/Section"));

var _reactNative = require("react-native");

var _homeButton = _interopRequireDefault(require("../../home-button"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _actions2 = require("../../../../core/auth/actions");

function LoginBackstageContainer() {
  var Styles = (0, _authStyles.default)();
  var error = (0, _reactRedux.useSelector)(_selectors.getLoginError);
  var isLoading = (0, _reactRedux.useSelector)(_selectors.isLoginLoading);
  var eventId = (0, _reactRedux.useSelector)(_selectors2.selectEventInfoId);
  var navigation = (0, _useNavigationExtended.default)();
  var dispatch = (0, _reactRedux.useDispatch)();

  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      loginForEmail = _useState2[0],
      setLoginForEmail = _useState2[1];

  (0, _useHeader.default)({
    visible: false,
    withScroll: false,
    withLeftMenu: false
  });

  _react.default.useEffect(function () {
    dispatch((0, _actions2.removeLoginError)());
  }, []);

  if (!_reactNativeFirebase.default.auth().isSignInWithEmailLink(window.location.href)) {
    return _react.default.createElement(_Redirect.default, {
      to: _routesConsts.Routes.HOME
    });
  }

  if (!loginForEmail || error) {
    return _react.default.createElement(_Section.default, {
      style: {
        flex: 1
      },
      color: "pageBackground"
    }, _react.default.createElement(_reactNative.ScrollView, {
      contentContainerStyle: Styles.scrollView
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: ['xl', 0, 0, 0]
    }, _react.default.createElement(_homeButton.default, null)), _react.default.createElement(_loginFinalizeView.default, {
      onLogin: function onLogin(email) {
        return setLoginForEmail(email);
      },
      goToRegister: function goToRegister() {
        return navigation.navigate(_routesConsts.Routes.REGISTER);
      },
      goToLogin: function goToLogin() {
        return navigation.navigate(_routesConsts.Routes.LOGIN);
      },
      loginFeedback: error,
      isLoading: isLoading
    })));
  }

  dispatch((0, _actions.firebaseEmailLogin)(loginForEmail, eventId));
  return _react.default.createElement(_react.default.Fragment, null);
}