Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleJoinRequest;

var _react = require("react");

var _AudioVideoProvider = require("./AudioVideoProvider");

function HandleJoinRequest(_ref) {
  var isCurrentUserRequesting = _ref.isCurrentUserRequesting;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      requestAccess = _useAudioVideoContext.requestAccess;

  (0, _react.useEffect)(function () {
    if (isCurrentUserRequesting) {
      requestAccess();
    }
  }, [isCurrentUserRequesting]);
  return null;
}