var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = UnassignedTicketsListView;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../../core/tickets/selectors");

var _core = require("@material-ui/core");

var _Input = _interopRequireDefault(require("../../../../theme/components/inputs/Input"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _formik = require("formik");

var Yup = _interopRequireWildcard(require("yup"));

var _currencyUtils = require("../../../../utils/currency-utils");

var _actions = require("../../../../core/checkout/actions");

var _eventRegistrationUtils = require("../../../event-registration/event-registration-utils");

var _selectors2 = require("../../../../core/user/selectors");

var _selectors3 = require("../../../../core/event-info/selectors");

var _successfulAssignment = _interopRequireDefault(require("../../../event-registration/event-registration-components/common/successfulAssignment"));

var _constants = require("../../../../core/tickets/constants");

var _styles = _interopRequireDefault(require("./styles"));

var _RenderUnassignedAddons = require("../../../common/RenderUnassignedAddons");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getValues = function getValues(unassignedTickets) {
  var validationSchema = {};
  var initialValues = {};
  unassignedTickets.forEach(function (unassignedTicket) {
    var _objectSpread2, _objectSpread3;

    validationSchema = _objectSpread(_objectSpread({}, validationSchema), {}, (_objectSpread2 = {}, (0, _defineProperty2.default)(_objectSpread2, "".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id), Yup.string()), (0, _defineProperty2.default)(_objectSpread2, "".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id), Yup.string().email('email is not valid')), _objectSpread2));
    initialValues = _objectSpread(_objectSpread({}, initialValues), {}, (_objectSpread3 = {}, (0, _defineProperty2.default)(_objectSpread3, "".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id), ''), (0, _defineProperty2.default)(_objectSpread3, "".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id), ''), _objectSpread3));
  });
  return {
    validationSchema: validationSchema,
    initialValues: initialValues
  };
};

function UnassignedTicketsListView(_ref) {
  var unassignedTickets = _ref.unassignedTickets;
  var classes = (0, _styles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var userId = (0, _reactRedux.useSelector)(_selectors2.getFirebaseUserUid);
  var eventId = (0, _reactRedux.useSelector)(_selectors3.selectEventInfoId);
  var boughtTicketsLoading = (0, _reactRedux.useSelector)(_selectors.selectBoughtTicketsLoading);

  var _useState = (0, _react.useState)({}),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      successfulAssigments = _useState2[0],
      setSuccessfulAssigments = _useState2[1];

  var _useState3 = (0, _react.useState)([]),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      successfulAssigmentsIds = _useState4[0],
      setSuccessfulAssigmentsIds = _useState4[1];

  var _useMemo = (0, _react.useMemo)(function () {
    return getValues(unassignedTickets);
  }, [unassignedTickets]),
      validationSchema = _useMemo.validationSchema,
      initialValues = _useMemo.initialValues;

  var handleAssignTickets = function () {
    var _ref3 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(values, _ref2) {
      var setErrors, groupedTickets, _verifyIfAllFieldsAre, hasErrors, errors, payload, assignResult, grupedTicketsResult, groupedTicketsErrors, groupedTicketsSuccess, successfulTicketsIds;

      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              setErrors = _ref2.setErrors;
              _context.prev = 1;
              groupedTickets = (0, _eventRegistrationUtils.groupUnasignedTicketsById)(values, successfulAssigmentsIds);

              if (!(groupedTickets.length === 0)) {
                _context.next = 5;
                break;
              }

              return _context.abrupt("return");

            case 5:
              _verifyIfAllFieldsAre = (0, _eventRegistrationUtils.verifyIfAllFieldsAreFilled)(groupedTickets), hasErrors = _verifyIfAllFieldsAre.hasErrors, errors = _verifyIfAllFieldsAre.errors;

              if (!hasErrors) {
                _context.next = 9;
                break;
              }

              setErrors(errors);
              return _context.abrupt("return");

            case 9:
              payload = {
                assignerId: userId,
                eventId: eventId,
                groupedTickets: groupedTickets
              };
              _context.next = 12;
              return dispatch((0, _actions.assignTickets)(payload));

            case 12:
              assignResult = _context.sent;
              grupedTicketsResult = assignResult.payload.unassignedTicketResult;
              groupedTicketsErrors = {};
              groupedTicketsSuccess = _objectSpread({}, successfulAssigments);
              successfulTicketsIds = (0, _toConsumableArray2.default)(successfulAssigmentsIds);
              grupedTicketsResult.forEach(function (ticket) {
                if (!ticket.status.successful) {
                  groupedTicketsErrors = _objectSpread(_objectSpread({}, groupedTicketsErrors), {}, (0, _defineProperty2.default)({}, "".concat(_constants.EMAIL_FIELD, "-").concat(ticket.unassignedTicketId), ticket.status.errorMessage));
                } else {
                  groupedTicketsSuccess = _objectSpread(_objectSpread({}, groupedTicketsSuccess), {}, (0, _defineProperty2.default)({}, "".concat(_constants.ASSIGNED_FIELD, "-").concat(ticket.unassignedTicketId), true));
                  successfulTicketsIds.push(ticket.unassignedTicketId);
                }
              });
              setSuccessfulAssigments(groupedTicketsSuccess);
              setSuccessfulAssigmentsIds(successfulTicketsIds);
              setErrors(groupedTicketsErrors);
              _context.next = 26;
              break;

            case 23:
              _context.prev = 23;
              _context.t0 = _context["catch"](1);
              console.log(_context.t0);

            case 26:
            case "end":
              return _context.stop();
          }
        }
      }, _callee, null, [[1, 23]]);
    }));

    return function handleAssignTickets(_x, _x2) {
      return _ref3.apply(this, arguments);
    };
  }();

  var assignmentIsComplete = Object.keys(successfulAssigments).length >= 1 && Object.keys(unassignedTickets).length >= 1 && Object.keys(successfulAssigments).length === Object.keys(unassignedTickets).length;
  if (boughtTicketsLoading) return null;
  return _react.default.createElement(_core.Box, {
    component: _core.Card,
    className: classes.container
  }, _react.default.createElement(_formik.Formik, {
    initialValues: initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: handleAssignTickets
  }, function (_ref4) {
    var errors = _ref4.errors,
        handleBlur = _ref4.handleBlur,
        handleChange = _ref4.handleChange,
        handleSubmit = _ref4.handleSubmit,
        isSubmitting = _ref4.isSubmitting,
        touched = _ref4.touched,
        values = _ref4.values;
    return _react.default.createElement(_react.default.Fragment, null, unassignedTickets.map(function (unassignedTicket, index) {
      var _unassignedTicket$tic, _unassignedTicket$tic2, _unassignedTicket$sel;

      return _react.default.createElement(_core.Box, {
        my: 2,
        style: {
          borderBottom: unassignedTickets.length - 1 !== index ? '1px dotted lightgrey' : ''
        },
        key: unassignedTicket.id
      }, _react.default.createElement(_core.Box, {
        p: 3
      }, _react.default.createElement(_core.Typography, {
        variant: "h2"
      }, "#".concat(index + 1, " - ").concat((_unassignedTicket$tic = unassignedTicket.ticketInfo) === null || _unassignedTicket$tic === void 0 ? void 0 : _unassignedTicket$tic.name, " - ").concat((_unassignedTicket$tic2 = unassignedTicket.ticketInfo) === null || _unassignedTicket$tic2 === void 0 ? void 0 : _unassignedTicket$tic2.parsedPriceLabel)), (unassignedTicket === null || unassignedTicket === void 0 ? void 0 : (_unassignedTicket$sel = unassignedTicket.selectedAddons) === null || _unassignedTicket$sel === void 0 ? void 0 : _unassignedTicket$sel.length) >= 1 && _react.default.createElement(_core.Box, {
        my: 2
      }, _react.default.createElement(_RenderUnassignedAddons.RenderUnassignedAddons, {
        ticketAddons: unassignedTicket === null || unassignedTicket === void 0 ? void 0 : unassignedTicket.selectedAddons
      })), successfulAssigments["".concat(_constants.ASSIGNED_FIELD, "-").concat(unassignedTicket.id)] ? _react.default.createElement(_successfulAssignment.default, null) : _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Box, {
        mt: 1
      }, _react.default.createElement(_Input.default, {
        type: "text",
        placeholder: "".concat(_i18n.default.translate('assignTickets.fullName'), " *"),
        color: "alternativeText",
        value: values["".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id)],
        name: "".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id),
        onChange: handleChange,
        onBlur: handleBlur
      }), !!errors["".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id)] && touched["".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id)] && _react.default.createElement(_core.Box, {
        className: classes.errorContainer
      }, _react.default.createElement(_core.Typography, {
        variant: "caption",
        style: {
          color: 'red'
        }
      }, errors["".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id)]))), _react.default.createElement(_core.Box, {
        my: 2
      }, _react.default.createElement(_Input.default, {
        type: "email",
        color: "alternativeText",
        placeholder: "".concat(_i18n.default.translate('assignTickets.email'), " *"),
        value: values["".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id)],
        name: "".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id),
        onChange: handleChange,
        onBlur: handleBlur
      }), !!errors["".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id)] && touched["".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id)] && _react.default.createElement(_core.Box, {
        className: classes.errorContainer
      }, _react.default.createElement(_core.Typography, {
        variant: "caption",
        style: {
          color: 'red'
        }
      }, errors["".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id)]))))));
    }), _react.default.createElement(_core.Box, {
      my: 2
    }, errors.duplicatedEmails && _react.default.createElement(_core.Box, {
      className: classes.errorContainer
    }, _react.default.createElement(_core.Typography, {
      variant: "caption",
      style: {
        color: 'red'
      }
    }, _i18n.default.translate('assignTickets.emailShouldBeUnique')))), _react.default.createElement(_core.Box, {
      px: 3,
      mb: 2
    }, _react.default.createElement(_core.Button, {
      onClick: handleSubmit,
      disabled: isSubmitting || assignmentIsComplete,
      color: "primary",
      variant: "contained",
      className: classes.button
    }, isSubmitting ? _react.default.createElement(_core.CircularProgress, {
      size: "1.5rem"
    }) : _i18n.default.translate('assignTickets.title'))));
  }));
}