Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)({
  textUppercase: {
    textTransform: 'uppercase'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    '@media(max-width: 600px)': {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  controlContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    position: 'relative'
  }
});
var _default = styles;
exports.default = _default;