var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorBoundary = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativeGestureHandler = require("react-native-gesture-handler");

var _reactNativePaper = require("react-native-paper");

var _error = require("./error.consts");

var _ErrorBoundary = _interopRequireDefault(require("./ErrorBoundary.styles"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _reactFeather = require("react-feather");

var _eventList = _interopRequireDefault(require("../event-list"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var ErrorBoundary = function (_React$Component) {
  (0, _inherits2.default)(ErrorBoundary, _React$Component);

  var _super = _createSuper(ErrorBoundary);

  function ErrorBoundary(props) {
    var _this;

    (0, _classCallCheck2.default)(this, ErrorBoundary);
    _this = _super.call(this, props);

    _this.renderNoEventForDomain = function () {
      return _react.default.createElement(_reactNative.View, {
        style: _ErrorBoundary.default.container
      }, _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.error
      }, _i18n.default.t('errors.error404')), _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.errorMessage2
      }, _i18n.default.t('domain.noEventForDomain')), _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.errorMessage
      }, _i18n.default.t('domain.noAssociatedEvent')));
    };

    _this.renderMoreEventsForDomain = function () {
      return _react.default.createElement(_reactNative.View, {
        style: _ErrorBoundary.default.container
      }, _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.error
      }, window.location.host), _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.errorMessage
      }, _i18n.default.t('domain.severalEvents')), _this.state.error && _this.state.error.info && _react.default.createElement(_eventList.default, {
        events: _this.state.error.info
      }));
    };

    _this.renderInvalidIdTemplate = function () {
      return _react.default.createElement(_reactNative.View, {
        style: _ErrorBoundary.default.container
      }, _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.error
      }, _i18n.default.t('errors.error404')), _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.errorMessage
      }, _i18n.default.t('errors.eventNotExists')));
    };

    _this.renderDefaultErrorTemplate = function (error) {
      return _react.default.createElement(_reactNative.View, {
        style: _ErrorBoundary.default.container
      }, _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.error
      }, _i18n.default.t('errors.error')), _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.errorMessage
      }, _i18n.default.t('errors.somethingWentWrong')), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        style: _ErrorBoundary.default.button,
        onPress: function onPress() {
          return window.location.reload();
        }
      }, _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.buttonText
      }, _i18n.default.t('errors.tryAgain'))), _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.errorMessage2
      }, error.toString()));
    };

    _this.renderFirewallBlockingErrorTemplate = function () {
      var event = "".concat(window.location.host).concat(window.location.pathname);
      return _react.default.createElement(_reactNative.View, {
        style: _ErrorBoundary.default.container
      }, _react.default.createElement(_reactNative.View, {
        style: _ErrorBoundary.default.centeredContainer
      }, _react.default.createElement(_reactFeather.AlertTriangle, {
        size: "100",
        color: "orange"
      }), _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.error
      }, _i18n.default.t('errors.firewall')), _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.errorMessage
      }, _i18n.default.t('errors.firewall1'), event), _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.errorMessage
      }, _i18n.default.t('errors.firewall2')), _react.default.createElement(_reactNative.Text, {
        style: [_ErrorBoundary.default.errorMessage, _ErrorBoundary.default.bold]
      }, _i18n.default.t('errors.firewall3')), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        style: [_ErrorBoundary.default.button, _ErrorBoundary.default.topMargin],
        onPress: function onPress() {
          return window.location.reload();
        }
      }, _react.default.createElement(_reactNative.Text, {
        style: _ErrorBoundary.default.buttonText
      }, _i18n.default.t('errors.tryAgain')))));
    };

    _this.renderErrorTemplate = function (error) {
      var errorMsg = error.toString();

      if (errorMsg.includes('FirebaseError: [code=unavailable]') || errorMsg.includes('[code=unavailable]') || errorMsg.includes('Failed to get document because the client is offline')) {
        return _this.renderFirewallBlockingErrorTemplate();
      } else {
        return _this.renderDefaultErrorTemplate(error);
      }
    };

    _this.state = {
      hasError: false,
      error: null,
      events: []
    };
    return _this;
  }

  (0, _createClass2.default)(ErrorBoundary, [{
    key: "componentDidCatch",
    value: function componentDidCatch(error) {
      this.setState({
        hasError: true,
        error: error
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state = this.state,
          hasError = _this$state.hasError,
          error = _this$state.error;
      var templateToRender = null;

      if (hasError && error) {
        switch (error.message) {
          case _error.eventErrors.NO_EVENT_ASSOCIATED_TO_DOMAIN:
            templateToRender = this.renderNoEventForDomain();
            break;

          case _error.eventErrors.MORE_EVENTS_ASSOCIATED_TO_DOMAIN:
            templateToRender = this.renderMoreEventsForDomain();
            break;

          case _error.eventErrors.INVALID_EVENT_ID:
            templateToRender = this.renderInvalidIdTemplate();
            break;

          default:
            templateToRender = this.renderErrorTemplate(error);
            break;
        }

        return _react.default.createElement(_reactNativeGestureHandler.ScrollView, null, templateToRender);
      }

      return this.props.children;
    }
  }]);
  return ErrorBoundary;
}(_react.default.Component);

exports.ErrorBoundary = ErrorBoundary;