Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    header: {
      textTransform: 'uppercase'
    },
    container: {
      margin: -theme.spacing(2)
    },
    cardBox: {
      minWidth: 200,
      flexBasis: '22%',
      alignSelf: 'stretch',
      '@media(max-width: 1280px)': {
        minWidth: 'calc(100%/3)',
        flexBasis: 'calc(100%/3)'
      },
      '@media(max-width: 600px)': {
        width: '100%',
        flexBasis: '100%'
      }
    }
  };
});
var _default = styles;
exports.default = _default;