var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleBreakoutRoomStatus;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _reactRedux = require("react-redux");

var _lodash = require("lodash");

var _selectors = require("../../core/networking/selectors");

var _selectors2 = require("../../core/user-rooms/selectors");

var _routesConsts = require("../../navigation/client/routes-consts");

var _useNavigationExtended = _interopRequireDefault(require("../../utils/hooks/useNavigationExtended"));

var _usePrevious = _interopRequireDefault(require("../../utils/hooks/usePrevious"));

var _actions = require("../../core/user-rooms/actions");

var BREAKOUT_ROOMS_STATUSES = {
  STARTED: 'started',
  STARTING: 'starting',
  STOPPED: 'stopped'
};

function HandleBreakoutRoomStatus(_ref) {
  var roomId = _ref.roomId,
      children = _ref.children;
  var dispatch = (0, _reactRedux.useDispatch)();

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      wasBreakoutRoomsStarted = _useState2[0],
      setWasBreakoutRoomsStarted = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      wasBreakoutRoomsStopped = _useState4[0],
      setWasBreakoutRoomsStopped = _useState4[1];

  var roomInfo = (0, _reactRedux.useSelector)((0, _selectors2.roomStateSelector)(roomId));
  var _roomInfo$breakoutRoo = roomInfo.breakoutRoomToJoin,
      breakoutRoomToJoin = _roomInfo$breakoutRoo === void 0 ? null : _roomInfo$breakoutRoo,
      mainRoom = roomInfo.mainRoom,
      _roomInfo$noSpaceAvai = roomInfo.noSpaceAvailable,
      noSpaceAvailable = _roomInfo$noSpaceAvai === void 0 ? false : _roomInfo$noSpaceAvai;
  var mainRoomTable = (0, _reactRedux.useSelector)(_selectors.mainRoomTableSelector);

  var _useNavigationExtende = (0, _useNavigationExtended.default)(),
      navigate = _useNavigationExtende.navigate;

  var breakoutRoomsStatus = (0, _reactRedux.useSelector)((0, _selectors2.breakoutRoomsStatusSelector)(roomId));
  var previousBreakoutRoomsStatus = (0, _usePrevious.default)(breakoutRoomsStatus);
  var mainRoomId = mainRoomTable === null || mainRoomTable === void 0 ? void 0 : mainRoomTable.id;
  var STARTED = BREAKOUT_ROOMS_STATUSES.STARTED,
      STOPPED = BREAKOUT_ROOMS_STATUSES.STOPPED,
      STARTING = BREAKOUT_ROOMS_STATUSES.STARTING;
  var isBreakoutRoomsStarted = breakoutRoomsStatus === STARTED;
  (0, _react.useEffect)(function () {
    if (previousBreakoutRoomsStatus === STARTED && breakoutRoomsStatus === STOPPED) {
      setWasBreakoutRoomsStopped(true);
    }
  }, [previousBreakoutRoomsStatus, breakoutRoomsStatus, STARTED, STOPPED]);
  (0, _react.useEffect)(function () {
    if (previousBreakoutRoomsStatus === STARTING && breakoutRoomsStatus === STARTED) {
      setWasBreakoutRoomsStarted(true);
    }
  }, [previousBreakoutRoomsStatus, breakoutRoomsStatus, STARTING, STARTED]);
  (0, _react.useEffect)(function () {
    if (mainRoom && wasBreakoutRoomsStarted && breakoutRoomToJoin) {
      setWasBreakoutRoomsStarted(false);
      navigate(_routesConsts.Routes.NETWORKING_TABLE_PAGE, {
        tableId: breakoutRoomToJoin
      });
    }

    if (mainRoom && wasBreakoutRoomsStarted && noSpaceAvailable) {
      setWasBreakoutRoomsStarted(false);
      dispatch((0, _actions.setNoSpaceAvailable)(true));
      navigate(_routesConsts.Routes.NETWORKING_PAGE);
    }
  }, [mainRoom, wasBreakoutRoomsStarted, breakoutRoomToJoin, noSpaceAvailable, navigate, dispatch]);
  (0, _react.useEffect)(function () {
    if (!mainRoom && wasBreakoutRoomsStopped) {
      setWasBreakoutRoomsStopped(false);
      navigate(_routesConsts.Routes.NETWORKING_TABLE_LIVE_PAGE, {
        tableId: mainRoomId
      });
    }
  }, [mainRoom, wasBreakoutRoomsStopped, navigate, mainRoomId]);
  return (0, _lodash.isFunction)(children) ? children(isBreakoutRoomsStarted) : children;
}