var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNoAdsTicketCurrency = exports.getNoAdsTicketPrice = exports.getNoAdsTicketCode = exports.getNoAdsTicketID = exports.getAccessTicketCurrency = exports.getAccessTicketPrice = exports.getAccessTicketCode = exports.getAccessTicketID = exports.getAccessTicket = exports.hasAccessTicket = exports.isFromFestival = exports.getFestival = exports.getFestivalStageName = exports.getFestivalStageId = exports.getFestivalName = exports.getFestivalId = exports.getFestivalStageType = exports.getStatus = exports.getNumFollowers = exports.getEventLineupPriority = exports.getEventPriority = exports.getEventHashtags = exports.getMusicType = exports.getShortDesc = exports.getEventDesc = exports.getAdUrl = exports.getLiveUrl = exports.getFreeWatchTime = exports.getImage = exports.getCoverImage = exports.getDjPicture = exports.getDjName = exports.getDjId = exports.hasEnded = exports.hasStarted = exports.isReplay = exports.getSecondsLeftTillStart = exports.getDjPastEvents = exports.getPastEvents = exports.getStartDate = exports.getUpcomingEvents = exports.hasLoadedUpcomingEvents = exports.getFestivalEndDate = exports.getMyFestivalEvents = exports.getScheduleFestivalEvents = exports.getFestivalLineup = exports.getSimilarEventsByMusicGenres = exports.getEventsByHashtag = exports.getDjUpcomingShows = exports.getEventsBySameDjAndSameMusicType = exports.getLiveEventFromStage = exports.hasLives = exports.getLiveNowEvents = exports.hasLoadedLiveNowEvents = exports.getEvents = exports.selectEventsLoading = exports.getUserEvents = exports.hasLoaded = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _appraisal = _interopRequireDefault(require("../appraisal"));

var _festival = _interopRequireDefault(require("../festival"));

var _flags = _interopRequireDefault(require("../flags"));

var _profile = _interopRequireDefault(require("../profile"));

var userSelectors = _interopRequireWildcard(require("../user/selectors"));

var _constants = require("./constants");

var _toolkit = require("@reduxjs/toolkit");

var checkIfBlockedContent = userSelectors.checkIfBlockedContent;

var selectEventsState = function selectEventsState(state) {
  return state[_constants.NAME];
};

var hasLoaded = function hasLoaded(state) {
  return state[_constants.NAME].size > 0;
};

exports.hasLoaded = hasLoaded;
var getUserEvents = (0, _toolkit.createSelector)(selectEventsState, function (state) {
  return state.get('events');
});
exports.getUserEvents = getUserEvents;
var selectEventsLoading = (0, _toolkit.createSelector)(selectEventsState, function (state) {
  return state.get('loading');
});
exports.selectEventsLoading = selectEventsLoading;

var getEvents = function getEvents(state) {
  var events = state[_constants.NAME].filter(function (element) {
    return element.get('status') === _constants.LIVE_STATUS.SCHEDULED && element.get('startDate') > Date.now() && !checkIfBlockedContent(state, element.getIn(['dj', 'id']).toString()) && getFestivalStageType(state, element.get('id')) !== 'continuous_streaming';
  });

  return _lodash.default.sortBy(events.toArray(), function (element) {
    return element.get('startDate');
  });
};

exports.getEvents = getEvents;

var hasLoadedLiveNowEvents = function hasLoadedLiveNowEvents(state) {
  return getLiveNowEvents(state).size > 0;
};

exports.hasLoadedLiveNowEvents = hasLoadedLiveNowEvents;

var getLiveNowEvents = function getLiveNowEvents(state) {
  return state[_constants.NAME].filter(function (element) {
    return element.get('status') === _constants.LIVE_STATUS.PLAYING && getFestivalStageType(state, element.get('id')) !== 'continuous_streaming' && !checkIfBlockedContent(state, element.getIn(['dj', 'id']).toString());
  });
};

exports.getLiveNowEvents = getLiveNowEvents;

var hasLives = function hasLives(state, stageId) {
  return state[_constants.NAME].filter(function (element) {
    return element.get('status') === _constants.LIVE_STATUS.PLAYING && element.getIn(['festivalStage', 'id']) === stageId;
  }).size > 0 ? 0 : 1;
};

exports.hasLives = hasLives;

var getLiveEventFromStage = function getLiveEventFromStage(state, stageId) {
  return _lodash.default.sortBy(state[_constants.NAME].filter(function (element) {
    return element.getIn(['festivalStage', 'id']) === stageId && (element.get('startDate') > Date.now() || element.get('status') === _constants.LIVE_STATUS.PLAYING) && !checkIfBlockedContent(state, element.getIn(['dj', 'id']).toString());
  }).toArray(), function (event) {
    return event.get('startDate');
  });
};

exports.getLiveEventFromStage = getLiveEventFromStage;

var getEventsBySameDjAndSameMusicType = function getEventsBySameDjAndSameMusicType(state, eventId, djId) {
  var futureDjEvents = state[_constants.NAME].filter(function (element) {
    return getDjId(state, element.get('id')) === djId && element.get('id') !== eventId && getFestivalStageType(state, element.get('id')) !== 'continuous_streaming';
  });

  var musicType = getMusicType(state, eventId);

  if (musicType) {
    var eventsByMusicType = state[_constants.NAME].filter(function (element) {
      return getDjId(state, element.get('id')) !== djId && element.get('id') !== eventId && getMusicType(state, element.get('id')) === musicType && element.get('startDate') > Date.now() && getFestivalStageType(state, element.get('id')) !== 'continuous_streaming';
    });

    return futureDjEvents.concat(eventsByMusicType).toArray().slice(0, 10);
  } else if (futureDjEvents.size !== 0) {
    return futureDjEvents.toArray().slice(0, 10);
  } else {
    return getEvents(state).slice(0, 10);
  }
};

exports.getEventsBySameDjAndSameMusicType = getEventsBySameDjAndSameMusicType;

var getDjUpcomingShows = function getDjUpcomingShows(state, djId) {
  return state[_constants.NAME].filter(function (event) {
    return getDjId(state, event.get('id')) === djId && event.get('status') === _constants.LIVE_STATUS.SCHEDULED && event.get('startDate') > Date.now();
  }).toArray().slice(0, 10);
};

exports.getDjUpcomingShows = getDjUpcomingShows;

var getEventsByHashtag = function getEventsByHashtag(state, hashtag) {
  return state[_constants.NAME].filter(function (event) {
    var musicType = getMusicType(state, event.get('id'));
    return hashtag === musicType && (event.get('status') === _constants.LIVE_STATUS.SCHEDULED || event.get('status') === _constants.LIVE_STATUS.PLAYING) && !checkIfBlockedContent(state, event.getIn(['dj', 'id']).toString());
  });
};

exports.getEventsByHashtag = getEventsByHashtag;

var getSimilarEventsByMusicGenres = function getSimilarEventsByMusicGenres(state, djId) {
  var hashtags = _profile.default.selectors.getHashtags(state, djId);

  return state[_constants.NAME].filter(function (event) {
    var musicType = getMusicType(state, event.get('id'));

    if (hashtags) {
      return hashtags.indexOf(musicType) > -1 && event.get('startDate') > Date.now() && !checkIfBlockedContent(state, event.getIn(['dj', 'id']).toString());
    }

    return false;
  }).toArray().slice(0, 10);
};

exports.getSimilarEventsByMusicGenres = getSimilarEventsByMusicGenres;

var getFestivalLineup = function getFestivalLineup(state, festivalId) {
  var festivalEvents = state[_constants.NAME].filter(function (element) {
    return getFestivalId(state, element.get('id')) === festivalId && getFestivalStageType(state, element.get('id')) !== 'continuous_streaming' && !checkIfBlockedContent(state, element.getIn(['dj', 'id']).toString());
  });

  var sortedByDateFestivalEvents = _lodash.default.sortBy(festivalEvents.toArray(), function (element) {
    return element.get('startDate');
  }).reverse();

  return _lodash.default.sortBy(sortedByDateFestivalEvents, function (event) {
    return getEventLineupPriority(state, event.get('id'));
  }).reverse();
};

exports.getFestivalLineup = getFestivalLineup;

var getScheduleFestivalEvents = function getScheduleFestivalEvents(state, startDate, festivalId) {
  var eventStartDate = (0, _moment.default)(startDate).format('DD/MM/YYYY');

  var scheduledEventsForDate = state[_constants.NAME].filter(function (element) {
    var currentEventStartDate = (0, _moment.default)(getStartDate(state, element.get('id'))).format('DD/MM/YYYY');
    return currentEventStartDate === eventStartDate && getFestivalId(state, element.get('id')) === festivalId && getFestivalStageType(state, element.get('id')) !== 'continuous_streaming' && !checkIfBlockedContent(state, element.getIn(['dj', 'id']).toString());
  });

  return _lodash.default.sortBy(scheduledEventsForDate.toArray(), function (element) {
    return element.get('startDate');
  });
};

exports.getScheduleFestivalEvents = getScheduleFestivalEvents;

var getMyFestivalEvents = function getMyFestivalEvents(state, startDate, festivalId) {
  var eventStartDate = (0, _moment.default)(startDate).format('DD/MM/YYYY');

  var myFestivalEvents = state[_constants.NAME].filter(function (element) {
    var currentEventStartDate = (0, _moment.default)(getStartDate(state, element.get('id'))).format('DD/MM/YYYY');
    return _appraisal.default.selectors.isFollowing(state, element.getIn(['dj', 'id'])) && currentEventStartDate === eventStartDate && getFestivalId(state, element.get('id')) === festivalId && !checkIfBlockedContent(state, element.getIn(['dj', 'id']).toString()) && getFestivalStageType(state, element.get('id')) !== 'continuous_streaming';
  });

  return _lodash.default.sortBy(myFestivalEvents.toArray(), function (element) {
    return element.get('startDate');
  }).reverse();
};

exports.getMyFestivalEvents = getMyFestivalEvents;

var getFestivalEndDate = function getFestivalEndDate(state, festivalId) {
  var events = state[_constants.NAME].filter(function (element) {
    return getFestivalId(state, element.get('id')) === festivalId;
  });

  if (events.size === 0) {
    return _festival.default.selectors.getEndDate(state, festivalId);
  }

  var sorted = _lodash.default.sortBy(events.toArray(), function (element) {
    return element.get('startDate');
  });

  return sorted[sorted.length - 1].get('startDate');
};

exports.getFestivalEndDate = getFestivalEndDate;

var hasLoadedUpcomingEvents = function hasLoadedUpcomingEvents(state) {
  return getUpcomingEvents(state).length > 0;
};

exports.hasLoadedUpcomingEvents = hasLoadedUpcomingEvents;

var getUpcomingEvents = function getUpcomingEvents(state) {
  var upcomingList = [];

  var following = state[_constants.NAME].filter(function (event) {
    return _appraisal.default.selectors.isFollowing(state, event.getIn(['dj', 'id'])) && event.get('status') === _constants.LIVE_STATUS.SCHEDULED && event.get('startDate') > Date.now() && !checkIfBlockedContent(state, event.getIn(['dj', 'id']).toString()) && getFestivalStageType(state, event.get('id')) !== 'continuous_streaming';
  }).toArray();

  if (following.length < 11) {
    var list = state[_constants.NAME].filter(function (event) {
      var isFollowing = _appraisal.default.selectors.isFollowing(state, event.getIn(['dj', 'id']));

      return event.get('priority') >= 0 && !isFollowing && event.get('status') === _constants.LIVE_STATUS.SCHEDULED && event.get('startDate') > Date.now() && getFestivalStageType(state, event.get('id')) !== 'continuous_streaming';
    }).toArray().slice(0, 11 - following.length);

    upcomingList = following.concat(list);
  } else {
    upcomingList = following.slice(0, 11);
  }

  return _lodash.default.sortBy(upcomingList, function (element) {
    return element.get('startDate');
  });
};

exports.getUpcomingEvents = getUpcomingEvents;

var getStartDate = function getStartDate(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'startDate']);
};

exports.getStartDate = getStartDate;

var getPastEvents = function getPastEvents(state) {
  var events = state[_constants.NAME].filter(function (element) {
    return element.get('replay') && !checkIfBlockedContent(state, element.getIn(['dj', 'id']).toString());
  }).toArray();

  var uniqueEventsByDj = _lodash.default.uniqBy(events, function (event) {
    return event.getIn(['dj', 'id']);
  });

  return uniqueEventsByDj;
};

exports.getPastEvents = getPastEvents;

var getDjPastEvents = function getDjPastEvents(state, djId) {
  var events = state[_constants.NAME].filter(function (element) {
    return element.get('replay') && element.getIn(['dj', 'id']).toString() === djId;
  }).toArray();

  return events;
};

exports.getDjPastEvents = getDjPastEvents;

var getSecondsLeftTillStart = function getSecondsLeftTillStart(state, liveId) {
  var startDate = state[_constants.NAME].getIn([liveId, 'startDate']);

  if (!startDate) {
    return 0;
  }

  return Math.floor((startDate.getTime() - Date.now()) / 1000);
};

exports.getSecondsLeftTillStart = getSecondsLeftTillStart;

var isReplay = function isReplay(state, liveId) {
  return !!state[_constants.NAME].getIn([liveId, 'replay']);
};

exports.isReplay = isReplay;

var hasStarted = function hasStarted(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'status'], _constants.LIVE_STATUS.SCHEDULED) === _constants.LIVE_STATUS.PLAYING;
};

exports.hasStarted = hasStarted;

var hasEnded = function hasEnded(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'status'], _constants.LIVE_STATUS.SCHEDULED) === _constants.LIVE_STATUS.ENDED;
};

exports.hasEnded = hasEnded;

var getDjId = function getDjId(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'dj', 'id']);
};

exports.getDjId = getDjId;

var getDjName = function getDjName(state, liveId) {
  return _profile.default.selectors.getUserName(state, getDjId(state, liveId));
};

exports.getDjName = getDjName;

var getDjPicture = function getDjPicture(state, liveId) {
  return _profile.default.selectors.getUserPicture(state, getDjId(state, liveId));
};

exports.getDjPicture = getDjPicture;

var getCoverImage = function getCoverImage(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'coverImage', 'contentUrl']);
};

exports.getCoverImage = getCoverImage;

var getImage = function getImage(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'image', 'contentUrl']);
};

exports.getImage = getImage;

var getFreeWatchTime = function getFreeWatchTime(state, liveId) {
  var flag = _flags.default.selectors.disablePaymentRegionFestivalFlag(state);

  var festivalId = getFestivalId(state, liveId);

  if (festivalId === undefined) {
    return state[_constants.NAME].getIn([liveId, 'freeWatchTime']);
  }

  if (flag === false && festivalId === 35) {
    return 300;
  }

  return state[_constants.NAME].getIn([liveId, 'freeWatchTime']);
};

exports.getFreeWatchTime = getFreeWatchTime;

var getLiveUrl = function getLiveUrl(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'liveUrl']);
};

exports.getLiveUrl = getLiveUrl;

var getAdUrl = function getAdUrl(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'adUrl']);
};

exports.getAdUrl = getAdUrl;

var getEventDesc = function getEventDesc(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'description']);
};

exports.getEventDesc = getEventDesc;

var getShortDesc = function getShortDesc(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'shortDescription']);
};

exports.getShortDesc = getShortDesc;

var getMusicType = function getMusicType(state, eventId) {
  var hashTags = state[_constants.NAME].getIn([eventId, 'hashTags']);

  if (hashTags) {
    return hashTags.toArray().pop();
  } else {
    return undefined;
  }
};

exports.getMusicType = getMusicType;

var getEventHashtags = function getEventHashtags(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'hashTags']);
};

exports.getEventHashtags = getEventHashtags;

var getEventPriority = function getEventPriority(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'priority'], 0);
};

exports.getEventPriority = getEventPriority;

var getEventLineupPriority = function getEventLineupPriority(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'lineupPriority'], 0);
};

exports.getEventLineupPriority = getEventLineupPriority;

var getNumFollowers = function getNumFollowers(state, liveId) {
  return _profile.default.selectors.getNumFollowers(state, getDjId(state, liveId));
};

exports.getNumFollowers = getNumFollowers;

var getStatus = function getStatus(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'status']);
};

exports.getStatus = getStatus;

var getFestivalStageType = function getFestivalStageType(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'festivalStage', 'type']);
};

exports.getFestivalStageType = getFestivalStageType;

var getFestivalId = function getFestivalId(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'festival', 'id']);
};

exports.getFestivalId = getFestivalId;

var getFestivalName = function getFestivalName(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'festival', 'name']);
};

exports.getFestivalName = getFestivalName;

var getFestivalStageId = function getFestivalStageId(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'festivalStage', 'id']);
};

exports.getFestivalStageId = getFestivalStageId;

var getFestivalStageName = function getFestivalStageName(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'festivalStage', 'name']);
};

exports.getFestivalStageName = getFestivalStageName;

var getFestival = function getFestival(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'festival']);
};

exports.getFestival = getFestival;

var isFromFestival = function isFromFestival(state, liveId) {
  return getFestivalId(state, liveId) !== undefined;
};

exports.isFromFestival = isFromFestival;

var hasAccessTicket = function hasAccessTicket(state, liveId) {
  var FREE_TICKET_CODE = 'FREE';
  return getAccessTicketCode(state, liveId) !== FREE_TICKET_CODE && getAccessTicket(state, liveId) != null;
};

exports.hasAccessTicket = hasAccessTicket;

var getAccessTicket = function getAccessTicket(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'accessTicket']);
};

exports.getAccessTicket = getAccessTicket;

var getAccessTicketID = function getAccessTicketID(state, liveId) {
  var flag = _flags.default.selectors.disablePaymentRegionFestivalFlag(state);

  var festivalId = getFestivalId(state, liveId);

  if (festivalId === undefined) {
    return state[_constants.NAME].getIn([liveId, 'accessTicket', 'id']);
  }

  if (flag === false && festivalId === 35) {
    return 3;
  }

  return state[_constants.NAME].getIn([liveId, 'accessTicket', 'id']);
};

exports.getAccessTicketID = getAccessTicketID;

var getAccessTicketCode = function getAccessTicketCode(state, liveId) {
  var flag = _flags.default.selectors.disablePaymentRegionFestivalFlag(state);

  var festivalId = getFestivalId(state, liveId);

  if (festivalId === undefined) {
    return state[_constants.NAME].getIn([liveId, 'accessTicket', 'code']);
  }

  if (flag === false && festivalId === 35) {
    return 'ec-premium-apple';
  }

  return state[_constants.NAME].getIn([liveId, 'accessTicket', 'code']);
};

exports.getAccessTicketCode = getAccessTicketCode;

var getAccessTicketPrice = function getAccessTicketPrice(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'accessTicket', 'price']);
};

exports.getAccessTicketPrice = getAccessTicketPrice;

var getAccessTicketCurrency = function getAccessTicketCurrency(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'accessTicket', 'currency']);
};

exports.getAccessTicketCurrency = getAccessTicketCurrency;

var getNoAdsTicketID = function getNoAdsTicketID(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'noAdsTicket', 'id']);
};

exports.getNoAdsTicketID = getNoAdsTicketID;

var getNoAdsTicketCode = function getNoAdsTicketCode(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'noAdsTicket', 'code']);
};

exports.getNoAdsTicketCode = getNoAdsTicketCode;

var getNoAdsTicketPrice = function getNoAdsTicketPrice(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'noAdsTicket', 'price']);
};

exports.getNoAdsTicketPrice = getNoAdsTicketPrice;

var getNoAdsTicketCurrency = function getNoAdsTicketCurrency(state, liveId) {
  return state[_constants.NAME].getIn([liveId, 'noAdsTicket', 'currency']);
};

exports.getNoAdsTicketCurrency = getNoAdsTicketCurrency;