var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LeaderboardAboutScreen = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _gameSparks = _interopRequireDefault(require("../../../core/gameSparks"));

var _NavigationButtons = require("../../../navigation/NavigationButtons");

var _Paragraph = _interopRequireDefault(require("../../common/typography_v1/Paragraph"));

var _Title = _interopRequireDefault(require("../../common/typography_v1/Title"));

var _Headline = _interopRequireDefault(require("../../common/typography_v1/Headline"));

var _aboutScreenStyles = _interopRequireDefault(require("./about-screen-styles"));

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

var _immutable = require("immutable");

var _LoadingScreen = _interopRequireDefault(require("../../LoadingScreen"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var TITLE = 'Introducing Contests';

var LeaderboardAboutScreen = function (_React$PureComponent) {
  (0, _inherits2.default)(LeaderboardAboutScreen, _React$PureComponent);

  var _super = _createSuper(LeaderboardAboutScreen);

  function LeaderboardAboutScreen() {
    (0, _classCallCheck2.default)(this, LeaderboardAboutScreen);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(LeaderboardAboutScreen, [{
    key: "render",
    value: function render() {
      var _this$props$learnMore = this.props.learnMoreScreen,
          learnMoreScreen = _this$props$learnMore === void 0 ? (0, _immutable.Map)() : _this$props$learnMore;
      var headline = learnMoreScreen.get('headline');
      var contentMap = learnMoreScreen.get('content');
      var iosDisclaimer = learnMoreScreen.get('iosDisclaimer');
      var content = [];

      if (contentMap && contentMap.size) {
        content = contentMap.toArray();
      }

      return !this.props.learnMoreScreen ? React.createElement(_LoadingScreen.default, null) : React.createElement(_reactNative.View, {
        style: _aboutScreenStyles.default.container
      }, React.createElement(_reactNativeSafeAreaView.default, {
        style: [_aboutScreenStyles.default.container],
        forceInset: {
          top: 'always',
          bottom: 'never',
          horizontal: 'always'
        }
      }, React.createElement(_reactNative.ScrollView, {
        contentContainerStyle: _aboutScreenStyles.default.contentContainerStyle
      }, React.createElement(_reactNative.View, {
        style: _aboutScreenStyles.default.content
      }, React.createElement(_Headline.default, {
        style: _aboutScreenStyles.default.headline
      }, headline), content.map(function (item, index) {
        var title = item.get('title');
        var paragraphs = item.get('paragraphs');
        return paragraphs && paragraphs.size && paragraphs.size > 0 ? React.createElement(React.Fragment, {
          key: index
        }, React.createElement(_Title.default, {
          style: _aboutScreenStyles.default.title
        }, title), paragraphs.map(function (p, i) {
          return React.createElement(_Paragraph.default, {
            key: "p - ".concat(i),
            style: _aboutScreenStyles.default.paragraph
          }, p);
        })) : null;
      }), iosDisclaimer && _reactNative.Platform.OS === 'ios' ? React.createElement(React.Fragment, null, React.createElement(_Title.default, {
        style: _aboutScreenStyles.default.title
      }, iosDisclaimer.get('title')), React.createElement(_Paragraph.default, {
        style: _aboutScreenStyles.default.paragraph
      }, iosDisclaimer.get('paragraph'))) : null))));
    }
  }]);
  return LeaderboardAboutScreen;
}(React.PureComponent);

exports.LeaderboardAboutScreen = LeaderboardAboutScreen;

LeaderboardAboutScreen.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  return {
    headerLeft: React.createElement(_reactNative.View, {
      style: _aboutScreenStyles.default.headerLeft
    }, React.createElement(_reactNative.Text, {
      style: _aboutScreenStyles.default.headerLeftText
    }, TITLE)),
    headerRight: (0, _NavigationButtons.xButton)(navigation),
    headerTransparent: true,
    headerStyle: _aboutScreenStyles.default.headerStyle
  };
};

function mapStateToProps(state) {
  return {
    learnMoreScreen: _gameSparks.default.selectors.getLeaderboarLearnMore(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(LeaderboardAboutScreen);

exports.default = _default;