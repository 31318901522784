var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RenderRowEventInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _events = _interopRequireDefault(require("../../../../core/events"));

var _reactNavigation = require("react-navigation");

var _Paragraph = _interopRequireDefault(require("../../../common/typography_v1/Paragraph"));

var _FollowUserButton = _interopRequireDefault(require("../../../common/follow/FollowUserButton"));

var _SmartDate = _interopRequireDefault(require("../../../common/SmartDate"));

var _CustomCachedImage = _interopRequireDefault(require("../../../common/CustomCachedImage"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var RenderRowEventInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(RenderRowEventInternal, _React$PureComponent);

  var _super = _createSuper(RenderRowEventInternal);

  function RenderRowEventInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, RenderRowEventInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      screenWidth: 0,
      stages: null
    };
    return _this;
  }

  (0, _createClass2.default)(RenderRowEventInternal, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      var date = new Date(this.props.startDate);
      var smallDeviceWidth = this.props.screenWidth < 375 ? true : false;
      var itemWidth = smallDeviceWidth ? 288 : 300;
      var imageWidth = smallDeviceWidth ? 36 : 48;
      return React.createElement(_reactNative.View, {
        style: [styles.rowItem, {
          width: itemWidth
        }],
        key: 'eventView' + this.props.eventId
      }, React.createElement(_reactNative.TouchableHighlight, {
        testID: 'TapImageLiveNow' + this.props.eventId,
        onPress: function onPress() {
          return _this2.props.navigation.push('EventDetail', {
            eventId: _this2.props.eventId
          });
        }
      }, React.createElement(_CustomCachedImage.default, {
        style: [styles.itemImageSmall, {
          width: imageWidth
        }],
        source: {
          uri: this.props.coverImage
        },
        resizeMode: "cover"
      })), React.createElement(_reactNative.View, {
        style: styles.eventDetailsShort
      }, React.createElement(_reactNative.View, {
        style: styles.itemTitleSection
      }, React.createElement(_reactNativePaper.TouchableRipple, {
        testID: 'TapEvent' + this.props.eventId,
        onPress: function onPress() {
          return _this2.props.navigation.push('EventDetail', {
            eventId: _this2.props.eventId
          });
        }
      }, React.createElement(_Paragraph.default, {
        numberOfLines: 1,
        ellipsizeMode: "tail",
        style: styles.itemTitle
      }, this.props.djName)), React.createElement(_SmartDate.default, {
        date: date,
        displayDirection: "row",
        fontSize: 13,
        color: "rgba(255,255,255,0.5)",
        style: {
          lineHeight: 13
        }
      })), React.createElement(_reactNative.View, {
        style: {}
      }, React.createElement(_FollowUserButton.default, {
        id: this.props.djId,
        eventId: this.props.eventId,
        buttonSize: 'small',
        isFixedButton: true,
        style: {
          width: 76
        }
      }))));
    }
  }]);
  return RenderRowEventInternal;
}(React.PureComponent);

exports.RenderRowEventInternal = RenderRowEventInternal;

var styles = _reactNative.StyleSheet.create({
  itemImageSmall: {
    aspectRatio: 1 / 1,
    width: 48,
    minHeight: 48,
    marginRight: 16
  },
  eventDetailsShort: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255, 255, 255, 0.15)',
    width: 236,
    paddingTop: 12,
    paddingBottom: 10
  },
  itemTitleSection: {
    maxWidth: 130,
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  rowItem: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    justifyContent: 'space-between'
  },
  itemTitle: {
    lineHeight: 15,
    marginBottom: 4
  },
  itemPeriod: {
    color: 'rgba(255,255,255,0.6)',
    lineHeight: 13
  }
});

function mapStateToProps(state, ownProps) {
  var eventId = ownProps.eventId;
  return {
    coverImage: _events.default.selectors.getCoverImage(state, eventId),
    startDate: _events.default.selectors.getStartDate(state, eventId),
    djId: _events.default.selectors.getDjId(state, eventId),
    djName: _events.default.selectors.getDjName(state, eventId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(RenderRowEventInternal)));

exports.default = _default;