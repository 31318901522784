var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAudioVideoContext = useAudioVideoContext;
exports.default = exports.AudioVideoContext = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _agoraRtcSdkNg = _interopRequireDefault(require("agora-rtc-sdk-ng"));

var _useAgoraLocalTracks = require("../../../utils/hooks/useAgoraLocalTracks");

var _useScreenShareToggle2 = _interopRequireDefault(require("../../../utils/hooks/useScreenShareToggle"));

var _useSafeState11 = _interopRequireDefault(require("../../../utils/hooks/useSafeState"));

var _useTrackControl3 = _interopRequireDefault(require("../../../utils/hooks/useTrackControl"));

var _agoraConstants = require("../../../constants/agora-constants");

var _logger = _interopRequireDefault(require("../../../core/services/logger"));

var _useEmitEvent = _interopRequireDefault(require("../../../utils/hooks/useEmitEvent"));

var AudioVideoContext = _react.default.createContext(null);

exports.AudioVideoContext = AudioVideoContext;

function AudioVideoProvider(_ref) {
  var children = _ref.children;

  var _useSafeState = (0, _useSafeState11.default)(_react.default.useState(null)),
      _useSafeState2 = (0, _slicedToArray2.default)(_useSafeState, 2),
      error = _useSafeState2[0],
      setError = _useSafeState2[1];

  var _useSafeState3 = (0, _useSafeState11.default)(_react.default.useState(false)),
      _useSafeState4 = (0, _slicedToArray2.default)(_useSafeState3, 2),
      loading = _useSafeState4[0],
      setLoading = _useSafeState4[1];

  var _useSafeState5 = (0, _useSafeState11.default)(_react.default.useState(function () {
    return localStorage.getItem(_agoraConstants.DEFAULT_AUDIO_OUTPUT_DEVICE);
  })),
      _useSafeState6 = (0, _slicedToArray2.default)(_useSafeState5, 2),
      sinkId = _useSafeState6[0],
      setSinkId = _useSafeState6[1];

  var _useSafeState7 = (0, _useSafeState11.default)(_react.default.useState(false)),
      _useSafeState8 = (0, _slicedToArray2.default)(_useSafeState7, 2),
      isRequestingAccess = _useSafeState8[0],
      setIsRequestingAccess = _useSafeState8[1];

  var setAudioOutput = _react.default.useCallback(function (deviceId) {
    return setSinkId(deviceId);
  }, [setSinkId]);

  var client = _react.default.useMemo(function () {
    var _client = _agoraRtcSdkNg.default.createClient({
      codec: 'vp8',
      mode: 'live'
    });

    _client.setLowStreamParameter({
      height: 240,
      width: 360,
      framerate: 15
    });

    return _client;
  }, []);

  var emit = (0, _useEmitEvent.default)(_agoraConstants.subscriptionObserver);

  var _useSafeState9 = (0, _useSafeState11.default)(_react.default.useState(false)),
      _useSafeState10 = (0, _slicedToArray2.default)(_useSafeState9, 2),
      settingsDialogOpen = _useSafeState10[0],
      setSettingsDialogOpen = _useSafeState10[1];

  var _useLocalAudioTrack = (0, _useAgoraLocalTracks.useLocalAudioTrack)(setError),
      audioTrack = _useLocalAudioTrack.audioTrack,
      getLocalAudioTrack = _useLocalAudioTrack.getLocalAudioTrack,
      audioHasError = _useLocalAudioTrack.hasQueriedError,
      audioIsQuerying = _useLocalAudioTrack.isQuerying;

  var _useLocalVideoTrack = (0, _useAgoraLocalTracks.useLocalVideoTrack)(setError),
      videoTrack = _useLocalVideoTrack.videoTrack,
      getLocalVideoTrack = _useLocalVideoTrack.getLocalVideoTrack,
      videoHasError = _useLocalVideoTrack.hasQueriedError,
      videoIsQuerying = _useLocalVideoTrack.isQuerying;

  var _useScreenShareToggle = (0, _useScreenShareToggle2.default)(setError),
      hasScreenShare = _useScreenShareToggle.hasScreenShare,
      screenShareLoading = _useScreenShareToggle.screenShareLoading,
      screenShareVideo = _useScreenShareToggle.screenShareVideo,
      screenShareAudio = _useScreenShareToggle.screenShareAudio,
      startScreenShare = _useScreenShareToggle.startScreenShare,
      stopScreenShare = _useScreenShareToggle.stopScreenShare;

  var _useTrackControl = (0, _useTrackControl3.default)(audioTrack, client),
      disableAudio = _useTrackControl.disableTrack,
      enableAudio = _useTrackControl.enableTrack,
      toggleAudio = _useTrackControl.toggleTrack,
      audioEnabled = _useTrackControl.enabled,
      audioOnPublish = _useTrackControl.onPublish;

  var _useTrackControl2 = (0, _useTrackControl3.default)(videoTrack, client),
      disableVideo = _useTrackControl2.disableTrack,
      enableVideo = _useTrackControl2.enableTrack,
      toggleVideo = _useTrackControl2.toggleTrack,
      videoEnabled = _useTrackControl2.enabled,
      videoOnPublish = _useTrackControl2.onPublish;

  var isQuerying = _react.default.useMemo(function () {
    return videoIsQuerying || audioIsQuerying;
  }, [videoIsQuerying, audioIsQuerying]);

  _react.default.useEffect(function () {
    getLocalAudioTrack();
    getLocalVideoTrack();
  }, [client, getLocalAudioTrack, getLocalVideoTrack]);

  var join = _react.default.useCallback(function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(room, token, uid) {
      return _regenerator.default.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              setLoading(true);
              setError(null);
              client.startProxyServer(3);
              _context2.next = 5;
              return client.setClientRole('host');

            case 5:
              _context2.prev = 5;
              _context2.next = 8;
              return client.enableDualStream();

            case 8:
              _context2.next = 13;
              break;

            case 10:
              _context2.prev = 10;
              _context2.t0 = _context2["catch"](5);

              _logger.default.info("Dual stream error! ".concat(_context2.t0.code, ", ").concat(_context2.t0.message));

            case 13:
              client.join(process.env.AGORA_APP_ID, room, token, uid).then((0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
                var tracks;
                return _regenerator.default.wrap(function _callee$(_context) {
                  while (1) {
                    switch (_context.prev = _context.next) {
                      case 0:
                        tracks = [];

                        if (audioTrack && audioEnabled) {
                          tracks.push(audioTrack);
                          audioOnPublish(true);
                        }

                        if (videoTrack && videoEnabled) {
                          tracks.push(videoTrack);
                          videoOnPublish(true);
                        }

                        if (!tracks.length) {
                          _context.next = 6;
                          break;
                        }

                        _context.next = 6;
                        return client.publish(tracks);

                      case 6:
                        if (isRequestingAccess) {
                          setIsRequestingAccess(false);
                        }

                      case 7:
                      case "end":
                        return _context.stop();
                    }
                  }
                }, _callee);
              }))).catch(setError).finally(function () {
                return setLoading(false);
              });

            case 14:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2, null, [[5, 10]]);
    }));

    return function (_x, _x2, _x3) {
      return _ref2.apply(this, arguments);
    };
  }(), [setLoading, setError, videoTrack, audioTrack, client, audioEnabled, videoEnabled, videoOnPublish, audioOnPublish]);

  var requestAccess = _react.default.useCallback(function () {
    setIsRequestingAccess(true);
  }, [setIsRequestingAccess]);

  var clearRequestAccess = _react.default.useCallback(function () {
    setIsRequestingAccess(false);
  }, [setIsRequestingAccess]);

  var leaveAndRemoveTracks = _react.default.useCallback((0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3() {
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            stopScreenShare();
            _context3.next = 3;
            return client.unpublish();

          case 3:
            audioOnPublish(false);
            videoOnPublish(false);

            if (videoTrack) {
              videoTrack.stop();
              videoTrack.close();
            }

            if (audioTrack) {
              audioTrack.stop();
              audioTrack.close();
            }

            if (isRequestingAccess) {
              setIsRequestingAccess(false);
            }

            emit({
              type: _agoraConstants.UNSUBSCRIBE_ALL_EVENT,
              origin: '[AudioVideoProvider]'
            });
            _context3.next = 11;
            return client.leave();

          case 11:
            client.stopProxyServer();

          case 12:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  })), [client, videoTrack, audioTrack, audioOnPublish, videoOnPublish, stopScreenShare, emit]);

  var removeTracks = _react.default.useCallback((0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4() {
    return _regenerator.default.wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            if (videoTrack) {
              videoTrack.stop();
              videoTrack.close();
            }

            if (audioTrack) {
              audioTrack.stop();
              audioTrack.close();
            }

          case 2:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4);
  })), [videoTrack, audioTrack]);

  var leave = _react.default.useCallback((0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5() {
    return _regenerator.default.wrap(function _callee5$(_context5) {
      while (1) {
        switch (_context5.prev = _context5.next) {
          case 0:
            _context5.next = 2;
            return stopScreenShare();

          case 2:
            _context5.next = 4;
            return client.unpublish();

          case 4:
            emit({
              type: _agoraConstants.UNSUBSCRIBE_ALL_EVENT,
              origin: '[AudioVideoProvider]'
            });
            _context5.next = 7;
            return client.leave();

          case 7:
            client.stopProxyServer();

          case 8:
          case "end":
            return _context5.stop();
        }
      }
    }, _callee5);
  })), [client, stopScreenShare, emit]);

  return _react.default.createElement(AudioVideoContext.Provider, {
    value: {
      settingsDialogOpen: settingsDialogOpen,
      setSettingsDialogOpen: setSettingsDialogOpen,
      videoEnabled: videoEnabled,
      toggleVideo: toggleVideo,
      audioEnabled: audioEnabled,
      toggleAudio: toggleAudio,
      getLocalAudioTrack: getLocalAudioTrack,
      getLocalVideoTrack: getLocalVideoTrack,
      join: join,
      leave: leave,
      leaveAndRemoveTracks: leaveAndRemoveTracks,
      removeTracks: removeTracks,
      setAudioOutput: setAudioOutput,
      sinkId: sinkId,
      loading: loading,
      error: error,
      client: client,
      audioTrack: audioTrack,
      videoTrack: videoTrack,
      isQuerying: isQuerying,
      audioHasError: audioHasError,
      videoHasError: videoHasError,
      hasScreenShare: hasScreenShare,
      screenShareLoading: screenShareLoading,
      screenShareVideo: screenShareVideo,
      screenShareAudio: screenShareAudio,
      startScreenShare: startScreenShare,
      stopScreenShare: stopScreenShare,
      enableVideo: enableVideo,
      disableVideo: disableVideo,
      enableAudio: enableAudio,
      disableAudio: disableAudio,
      requestAccess: requestAccess,
      isRequestingAccess: isRequestingAccess,
      clearRequestAccess: clearRequestAccess
    }
  }, children);
}

var _default = AudioVideoProvider;
exports.default = _default;

function useAudioVideoContext() {
  var context = _react.default.useContext(AudioVideoContext);

  return context;
}