var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EmailLoginView;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _emailLoginStyles = _interopRequireDefault(require("./emailLogin-styles"));

var _reactNativePaperV = require("react-native-paper-v3");

var _horizontalWhiteNewLogo = _interopRequireDefault(require("../../common/logo/horizontalWhiteNewLogo.png"));

var _NavigationButtons = require("../../../navigation/NavigationButtons");

var _button = _interopRequireDefault(require("design-system/actions/button"));

var _section = _interopRequireDefault(require("design-system/structure/section"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

var _theme = _interopRequireDefault(require("../../../../design-system/theme"));

function EmailLoginView(props) {
  var colors = _theme.default.colors;
  var onGoBack = props.onGoBack,
      onEmailAuth = props.onEmailAuth,
      loading = props.loading,
      onForgotPassword = props.onForgotPassword;

  var _useState = (0, _react.useState)(''),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      email = _useState2[0],
      setEmail = _useState2[1];

  var _useState3 = (0, _react.useState)(''),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      password = _useState4[0],
      setPassword = _useState4[1];

  var handleEmail = (0, _react.useCallback)(function (text) {
    setEmail(text);
  }, []);
  var handlePassword = (0, _react.useCallback)(function (text) {
    setPassword(text);
  }, []);
  var handleOnPress = (0, _react.useCallback)(function () {
    onEmailAuth(email, password);
  }, [email, password, onEmailAuth]);
  var handleOnPressForgotPassword = (0, _react.useCallback)(function () {
    return onForgotPassword();
  }, [onForgotPassword]);
  return _react.default.createElement(_react.default.Fragment, null, (0, _NavigationButtons.backButtonWithCustomAction)(onGoBack), _react.default.createElement(_reactNative.View, {
    style: _emailLoginStyles.default.emailContainer,
    testID: "EmailContainer"
  }, _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, _react.default.createElement(_reactNative.Image, {
    source: _horizontalWhiteNewLogo.default,
    style: _emailLoginStyles.default.logo,
    resizeMode: 'contain'
  })), _react.default.createElement(_section.default, {
    spacingHorizontal: "extraLoose"
  }, _react.default.createElement(_section.default, {
    spacingVertical: "loose",
    horizontal: true
  }, _react.default.createElement(_reactNativePaperV.TextInput, {
    testID: "email",
    mode: "flat",
    placeholder: "Email Address",
    underlineColor: "rgba(255,255,255,0.2)",
    returnKeyType: 'done',
    theme: {
      colors: {
        placeholder: 'rgba(188, 192, 195, 1)',
        text: 'black',
        primary: colors.black,
        background: 'white'
      }
    },
    autoCapitalize: "none",
    value: email,
    style: _emailLoginStyles.default.textInput,
    keyboardType: "email-address",
    onChangeText: handleEmail
  })), _react.default.createElement(_section.default, {
    spacingVertical: "loose"
  }, _react.default.createElement(_reactNativePaperV.TextInput, {
    testID: "password",
    mode: "flat",
    placeholder: "Password",
    underlineColor: "rgba(255,255,255,0.2)",
    returnKeyType: 'done',
    theme: {
      colors: {
        placeholder: 'rgba(188, 192, 195, 1)',
        text: 'black',
        primary: colors.black,
        background: 'white'
      }
    },
    style: _emailLoginStyles.default.textInput,
    value: password,
    secureTextEntry: true,
    onChangeText: handlePassword
  })), _react.default.createElement(_section.default, {
    spacingVertical: "loose"
  }, _react.default.createElement(_button.default, {
    testID: "EmailAuthButton",
    style: _emailLoginStyles.default.signIn,
    loading: loading,
    size: "large",
    onPress: handleOnPress
  }, "Sign in")), _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, _react.default.createElement(_section.default, {
    spacingVertical: "baseTight"
  }, _react.default.createElement(_reactNativePaperV.TouchableRipple, {
    testID: "forgotPasswordTouchable",
    name: 'forgotPassword',
    disabled: loading,
    onPress: handleOnPressForgotPassword
  }, _react.default.createElement(_reactNativePaperV.Paragraph, {
    style: _emailLoginStyles.default.forgotPassword
  }, "Forgot password?"))))), _react.default.createElement(_section.default, null), _react.default.createElement(_section.default, null)));
}