var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _mybeatsScreenView = _interopRequireDefault(require("./mybeats-screen-view"));

var _mybeatsInfoScreenView = _interopRequireDefault(require("./mybeats-info-screen-view"));

var _gameSparks = _interopRequireDefault(require("../../core/gameSparks"));

function MyBeatsScreenContainer(props) {
  var MY_BEATS_SCREEN_PROPERTIES = _gameSparks.default.constants.MY_BEATS_SCREEN_PROPERTIES;
  var loadGsPlayer = props.loadGsPlayer,
      getGsPropertySet = props.getGsPropertySet,
      isDj = props.isDj;
  return _react.default.createElement(_react.default.Fragment, null, isDj ? _react.default.createElement(_mybeatsScreenView.default, props) : _react.default.createElement(_mybeatsInfoScreenView.default, null));
}

var _default = MyBeatsScreenContainer;
exports.default = _default;