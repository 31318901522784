var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _DesignSystem = require("../../DesignSystem");

var _Spacing = _interopRequireDefault(require("../spacing/Spacing"));

var _core = require("@material-ui/core");

var _styles = require("@material-ui/core/styles");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var CustomBox = (0, _styles.styled)('div')(function (_ref) {
  var theme = _ref.theme,
      activecolor = _ref.activecolor,
      _ref$left = _ref.left,
      left = _ref$left === void 0 ? 'auto' : _ref$left,
      _ref$right = _ref.right,
      right = _ref$right === void 0 ? 'auto' : _ref$right;
  return {
    display: 'flex',
    position: 'absolute',
    color: theme.palette[activecolor].main,
    left: left,
    right: right
  };
});

function Select(_ref2) {
  var error = _ref2.error,
      _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? 'pageText' : _ref2$color,
      _ref2$dense = _ref2.dense,
      dense = _ref2$dense === void 0 ? false : _ref2$dense,
      value = _ref2.value,
      leftIcon = _ref2.leftIcon,
      rightIcon = _ref2.rightIcon,
      disabled = _ref2.disabled,
      shaped = _ref2.shaped,
      name = _ref2.name,
      _ref2$options = _ref2.options,
      options = _ref2$options === void 0 ? [] : _ref2$options,
      _ref2$style = _ref2.style,
      style = _ref2$style === void 0 ? {} : _ref2$style,
      _ref2$containerStyle = _ref2.containerStyle,
      containerStyle = _ref2$containerStyle === void 0 ? {
    position: 'relative',
    alignItems: 'center'
  } : _ref2$containerStyle,
      _ref2$onChange = _ref2.onChange,
      _onChange = _ref2$onChange === void 0 ? function () {} : _ref2$onChange,
      _ref2$hasEmptyOption = _ref2.hasEmptyOption,
      hasEmptyOption = _ref2$hasEmptyOption === void 0 ? true : _ref2$hasEmptyOption,
      _ref2$emptyOptionDisa = _ref2.emptyOptionDisabled,
      emptyOptionDisabled = _ref2$emptyOptionDisa === void 0 ? false : _ref2$emptyOptionDisa,
      _ref2$sendEntireEvent = _ref2.sendEntireEvent,
      sendEntireEvent = _ref2$sendEntireEvent === void 0 ? false : _ref2$sendEntireEvent;

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      focused = _React$useState2[0],
      setFocused = _React$useState2[1];

  var _useTheme = (0, _DesignSystem.useTheme)(),
      shape = _useTheme.shape,
      colors = _useTheme.colors,
      typography = _useTheme.typography,
      spacing = _useTheme.spacing;

  var theme = (0, _core.useTheme)();
  var activecolor = error ? 'error' : focused ? 'button' : color;
  var padding = dense ? spacing.s : spacing.m;
  return _react.default.createElement(_Spacing.default, {
    style: containerStyle,
    flex: 1,
    direction: "row",
    alignItems: "flex-start",
    justifyContent: "center"
  }, leftIcon ? _react.default.createElement(CustomBox, {
    activecolor: activecolor,
    theme: theme,
    left: 15
  }, leftIcon) : null, _react.default.createElement("select", {
    name: name,
    onChange: function onChange(e) {
      if (sendEntireEvent) {
        _onChange(e);
      } else {
        _onChange(e.target.value);
      }
    },
    id: name,
    value: value,
    disabled: disabled,
    style: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, typography.paragraph), {}, {
      outlineColor: 'transparent',
      outlineWidth: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      color: "".concat(colors[color] || color).concat(disabled ? '88' : ''),
      paddingTop: padding,
      paddingBottom: padding,
      paddingLeft: padding + (leftIcon ? padding + spacing.m : 0),
      paddingRight: padding + (rightIcon ? padding + spacing.m : 0),
      borderStyle: 'solid',
      borderColor: "".concat(focused ? colors.button : colors[color] || color).concat(disabled ? '88' : ''),
      width: '100%',
      zIndex: 1,
      borderWidth: 2
    }, shape.buttons), error ? {
      borderColor: colors.errorColor
    } : {}), style)
  }, hasEmptyOption && _react.default.createElement("option", {
    disabled: emptyOptionDisabled,
    value: ''
  }, ''), options.map(function (option) {
    return _react.default.createElement("option", {
      key: option.id,
      value: option.id,
      disabled: option.disabled
    }, option.value);
  })), rightIcon ? _react.default.createElement(CustomBox, {
    activecolor: activecolor,
    theme: theme,
    right: 15
  }, rightIcon) : null);
}

var _default = Select;
exports.default = _default;