var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaperV = require("react-native-paper-v3");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _DesignSystem = require("../../../theme/DesignSystem");

var _core = require("@material-ui/core");

var _styles = _interopRequireDefault(require("./styles"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var Testimonial = function Testimonial(_ref) {
  var id = _ref.id,
      name = _ref.name,
      photoUrl = _ref.photoUrl,
      text = _ref.text,
      noClip = _ref.noClip;
  var navigation = (0, _useNavigationExtended.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      shape = _useTheme.shape;

  var classes = (0, _styles.default)();
  return _react.default.createElement(_reactNative.TouchableHighlight, {
    underlayColor: "".concat(colors.buttonHover, "55"),
    style: _objectSpread(_objectSpread({}, shape.cards), {}, {
      width: '100%'
    }),
    onPress: function onPress() {
      return navigation.navigate(_routesConsts.Routes.TESTIMONIAL_PAGE, {
        testimonialId: id
      });
    }
  }, _react.default.createElement(_reactNative.View, null, _react.default.createElement(_core.Card, {
    elevation: 0
  }, _react.default.createElement(_core.Box, {
    m: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  }, noClip ? _react.default.createElement(_core.Typography, null, text) : _react.default.createElement(_core.Typography, {
    className: classes.thirdLineTruncate
  }, text), _react.default.createElement(_core.Box, {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    mt: 2
  }, _react.default.createElement(_reactNativePaperV.Avatar.Image, {
    style: {
      backgroundColor: 'none'
    },
    size: 48,
    source: photoUrl
  }), _react.default.createElement(_core.Box, {
    ml: 2,
    className: classes.testimonialName
  }, _react.default.createElement(_core.Typography, {
    noWrap: true,
    className: classes.textBold
  }, name)))))));
};

var _default = Testimonial;
exports.default = _default;