var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResetPassword;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _homeButton = _interopRequireDefault(require("../home-button"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _authStyles = _interopRequireDefault(require("../auth-styles"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/auth/selectors");

var _actions = require("../../../core/auth/actions");

var _resetPasswordView = _interopRequireDefault(require("./reset-password-view"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _selectors2 = require("../../../core/event-info/selectors");

function ResetPassword() {
  var navigation = (0, _useNavigationExtended.default)();
  var Styles = (0, _authStyles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var isLoading = (0, _reactRedux.useSelector)(_selectors.isResetPasswordLoading);
  var eventId = (0, _reactRedux.useSelector)(_selectors2.selectEventInfoId);

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      resetSuccess = _useState2[0],
      setResetSuccess = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      resetFailed = _useState4[0],
      setResetFailed = _useState4[1];

  _react.default.useEffect(function () {
    return function () {
      return dispatch((0, _actions.removeResetPasswordError)());
    };
  }, []);

  (0, _useHeader.default)({
    visible: false,
    withScroll: false,
    withLeftMenu: false
  });

  var onResetPassword = function () {
    var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(email) {
      var response;
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return dispatch((0, _actions.resetPassword)({
                email: email,
                eventId: eventId
              }));

            case 2:
              response = _context.sent;

              if (response.payload) {
                setResetSuccess(true);
                setTimeout(function () {
                  navigation.navigate(_routesConsts.Routes.LOGIN, {
                    eventId: eventId
                  });
                }, 2000);
              } else {
                setResetFailed(true);
              }

            case 4:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function onResetPassword(_x) {
      return _ref.apply(this, arguments);
    };
  }();

  var onCloseToaster = function onCloseToaster() {
    if (resetSuccess) {
      setResetSuccess(false);
    } else if (resetFailed) {
      setResetFailed(false);
    }
  };

  return _react.default.createElement(_Section.default, {
    style: {
      flex: 1
    },
    color: "pageBackground"
  }, _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: Styles.scrollView
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 0, 0, 0]
  }, _react.default.createElement(_homeButton.default, null)), _react.default.createElement(_resetPasswordView.default, {
    onResetPassword: onResetPassword,
    goToRegister: function goToRegister() {
      return navigation.navigate(_routesConsts.Routes.REGISTER);
    },
    goToLogin: function goToLogin() {
      return navigation.navigate(_routesConsts.Routes.LOGIN);
    },
    isLoading: isLoading,
    status: {
      success: resetSuccess,
      failed: resetFailed
    },
    onCloseToaster: onCloseToaster
  })));
}