var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _consts = require("../../../../utils/consts");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _useScrollListener2 = _interopRequireDefault(require("../../../../utils/hooks/useScrollListener"));

var _headerLink = _interopRequireDefault(require("../../../landing-screen/header-link"));

var _DesignSystem = require("../../../../theme/DesignSystem");

var _mobileMenuStyle = _interopRequireDefault(require("./mobile-menu-style"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _reactRedux = require("react-redux");

var _useNavigationExtended = _interopRequireDefault(require("../../../../utils/hooks/useNavigationExtended"));

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _selectors = require("../../../../core/user/selectors");

var _reactFeather = require("react-feather");

var _reactNativePaperV = require("react-native-paper-v3");

var _hamburgerMenuFooter = _interopRequireDefault(require("./menu-footer/hamburger-menu-footer"));

var _useOpenState3 = _interopRequireDefault(require("../useOpenState"));

var _clsx = _interopRequireDefault(require("clsx"));

var _styles = _interopRequireDefault(require("./styles"));

var MobileMenu = function MobileMenu() {
  var Styles = (0, _mobileMenuStyle.default)();
  var classes = (0, _styles.default)();
  var isLoggedIn = (0, _reactRedux.useSelector)(_selectors.isFirebaseUserLoggedIn);
  var navigation = (0, _useNavigationExtended.default)();

  var _useScrollListener = (0, _useScrollListener2.default)(),
      sectionMap = _useScrollListener.sectionMap;

  var match = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
    var breakpoints = _ref.breakpoints;
    return breakpoints.bg;
  });

  var _useOpenState = (0, _useOpenState3.default)('menubol'),
      _useOpenState2 = (0, _slicedToArray2.default)(_useOpenState, 2),
      open = _useOpenState2[0],
      setOpen = _useOpenState2[1];

  return open && match && _react.default.createElement(_reactNative.ScrollView, {
    style: Styles.menuContainer
  }, _react.default.createElement(_reactNative.View, {
    style: Styles.menuInnerContainer
  }, _react.default.createElement(_reactNativePaperV.TouchableRipple, {
    style: Styles.closeIcon,
    onPress: function onPress() {
      return setOpen(false);
    }
  }, _react.default.createElement(_reactFeather.X, {
    size: "24"
  })), _react.default.createElement(_Spacing.default, {
    direction: "column",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "stretch"
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    justifyContent: "flex-start"
  }, !isLoggedIn && _react.default.createElement(_headerLink.default, {
    key: "login",
    isActive: false,
    className: (0, _clsx.default)(classes.menuItem, classes.login),
    onClick: function onClick() {
      navigation.navigate(_routesConsts.Routes.LOGIN);
      setOpen(false);
    }
  }, _i18n.default.t('login.header')), Object.entries(sectionMap).sort(function (_ref2, _ref3) {
    var _ref4 = (0, _slicedToArray2.default)(_ref2, 2),
        _ = _ref4[0],
        aValue = _ref4[1];

    var _ref5 = (0, _slicedToArray2.default)(_ref3, 2),
        __ = _ref5[0],
        bValue = _ref5[1];

    return aValue.index - bValue.index;
  }).map(function (_ref6) {
    var _ref7 = (0, _slicedToArray2.default)(_ref6, 2),
        key = _ref7[0],
        value = _ref7[1];

    return _react.default.createElement(_headerLink.default, {
      key: key,
      isActive: false,
      className: classes.menuItem,
      onClick: function onClick() {
        value.scrollTo();
        setOpen(false);
      }
    }, value.type === _consts.SECTION_TYPES.CUSTOM_SECTIONS ? value.title : _i18n.default.t("header.".concat(value.type)));
  })), _react.default.createElement(_hamburgerMenuFooter.default, null))));
};

var _default = MobileMenu;
exports.default = _default;