var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _selectors = require("../../../../core/expo/selectors");

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _LinkButton = _interopRequireDefault(require("../../../../theme/components/buttons/LinkButton"));

var _Section = _interopRequireDefault(require("../../../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _boothFilesStyles = _interopRequireDefault(require("./booth-files-styles"));

var _styles = _interopRequireDefault(require("./styles"));

var BoothFilesContainer = function BoothFilesContainer() {
  var _booth$files;

  var Styles = (0, _boothFilesStyles.default)();
  var classes = (0, _styles.default)();
  var boothId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.BOOTH_ID);
  var booth = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.selectBoothById)(state, boothId);
  });

  var downloadFile = function downloadFile(name, downloadUrl) {
    if (_reactNative.Platform.OS === 'web') {
      var link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', name);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (!(booth === null || booth === void 0 ? void 0 : (_booth$files = booth.files) === null || _booth$files === void 0 ? void 0 : _booth$files.length)) return _react.default.createElement(_Spacing.default, {
    flex: 1,
    alignItems: "center",
    direction: "column",
    mSpacing: "s",
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate('expo.noFiles')));
  return _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    mSpacing: "s",
    flex: 1
  }, _react.default.createElement(_core.Typography, {
    variant: "h4"
  }, _i18n.default.t('rightSidebarMenu.uploadedFiles')), _react.default.createElement(_reactNative.ScrollView, null, _react.default.createElement(_Spacing.default, {
    mSpacing: ['m', 'none'],
    direction: "column",
    alignItems: "stretch"
  }, booth.files.map(function (file, index) {
    return _react.default.createElement(_Spacing.default, {
      mSpacing: ['root', 'none'],
      key: "file-".concat(index)
    }, _react.default.createElement(_Section.default, {
      color: "alternativeBackground",
      style: Styles.file
    }, _react.default.createElement(_LinkButton.default, {
      onPress: function onPress() {
        downloadFile(file.name, file.downloadUrl);
      }
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: "s"
    }, _react.default.createElement(_Spacing.default, null, _react.default.createElement(_reactFeather.Download, {
      size: "1.2rem",
      style: Styles.fileIcon
    })), _react.default.createElement(_core.Typography, {
      variant: "caption",
      className: classes.fileName
    }, file.name)))));
  }))));
};

var _default = BoothFilesContainer;
exports.default = _default;