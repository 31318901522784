var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = UsersListContainer;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _reactNativeGestureHandler = require("react-native-gesture-handler");

var _reactRedux = require("react-redux");

var _actions = require("../../../core/chat/actions");

var _selectors = require("../../../core/chat/selectors");

var _selectors2 = require("../../../core/user/selectors");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _user = _interopRequireDefault(require("../user"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _selectors3 = require("../../../core/user-rooms/selectors");

var _tableParticipant = _interopRequireDefault(require("./table-participant"));

var _actions2 = require("../../../core/user-rooms/actions");

var _Divider = _interopRequireDefault(require("../../../theme/components/divider/Divider"));

var _styles = _interopRequireDefault(require("../styles"));

function UsersListContainer(_ref) {
  var roomId = _ref.roomId,
      isAdmin = _ref.isAdmin,
      isWatching = _ref.isWatching;
  var classes = (0, _styles.default)();
  var users = (0, _reactRedux.useSelector)(_selectors.roomUsersAndStatusesSelector);
  var participantsInfo = (0, _reactRedux.useSelector)((0, _selectors3.roomAllParticipantsSelector)(roomId));
  var participants = Object.entries(participantsInfo);
  var userId = (0, _reactRedux.useSelector)(_selectors2.getFirebaseUserUid);
  var isBlocked = (0, _reactRedux.useSelector)(_selectors.imBlockedSelector);
  var dispatch = (0, _reactRedux.useDispatch)();

  var onKick = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(participantId) {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return dispatch((0, _actions2.kickParticipantFromRoom)({
                roomId: roomId,
                participantId: participantId
              }));

            case 2:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function onKick(_x) {
      return _ref2.apply(this, arguments);
    };
  }();

  var participantsIds = Object.values(participantsInfo).map(function (participant) {
    return participant.uid;
  });
  var watchers = users.filter(function (user) {
    return !participantsIds.includes(user.id);
  });

  var onBlock = function onBlock(user) {
    return dispatch((0, _actions.sendUserBlock)({
      user: user
    }));
  };

  var onUnblock = function onUnblock(user) {
    return dispatch((0, _actions.sendUserUnblock)({
      user: user
    }));
  };

  if (isBlocked) return _react.default.createElement(_Spacing.default, {
    flex: 1,
    alignItems: "center",
    direction: "column",
    mSpacing: "s",
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate('chat.youBlockedFromChat')));
  if (!users.length) return _react.default.createElement(_Spacing.default, {
    flex: 1,
    alignItems: "center",
    direction: "column",
    mSpacing: "s",
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate('chat.noUsers')));
  return _react.default.createElement(_reactNativeGestureHandler.ScrollView, {
    direction: "column",
    alignItems: "stretch"
  }, participants.length > 0 ? _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Typography, {
    className: classes.participantsTitle
  }, _i18n.default.translate('chat.participants')), participants.map(function (_ref3) {
    var _ref4 = (0, _slicedToArray2.default)(_ref3, 2),
        id = _ref4[0],
        _ref4$ = _ref4[1],
        name = _ref4$.name,
        picture = _ref4$.picture,
        uid = _ref4$.uid;

    return _react.default.createElement(_tableParticipant.default, {
      name: name,
      picture: picture,
      id: id,
      key: uid,
      handleKick: onKick,
      isAdmin: isAdmin,
      isCurrentUser: userId === uid,
      isWatching: isWatching
    });
  }), _react.default.createElement(_Divider.default, {
    width: 1,
    spacing: "s"
  })) : null, _react.default.createElement(_core.Typography, {
    className: classes.participantsTitle
  }, _i18n.default.translate('chat.watchers')), watchers.map(function (user) {
    return _react.default.createElement(_user.default, (0, _extends2.default)({
      onBlock: isAdmin ? function () {
        return onBlock(user);
      } : null,
      onUnblock: isAdmin ? function () {
        return onUnblock(user);
      } : null,
      key: user.usr
    }, user));
  }));
}