var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _CountDown = _interopRequireDefault(require("../common/CountDown"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _core = require("@material-ui/core");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var zoneAbbreviation = (0, _momentTimezone.default)().tz(_momentTimezone.default.tz.guess()).format('z');

var SmartCountDown = function (_React$PureComponent) {
  (0, _inherits2.default)(SmartCountDown, _React$PureComponent);

  var _super = _createSuper(SmartCountDown);

  function SmartCountDown() {
    var _this;

    (0, _classCallCheck2.default)(this, SmartCountDown);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      now: Date.now()
    };

    _this._handleAppStateChange = function (currentAppState) {
      if (currentAppState === 'active') {
        _this.setState({
          now: Date.now()
        });
      }
    };

    return _this;
  }

  (0, _createClass2.default)(SmartCountDown, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _reactNative.AppState.addEventListener('change', this._handleAppStateChange);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _reactNative.AppState.removeEventListener('change', this._handleAppStateChange);
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
          color = _this$props.color,
          size = _this$props.size;

      if (!this.props.untilDate) {
        return null;
      }

      var countdownThreshold = this.props.countdownThreshold ? this.props.countdownThreshold * 60 * 60 : 1 * 60 * 60;
      var secondsLeft = Math.floor((this.props.untilDate.getTime() - this.state.now) / 1000);

      if (secondsLeft >= countdownThreshold) {
        var date = (0, _momentTimezone.default)(this.props.untilDate);
        var d = new Date(date);
        var formatedDate = d.toLocaleTimeString(_momentTimezone.default.locale(), {
          hour: '2-digit',
          minute: '2-digit'
        });
        return React.createElement(_reactNative.View, {
          style: styles.container
        }, React.createElement(_reactNative.Text, {
          style: [{
            fontSize: size
          }, this.props.style],
          color: color
        }, React.createElement(_core.Typography, {
          variant: "inherit",
          color: "inherit"
        }, date.calendar(this.state.now, {
          sameDay: _i18n.default.t('global.today'),
          nextDay: _i18n.default.t('global.tomorrow'),
          nextWeek: 'dddd',
          sameElse: 'Do MMM'
        }), ", ", formatedDate, " (", zoneAbbreviation, ")")));
      } else {
        return React.createElement(_reactNative.View, {
          style: styles.container
        }, React.createElement(_CountDown.default, {
          until: secondsLeft,
          onFinish: this.props.onFinish,
          size: size,
          digitBgColor: "transparent",
          digitTxtColor: color,
          timeTxtColor: color,
          timeToShow: countdownThreshold > 1 ? ['H', 'M', 'S'] : ['M', 'S'],
          labelH: this.props.digitsOnly ? ':' : 'hours',
          labelM: this.props.digitsOnly ? ':' : 'mins',
          labelS: this.props.digitsOnly ? '' : 'sec',
          timeElapsedMessage: this.props.timeElapsedMessage,
          timeElapsedStyle: this.props.timeElapsedStyle,
          style: this.props.style
        }));
      }
    }
  }]);
  return SmartCountDown;
}(React.PureComponent);

exports.default = SmartCountDown;

var styles = _reactNative.StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  headline: {
    textAlign: 'center'
  }
});