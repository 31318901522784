Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textWhite: {
      color: theme.palette.headerText.main
    },
    container: {
      padding: theme.spacing(8),
      marginRight: 440 + theme.spacing(6),
      alignItems: 'flex-start',
      '@media(max-width: 1152px)': {
        marginRight: 0,
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3)
      }
    },
    imgBackgroundBlack: {
      backgroundColor: theme.palette.pageText.main,
      position: 'absolute',
      opacity: 0.65,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1
    }
  };
});
var _default = styles;
exports.default = _default;