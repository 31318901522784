var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useTicketStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  circle: _objectSpread(_objectSpread({}, _reactNative.Platform.select({
    web: {
      borderRadius: '50%'
    }
  })), {}, {
    width: 22,
    height: 22,
    alignItems: 'center',
    justifyContent: 'center'
  }),
  innerCircle: _objectSpread(_objectSpread({}, _reactNative.Platform.select({
    web: {
      borderRadius: '50%'
    }
  })), {}, {
    width: 8,
    height: 8
  }),
  container: _objectSpread({}, _reactNative.Platform.select({
    web: {
      cursor: 'pointer',
      userSelect: 'none'
    }
  }))
}).addThemeBasedStyle(function (theme) {
  return {
    circle: {},
    shaped: _objectSpread({}, theme.shape.buttons)
  };
}).buildHook();
var _default = useTicketStyles;
exports.default = _default;