var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _core = require("@material-ui/core");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _styles = _interopRequireDefault(require("./styles"));

var NoAccessToVideos = function NoAccessToVideos() {
  var classes = (0, _styles.default)();
  var navigation = (0, _useNavigationExtended.default)();
  return _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "stretch",
    className: classes.container
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    className: classes.lobbyCard
  }, _react.default.createElement(_core.Box, {
    component: _core.Paper,
    className: classes.noVideContainer,
    maxWidth: 400
  }, _react.default.createElement(_core.Box, {
    my: 5,
    mx: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_core.Typography, {
    align: "center",
    variant: "body1"
  }, _i18n.default.t('videos.noAccessToVideos'))), _react.default.createElement(_core.Box, {
    mb: 5,
    mx: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_ContainedButton.default, {
    size: "large",
    onPress: function onPress() {
      return navigation.navigate(_routesConsts.Routes.MY_TICKETS);
    }
  }, _i18n.default.t('liveNow.upgradeTicket'))))));
};

var _default = NoAccessToVideos;
exports.default = _default;