var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _reactRedux = require("react-redux");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _currencyUtils = require("../../../../utils/currency-utils");

var _eventRegisterProvider = require("../../event-register-provider");

var _eventRegistrationUtils = require("../../event-registration-utils");

var _promoCodeContainer = _interopRequireDefault(require("../checkout-step/promo-code-container"));

var _termsAndConditions = _interopRequireDefault(require("./terms-and-conditions"));

var _selectors = require("../../../../core/event-info/selectors");

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    summaryContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'
    },
    eventInfoContainer: (0, _defineProperty2.default)({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      maxHeight: '100%',
      marginBottom: 8
    }, theme.breakpoints.down(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      display: 'none'
    }),
    detailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    logo: {
      width: '100%',
      borderRadius: 10
    },
    bold: {
      fontWeight: 'bold'
    },
    title: (0, _defineProperty2.default)({
      marginBottom: 8,
      display: 'none'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      marginBottom: 16
    }),
    priceBox: (0, _defineProperty2.default)({
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 8,
      justifyContent: 'space-between'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      marginBottom: 16
    }),
    totalContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'flex-end',
      paddingTop: 8,
      gap: 8
    },
    priceContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    }
  };
});

var OrderSummary = function OrderSummary() {
  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      currency = _useEventRegisterCont.currency,
      checkedAddonsVariations = _useEventRegisterCont.checkedAddonsVariations,
      eventSlug = _useEventRegisterCont.eventSlug,
      formValuesState = _useEventRegisterCont.formValuesState,
      selectedTicket = _useEventRegisterCont.selectedTicket,
      showPromoCode = _useEventRegisterCont.showPromoCode,
      summaryPrices = _useEventRegisterCont.summaryPrices,
      userProfile = _useEventRegisterCont.userProfile,
      ticketQuantity = _useEventRegisterCont.ticketQuantity;

  var ticketPrice = selectedTicket === null || selectedTicket === void 0 ? void 0 : selectedTicket.price;
  var ticketName = selectedTicket === null || selectedTicket === void 0 ? void 0 : selectedTicket.name;
  var userEmail = (formValuesState === null || formValuesState === void 0 ? void 0 : formValuesState.email) || (userProfile === null || userProfile === void 0 ? void 0 : userProfile.email) || '';
  var eventDates = (0, _reactRedux.useSelector)(_selectors.getEventDates);
  var eventName = (0, _reactRedux.useSelector)(_selectors.getEventName);
  var logoUrl = (0, _reactRedux.useSelector)(_selectors.selectEventLogoUrl);

  var _useStyles = useStyles(),
      bold = _useStyles.bold,
      title = _useStyles.title,
      summaryContainer = _useStyles.summaryContainer,
      priceBox = _useStyles.priceBox,
      totalContainer = _useStyles.totalContainer,
      eventInfoContainer = _useStyles.eventInfoContainer,
      logo = _useStyles.logo,
      detailsContainer = _useStyles.detailsContainer,
      priceContainer = _useStyles.priceContainer;

  return _react.default.createElement(_core.Box, {
    className: summaryContainer
  }, _react.default.createElement(_core.Box, {
    className: eventInfoContainer
  }, _react.default.createElement("img", {
    src: logoUrl,
    className: logo
  }), _react.default.createElement(_core.Box, {
    my: 1,
    className: detailsContainer
  }, _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: bold
  }, eventName), _react.default.createElement(_core.Typography, {
    variant: "overline"
  }, eventDates))), _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "row",
    className: title
  }, _react.default.createElement(_core.Typography, {
    variant: "h3"
  }, _i18n.default.translate('checkout.summary'))), _react.default.createElement(_core.Box, {
    className: priceBox
  }, _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: bold
  }, "".concat(ticketQuantity, " x ").concat(ticketName)), _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: bold
  }, (0, _currencyUtils.concatPriceWithCurrency)(!isNaN(ticketPrice) ? ticketPrice * ticketQuantity : '', currency))), checkedAddonsVariations.length >= 1 && _react.default.createElement(_core.Box, {
    className: priceBox
  }, _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: bold
  }, "".concat(ticketQuantity, " x ").concat(_i18n.default.translate('checkout.addOns'))), _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: bold
  }, (0, _currencyUtils.concatPriceWithCurrency)(!isNaN(summaryPrices.variationsTotal) ? summaryPrices.variationsTotal : '', currency))), _react.default.createElement(_core.Box, {
    className: totalContainer
  }, showPromoCode && _react.default.createElement(_promoCodeContainer.default, null), _react.default.createElement(_core.Box, {
    className: priceContainer
  }, _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: bold
  }, _i18n.default.translate('checkout.total')), _react.default.createElement(_core.Box, {
    className: priceBox
  }, (summaryPrices === null || summaryPrices === void 0 ? void 0 : summaryPrices.totalWithPromotion) ? _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: bold
  }, (0, _currencyUtils.concatPriceWithCurrency)(!isNaN(summaryPrices.totalWithPromotion) ? summaryPrices.totalWithPromotion : '', currency)) : _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: bold
  }, (0, _currencyUtils.concatPriceWithCurrency)(!isNaN(summaryPrices.total) ? summaryPrices.total : '', currency))))), userEmail && _react.default.createElement(_core.Box, {
    style: {
      textAlign: 'center'
    }
  }, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, _i18n.default.t('billing.termsAndConditions'), " ", userEmail)), _react.default.createElement(_termsAndConditions.default, {
    beforeText: 'billing.termsAndConditions2',
    eventSlug: eventSlug
  }));
};

var _default = OrderSummary;
exports.default = _default;