var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.qAndAEntityAdapter = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var _createReducer;

var qAndAEntityAdapter = (0, _toolkit.createEntityAdapter)();
exports.qAndAEntityAdapter = qAndAEntityAdapter;
var InitialState = qAndAEntityAdapter.getInitialState({
  questions: [],
  loading: false
});
var questionsAndAnswersReducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.setQuestions.type, function (state, _ref) {
  var payload = _ref.payload;
  qAndAEntityAdapter.setAll(state, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.deleteQuestion.pending, function (state) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.deleteQuestion.rejected, function (state, _ref2) {
  var error = _ref2.error;
  state.loading = false;
  state.error = error;
}), _createReducer));
var _default = questionsAndAnswersReducer;
exports.default = _default;