var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _Button = _interopRequireDefault(require("../common/Button"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var NoEventsPopup = function (_React$PureComponent) {
  (0, _inherits2.default)(NoEventsPopup, _React$PureComponent);

  var _super = _createSuper(NoEventsPopup);

  function NoEventsPopup() {
    var _this;

    (0, _classCallCheck2.default)(this, NoEventsPopup);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.onCloseModal = function () {
      _this.props.navigation.goBack();
    };

    return _this;
  }

  (0, _createClass2.default)(NoEventsPopup, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      return _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_reactNative.TouchableWithoutFeedback, {
        onPress: function onPress() {
          return _this2.onCloseModal();
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.backdrop
      })), _react.default.createElement(_reactNative.View, {
        style: styles.wrapper
      }, _react.default.createElement(_reactNative.View, {
        style: styles.surface
      }, _react.default.createElement(_CustomIcon.default, {
        name: "calendar-clock",
        size: 48,
        color: "rgba(0,0,0,0.4)"
      }), _react.default.createElement(_Paragraph.default, {
        style: styles.alertText
      }, "No shows currently planned for this genre. Please check again later."), _react.default.createElement(_Button.default, {
        testID: 'DismissModal',
        dark: false,
        color: "#000",
        name: "DismissModal",
        mode: "outlined",
        onPress: function onPress() {
          return _this2.onCloseModal();
        }
      }, "Dismiss"))));
    }
  }]);
  return NoEventsPopup;
}(_react.default.PureComponent);

exports.default = NoEventsPopup;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1
  },
  backdrop: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  wrapper: _objectSpread(_objectSpread({}, _reactNative.StyleSheet.absoluteFillObject), {}, {
    justifyContent: 'center',
    padding: 16
  }),
  surface: {
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 560,
    padding: 32,
    borderRadius: 4
  },
  alertText: {
    color: '#000',
    textAlign: 'center',
    marginVertical: 24
  }
});