var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Expo;

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _expoStyles = _interopRequireDefault(require("./expo-styles"));

var _styles = _interopRequireDefault(require("./styles"));

function Expo(_ref) {
  var expo = _ref.expo,
      category = _ref.category,
      sponsor = _ref.sponsor;
  var navigation = (0, _useNavigationExtended.default)();
  var Styles = (0, _expoStyles.default)();
  var classes = (0, _styles.default)();
  var selectedStyle = (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) !== '0' ? (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) !== '1' ? classes.large : classes.medium : classes.small;
  var selectedStyleBtn = (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) !== '0' ? (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) !== '1' ? classes.largeBtn : classes.mediumBtn : classes.smallBtn;
  var selectedStyleinfoContainer = (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) === '0' ? classes.smallinfoContainer : '';
  var titleVariant = (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) === '2' ? 'h2' : 'h3';
  return _react.default.createElement(_reactNative.View, null, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    flex: "1",
    m: 3
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flex: "1",
    component: _core.Paper,
    elevation: 0,
    className: classes.innerContainer
  }, _react.default.createElement(_core.Box, {
    component: _core.Paper,
    elevation: 0,
    overflow: "hidden",
    flex: "1",
    display: "flex",
    flexDirection: "column",
    className: selectedStyle
  }, _react.default.createElement(_reactNative.TouchableHighlight, {
    onPress: function onPress() {
      return navigation.navigate(_routesConsts.Routes.EXPO_BOOTH_PAGE, {
        boothId: expo.id
      });
    }
  }, _react.default.createElement(_reactNative.Image, {
    resizeMode: 'cover',
    style: Styles.image,
    source: {
      uri: expo.headerUrl
    }
  })), _react.default.createElement(_core.Box, {
    m: 3,
    display: "flex",
    alignItems: "stretch",
    className: "".concat(classes.infoContainer, " ").concat(selectedStyleinfoContainer)
  }, _react.default.createElement(_core.Box, {
    mb: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-start",
    flex: "1"
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    mb: 1
  }, _react.default.createElement(_core.Typography, {
    variant: titleVariant,
    className: classes.cardTitle
  }, expo.headline)), _react.default.createElement(_core.Box, {
    display: "flex"
  }, _react.default.createElement(_core.Typography, {
    variant: "h4"
  }, sponsor ? sponsor.name : ''))), _react.default.createElement(_core.Box, {
    ml: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  }, _react.default.createElement(_ContainedButton.default, {
    onPress: function onPress() {
      return navigation.navigate(_routesConsts.Routes.EXPO_BOOTH_PAGE, {
        boothId: expo.id
      });
    }
  }, _react.default.createElement(_core.Typography, {
    className: "".concat(classes.cardBtn, " ").concat(selectedStyleBtn)
  }, _i18n.default.t('expo.visitBooth')))))))));
}