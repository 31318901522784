var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _DesignSystem = require("../../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useLiveNowStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    image: _objectSpread(_objectSpread({
      width: '100%',
      paddingBottom: '56.25%'
    }, theme.shape.cards), {}, {
      position: 'relative',
      backgroundColor: "rgba(0, 0, 0, 0.6)"
    }),
    previewText: {
      justifyContent: 'center',
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    container: {
      padding: theme.spacing.m,
      width: '100%',
      minWidth: 0,
      maxWidth: '50%'
    },
    imgBackground: _objectSpread({
      flex: 1,
      justifyContent: 'center'
    }, theme.shape.cards),
    imageStyle: _objectSpread({}, theme.shape.cards),
    imgBackgroundBlack: _objectSpread({
      position: 'absolute',
      opacity: 0.75,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }, theme.shape.cards),
    playButtonContainer: {
      backgroundColor: "rgba(7, 7, 7, 0.6)",
      borderRadius: theme.spacing.xxl,
      padding: theme.spacing.xl
    },
    absolute: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    descriptionText: {
      marginVertical: theme.spacing.s,
      fontSize: theme.fontSize
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.lg;
}, function (theme) {
  return {
    container: {
      maxWidth: '100%'
    }
  };
}).buildHook();
var _default = useLiveNowStyles;
exports.default = _default;