var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var EventLiveFullscreenOverlay = function EventLiveFullscreenOverlay(props) {
  var started = props.started,
      fullScreen = props.fullScreen,
      enableFullscreen = props.enableFullscreen,
      disableFullscreen = props.disableFullscreen,
      fullScreenOverlayVisible = props.fullScreenOverlayVisible;

  if (started && fullScreenOverlayVisible) {
    if (fullScreen) {
      return React.createElement(_reactNative.View, null, React.createElement(_reactNativePaper.TouchableRipple, {
        onPress: disableFullscreen,
        style: window.innerWidth > 1280 ? styles.fullScreenOverlay : styles.nonFullScreenOverlay
      }, React.createElement(_reactNative.View, null)));
    } else {
      return React.createElement(_reactNative.View, null, React.createElement(_reactNativePaper.TouchableRipple, {
        onPress: enableFullscreen,
        style: styles.nonFullScreenOverlay
      }, React.createElement(_reactNative.View, null)));
    }
  }

  return null;
};

var _default = EventLiveFullscreenOverlay;
exports.default = _default;

var styles = _reactNative.StyleSheet.create({
  fullScreenOverlay: {
    height: 60,
    width: 60,
    position: 'absolute',
    bottom: 20,
    right: 48,
    cursor: 'pointer'
  },
  nonFullScreenOverlay: {
    height: 42,
    width: 44,
    position: 'absolute',
    bottom: 2,
    right: 12,
    cursor: 'pointer'
  }
});