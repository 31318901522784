var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ChatInput;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _Input = _interopRequireDefault(require("../../theme/components/inputs/Input"));

var _DesignSystem = require("../../theme/DesignSystem");

function ChatInput(_ref) {
  var onMessage = _ref.onMessage,
      disabled = _ref.disabled,
      placeholder = _ref.placeholder,
      _ref$hasMargin = _ref.hasMargin,
      hasMargin = _ref$hasMargin === void 0 ? true : _ref$hasMargin;

  var _React$useState = _react.default.useState(''),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      msg = _React$useState2[0],
      setMsg = _React$useState2[1];

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing;

  return _react.default.createElement(_Input.default, {
    disabled: disabled,
    placeholder: placeholder,
    value: msg,
    style: {
      marginRight: hasMargin ? spacing.s : 0,
      borderWidth: 1,
      fontSize: 16,
      borderColor: '#dddddd'
    },
    dense: true,
    color: "alternativeText",
    onChange: function onChange(_ref2) {
      var target = _ref2.target;
      return setMsg(target.value);
    },
    onKeyPress: function onKeyPress(_ref3) {
      var nativeEvent = _ref3.nativeEvent;

      if (nativeEvent.key === 'Enter' && !nativeEvent.altKey && !nativeEvent.ctrlKey) {
        msg.length && onMessage(msg);
        setMsg('');
      }
    }
  });
}