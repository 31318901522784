var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Spacing;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _lodash = _interopRequireDefault(require("lodash"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function Spacing(_ref) {
  var _ref$direction = _ref.direction,
      direction = _ref$direction === void 0 ? 'row' : _ref$direction,
      wrap = _ref.wrap,
      self = _ref.self,
      alignItems = _ref.alignItems,
      justifyContent = _ref.justifyContent,
      children = _ref.children,
      mSpacing = _ref.mSpacing,
      pSpacing = _ref.pSpacing,
      _ref$breakpointStyle = _ref.breakpointStyle,
      breakpointStyle = _ref$breakpointStyle === void 0 ? {} : _ref$breakpointStyle,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'none' : _ref$color,
      _ref$shaping = _ref.shaping,
      shaping = _ref$shaping === void 0 ? 'none' : _ref$shaping,
      flex = _ref.flex,
      style = _ref.style,
      innerKey = _ref.innerKey,
      rest = (0, _objectWithoutProperties2.default)(_ref, ["direction", "wrap", "self", "alignItems", "justifyContent", "children", "mSpacing", "pSpacing", "breakpointStyle", "color", "shaping", "flex", "style", "innerKey"]);

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing,
      colors = _useTheme.colors,
      shape = _useTheme.shape;

  var breakpoint = (0, _DesignSystem.useCurrentBreakpoint)();
  var marginSpacing = getSpacing('margin', mSpacing, spacing);
  var paddingSpacing = getSpacing('padding', pSpacing, spacing);

  var flexStyle = _objectSpread({
    alignItems: alignItems || 'center',
    justifyContent: justifyContent || 'flex-start',
    flexDirection: direction,
    flexWrap: wrap,
    flex: flex,
    backgroundColor: colors[color] || color
  }, shape[shaping] || {});

  var selfAlignment = getAlignment(self);
  return _react.default.createElement(_reactNative.View, (0, _extends2.default)({
    key: innerKey
  }, rest, {
    style: [marginSpacing, paddingSpacing, flexStyle, selfAlignment, style, breakpointStyle[breakpoint] || breakpointStyle.default]
  }), children);
}

function getAlignment(selfAlignment) {
  switch (selfAlignment) {
    case 'top':
      return {
        marginBottom: 'auto'
      };

    case 'top-left':
      return {
        marginBottom: 'auto',
        marginRight: 'auto'
      };

    case 'top-right':
      return {
        marginBottom: 'auto',
        marginLeft: 'auto'
      };

    case 'bottom':
      return {
        marginTop: 'auto'
      };

    case 'bottom-left':
      return {
        marginTop: 'auto',
        marginRight: 'auto'
      };

    case 'bottom-right':
      return {
        marginTop: 'auto',
        marginLeft: 'auto'
      };

    case 'middle':
      return {
        marginTop: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 'auto'
      };

    case 'left':
      return {
        marginRight: 'auto'
      };

    case 'right':
      return {
        marginLeft: 'auto'
      };

    default:
      return {};
  }
}

function getSpacing(type, spacing, themeSpacing) {
  var _ref4, _ref5, _ref6;

  if (_lodash.default.isArray(spacing)) {
    switch (spacing.length) {
      case 0:
        return (0, _defineProperty2.default)({}, type, 0);

      case 1:
        return (0, _defineProperty2.default)({}, type, themeSpacing[spacing[0]] || spacing[0]);

      case 2:
        return _ref4 = {}, (0, _defineProperty2.default)(_ref4, "".concat(type, "Top"), themeSpacing[spacing[0]] || spacing[0]), (0, _defineProperty2.default)(_ref4, "".concat(type, "Bottom"), themeSpacing[spacing[0]] || spacing[0]), (0, _defineProperty2.default)(_ref4, "".concat(type, "Left"), themeSpacing[spacing[1]] || spacing[1]), (0, _defineProperty2.default)(_ref4, "".concat(type, "Right"), themeSpacing[spacing[1]] || spacing[1]), _ref4;

      case 3:
        return _ref5 = {}, (0, _defineProperty2.default)(_ref5, "".concat(type, "Top"), themeSpacing[spacing[0]] || spacing[0]), (0, _defineProperty2.default)(_ref5, "".concat(type, "Left"), themeSpacing[spacing[1]] || spacing[1]), (0, _defineProperty2.default)(_ref5, "".concat(type, "Right"), themeSpacing[spacing[1]] || spacing[1]), (0, _defineProperty2.default)(_ref5, "".concat(type, "Bottom"), themeSpacing[spacing[2]] || spacing[2]), _ref5;

      case 4:
        return _ref6 = {}, (0, _defineProperty2.default)(_ref6, "".concat(type, "Top"), themeSpacing[spacing[0]] || spacing[0]), (0, _defineProperty2.default)(_ref6, "".concat(type, "Right"), themeSpacing[spacing[1]] || spacing[1]), (0, _defineProperty2.default)(_ref6, "".concat(type, "Bottom"), themeSpacing[spacing[2]] || spacing[2]), (0, _defineProperty2.default)(_ref6, "".concat(type, "Left"), themeSpacing[spacing[3]] || spacing[3]), _ref6;

      default:
        throw Error('DESIGN SYSTEM: Please provide at least 0 and maximum 4 elements in a spacing array!');
    }
  } else {
    return (0, _defineProperty2.default)({}, type, themeSpacing[spacing] || spacing);
  }
}