var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Reconnecting;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _AbsoluteBox = _interopRequireDefault(require("../../../theme/components/box/AbsoluteBox"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var ReconnectingText = (0, _core.withStyles)({
  root: {
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    margin: 'auto',
    color: 'white',
    fontFamily: function fontFamily(_ref) {
      var font = _ref.font;
      return font;
    }
  }
})(function (_ref2) {
  var font = _ref2.font,
      fs = _ref2.fs,
      props = (0, _objectWithoutProperties2.default)(_ref2, ["font", "fs"]);
  return _react.default.createElement(_core.Box, props);
});

function Reconnecting(_ref3) {
  var grid = _ref3.grid;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      fontFamily = _useTheme.typography.fontFamily;

  var fontSize = Math.min(Math.round(grid.width / 100 * 8), 13);
  return _react.default.createElement(_AbsoluteBox.default, {
    p: 0,
    align: {},
    width: grid.width,
    height: grid.height
  }, _react.default.createElement(ReconnectingText, {
    font: fontFamily,
    fontSize: fontSize
  }, _i18n.default.translate('chat.reconnecting')));
}