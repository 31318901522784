var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var Title = function Title(props) {
  return React.createElement(_reactNativePaper.Headline, (0, _extends2.default)({}, props, {
    style: [styles.text, props.style]
  }));
};

var _default = Title;
exports.default = _default;

var styles = _reactNative.StyleSheet.create({
  text: {
    fontSize: 17,
    lineHeight: 25,
    marginVertical: 0
  }
});