var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventDetailInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _NavigationButtons = require("../../navigation/NavigationButtons");

var _reactNativeLinearGradient = _interopRequireDefault(require("react-native-linear-gradient"));

var _reactNativePaper = require("react-native-paper");

var _AvatarImage = _interopRequireDefault(require("../common/avatar/AvatarImage"));

var _FollowUserButton = _interopRequireDefault(require("../common/follow/FollowUserButton"));

var _Button = _interopRequireDefault(require("../common/Button"));

var _LiveShareButton = _interopRequireDefault(require("../live/LiveShareButton"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _VipBadge = _interopRequireDefault(require("../common/vip/VipBadge"));

var _Headline = _interopRequireDefault(require("../common/typography_v1/Headline"));

var _ParagraphSmall = _interopRequireDefault(require("../common/typography_v1/ParagraphSmall"));

var _SmartDate = _interopRequireDefault(require("../common/SmartDate"));

var _events = _interopRequireDefault(require("../../core/events"));

var _activity = _interopRequireDefault(require("../../core/activity"));

var _live = _interopRequireDefault(require("../../core/live"));

var _SimilarEventSection = _interopRequireDefault(require("../common/event/similar/SimilarEventSection"));

var _LoadingScreen = _interopRequireDefault(require("../LoadingScreen"));

var _profile = _interopRequireDefault(require("../../core/profile"));

var _CustomCachedImage = _interopRequireDefault(require("../common/CustomCachedImage"));

var _reactNativePaperV = require("react-native-paper-v3");

var _blue_background = _interopRequireDefault(require("../../assets/images/blue_background.png"));

var _sectionView = _interopRequireDefault(require("../../../design-system/structure/section/section-view"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var EventDetailInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(EventDetailInternal, _React$PureComponent);

  var _super = _createSuper(EventDetailInternal);

  function EventDetailInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, EventDetailInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.scrollY = new _reactNative.Animated.Value(0);
    _this.changingBgColor = 0;
    return _this;
  }

  (0, _createClass2.default)(EventDetailInternal, [{
    key: "loadFollowNumber",
    value: function loadFollowNumber() {
      if (this.props.djId !== undefined) {
        this.props.loadFollowersNumber(this.props.djId);
        this.props.loadFollowingsNumber(this.props.djId);
      }
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {}
  }, {
    key: "render",
    value: function render() {
      if (this.props.image !== undefined) {
        return React.createElement(_reactNative.View, {
          style: styles.LoadingScreen,
          testID: this.props.eventId
        }, React.createElement(_LoadingScreen.default, null));
      }

      return React.createElement(_reactNative.View, {
        style: {
          flex: 1
        }
      }, React.createElement(_reactNative.Image, {
        source: _blue_background.default,
        style: styles.backgroundImage
      }), React.createElement(_sectionView.default, {
        spacingTop: "loose"
      }, React.createElement(_sectionView.default, {
        spacingTop: "extraLoose",
        spacingBottom: "base",
        spacingHorizontal: "extraLoose"
      }, React.createElement(_reactNative.Text, {
        style: styles.details
      }, "Details"))));
    }
  }]);
  return EventDetailInternal;
}(React.PureComponent);

exports.EventDetailInternal = EventDetailInternal;

EventDetailInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  var state = navigation.state;
  return {
    headerLeft: (0, _NavigationButtons.backButton)(navigation),
    headerRight: React.createElement(_reactNative.View, null),
    headerTransparent: true,
    headerStyle: {
      backgroundColor: navigation.getParam('BackgroundColor', state.params.bgColor)
    }
  };
};

var styles = _reactNative.StyleSheet.create({
  LoadingScreen: {
    flex: 1,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%'
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    flexDirection: 'column'
  },
  coverImage: {
    aspectRatio: 2 / 3,
    width: '100%',
    minHeight: _reactNative.Platform.OS === 'web' ? _reactNative.Dimensions.get('screen').height / 2.5 : 0
  },
  linearGradient: {
    bottom: 0,
    position: 'absolute',
    width: '100%',
    height: '50%'
  },
  eventDetailContainer: {
    width: '100%',
    aspectRatio: 2 / 3,
    justifyContent: 'flex-end',
    marginBottom: 24,
    minHeight: _reactNative.Platform.OS === 'web' ? _reactNative.Dimensions.get('screen').height / 2.5 : 0
  },
  eventDetail: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingHorizontal: 16
  },
  hashtagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 4,
    marginBottom: 24
  },
  hashtagText: {
    color: 'white',
    marginRight: 8,
    opacity: 0.7
  },
  djNameAndAvatarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16
  },
  djNameAndAvatar: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 16,
    overflow: 'hidden'
  },
  djNameContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    width: 0,
    flexGrow: 1
  },
  djAvatar: {
    borderColor: 'white',
    overflow: 'hidden',
    borderWidth: 2
  },
  djNameText: {
    color: 'white',
    marginBottom: 8,
    lineHeight: 29,
    flexWrap: 'wrap'
  },
  sideButtonsContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingVertical: 0
  },
  sideButtons: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  shareButton: {
    marginBottom: 16
  },
  shareBtnContentStyle: {
    borderColor: '#FFF',
    borderWidth: 1,
    backgroundColor: 'transparent',
    borderRadius: 44,
    width: 44,
    height: 44
  },
  tagViewsContainer: {
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  tagViewsText: {
    color: '#ffffff',
    lineHeight: 13,
    height: 13,
    marginVertical: 0,
    paddingBottom: 0
  },
  chatButtonContainer: {
    borderRadius: 56,
    width: 44,
    height: 44,
    borderWidth: 1,
    borderColor: '#FFF',
    marginBottom: 24
  },
  chatButtonTouchable: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  messageNotification: {
    position: 'absolute',
    right: -8,
    top: -8,
    width: 20,
    height: 20,
    borderRadius: 20,
    backgroundColor: 'red',
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 3
  },
  messageNotificationText: {
    color: 'white',
    marginVertical: 0,
    lineHeight: 13,
    height: 13
  },
  details: {
    fontWeight: 'bold',
    fontSize: 30,
    lineHeight: 36,
    color: 'black'
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
});

function mapStateToProps(state, ownProps) {
  return {};
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  joinLive: _live.default.actions.join,
  leaveLive: _live.default.actions.leave,
  loadEvent: _events.default.actions.loadEvent,
  loadEventsMusicType: _events.default.actions.loadEventsMusicType,
  loadFollowingsNumber: _profile.default.actions.loadFollowingsNumber,
  loadFollowersNumber: _profile.default.actions.loadFollowersNumber
})(EventDetailInternal);

exports.default = _default;