var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventsBoxInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactNavigation = require("react-navigation");

var _RenderRowEvent = _interopRequireDefault(require("./RenderRowEvent"));

var _Title = _interopRequireDefault(require("../../../common/typography_v1/Title"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var EventsBoxInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(EventsBoxInternal, _React$PureComponent);

  var _super = _createSuper(EventsBoxInternal);

  function EventsBoxInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, EventsBoxInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      screenWidth: 0,
      stages: null
    };
    return _this;
  }

  (0, _createClass2.default)(EventsBoxInternal, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      var boxElem = this.props.boxArray;
      var b = this.props.b;
      var smallDeviceWidth = this.props.screenWidth < 375 ? true : false;
      var itemWidth = smallDeviceWidth ? 288 : 300;
      return React.createElement(_reactNative.View, {
        key: 'box' + b,
        style: [styles.itemContainer, {
          width: itemWidth
        }]
      }, React.createElement(_Title.default, {
        style: styles.upNext
      }, b === 1 ? 'Up Next' : ' '), boxElem.map(function (boxItem) {
        return React.createElement(_RenderRowEvent.default, {
          eventId: boxItem.get('id'),
          screenWidth: _this2.props.screenWidth,
          key: 'Event' + boxItem.get('id')
        });
      }));
    }
  }]);
  return EventsBoxInternal;
}(React.PureComponent);

exports.EventsBoxInternal = EventsBoxInternal;

var styles = _reactNative.StyleSheet.create({
  upNext: {
    width: '100%',
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: 13,
    lineHeight: 16
  },
  itemContainer: {
    marginRight: 16
  }
});

var _default = (0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(EventsBoxInternal));

exports.default = _default;