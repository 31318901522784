var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _actions = require("./actions");

var constants = _interopRequireWildcard(require("./constants"));

function createMiddleware(socket) {
  return function (store) {
    socket.on(constants.WEBSOCKET_STATUS_CHANGED_EVENT, onStatusChanged(socket, store));
    return function (next) {
      return function (action) {
        var result = next(action);
        return result;
      };
    };
  };
}

var onStatusChanged = function onStatusChanged(socket, store) {
  return function (response) {
    store.dispatch((0, _actions.statusChanged)(socket.io.opts.query.liveId, response.status, response.liveUrl, response.description, response.hashTags, response.priority));
  };
};