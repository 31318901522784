Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calcCheckoutPrice = calcCheckoutPrice;

var _numberUtils = require("./number-utils");

function calcCheckoutPrice(addonVariations, ticketPrice, promotion, ticketQuantity) {
  var total = 0;
  var variationsTotal = 0;

  if (ticketPrice) {
    total = ticketPrice * ticketQuantity;
  }

  if (addonVariations === null || addonVariations === void 0 ? void 0 : addonVariations.length) {
    variationsTotal = addonVariations.reduce(function (total, variation) {
      return (variation === null || variation === void 0 ? void 0 : variation.price) ? total + variation.price : total;
    }, 0);
    total += variationsTotal * ticketQuantity;
  }

  total = (0, _numberUtils.toFixedNumber)(total, 2);
  variationsTotal = (0, _numberUtils.toFixedNumber)(variationsTotal * ticketQuantity, 2);

  if (promotion === 100) {
    return {
      total: 0,
      variationsTotal: variationsTotal,
      totalWithPromotion: 0
    };
  } else {
    return {
      total: total,
      variationsTotal: variationsTotal,
      totalWithPromotion: promotion ? (0, _numberUtils.toFixedNumber)(total - total * (promotion / 100), 2) : undefined
    };
  }
}