var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEventData = getEventData;
exports.getAddonData = getAddonData;
exports.getEventDataFromDomain = getEventDataFromDomain;
exports.getEventSections = getEventSections;
exports.detectEventDomainMismatch = detectEventDomainMismatch;
exports.findEventIdFromCustomEventSlug = findEventIdFromCustomEventSlug;
exports.findCustomEventSlugForEventId = findCustomEventSlugForEventId;
exports.renderPageWithEventList = renderPageWithEventList;
exports.FILTERED_DOMAINS = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _error = require("../../components/errors/error.consts");

var _routesConsts = require("../../navigation/client/routes-consts");

var _firebaseUtils = require("../../utils/firebase-utils");

var _NavigationService = _interopRequireDefault(require("../../navigation/NavigationService"));

var _reactNative = require("react-native");

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var moment = require('moment-timezone');

var EVENTS = _reactNativeFirebase.default.firestore().collection('events');

var DOMAINS = _reactNativeFirebase.default.firestore().collection('domains');

var EVENT_SLUGS = _reactNativeFirebase.default.firestore().collection('eventSlugs');

var SECTIONS_COLLECTION = 'landingPage';
var DEFAULT_CUSTOM_DOMAIN_FIELD = 'defaultCustomDomain';
var NAME_FIELD = 'name';
var EVENT_ID_FIELD = 'eventId';
var APPS = 'apps';
var CONFIG = 'config';
var DEFAULT_CONFIG = 'default-config';
var FILTERED_DOMAINS = ['localhost:3000', 'localhost:3001', 'dev-eventmix.web.app', 'dev-eventmix.firebaseapp.com', 'playground.eventmix.live', 'eventmix-prod.web.app', 'eventmix-prod.firebaseapp.com', 'app.eventmix.live'];
exports.FILTERED_DOMAINS = FILTERED_DOMAINS;

function getEventData(_x) {
  return _getEventData.apply(this, arguments);
}

function _getEventData() {
  _getEventData = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(eventId) {
    var eventDoc, eventData;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            if (eventId) {
              _context.next = 2;
              break;
            }

            throw new Error(_error.eventErrors.NO_EVENT_ASSOCIATED_TO_DOMAIN);

          case 2:
            _context.prev = 2;
            _context.next = 5;
            return EVENTS.doc(eventId).get();

          case 5:
            eventDoc = _context.sent;

            if (!eventDoc.exists) {
              _context.next = 11;
              break;
            }

            eventData = _objectSpread(_objectSpread({}, eventDoc.data()), {}, {
              id: eventId
            });
            return _context.abrupt("return", (0, _firebaseUtils.formatEventData)(eventData));

          case 11:
            throw new Error(_error.eventErrors.INVALID_EVENT_ID);

          case 12:
            _context.next = 17;
            break;

          case 14:
            _context.prev = 14;
            _context.t0 = _context["catch"](2);
            throw _context.t0;

          case 17:
          case "end":
            return _context.stop();
        }
      }
    }, _callee, null, [[2, 14]]);
  }));
  return _getEventData.apply(this, arguments);
}

function getAddonData(_x2) {
  return _getAddonData.apply(this, arguments);
}

function _getAddonData() {
  _getAddonData = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(addonId) {
    var addonConfig;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            _context2.prev = 0;
            _context2.next = 3;
            return _reactNativeFirebase.default.firestore().doc("".concat(APPS, "/").concat(addonId, "/").concat(CONFIG, "/").concat(DEFAULT_CONFIG)).get();

          case 3:
            addonConfig = _context2.sent;

            if (addonConfig.exists) {
              _context2.next = 6;
              break;
            }

            return _context2.abrupt("return", null);

          case 6:
            return _context2.abrupt("return", _objectSpread(_objectSpread({}, addonConfig.data()), {}, {
              id: addonId
            }));

          case 9:
            _context2.prev = 9;
            _context2.t0 = _context2["catch"](0);
            console.error("[ADD-ON] Error on getting add-on config, gameId: ".concat(addonId, ", error:").concat(JSON.stringify(_context2.t0)));

          case 12:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2, null, [[0, 9]]);
  }));
  return _getAddonData.apply(this, arguments);
}

function getEventDataFromDomain(_x3) {
  return _getEventDataFromDomain.apply(this, arguments);
}

function _getEventDataFromDomain() {
  _getEventDataFromDomain = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(domain) {
    var query, domainSnapshot, _domainSnapshot$docs$, events, eventsArray, promises;

    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            query = null;

            if (!FILTERED_DOMAINS.includes(domain)) {
              _context3.next = 5;
              break;
            }

            query = EVENTS.orderBy('endDate', 'desc').get();
            _context3.next = 23;
            break;

          case 5:
            _context3.next = 7;
            return DOMAINS.where(NAME_FIELD, '==', domain).get();

          case 7:
            domainSnapshot = _context3.sent;

            if (!domainSnapshot) {
              query = EVENTS.where(DEFAULT_CUSTOM_DOMAIN_FIELD, '==', domain).get();
            }

            if (!(domainSnapshot && domainSnapshot.docs)) {
              _context3.next = 23;
              break;
            }

            events = (_domainSnapshot$docs$ = domainSnapshot.docs[0]) === null || _domainSnapshot$docs$ === void 0 ? void 0 : _domainSnapshot$docs$.get('events');
            eventsArray = events ? Object.keys(events) : [];
            _context3.t0 = eventsArray.length;
            _context3.next = _context3.t0 === 0 ? 15 : _context3.t0 === 1 ? 17 : 20;
            break;

          case 15:
            query = EVENTS.where(DEFAULT_CUSTOM_DOMAIN_FIELD, '==', domain).get();
            return _context3.abrupt("break", 23);

          case 17:
            _context3.next = 19;
            return getEventData(eventsArray[0]);

          case 19:
            return _context3.abrupt("return", _context3.sent);

          case 20:
            promises = [];
            eventsArray.map(function (eventId) {
              promises.push(EVENTS.doc(eventId).get());
            });
            return _context3.abrupt("return", Promise.all(promises).then(function (snapshot) {
              throw renderPageWithEventList(snapshot);
            }));

          case 23:
            return _context3.abrupt("return", query.then(function (querySnapshot) {
              switch (querySnapshot.size) {
                case 0:
                  throw new Error(_error.eventErrors.NO_EVENT_ASSOCIATED_TO_DOMAIN);

                case 1:
                  var eventData = {};
                  querySnapshot.forEach(function (doc) {
                    eventData = _objectSpread(_objectSpread({}, doc.data()), {}, {
                      id: doc.id
                    });
                  });
                  return (0, _firebaseUtils.formatEventData)(eventData);

                default:
                  throw renderPageWithEventList(querySnapshot);
              }
            }).catch(function (error) {
              throw error;
            }));

          case 24:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));
  return _getEventDataFromDomain.apply(this, arguments);
}

function getEventSections(_x4) {
  return _getEventSections.apply(this, arguments);
}

function _getEventSections() {
  _getEventSections = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(eventId) {
    var collection, sections;
    return _regenerator.default.wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            if (eventId) {
              _context4.next = 2;
              break;
            }

            throw new Error(_error.eventErrors.NO_EVENT_ASSOCIATED_TO_DOMAIN);

          case 2:
            _context4.next = 4;
            return EVENTS.doc(eventId).collection(SECTIONS_COLLECTION).get();

          case 4:
            collection = _context4.sent;
            sections = [];

            if (collection) {
              collection.forEach(function (doc) {
                sections.push(_objectSpread(_objectSpread({}, doc.data()), {}, {
                  id: doc.id
                }));
              });
            }

            return _context4.abrupt("return", sections);

          case 8:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4);
  }));
  return _getEventSections.apply(this, arguments);
}

function detectEventDomainMismatch(_x5, _x6) {
  return _detectEventDomainMismatch.apply(this, arguments);
}

function _detectEventDomainMismatch() {
  _detectEventDomainMismatch = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee6(eventId, domain) {
    var domainSnap, eventIdToReturn;
    return _regenerator.default.wrap(function _callee6$(_context6) {
      while (1) {
        switch (_context6.prev = _context6.next) {
          case 0:
            if (!FILTERED_DOMAINS.includes(domain)) {
              _context6.next = 2;
              break;
            }

            return _context6.abrupt("return", eventId);

          case 2:
            _context6.next = 4;
            return DOMAINS.where(NAME_FIELD, '==', domain).limit(1).get();

          case 4:
            domainSnap = _context6.sent;
            eventIdToReturn = eventId;

            if (domainSnap) {
              domainSnap.forEach(function () {
                var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(doc) {
                  var _doc$data;

                  var events, eventsArray;
                  return _regenerator.default.wrap(function _callee5$(_context5) {
                    while (1) {
                      switch (_context5.prev = _context5.next) {
                        case 0:
                          events = ((_doc$data = doc.data()) === null || _doc$data === void 0 ? void 0 : _doc$data.events) || {};
                          eventsArray = Object.keys(events);
                          _context5.t0 = eventsArray.length;
                          _context5.next = _context5.t0 === 0 ? 5 : _context5.t0 === 1 ? 7 : 8;
                          break;

                        case 5:
                          eventIdToReturn = 'NONE';
                          throw new Error(_error.eventErrors.NO_EVENT_ASSOCIATED_TO_DOMAIN);

                        case 7:
                          if (eventsArray[0] !== eventId) {
                            _NavigationService.default.navigate(_routesConsts.Routes.HOME, {
                              eventId: eventsArray[0]
                            });

                            eventIdToReturn = eventsArray[0];
                          }

                        case 8:
                          if (!eventsArray.includes(eventId)) {
                            if (_reactNative.Platform.OS === 'web') {
                              window.location.replace('/');
                            }
                          }

                        case 9:
                        case "end":
                          return _context5.stop();
                      }
                    }
                  }, _callee5);
                }));

                return function (_x9) {
                  return _ref.apply(this, arguments);
                };
              }());
            }

            return _context6.abrupt("return", eventIdToReturn);

          case 8:
          case "end":
            return _context6.stop();
        }
      }
    }, _callee6);
  }));
  return _detectEventDomainMismatch.apply(this, arguments);
}

function findEventIdFromCustomEventSlug(_x7) {
  return _findEventIdFromCustomEventSlug.apply(this, arguments);
}

function _findEventIdFromCustomEventSlug() {
  _findEventIdFromCustomEventSlug = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee7(eventSlug) {
    var eventId, doc;
    return _regenerator.default.wrap(function _callee7$(_context7) {
      while (1) {
        switch (_context7.prev = _context7.next) {
          case 0:
            eventId = eventSlug;

            if (!eventSlug) {
              _context7.next = 6;
              break;
            }

            _context7.next = 4;
            return EVENT_SLUGS.doc(eventSlug).get();

          case 4:
            doc = _context7.sent;

            if (doc.get(EVENT_ID_FIELD)) {
              eventId = doc.get(EVENT_ID_FIELD);
            }

          case 6:
            return _context7.abrupt("return", eventId);

          case 7:
          case "end":
            return _context7.stop();
        }
      }
    }, _callee7);
  }));
  return _findEventIdFromCustomEventSlug.apply(this, arguments);
}

function findCustomEventSlugForEventId(_x8) {
  return _findCustomEventSlugForEventId.apply(this, arguments);
}

function _findCustomEventSlugForEventId() {
  _findCustomEventSlugForEventId = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee8(eventId) {
    var eventSlug, slugSnapshot;
    return _regenerator.default.wrap(function _callee8$(_context8) {
      while (1) {
        switch (_context8.prev = _context8.next) {
          case 0:
            eventSlug = eventId;

            if (!eventId) {
              _context8.next = 6;
              break;
            }

            _context8.next = 4;
            return EVENT_SLUGS.where(EVENT_ID_FIELD, '==', eventId).limit(1).get();

          case 4:
            slugSnapshot = _context8.sent;

            if (slugSnapshot) {
              slugSnapshot.forEach(function (doc) {
                eventSlug = doc.id;
              });
            }

          case 6:
            return _context8.abrupt("return", eventSlug);

          case 7:
          case "end":
            return _context8.stop();
        }
      }
    }, _callee8);
  }));
  return _findCustomEventSlugForEventId.apply(this, arguments);
}

function renderPageWithEventList(querySnapshot) {
  var eventArray = [];
  querySnapshot.forEach(function (doc) {
    var event = doc.data();
    var startDate = getTimestamp(event.startDate);
    var endDate = getTimestamp(event.endDate);
    eventArray.push({
      name: event.name,
      id: doc.id,
      startDate: event.startDate,
      endDate: event.endDate,
      image: event.bannerUrl || '',
      timezone: event.timezone,
      status: getStatus(startDate, endDate, event.timezone)
    });
  });
  var error = {
    message: _error.eventErrors.MORE_EVENTS_ASSOCIATED_TO_DOMAIN,
    info: eventArray
  };
  return error;
}

var getStatus = function getStatus(startDate, endDate, timezone) {
  startDate = moment(startDate).tz(timezone);
  endDate = moment(endDate).tz(timezone);
  var currentDate = moment();
  var startDifference = startDate.diff(currentDate, 'minutes');
  var endDifference = endDate.diff(currentDate, 'minutes');
  var status = startDifference <= 0 ? endDifference < 0 ? _i18n.default.translate('event.past') : _i18n.default.translate('event.live') : _i18n.default.translate('event.upcoming');
  return status;
};

var getTimestamp = function getTimestamp(date) {
  var d = date;
  date = new _reactNativeFirebase.default.firestore.Timestamp(d.seconds, d.nanoseconds).toDate();
  return date;
};