var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TableParticipant;

var _react = _interopRequireDefault(require("react"));

var _Avatar = _interopRequireDefault(require("../../../theme/components/avatar/Avatar"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _stringFormatter = require("../../../utils/string-formatter");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _relativeModal = _interopRequireDefault(require("../../../theme/components/relative-modal/relative-modal"));

var _profileCardModal = _interopRequireDefault(require("../profile-card-modal"));

function TableParticipant(_ref) {
  var name = _ref.name,
      picture = _ref.picture,
      id = _ref.id,
      handleKick = _ref.handleKick,
      isAdmin = _ref.isAdmin,
      isCurrentUser = _ref.isCurrentUser,
      isWatching = _ref.isWatching;
  var canKickParticipant = isAdmin && !isCurrentUser && !isWatching;
  return _react.default.createElement(_Spacing.default, {
    mSpacing: ['m', 'l'],
    direction: "row",
    wrap: "nowrap"
  }, _react.default.createElement(_relativeModal.default, {
    content: _react.default.createElement(_profileCardModal.default, {
      userId: id,
      displayName: name
    })
  }, function (_ref2) {
    var onOpen = _ref2.onOpen;
    return _react.default.createElement(_Spacing.default, {
      style: {
        cursor: 'pointer'
      }
    }, _react.default.createElement(_Avatar.default, {
      onPress: function onPress(e) {
        var messagePositions = e.target.getBoundingClientRect();
        e.preventDefault();
        e.stopPropagation();
        onOpen(messagePositions);
      },
      source: picture || "https://via.placeholder.com/40/000000/FFFFFF/?text=".concat((0, _stringFormatter.getInitials)(name)),
      name: name,
      size: 40
    }));
  }), _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 0, 's'],
    flex: 1,
    justifyContent: "space-between",
    alignItems: "stretch"
  }, _react.default.createElement(_core.Typography, {
    variant: "h4",
    align: "left"
  }, name, " ", isCurrentUser ? '(You)' : null), canKickParticipant ? _react.default.createElement(_core.Button, {
    style: {
      backgroundColor: 'red',
      color: 'white',
      borderRadius: 10
    },
    onClick: function onClick() {
      return handleKick(id);
    }
  }, _i18n.default.translate('participants.kick')) : null));
}