var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-settings/selectors");

var _constants = require("../../../core/polls/constants");

var _DesignSystem = require("../../../theme/DesignSystem");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _AutoConnect = _interopRequireDefault(require("../../chat/AutoConnect"));

var _blockedUsersList = _interopRequireDefault(require("../../chat/blocked-users-list"));

var _Chat = _interopRequireDefault(require("../../chat/Chat"));

var _usersList = _interopRequireDefault(require("../../chat/users-list"));

var _polls = _interopRequireDefault(require("../../common/polls"));

var _rightSideMenu = require("../../common/right-side-menu");

var _menuItem = _interopRequireDefault(require("../../common/right-side-menu/menu-item/menu-item"));

var _separator = _interopRequireDefault(require("../../common/right-side-menu/menu-item/separator"));

var _speakerRequests = _interopRequireDefault(require("./speaker-requests"));

var _featureNamesConstants = require("../../../constants/feature-names-constants");

var _useUserBackstageFeatures = _interopRequireDefault(require("../../../utils/hooks/useUserBackstageFeatures"));

var _selectors2 = require("../../../core/user-rooms/selectors");

var _core = require("@material-ui/core");

var _QA = _interopRequireDefault(require("../../chat/Q&A"));

var _selectors3 = require("../../../core/QAndA/selectors");

var ALLOWED_FEATURES = [_featureNamesConstants.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS, _featureNamesConstants.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_STAGE, _featureNamesConstants.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_SESSION];

var BackstageMenu = function BackstageMenu(_ref) {
  var _chatSettings$disable, _qaSettings$disabled;

  var backstageId = _ref.backstageId;
  var chatSettings = (0, _reactRedux.useSelector)(_selectors.chatSettingsSelector);
  var qaSettings = (0, _reactRedux.useSelector)(_selectors.qaSettingsSelector);
  var chatDisabled = !!((_chatSettings$disable = chatSettings.disabled) === null || _chatSettings$disable === void 0 ? void 0 : _chatSettings$disable.stages);
  var qaDisabled = !!((_qaSettings$disabled = qaSettings.disabled) === null || _qaSettings$disabled === void 0 ? void 0 : _qaSettings$disabled.stages);
  var requestsEnabled = (0, _useUserBackstageFeatures.default)(backstageId, ALLOWED_FEATURES);
  var participants = (0, _reactRedux.useSelector)((0, _selectors2.participantsWithRequestSelector)(backstageId));
  var length = Object.keys(participants).length;
  var questionsAndAnswers = (0, _reactRedux.useSelector)(_selectors3.selectQuestionsList);
  var numberOfQuestions = questionsAndAnswers.length;
  var smallResolution = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.bg;
  });
  var menuBuilder = new _rightSideMenu.MenuBuilder();
  menuBuilder.add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.REQUESTS, function (_ref3) {
    var isSelected = _ref3.isSelected,
        onSelect = _ref3.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "Requests",
      onPress: onSelect,
      icon: _react.default.createElement(_core.Badge, {
        badgeContent: length,
        color: "error"
      }, _react.default.createElement(_reactFeather.UserPlus, null)),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.requests'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.REQUESTS, _react.default.createElement(_speakerRequests.default, null)).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.INTERNAL_CHAT, function (_ref4) {
    var isSelected = _ref4.isSelected,
        onSelect = _ref4.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "Backstage Chat",
      onPress: onSelect,
      icon: _react.default.createElement(_reactFeather.MessageSquare, null),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.backstageChat'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.INTERNAL_CHAT, _react.default.createElement(_AutoConnect.default, {
    id: "".concat(backstageId, "-BACKSTAGE-CHAT")
  }, _react.default.createElement(_Chat.default, {
    rejoinId: "".concat(backstageId, "-BACKSTAGE-CHAT")
  }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.SEPARATOR, function () {
    return _react.default.createElement(_separator.default, null);
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.SEPARATOR, null).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.CHAT, function (_ref5) {
    var isSelected = _ref5.isSelected,
        onSelect = _ref5.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "Chat",
      onPress: onSelect,
      icon: _react.default.createElement(_reactFeather.MessageCircle, null),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.chat'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.CHAT, _react.default.createElement(_AutoConnect.default, {
    id: backstageId
  }, _react.default.createElement(_Chat.default, {
    rejoinId: backstageId,
    disabled: chatDisabled,
    disabledMessage: chatSettings.disabledMessage
  }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.CHAT_PARTICIPANTS, function (_ref6) {
    var isSelected = _ref6.isSelected,
        onSelect = _ref6.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "People",
      onPress: onSelect,
      icon: _react.default.createElement(_reactFeather.Users, null),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.people'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.CHAT_PARTICIPANTS, _react.default.createElement(_AutoConnect.default, {
    id: backstageId
  }, _react.default.createElement(_usersList.default, null))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.QA, function (_ref7) {
    var isSelected = _ref7.isSelected,
        onSelect = _ref7.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "QandA",
      onPress: onSelect,
      icon: _react.default.createElement(_core.Badge, {
        badgeContent: numberOfQuestions,
        color: "primary"
      }, _react.default.createElement(_reactFeather.HelpCircle, null)),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.qa'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.QA, _react.default.createElement(_AutoConnect.default, {
    id: backstageId
  }, _react.default.createElement(_QA.default, null))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.POLLS, function (_ref8) {
    var isSelected = _ref8.isSelected,
        onSelect = _ref8.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "Polls",
      onPress: onSelect,
      icon: _react.default.createElement(_reactFeather.BarChart, null),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.polls'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.POLLS, _react.default.createElement(_polls.default, {
    location: _constants.POLL_TYPES.LIVE
  })).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PARTICIPANTS, function (_ref9) {
    var isSelected = _ref9.isSelected,
        onSelect = _ref9.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "Abuse",
      onPress: onSelect,
      icon: _react.default.createElement(_reactFeather.Shield, null),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.abuse'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PARTICIPANTS, _react.default.createElement(_AutoConnect.default, {
    id: backstageId
  }, _react.default.createElement(_blockedUsersList.default, null)));
  return _react.default.createElement(_react.default.Fragment, null, menuBuilder.buildBackstageMenu({
    chatDisabled: chatDisabled,
    requestsDisabled: !requestsEnabled,
    separatorDisabled: smallResolution
  }));
};

var _default = BackstageMenu;
exports.default = _default;