var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LiveEventItemInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _reactNavigation = require("react-navigation");

var _reactNative = require("react-native");

var _events = _interopRequireDefault(require("../../../core/events"));

var _reactNativePaper = require("react-native-paper");

var _Paragraph = _interopRequireDefault(require("../../common/typography_v1/Paragraph"));

var _Caption = _interopRequireDefault(require("../../common/typography_v1/Caption"));

var _PulseDot = _interopRequireDefault(require("../../common/pulse/PulseDot"));

var _ParagraphSmall = _interopRequireDefault(require("../../common/typography_v1/ParagraphSmall"));

var _Button = _interopRequireDefault(require("../../common/Button"));

var _VipBadge = _interopRequireDefault(require("../../common/vip/VipBadge"));

var _LiveTimeCounter = require("../../common/LiveTimeCounter");

var _CustomCachedImage = _interopRequireDefault(require("../../common/CustomCachedImage"));

var _moment = _interopRequireDefault(require("moment"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var LiveEventItemInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(LiveEventItemInternal, _React$PureComponent);

  var _super = _createSuper(LiveEventItemInternal);

  function LiveEventItemInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, LiveEventItemInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      screenWidth: _reactNative.Dimensions.get('window').width,
      image: undefined,
      timeCount: (0, _LiveTimeCounter.TimeCount)((0, _moment.default)(_this.props.startDate))
    };
    _this.liveTimeCounter = undefined;

    _this.setImageFromLiveUrl = function () {
      var regex = /.*\.mux.com\/(\w+)\.m3u8/gm;
      var match = regex.exec(_this.props.liveUrl);

      _this.setState({
        image: match ? "https://image.mux.com/".concat(match[1], "/animated.gif?fps=8&width=256") : _this.props.coverImage
      });
    };

    _this.updateLiveTimeCounter = function () {
      var date = (0, _moment.default)(_this.props.startDate);

      _this.setState({
        timeCount: (0, _LiveTimeCounter.TimeCount)(date)
      });
    };

    _this.handleImageLoadError = function () {
      if (_this.state.image !== _this.props.coverImage) {
        _this.setState({
          image: _this.props.coverImage
        });
      }
    };

    return _this;
  }

  (0, _createClass2.default)(LiveEventItemInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.liveTimeCounter = setInterval(this.updateLiveTimeCounter, 1000);
      this.setImageFromLiveUrl();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.liveTimeCounter) {
        clearInterval(this.liveTimeCounter);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var smallDeviceWidth = this.state.screenWidth < 375 ? true : false;
      var itemWidth = this.props.countOneLiveEvent ? this.state.screenWidth - 32 : smallDeviceWidth ? 250 : 300;
      return React.createElement(_reactNative.View, {
        key: this.props.eventId,
        style: [styles.itemContainer, {
          width: itemWidth
        }]
      }, React.createElement(_reactNative.TouchableHighlight, {
        testID: 'TapImageLiveNow' + this.props.eventId,
        onPress: function onPress() {
          _this2.props.navigation.navigate('EventLive', {
            liveId: _this2.props.eventId
          });
        },
        style: styles.itemImageContainer
      }, React.createElement(_CustomCachedImage.default, {
        testID: "cover",
        style: styles.itemImage,
        source: {
          uri: this.state.image
        },
        onError: this.handleImageLoadError,
        resizeMode: "cover"
      })), React.createElement(_reactNative.View, {
        style: styles.livelabelContainer
      }, React.createElement(_reactNative.View, {
        style: styles.liveLabel
      }, React.createElement(_PulseDot.default, null), React.createElement(_Caption.default, {
        style: styles.liveLabelText
      }, "LIVE"))), this.props.showVipBadge && React.createElement(_VipBadge.default, {
        eventId: this.props.eventId,
        style: {
          right: 0
        }
      }), React.createElement(_reactNative.View, {
        style: styles.eventDetails
      }, React.createElement(_reactNative.View, {
        style: styles.itemTitleSection
      }, React.createElement(_reactNativePaper.TouchableRipple, {
        testID: 'TapDj' + this.props.djId,
        onPress: function onPress() {
          return _this2.props.navigation.navigate('Profile', {
            userId: _this2.props.djId.toString(),
            djName: _this2.props.djName,
            avatar: _this2.props.djPicture
          });
        }
      }, React.createElement(_Paragraph.default, {
        numberOfLines: 1,
        ellipsizeMode: "tail",
        style: styles.itemTitle
      }, this.props.djName)), React.createElement(_ParagraphSmall.default, {
        style: styles.itemPeriod
      }, this.state.timeCount)), React.createElement(_reactNative.View, {
        style: {}
      }, React.createElement(_Button.default, {
        testID: 'TapLiveNow' + this.props.eventId,
        mode: "contained",
        dark: false,
        color: "#ffffff",
        buttonSize: "normal",
        disabled: false,
        onPress: function onPress() {
          _this2.props.navigation.navigate('EventLive', {
            liveId: _this2.props.eventId
          });
        }
      }, "Join"))));
    }
  }]);
  return LiveEventItemInternal;
}(React.PureComponent);

exports.LiveEventItemInternal = LiveEventItemInternal;

var styles = _reactNative.StyleSheet.create({
  sectionContainer: {
    marginVertical: 20
  },
  itemContainer: {
    marginRight: 16,
    maxWidth: _reactNative.Platform.OS === 'web' ? '100%' : undefined
  },
  itemImageContainer: {
    aspectRatio: 16 / 9,
    width: '100%'
  },
  itemImage: {
    aspectRatio: 16 / 9,
    width: '100%',
    borderRadius: 4,
    minHeight: _reactNative.Platform.OS === 'web' ? _reactNative.Dimensions.get('screen').height / 4.8 : 0
  },
  sectionTitle: {
    lineHeight: 17,
    color: 'white',
    paddingHorizontal: 16,
    marginBottom: 16
  },
  eventDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12
  },
  itemTitleSection: {
    maxWidth: 200,
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  itemTitle: {
    lineHeight: 15,
    marginBottom: 4
  },
  itemPeriod: {
    color: 'rgba(255,255,255,0.6)',
    lineHeight: 13
  },
  livelabelContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 8,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    aspectRatio: 16 / 9
  },
  liveLabel: {
    backgroundColor: 'red',
    paddingLeft: 2,
    paddingRight: 4,
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 20
  },
  liveLabelText: {
    color: '#fff',
    lineHeight: 11,
    height: 11
  },
  numUsersLive: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'rgba(0,0,0, 0.4)',
    paddingHorizontal: 8,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2
  },
  numUsersLiveText: {
    color: '#fff',
    marginLeft: 5,
    lineHeight: 11,
    height: 11,
    marginVertical: 0,
    paddingBottom: 0
  }
});

function mapStateToProps(state, ownProps) {
  var eventId = ownProps.eventId;
  return {
    key: eventId,
    liveUrl: _events.default.selectors.getLiveUrl(state, eventId),
    showVipBadge: _events.default.selectors.hasAccessTicket(state, eventId),
    coverImage: _events.default.selectors.getCoverImage(state, eventId),
    numFollowers: _events.default.selectors.getNumFollowers(state, eventId),
    startDate: _events.default.selectors.getStartDate(state, eventId),
    djId: _events.default.selectors.getDjId(state, eventId),
    djName: _events.default.selectors.getDjName(state, eventId),
    djPicture: _events.default.selectors.getDjPicture(state, eventId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(LiveEventItemInternal)));

exports.default = _default;