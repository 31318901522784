var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/chat-user/selectors");

var _actions = require("../../../core/chat-user/actions");

var _core = require("@material-ui/core");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Avatar = _interopRequireDefault(require("../../../theme/components/avatar/Avatar"));

var _stringFormatter = require("../../../utils/string-formatter");

var _profileStyles = _interopRequireDefault(require("./profile-styles"));

var _reactFeather = require("react-feather");

var _externalLinkUtils = require("../../../utils/external-link-utils");

var _styles = _interopRequireDefault(require("./styles"));

var ProfileCard = function ProfileCard(_ref) {
  var userId = _ref.userId,
      displayName = _ref.displayName;
  var dispatch = (0, _reactRedux.useDispatch)();
  var Styles = (0, _profileStyles.default)();
  var classes = (0, _styles.default)();
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectChatUserProfileIsLoading);
  var profileInfo = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.selectChatUserProfileById)(state, userId);
  });
  (0, _react.useEffect)(function () {
    if (!profileInfo) {
      dispatch((0, _actions.getChatUserProfileInfo)(userId));
    }
  }, [profileInfo]);

  if (isLoading || !profileInfo) {
    return _react.default.createElement(_core.Typography, null, "Loading...");
  }

  return profileInfo && _react.default.createElement(_core.Box, {
    m: 1,
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_core.Box, {
    m: 2,
    className: classes.avatar
  }, _react.default.createElement(_Avatar.default, {
    source: profileInfo.photo || "https://via.placeholder.com/120/000000/FFFFFF/?text=".concat((0, _stringFormatter.getInitials)(displayName)),
    name: displayName,
    size: 120
  })), _react.default.createElement(_core.Box, {
    m: 0.5
  }, _react.default.createElement(_core.Typography, {
    variant: "h3"
  }, displayName)), _react.default.createElement(_core.Box, {
    m: 0.5
  }, _react.default.createElement(_core.Typography, {
    variant: "caption",
    align: "center"
  }, profileInfo.headline)), _react.default.createElement(_core.Box, {
    my: 2
  }, _react.default.createElement(_core.Typography, {
    variant: "body1",
    align: "justify"
  }, profileInfo.bio)), _react.default.createElement(_core.Box, {
    my: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, null, profileInfo.facebook && _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.Facebook, {
    onClick: function onClick() {
      return (0, _externalLinkUtils.openExternalUrl)(profileInfo.facebook);
    }
  })), profileInfo.twitter && _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.Twitter, {
    onClick: function onClick() {
      return (0, _externalLinkUtils.openExternalUrl)(profileInfo.twitter);
    }
  })), profileInfo.linkedin && _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.Linkedin, {
    onClick: function onClick() {
      return (0, _externalLinkUtils.openExternalUrl)(profileInfo.linkedin);
    }
  })), profileInfo.instagram && _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.Instagram, {
    onClick: function onClick() {
      return (0, _externalLinkUtils.openExternalUrl)(profileInfo.instagram);
    }
  })), _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.Mail, {
    onClick: function onClick() {
      return (0, _externalLinkUtils.sendEmailTo)(profileInfo.email);
    }
  })))));
};

var _default = ProfileCard;
exports.default = _default;