var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.GoogleLoginButtonInternal = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _googleSignin = _interopRequireWildcard(require("@react-native-community/google-signin"));

var _common = require("../common");

var _user = _interopRequireDefault(require("../../core/user"));

var _iconButton = _interopRequireDefault(require("design-system/actions/icon-button"));

var _Analytics = require("../../core/services/Analytics");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var GoogleLoginButtonInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(GoogleLoginButtonInternal, _React$PureComponent);

  var _super = _createSuper(GoogleLoginButtonInternal);

  function GoogleLoginButtonInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, GoogleLoginButtonInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      inProgress: false
    };
    _this.pendingLogin = void 0;
    _this.pendingLoginWeb = void 0;
    _this.handlePress = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              (0, _Analytics.trackEvent)('login_press', {
                provider: _user.default.constants.LOGIN_PROVIDERS.GOOGLE
              });

              _this.setState({
                inProgress: true
              });

              _this.pendingLogin = (0, _common.makeCancelable)(_googleSignin.GoogleSignin.hasPlayServices().then(function (hasPlayServices) {
                if (hasPlayServices) {
                  return _googleSignin.GoogleSignin.signIn().then(function (userInfo) {
                    if (userInfo && userInfo.idToken) {
                      return _this.props.login(_user.default.constants.LOGIN_PROVIDERS.GOOGLE, userInfo.idToken).then(function () {
                        if (_this.props.onSuccess) {
                          _this.props.onSuccess();
                        }
                      });
                    }
                  }).catch(function (error) {
                    if (!error.isCancelled) {
                      _this.setState({
                        inProgress: false
                      });
                    }
                  });
                }
              }));

              _this.pendingLogin.promise.then(function () {
                _this.setState({
                  inProgress: false
                });
              }).catch(function (_ref2) {
                var isCancelled = _ref2.isCancelled;

                if (!isCancelled) {
                  _this.setState({
                    inProgress: false
                  });
                }
              });

            case 4:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    _this.handleResponseGoogle = function (response) {
      if (response && response.tokenId) {
        _this.pendingLoginWeb = (0, _common.makeCancelable)(_this.props.login(_user.default.constants.LOGIN_PROVIDERS.GOOGLE, response.tokenId).then(function (res) {
          if (_this.props.onSuccess) {
            _this.props.onSuccess();
          }
        }));

        _this.pendingLoginWeb.promise.then(function () {
          _this.setState({
            inProgress: false
          });
        }).catch(function (_ref3) {
          var isCancelled = _ref3.isCancelled;

          if (!isCancelled) {
            _this.setState({
              inProgress: false
            });
          }
        });
      }
    };

    _this.handleGoogleFailure = function () {
      return _this.setState({
        inProgress: false
      });
    };

    _this.handleGoogleRequest = function () {
      return _this.setState({
        inProgress: true
      });
    };

    _this.getButtonProps = function () {
      return {
        color: '#fff',
        iconColor: 'black',
        icon: 'google',
        circleButton: true,
        size: 'large',
        disabled: _this.state.inProgress,
        style: _this.props.style
      };
    };

    return _this;
  }

  (0, _createClass2.default)(GoogleLoginButtonInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var config = {
        scopes: ['profile', 'email']
      };

      if (_reactNative.Platform.OS === 'android') {
        config = (0, _extends2.default)(config, {
          webClientId: process.env.GOOGLE_CLIENT_ID || ''
        });
      }

      _googleSignin.GoogleSignin.configure(config);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.pendingLogin) {
        this.pendingLogin.cancel();
      }

      if (this.pendingLoginWeb) {
        this.pendingLoginWeb.cancel();
      }
    }
  }, {
    key: "render",
    value: function render() {
      if (_reactNative.Platform.OS === 'web') {
        return _react.default.createElement(_googleSignin.default, {
          buttonProps: _objectSpread({}, this.getButtonProps()),
          clientId: process.env.GOOGLE_CLIENT_ID,
          onRequest: this.handleGoogleRequest,
          onSuccess: this.handleResponseGoogle,
          onFailure: this.handleGoogleFailure
        });
      } else {
        return _react.default.createElement(_iconButton.default, (0, _extends2.default)({
          testID: 'GoogleAuthButton',
          onPress: this.handlePress
        }, this.getButtonProps()));
      }
    }
  }]);
  return GoogleLoginButtonInternal;
}(_react.default.PureComponent);

exports.GoogleLoginButtonInternal = GoogleLoginButtonInternal;

var _default = (0, _reactRedux.connect)(null, {
  login: _user.default.actions.loginWithProvider
})(GoogleLoginButtonInternal);

exports.default = _default;