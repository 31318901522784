var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = UserSection;

var _react = _interopRequireDefault(require("react"));

var _Avatar = _interopRequireDefault(require("../../../../theme/components/avatar/Avatar"));

var _relativeModal = _interopRequireDefault(require("../../../../theme/components/relative-modal/relative-modal"));

var _stringFormatter = require("../../../../utils/string-formatter");

var _profileCardModal = _interopRequireDefault(require("../../profile-card-modal"));

function UserSection(_ref) {
  var userInfo = _ref.userInfo;
  return _react.default.createElement(_relativeModal.default, {
    content: _react.default.createElement(_profileCardModal.default, {
      userId: userInfo.userId,
      displayName: userInfo.userName
    })
  }, function (_ref2) {
    var onOpen = _ref2.onOpen;
    return _react.default.createElement(_Avatar.default, {
      onPress: function onPress(e) {
        var messagePositions = e.target.getBoundingClientRect();
        e.preventDefault();
        e.stopPropagation();
        onOpen(messagePositions);
      },
      source: userInfo.avatar || "https://via.placeholder.com/40/000000/FFFFFF/?text=".concat((0, _stringFormatter.getInitials)(userInfo.userName)),
      name: userInfo.userName,
      size: 40
    });
  });
}