var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AnimatedLayer;

var _react = _interopRequireWildcard(require("react"));

var _konva = _interopRequireDefault(require("konva"));

var _reactKonva = require("react-konva");

var _logger = _interopRequireDefault(require("../../../../core/services/logger"));

function AnimatedLayer(_ref) {
  var children = _ref.children,
      stream = _ref.stream,
      fps = _ref.fps;
  var layerRef = (0, _react.useRef)(null);
  (0, _react.useEffect)(function () {
    if (!layerRef.current) return;
    if (!stream) return;
    var anim = new _konva.default.Animation(function () {}, layerRef.current);
    anim.start();

    _logger.default.debug('Canvas rendering started!');

    layerRef.current.getCanvas()._canvas.captureStream(fps).getTracks().forEach(function (track) {
      stream.addTrack(track);

      _logger.default.debug('Canvas track added!');
    });

    _logger.default.debug('Canvas stream capturing!');

    return function () {
      anim.stop();
    };
  }, [stream, fps]);
  return _react.default.createElement(_reactKonva.Layer, {
    ref: layerRef
  }, children);
}