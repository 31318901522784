Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    title: {
      maxWidth: '90%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    container: {
      width: '100%',
      minWidth: 0,
      maxWidth: 471,
      boxSizing: 'border-box',
      '@media(max-width: 600px)': {
        padding: theme.spacing(0),
        marginBottom: theme.spacing(2)
      }
    },
    outerContainerFull: {
      overflow: 'hidden',
      borderRadius: 10,
      backgroundColor: theme.palette.borderColor.main,
      minHeight: 400,
      boxSizing: 'border-box',
      position: 'relative'
    },
    outerContainer: {
      overflow: 'hidden',
      borderRadius: 10,
      backgroundColor: theme.palette.button.main + '44',
      boxSizing: 'border-box',
      position: 'relative',
      minHeight: 400
    },
    leftColumn: {
      marginLeft: -theme.spacing(8),
      flex: 2
    },
    centerColumn: {
      marginTop: -theme.spacing(10),
      marginBottom: -theme.spacing(10),
      flex: 4
    },
    rightColumn: {
      marginRight: -theme.spacing(8),
      flex: 2
    },
    titleContainer: {
      position: 'absolute',
      width: '100%',
      left: 0,
      bottom: 16
    },
    placeholder: {
      backgroundColor: theme.palette.containerBackground.main,
      borderRadius: theme.spacing(3),
      border: '2px solid',
      borderColor: theme.palette.borderColor.main
    },
    image: {
      '& > div': {
        minHeight: 210,
        minWidth: 296,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        backgroundColor: theme.palette.alternativeBackground.main,
        borderWidth: 5,
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: theme.palette.alternativeBackground.main,
        '@media(max-width: 600px)': {
          minWidth: 70
        }
      }
    }
  };
});
var _default = styles;
exports.default = _default;