var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.NewVersionSnackBarInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _notifications = _interopRequireDefault(require("../../core/notifications"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var NewVersionSnackBarInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(NewVersionSnackBarInternal, _React$PureComponent);

  var _super = _createSuper(NewVersionSnackBarInternal);

  function NewVersionSnackBarInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, NewVersionSnackBarInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      visible: _this.props.hasNewerVersion
    };

    _this.upgrade = function () {
      _reactNative.Linking.openURL(_this.props.upgradeUrl).catch(function () {});
    };

    return _this;
  }

  (0, _createClass2.default)(NewVersionSnackBarInternal, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      var colors = this.props.theme.colors;
      return _react.default.createElement(_reactNativePaper.Portal, null, _react.default.createElement(_reactNativePaper.Snackbar, {
        style: {
          backgroundColor: colors.snackbarBG,
          marginBottom: 56
        },
        visible: this.state.visible,
        onDismiss: function onDismiss() {
          return _this2.setState({
            visible: false
          });
        },
        action: {
          label: 'Upgrade',
          onPress: this.upgrade
        },
        duration: _reactNativePaper.Snackbar.DURATION_SHORT
      }, "A new app version is available. Update it now."));
    }
  }]);
  return NewVersionSnackBarInternal;
}(_react.default.PureComponent);

exports.NewVersionSnackBarInternal = NewVersionSnackBarInternal;

function mapStateToProps(state) {
  return {
    key: _notifications.default.selectors.getLatestAvailableVersion(state),
    isDeprecated: _notifications.default.selectors.isAppDeprecated(state),
    hasNewerVersion: _notifications.default.selectors.hasNewerVersion(state),
    latestAvailableVersion: _notifications.default.selectors.getLatestAvailableVersion(state),
    upgradeUrl: _notifications.default.selectors.getLatestAvailableVersionUrl(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  snooze: _notifications.default.actions.snoozeDeprecationWarning
})((0, _reactNativePaper.withTheme)(NewVersionSnackBarInternal));

exports.default = _default;