var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResetPasswordCustomView;

var _formik = require("formik");

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var yup = _interopRequireWildcard(require("yup"));

var _eventLogo = _interopRequireDefault(require("../../common/event-logo"));

var _messageBlock = _interopRequireDefault(require("../../common/message-block"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _core = require("@material-ui/core");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Input = _interopRequireDefault(require("../../../theme/components/inputs/Input"));

var _reactFeather = require("react-feather");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _LinkButton = _interopRequireDefault(require("../../../theme/components/buttons/LinkButton"));

var _authStyles = _interopRequireDefault(require("../auth-styles"));

var _styles = _interopRequireDefault(require("./styles"));

var _MuiLinkButton = _interopRequireDefault(require("./../../common/custom-design-components/MuiLinkButton"));

function ResetPasswordCustomView(_ref) {
  var onResetPassword = _ref.onResetPassword,
      goToRegister = _ref.goToRegister,
      goToLogin = _ref.goToLogin,
      isLoading = _ref.isLoading,
      resetPasswordFeedback = _ref.resetPasswordFeedback;
  var Styles = (0, _authStyles.default)();
  var classes = (0, _styles.default)();
  return _react.default.createElement(_Spacing.default, {
    alignItems: "stretch",
    style: Styles.container,
    mSpacing: [0, 0, 'xxl', 0],
    direction: "column"
  }, _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_eventLogo.default, null)), _react.default.createElement(_Section.default, {
    shaped: true,
    shaping: "forms",
    color: "containerBackground"
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 'xxl'],
    style: Styles.authBox,
    direction: "column",
    alignItems: "stretch",
    justifyContent: "center"
  }, resetPasswordFeedback && _react.default.createElement(_Spacing.default, {
    alignItems: "stretch",
    direction: "column",
    mSpacing: [0, 0, 'xl', 0]
  }, _react.default.createElement(_messageBlock.default, {
    level: "warning"
  }, _i18n.default.translate("errors.".concat(resetPasswordFeedback), {
    defaultValue: resetPasswordFeedback
  }))), _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'l', 0],
    direction: "column",
    alignItems: "center"
  }, _react.default.createElement(_core.Typography, {
    variant: "h2",
    className: classes.fontWeightNormal
  }, _i18n.default.translate('resetPassword.resetPassword'))), _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'l', 0]
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate('resetPassword.resetActionText'))), _react.default.createElement(_formik.Formik, {
    initialValues: {
      password: '',
      passwordConfirm: ''
    },
    onSubmit: function onSubmit(_ref2) {
      var password = _ref2.password;
      return onResetPassword(password);
    },
    validationSchema: yup.object().shape({
      password: yup.string().required(_i18n.default.t('login.passwordRequired')),
      passwordConfirm: yup.string().required(_i18n.default.t('login.passwordRequired')).oneOf([yup.ref('password'), null], _i18n.default.t('register.passwordMatch'))
    })
  }, function (_ref3) {
    var values = _ref3.values,
        handleChange = _ref3.handleChange,
        errors = _ref3.errors,
        setFieldTouched = _ref3.setFieldTouched,
        touched = _ref3.touched,
        isValid = _ref3.isValid,
        submitForm = _ref3.submitForm;
    return _react.default.createElement(_react.Fragment, null, _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'l', 0],
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'base', 0]
    }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('login.password'))), _react.default.createElement(_Input.default, {
      shaped: true,
      color: "alternativeText",
      value: values.password,
      onChange: handleChange,
      onKeyPress: function onKeyPress(_ref4) {
        var key = _ref4.nativeEvent.key;
        return key === 'Enter' && submitForm();
      },
      name: "password",
      secureTextEntry: true,
      placeholder: '********',
      returnKeyType: 'next',
      error: !!errors.password && touched.password,
      onBlur: function onBlur() {
        return setFieldTouched('password');
      },
      leftIcon: _react.default.createElement(_reactFeather.Key, null)
    }), !!errors.password && touched.password && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_core.Typography, {
      variant: "caption",
      color: "error"
    }, errors.password))), _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'l', 0],
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'base', 0]
    }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('register.passwordConfirm'))), _react.default.createElement(_Input.default, {
      shaped: true,
      color: "alternativeText",
      value: values.passwordConfirm,
      onChange: handleChange,
      onKeyPress: function onKeyPress(_ref5) {
        var key = _ref5.nativeEvent.key;
        return key === 'Enter' && submitForm();
      },
      name: "passwordConfirm",
      secureTextEntry: true,
      placeholder: '********',
      returnKeyType: 'next',
      error: !!errors.password && touched.passwordConfirm,
      onBlur: function onBlur() {
        return setFieldTouched('passwordConfirm');
      },
      leftIcon: _react.default.createElement(_reactFeather.Key, null)
    }), !!errors.passwordConfirm && touched.passwordConfirm && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_core.Typography, {
      variant: "caption",
      color: "error"
    }, errors.passwordConfirm))), _react.default.createElement(_Spacing.default, {
      mSpacing: ['xs', 0, 'l', 0],
      direction: "row",
      alignItems: "center",
      wrap: "wrap",
      justifyContent: "space-between"
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: ['s', 0]
    }, _react.default.createElement(_MuiLinkButton.default, {
      variant: "caption",
      onClick: goToRegister,
      translationKey: 'login.register'
    })), _react.default.createElement(_Spacing.default, {
      mSpacing: ['s', 0]
    }, _react.default.createElement(_MuiLinkButton.default, {
      variant: "caption",
      onClick: goToLogin,
      translationKey: 'login.login'
    }))), _react.default.createElement(_ContainedButton.default, {
      disabled: isLoading,
      onPress: function onPress() {
        if (isValid) {
          submitForm();
        }
      }
    }, isLoading ? _react.default.createElement(_reactNative.ActivityIndicator, {
      size: "small",
      color: "#fff"
    }) : _i18n.default.t('resetPassword.resetPassword')));
  }))));
}