var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _actions = require("../../../core/polls/actions");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _editPoll = _interopRequireDefault(require("./edit-poll"));

var _newPoll = _interopRequireDefault(require("./new-poll"));

var _pollsList = _interopRequireDefault(require("./polls-list"));

var _pollsStyles = _interopRequireDefault(require("./polls-styles"));

var _singlePoll = _interopRequireDefault(require("./single-poll"));

var POLLS_LIST = 'polls-list';
var NEW_POLL = 'polls-new';
var SINGLE_POLL = 'polls-single';
var EDIT_POLL = 'polls-edit';

var PollsContainer = function PollsContainer(_ref) {
  var location = _ref.location;
  var dispatch = (0, _reactRedux.useDispatch)();
  var Styles = (0, _pollsStyles.default)();

  var _useState = (0, _react.useState)(POLLS_LIST),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      visibleBlock = _useState2[0],
      setVisibleBlock = _useState2[1];

  var _useState3 = (0, _react.useState)(null),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      selectedPoll = _useState4[0],
      setSelectedPoll = _useState4[1];

  _react.default.useEffect(function () {
    dispatch((0, _actions.resetPolls)());
    dispatch((0, _actions.getPolls)(location));
    var unsubscribe = null;
    (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      var _yield$dispatch, payload;

      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return dispatch((0, _actions.subscribeToPollChanges)(location));

            case 2:
              _yield$dispatch = _context.sent;
              payload = _yield$dispatch.payload;
              unsubscribe = payload;

            case 5:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }))();
    return function () {
      unsubscribe && unsubscribe();
    };
  }, [dispatch]);

  return _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: Styles.scrollView
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    mSpacing: "m",
    alignItems: "stretch",
    style: Styles.pollsContainer
  }, _react.default.createElement(_react.default.Fragment, null, visibleBlock === POLLS_LIST && _react.default.createElement(_pollsList.default, {
    onNewPoll: function onNewPoll() {
      return setVisibleBlock(NEW_POLL);
    },
    onSinglePoll: function onSinglePoll(poll) {
      setVisibleBlock(SINGLE_POLL);
      setSelectedPoll(poll.id);
    },
    onEditPoll: function onEditPoll(poll) {
      setVisibleBlock(EDIT_POLL);
      setSelectedPoll(poll.id);
    }
  }), visibleBlock === NEW_POLL && _react.default.createElement(_newPoll.default, {
    backToPollsList: function backToPollsList() {
      return setVisibleBlock(POLLS_LIST);
    },
    location: location
  }), visibleBlock === EDIT_POLL && _react.default.createElement(_editPoll.default, {
    backToPollsList: function backToPollsList() {
      return setVisibleBlock(POLLS_LIST);
    },
    selectedPollId: selectedPoll
  }), visibleBlock === SINGLE_POLL && _react.default.createElement(_singlePoll.default, {
    selectedPollId: selectedPoll,
    backToPollsList: function backToPollsList() {
      return setVisibleBlock(POLLS_LIST);
    }
  }))));
};

var _default = PollsContainer;
exports.default = _default;