var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEventRegisterContext = useEventRegisterContext;
exports.default = exports.EventRegisterContext = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _moment = _interopRequireDefault(require("moment"));

var _reactRedux = require("react-redux");

var _uuid = require("uuid");

var _reactStripeJs = require("@stripe/react-stripe-js");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _selectors = require("../../core/checkout/selectors");

var _user = _interopRequireDefault(require("../../core/user"));

var _selectors2 = require("../../core/user-profile/selectors");

var _paymentUtils = require("../../utils/payment-utils");

var _actions = require("../../core/auth/actions");

var _eventRegistrationUtils = require("./event-registration-utils");

var _selectors3 = require("../../core/customRegistration/selectors");

var _actions2 = require("../../core/checkout/actions");

var _actions3 = require("../../core/customRegistration/actions");

var _contactInformationStepUtils = require("./event-registration-components/contact-information-step/contact-information-step-utils");

var _currencyUtils = require("../../utils/currency-utils");

var _selectors4 = require("../../core/tickets/selectors");

var _constants = require("../../core/tickets/constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var EventRegisterContext = (0, _react.createContext)({
  selectedTicketId: '',
  summaryPrices: {}
});
exports.EventRegisterContext = EventRegisterContext;

function EventRegisterProvider(_ref) {
  var children = _ref.children,
      _ref$initialTicketId = _ref.initialTicketId,
      initialTicketId = _ref$initialTicketId === void 0 ? '' : _ref$initialTicketId,
      eventInfo = _ref.eventInfo,
      closeDialog = _ref.closeDialog,
      callBacks = _ref.callBacks;
  var stripeElements = (0, _reactStripeJs.useElements)();
  var stripe = (0, _reactStripeJs.useStripe)();

  var registrationId = _react.default.useMemo(function () {
    return (0, _uuid.v4)();
  }, []);

  var userTickets = (0, _reactRedux.useSelector)(_selectors4.selectTicketsList);
  var availableEventTickets = (0, _reactRedux.useSelector)(_selectors.validTicketsSelector);
  var currency = eventInfo.currency,
      eventId = eventInfo.id,
      connectedAccountId = eventInfo.connectedAccountId,
      dateFormat = eventInfo.dateFormat,
      eventSlug = eventInfo.eventSlug,
      passwordLessRegistration = eventInfo.passwordLessRegistration;

  var _useRef = (0, _react.useRef)((0, _reactRedux.useSelector)(_user.default.selectors.isFirebaseUserLoggedIn)),
      isLoggedIn = _useRef.current;

  var userProfile = (0, _reactRedux.useSelector)(_selectors2.selectUserProfile);
  var availablePromoCodes = (0, _reactRedux.useSelector)(_selectors.promoCodesSelector);
  var unassignedTickets = (0, _reactRedux.useSelector)((0, _selectors4.selectBoughtTickets)(availableEventTickets));
  var dispatch = (0, _reactRedux.useDispatch)();

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      activeStep = _useState2[0],
      setActiveStep = _useState2[1];

  var _useState3 = (0, _react.useState)(_eventRegistrationUtils.INITIAL_STEPS),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      steps = _useState4[0],
      setSteps = _useState4[1];

  var _useState5 = (0, _react.useState)(initialTicketId || availableEventTickets[0].id),
      _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
      selectedTicketId = _useState6[0],
      setSelectedTicketId = _useState6[1];

  var _useState7 = (0, _react.useState)(availableEventTickets.find(function (ticket) {
    return ticket.id === selectedTicketId;
  })),
      _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
      selectedTicket = _useState8[0],
      setSelectedTicket = _useState8[1];

  var _useState9 = (0, _react.useState)(1),
      _useState10 = (0, _slicedToArray2.default)(_useState9, 2),
      ticketQuantity = _useState10[0],
      setTicketQuantity = _useState10[1];

  var _useState11 = (0, _react.useState)(),
      _useState12 = (0, _slicedToArray2.default)(_useState11, 2),
      purchaseTransactionId = _useState12[0],
      setPurchaseTransactionId = _useState12[1];

  var handleTicketQuantity = function handleTicketQuantity(quantity) {
    setTicketQuantity(quantity);
  };

  var _useState13 = (0, _react.useState)((selectedTicket === null || selectedTicket === void 0 ? void 0 : selectedTicket.selectedAddons) || []),
      _useState14 = (0, _slicedToArray2.default)(_useState13, 2),
      availableTicketAddons = _useState14[0],
      setAvailableTicketAddons = _useState14[1];

  var _React$useState = _react.default.useState([]),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      checkedAddonsVariations = _React$useState2[0],
      setCheckedAddonsVariations = _React$useState2[1];

  var _useState15 = (0, _react.useState)({
    total: null,
    totalWithPromotion: null,
    variationsTotal: null
  }),
      _useState16 = (0, _slicedToArray2.default)(_useState15, 2),
      summaryPrices = _useState16[0],
      setSummaryPrices = _useState16[1];

  var _useState17 = (0, _react.useState)(),
      _useState18 = (0, _slicedToArray2.default)(_useState17, 2),
      formValuesState = _useState18[0],
      setFormValuesState = _useState18[1];

  var _useState19 = (0, _react.useState)(null),
      _useState20 = (0, _slicedToArray2.default)(_useState19, 2),
      selectedPromoCode = _useState20[0],
      setSelectedPromoCode = _useState20[1];

  (0, _react.useEffect)(function () {
    dispatch((0, _actions3.getCustomRegistration)({
      eventId: eventId,
      selectedTicket: selectedTicket
    }));
  }, []);
  var nonstandardFields = (0, _reactRedux.useSelector)((0, _selectors3.selectCustomFieldsByFormId)((selectedTicket === null || selectedTicket === void 0 ? void 0 : selectedTicket.registrationFormId) || '--default--'));
  var userHasSelectedTicket = !!userTickets.find(function (ticket) {
    return ticket.id === selectedTicketId;
  });

  var _useState21 = (0, _react.useState)(false),
      _useState22 = (0, _slicedToArray2.default)(_useState21, 2),
      isTransactionOngoing = _useState22[0],
      setIsTransactionOngoing = _useState22[1];

  var _useState23 = (0, _react.useState)({}),
      _useState24 = (0, _slicedToArray2.default)(_useState23, 2),
      successfulAssigments = _useState24[0],
      setSuccesfulAssigments = _useState24[1];

  var _useState25 = (0, _react.useState)([]),
      _useState26 = (0, _slicedToArray2.default)(_useState25, 2),
      successfulAssigmentsIds = _useState26[0],
      setSuccessfulAssigmentsIds = _useState26[1];

  var checkMatchPromoCode = function checkMatchPromoCode() {
    return availablePromoCodes.find(function (_ref2) {
      var code = _ref2.code,
          allTickets = _ref2.allTickets,
          endDate = _ref2.endDate,
          startDate = _ref2.startDate,
          selectedTickets = _ref2.selectedTickets,
          quantity = _ref2.quantity,
          used = _ref2.used;

      var _code = (selectedPromoCode === null || selectedPromoCode === void 0 ? void 0 : selectedPromoCode.code) || '';

      var ticketId = (selectedPromoCode === null || selectedPromoCode === void 0 ? void 0 : selectedPromoCode.ticketId) || '';
      var codeAccess = String(code).toLowerCase() === String(_code).toLowerCase();
      var ticketAccess = allTickets || selectedTickets.find(function (_ref3) {
        var id = _ref3.id;
        return id === ticketId;
      });
      var dateAccess = endDate && startDate ? (0, _moment.default)().isBetween((0, _moment.default)(startDate), (0, _moment.default)(endDate)) : true;
      var hasQuantity = quantity ? quantity - (used || 0) > 0 : true;
      return codeAccess && ticketAccess && dateAccess && hasQuantity;
    });
  };

  var _useState27 = (0, _react.useState)(checkMatchPromoCode()),
      _useState28 = (0, _slicedToArray2.default)(_useState27, 2),
      matchPromoCode = _useState28[0],
      setMatchPromoCode = _useState28[1];

  var _useState29 = (0, _react.useState)(true),
      _useState30 = (0, _slicedToArray2.default)(_useState29, 2),
      blockNavigation = _useState30[0],
      setBlockNavigation = _useState30[1];

  (0, _react.useEffect)(function () {
    setSelectedTicket(availableEventTickets.find(function (ticket) {
      return ticket.id === selectedTicketId;
    }));
  }, [selectedTicketId]);
  (0, _react.useEffect)(function () {
    setSummaryPrices((0, _paymentUtils.calcCheckoutPrice)(checkedAddonsVariations, selectedTicket === null || selectedTicket === void 0 ? void 0 : selectedTicket.price, matchPromoCode === null || matchPromoCode === void 0 ? void 0 : matchPromoCode.percent, ticketQuantity));
  }, [selectedTicket, matchPromoCode, checkedAddonsVariations, ticketQuantity]);
  (0, _react.useEffect)(function () {
    setAvailableTicketAddons((selectedTicket === null || selectedTicket === void 0 ? void 0 : selectedTicket.selectedAddons) || []);
  }, [selectedTicket]);
  (0, _react.useEffect)(function () {
    if (!availableTicketAddons.length) {
      changeStepStatus(_eventRegistrationUtils.STEPS_IDS.ticketAddons, false);
    } else {
      changeStepStatus(_eventRegistrationUtils.STEPS_IDS.ticketAddons, true);
    }

    setCheckedAddonsVariations([]);
  }, [availableTicketAddons]);
  (0, _react.useEffect)(function () {
    if (summaryPrices.total > 0) {
      changeStepStatus(_eventRegistrationUtils.STEPS_IDS.checkout, true);
      changeLastStepLabel(_eventRegistrationUtils.STEPS_IDS.checkout, {
        label: _eventRegistrationUtils.LAST_STEP_LABELS[_eventRegistrationUtils.STEPS_IDS.checkout],
        value: (0, _currencyUtils.concatPriceWithCurrency)(summaryPrices.totalWithPromotion ? summaryPrices.totalWithPromotion : summaryPrices.total, currency)
      });
    } else {
      changeStepStatus(_eventRegistrationUtils.STEPS_IDS.checkout, false);
    }
  }, [summaryPrices]);
  (0, _react.useEffect)(function () {
    setMatchPromoCode(checkMatchPromoCode());
  }, [selectedPromoCode, checkMatchPromoCode]);
  (0, _react.useEffect)(function () {
    var unsubscribeTicket = null;

    if (isTransactionOngoing) {
      var ticketDocRef = _reactNativeFirebase.default.firestore().collection("users/".concat(userProfile === null || userProfile === void 0 ? void 0 : userProfile.id, "/ticketTransactions")).doc(purchaseTransactionId);

      unsubscribeTicket = ticketDocRef.onSnapshot(function (snapshot) {
        if (snapshot === null || snapshot === void 0 ? void 0 : snapshot.exists) {
          setIsTransactionOngoing(false);
          setActiveStep(function (prevStep) {
            var nextActiveStep = steps.findIndex(function (step, index) {
              return index > prevStep && (step === null || step === void 0 ? void 0 : step.isEnabled);
            });

            if (nextActiveStep > -1) {
              return nextActiveStep;
            }

            return prevStep;
          });
        }
      });
    }

    return function () {
      if (typeof unsubscribeTicket === 'function') {
        unsubscribeTicket();
      }
    };
  }, [isTransactionOngoing, purchaseTransactionId]);

  var changeStepStatus = function changeStepStatus(stepId, isEnabled) {
    setSteps(function (prevSteps) {
      var newSteps = prevSteps.map(function (step) {
        if (step.id === stepId) {
          return _objectSpread(_objectSpread({}, step), {}, {
            isEnabled: isEnabled
          });
        }

        return step;
      });
      return newSteps;
    });
  };

  var changeLastStepLabel = function changeLastStepLabel(stepId, _ref4) {
    var label = _ref4.label,
        value = _ref4.value;
    setSteps(function (prevSteps) {
      var newSteps = prevSteps.map(function (step) {
        if (step.id === stepId) {
          return _objectSpread(_objectSpread({}, step), {}, {
            lastStep: _objectSpread(_objectSpread({}, step.lastStep), {}, {
              label: label,
              value: value
            })
          });
        }

        return step;
      });
      return newSteps;
    });
  };

  var onNext = function () {
    var _ref5 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      var values,
          formikActions,
          currentStepId,
          contactCallbackResult,
          checkoutCallbackResult,
          assignTicketsResult,
          _args = arguments;
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              values = _args.length > 0 && _args[0] !== undefined ? _args[0] : null;
              formikActions = _args.length > 1 && _args[1] !== undefined ? _args[1] : null;

              if (!blockNavigation) {
                _context.next = 4;
                break;
              }

              return _context.abrupt("return");

            case 4:
              currentStepId = steps[activeStep].id;
              _context.t0 = currentStepId;
              _context.next = _context.t0 === _eventRegistrationUtils.STEPS_IDS.contactInformation ? 8 : _context.t0 === _eventRegistrationUtils.STEPS_IDS.checkout ? 14 : _context.t0 === _eventRegistrationUtils.STEPS_IDS.assignTickets ? 20 : 27;
              break;

            case 8:
              _context.next = 10;
              return contactInformationFinishCallback(values, formikActions);

            case 10:
              contactCallbackResult = _context.sent;

              if (contactCallbackResult.success) {
                _context.next = 13;
                break;
              }

              return _context.abrupt("return");

            case 13:
              return _context.abrupt("break", 28);

            case 14:
              _context.next = 16;
              return checkoutFinishCallback(values, formikActions);

            case 16:
              checkoutCallbackResult = _context.sent;

              if (checkoutCallbackResult.success) {
                _context.next = 19;
                break;
              }

              return _context.abrupt("return");

            case 19:
              return _context.abrupt("break", 28);

            case 20:
              _context.next = 22;
              return assignTicketsCallback(values, formikActions);

            case 22:
              assignTicketsResult = _context.sent;

              if (assignTicketsResult.success) {
                _context.next = 25;
                break;
              }

              return _context.abrupt("return");

            case 25:
              closeDialog();
              return _context.abrupt("return");

            case 27:
              return _context.abrupt("break", 28);

            case 28:
              if (!(currentStepId === _eventRegistrationUtils.STEPS_IDS.checkout)) {
                _context.next = 30;
                break;
              }

              return _context.abrupt("return");

            case 30:
              setActiveStep(function (prevStep) {
                var nextActiveStep = steps.findIndex(function (step, index) {
                  return index > prevStep && (step === null || step === void 0 ? void 0 : step.isEnabled);
                });

                if (nextActiveStep > -1) {
                  return nextActiveStep;
                }

                return prevStep;
              });

            case 31:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function onNext() {
      return _ref5.apply(this, arguments);
    };
  }();

  var onBack = function onBack() {
    if (!blockNavigation) {
      if (isLastStep()) {
        closeDialog();
        return;
      }

      setActiveStep(function (prevStep) {
        var reversedSteps = steps.map(function (_, index, initialSteps) {
          return initialSteps[initialSteps.length - 1 - index];
        });
        var previousActiveStep = reversedSteps.findIndex(function (step, index) {
          return index > reversedSteps.length - 1 - prevStep && (step === null || step === void 0 ? void 0 : step.isEnabled);
        });

        if (previousActiveStep > -1) {
          return reversedSteps.length - 1 - previousActiveStep;
        }

        closeDialog();
        return prevStep;
      });
    }
  };

  var goToLogin = function goToLogin() {
    callBacks.goToLogin();
    closeDialog();
  };

  var onSelectedTicketChange = function onSelectedTicketChange(ticketId) {
    if (ticketId !== selectedTicketId) {
      setTicketQuantity(1);
    }

    setSelectedTicketId(ticketId);
  };

  var onCheckedAddonsVariationsChange = function onCheckedAddonsVariationsChange(newCheckedAddonVariations) {
    setCheckedAddonsVariations(newCheckedAddonVariations);
  };

  var onFormValuesStateChanged = function onFormValuesStateChanged(newFormValuesState) {
    setFormValuesState(newFormValuesState);
  };

  var onPromoCodeMatched = function onPromoCodeMatched(newPromoCode) {
    setSelectedPromoCode(newPromoCode);
  };

  var handleBlockNavigationChange = function handleBlockNavigationChange() {
    setTimeout(function () {
      setBlockNavigation(false);
    }, 100);
  };

  var getEnabledSteps = function getEnabledSteps() {
    return steps.filter(function (step) {
      return step.isEnabled;
    });
  };

  var isLastStep = function isLastStep() {
    var _steps$activeStep;

    var enabledSteps = getEnabledSteps();
    var activeStepId = (_steps$activeStep = steps[activeStep]) === null || _steps$activeStep === void 0 ? void 0 : _steps$activeStep.id;
    var lastEnabledStepId = enabledSteps[enabledSteps.length - 1].id;
    if (activeStepId === lastEnabledStepId) return true;
    return false;
  };

  var isPromoCodeOutOfStock = function isPromoCodeOutOfStock() {
    var _code = (selectedPromoCode === null || selectedPromoCode === void 0 ? void 0 : selectedPromoCode.code) || '';

    var ticketId = (selectedPromoCode === null || selectedPromoCode === void 0 ? void 0 : selectedPromoCode.ticketId) || '';
    var endDate = selectedPromoCode === null || selectedPromoCode === void 0 ? void 0 : selectedPromoCode.endDate;
    var startDate = selectedPromoCode === null || selectedPromoCode === void 0 ? void 0 : selectedPromoCode.endDate;
    var promoCode = availablePromoCodes.find(function (_ref6) {
      var code = _ref6.code,
          allTickets = _ref6.allTickets,
          selectedTickets = _ref6.selectedTickets;
      var codeAccess = String(code).toLowerCase() === String(_code).toLowerCase();
      var ticketAccess = allTickets || selectedTickets.find(function (_ref7) {
        var id = _ref7.id;
        return id === ticketId;
      });
      var dateAccess = endDate && startDate ? (0, _moment.default)().isBetween((0, _moment.default)(startDate), (0, _moment.default)(endDate)) : true;
      return codeAccess && ticketAccess && dateAccess;
    });
    if (!promoCode) return null;
    return promoCode.quantity ? promoCode.quantity - (promoCode.used || 0) <= 0 : false;
  };

  var groupSelectedAddonVariationsByAddonId = function groupSelectedAddonVariationsByAddonId() {
    if (!checkedAddonsVariations || checkedAddonsVariations.length === 0) {
      return [];
    }

    return checkedAddonsVariations.reduce(function (accum, variation) {
      var addonId = variation.addonId;

      if (!accum[addonId]) {
        accum[addonId] = [];
      }

      accum[addonId].push(variation.id);
      return accum;
    }, {});
  };

  var triggerEventRegistration = function () {
    var _ref8 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(values, setErrors) {
      var username, email, rest, registrationValues, response, _response$error;

      return _regenerator.default.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              username = values.username, email = values.email, rest = (0, _objectWithoutProperties2.default)(values, ["username", "email"]);
              registrationValues = {
                username: username,
                email: email,
                registrationId: registrationId,
                customFields: nonstandardFields.map((0, _contactInformationStepUtils.createNonstandardFormField)(rest))
              };
              _context2.next = 4;
              return dispatch((0, _actions.eventRegistration)(_objectSpread(_objectSpread({}, registrationValues), {}, {
                isLoggedIn: isLoggedIn
              })));

            case 4:
              response = _context2.sent;

              if (!(response === null || response === void 0 ? void 0 : response.payload)) {
                _context2.next = 7;
                break;
              }

              return _context2.abrupt("return", {
                success: true,
                uid: userProfile === null || userProfile === void 0 ? void 0 : userProfile.id
              });

            case 7:
              if (!(response === null || response === void 0 ? void 0 : response.error)) {
                _context2.next = 10;
                break;
              }

              setErrors({
                submit: response === null || response === void 0 ? void 0 : (_response$error = response.error) === null || _response$error === void 0 ? void 0 : _response$error.message
              });
              return _context2.abrupt("return", {
                success: false
              });

            case 10:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    }));

    return function triggerEventRegistration(_x, _x2) {
      return _ref8.apply(this, arguments);
    };
  }();

  var triggerUserRegistration = function () {
    var _ref9 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(values, setErrors) {
      var _response$payload;

      var username, email, password, rest, registrationValues, response, _response$error2;

      return _regenerator.default.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              username = values.username, email = values.email, password = values.password, rest = (0, _objectWithoutProperties2.default)(values, ["username", "email", "password"]);
              registrationValues = {
                username: username,
                email: email,
                password: password,
                registrationId: registrationId,
                customFields: nonstandardFields.map((0, _contactInformationStepUtils.createNonstandardFormField)(rest))
              };
              _context3.next = 4;
              return dispatch((0, _actions.userRegistration)(registrationValues));

            case 4:
              response = _context3.sent;

              if (!(response === null || response === void 0 ? void 0 : response.payload)) {
                _context3.next = 7;
                break;
              }

              return _context3.abrupt("return", {
                success: true,
                uid: response === null || response === void 0 ? void 0 : (_response$payload = response.payload) === null || _response$payload === void 0 ? void 0 : _response$payload.id
              });

            case 7:
              if (!(response === null || response === void 0 ? void 0 : response.error)) {
                _context3.next = 10;
                break;
              }

              setErrors({
                submit: response === null || response === void 0 ? void 0 : (_response$error2 = response.error) === null || _response$error2 === void 0 ? void 0 : _response$error2.message
              });
              return _context3.abrupt("return", {
                success: false
              });

            case 10:
            case "end":
              return _context3.stop();
          }
        }
      }, _callee3);
    }));

    return function triggerUserRegistration(_x3, _x4) {
      return _ref9.apply(this, arguments);
    };
  }();

  var triggerFreeTicketCreation = function () {
    var _ref10 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(setErrors) {
      var variationsGroupedByAddonId, response, _response$error3, _response$error4;

      return _regenerator.default.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              variationsGroupedByAddonId = groupSelectedAddonVariationsByAddonId();
              _context4.next = 3;
              return dispatch((0, _actions2.finishEventFreeRegistration)({
                ticketId: selectedTicketId,
                eventId: eventId,
                variationsGroupedByAddonId: variationsGroupedByAddonId,
                promoCodeId: selectedPromoCode === null || selectedPromoCode === void 0 ? void 0 : selectedPromoCode.id
              }));

            case 3:
              response = _context4.sent;

              if (!(response === null || response === void 0 ? void 0 : response.payload)) {
                _context4.next = 6;
                break;
              }

              return _context4.abrupt("return", {
                success: true
              });

            case 6:
              if (!(response === null || response === void 0 ? void 0 : response.error)) {
                _context4.next = 9;
                break;
              }

              if ((response === null || response === void 0 ? void 0 : (_response$error3 = response.error) === null || _response$error3 === void 0 ? void 0 : _response$error3.message) === 'Promocode out of stock') {
                setErrors({
                  promoCode: true
                });
              } else {
                setErrors({
                  submit: response === null || response === void 0 ? void 0 : (_response$error4 = response.error) === null || _response$error4 === void 0 ? void 0 : _response$error4.message
                });
              }

              return _context4.abrupt("return", {
                success: false
              });

            case 9:
            case "end":
              return _context4.stop();
          }
        }
      }, _callee4);
    }));

    return function triggerFreeTicketCreation(_x5) {
      return _ref10.apply(this, arguments);
    };
  }();

  var triggerFullDiscountTicketCreation = function () {
    var _ref11 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(values, setErrors) {
      var userRegistrationResponse;
      return _regenerator.default.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              if (!isLoggedIn) {
                _context5.next = 4;
                break;
              }

              _context5.next = 3;
              return triggerFreeTicketCreation(setErrors);

            case 3:
              return _context5.abrupt("return", _context5.sent);

            case 4:
              _context5.next = 6;
              return triggerUserRegistration(values, setErrors);

            case 6:
              userRegistrationResponse = _context5.sent;

              if (!(userRegistrationResponse === null || userRegistrationResponse === void 0 ? void 0 : userRegistrationResponse.success)) {
                _context5.next = 11;
                break;
              }

              _context5.next = 10;
              return triggerFreeTicketCreation(setErrors);

            case 10:
              return _context5.abrupt("return", _context5.sent);

            case 11:
              if (!(userRegistrationResponse === null || userRegistrationResponse === void 0 ? void 0 : userRegistrationResponse.error)) {
                _context5.next = 13;
                break;
              }

              return _context5.abrupt("return", {
                success: false
              });

            case 13:
            case "end":
              return _context5.stop();
          }
        }
      }, _callee5);
    }));

    return function triggerFullDiscountTicketCreation(_x6, _x7) {
      return _ref11.apply(this, arguments);
    };
  }();

  var contactInformationFinishCallback = function () {
    var _ref12 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee6(values, formikActions) {
      var setErrors, result, isOrderSummaryFree, transactionId, payloadInfo, _result;

      return _regenerator.default.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              setErrors = formikActions.setErrors;

              if (!isLoggedIn) {
                _context6.next = 7;
                break;
              }

              _context6.next = 4;
              return triggerEventRegistration(values, setErrors);

            case 4:
              _context6.t0 = _context6.sent;
              _context6.next = 10;
              break;

            case 7:
              _context6.next = 9;
              return triggerUserRegistration(values, setErrors);

            case 9:
              _context6.t0 = _context6.sent;

            case 10:
              result = _context6.t0;

              if (result.success) {
                _context6.next = 13;
                break;
              }

              return _context6.abrupt("return", {
                success: false
              });

            case 13:
              isOrderSummaryFree = summaryPrices.variationsTotal + summaryPrices.total === 0 || summaryPrices.totalWithPromotion === 0;

              if (!isOrderSummaryFree) {
                _context6.next = 22;
                break;
              }

              transactionId = (0, _uuid.v4)();
              setPurchaseTransactionId(transactionId);
              payloadInfo = {
                ticketId: selectedTicketId,
                ticketQuantity: ticketQuantity,
                eventId: eventId,
                userId: userProfile.id,
                variationsGroupedByAddonId: groupSelectedAddonVariationsByAddonId(),
                transactionId: transactionId
              };
              _context6.next = 20;
              return dispatch((0, _actions2.createFreeUnassignedTickets)(payloadInfo));

            case 20:
              _result = _context6.sent;
              return _context6.abrupt("return", {
                success: _result.payload.success
              });

            case 22:
              return _context6.abrupt("return", {
                success: true
              });

            case 23:
            case "end":
              return _context6.stop();
          }
        }
      }, _callee6);
    }));

    return function contactInformationFinishCallback(_x8, _x9) {
      return _ref12.apply(this, arguments);
    };
  }();

  var assignTicketsCallback = function () {
    var _ref13 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee7(values, formikActions) {
      var groupedTickets, _verifyIfAllFieldsAre, hasErrors, errors, userEmailInTicketList, payloadInfo, assignResult, grupedTicketsResult, groupedTicketsErrors, groupedTicketsSuccess, successfulTicketsIds;

      return _regenerator.default.wrap(function _callee7$(_context7) {
        while (1) {
          switch (_context7.prev = _context7.next) {
            case 0:
              groupedTickets = (0, _eventRegistrationUtils.groupUnasignedTicketsById)(values, successfulAssigmentsIds);

              if (!(groupedTickets.length === 0)) {
                _context7.next = 3;
                break;
              }

              return _context7.abrupt("return", {
                success: false
              });

            case 3:
              _verifyIfAllFieldsAre = (0, _eventRegistrationUtils.verifyIfAllFieldsAreFilled)(groupedTickets), hasErrors = _verifyIfAllFieldsAre.hasErrors, errors = _verifyIfAllFieldsAre.errors;

              if (!hasErrors) {
                _context7.next = 7;
                break;
              }

              formikActions.setErrors(errors);
              return _context7.abrupt("return", {
                success: false
              });

            case 7:
              if (!(0, _eventRegistrationUtils.areEmailsRepeated)(groupedTickets)) {
                _context7.next = 10;
                break;
              }

              formikActions.setErrors({
                duplicatedEmails: true
              });
              return _context7.abrupt("return", {
                success: false
              });

            case 10:
              userEmailInTicketList = groupedTickets.find(function (ticket) {
                return ticket.email === userProfile.email;
              });

              if (!(userHasSelectedTicket && userEmailInTicketList)) {
                _context7.next = 14;
                break;
              }

              formikActions.setErrors((0, _defineProperty2.default)({}, "".concat(_constants.EMAIL_FIELD, "-").concat(userEmailInTicketList.unassignedTicketId), _i18n.default.t('assignTickets.youAlreadyHaveThisTicket')));
              return _context7.abrupt("return", {
                success: false
              });

            case 14:
              payloadInfo = {
                assignerId: userProfile.id,
                eventId: eventId,
                groupedTickets: groupedTickets
              };
              _context7.next = 17;
              return dispatch((0, _actions2.assignTickets)(payloadInfo));

            case 17:
              assignResult = _context7.sent;
              grupedTicketsResult = assignResult.payload.unassignedTicketResult;
              groupedTicketsErrors = {};
              groupedTicketsSuccess = _objectSpread({}, successfulAssigments);
              successfulTicketsIds = (0, _toConsumableArray2.default)(successfulAssigmentsIds);
              grupedTicketsResult.forEach(function (ticket) {
                if (!ticket.status.successful) {
                  groupedTicketsErrors = _objectSpread(_objectSpread({}, groupedTicketsErrors), {}, (0, _defineProperty2.default)({}, "".concat(_constants.EMAIL_FIELD, "-").concat(ticket.unassignedTicketId), ticket.status.errorMessage));
                } else {
                  groupedTicketsSuccess = _objectSpread(_objectSpread({}, groupedTicketsSuccess), {}, (0, _defineProperty2.default)({}, "".concat(_constants.ASSIGNED_FIELD, "-").concat(ticket.unassignedTicketId), true));
                  successfulTicketsIds.push(ticket.unassignedTicketId);
                }
              });

              if (Object.keys(groupedTicketsErrors).length > 0) {
                formikActions.setErrors(groupedTicketsErrors);
              }

              if (Object.keys(groupedTicketsSuccess).length > 0) {
                setSuccesfulAssigments(function (prev) {
                  return _objectSpread(_objectSpread({}, prev), groupedTicketsSuccess);
                });
                setSuccessfulAssigmentsIds(successfulTicketsIds);
              }

              return _context7.abrupt("return", {
                success: false
              });

            case 26:
            case "end":
              return _context7.stop();
          }
        }
      }, _callee7);
    }));

    return function assignTicketsCallback(_x10, _x11) {
      return _ref13.apply(this, arguments);
    };
  }();

  var checkoutFinishCallback = function () {
    var _ref14 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee10(values, formikActions) {
      var setErrors, setStatus, setSubmitting, variationsGroupedByAddonId, showError, showCountryError, showCardError, showCompanyError, billingDetails, companyInfo, validateCard, validateCompany, triggerPayment, paymentMethodResult;
      return _regenerator.default.wrap(function _callee10$(_context10) {
        while (1) {
          switch (_context10.prev = _context10.next) {
            case 0:
              setErrors = formikActions.setErrors, setStatus = formikActions.setStatus, setSubmitting = formikActions.setSubmitting;
              variationsGroupedByAddonId = groupSelectedAddonVariationsByAddonId();

              showError = function showError(message) {
                setStatus({
                  success: false
                });
                setErrors({
                  submit: message
                });
                setSubmitting(false);
              };

              showCountryError = function showCountryError(message) {
                setStatus({
                  success: false
                });
                setErrors({
                  country: message
                });
                setSubmitting(false);
              };

              showCardError = function showCardError(message) {
                setStatus({
                  success: false
                });
                setErrors({
                  card: message
                });
                setSubmitting(false);
              };

              showCompanyError = function showCompanyError(message) {
                setStatus({
                  success: false
                });
                setErrors({
                  taxIdNumber: message
                });
                setSubmitting(false);
              };

              billingDetails = {
                name: values.cardholderName,
                email: values.email,
                address: {
                  city: values.city,
                  country: values === null || values === void 0 ? void 0 : values.country.slice(-2),
                  line1: values.addressLine1,
                  state: values === null || values === void 0 ? void 0 : values.country,
                  postal_code: values.postalCode
                }
              };
              companyInfo = null;

              if (values.companyName && values.taxIdNumber) {
                companyInfo = {
                  companyName: values.companyName,
                  taxIdNumber: values.taxIdNumber
                };
              }

              validateCard = function () {
                var _ref15 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee8() {
                  var cardElement, _yield$stripe$createP, cardError, paymentMethod;

                  return _regenerator.default.wrap(function _callee8$(_context8) {
                    while (1) {
                      switch (_context8.prev = _context8.next) {
                        case 0:
                          cardElement = stripeElements.getElement(_reactStripeJs.CardElement);
                          _context8.next = 3;
                          return stripe.createPaymentMethod({
                            type: 'card',
                            card: cardElement,
                            billing_details: billingDetails
                          });

                        case 3:
                          _yield$stripe$createP = _context8.sent;
                          cardError = _yield$stripe$createP.error;
                          paymentMethod = _yield$stripe$createP.paymentMethod;

                          if (!(cardError || (paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.error))) {
                            _context8.next = 11;
                            break;
                          }

                          if (cardError.param === 'billing_details[address][country]') {
                            showCountryError(_i18n.default.t("billing.error.country"));
                          } else {
                            showCardError(_i18n.default.t("billing.error.".concat(cardError.code)));
                          }

                          return _context8.abrupt("return", null);

                        case 11:
                          return _context8.abrupt("return", paymentMethod);

                        case 12:
                        case "end":
                          return _context8.stop();
                      }
                    }
                  }, _callee8);
                }));

                return function validateCard() {
                  return _ref15.apply(this, arguments);
                };
              }();

              validateCompany = function validateCompany() {
                if (values.companyName && !values.taxIdNumber || !values.companyName && values.taxIdNumber) {
                  showCompanyError(_i18n.default.t('billing.error.incompleteCompanyDetails'));
                  return false;
                }

                return true;
              };

              triggerPayment = function () {
                var _ref16 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee9(paymentMethod) {
                  var transactionId, payloadInfo, _checkoutPaidTicketRe, checkoutPaidTicketResult, clientSecret, confirmCardPayment;

                  return _regenerator.default.wrap(function _callee9$(_context9) {
                    while (1) {
                      switch (_context9.prev = _context9.next) {
                        case 0:
                          transactionId = (0, _uuid.v4)();
                          payloadInfo = {
                            paymentMethod: paymentMethod,
                            ticketId: selectedTicketId,
                            ticketQuantity: ticketQuantity,
                            eventId: eventId,
                            userId: userProfile.id,
                            billingDetails: billingDetails,
                            promoCodeInfo: {
                              promoCode: selectedPromoCode
                            },
                            variationsGroupedByAddonId: variationsGroupedByAddonId,
                            companyInfo: companyInfo,
                            transactionId: transactionId
                          };
                          setPurchaseTransactionId(transactionId);
                          _context9.prev = 3;
                          _context9.next = 6;
                          return dispatch((0, _actions2.startCheckoutPaidTicket)(payloadInfo));

                        case 6:
                          checkoutPaidTicketResult = _context9.sent;

                          if (!(checkoutPaidTicketResult === null || checkoutPaidTicketResult === void 0 ? void 0 : (_checkoutPaidTicketRe = checkoutPaidTicketResult.payload) === null || _checkoutPaidTicketRe === void 0 ? void 0 : _checkoutPaidTicketRe.clientSecret)) {
                            _context9.next = 20;
                            break;
                          }

                          clientSecret = checkoutPaidTicketResult.payload.clientSecret;
                          _context9.next = 11;
                          return stripe.confirmCardPayment(clientSecret, {
                            payment_method: paymentMethod.id
                          });

                        case 11:
                          confirmCardPayment = _context9.sent;

                          if (!(confirmCardPayment.paymentIntent.status === 'succeeded')) {
                            _context9.next = 17;
                            break;
                          }

                          setIsTransactionOngoing(true);
                          return _context9.abrupt("return", {
                            success: true,
                            isWithPayment: true
                          });

                        case 17:
                          showError(_i18n.default.t('errors.somethingWentWrong'));

                        case 18:
                          _context9.next = 21;
                          break;

                        case 20:
                          if (checkoutPaidTicketResult.payload.code === 'tax_id_invalid') {
                            showCompanyError(_i18n.default.t('billing.error.incorrectTaxId'));
                          } else {
                            showError(checkoutPaidTicketResult.payload.message || _i18n.default.t('errors.somethingWentWrong'));
                          }

                        case 21:
                          _context9.next = 26;
                          break;

                        case 23:
                          _context9.prev = 23;
                          _context9.t0 = _context9["catch"](3);
                          showError(_i18n.default.t('errors.somethingWentWrong'));

                        case 26:
                        case "end":
                          return _context9.stop();
                      }
                    }
                  }, _callee9, null, [[3, 23]]);
                }));

                return function triggerPayment(_x14) {
                  return _ref16.apply(this, arguments);
                };
              }();

              if (!((matchPromoCode === null || matchPromoCode === void 0 ? void 0 : matchPromoCode.percent) === 100)) {
                _context10.next = 16;
                break;
              }

              _context10.next = 15;
              return triggerFullDiscountTicketCreation(values, setErrors);

            case 15:
              return _context10.abrupt("return", _context10.sent);

            case 16:
              if (validateCompany()) {
                _context10.next = 18;
                break;
              }

              return _context10.abrupt("return", {
                success: false
              });

            case 18:
              _context10.next = 20;
              return validateCard();

            case 20:
              paymentMethodResult = _context10.sent;

              if (paymentMethodResult) {
                _context10.next = 23;
                break;
              }

              return _context10.abrupt("return", {
                success: false
              });

            case 23:
              _context10.next = 25;
              return triggerPayment(paymentMethodResult);

            case 25:
              return _context10.abrupt("return", _context10.sent);

            case 26:
            case "end":
              return _context10.stop();
          }
        }
      }, _callee10);
    }));

    return function checkoutFinishCallback(_x12, _x13) {
      return _ref14.apply(this, arguments);
    };
  }();

  var showPromoCode = !!selectedTicket && !!(availablePromoCodes === null || availablePromoCodes === void 0 ? void 0 : availablePromoCodes.length);
  var currentTransactionUnassignedTickets = unassignedTickets.filter(function (unassignedTicket) {
    return unassignedTicket.transactionId === purchaseTransactionId;
  });
  var assignmentIsComplete = Object.keys(successfulAssigments).length >= 1 && Object.keys(currentTransactionUnassignedTickets).length >= 1 && Object.keys(successfulAssigments).length === Object.keys(currentTransactionUnassignedTickets).length;

  var mapTicketsAddons = function mapTicketsAddons() {
    var grupedAddons = groupSelectedAddonVariationsByAddonId();
    return Object.entries(grupedAddons).map(function (_ref17) {
      var _ref18 = (0, _slicedToArray2.default)(_ref17, 2),
          key = _ref18[0],
          variations = _ref18[1];

      var addon = availableTicketAddons.find(function (_ref19) {
        var id = _ref19.id;
        return id === key;
      });
      var mappedVariations = {};
      variations.forEach(function (variation) {
        var variationData = checkedAddonsVariations.find(function (checkedVariation) {
          return checkedVariation.id === variation;
        });
        mappedVariations = _objectSpread(_objectSpread({}, mappedVariations), {}, (0, _defineProperty2.default)({}, variation, variationData));
      });
      return _objectSpread(_objectSpread({}, addon), {}, {
        variations: _objectSpread({}, mappedVariations)
      });
    });
  };

  var mappedTicketsAddons = mapTicketsAddons();
  return _react.default.createElement(EventRegisterContext.Provider, {
    value: {
      activeStep: activeStep,
      ticketQuantity: ticketQuantity,
      availableEventTickets: availableEventTickets,
      availablePromoCodes: availablePromoCodes,
      availableTicketAddons: availableTicketAddons,
      blockNavigation: blockNavigation,
      checkedAddonsVariations: checkedAddonsVariations,
      checkMatchPromoCode: checkMatchPromoCode,
      connectedAccountId: connectedAccountId,
      closeDialog: closeDialog,
      currentTransactionUnassignedTickets: currentTransactionUnassignedTickets,
      dateFormat: dateFormat,
      currency: currency,
      eventId: eventId,
      eventSlug: eventSlug,
      formValuesState: formValuesState,
      getEnabledSteps: getEnabledSteps,
      goToLogin: goToLogin,
      handleBlockNavigationChange: handleBlockNavigationChange,
      handleTicketQuantity: handleTicketQuantity,
      assignmentIsComplete: assignmentIsComplete,
      isLastStep: isLastStep,
      isLoggedIn: isLoggedIn,
      isPromoCodeOutOfStock: isPromoCodeOutOfStock,
      isTransactionOngoing: isTransactionOngoing,
      mappedTicketsAddons: mappedTicketsAddons,
      matchPromoCode: matchPromoCode,
      nonstandardFields: nonstandardFields,
      onBack: onBack,
      onCheckedAddonsVariationsChange: onCheckedAddonsVariationsChange,
      onFormValuesStateChanged: onFormValuesStateChanged,
      onNext: onNext,
      onPromoCodeMatched: onPromoCodeMatched,
      onSelectedTicketChange: onSelectedTicketChange,
      passwordLessRegistration: passwordLessRegistration,
      purchaseTransactionId: purchaseTransactionId,
      registrationId: registrationId,
      selectedTicket: selectedTicket,
      selectedTicketId: selectedTicketId,
      selectedPromoCode: selectedPromoCode,
      successfulAssigments: successfulAssigments,
      showPromoCode: showPromoCode,
      steps: steps,
      stripe: stripe,
      summaryPrices: summaryPrices,
      userProfile: userProfile,
      userHasSelectedTicket: userHasSelectedTicket,
      unassignedTickets: unassignedTickets
    }
  }, children);
}

var _default = EventRegisterProvider;
exports.default = _default;

function useEventRegisterContext() {
  var context = (0, _react.useContext)(EventRegisterContext);
  return context;
}