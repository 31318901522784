var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.HomeScreenInternal = exports.iosTopMargin = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var _reactNavigation = require("react-navigation");

var _reactRedux = require("react-redux");

var _events = _interopRequireDefault(require("../../core/events"));

var _blue_background = _interopRequireDefault(require("../../assets/images/blue_background.png"));

var _sectionView = _interopRequireDefault(require("../../../design-system/structure/section/section-view"));

var _eventCard = _interopRequireDefault(require("../common/event/event-card"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var iosTopMargin = _reactNative.Platform.OS === 'ios' ? _reactNativeDeviceInfo.default.hasNotch() ? 32 : 16 : 0;
exports.iosTopMargin = iosTopMargin;

var HomeScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(HomeScreenInternal, _React$PureComponent);

  var _super = _createSuper(HomeScreenInternal);

  function HomeScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, HomeScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      refreshing: false
    };

    _this._handleAppStateChange = function (currentAppState) {
      if (currentAppState === 'active' && _this.props.navigation.isFocused()) {
        _this.setState({
          refreshing: true,
          festivalFeedLoaded: false,
          liveNowFeedLoaded: false,
          upcomingEventsFeedLoaded: false,
          pastEventsFeedLoaded: false
        });
      }
    };

    _this.renderEmptyComponent = function () {
      if (_this.props.loading) {
        return React.createElement(_reactNative.ActivityIndicator, {
          size: "large",
          color: "white"
        });
      }

      return React.createElement(_sectionView.default, {
        spacingBottom: "base",
        spacingHorizontal: "extraLoose"
      }, React.createElement(_reactNative.Text, {
        style: styles.emptyList
      }, 'No upcoming\nevents!'));
    };

    return _this;
  }

  (0, _createClass2.default)(HomeScreenInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _reactNative.AppState.addEventListener('change', this._handleAppStateChange);

      this.props.getEvents();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate() {}
  }, {
    key: "hasReloaded",
    value: function hasReloaded() {
      if (this.state.festivalFeedLoaded && this.state.liveNowFeedLoaded && this.state.upcomingEventsFeedLoaded && this.state.pastEventsFeedLoaded) {
        this.setState({
          refreshing: false
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(React.Fragment, null, React.createElement(_reactNative.Image, {
        source: _blue_background.default,
        style: styles.backgroundImage
      }), React.createElement(_sectionView.default, {
        spacingTop: "loose"
      }, React.createElement(_sectionView.default, {
        spacingTop: "extraLoose",
        spacingBottom: "base",
        spacingHorizontal: "extraLoose"
      }, React.createElement(_reactNative.Text, {
        style: styles.myEvents
      }, "My events"))), React.createElement(_reactNative.FlatList, {
        directionalLockEnabled: true,
        pinchGestureEnabled: false,
        showsVerticalScrollIndicator: false,
        data: this.props.events,
        renderItem: function renderItem(_ref) {
          var item = _ref.item;
          return React.createElement(_sectionView.default, {
            spacingVertical: "base",
            spacingHorizontal: "loose"
          }, React.createElement(_eventCard.default, {
            isLive: item.isLive,
            name: item.name,
            startDate: item.startDate,
            endDate: item.endDate,
            label: item.label,
            image: item.bannerUrl
          }));
        },
        ListEmptyComponent: this.renderEmptyComponent,
        removeClippedSubviews: true,
        keyExtractor: function keyExtractor(item, index) {
          return index.toString();
        }
      }));
    }
  }]);
  return HomeScreenInternal;
}(React.PureComponent);

exports.HomeScreenInternal = HomeScreenInternal;

var styles = _reactNative.StyleSheet.create({
  myEvents: {
    fontWeight: 'bold',
    fontSize: 30,
    lineHeight: 36,
    color: 'black'
  },
  mainBackground: {
    backgroundColor: 'black',
    flex: 1
  },
  LoadingScreen: {
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%'
  },
  dividerContainer: {
    height: 24,
    marginVertical: 16,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dividerLine: {
    flex: 1,
    height: 24,
    borderBottomWidth: 1,
    borderColor: 'rgba(255,255,255,0.2)',
    transform: [{
      translateY: -12
    }]
  },
  dividerText: {
    paddingHorizontal: 16
  },
  moreSection: {
    alignItems: 'center',
    marginBottom: 20
  },
  moreButtonContainer: {
    width: 110
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  emptyList: {
    lineHeight: 22,
    flexWrap: 'wrap',
    fontWeight: 'bold',
    fontSize: 18,
    color: 'black'
  }
});

function mapStateToProps(state) {
  return {
    events: _events.default.selectors.getUserEvents(state),
    loading: _events.default.selectors.selectEventsLoading(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  getEvents: _events.default.actions.getEvents
})(HomeScreenInternal);

exports.default = _default;