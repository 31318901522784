var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AddonVariationList;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _eventRegisterProvider = require("../../event-register-provider");

var _coloredCheckbox = _interopRequireDefault(require("../../../../theme/components/inputs/coloredCheckbox"));

var _currencyUtils = require("../../../../utils/currency-utils");

var _DesignSystem = require("../../../../theme/DesignSystem");

var _addonSessionRow = _interopRequireDefault(require("./addon-session-row"));

var _reactFeather = require("react-feather");

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    bold: {
      fontWeight: 'bold'
    },
    addonCheckbox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    list: {
      width: '100%'
    },
    listItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      padding: 0
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer'
    },
    addonSelector: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      marginTop: 8
    }
  };
});

function AddonVariationList(_ref) {
  var addonsVisibility = _ref.checkoutItemsVisibility,
      addon = _ref.checkoutItem,
      isTogglable = _ref.isTogglable,
      handleChange = _ref.handleChange,
      index = _ref.index,
      toggleVisibility = _ref.toggleVisibility;

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      currency = _useEventRegisterCont.currency,
      checkedAddonsVariations = _useEventRegisterCont.checkedAddonsVariations;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var classes = useStyles();
  return _react.default.createElement(_core.List, {
    key: index,
    className: classes.list
  }, _react.default.createElement(_core.Box, {
    onClick: function onClick() {
      return toggleVisibility(index);
    },
    className: classes.nameContainer
  }, _react.default.createElement(_core.Typography, {
    variant: "h3"
  }, addon.name), addonsVisibility[index] ? _react.default.createElement(_reactFeather.ChevronDown, null) : _react.default.createElement(_reactFeather.ChevronRight, null)), (addon === null || addon === void 0 ? void 0 : addon.variations) && (addonsVisibility[index] || !isTogglable) && Object.values(addon.variations).map(function (variation, index) {
    return _react.default.createElement(_core.ListItem, {
      key: index,
      className: classes.listItem
    }, _react.default.createElement(_core.Box, {
      className: classes.addonSelector
    }, _react.default.createElement(_core.Box, {
      className: classes.addonCheckbox
    }, (addon === null || addon === void 0 ? void 0 : addon.selectMultiple) ? _react.default.createElement(_coloredCheckbox.default, {
      checked: !!checkedAddonsVariations.find(function (item) {
        return item.id === variation.id;
      }),
      onChange: function onChange(e) {
        return handleChange(e, variation, addon);
      },
      customColor: colors.button
    }) : _react.default.createElement(_core.Radio, {
      value: variation.id,
      checked: !!checkedAddonsVariations.find(function (item) {
        return item.id === variation.id;
      }),
      onClick: function onClick(e) {
        return handleChange(e, variation, addon);
      }
    }), _react.default.createElement(_core.Typography, {
      variant: "caption",
      className: classes.bold
    }, variation.name)), _react.default.createElement(_core.Typography, {
      className: classes.bold
    }, (0, _currencyUtils.concatPriceWithCurrency)(variation.price, currency))), variation.sessionId && _react.default.createElement(_addonSessionRow.default, {
      variation: variation
    }));
  }));
}