var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _core = require("@material-ui/core");

var _eventSettings = _interopRequireDefault(require("../../core/event-settings"));

var _reactRedux = require("react-redux");

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _styles = _interopRequireDefault(require("./styles"));

var ChecklistComponent = function ChecklistComponent(_ref) {
  var checklist = _ref.checklist;

  var _useSelector = (0, _reactRedux.useSelector)(_eventSettings.default.selectors.labelsSettingsSelector),
      checklistTitle = _useSelector.Checklist;

  var classes = (0, _styles.default)();
  if (!checklist) return null;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Box, {
    display: "flex",
    pb: 2
  }, _react.default.createElement(_core.Typography, {
    variant: "h2",
    className: classes.textUppercase
  }, checklistTitle || _i18n.default.t('header.checklist'))), _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  }, Object.keys(checklist).map(function (key, index) {
    return _react.default.createElement(_core.Box, {
      my: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      key: index
    }, _react.default.createElement(_core.Box, {
      mr: 3
    }, _react.default.createElement(_core.Typography, null, _react.default.createElement(_reactFeather.CheckCircle, null))), _react.default.createElement(_core.Typography, null, checklist[key].description));
  })));
};

var _default = ChecklistComponent;
exports.default = _default;