var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _DesignSystem = require("../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useEventCardStyle = new _DesignSystem.MakeStyle().addConstantStyle({
  infoDescription: {
    flex: 1
  },
  small: {
    width: '286px'
  },
  medium: {
    width: '350px'
  },
  large: {
    width: '430px'
  },
  infoContainer: {
    flexDirection: 'row',
    flex: 1
  },
  smallinfoContainer: {
    flexDirection: 'column'
  }
}).addThemeBasedStyle(function (theme) {
  return {
    content: {
      padding: theme.spacing.l,
      flex: 1
    },
    innerContainer: _objectSpread({
      flex: 1,
      borderWidth: 1,
      borderColor: theme.colors.borderColor
    }, theme.shape.cards),
    container: {
      margin: theme.spacing.l
    },
    image: _objectSpread(_objectSpread({
      width: '100%',
      paddingBottom: '56.25%'
    }, theme.shape.cards), {}, {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomWidth: 1,
      borderColor: theme.colors.borderColor
    }),
    cardBtn: {
      color: 'inherit'
    },
    mediumBtn: {
      fontSize: '0.875rem',
      lineHeight: '1.5'
    },
    smallBtn: {
      fontSize: '0.875rem',
      lineHeight: '1.5'
    },
    imgBackground: _objectSpread({
      flex: 1,
      justifyContent: 'center'
    }, theme.shape.cards),
    imageStyle: _objectSpread({}, theme.shape.cards)
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, {
  small: {
    width: '100%',
    minHeight: 450
  },
  medium: {
    width: '100%',
    minHeight: 450
  },
  large: {
    width: '100%',
    minHeight: 450
  },
  infoContainer: {
    flexDirection: 'column'
  },
  cardBtn: {
    fontSize: '0.875rem',
    lineHeight: '1.5'
  }
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.xsm;
}, {
  small: {
    width: '100%',
    minHeight: 350
  },
  medium: {
    width: '100%',
    minHeight: 350
  },
  large: {
    width: '100%',
    minHeight: 350
  }
}).buildHook();
var _default = useEventCardStyle;
exports.default = _default;