var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _lodash = _interopRequireDefault(require("lodash"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _selectors = require("../../../core/event-info/selectors");

var _selectors2 = require("../../../core/user/selectors");

var _menuItem = _interopRequireDefault(require("../../common/right-side-menu/menu-item/menu-item"));

var _reactFeather = require("react-feather");

var _Chat = _interopRequireDefault(require("../../chat/Chat"));

var _usersList = _interopRequireDefault(require("../../chat/users-list"));

var _blockedUsersList = _interopRequireDefault(require("../../chat/blocked-users-list"));

var _rightSideMenu = require("../../common/right-side-menu");

var _selectors3 = require("../../../core/team/selectors");

var _selectors4 = require("../../../core/event-settings/selectors");

var _AutoConnect = _interopRequireDefault(require("../../chat/AutoConnect"));

var _reactNavigationHooks = require("react-navigation-hooks");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _selectors5 = require("../../../core/user-rooms/selectors");

var _core = require("@material-ui/core");

var _separator = _interopRequireDefault(require("../../common/right-side-menu/menu-item/separator"));

var _participantRequests = _interopRequireDefault(require("./participant-requests"));

var _featureNamesConstants = require("../../../constants/feature-names-constants");

var _selectors6 = require("../../../core/user-roles/selectors");

var _QA = _interopRequireDefault(require("../../chat/Q&A"));

var _selectors7 = require("../../../core/QAndA/selectors");

var menuBuilder = new _rightSideMenu.MenuBuilder();

var NetworkingTableMenu = function NetworkingTableMenu(_ref) {
  var _ref$watchMode = _ref.watchMode,
      watchMode = _ref$watchMode === void 0 ? false : _ref$watchMode;
  var createdBy = (0, _reactRedux.useSelector)(_selectors.eventCreatorIdSelector);
  var uid = (0, _reactRedux.useSelector)(_selectors2.getFirebaseUserUid);
  var adminAccess = (0, _reactRedux.useSelector)(_selectors3.isStaffMember);
  var chatSettings = (0, _reactRedux.useSelector)(_selectors4.chatSettingsSelector);
  var peopleSettings = (0, _reactRedux.useSelector)(_selectors4.peopleSettingsSelector);
  var tableId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.TABLE_ID);

  var _useSelector = (0, _reactRedux.useSelector)(_selectors.selectEventInfo),
      eventId = _useSelector.id;

  var requestingParticipants = (0, _reactRedux.useSelector)((0, _selectors5.roomRequestingUsers)(tableId));
  var questionsAndAnswers = (0, _reactRedux.useSelector)(_selectors7.selectQuestionsList);
  var canAcceptRequest = (0, _reactRedux.useSelector)((0, _selectors6.hasUserFeatureAccessSelector)(_featureNamesConstants.ALLOW_RESTRICTED_NETWORKING_TABLE_REQUEST, tableId));
  var isAdmin = createdBy === uid || adminAccess || canAcceptRequest;
  var requestingParticipantsNumber = Object.keys(requestingParticipants).length;
  var numberOfQuestions = questionsAndAnswers.length;

  var menu = _react.default.useMemo(function () {
    var _chatSettings$disable, _peopleSettings$disab;

    var chatDisabled = Boolean((_chatSettings$disable = chatSettings.disabled) === null || _chatSettings$disable === void 0 ? void 0 : _chatSettings$disable.networking);
    var peopleDisabled = Boolean((_peopleSettings$disab = peopleSettings.disabled) === null || _peopleSettings$disab === void 0 ? void 0 : _peopleSettings$disab.networking);
    var requestsDisabled = Boolean(!isAdmin);
    menuBuilder.add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.CHAT, function (_ref2) {
      var isSelected = _ref2.isSelected,
          onSelect = _ref2.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Chat",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.MessageCircle, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.tableChat'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.CHAT, _react.default.createElement(_AutoConnect.default, {
      id: tableId
    }, _react.default.createElement(_Chat.default, {
      rejoinId: tableId,
      disabled: chatDisabled,
      disabledMessage: chatSettings.disabledMessage
    }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.REQUESTS, function (_ref3) {
      var isSelected = _ref3.isSelected,
          onSelect = _ref3.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Requests",
        onPress: onSelect,
        icon: _react.default.createElement(_core.Badge, {
          badgeContent: requestingParticipantsNumber,
          color: "error"
        }, _react.default.createElement(_reactFeather.UserPlus, null)),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.requests'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.REQUESTS, _react.default.createElement(_AutoConnect.default, {
      id: tableId
    }, _react.default.createElement(_participantRequests.default, null), ",")).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.CHAT_PARTICIPANTS, function (_ref4) {
      var isSelected = _ref4.isSelected,
          onSelect = _ref4.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Participants",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.Users, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.participants'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.CHAT_PARTICIPANTS, _react.default.createElement(_AutoConnect.default, {
      id: tableId
    }, _react.default.createElement(_usersList.default, {
      roomId: tableId,
      isAdmin: isAdmin,
      isWatching: watchMode
    }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.SEPARATOR, function () {
      return _react.default.createElement(_separator.default, null);
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.SEPARATOR, null).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.INTERNAL_CHAT, function (_ref5) {
      var isSelected = _ref5.isSelected,
          onSelect = _ref5.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Event Chat",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.MessageSquare, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.eventChat'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.INTERNAL_CHAT, _react.default.createElement(_AutoConnect.default, {
      id: eventId
    }, _react.default.createElement(_Chat.default, {
      rejoinId: eventId
    }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.QA, function (_ref6) {
      var isSelected = _ref6.isSelected,
          onSelect = _ref6.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "QandA",
        onPress: onSelect,
        icon: _react.default.createElement(_core.Badge, {
          badgeContent: numberOfQuestions,
          color: "primary"
        }, _react.default.createElement(_reactFeather.HelpCircle, null)),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.qa'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.QA, _react.default.createElement(_QA.default, null)).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PARTICIPANTS, function (_ref7) {
      var isSelected = _ref7.isSelected,
          onSelect = _ref7.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Abuse",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.Shield, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, "Abuse");
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PARTICIPANTS, _react.default.createElement(_AutoConnect.default, {
      id: tableId
    }, _react.default.createElement(_blockedUsersList.default, null), ","));
    if (isAdmin) return menuBuilder.buildBackstageMenu({
      chatDisabled: chatDisabled,
      peopleDisabled: peopleDisabled,
      watchMode: watchMode
    });
    return menuBuilder.buildBackstageMenu({
      chatDisabled: chatDisabled,
      peopleDisabled: peopleDisabled,
      requestsDisabled: requestsDisabled,
      watchMode: watchMode
    });
  }, [isAdmin, chatSettings, tableId, peopleSettings, requestingParticipantsNumber, numberOfQuestions]);

  return menu;
};

var _default = NetworkingTableMenu;
exports.default = _default;