var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PromoCodeContainer;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _core = require("@material-ui/core");

var _formik = require("formik");

var _react = _interopRequireWildcard(require("react"));

var _Input = _interopRequireDefault(require("../../../../theme/components/inputs/Input"));

var _eventRegisterProvider = require("../../event-register-provider");

var _checkoutStepUtils = require("./checkout-step-utils");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _eventRegistrationUtils = require("../../event-registration-utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    inputContainer: (0, _defineProperty2.default)({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: 1,
      width: '100%'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      marginBottom: 25
    })
  };
});

function PromoCodeContainer() {
  var _formikContext$errors;

  var classes = useStyles();

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      onPromoCodeMatched = _useEventRegisterCont.onPromoCodeMatched,
      selectedTicketId = _useEventRegisterCont.selectedTicketId,
      selectedPromoCode = _useEventRegisterCont.selectedPromoCode,
      isPromoCodeOutOfStock = _useEventRegisterCont.isPromoCodeOutOfStock,
      availablePromoCodes = _useEventRegisterCont.availablePromoCodes,
      matchPromoCode = _useEventRegisterCont.matchPromoCode;

  var _useState = (0, _react.useState)(selectedPromoCode),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      currentPromoCodeState = _useState2[0],
      setCurrentPromoCodeState = _useState2[1];

  var onChangePromoCode = function onChangePromoCode(e) {
    setCurrentPromoCodeState({
      ticketId: selectedTicketId,
      code: e.target.value
    });
  };

  (0, _react.useEffect)(function () {
    var newPromoCodeInfo = availablePromoCodes.find(function (_ref) {
      var code = _ref.code;
      return String(currentPromoCodeState === null || currentPromoCodeState === void 0 ? void 0 : currentPromoCodeState.code).toLowerCase() === String(code).toLowerCase();
    });

    if (newPromoCodeInfo) {
      if ((newPromoCodeInfo === null || newPromoCodeInfo === void 0 ? void 0 : newPromoCodeInfo.code) !== (selectedPromoCode === null || selectedPromoCode === void 0 ? void 0 : selectedPromoCode.code)) {
        onPromoCodeMatched(_objectSpread(_objectSpread({}, newPromoCodeInfo), {}, {
          ticketId: currentPromoCodeState === null || currentPromoCodeState === void 0 ? void 0 : currentPromoCodeState.ticketId
        }));
      }
    } else {
      onPromoCodeMatched(null);
    }
  }, [currentPromoCodeState, onPromoCodeMatched, availablePromoCodes]);
  var outOfStock = isPromoCodeOutOfStock();
  var formikContext = (0, _formik.useFormikContext)();
  var showError = outOfStock !== null && outOfStock === true || (formikContext === null || formikContext === void 0 ? void 0 : (_formikContext$errors = formikContext.errors) === null || _formikContext$errors === void 0 ? void 0 : _formikContext$errors.promoCode);
  var isPromoCodeFound = !!((currentPromoCodeState === null || currentPromoCodeState === void 0 ? void 0 : currentPromoCodeState.code) && matchPromoCode);
  return _react.default.createElement(_core.Box, {
    className: classes.inputContainer
  }, _react.default.createElement(_Input.default, {
    shaped: true,
    color: "alternativeText",
    error: (currentPromoCodeState === null || currentPromoCodeState === void 0 ? void 0 : currentPromoCodeState.code) && !matchPromoCode,
    value: currentPromoCodeState === null || currentPromoCodeState === void 0 ? void 0 : currentPromoCodeState.code,
    style: isPromoCodeFound && {
      outlineColor: 'green',
      borderColor: 'green'
    },
    onChange: onChangePromoCode,
    placeholder: _i18n.default.t('checkout.promoCode'),
    type: "text",
    dense: true
  }), showError && _react.default.createElement(_checkoutStepUtils.ErrorBlock, {
    errorMessage: _i18n.default.translate('checkout.promoCodeOutOfStock')
  }));
}