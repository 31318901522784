var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _styles = _interopRequireDefault(require("./styles"));

var NoVideosAvailable = function NoVideosAvailable() {
  var classes = (0, _styles.default)();
  return _react.default.createElement(_core.Box, {
    mt: 2,
    mb: 5,
    mx: -2,
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "stretch",
    className: classes.container
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    className: classes.lobbyCard
  }, _react.default.createElement(_core.Box, {
    component: _core.Paper,
    elevation: 0,
    maxWidth: 400
  }, _react.default.createElement(_core.Box, {
    my: 5,
    mx: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_core.Typography, {
    align: "center",
    variant: "body1"
  }, _i18n.default.t('videos.noVideos'))))));
};

var _default = NoVideosAvailable;
exports.default = _default;