var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _actions = require("../../../core/networking/actions");

var _selectors = require("../../../core/networking/selectors");

var _actions2 = require("../../../core/user-profile/actions");

var _selectors2 = require("../../../core/user-profile/selectors");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _footer = _interopRequireDefault(require("../../common/footer"));

var _tableView = _interopRequireDefault(require("./table/table-view"));

var _displayNameModal = _interopRequireDefault(require("../lobby-page/display-name-modal"));

var _Modal = _interopRequireDefault(require("../../../theme/components/modal/Modal"));

var _selectors3 = require("../../../core/user-rooms/selectors");

var _toaster = _interopRequireDefault(require("../../common/toaster/toaster"));

var _actions3 = require("../../../core/user-rooms/actions");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _core = require("@material-ui/core");

var _styles = _interopRequireDefault(require("./styles"));

var NetworkingPageContainer = function NetworkingPageContainer() {
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });
  var classes = (0, _styles.default)();
  var tables = (0, _reactRedux.useSelector)(_selectors.tableListSelector);
  var selectedTableId = (0, _reactRedux.useSelector)(_selectors.networkingSelectedTableIdSelector);
  var dispatch = (0, _reactRedux.useDispatch)();
  var navigation = (0, _useNavigationExtended.default)();
  var userProfile = (0, _reactRedux.useSelector)(_selectors2.selectUserProfile);
  var showDisplayNameModal = !!userProfile && !(userProfile === null || userProfile === void 0 ? void 0 : userProfile.displayName) && !(userProfile === null || userProfile === void 0 ? void 0 : userProfile.fullName);
  var noSpaceAvailableAtBreakoutRoomStart = (0, _reactRedux.useSelector)(_selectors3.noSpaceAvailable);

  var handleToasterClose = function handleToasterClose() {
    dispatch((0, _actions3.setNoSpaceAvailable)(false));
  };

  (0, _react.useEffect)(function () {
    dispatch((0, _actions.getTables)());
    var unsubscribe = null;
    (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      var _yield$dispatch, payload;

      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return dispatch((0, _actions.subscribeTablesChanges)());

            case 2:
              _yield$dispatch = _context.sent;
              payload = _yield$dispatch.payload;
              unsubscribe = payload;

            case 5:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }))();
    return function () {
      unsubscribe && unsubscribe();
    };
  }, [dispatch]);
  var join = (0, _react.useCallback)(function (id) {
    return navigation.navigate(_routesConsts.Routes.NETWORKING_TABLE_PAGE, {
      tableId: id
    });
  }, [navigation]);

  var onDisplayNameUpdate = function onDisplayNameUpdate(name) {
    dispatch((0, _actions2.updateUserProfile)(name));
  };

  return _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    minHeight: "100%"
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flex: "1",
    py: 5,
    px: 8,
    className: classes.container
  }, _react.default.createElement(_core.Box, {
    className: classes.innerContainer
  }, tables.map(function (table) {
    return _react.default.createElement(_tableView.default, (0, _extends2.default)({
      key: table.id
    }, table, {
      onJoin: join,
      selected: selectedTableId === table.id
    }));
  }), showDisplayNameModal && _react.default.createElement(_Modal.default, {
    content: _react.default.createElement(_displayNameModal.default, {
      onDisplayNameUpdate: onDisplayNameUpdate
    }),
    autoOpen: true
  }, function () {
    return _react.default.createElement(_react.default.Fragment, null);
  }), _react.default.createElement(_toaster.default, {
    visible: noSpaceAvailableAtBreakoutRoomStart,
    onClose: handleToasterClose,
    duration: 5000,
    text: _i18n.default.translate('networking.noSpaceAvailable')
  }))), _react.default.createElement(_footer.default, null));
};

var _default = NetworkingPageContainer;
exports.default = _default;