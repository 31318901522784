var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return (0, _defineProperty2.default)({
    textUppercase: {
      textTransform: 'uppercase'
    },
    textNormal: {
      fontWeight: '400'
    },
    title: (0, _defineProperty2.default)({
      textAlign: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }, theme.breakpoints.up('sm'), {
      textAlign: 'left'
    }),
    textContainer: (0, _defineProperty2.default)({
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center'
    }, theme.breakpoints.up('sm'), {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '0 0 0 10px'
    }),
    description: {
      marginTop: theme.spacing(1)
    },
    eventTime: {
      fontWeight: '400'
    },
    addToCalendarButton: {
      '@media(max-width: 600px)': {
        paddingLeft: theme.spacing(0)
      }
    },
    row: {
      '@media(max-width: 600px)': {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    descriptionText: {
      borderColor: theme.palette.alternativeBackground.main,
      borderStyle: 'solid',
      borderWidth: 1,
      marginBottom: theme.spacing(0.5)
    }
  }, "textContainer", {
    '@media(max-width: 600px)': {
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center'
    }
  });
});
var _default = styles;
exports.default = _default;