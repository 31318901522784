var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _color = _interopRequireDefault(require("color"));

var _PulseDot = _interopRequireDefault(require("../common/pulse/PulseDot"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon"));

var _reactNativePaper = require("react-native-paper");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var Button = function (_React$Component) {
  (0, _inherits2.default)(Button, _React$Component);

  var _super = _createSuper(Button);

  function Button() {
    (0, _classCallCheck2.default)(this, Button);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(Button, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
          disabled = _this$props.disabled,
          compact = _this$props.compact,
          mode = _this$props.mode,
          dark = _this$props.dark,
          loading = _this$props.loading,
          icon = _this$props.icon,
          pulseDot = _this$props.pulseDot,
          buttonColor = _this$props.color,
          children = _this$props.children,
          uppercase = _this$props.uppercase,
          accessibilityLabel = _this$props.accessibilityLabel,
          onPress = _this$props.onPress,
          style = _this$props.style,
          theme = _this$props.theme,
          buttonSize = _this$props.buttonSize,
          contentStyle = _this$props.contentStyle,
          isFixedButton = _this$props.isFixedButton,
          rest = (0, _objectWithoutProperties2.default)(_this$props, ["disabled", "compact", "mode", "dark", "loading", "icon", "pulseDot", "color", "children", "uppercase", "accessibilityLabel", "onPress", "style", "theme", "buttonSize", "contentStyle", "isFixedButton"]);
      var colors = theme.colors,
          roundness = theme.roundness;
      var fontFamily = theme.fonts.regular;
      var backgroundColor, borderColor, textColor, textSize, borderWidth, btnSize, fixedWidth, iconMargin;

      if (mode === 'contained') {
        if (disabled) {
          backgroundColor = (0, _color.default)(theme.dark ? colors.white : colors.black).alpha(0.12).rgb().string();
        } else if (buttonColor) {
          backgroundColor = buttonColor;
        } else {
          backgroundColor = colors.white;
        }
      } else {
        backgroundColor = 'transparent';
      }

      if (mode === 'outlined') {
        borderColor = (0, _color.default)(theme.dark ? colors.white : colors.black).alpha(1).rgb().string();
        borderWidth = 1;
      } else if (buttonColor) {
        borderColor = (0, _color.default)(buttonColor).alpha(0.2);
        borderWidth = 1;
      } else {
        borderColor = 'transparent';
        borderWidth = 0;
      }

      if (disabled) {
        textColor = (0, _color.default)(theme.dark ? colors.white : colors.black).alpha(0.32).rgb().string();
        borderColor = buttonColor;
        borderWidth = 1;
      } else if (mode === 'contained') {
        var isDark;

        if (typeof dark === 'boolean') {
          isDark = dark;
        } else {
          isDark = backgroundColor === 'transparent' ? false : !(0, _color.default)(backgroundColor).light();
        }

        textColor = isDark ? colors.white : colors.black;
      } else if (buttonColor) {
        textColor = buttonColor;
        borderColor = buttonColor;
        borderWidth = 1;
      } else {
        textColor = colors.white;
      }

      if (isFixedButton) {
        fixedWidth = styles.fixedSize;
      }

      if (buttonSize === 'large') {
        textSize = styles.labelLarge;
        btnSize = styles.btnLarge;
        iconMargin = styles.iconMarginBig;
      } else if (buttonSize === 'small') {
        textSize = styles.labelSmall;
        btnSize = styles.btnSmall;
        iconMargin = styles.icon;
      } else {
        textSize = styles.label;
        btnSize = styles.btnNormal;
        iconMargin = styles.icon;
      }

      var rippleColor = (0, _color.default)(textColor).alpha(0.32).rgb().string();
      var buttonStyle = {
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: borderWidth,
        borderRadius: roundness
      };
      var touchableStyle = {
        borderRadius: roundness
      };
      var textStyle = {
        color: textColor,
        fontFamily: fontFamily
      };
      return React.createElement(_reactNativePaper.Surface, (0, _extends2.default)({}, rest, {
        style: [styles.button, compact && styles.compact, buttonStyle, style]
      }), React.createElement(_reactNativePaper.TouchableRipple, {
        borderless: false,
        delayPressIn: 0,
        onPress: onPress,
        accessibilityLabel: accessibilityLabel,
        accessibilityTraits: disabled ? ['button', 'disabled'] : 'button',
        accessibilityComponentType: "button",
        accessibilityRole: "button",
        accessibilityStates: disabled ? ['disabled'] : undefined,
        disabled: disabled,
        rippleColor: rippleColor,
        style: touchableStyle
      }, React.createElement(_reactNative.View, {
        style: [styles.content, btnSize, contentStyle, fixedWidth]
      }, pulseDot && loading !== true ? React.createElement(_reactNative.View, {
        style: styles.pulse
      }, React.createElement(_PulseDot.default, {
        color: 'red'
      })) : null, icon && loading !== true ? React.createElement(_reactNative.View, {
        style: iconMargin
      }, React.createElement(_CustomIcon.default, {
        name: icon,
        size: 24,
        color: textColor
      })) : null, loading ? React.createElement(_reactNative.ActivityIndicator, {
        size: "small",
        color: textColor,
        style: styles.icon
      }) : null, React.createElement(_reactNativePaper.Text, {
        numberOfLines: 1,
        style: [textSize, compact && styles.compactLabel, textStyle, {
          fontFamily: fontFamily
        }]
      }, React.Children.map(children, function (child) {
        return typeof child === 'string' && uppercase ? child.toUpperCase() : child;
      })))));
    }
  }]);
  return Button;
}(React.Component);

Button.defaultProps = {
  mode: 'text',
  uppercase: false
};

var styles = _reactNative.StyleSheet.create({
  button: {
    borderStyle: 'solid'
  },
  compact: {
    minWidth: 'auto'
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  btnLarge: {
    height: 44,
    paddingHorizontal: 20
  },
  btnNormal: {
    height: 36,
    paddingHorizontal: 16
  },
  btnSmall: {
    height: 33,
    paddingHorizontal: 8
  },
  fixedSize: {
    paddingHorizontal: 0
  },
  icon: {
    marginRight: 8
  },
  iconMarginBig: {
    marginRight: 12
  },
  pulse: {
    marginLeft: -8,
    marginRight: 0
  },
  label: {
    textAlign: 'center',
    fontSize: 13,
    lineHeight: 36,
    fontWeight: '500'
  },
  labelLarge: {
    textAlign: 'center',
    fontSize: 15,
    lineHeight: 44,
    fontWeight: '500'
  },
  labelSmall: {
    textAlign: 'center',
    fontSize: 13,
    lineHeight: 33,
    fontWeight: '500'
  },
  compactLabel: {
    marginHorizontal: 8
  }
});

var _default = (0, _reactNativePaper.withTheme)(Button);

exports.default = _default;