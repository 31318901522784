var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NetworkingTableView;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _networkingTableViewStyle = _interopRequireDefault(require("./networking-table-view-style"));

var _DynamicSection = _interopRequireDefault(require("../../../theme/components/section/DynamicSection"));

var _core = require("@material-ui/core");

var _reactFeather = require("react-feather");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _AudioVideoProvider = _interopRequireDefault(require("../../common/audio-video/AudioVideoProvider"));

var _ClientState = _interopRequireDefault(require("../../common/audio-video/ClientState"));

var _reactRedux = require("react-redux");

var _agoraRtcSdkNg = _interopRequireDefault(require("agora-rtc-sdk-ng"));

var _ToggleAudio = _interopRequireDefault(require("../../common/audio-video/ToggleAudio"));

var _ToggleVideo = _interopRequireDefault(require("../../common/audio-video/ToggleVideo"));

var _SettingsButton = _interopRequireDefault(require("../../common/audio-video/SettingsButton"));

var _HandleJoin = _interopRequireDefault(require("../../common/audio-video/HandleJoin"));

var _ColoredCircularProgress = _interopRequireDefault(require("../../../theme/components/loaders/ColoredCircularProgress"));

var _HandleError = _interopRequireDefault(require("../../common/audio-video/HandleError"));

var _LeaveButton = _interopRequireDefault(require("../../common/audio-video/LeaveButton"));

var _ToggleScreenShare = _interopRequireDefault(require("../../common/audio-video/ToggleScreenShare"));

var _OptimizeRemoteVideo = _interopRequireDefault(require("../../common/audio-video/OptimizeRemoteVideo"));

var _OptimizeLocalVideo = _interopRequireDefault(require("../../common/audio-video/OptimizeLocalVideo"));

var _HandleLeave = _interopRequireDefault(require("../../common/audio-video/HandleLeave"));

var _HandleInactivity = _interopRequireDefault(require("../../common/audio-video/HandleInactivity"));

var _Participants = _interopRequireDefault(require("../../common/audio-video/Participants"));

var _SubscriptionsByRoom = _interopRequireDefault(require("../../common/audio-video/SubscriptionsByRoom"));

var _AudioVideoDeviceSelectorStep = _interopRequireDefault(require("../../common/audio-video/AudioVideoDeviceSelectorStep"));

var _selectors = require("../../../core/user-rooms/selectors");

var _selectors2 = require("../../../core/user-roles/selectors");

var _HandleNoInfo = _interopRequireDefault(require("../../common/audio-video/HandleNoInfo"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _sessions = _interopRequireDefault(require("../../sessions"));

var _selectors3 = require("../../../core/user-profile/selectors");

var _featureNamesConstants = require("../../../constants/feature-names-constants");

var _networkingTableMenu = _interopRequireDefault(require("./networking-table-menu"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _selectors4 = require("../../../core/session/selectors");

var _selectors5 = require("../../../core/user/selectors");

var _handleJoinRequestAccepted = _interopRequireDefault(require("../../common/audio-video/handle-join-request-accepted"));

var _handleJoinRequest = _interopRequireDefault(require("../../common/audio-video/handle-join-request"));

var _handleKickFromTable = _interopRequireDefault(require("../../common/audio-video/handle-kick-from-table"));

var _BreakoutRoomsButton = _interopRequireDefault(require("../../common/audio-video/BreakoutRoomsButton"));

var _formattingUtils = require("../../../utils/formatting-utils");

_agoraRtcSdkNg.default.setLogLevel(3);

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    modalContainer: {
      position: 'absolute',
      top: '0%',
      left: '0%',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start'
    },
    closeModalBox: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: 5,
      cursor: 'pointer',
      color: 'red'
    },
    iframe: {
      border: 'none',
      width: '100%',
      height: '100%'
    }
  };
});

function NetworkingTableView(_ref) {
  var _event$addon, _event$addon2, _event$addon3, _event$addon4;

  var roomId = _ref.roomId,
      tableName = _ref.tableName,
      event = _ref.event,
      isMainTable = _ref.isMainTable,
      isBreakoutRoomsStarted = _ref.isBreakoutRoomsStarted,
      isApproved = _ref.isApproved;
  var Styles = (0, _networkingTableViewStyle.default)();
  var classes = useStyles();

  var _useSelector = (0, _reactRedux.useSelector)((0, _selectors.accessTokensSelector)(roomId)),
      token = _useSelector.token,
      uid = _useSelector.id;

  var participantsInfo = (0, _reactRedux.useSelector)((0, _selectors.roomAllParticipantsSelector)(roomId));
  var shouldBeKicked = (0, _reactRedux.useSelector)((0, _selectors.shouldBeKickedSelector)(roomId));
  var navigation = (0, _useNavigationExtended.default)();
  var isUserJoined = Boolean(participantsInfo[uid]);
  var screenSharer = (0, _reactRedux.useSelector)((0, _selectors.roomScreenSharerSelector)(roomId));
  var userProfile = (0, _reactRedux.useSelector)(_selectors3.selectUserProfile);
  var userInfo = (0, _reactRedux.useSelector)(_selectors5.getFirebaseUser);
  var userRoles = (0, _reactRedux.useSelector)(_selectors2.userRolesSelector);
  var role = (0, _reactRedux.useSelector)((0, _selectors2.userHighestRoleSelector)(roomId));

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      showMap = _useState2[0],
      setShowMap = _useState2[1];

  var networkingTableSession = (0, _reactRedux.useSelector)((0, _selectors4.sessionByNetworkingTableId)(roomId));
  var canUserMuteSpeaker = (0, _reactRedux.useSelector)((0, _selectors2.hasUserFeatureAccessSelector)(_featureNamesConstants.MUTE_UNMUTE_SPEAKER, networkingTableSession === null || networkingTableSession === void 0 ? void 0 : networkingTableSession.resourceId));
  var requestingUsers = (0, _reactRedux.useSelector)((0, _selectors.roomRequestingUsers)(roomId));
  var isCurrentUserRequesting = Object.keys(requestingUsers).find(function (uid) {
    return uid === userInfo.uid;
  });

  var roomParticipantIds = _react.default.useMemo(function () {
    return Object.keys(participantsInfo).map(Number).filter(function (id) {
      return id !== Number(uid);
    });
  }, [participantsInfo, uid]);

  var mobileResolution = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.bg;
  });
  var bottomIframe = (event === null || event === void 0 ? void 0 : (_event$addon = event.addon) === null || _event$addon === void 0 ? void 0 : _event$addon.iframes.bottomIframe) || null;
  var topIframe = (event === null || event === void 0 ? void 0 : (_event$addon2 = event.addon) === null || _event$addon2 === void 0 ? void 0 : _event$addon2.iframes.topIframe) || null;
  var mapIframe = (event === null || event === void 0 ? void 0 : (_event$addon3 = event.addon) === null || _event$addon3 === void 0 ? void 0 : _event$addon3.iframes.mapIframe) || null;
  var hideTableName = event === null || event === void 0 ? void 0 : (_event$addon4 = event.addon) === null || _event$addon4 === void 0 ? void 0 : _event$addon4.hideTableName;
  var loadBottomIframe = bottomIframe && !isMainTable && role;
  var loadTopIframe = bottomIframe && !isMainTable && role;
  var loadMapIframe = mapIframe && !isMainTable && showMap;
  var urlParameters = [{
    parameter: 'eventId',
    value: event.id
  }, {
    parameter: 'roomId',
    value: roomId
  }, {
    parameter: 'eventName',
    value: event.name
  }, {
    parameter: 'tableName',
    value: tableName
  }, {
    parameter: 'userId',
    value: userInfo.uid
  }, {
    parameter: 'userType',
    value: role
  }];

  var handleCloseMap = function handleCloseMap() {
    setShowMap(false);
  };

  function receiveMessage(event) {
    if (event.data === 'EVENTMIX_EVEREST_GAME_OPEN_MAP_EVENT') {
      setShowMap(true);
    }
  }

  (0, _react.useEffect)(function () {
    window.addEventListener('message', receiveMessage, false);
  }, []);

  function loadingPlaceholder(key) {
    return _react.default.createElement(_DynamicSection.default, {
      key: key,
      style: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16
      },
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, function (color) {
      return _react.default.createElement(_ColoredCircularProgress.default, {
        customColor: color,
        size: 120
      });
    });
  }

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
    style: Styles.networkingContainer,
    flex: 1,
    direction: "column",
    pSpacing: "s",
    justifyContent: "flex-start",
    alignItems: "stretch"
  }, _react.default.createElement(_AudioVideoProvider.default, null, _react.default.createElement(_HandleError.default, null), _react.default.createElement(_HandleLeave.default, null), _react.default.createElement(_OptimizeLocalVideo.default, null), _react.default.createElement(_ClientState.default, {
    disconnectedState: _react.default.createElement(_react.default.Fragment, {
      key: "disconnected"
    }, _react.default.createElement(_HandleInactivity.default, null), _react.default.createElement(_DynamicSection.default, {
      style: {
        flex: 1,
        padding: 16,
        justifyContent: 'center'
      },
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, _react.default.createElement(_AudioVideoDeviceSelectorStep.default, null, _react.default.createElement(_handleJoinRequest.default, {
      isCurrentUserRequesting: isCurrentUserRequesting
    }), _react.default.createElement(_HandleJoin.default, {
      room: roomId,
      uid: uid,
      token: token
    }, function (_ref3) {
      var isQuerying = _ref3.isQuerying,
          onJoin = _ref3.onJoin;
      return _react.default.createElement(_ContainedButton.default, {
        onPress: onJoin,
        disabled: isQuerying || !token || !isUserJoined
      }, _i18n.default.translate('networking.takeASeat'));
    })))),
    connectedState: _react.default.createElement(_DynamicSection.default, {
      key: "connected",
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, _react.default.createElement(_handleKickFromTable.default, {
      shouldBeKicked: shouldBeKicked,
      roomId: roomId
    }), _react.default.createElement(_HandleNoInfo.default, {
      hasInfo: participantsInfo[uid],
      onNoInfo: function onNoInfo() {
        navigation.navigate(_routesConsts.Routes.NETWORKING_PAGE);
      }
    }), _react.default.createElement(_OptimizeRemoteVideo.default, {
      participants: roomParticipantIds
    }), _react.default.createElement(_SubscriptionsByRoom.default, {
      roomId: roomId,
      screenSharer: screenSharer,
      roomParticipantIds: roomParticipantIds
    }), _react.default.createElement(_core.Box, null, _react.default.createElement(_DynamicSection.default, {
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, function (color) {
      return _react.default.createElement(_core.Box, {
        display: "flex",
        flexDirection: "row",
        p: 1,
        pl: 2,
        pr: 2
      }, _react.default.createElement(_ToggleAudio.default, {
        roomId: roomId,
        speakerId: uid,
        color: color
      }), _react.default.createElement(_core.Box, {
        m: 1
      }), _react.default.createElement(_ToggleVideo.default, {
        color: color
      }), _react.default.createElement(_core.Box, {
        m: 1
      }), _react.default.createElement(_SettingsButton.default, {
        iconButton: true,
        color: color
      }), _react.default.createElement(_core.Box, {
        m: 1
      }), _react.default.createElement(_ToggleScreenShare.default, {
        room: roomId,
        color: color
      }), _react.default.createElement(_core.Box, {
        m: 1,
        display: "flex",
        flex: 1
      }), _react.default.createElement(_core.Box, {
        display: "flex"
      }, _react.default.createElement(_LeaveButton.default, {
        color: color
      }), _react.default.createElement(_core.Box, {
        m: 1
      }), _react.default.createElement(_BreakoutRoomsButton.default, {
        color: color,
        isMainRoom: isMainTable,
        roomId: roomId,
        isBreakoutRoomsStarted: isBreakoutRoomsStarted
      })));
    })), _react.default.createElement(_Participants.default, {
      roomId: roomId,
      participantsInfo: participantsInfo,
      uid: uid,
      canUserMuteSpeaker: canUserMuteSpeaker
    }), roomId === 'voPxgI3T6cB6t6RsC4IE' && userProfile && _react.default.createElement("iframe", {
      src: "https://test-realtime-drawing.azurewebsites.net/?userName=".concat(userProfile.fullName),
      width: "1200",
      height: "900"
    })),
    connectingState: loadingPlaceholder('disconnected'),
    disconnectingState: loadingPlaceholder('disconnected'),
    reconnectingOverlay: loadingPlaceholder('reconnecting'),
    requestingState: _react.default.createElement(_react.default.Fragment, {
      key: "requesting"
    }, _react.default.createElement(_DynamicSection.default, {
      style: {
        flex: 1,
        padding: 16,
        justifyContent: 'center'
      },
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, _react.default.createElement(_AudioVideoDeviceSelectorStep.default, null, _react.default.createElement(_handleJoinRequestAccepted.default, {
      eventId: event.id,
      roomId: roomId,
      uid: uid,
      token: token,
      isApproved: isApproved
    }), _react.default.createElement(_ContainedButton.default, {
      variant: "contained",
      disabled: true,
      iconRight: _react.default.createElement(_core.CircularProgress, {
        size: 20,
        color: "inherit"
      })
    }, _i18n.default.translate('networking.waitConfirmation')))))
  })), !hideTableName && _react.default.createElement(_Spacing.default, {
    pSpacing: ['l', 0],
    alignItems: "center",
    justifyContent: "space-between"
  }, _react.default.createElement(_core.Typography, {
    variant: "h2"
  }, tableName)), loadBottomIframe && _react.default.createElement(_Spacing.default, {
    pSpacing: ['l', 0],
    direction: "column",
    alignItems: "center",
    justifyContent: "space-between"
  }, _react.default.createElement("iframe", {
    src: (0, _formattingUtils.parseIframeUrl)(urlParameters, bottomIframe.url),
    style: {
      border: 'none',
      width: bottomIframe.width || '100%',
      height: bottomIframe.height
    },
    title: bottomIframe.title
  })), mobileResolution && _react.default.createElement(_Spacing.default, {
    style: {
      margin: '-10px'
    },
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_networkingTableMenu.default, null)), _react.default.createElement(_Spacing.default, {
    pSpacing: ['l', 0],
    alignItems: "center"
  }, _react.default.createElement(_sessions.default, {
    improvedWithPreview: false,
    showFullSchedule: false
  }))), !mobileResolution && _react.default.createElement("div", null, loadTopIframe && _react.default.createElement("iframe", {
    src: (0, _formattingUtils.parseIframeUrl)(urlParameters, topIframe.url),
    style: {
      border: 'none',
      width: topIframe.width || '100%',
      height: userRoles.length === 0 ? topIframe.height : topIframe.adminHeight
    },
    title: topIframe.title
  }), _react.default.createElement(_networkingTableMenu.default, null)), loadMapIframe && _react.default.createElement(_core.Box, {
    className: classes.modalContainer,
    onClick: handleCloseMap
  }, _react.default.createElement(_core.Box, {
    style: {
      width: mapIframe.width,
      height: mapIframe.height,
      marginTop: 25,
      backgroundColor: 'white'
    }
  }, _react.default.createElement(_core.Box, {
    className: classes.closeModalBox
  }, _react.default.createElement(_reactFeather.X, {
    size: "28"
  })), _react.default.createElement("iframe", {
    src: (0, _formattingUtils.parseIframeUrl)(urlParameters, mapIframe.url),
    className: classes.iframe,
    title: topIframe.title
  }))));
}