Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    liveVideoContainer: {
      paddingTop: 1,
      borderRadius: 10,
      overflow: 'hidden',
      margin: theme.spacing(1),
      position: 'relative'
    }
  };
});
var _default = styles;
exports.default = _default;