var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ForgotPasswordView;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _emailLoginStyles = _interopRequireDefault(require("./emailLogin-styles"));

var _reactNativePaperV = require("react-native-paper-v3");

var _horizontalWhiteNewLogo = _interopRequireDefault(require("../../common/logo/horizontalWhiteNewLogo.png"));

var _NavigationButtons = require("../../../navigation/NavigationButtons");

var _button = _interopRequireDefault(require("design-system/actions/button"));

var _section = _interopRequireDefault(require("design-system/structure/section"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

var _theme = _interopRequireDefault(require("../../../../design-system/theme"));

function ForgotPasswordView(props) {
  var colors = _theme.default.colors;
  var onGoBack = props.onGoBack,
      onForgotPassword = props.onForgotPassword,
      loading = props.loading;

  var _useState = (0, _react.useState)(''),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      email = _useState2[0],
      setEmail = _useState2[1];

  var handleEmail = (0, _react.useCallback)(function (email) {
    setEmail(email);
  }, []);
  var handleOnPressForgotPassword = (0, _react.useCallback)(function () {
    return onForgotPassword(email);
  }, [email, onForgotPassword]);
  return _react.default.createElement(_react.default.Fragment, null, (0, _NavigationButtons.backButtonWithCustomAction)(onGoBack), _react.default.createElement(_reactNative.View, {
    style: _emailLoginStyles.default.emailContainer,
    testID: "ForgotPasswordContainer"
  }, _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, _react.default.createElement(_reactNative.Image, {
    source: _horizontalWhiteNewLogo.default,
    style: _emailLoginStyles.default.logo,
    resizeMode: 'contain'
  })), _react.default.createElement(_section.default, {
    spacingHorizontal: "extraLoose"
  }, _react.default.createElement(_section.default, {
    spacingVertical: "loose"
  }, _react.default.createElement(_reactNativePaperV.TextInput, {
    testID: "email",
    mode: "flat",
    placeholder: "Email Address",
    underlineColor: "rgba(255,255,255,0.2)",
    returnKeyType: 'done',
    theme: {
      colors: {
        placeholder: 'rgba(188, 192, 195, 1)',
        text: 'black',
        primary: colors.black,
        background: 'white'
      }
    },
    autoCapitalize: "none",
    value: email,
    style: _emailLoginStyles.default.textInput,
    keyboardType: "email-address",
    onChangeText: handleEmail
  })), _react.default.createElement(_button.default, {
    testID: "ForgotPasswordButton",
    style: _emailLoginStyles.default.forgotPasswordButton,
    loading: loading,
    size: "large",
    onPress: handleOnPressForgotPassword
  }, "Submit")), _react.default.createElement(_section.default, null), _react.default.createElement(_section.default, null)));
}