var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.setOptionsWithoutMenu = exports.setOptionsWithoutHeader = exports.setOptionsWithLandingPageMenu = exports.setOptionsWithMenu = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _web = require("@react-navigation/web");

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNavigation = require("react-navigation");

var _reactNavigationStack = require("react-navigation-stack");

var _login = _interopRequireDefault(require("../../components/auth/login"));

var _finalize = _interopRequireDefault(require("../../components/auth/login/finalize"));

var _backstage = _interopRequireDefault(require("../../components/auth/login/backstage"));

var _resetPassword = _interopRequireDefault(require("../../components/auth/reset-password"));

var _resetPasswordCustom = _interopRequireDefault(require("../../components/auth/reset-password-custom"));

var _sideMenu = _interopRequireDefault(require("../../components/common/side-menu"));

var _landingPage = _interopRequireDefault(require("../../components/common/side-menu/landing-page"));

var _landingScreen = _interopRequireDefault(require("../../components/landing-screen"));

var _page = _interopRequireDefault(require("../../components/page404"));

var _privacyPolicy = _interopRequireDefault(require("../../components/privacy-policy"));

var _agendaPage = _interopRequireDefault(require("../../components/protected-pages/agenda-page"));

var _expoPage = _interopRequireDefault(require("../../components/protected-pages/expo-page"));

var _booth = _interopRequireDefault(require("../../components/protected-pages/expo-page/booth"));

var _liveNowPage = _interopRequireDefault(require("../../components/protected-pages/live-now-page"));

var _livePage = _interopRequireDefault(require("../../components/protected-pages/live-page"));

var _lobbyPage = _interopRequireDefault(require("../../components/protected-pages/lobby-page"));

var _myTicketsPage = _interopRequireDefault(require("../../components/protected-pages/my-tickets-page"));

var _myFilesPage = _interopRequireDefault(require("../../components/protected-pages/my-files-page"));

var _partnersPage = _interopRequireDefault(require("../../components/protected-pages/partners-page"));

var _adminPage = _interopRequireDefault(require("../../components/protected-pages/admin-page"));

var _networkingPage = _interopRequireDefault(require("../../components/protected-pages/networking-page"));

var _networkingTablePage = _interopRequireDefault(require("../../components/protected-pages/networking-table-page"));

var _recordedVideosPage = _interopRequireDefault(require("../../components/protected-pages/recorded-videos-page"));

var _speakersPage = _interopRequireDefault(require("../../components/protected-pages/speakers-page"));

var _recordedVideoPlayer = _interopRequireDefault(require("../../components/recorded-videos/recorded-video-player"));

var _WithDeferredDeepLink = _interopRequireDefault(require("../../components/referral/WithDeferredDeepLink"));

var _speakerPage = _interopRequireDefault(require("../../components/speakers/speaker-page"));

var _termsAndConditions = _interopRequireDefault(require("../../components/terms-and-conditions"));

var _testimonialPage = _interopRequireDefault(require("../../components/testimonials/testimonial-page"));

var _NavigationStyle = require("../NavigationStyle");

var _AuthGuard = _interopRequireDefault(require("./guards/AuthGuard"));

var _AdminGuard = _interopRequireDefault(require("./guards/AdminGuard"));

var _HasTicketGuard = _interopRequireDefault(require("./guards/HasTicketGuard"));

var _LoggedInGuard = _interopRequireDefault(require("./guards/LoggedInGuard"));

var _RecordedSessionGuard = _interopRequireDefault(require("./guards/RecordedSessionGuard"));

var _RenderGuard = _interopRequireDefault(require("./guards/RenderGuard"));

var _TicketGuard = _interopRequireDefault(require("./guards/TicketGuard"));

var _Redirect = _interopRequireDefault(require("./Redirect.js"));

var _routesConsts = require("./routes-consts");

var _networkingTableLive = _interopRequireDefault(require("../../components/protected-pages/networking-table-live"));

var _backstage2 = _interopRequireDefault(require("../../components/backstage"));

var _BackstageLoginGuard = _interopRequireDefault(require("./guards/BackstageLoginGuard"));

var _createStackNavigator;

var setOptionsWithMenu = {
  headerLeft: _react.default.createElement(_sideMenu.default, null),
  headerStyle: {
    height: 0
  }
};
exports.setOptionsWithMenu = setOptionsWithMenu;
var setOptionsWithLandingPageMenu = {
  headerLeft: _react.default.createElement(_landingPage.default, null),
  headerStyle: {
    height: 0
  }
};
exports.setOptionsWithLandingPageMenu = setOptionsWithLandingPageMenu;
var setOptionsWithoutHeader = {
  header: null
};
exports.setOptionsWithoutHeader = setOptionsWithoutHeader;
var setOptionsWithoutMenu = {
  headerLeft: null,
  headerStyle: {
    height: 0
  }
};
exports.setOptionsWithoutMenu = setOptionsWithoutMenu;
var RootNavigationStack = (0, _reactNavigationStack.createStackNavigator)((_createStackNavigator = {}, (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.HOME, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_HasTicketGuard.default, null, _react.default.createElement(_landingScreen.default, null)));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.HOME],
  navigationOptions: setOptionsWithLandingPageMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.LOGIN, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_LoggedInGuard.default, null, _react.default.createElement(_login.default, null)));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.LOGIN],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.LOGIN_FINALIZE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_LoggedInGuard.default, null, _react.default.createElement(_finalize.default, null)));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.LOGIN_FINALIZE],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.LOGIN_BACKSTAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_BackstageLoginGuard.default, null, _react.default.createElement(_backstage.default, null)));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.LOGIN_BACKSTAGE],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.RESET_PASSWORD, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_LoggedInGuard.default, null, _react.default.createElement(_resetPassword.default, null)));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.RESET_PASSWORD],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.RESET_PASSWORD_ACTION, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_LoggedInGuard.default, null, _react.default.createElement(_resetPasswordCustom.default, null)));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.RESET_PASSWORD_ACTION],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.LOBBY, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_lobbyPage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.LOBBY],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.TERMS, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_termsAndConditions.default, null));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.TERMS],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.PRIVACY_POLICY, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_privacyPolicy.default, null));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.PRIVACY_POLICY],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.EVENT_LIVE, {
  screen: function screen(props) {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_livePage.default, props))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.EVENT_LIVE],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.LIVE_NOW_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_liveNowPage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.LIVE_NOW_PAGE],
  navigationOptions: setOptionsWithMenu
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.SPEAKER_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_speakerPage.default, null));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.SPEAKER_PAGE],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.TESTIMONIAL_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_testimonialPage.default, null));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.TESTIMONIAL_PAGE],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.AGENDA_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_agendaPage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.AGENDA_PAGE],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.PARTNERS_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_partnersPage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.PARTNERS_PAGE],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.ADMIN_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AdminGuard.default, null, _react.default.createElement(_adminPage.default, null)));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.ADMIN_PAGE],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.NETWORKING_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_networkingPage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.NETWORKING_PAGE],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.NETWORKING_TABLE_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_networkingTablePage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.NETWORKING_TABLE_PAGE],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.NETWORKING_TABLE_LIVE_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_networkingTableLive.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.NETWORKING_TABLE_LIVE_PAGE],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.EXPO_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_expoPage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.EXPO_PAGE],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.EXPO_BOOTH_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_booth.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.EXPO_BOOTH_PAGE],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.SPEAKERS_PAGE, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_speakersPage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.SPEAKERS_PAGE],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.RECORDED_VIDEOS, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_recordedVideosPage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.RECORDED_VIDEOS],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.RECORDED_VIDEO, {
  screen: function screen(props) {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_RecordedSessionGuard.default, null, _react.default.createElement(_recordedVideoPlayer.default, props)))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.RECORDED_VIDEO],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.MY_TICKETS, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_myTicketsPage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.MY_TICKETS],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.MY_FILES, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_TicketGuard.default, null, _react.default.createElement(_myFilesPage.default, null))));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.MY_FILES],
  navigationOptions: setOptionsWithMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.BACKSTAGE, {
  screen: function screen(props) {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_AuthGuard.default, null, _react.default.createElement(_backstage2.default, props)));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.BACKSTAGE],
  navigationOptions: setOptionsWithoutMenu,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.PAGE404, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_page.default, null));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.PAGE404],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), (0, _defineProperty2.default)(_createStackNavigator, _routesConsts.Routes.NOT_FOUND, {
  screen: function screen() {
    return _react.default.createElement(_RenderGuard.default, null, _react.default.createElement(_Redirect.default, {
      to: _routesConsts.Routes.HOME
    }));
  },
  path: _routesConsts.Paths[_routesConsts.Routes.NOT_FOUND],
  navigationOptions: setOptionsWithoutHeader,
  headerTransparent: true
}), _createStackNavigator), {
  initialRouteName: [_routesConsts.Routes.HOME],
  cardStyle: {
    backgroundColor: 'none',
    userSelect: 'text'
  },
  mode: 'modal'
});
var AppWithModalStack = (0, _reactNavigationStack.createStackNavigator)({
  RootNavigationStack: RootNavigationStack
}, {
  disableRouteNamePaths: true,
  transparentCard: true,
  mode: 'modal',
  headerMode: 'none',
  defaultNavigationOptions: {
    gesturesEnabled: false
  },
  cardStyle: {
    backgroundColor: 'none'
  },
  transitionConfig: _reactNative.Platform.OS !== 'web' ? _NavigationStyle.transitionConfigOnlyOpacity : null
});
var createApp = (0, _reactNavigation.createAppContainer)(AppWithModalStack);

if (_reactNative.Platform.OS === 'web') {
  createApp = (0, _web.createBrowserApp)(AppWithModalStack);
}

var _default = (0, _WithDeferredDeepLink.default)(createApp);

exports.default = _default;