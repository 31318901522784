var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RelativeModal;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactFeather = require("react-feather");

var _customModal = _interopRequireDefault(require("../../../components/common/custom-modal/custom-modal"));

var _core = require("@material-ui/core");

var _styles = _interopRequireDefault(require("./styles"));

function RelativeModal(_ref) {
  var children = _ref.children,
      content = _ref.content,
      autoOpen = _ref.autoOpen;
  var classes = (0, _styles.default)();

  var _useState = (0, _react.useState)(autoOpen || false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      msgPosition = _useState4[0],
      setPositions = _useState4[1];

  var handleClose = (0, _react.useCallback)(function () {
    setOpen(false);
  }, []);
  return _react.default.createElement(_react.default.Fragment, null, children({
    onOpen: function onOpen(msgPositions) {
      setOpen(true);
      setPositions(msgPositions);
    }
  }), open && _react.default.createElement(_customModal.default, {
    onClose: handleClose
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    position: "relative"
  }, _react.default.createElement(_core.Box, {
    component: _core.Paper,
    elevation: 0
  }, _react.default.createElement(_core.IconButton, {
    onClick: handleClose,
    className: classes.closeIcon
  }, _react.default.createElement(_reactFeather.X, null)), _react.default.createElement(_core.Box, {
    mt: 3,
    mx: 5,
    mb: 1,
    flexDirection: "column",
    alignItems: "stretch",
    flex: "1"
  }, content)))));
}