var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventHashtagsInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _hashtags = _interopRequireDefault(require("../../core/hashtags"));

var _TagButton = _interopRequireDefault(require("./TagButton"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var EventHashtagsInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(EventHashtagsInternal, _React$PureComponent);

  var _super = _createSuper(EventHashtagsInternal);

  function EventHashtagsInternal() {
    (0, _classCallCheck2.default)(this, EventHashtagsInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(EventHashtagsInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this = this;

      this.props.loadHashtags().then(function () {
        if (_reactNative.Platform.OS !== 'web' && _this.props.onHasLoaded) {
          _this.props.onHasLoaded();
        }
      });
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var _this2 = this;

      if (this.props.reloading && this.props.reloading !== prevProps.reloading) {
        this.props.loadHashtags().then(function () {
          if (_reactNative.Platform.OS !== 'web' && _this2.props.onHasLoaded) {
            _this2.props.onHasLoaded();
          }
        });
      }
    }
  }, {
    key: "_renderItem",
    value: function _renderItem(item, selectedFilters) {
      return React.createElement(_TagButton.default, {
        key: item.get('priority'),
        tagItem: item,
        onSelect: this.props.onPress,
        selectedFilters: selectedFilters
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      return React.createElement(React.Fragment, null, this.props.hasHashTags ? React.createElement(_reactNative.ScrollView, {
        testID: "FestivalFeed",
        alwaysBounceVertical: false,
        horizontal: true,
        directionalLockEnabled: true,
        pinchGestureEnabled: false
      }, React.createElement(_reactNative.View, {
        style: styles.sectionContainer
      }, React.createElement(_reactNative.View, {
        style: styles.hashtagsRow
      }, this.props.hashtagsRow1.toArray().map(function (el) {
        return _this3._renderItem(el, _this3.props.selectedFilters);
      })), React.createElement(_reactNative.View, {
        style: styles.hashtagsRow
      }, this.props.hashtagsRow2.toArray().map(function (el) {
        return _this3._renderItem(el, _this3.props.selectedFilters);
      })))) : null);
    }
  }]);
  return EventHashtagsInternal;
}(React.PureComponent);

exports.EventHashtagsInternal = EventHashtagsInternal;

var styles = _reactNative.StyleSheet.create({
  sectionContainer: {
    flexDirection: 'column',
    marginBottom: 8,
    paddingHorizontal: 16
  },
  hashtagsRow: {
    flexDirection: 'row',
    paddingBottom: 8,
    justifyContent: 'flex-start',
    flex: 1,
    width: '100%',
    flexWrap: 'nowrap'
  }
});

function mapStateToProps(state) {
  return {
    hashtagsRow1: _hashtags.default.selectors.getHashtagsRow1(state),
    hashtagsRow2: _hashtags.default.selectors.getHashtagsRow2(state),
    hasHashTags: _hashtags.default.selectors.hasHashTags(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadHashtags: _hashtags.default.actions.loadHashTags
})(EventHashtagsInternal);

exports.default = _default;