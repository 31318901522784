var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FestivalAboutScreenInternal = exports.iosTopMargin = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var _festival = _interopRequireDefault(require("../../core/festival"));

var _reactNativeRenderHtml = _interopRequireDefault(require("react-native-render-html"));

var _NavigationButtons = require("../../navigation/NavigationButtons");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var iosTopMargin = _reactNative.Platform.OS === 'ios' ? _reactNativeDeviceInfo.default.hasNotch() ? 32 : 16 : 0;
exports.iosTopMargin = iosTopMargin;

var FestivalAboutScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(FestivalAboutScreenInternal, _React$PureComponent);

  var _super = _createSuper(FestivalAboutScreenInternal);

  function FestivalAboutScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, FestivalAboutScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.scrollY = new _reactNative.Animated.Value(0);
    _this.changingBgColor = 0;
    _this.state = {};
    return _this;
  }

  (0, _createClass2.default)(FestivalAboutScreenInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.scrollY = new _reactNative.Animated.Value(0);
      this.changingBgColor = this.scrollY.interpolate({
        inputRange: [0, 30, 60],
        outputRange: ['transparent', 'transparent', 'black'],
        extrapolate: 'clamp'
      });
      this.props.navigation.setParams({
        bgColor: this.changingBgColor
      });
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(React.Fragment, null, React.createElement(_reactNative.Animated.ScrollView, {
        onScroll: _reactNative.Animated.event([{
          nativeEvent: {
            contentOffset: {
              y: this.scrollY
            }
          }
        }]),
        scrollEventThrottle: 1,
        showsVerticalScrollIndicator: false,
        style: {
          backgroundColor: 'black'
        },
        contentContainerStyle: {
          paddingTop: iosTopMargin,
          paddingBottom: 24
        }
      }, React.createElement(_reactNative.ScrollView, {
        style: styles.container,
        contentContainerStyle: {
          paddingVertical: 52,
          paddingHorizontal: 24,
          backgroundColor: 'black'
        }
      }, React.createElement(_reactNative.View, null, React.createElement(_reactNativeRenderHtml.default, {
        html: this.props.description,
        imagesMaxWidth: _reactNative.Dimensions.get('window').width - 48,
        tagsStyles: {
          p: {
            color: 'white',
            fontSize: 15,
            lineHeight: 23,
            marginTop: 16,
            marginBottom: 16
          },
          img: {
            marginTop: 16,
            marginBottom: 16
          }
        }
      })))));
    }
  }]);
  return FestivalAboutScreenInternal;
}(React.PureComponent);

exports.FestivalAboutScreenInternal = FestivalAboutScreenInternal;

FestivalAboutScreenInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  var state = navigation.state;
  return {
    headerLeft: React.createElement(_reactNative.View, {
      style: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 24
      }
    }, React.createElement(_reactNative.Text, {
      style: {
        fontSize: 27,
        color: 'white'
      }
    }, "About")),
    headerRight: (0, _NavigationButtons.xButton)(navigation),
    headerTransparent: true,
    headerStyle: {
      backgroundColor: navigation.getParam('BackgroundColor', state.params.bgColor)
    }
  };
};

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'black'
  }
});

function mapStateToProps(state, ownProps) {
  var id = ownProps.festivalId;
  return {
    description: _festival.default.selectors.getDescription(state, id)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(FestivalAboutScreenInternal);

exports.default = _default;