var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PhoneNumberView;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativeCountryPickerModal = _interopRequireWildcard(require("react-native-country-picker-modal"));

var _reactNativePaperV = require("react-native-paper-v3");

var _libphonenumberJs = require("libphonenumber-js");

var _horizontalWhiteNewLogo = _interopRequireDefault(require("../../common/logo/horizontalWhiteNewLogo.png"));

var _NavigationButtons = require("../../../navigation/NavigationButtons");

var _phoneLoginStyles = _interopRequireDefault(require("./phoneLogin-styles"));

var _button = _interopRequireDefault(require("design-system/actions/button"));

var _section = _interopRequireDefault(require("design-system/structure/section"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

var countryKeys = function () {
  var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
    var keys;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            _context.prev = 0;
            _context.next = 3;
            return (0, _reactNativeCountryPickerModal.getAllCountries)();

          case 3:
            keys = _context.sent;
            return _context.abrupt("return", keys.map(function (country) {
              return country.cca2;
            }));

          case 7:
            _context.prev = 7;
            _context.t0 = _context["catch"](0);

          case 9:
          case "end":
            return _context.stop();
        }
      }
    }, _callee, null, [[0, 7]]);
  }));

  return function countryKeys() {
    return _ref.apply(this, arguments);
  };
}();

function PhoneNumberView(props) {
  var onPhoneAuth = props.onPhoneAuth,
      onGoBack = props.onGoBack,
      loading = props.loading;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      showPicker = _useState2[0],
      setShowPicker = _useState2[1];

  var _useState3 = (0, _react.useState)('+40'),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      number = _useState4[0],
      setNumber = _useState4[1];

  var _useState5 = (0, _react.useState)({
    cca2: 'RO',
    callingCode: '40',
    name: 'Romania'
  }),
      _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
      country = _useState6[0],
      setCountry = _useState6[1];

  var handleModal = (0, _react.useCallback)(function () {
    return setShowPicker(!showPicker);
  }, [showPicker]);
  var handleNumber = (0, _react.useCallback)(function (text) {
    var parsed = new _libphonenumberJs.AsYouType().input(text);
    setNumber(parsed);
  }, []);
  var isValid = (0, _react.useCallback)(function () {
    var phoneNumber = (0, _libphonenumberJs.parsePhoneNumberFromString)(number, country.cca2);

    if (phoneNumber) {
      return phoneNumber.isValid();
    }

    return false;
  }, [country, number]);
  var handleOnPress = (0, _react.useCallback)(function () {
    return onPhoneAuth(number);
  }, [number, onPhoneAuth]);
  return _react.default.createElement(_react.default.Fragment, null, (0, _NavigationButtons.backButtonWithCustomAction)(onGoBack), _react.default.createElement(_reactNative.View, {
    style: _phoneLoginStyles.default.phoneNumberContainer,
    testID: "PhoneContainer"
  }, _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, _react.default.createElement(_reactNative.Image, {
    source: _horizontalWhiteNewLogo.default,
    style: _phoneLoginStyles.default.logo,
    resizeMode: 'contain'
  })), _react.default.createElement(_section.default, {
    spacingHorizontal: "extraLoose"
  }, _react.default.createElement(_reactNative.View, {
    style: _phoneLoginStyles.default.picker,
    pointerEvents: "none"
  }, _react.default.createElement(_reactNativeCountryPickerModal.default, {
    testID: "CountryPicker",
    visible: showPicker,
    filterable: true,
    hideAlphabetFilter: true,
    countryList: countryKeys(),
    cca2: country.cca2,
    onSelect: function onSelect(value) {
      setCountry(value);
      setNumber("+".concat(value.callingCode));
    },
    closeable: true,
    withFilter: true,
    withFlag: true,
    withFlagButton: true,
    withCountryNameButton: true,
    withAlphaFilter: true,
    withCallingCode: true,
    withEmoji: true,
    onClose: handleModal,
    theme: _reactNativeCountryPickerModal.DEFAULT_THEME
  })), _react.default.createElement(_reactNativePaperV.Paragraph, {
    style: _phoneLoginStyles.default.paragraph
  }, "Select your country:"), _react.default.createElement(_section.default, {
    spacingBottom: "base"
  }, _react.default.createElement(_reactNativePaperV.TouchableRipple, {
    mode: "outlined",
    testID: "CountryButton",
    color: "#000",
    size: "large",
    onPress: handleModal
  }, _react.default.createElement(_reactNative.View, {
    style: _phoneLoginStyles.default.button
  }, _react.default.createElement(_reactNativeCountryPickerModal.FlagButton, {
    countryCode: country.cca2
  }), _react.default.createElement(_reactNativePaperV.Text, {
    style: _phoneLoginStyles.default.buttonText
  }, "".concat(country.name, " ( +").concat(country.callingCode, " )"))))), _react.default.createElement(_section.default, {
    spacingBottom: "extraLoose"
  }, _react.default.createElement(_reactNativePaperV.Paragraph, {
    style: _phoneLoginStyles.default.paragraph
  }, "Enter your phone number:"), _react.default.createElement(_reactNative.TextInput, {
    testID: "phoneNumber",
    placeholder: "Phone Number",
    underlineColor: "rgba(255,255,255,0.2)",
    returnKeyType: 'done',
    style: _phoneLoginStyles.default.textInput,
    value: number,
    keyboardType: "phone-pad",
    onChangeText: handleNumber
  })), _react.default.createElement(_section.default, {
    spacingTop: "base"
  }, _react.default.createElement(_button.default, {
    testID: "PhoneAuthButton",
    style: _phoneLoginStyles.default.submit,
    loading: loading,
    size: "large",
    disabled: !isValid() || loading,
    onPress: handleOnPress
  }, "Next"))), _react.default.createElement(_section.default, null), _react.default.createElement(_section.default, null), _react.default.createElement(_section.default, null)));
}