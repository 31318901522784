var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _reactContentLoader = _interopRequireDefault(require("react-content-loader"));

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _selectors = require("../../../../core/expo/selectors");

var _selectors2 = require("../../../../core/partners/selectors");

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _DynamicSection = _interopRequireDefault(require("../../../../theme/components/section/DynamicSection"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../../theme/DesignSystem");

var _useHeader = _interopRequireDefault(require("../../../../utils/hooks/useHeader"));

var _blockedUsersList = _interopRequireDefault(require("../../../chat/blocked-users-list"));

var _Chat = _interopRequireDefault(require("../../../chat/Chat"));

var _usersList = _interopRequireDefault(require("../../../chat/users-list"));

var _DefaultHTMLrenderer = _interopRequireDefault(require("../../../common/DefaultHTMLrenderer"));

var _footer = _interopRequireDefault(require("../../../common/footer"));

var _rightSideMenu = require("../../../common/right-side-menu");

var _menuItem = _interopRequireDefault(require("../../../common/right-side-menu/menu-item/menu-item"));

var _videoView = _interopRequireDefault(require("../../../common/video-external/video-view.web"));

var _QA = _interopRequireDefault(require("../../../chat/Q&A"));

var _boothPageStyles = _interopRequireDefault(require("./booth-page-styles"));

var _reactFeather = require("react-feather");

var _actions = require("../../../../core/chat/actions");

var _Booth = _interopRequireDefault(require("../../../../theme/components/icons/Booth"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _selectors3 = require("../../../../core/user/selectors");

var _selectors4 = require("../../../../core/event-info/selectors");

var _boothDetailsContainer = _interopRequireDefault(require("../booth-details/booth-details-container"));

var _boothFiles = _interopRequireDefault(require("../booth-files"));

var _selectors5 = require("../../../../core/team/selectors");

var _selectors6 = require("../../../../core/event-settings/selectors");

var _AutoConnect = _interopRequireDefault(require("../../../chat/AutoConnect"));

var _actions2 = require("../../../../core/user/actions");

var _userActivityConstants = require("../../../../constants/user-activity-constants");

var _separator = _interopRequireDefault(require("../../../common/right-side-menu/menu-item/separator"));

var _selectors7 = require("../../../../core/QAndA/selectors");

var menuBuilder = new _rightSideMenu.MenuBuilder();

var BoothPageContainer = function BoothPageContainer() {
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });
  var boothId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.BOOTH_ID);
  var booth = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.selectBoothById)(state, boothId);
  });
  var sponsor = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors2.selectPartnerById)(state, booth === null || booth === void 0 ? void 0 : booth.selectedSponsorId);
  });
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectExposLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_selectors.selectExposLoaded);
  var Styles = (0, _boothPageStyles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var uid = (0, _reactRedux.useSelector)(_selectors3.getFirebaseUserUid);
  var createdBy = (0, _reactRedux.useSelector)(_selectors4.eventCreatorIdSelector);
  var adminAccess = (0, _reactRedux.useSelector)(_selectors5.isStaffMember);
  var isAdmin = createdBy === uid || adminAccess;
  var chatSettings = (0, _reactRedux.useSelector)(_selectors6.chatSettingsSelector);
  var peopleSettings = (0, _reactRedux.useSelector)(_selectors6.peopleSettingsSelector);
  var event = (0, _reactRedux.useSelector)(_selectors4.selectEventInfo);
  var eventId = event.id;
  var questionsAndAnswers = (0, _reactRedux.useSelector)(_selectors7.selectQuestionsList);
  var numberOfQuestions = questionsAndAnswers.length;
  var mobileResolution = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
    var breakpoints = _ref.breakpoints;
    return breakpoints.bg;
  });
  (0, _react.useEffect)(function () {
    dispatch((0, _actions.sendRoomChange)(eventId));
  }, [eventId, dispatch]);
  (0, _react.useEffect)(function () {
    (0, _actions2.trackUserActivity)({
      event: event,
      eventType: _userActivityConstants.TRACKABLE_EVENTS.BOOTH_PAGE_ACCESSED,
      booth: {
        name: booth === null || booth === void 0 ? void 0 : booth.headline,
        id: boothId
      }
    });
  }, []);

  var menu = _react.default.useMemo(function () {
    var _chatSettings$disable, _peopleSettings$disab;

    var chatDisabled = Boolean((_chatSettings$disable = chatSettings.disabled) === null || _chatSettings$disable === void 0 ? void 0 : _chatSettings$disable.booths);
    var peopleDisabled = Boolean((_peopleSettings$disab = peopleSettings.disabled) === null || _peopleSettings$disab === void 0 ? void 0 : _peopleSettings$disab.booths);
    menuBuilder.add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.INTERNAL_CHAT, function (_ref2) {
      var isSelected = _ref2.isSelected,
          onSelect = _ref2.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Event Chat",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.MessageSquare, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.eventChat'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.INTERNAL_CHAT, _react.default.createElement(_AutoConnect.default, {
      id: event.id
    }, _react.default.createElement(_Chat.default, {
      rejoinId: event.id
    }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.SEPARATOR, function () {
      return _react.default.createElement(_separator.default, null);
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.BACKSTAGE_MENUS.SEPARATOR, null).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.BOOTH, function (_ref3) {
      var isSelected = _ref3.isSelected,
          onSelect = _ref3.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Booth",
        onPress: onSelect,
        icon: _react.default.createElement(_Booth.default, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.booth'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.BOOTH, _react.default.createElement(_boothDetailsContainer.default, null)).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.CHAT, function (_ref4) {
      var isSelected = _ref4.isSelected,
          onSelect = _ref4.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Chat",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.MessageCircle, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.chat'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.CHAT, _react.default.createElement(_AutoConnect.default, {
      id: boothId
    }, _react.default.createElement(_Chat.default, {
      rejoinId: boothId,
      disabled: chatDisabled,
      disabledMessage: chatSettings.disabledMessage
    }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.QA, function (_ref5) {
      var isSelected = _ref5.isSelected,
          onSelect = _ref5.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "QandA",
        onPress: onSelect,
        icon: _react.default.createElement(_core.Badge, {
          badgeContent: numberOfQuestions,
          color: "primary"
        }, _react.default.createElement(_reactFeather.HelpCircle, null)),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.qa'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.QA, _react.default.createElement(_QA.default, null)).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.CHAT_PARTICIPANTS, function (_ref6) {
      var isSelected = _ref6.isSelected,
          onSelect = _ref6.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "People",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.Users, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.people'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.CHAT_PARTICIPANTS, _react.default.createElement(_AutoConnect.default, {
      id: boothId
    }, _react.default.createElement(_usersList.default, null))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.FILES, function (_ref7) {
      var isSelected = _ref7.isSelected,
          onSelect = _ref7.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Files",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.File, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.files'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.FILES, _react.default.createElement(_boothFiles.default, null)).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PARTICIPANTS, function (_ref8) {
      var isSelected = _ref8.isSelected,
          onSelect = _ref8.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Abuse",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.Shield, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, "Abuse");
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PARTICIPANTS, _react.default.createElement(_AutoConnect.default, {
      id: boothId
    }, _react.default.createElement(_blockedUsersList.default, null)));
    if (isAdmin) return menuBuilder.buildAdminMenu({
      chatDisabled: chatDisabled,
      peopleDisabled: peopleDisabled
    });
    return menuBuilder.buildClientMenu({
      chatDisabled: chatDisabled,
      peopleDisabled: peopleDisabled
    });
  }, [isAdmin, chatSettings, boothId, peopleSettings, numberOfQuestions]);

  if (isLoading && !isLoaded) {
    return _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch",
      wrap: "wrap",
      style: Styles.container
    }, _react.default.createElement(_reactContentLoader.default, {
      backgroundColor: "#e0e0e0",
      flex: 1,
      height: 200,
      width: "100%"
    }, _react.default.createElement("rect", {
      x: "0",
      y: "0",
      rx: "0",
      ry: "0",
      width: "100%",
      height: "200"
    })));
  }

  return _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%',
      flexGrow: 1
    }
  }, _react.default.createElement(_Spacing.default, {
    direction: "row",
    alignItems: "flex-start",
    style: Styles.container
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.videoLayout,
    flex: 1,
    direction: "column",
    alignItems: "stretch"
  }, (!!booth.embedded.video || !!booth.embedded.poster) && _react.default.createElement(_DynamicSection.default, {
    shaped: true,
    style: Styles.videoContainer
  }, _react.default.createElement(_reactNative.View, {
    style: Styles.videoContent
  }, booth.embedded.video ? _react.default.createElement(_videoView.default, {
    title: booth.headline,
    video: booth.embedded.video,
    height: "100%",
    width: "100%"
  }) : _react.default.createElement(_reactNative.Image, {
    source: booth.embedded.poster,
    style: Styles.videoReplacementImage,
    resizeMode: "cover"
  }))), _react.default.createElement(_Spacing.default, {
    style: Styles.logoHeadingContainer
  }, _react.default.createElement(_reactNative.Image, {
    source: sponsor.logoUrl,
    style: Styles.sponsorLogo,
    resizeMode: "contain",
    nativeID: "imageLogo"
  }), _react.default.createElement(_core.Typography, {
    variant: "h3",
    align: "left"
  }, booth.headline)), mobileResolution && _react.default.createElement(_Spacing.default, {
    style: Styles.menuContainer,
    direction: "row",
    flex: "1"
  }, menu), _react.default.createElement(_Spacing.default, {
    style: Styles.about,
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    mSpacing: ['m', 0]
  }, _react.default.createElement(_core.Typography, {
    variant: "body1",
    align: "left"
  }, _react.default.createElement(_DefaultHTMLrenderer.default, {
    source: booth === null || booth === void 0 ? void 0 : booth.about
  }))))), !mobileResolution && menu), _react.default.createElement(_footer.default, null));
};

var _default = BoothPageContainer;
exports.default = _default;