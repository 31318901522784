var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var _constants = require("./constants");

var _createReducer;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var initialMessageState = _constants.messageEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

var initialUserState = _constants.userEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

var initialBlockedUserState = _constants.userEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

var initialChatState = {
  messages: initialMessageState,
  users: initialUserState,
  blockedUsers: initialBlockedUserState,
  chatRoom: null,
  socketStatus: _constants.WEBSOCKET_STATUS.DISCONNECTED,
  error: null,
  roomLoading: false,
  roomLoaded: false,
  unreadCounter: {},
  autoReadMode: true
};
var reducer = (0, _toolkit.createReducer)(initialChatState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.sendConnection.type, function (state) {
  state.socketStatus = _constants.WEBSOCKET_STATUS.CONNECTING;
  state.error = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveConnection.type, function (state) {
  state.socketStatus = _constants.WEBSOCKET_STATUS.CONNECTED;
  state.roomLoading = false;
  state.roomLoaded = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveConnectionFailedError.type, function (state, _ref) {
  var payload = _ref.payload;
  var error = payload.error;
  state.error = error;
  state.socketStatus = _constants.WEBSOCKET_STATUS.DISCONNECTED;
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveDisconnect.type, function (state) {
  return _objectSpread(_objectSpread({}, initialChatState), {}, {
    error: state.error
  });
}), (0, _defineProperty2.default)(_createReducer, _actions.sendRoomJoin.type, function (state, _ref2) {
  var payload = _ref2.payload;
  state.roomLoaded = false;
  state.roomLoading = true;
  state.error = null;
  state.chatRoom = payload;
}), (0, _defineProperty2.default)(_createReducer, _actions.sendRoomReJoin.type, function (state, _ref3) {
  var payload = _ref3.payload;
  state.roomLoaded = false;
  state.roomLoading = true;
  state.error = null;
  state.chatRoom = payload;
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveRoomJoin.type, function (state, _ref4) {
  var payload = _ref4.payload;
  var users = payload.users,
      messages = payload.messages,
      blockedUsers = payload.blockedUsers,
      room = payload.room;
  state.roomLoaded = true;
  state.roomLoading = false;
  state.chatRoom = room;

  _constants.messageEntityAdapter.setAll(state.messages, messages);

  _constants.userEntityAdapter.setAll(state.users, users);

  _constants.blockedUserEntityAdapter.setAll(state.blockedUsers, blockedUsers);
}), (0, _defineProperty2.default)(_createReducer, _actions.sendRoomChange.type, function (state, _ref5) {
  var payload = _ref5.payload;
  state.roomLoaded = false;
  state.roomLoading = true;
  state.error = null;
  state.chatRoom = payload;
  state.autoReadMode = true;
  state.unreadCounter = {};
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveRoomChange.type, function (state, _ref6) {
  var payload = _ref6.payload;
  var users = payload.users,
      messages = payload.messages,
      blockedUsers = payload.blockedUsers;
  state.roomLoaded = true;
  state.roomLoading = false;
  state.autoReadMode = true;
  state.unreadCounter = {};

  _constants.messageEntityAdapter.setAll(state.messages, messages);

  _constants.userEntityAdapter.setAll(state.users, users);

  _constants.blockedUserEntityAdapter.setAll(state.blockedUsers, blockedUsers);
}), (0, _defineProperty2.default)(_createReducer, _actions.sendRoomLeave.type, function (state) {
  state.chatRoom = null;
  state.roomLoading = true;
  state.roomLoaded = false;
  state.autoReadMode = true;
  state.unreadCounter = {};

  _constants.messageEntityAdapter.removeAll(state.messages);

  _constants.userEntityAdapter.removeAll(state.users);

  _constants.blockedUserEntityAdapter.removeAll(state.blockedUsers);
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveRoomLeave.type, function (state) {
  state.roomLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveUserJoin.type, function (state, _ref7) {
  var payload = _ref7.payload;
  var user = payload.user;

  _constants.userEntityAdapter.upsertOne(state.users, user);
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveUserLeave.type, function (state, _ref8) {
  var payload = _ref8.payload;
  var user = payload.user;

  _constants.userEntityAdapter.removeOne(state.users, user.id);
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveMessage.type, function (state, _ref9) {
  var payload = _ref9.payload;
  var message = payload.message;

  _constants.messageEntityAdapter.upsertOne(state.messages, _objectSpread(_objectSpread({}, message), {}, {
    loading: false
  }));

  if (state.messages.ids.length > _constants.CHAT_LATEST_MESSAGES) {
    _constants.messageEntityAdapter.removeOne(state.messages, state.messages.ids[0]);

    delete state.unreadCounter[message.sig];
  }

  if (!state.autoReadMode) {
    state.unreadCounter[message.sig] = 1;
  }
}), (0, _defineProperty2.default)(_createReducer, _actions.resetUnreadCounter.type, function (state) {
  state.unreadCounter = {};
  state.autoReadMode = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.setAutoReadMode, function (state, _ref10) {
  var payload = _ref10.payload;
  state.autoReadMode = payload;
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveDeleteMessage.type, function (state, _ref11) {
  var payload = _ref11.payload;
  var message = payload.message;

  _constants.messageEntityAdapter.removeOne(state.messages, message.sig);

  delete state.unreadCounter[message.sig];
}), (0, _defineProperty2.default)(_createReducer, _actions.sendMessage.type, function (state, _ref12) {
  var payload = _ref12.payload;
  var message = payload.message;

  _constants.messageEntityAdapter.upsertOne(state.messages, _objectSpread(_objectSpread({}, message), {}, {
    loading: true
  }));

  state.autoReadMode = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.sendMessageFailed.type, function (state, _ref13) {
  var payload = _ref13.payload;
  var message = payload.message,
      error = payload.error;
  state.messages.error = error;

  _constants.messageEntityAdapter.updateOne(state.messages, {
    id: message.sig,
    changes: {
      loading: false,
      failed: true
    }
  });
}), (0, _defineProperty2.default)(_createReducer, _actions.sendDeleteMessage.type, function (state, _ref14) {
  var payload = _ref14.payload;
  var message = payload.message;

  _constants.messageEntityAdapter.updateOne(state.messages, {
    id: message.sig,
    changes: {
      deleting: true
    }
  });
}), (0, _defineProperty2.default)(_createReducer, _actions.sendDeleteMessageFailed.type, function (state, _ref15) {
  var payload = _ref15.payload;
  var message = payload.message,
      error = payload.error;
  state.messages.error = error;

  _constants.messageEntityAdapter.updateOne(state.messages, {
    id: message.sig,
    changes: {
      deleting: false
    }
  });
}), (0, _defineProperty2.default)(_createReducer, _actions.sendUserBlock.type, function (state, _ref16) {
  var payload = _ref16.payload;
  var user = payload.user;

  _constants.userEntityAdapter.updateOne(state.users, {
    id: user.id,
    changes: {
      blocking: true
    }
  });
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveUserBlock.type, function (state, _ref17) {
  var payload = _ref17.payload;
  var user = payload.user;

  _constants.blockedUserEntityAdapter.upsertOne(state.blockedUsers, user);
}), (0, _defineProperty2.default)(_createReducer, _actions.sendUserBlockFailed.type, function (state, _ref18) {
  var payload = _ref18.payload;
  var error = payload.error,
      user = payload.user;

  _constants.userEntityAdapter.updateOne(state.users, {
    id: user.id,
    changes: {
      blocking: false
    }
  });

  state.users.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.sendUserUnblock.type, function (state, _ref19) {
  var payload = _ref19.payload;
  var user = payload.user;

  _constants.blockedUserEntityAdapter.updateOne(state.blockedUsers, {
    id: user.id,
    changes: {
      unblocking: true
    }
  });
}), (0, _defineProperty2.default)(_createReducer, _actions.receiveUserUnblock.type, function (state, _ref20) {
  var payload = _ref20.payload;
  var user = payload.user;

  _constants.blockedUserEntityAdapter.removeOne(state.blockedUsers, user.id);
}), (0, _defineProperty2.default)(_createReducer, _actions.sendUserUnblockFailed.type, function (state, _ref21) {
  var payload = _ref21.payload;
  var error = payload.error,
      user = payload.user;

  _constants.blockedUserEntityAdapter.updateOne(state.blockedUsers, {
    id: user.id,
    changes: {
      unblocking: false
    }
  });

  state.blockedUsers.error = error;
}), _createReducer));
var _default = reducer;
exports.default = _default;