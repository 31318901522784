var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-info/selectors");

var _selectors2 = require("../../../core/user-roles/selectors");

var _selectors3 = require("../../../core/user/selectors");

var _formattingUtils = require("../../../utils/formatting-utils");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _footer = _interopRequireDefault(require("../../common/footer"));

var _styles = _interopRequireDefault(require("./styles"));

var AdminPageContainer = function AdminPageContainer() {
  var _event$addon;

  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });
  var classes = (0, _styles.default)();
  var role = (0, _reactRedux.useSelector)((0, _selectors2.userHighestRoleSelector)());
  var event = (0, _reactRedux.useSelector)(_selectors.selectEventInfo);
  var userInfo = (0, _reactRedux.useSelector)(_selectors3.getFirebaseUser);
  var adminIframe = (event === null || event === void 0 ? void 0 : (_event$addon = event.addon) === null || _event$addon === void 0 ? void 0 : _event$addon.iframes.adminIframe) || null;
  var urlParameters = [{
    parameter: 'eventId',
    value: event.id
  }, {
    parameter: 'eventName',
    value: event.name
  }, {
    parameter: 'userId',
    value: userInfo.uid
  }, {
    parameter: 'userType',
    value: role
  }];
  return _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%',
      flexGrow: 1
    }
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flex: "1",
    px: 8,
    p: 5,
    className: classes.container
  }, adminIframe && _react.default.createElement("iframe", {
    src: (0, _formattingUtils.parseIframeUrl)(urlParameters, adminIframe.url),
    style: {
      border: 'none',
      width: '100%',
      height: '100%'
    },
    title: adminIframe.title
  })), _react.default.createElement(_footer.default, null));
};

var _default = AdminPageContainer;
exports.default = _default;