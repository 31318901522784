var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.NewVersionNotificationInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _notifications = _interopRequireDefault(require("../../core/notifications"));

var _Button = _interopRequireDefault(require("../common/Button"));

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _Caption = _interopRequireDefault(require("../common/typography_v1/Caption"));

var _style = _interopRequireDefault(require("./style"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var NewVersionNotificationInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(NewVersionNotificationInternal, _React$PureComponent);

  var _super = _createSuper(NewVersionNotificationInternal);

  function NewVersionNotificationInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, NewVersionNotificationInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.handleUpgrade = function () {
      _reactNative.Linking.openURL(_this.props.upgradeUrl).catch(function () {});
    };

    return _this;
  }

  (0, _createClass2.default)(NewVersionNotificationInternal, [{
    key: "render",
    value: function render() {
      var colors = this.props.theme.colors;

      if (!this.props.hasNewerVersion || this.props.isDeprecated) {
        return null;
      }

      return _react.default.createElement(_reactNative.View, {
        style: {
          flexDirection: 'column',
          padding: 20,
          backgroundColor: colors.surface
        }
      }, _react.default.createElement(_Paragraph.default, {
        style: _style.default.textStyle
      }, "A new app version is available. Update it and keep on having the best music experience.", ' '), _react.default.createElement(_Caption.default, {
        style: {
          textAlign: 'center',
          marginBottom: 16,
          opacity: 0.5
        }
      }, "Version ", this.props.latestAvailableVersion), _react.default.createElement(_Button.default, {
        mode: "contained",
        color: colors.white,
        buttonSize: "large",
        onPress: this.handleUpgrade,
        uppercase: true
      }, "Upgrade"));
    }
  }]);
  return NewVersionNotificationInternal;
}(_react.default.PureComponent);

exports.NewVersionNotificationInternal = NewVersionNotificationInternal;

function mapStateToProps(state) {
  return {
    isDeprecated: _notifications.default.selectors.isAppDeprecated(state),
    hasNewerVersion: _notifications.default.selectors.hasNewerVersion(state),
    latestAvailableVersion: _notifications.default.selectors.getLatestAvailableVersion(state),
    upgradeUrl: _notifications.default.selectors.getLatestAvailableVersionUrl(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps)((0, _reactNativePaper.withTheme)(NewVersionNotificationInternal));

exports.default = _default;