Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PIN_ANSWER = exports.DELETE_ANSWER = exports.ANSWER_QUESTION = exports.DISLIKE_QUESTION = exports.LIKE_QUESTION = exports.PIN_QUESTION = exports.DELETE_QUESTION = exports.SEND_Q_AND_A_QUESTIONS = exports.SET_Q_AND_A_MESSAGES = exports.NAME = void 0;
var NAME = 'qAndA';
exports.NAME = NAME;
var SET_Q_AND_A_MESSAGES = 'QAndA/SET_Q_AND_A_MESSAGES';
exports.SET_Q_AND_A_MESSAGES = SET_Q_AND_A_MESSAGES;
var SEND_Q_AND_A_QUESTIONS = 'QAndA/SEND_Q_AND_A_QUESTIONS';
exports.SEND_Q_AND_A_QUESTIONS = SEND_Q_AND_A_QUESTIONS;
var DELETE_QUESTION = 'QAndA/DELETE_QUESTION';
exports.DELETE_QUESTION = DELETE_QUESTION;
var PIN_QUESTION = 'QAndA/PIN_QUESTION';
exports.PIN_QUESTION = PIN_QUESTION;
var LIKE_QUESTION = 'QAndA/LIKE_QUESTION';
exports.LIKE_QUESTION = LIKE_QUESTION;
var DISLIKE_QUESTION = 'QAndA/DISLIKE_QUESTION';
exports.DISLIKE_QUESTION = DISLIKE_QUESTION;
var ANSWER_QUESTION = 'QAndA/ANSWER_QUESTION';
exports.ANSWER_QUESTION = ANSWER_QUESTION;
var DELETE_ANSWER = 'QAndA/DELETE_ANSWER';
exports.DELETE_ANSWER = DELETE_ANSWER;
var PIN_ANSWER = 'QAndA/PIN_ANSWER';
exports.PIN_ANSWER = PIN_ANSWER;