Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textUppercase: {
      textTransform: 'uppercase'
    },
    daySelection: {
      flexDirection: 'row',
      maxWidth: '100%',
      margin: -theme.spacing(0.5)
    },
    stageSelection: {
      maxWidth: '100%',
      borderBottomColor: "".concat(theme.palette.pageText.main, "55"),
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginRight: 'auto'
    }
  };
});
var _default = styles;
exports.default = _default;