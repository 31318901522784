var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TermsAndConditions;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    termsConditionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      color: theme.palette.alternativeText.main
    },
    termsConditions: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center'
    }
  };
});

function TermsAndConditions(_ref) {
  var beforeText = _ref.beforeText,
      _ref$eventSlug = _ref.eventSlug,
      eventSlug = _ref$eventSlug === void 0 ? '' : _ref$eventSlug;
  var classes = useStyles();
  var baseUrl = "https://".concat(window.location.host, "/").concat(eventSlug);
  return _react.default.createElement(_core.Box, {
    className: classes.termsConditionsContainer
  }, _react.default.createElement(_core.Typography, {
    className: classes.termsConditions,
    variant: "caption",
    component: "div",
    align: "center"
  }, _i18n.default.translate(beforeText), "\xA0", _react.default.createElement(_core.Link, {
    href: "".concat(baseUrl, "/terms-and-conditions"),
    target: "_blank",
    variant: "caption",
    underline: "always"
  }, _i18n.default.translate('contactInformation.terms')), "\xA0", _i18n.default.translate('contactInformation.and'), "\xA0", _react.default.createElement(_core.Link, {
    href: "".concat(baseUrl, "/privacy-policy"),
    target: "_blank",
    variant: "caption",
    underline: "always"
  }, _i18n.default.translate('contactInformation.policy')), '.'));
}