var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dislikeQuestion = exports.likeQuestion = exports.togglePinAnswer = exports.deleteAnswer = exports.answerQuestion = exports.togglePinQuestion = exports.deleteQuestion = exports.createQuestion = exports.setQuestions = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _selectors = require("../event-info/selectors");

var _uuid = require("uuid");

var _selectors2 = require("../user/selectors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var setQuestions = (0, _toolkit.createAction)(_constants.SET_Q_AND_A_MESSAGES);
exports.setQuestions = setQuestions;
var createQuestion = (0, _toolkit.createAsyncThunk)(_constants.SEND_Q_AND_A_QUESTIONS, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(message, _ref) {
    var getState, eventId, QAndACollection, userInfo, questionObject;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            QAndACollection = _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/questionsAndAnswers"));
            userInfo = (0, _selectors2.getFirebaseUser)(getState());
            questionObject = {
              createdBy: userInfo.uid,
              userInfo: {
                userName: userInfo.displayName,
                avatar: userInfo.photoURL || null,
                userId: userInfo.uid
              },
              createdAt: _reactNativeFirebase.default.firestore.FieldValue.serverTimestamp(),
              answers: [],
              likedBy: [],
              dislikedBy: [],
              isPinned: false,
              message: message
            };
            _context.next = 7;
            return QAndACollection.add(questionObject);

          case 7:
            return _context.abrupt("return", questionObject);

          case 8:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.createQuestion = createQuestion;
var deleteQuestion = (0, _toolkit.createAsyncThunk)(_constants.DELETE_QUESTION, function () {
  var _ref4 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(questionId, _ref3) {
    var getState, eventId, questionRef;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            getState = _ref3.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            questionRef = getQuestionRef(questionId, eventId);
            _context2.next = 5;
            return questionRef.delete();

          case 5:
            return _context2.abrupt("return", questionId);

          case 6:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function (_x3, _x4) {
    return _ref4.apply(this, arguments);
  };
}());
exports.deleteQuestion = deleteQuestion;
var togglePinQuestion = (0, _toolkit.createAsyncThunk)(_constants.PIN_QUESTION, function () {
  var _ref7 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(_ref5, _ref6) {
    var questionId, pinState, getState, eventId, questionRef;
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            questionId = _ref5.questionId, pinState = _ref5.pinState;
            getState = _ref6.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            questionRef = getQuestionRef(questionId, eventId);
            _context3.next = 6;
            return questionRef.set({
              isPinned: !pinState
            }, {
              merge: true
            });

          case 6:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));

  return function (_x5, _x6) {
    return _ref7.apply(this, arguments);
  };
}());
exports.togglePinQuestion = togglePinQuestion;
var answerQuestion = (0, _toolkit.createAsyncThunk)(_constants.ANSWER_QUESTION, function () {
  var _ref10 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(_ref8, _ref9) {
    var questionId, message, userId, getState, eventId, questionRef, userInfo;
    return _regenerator.default.wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            questionId = _ref8.questionId, message = _ref8.message, userId = _ref8.userId;
            getState = _ref9.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            questionRef = getQuestionRef(questionId, eventId);
            userInfo = (0, _selectors2.getFirebaseUser)(getState());
            _context4.next = 7;
            return questionRef.update({
              answers: _reactNativeFirebase.default.firestore.FieldValue.arrayUnion({
                message: message,
                createdBy: userId,
                id: (0, _uuid.v4)(),
                createdAt: _reactNativeFirebase.default.firestore.Timestamp.now(),
                isPinned: false,
                userInfo: {
                  userName: userInfo.displayName,
                  avatar: userInfo.photoURL || null,
                  userId: userId
                }
              })
            });

          case 7:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4);
  }));

  return function (_x7, _x8) {
    return _ref10.apply(this, arguments);
  };
}());
exports.answerQuestion = answerQuestion;
var deleteAnswer = (0, _toolkit.createAsyncThunk)(_constants.DELETE_ANSWER, function () {
  var _ref13 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(_ref11, _ref12) {
    var questionId, answerId, getState, eventId, questionDoc, questionData, newAnswers;
    return _regenerator.default.wrap(function _callee5$(_context5) {
      while (1) {
        switch (_context5.prev = _context5.next) {
          case 0:
            questionId = _ref11.questionId, answerId = _ref11.answerId;
            getState = _ref12.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            _context5.next = 5;
            return getQuestionRef(questionId, eventId).get();

          case 5:
            questionDoc = _context5.sent;
            questionData = questionDoc.data();
            newAnswers = questionData.answers.filter(function (answer) {
              return answer.id !== answerId;
            });
            _context5.next = 10;
            return questionDoc.ref.set({
              answers: newAnswers
            }, {
              merge: true
            });

          case 10:
          case "end":
            return _context5.stop();
        }
      }
    }, _callee5);
  }));

  return function (_x9, _x10) {
    return _ref13.apply(this, arguments);
  };
}());
exports.deleteAnswer = deleteAnswer;
var togglePinAnswer = (0, _toolkit.createAsyncThunk)(_constants.PIN_ANSWER, function () {
  var _ref16 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee6(_ref14, _ref15) {
    var questionId, answerId, pinState, getState, eventId, questionDoc, questionData, newAnswers;
    return _regenerator.default.wrap(function _callee6$(_context6) {
      while (1) {
        switch (_context6.prev = _context6.next) {
          case 0:
            questionId = _ref14.questionId, answerId = _ref14.answerId, pinState = _ref14.pinState;
            getState = _ref15.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            _context6.next = 5;
            return getQuestionRef(questionId, eventId).get();

          case 5:
            questionDoc = _context6.sent;
            questionData = questionDoc.data();
            newAnswers = questionData.answers.map(function (answer) {
              return _objectSpread(_objectSpread({}, answer), {}, {
                isPinned: answer.id === answerId ? !pinState : answer.isPinned
              });
            });
            _context6.next = 10;
            return questionDoc.ref.set({
              answers: newAnswers
            }, {
              merge: true
            });

          case 10:
          case "end":
            return _context6.stop();
        }
      }
    }, _callee6);
  }));

  return function (_x11, _x12) {
    return _ref16.apply(this, arguments);
  };
}());
exports.togglePinAnswer = togglePinAnswer;
var likeQuestion = (0, _toolkit.createAsyncThunk)(_constants.LIKE_QUESTION, function () {
  var _ref18 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee7(questionId, _ref17) {
    var getState, eventId, userId, questionDoc, questionData, likedByUser, dislikedByUser;
    return _regenerator.default.wrap(function _callee7$(_context7) {
      while (1) {
        switch (_context7.prev = _context7.next) {
          case 0:
            getState = _ref17.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            userId = (0, _selectors2.getFirebaseUserUid)(getState());
            _context7.next = 5;
            return getQuestionRef(questionId, eventId).get();

          case 5:
            questionDoc = _context7.sent;
            questionData = questionDoc.data();
            likedByUser = questionData.likedBy.includes(userId);
            dislikedByUser = questionData.dislikedBy.includes(userId);

            if (dislikedByUser) {
              questionDoc.ref.update({
                dislikedBy: _reactNativeFirebase.default.firestore.FieldValue.arrayRemove(userId)
              });
            }

            if (!likedByUser) {
              _context7.next = 15;
              break;
            }

            _context7.next = 13;
            return questionDoc.ref.update({
              likedBy: _reactNativeFirebase.default.firestore.FieldValue.arrayRemove(userId)
            });

          case 13:
            _context7.next = 17;
            break;

          case 15:
            _context7.next = 17;
            return questionDoc.ref.update({
              likedBy: _reactNativeFirebase.default.firestore.FieldValue.arrayUnion(userId)
            });

          case 17:
          case "end":
            return _context7.stop();
        }
      }
    }, _callee7);
  }));

  return function (_x13, _x14) {
    return _ref18.apply(this, arguments);
  };
}());
exports.likeQuestion = likeQuestion;
var dislikeQuestion = (0, _toolkit.createAsyncThunk)(_constants.DISLIKE_QUESTION, function () {
  var _ref20 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee8(questionId, _ref19) {
    var getState, eventId, userId, questionDoc, questionData, likedByUser, dislikedByUser;
    return _regenerator.default.wrap(function _callee8$(_context8) {
      while (1) {
        switch (_context8.prev = _context8.next) {
          case 0:
            getState = _ref19.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            userId = (0, _selectors2.getFirebaseUserUid)(getState());
            _context8.next = 5;
            return getQuestionRef(questionId, eventId).get();

          case 5:
            questionDoc = _context8.sent;
            questionData = questionDoc.data();
            likedByUser = questionData.likedBy.includes(userId);
            dislikedByUser = questionData.dislikedBy.includes(userId);

            if (likedByUser) {
              questionDoc.ref.update({
                likedBy: _reactNativeFirebase.default.firestore.FieldValue.arrayRemove(userId)
              });
            }

            if (!dislikedByUser) {
              _context8.next = 15;
              break;
            }

            _context8.next = 13;
            return questionDoc.ref.update({
              dislikedBy: _reactNativeFirebase.default.firestore.FieldValue.arrayRemove(userId)
            });

          case 13:
            _context8.next = 17;
            break;

          case 15:
            _context8.next = 17;
            return questionDoc.ref.update({
              dislikedBy: _reactNativeFirebase.default.firestore.FieldValue.arrayUnion(userId)
            });

          case 17:
          case "end":
            return _context8.stop();
        }
      }
    }, _callee8);
  }));

  return function (_x15, _x16) {
    return _ref20.apply(this, arguments);
  };
}());
exports.dislikeQuestion = dislikeQuestion;

var getQuestionRef = function getQuestionRef(questionId, eventId) {
  return _reactNativeFirebase.default.firestore().doc("events/".concat(eventId, "/questionsAndAnswers/").concat(questionId));
};