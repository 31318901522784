var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AddonSessionRow;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _core = require("@material-ui/core");

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _eventRegisterProvider = require("../../event-register-provider");

var _selectors = require("../../../../core/session/selectors");

var _selectors2 = require("../../../../core/speakers/selectors");

var _timezoneUtils = require("../../../../utils/timezone-utils");

var _dateFormatter = require("../../../../utils/date-formatter");

var _getInitials = _interopRequireDefault(require("../../../common/avatar/getInitials"));

var _eventRegistrationUtils = require("../../event-registration-utils");

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    variationContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      boxSizing: 'border-box',
      width: '100%',
      borderRadius: 4,
      padding: 10
    },
    description: {
      marginBottom: 15
    },
    sessionContainer: (0, _defineProperty2.default)({
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'center'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      flexDirection: 'row'
    }),
    sessionColumn: (0, _defineProperty2.default)({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 10
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      marginRight: 10,
      marginBottom: 0
    }),
    responsiveGutterBottom: (0, _defineProperty2.default)({
      height: 'fit-content',
      marginBottom: 10
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      marginBottom: 0
    }),
    avatar: {
      width: 45,
      height: 45,
      marginRight: 4
    }
  };
});

function AddonSessionRow(_ref) {
  var variation = _ref.variation;
  var classes = useStyles();

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      dateFormat = _useEventRegisterCont.dateFormat;

  var sessions = (0, _reactRedux.useSelector)(_selectors.selectSessionsList);
  var speakers = (0, _reactRedux.useSelector)(_selectors2.selectSpeakersList);
  var session = sessions.find(function (session) {
    return session.id === variation.sessionId;
  });

  if (!session) {
    return null;
  }

  var selectedSpeakers = (session === null || session === void 0 ? void 0 : session.selectedSpeakers) ? session === null || session === void 0 ? void 0 : session.selectedSpeakers.map(function (selectedSpeaker) {
    return speakers.find(function (speaker) {
      return speaker.id === selectedSpeaker.id;
    });
  }) : null;
  var startDateTime = new Date(session === null || session === void 0 ? void 0 : session.start);
  var sessionTimeInterval = (0, _dateFormatter.printMinuteDifference)(session === null || session === void 0 ? void 0 : session.start, session === null || session === void 0 ? void 0 : session.end, dateFormat);
  var zoneAbbreviation = (0, _timezoneUtils.getZoneAbbreviation)();
  var speakersStr = (speakers === null || speakers === void 0 ? void 0 : speakers.length) ? speakers.reduce(function (accum, speaker) {
    return accum ? "".concat(accum, ", ").concat(speaker.name) : speaker.name;
  }, '') : '';
  var title = speakersStr ? "".concat(speakersStr, " - ").concat(session.title) : session.title;
  return _react.default.createElement(_core.Box, {
    className: classes.variationContent
  }, _react.default.createElement(_core.Box, {
    className: classes.description
  }, variation.description && _react.default.createElement(_core.Typography, {
    variant: "overline"
  }, variation.description)), _react.default.createElement(_core.Box, {
    className: classes.sessionContainer
  }, _react.default.createElement(_core.Box, {
    className: classes.sessionColumn
  }, selectedSpeakers && selectedSpeakers.map(function (speaker, index) {
    return _react.default.createElement(_core.Avatar, {
      key: index,
      className: classes.avatar,
      src: speaker.photo || "https://via.placeholder.com/128/000000/FFFFFF/?text=".concat((0, _getInitials.default)(speaker.name))
    });
  })), _react.default.createElement(_core.Typography, {
    variant: "overline",
    align: "center",
    className: classes.responsiveGutterBottom
  }, (0, _momentTimezone.default)(startDateTime).format('DD MMM, YYYY')), _react.default.createElement(_core.Typography, {
    variant: "overline",
    align: "center",
    className: classes.responsiveGutterBottom
  }, sessionTimeInterval, " - ", zoneAbbreviation), _react.default.createElement(_core.Typography, {
    variant: "overline",
    align: "center",
    className: classes.responsiveGutterBottom
  }, title)));
}