var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LeaderboardListContainer = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _gameSparks = _interopRequireDefault(require("../../core/gameSparks"));

var _leaderboardScreenStyles = _interopRequireWildcard(require("./leaderboard-screen-styles"));

var _blankSlate = _interopRequireDefault(require("./content/blank-slate"));

var _header = _interopRequireDefault(require("./header"));

var _footer = _interopRequireDefault(require("./footer"));

var _content = _interopRequireDefault(require("./content"));

var _moment = _interopRequireDefault(require("moment"));

var _theme = _interopRequireDefault(require("../../theme"));

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var SCREEN_TITLE = 'TOP DJ Chart';
var _gameSparks$constants = _gameSparks.default.constants,
    TOP_DJ_COUNT = _gameSparks$constants.TOP_DJ_COUNT,
    LEADERBOARD_SCREEN_PROPS = _gameSparks$constants.LEADERBOARD_SCREEN_PROPS;

var LeaderboardListContainer = function (_React$PureComponent) {
  (0, _inherits2.default)(LeaderboardListContainer, _React$PureComponent);

  var _super = _createSuper(LeaderboardListContainer);

  function LeaderboardListContainer() {
    var _this;

    (0, _classCallCheck2.default)(this, LeaderboardListContainer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      wasLoadMorePressed: false,
      onEndReachedCalledDuringMomentum: false,
      isLoading: false,
      isLoadingMore: false,
      isRefreshing: false,
      lastItem: 0,
      isFinished: false,
      endOfMonth: (0, _moment.default)().utc(0).endOf('month').toDate(),
      showFromLastMonth: false
    };

    _this.resetLoaders = function () {
      return _this.setState({
        isLoading: false,
        isRefreshing: false,
        isLoadingMore: false
      });
    };

    _this.handleOnFinish = function () {
      _this.setState(function (prevState) {
        return {
          isFinished: !prevState.isFinished
        };
      });
    };

    _this.handleLoadLeaderboardData = function (initialLoad) {
      var _this$props = _this.props,
          loadLeaderboard = _this$props.loadLeaderboard,
          getGsPropertySet = _this$props.getGsPropertySet;

      _this.setState({
        wasLoadMorePressed: false,
        isLoading: initialLoad,
        isRefreshing: !initialLoad,
        isLoadingMore: initialLoad
      }, function () {
        loadLeaderboard({
          entryCount: TOP_DJ_COUNT,
          reset: true
        });
        getGsPropertySet(LEADERBOARD_SCREEN_PROPS);
      });
    };

    _this.handleLoadMore = function (loadMoreFromScroll) {
      var lastItem = _this.state.lastItem;
      var _this$props2 = _this.props,
          leaderboard = _this$props2.leaderboard,
          totalCount = _this$props2.totalCount,
          isLastMonthLeaderboard = _this$props2.isLastMonthLeaderboard;
      var isLoadMoreFromScroll = loadMoreFromScroll === true;
      var queryData = {};

      if (isLastMonthLeaderboard) {
        return;
      }

      if (totalCount === lastItem && isLoadMoreFromScroll) {
        return;
      }

      if (totalCount === leaderboard.length && !loadMoreFromScroll) {
        return;
      }

      if (isLoadMoreFromScroll) {
        queryData = {
          offset: lastItem
        };
      } else {
        queryData = {
          entryCount: lastItem,
          reset: true
        };
      }

      _this.setState({
        isLoadingMore: isLoadMoreFromScroll,
        wasLoadMorePressed: !isLoadMoreFromScroll
      }, function () {
        return _this.props.loadLeaderboard(_objectSpread({}, queryData));
      });
    };

    _this.handleMomentumScroll = function () {
      _this.setState({
        onEndReachedCalledDuringMomentum: false
      });
    };

    _this.handleEndReached = function (_ref) {
      var distanceFromEnd = _ref.distanceFromEnd;
      var _this$state = _this.state,
          onEndReachedCalledDuringMomentum = _this$state.onEndReachedCalledDuringMomentum,
          isLoading = _this$state.isLoading;

      if (!onEndReachedCalledDuringMomentum && !isLoading && distanceFromEnd > 50) {
        _this.setState({
          onEndReachedCalledDuringMomentum: true
        }, _this.handleLoadMore(true));
      }
    };

    _this.keyExtractor = function (item, index) {
      return index.toString();
    };

    _this.renderItem = function (_ref2) {
      var item = _ref2.item,
          index = _ref2.index;
      return _react.default.createElement(_content.default, {
        item: item,
        leaderboardUnit: _this.props.leaderboardUnit,
        index: index,
        wasLoadMorePressed: _this.state.wasLoadMorePressed,
        onPress: _this.handleLoadMore
      });
    };

    return _this;
  }

  (0, _createClass2.default)(LeaderboardListContainer, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, prevState) {
      var isLeaderboardLoading = this.props.isLeaderboardLoading;
      var prevLoading = prevProps.isLeaderboardLoading;

      if (prevLoading && prevLoading !== isLeaderboardLoading) {
        this.resetLoaders();
      }
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {}
  }, {
    key: "render",
    value: function render() {
      var _this$props3 = this.props,
          totalPrize = _this$props3.totalPrize,
          isLastMonthLeaderboard = _this$props3.isLastMonthLeaderboard;
      var leaderboard = this.props.leaderboard && this.props.leaderboard.length > 0 ? this.props.leaderboard : [];
      var _this$state2 = this.state,
          isLoading = _this$state2.isLoading,
          isLoadingMore = _this$state2.isLoadingMore,
          isRefreshing = _this$state2.isRefreshing,
          isFinished = _this$state2.isFinished,
          endOfMonth = _this$state2.endOfMonth;
      var isEmpty = leaderboard.length === 0;
      var finishedState = isLastMonthLeaderboard && !isEmpty;
      return _react.default.createElement(_reactNative.View, {
        style: [_leaderboardScreenStyles.default.container, {
          backgroundColor: _theme.default.colors.background
        }],
        testID: "LeaderboardListScreen"
      }, _react.default.createElement(_reactNativeSafeAreaView.default, {
        style: [_leaderboardScreenStyles.default.container, {
          backgroundColor: _theme.default.colors.background
        }],
        forceInset: {
          vertical: 'never',
          horizontal: 'always'
        }
      }, isLoading ? _react.default.createElement(_reactNative.ActivityIndicator, {
        size: "large",
        color: _theme.default.colors.white,
        style: _leaderboardScreenStyles.default.loader
      }) : _react.default.createElement(_reactNative.FlatList, {
        directionalLockEnabled: true,
        ListEmptyComponent: _react.default.createElement(_blankSlate.default, null),
        refreshControl: _react.default.createElement(_reactNative.RefreshControl, {
          tintColor: _theme.default.colors.white,
          refreshing: isRefreshing,
          onRefresh: this.handleLoadLeaderboardData
        }),
        pinchGestureEnabled: false,
        contentContainerStyle: _leaderboardScreenStyles.default.listContentContainer,
        data: leaderboard,
        renderItem: this.renderItem,
        keyExtractor: this.keyExtractor,
        onEndReached: this.handleEndReached,
        onEndReachedThreshold: 0.3,
        onMomentumScrollBegin: this.handleMomentumScroll,
        ListHeaderComponent: _react.default.createElement(_header.default, {
          isFinished: isFinished || finishedState,
          untilDate: endOfMonth,
          totalPrize: totalPrize,
          onFinish: this.handleOnFinish,
          isEmpty: isEmpty
        }),
        ListFooterComponent: _react.default.createElement(_footer.default, {
          isFinished: isFinished || finishedState,
          isLoading: isLoadingMore,
          isEmpty: isEmpty
        })
      })));
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(props, prevState) {
      var leaderboard = props.leaderboard,
          isLeaderboardLoading = props.isLeaderboardLoading;
      var lastItem = leaderboard && leaderboard[leaderboard.length - 1];

      if (!isLeaderboardLoading && lastItem && lastItem.rank) {
        return {
          lastItem: lastItem.rank
        };
      }

      return null;
    }
  }]);
  return LeaderboardListContainer;
}(_react.default.PureComponent);

exports.LeaderboardListContainer = LeaderboardListContainer;

LeaderboardListContainer.navigationOptions = function (_ref3) {
  var navigation = _ref3.navigation;
  return {
    title: SCREEN_TITLE,
    headerTintColor: _leaderboardScreenStyles.headerTintColor,
    headerTitleStyle: _leaderboardScreenStyles.default.headerTitleStyle,
    headerStyle: _leaderboardScreenStyles.default.headerStyle,
    headerLeft: _react.default.createElement(_reactNative.View, null),
    headerRight: _react.default.createElement(_reactNative.View, null)
  };
};

var _default = LeaderboardListContainer;
exports.default = _default;