var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _videoView = _interopRequireDefault(require("./video-view"));

var _reactNativeVideo = _interopRequireWildcard(require("react-native-video"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function VideoContainer(props) {
  var videoRef = (0, _react.useRef)(null);

  var _useState = (0, _react.useState)(true),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      paused = _useState2[0],
      setPaused = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      ended = _useState4[0],
      setEnded = _useState4[1];

  var _useState5 = (0, _react.useState)(),
      _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
      position = _useState6[0],
      setPosition = _useState6[1];

  var handlePlayPress = function handlePlayPress() {
    setPaused(!paused);

    if (paused && props.onPlayStart) {
      props.onPlayStart();
    }
  };

  var handleEnd = function handleEnd() {
    setPaused(true);
    setEnded(true);
  };

  var handleProgress = function handleProgress(data) {
    setPosition(data);
  };

  (0, _react.useEffect)(function () {
    if (ended) {
      videoRef.current.seek(0);

      var initialPosition = _objectSpread(_objectSpread({
        playableDuration: 0,
        seekableDuration: 0
      }, position), {}, {
        currentTime: 0
      });

      setEnded(false);
      setPosition(initialPosition);
    }
  }, [position, ended]);
  return _react.default.createElement(_videoView.default, (0, _extends2.default)({}, props, {
    ref: videoRef,
    paused: paused,
    onPlayPress: handlePlayPress,
    onVideoPress: handlePlayPress,
    onProgress: handleProgress,
    onEnd: handleEnd,
    style: props.style
  }));
}

var _default = VideoContainer;
exports.default = _default;