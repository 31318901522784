var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("../user/constants");

var _actions = require("./actions");

var _websocketConstants = require("../../constants/websocket-constants");

var _createReducer;

var InitialState = {
  status: _websocketConstants.WEBSOCKET_STATUS.DISCONNECTED,
  error: null,
  roomStates: {}
};
var reducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.connect.pending, function (state) {
  state.status = _websocketConstants.WEBSOCKET_STATUS.CONNECTING;
}), (0, _defineProperty2.default)(_createReducer, _actions.connect.fulfilled, function (state) {
  state.status = _websocketConstants.WEBSOCKET_STATUS.CONNECTED;
}), (0, _defineProperty2.default)(_createReducer, _actions.connect.rejected, function (state, _ref) {
  var error = _ref.error;
  state.error = error;

  if (!error.reason || !error.reason.includes('already')) {
    state.status = _websocketConstants.WEBSOCKET_STATUS.DISCONNECTED;
  }
}), (0, _defineProperty2.default)(_createReducer, _actions.disconnect.pending, function (state) {
  state.status = _websocketConstants.WEBSOCKET_STATUS.DISCONNECTING;
}), (0, _defineProperty2.default)(_createReducer, _actions.disconnect.fulfilled, function (state) {
  state.status = _websocketConstants.WEBSOCKET_STATUS.DISCONNECTED;
}), (0, _defineProperty2.default)(_createReducer, _actions.disconnected.type, function (state) {
  state.status = _websocketConstants.WEBSOCKET_STATUS.DISCONNECTED;
}), (0, _defineProperty2.default)(_createReducer, _actions.joinRoom.pending, function (state, _ref2) {
  var arg = _ref2.meta.arg;
  var roomId = arg.roomId;
  state.roomStates[roomId] = {
    arg: arg,
    room: {
      loaded: false,
      loading: true,
      error: null,
      participants: {}
    },
    tokens: {}
  };
}), (0, _defineProperty2.default)(_createReducer, _actions.watchRoom.pending, function (state, _ref3) {
  var arg = _ref3.meta.arg;
  var roomId = arg.roomId;
  state.roomStates[roomId] = {
    arg: arg,
    room: {
      loaded: false,
      loading: false,
      error: null,
      participants: {}
    },
    tokens: {}
  };
}), (0, _defineProperty2.default)(_createReducer, _actions.joinRoom.fulfilled, function (state, _ref4) {
  var arg = _ref4.meta.arg,
      payload = _ref4.payload;
  var roomId = arg.roomId;

  if (payload) {
    state.roomStates[roomId].tokens = payload;
  }

  state.roomStates[roomId].room.loading = false;
  state.roomStates[roomId].room.loaded = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.joinRoom.rejected, function (state, _ref5) {
  var arg = _ref5.meta.arg,
      error = _ref5.error;
  var roomId = arg.roomId;
  state.roomStates[roomId].room.error = error;
  state.roomStates[roomId].room.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.requestToJoinRoom.pending, function (state, _ref6) {
  var arg = _ref6.meta.arg;
  var roomId = arg.roomId;
  state.roomStates[roomId] = {
    arg: arg,
    room: {
      loaded: false,
      loading: true,
      error: null,
      isApproved: null,
      participants: {}
    },
    tokens: {}
  };
}), (0, _defineProperty2.default)(_createReducer, _actions.requestToJoinRoom.fulfilled, function (state, _ref7) {
  var arg = _ref7.meta.arg,
      payload = _ref7.payload;
  var roomId = arg.roomId;
  state.roomStates[roomId].room.participantRequests = (0, _defineProperty2.default)({}, payload.id, {
    uid: payload.id,
    roles: {}
  });
  state.roomStates[roomId].room.loaded = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.requestToJoinRoom.rejected, function (state, _ref8) {
  var arg = _ref8.meta.arg,
      error = _ref8.error;
  var roomId = arg.roomId;
  state.roomStates[roomId].room.error = error;
  state.roomStates[roomId].room.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.allowedAccessToRoom.type, function (state, _ref9) {
  var _ref9$payload = _ref9.payload,
      isApproved = _ref9$payload.isApproved,
      roomId = _ref9$payload.roomId;

  if (isApproved) {
    state.roomStates[roomId].isApproved = isApproved;
  }

  state.roomStates[roomId].room.loading = false;
  state.roomStates[roomId].room.loaded = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.deniedAccessToRoom.type, function (state, _ref10) {
  var _ref10$payload = _ref10.payload,
      isApproved = _ref10$payload.isApproved,
      roomId = _ref10$payload.roomId;
  state.roomStates[roomId].isApproved = isApproved;
  state.roomStates[roomId].room.loading = false;
  state.roomStates[roomId].room.loaded = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.kickedFromRoom.type, function (state, _ref11) {
  var roomId = _ref11.payload.roomId;
  state.roomStates[roomId].room.loading = false;
  state.roomStates[roomId].room.shouldBeKicked = true;
  state.roomStates[roomId].room.loaded = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.cancelRequestToJoinRoom.fulfilled, function (state, _ref12) {
  var arg = _ref12.meta.arg;
  delete state.roomStates[arg];
}), (0, _defineProperty2.default)(_createReducer, _actions.roomChanged.type, function (state, _ref13) {
  var payload = _ref13.payload;
  var roomId = payload.roomId,
      data = payload.data;

  if (state.roomStates[roomId]) {
    (0, _extends2.default)(state.roomStates[roomId].room, data.room);
  }
}), (0, _defineProperty2.default)(_createReducer, _actions.participantsChanged.type, function (state, _ref14) {
  var payload = _ref14.payload;
  var roomId = payload.roomId,
      data = payload.data;

  if (state.roomStates[roomId]) {
    state.roomStates[roomId].room.participants = data.participants;
  }
}), (0, _defineProperty2.default)(_createReducer, _actions.participantRequestsChanged.type, function (state, _ref15) {
  var payload = _ref15.payload;
  var roomId = payload.roomId,
      data = payload.data;

  if (state.roomStates[roomId]) {
    state.roomStates[roomId].room.participantRequests = data.participantRequests;
  }
}), (0, _defineProperty2.default)(_createReducer, _actions.leaveRoom.fulfilled, function (state, _ref16) {
  var arg = _ref16.meta.arg;
  delete state.roomStates[arg];
}), (0, _defineProperty2.default)(_createReducer, _actions.startScreenShare.fulfilled, function (state, _ref17) {
  var payload = _ref17.payload,
      arg = _ref17.meta.arg;

  if (state.roomStates[arg]) {
    (0, _extends2.default)(state.roomStates[arg].tokens, payload);
  }
}), (0, _defineProperty2.default)(_createReducer, _actions.stopScreenShare.pending, function (state, _ref18) {
  var arg = _ref18.meta.arg;

  if (state.roomStates[arg]) {
    state.roomStates[arg].tokens.screenShareToken = null;
    state.roomStates[arg].tokens.screenShareId = null;
  }
}), (0, _defineProperty2.default)(_createReducer, _actions.joinBreakoutRoom.type, function (state, _ref19) {
  var payload = _ref19.payload;
  state.roomStates[payload.mainRoomId].room.breakoutRoomToJoin = payload.breakoutRoomId;
  state.roomStates[payload.mainRoomId].room.noSpaceAvailable = payload.noSpaceAvailable;
}), (0, _defineProperty2.default)(_createReducer, _actions.setNoSpaceAvailable.type, function (state, _ref20) {
  var payload = _ref20.payload;
  state.roomStates.noSpaceAvailable = payload;
}), (0, _defineProperty2.default)(_createReducer, _constants.USER_LOGOUT, function () {
  return InitialState;
}), _createReducer));
var _default = reducer;
exports.default = _default;