var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RequestErrorBlock;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _formik = require("formik");

var _eventRegisterProvider = require("../../event-register-provider");

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    errorBlockContainer: {
      gridArea: 'error-block',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: 'auto',
      backgroundColor: theme.palette.error.main,
      boxSizing: 'border-box',
      color: theme.palette.buttonText.main,
      padding: '8px 0px'
    },
    emptyDiv: {
      gridArea: 'error-block',
      height: 0
    }
  };
});

function RequestErrorBlock() {
  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      activeStep = _useEventRegisterCont.activeStep,
      steps = _useEventRegisterCont.steps;

  var currentStepInfo = steps[activeStep];
  var classes = useStyles();
  return (currentStepInfo === null || currentStepInfo === void 0 ? void 0 : currentStepInfo.hasFormikWrapper) ? _react.default.createElement(FormikError, null) : _react.default.createElement("div", {
    className: classes.emptyDiv
  });
}

function FormikError() {
  var _useFormikContext = (0, _formik.useFormikContext)(),
      errors = _useFormikContext.errors;

  var showError = !!(errors === null || errors === void 0 ? void 0 : errors.submit);
  var classes = useStyles();
  return showError ? _react.default.createElement(_core.Box, {
    className: classes.errorBlockContainer
  }, _react.default.createElement(_core.Typography, {
    variation: "body1"
  }, errors.submit)) : _react.default.createElement("div", {
    className: classes.emptyDiv
  });
}