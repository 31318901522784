var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventLiveOverlayInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _EventLiveOverlayContent = _interopRequireDefault(require("./EventLiveOverlayContent"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var EventLiveOverlayInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(EventLiveOverlayInternal, _React$PureComponent);

  var _super = _createSuper(EventLiveOverlayInternal);

  function EventLiveOverlayInternal() {
    (0, _classCallCheck2.default)(this, EventLiveOverlayInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(EventLiveOverlayInternal, [{
    key: "render",
    value: function render() {
      return React.createElement(_reactNative.View, {
        style: [{
          height: 60,
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0
        }]
      }, React.createElement(_EventLiveOverlayContent.default, this.props));
    }
  }]);
  return EventLiveOverlayInternal;
}(React.PureComponent);

exports.EventLiveOverlayInternal = EventLiveOverlayInternal;
var _default = EventLiveOverlayInternal;
exports.default = _default;