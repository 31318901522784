var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkForUpdates = checkForUpdates;
exports.snoozeDeprecationWarning = snoozeDeprecationWarning;
exports.checkForUpdatesInProgress = checkForUpdatesInProgress;
exports.checkForUpdatesCompleted = checkForUpdatesCompleted;
exports.updateDevice = updateDevice;
exports.removeDevice = removeDevice;

var _actionTypes = require("./actionTypes");

var _reactNative = require("react-native");

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var RNLocalize = _interopRequireWildcard(require("react-native-localize"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _constants = require("./constants");

var _user = _interopRequireDefault(require("../user"));

function checkForUpdates() {
  return function (dispatch, getState, _ref) {
    var api = _ref.api;
    var state = getState();

    if (_reactNative.Platform.OS === 'web') {
      return;
    }

    if (state[_constants.NAME].getIn(['updateNotification', 'inProgress'])) {
      return Promise.resolve();
    }

    if (Date.now() - state[_constants.NAME].getIn(['updateNotification', 'lastCheckTime']) < 1000 * 60 * 60 * 24) {
      return Promise.resolve();
    }

    dispatch(checkForUpdatesInProgress());
    return api.get({
      action: "/version/".concat(_reactNative.Platform.OS, "/").concat(_reactNative.Platform.Version, "/").concat(_reactNativeDeviceInfo.default.getVersion()),
      params: {
        bundle: _reactNativeDeviceInfo.default.getBundleId()
      },
      userInitiated: false
    }).then(function (_ref2) {
      var response = _ref2.data;
      return dispatch(checkForUpdatesCompleted(response));
    }).catch(function () {
      return dispatch(checkForUpdatesCompleted());
    });
  };
}

function snoozeDeprecationWarning() {
  var durationInMinutes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 60 * 24;
  return {
    type: _actionTypes.SNOOZE_DEPRECATION_WARNING,
    durationInMinutes: durationInMinutes
  };
}

function checkForUpdatesInProgress() {
  return {
    type: _actionTypes.CHECKING_FOR_UPDATES
  };
}

function checkForUpdatesCompleted() {
  var response = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return {
    type: _actionTypes.CHECKING_FOR_UPDATES_COMPLETED,
    notification: response
  };
}

function updateDevice() {
  var token = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return function (dispatch, getState, _ref3) {
    var api = _ref3.api;

    if (_reactNative.Platform.OS === 'web') {
      return;
    }

    var userId = _user.default.selectors.getFirebaseUserUid(getState());

    if (!userId) {
      return Promise.resolve();
    }

    var getTokenPromise = token ? Promise.resolve(token) : _reactNativeFirebase.default.messaging().getToken();
    return getTokenPromise.then(function (fcmToken) {
      var deviceId = _reactNativeDeviceInfo.default.getUniqueId();

      var locales = RNLocalize.getLocales();
      return api.put({
        action: "/user_devices/".concat(deviceId),
        data: {
          deviceId: deviceId,
          fcmToken: fcmToken,
          appVersion: _reactNativeDeviceInfo.default.getVersion(),
          user: {
            id: userId
          },
          platform: _reactNative.Platform.OS,
          platformVersion: _reactNative.Platform.Version.toString(),
          timeZone: RNLocalize.getTimeZone(),
          locale: locales && locales[0] && locales[0].languageTag,
          make: _reactNativeDeviceInfo.default.getBrand(),
          model: _reactNativeDeviceInfo.default.getModel(),
          modelVersion: _reactNativeDeviceInfo.default.getDeviceId(),
          data: {
            bundle: _reactNativeDeviceInfo.default.getBundleId()
          }
        },
        userInitiated: false
      });
    });
  };
}

function removeDevice() {
  return function (dispatch, getState, _ref4) {
    var api = _ref4.api;

    if (_reactNative.Platform.OS === 'web') {
      return;
    }

    return api.delete({
      action: "/user_devices/".concat(_reactNativeDeviceInfo.default.getUniqueId())
    });
  };
}