Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    border: {
      borderBottomColor: theme.palette.borderColor.main,
      borderBottomWidth: 1,
      paddingBottom: theme.spacing(0.5),
      textTransform: 'uppercase'
    },
    container: {
      '@media(max-width: 425px)': {
        justifyContent: 'center'
      }
    }
  };
});
var _default = styles;
exports.default = _default;