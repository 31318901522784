Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    container: {
      marginLeft: 90,
      '@media(max-width: 1026px)': {
        marginLeft: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        paddingRight: 15
      }
    }
  };
});
var _default = styles;
exports.default = _default;