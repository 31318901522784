var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _selectors = require("../../../../core/expo/selectors");

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _ImageWithLoading = _interopRequireDefault(require("../../../../theme/components/image/ImageWithLoading"));

var _DynamicSection = _interopRequireDefault(require("../../../../theme/components/section/DynamicSection"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../../theme/DesignSystem");

var _colorUtils = _interopRequireDefault(require("../../../../utils/color-utils"));

var _externalLinkUtils = require("../../../../utils/external-link-utils");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _absoluteSpinner = _interopRequireDefault(require("../../../common/absolute-spinner"));

var _DefaultHTMLrenderer = _interopRequireDefault(require("../../../common/DefaultHTMLrenderer"));

var _boothDetailsStyles = _interopRequireDefault(require("./booth-details-styles"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var BoothDetailsContainer = function BoothDetailsContainer() {
  var Styles = (0, _boothDetailsStyles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var boothId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.BOOTH_ID);
  var booth = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.selectBoothById)(state, boothId);
  });
  return _react.default.createElement(_reactNative.ScrollView, null, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    mSpacing: ['s']
  }, !!booth.bannerUrl && _react.default.createElement(_ImageWithLoading.default, {
    loadingIndicator: _react.default.createElement(_DynamicSection.default, {
      parentBackground: "pageBackground",
      style: _objectSpread(_objectSpread({}, StyleSheet.absoluteFillObject), {}, {
        position: 'absolute',
        width: '100%',
        color: 'white'
      })
    }, _react.default.createElement(_absoluteSpinner.default, {
      color: (0, _colorUtils.default)(colors.pageBackground, '#FFFFFF', '#000000')
    })),
    errorFallback: _react.default.createElement(_DynamicSection.default, {
      parentBackground: "pageBackground",
      style: _objectSpread(_objectSpread({}, StyleSheet.absoluteFillObject), {}, {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%'
      })
    }, function (textColor) {
      return _react.default.createElement(_core.Typography, {
        style: {
          color: textColor
        }
      }, _i18n.default.t('rightSidebar.noImage'));
    }),
    imageProps: {
      style: [Styles.image],
      resizeMode: 'cover',
      onClick: function onClick() {
        return (0, _externalLinkUtils.openExternalUrl)(booth.bannerLink);
      }
    },
    url: booth.bannerUrl
  }), _react.default.createElement(_Spacing.default, {
    mSpacing: ['s', 'none', 'none', 'none'],
    direction: "column",
    alignItems: "stretch",
    style: Styles.content
  }, _react.default.createElement(_core.Typography, {
    variant: "caption",
    align: "center"
  }, _react.default.createElement(_DefaultHTMLrenderer.default, {
    source: booth.offerDescription
  })), _react.default.createElement(_Spacing.default, {
    mSpacing: ['s', 'none'],
    direction: "row",
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, null, booth.website && _react.default.createElement(_Spacing.default, {
    style: Styles.social,
    mSpacing: 'xs'
  }, _react.default.createElement(_reactFeather.Globe, {
    onClick: function onClick() {
      return (0, _externalLinkUtils.openExternalUrl)(booth.website);
    }
  })), booth.facebook && _react.default.createElement(_Spacing.default, {
    style: Styles.social,
    mSpacing: 'xs'
  }, _react.default.createElement(_reactFeather.Facebook, {
    onClick: function onClick() {
      return (0, _externalLinkUtils.openExternalUrl)(booth.facebook);
    }
  })), booth.twitter && _react.default.createElement(_Spacing.default, {
    style: Styles.social,
    mSpacing: 'xs'
  }, _react.default.createElement(_reactFeather.Twitter, {
    onClick: function onClick() {
      return (0, _externalLinkUtils.openExternalUrl)(booth.twitter);
    }
  })), booth.linkedin && _react.default.createElement(_Spacing.default, {
    style: Styles.social,
    mSpacing: 'xs'
  }, _react.default.createElement(_reactFeather.Linkedin, {
    onClick: function onClick() {
      return (0, _externalLinkUtils.openExternalUrl)(booth.linkedin);
    }
  })))), booth.offer !== '' && _react.default.createElement(_Spacing.default, {
    mSpacing: ['s', 'xs'],
    pSpacing: ['s', 'm'],
    direction: "row",
    alignItems: "center",
    justifyContent: "center",
    style: Styles.offer
  }, _react.default.createElement(_core.Typography, {
    variant: "h4",
    align: "center"
  }, booth.offer)), booth.website !== '' && booth.buttonText !== '' && _react.default.createElement(_Spacing.default, {
    mSpacing: ['s'],
    direction: "row",
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_ContainedButton.default, {
    onPress: function onPress() {
      return (0, _externalLinkUtils.openExternalUrl)(booth.website);
    }
  }, booth.buttonText)))));
};

var _default = BoothDetailsContainer;
exports.default = _default;