var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _videoTypeConstants = require("../../../../constants/video-type-constants");

var _selectors = require("../../../../core/event-info/selectors");

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _ImageWithLoading = _interopRequireDefault(require("../../../../theme/components/image/ImageWithLoading"));

var _DynamicSection = _interopRequireDefault(require("../../../../theme/components/section/DynamicSection"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../../theme/DesignSystem");

var _colorUtils = _interopRequireDefault(require("../../../../utils/color-utils"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _absoluteSpinner = _interopRequireDefault(require("../../../common/absolute-spinner"));

var _liveNowStyles = _interopRequireDefault(require("./live-now-styles"));

var _styles = _interopRequireDefault(require("./styles"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var LiveNow = function LiveNow(_ref) {
  var posterUrl = _ref.posterUrl,
      stage = _ref.stage,
      sessionTitle = _ref.sessionTitle,
      starting = _ref.starting,
      onPress = _ref.onPress,
      poster = _ref.poster,
      videoType = _ref.videoType,
      showTitleSection = _ref.showTitleSection,
      mode = _ref.mode,
      sessionDescription = _ref.sessionDescription;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      shape = _useTheme.shape;

  var Styles = (0, _liveNowStyles.default)();
  var classes = (0, _styles.default)();
  var eventInfo = (0, _reactRedux.useSelector)(_selectors.selectEventInfo);

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      loadedPreview = _useState2[0],
      setLoadedPreview = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      previewError = _useState4[0],
      setPreviewError = _useState4[1];

  var playButton = function playButton(textColor) {
    var absolutePositioning = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return _react.default.createElement(_Spacing.default, {
      style: [Styles.playButtonContainer, absolutePositioning ? Styles.absolute : '']
    }, _react.default.createElement(_reactFeather.Play, {
      color: textColor,
      size: 32,
      fill: textColor
    }));
  };

  var buildLivePreview = function buildLivePreview() {
    if (videoType === _videoTypeConstants.NETWORKING && mode === 'live') {
      return _react.default.createElement(_reactNative.View, {
        style: Styles.image
      }, _react.default.createElement(_Spacing.default, {
        style: Styles.previewText
      }, _react.default.createElement(_ContainedButton.default, {
        onPress: onPress
      }, _i18n.default.translate('liveNow.goToNetworkingArea'))));
    }

    switch (videoType) {
      case _videoTypeConstants.LECTURE:
      case _videoTypeConstants.NETWORKING:
        return _react.default.createElement(_reactNative.View, {
          style: {
            position: 'relative'
          }
        }, poster || _react.default.createElement(_ImageWithLoading.default, {
          loadingIndicator: _react.default.createElement(_DynamicSection.default, {
            parentBackground: "pageBackground",
            shaped: true,
            shaping: "cards",
            style: _objectSpread(_objectSpread({}, _reactNative.StyleSheet.absoluteFillObject), {}, {
              backgroundColor: 'transparent'
            })
          }, _react.default.createElement(_absoluteSpinner.default, {
            color: (0, _colorUtils.default)(colors.pageBackground, '#FFFFFF', '#000000'),
            shaped: true
          })),
          errorFallback: _react.default.createElement(_DynamicSection.default, {
            parentBackground: "pageBackground",
            shaped: true,
            style: _objectSpread(_objectSpread(_objectSpread({}, _reactNative.StyleSheet.absoluteFillObject), shape.cards), {}, {
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent'
            })
          }, function (textColor) {
            return playButton(textColor);
          }),
          containerProps: {
            style: _objectSpread({
              position: 'relative'
            }, shape.cards),
            alignItems: 'center',
            justifyContent: 'center'
          },
          imageProps: {
            style: [Styles.image, shape.cards],
            resizeMode: 'cover'
          },
          url: posterUrl || ' ',
          onLoadEnd: function onLoadEnd() {
            return setLoadedPreview(true);
          },
          onError: function onError() {
            return setPreviewError(true);
          }
        }), loadedPreview && !previewError && !!posterUrl ? playButton('#FFFFFF', true) : null);

      case _videoTypeConstants.BREAK:
        return _react.default.createElement(_reactNative.View, {
          style: Styles.image
        }, _react.default.createElement(_Spacing.default, {
          style: Styles.previewText
        }, _react.default.createElement(_core.Typography, {
          className: classes.textWhite
        }, _i18n.default.t('liveNow.break'))));

      default:
        return null;
    }
  };

  var livePreview = buildLivePreview();

  if (!livePreview) {
    return null;
  }

  return _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    justifyContent: "flex-start",
    style: Styles.container
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_reactNative.TouchableHighlight, {
    style: _objectSpread({
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start'
    }, shape.cards),
    onPress: onPress
  }, _react.default.createElement(_reactNative.ImageBackground, {
    source: eventInfo.bannerUrl,
    style: Styles.imgBackground,
    imageStyle: Styles.imageStyle
  }, (typeof eventInfo.showHeaderOverlay === 'undefined' || eventInfo.showHeaderOverlay) && _react.default.createElement(_reactNative.View, {
    style: Styles.imgBackgroundBlack
  }), livePreview)), showTitleSection && videoType && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
    mSpacing: ['s', 0, 0, 0]
  }, _react.default.createElement(_core.Typography, {
    variant: "h4"
  }, stage)), _react.default.createElement(_Spacing.default, {
    mSpacing: ['root', 0]
  }, _react.default.createElement(_core.Typography, null, sessionTitle)), _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: classes.startingText
  }, starting), _react.default.createElement(_core.Typography, {
    variant: "body1",
    className: classes.descriptionText
  }, sessionDescription))));
};

var _default = LiveNow;
exports.default = _default;