var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setNoSpaceAvailable = exports.muteUnmuteSpeaker = exports.stopScreenShare = exports.startScreenShare = exports.kickSpeakerFromLive = exports.declineLiveRequest = exports.allowLiveRequest = exports.stopLive = exports.startLive = exports.leaveLive = exports.cancelLiveRequest = exports.joinLiveRequest = exports.joinBreakoutRoom = exports.unsubscribeBreakoutRoomsStarted = exports.subscribeBreakoutRoomsStarted = exports.kickedFromRoom = exports.deniedAccessToRoom = exports.allowedAccessToRoom = exports.subscribeKickedFromRoom = exports.subscribeDeniedAccessToRoom = exports.subscribeAllowedAccessToRoom = exports.participantRequestsChanged = exports.participantsChanged = exports.roomChanged = exports.unsubscribeKickFromRoom = exports.unsubscribeRoomChanges = exports.unsubscribeParticipantsChanges = exports.subscribeRoomChanges = exports.unsubscribeParticipantRequestsChanges = exports.subscribeParticipantRequestsChanges = exports.subscribeParticipantsChanges = exports.disconnected = exports.stopBreakoutRooms = exports.startBreakoutRooms = exports.unwatchRoom = exports.watchRoom = exports.leaveRoom = exports.kickParticipantFromRoom = exports.denyRequestToJoinRoom = exports.allowRequestToJoinRoom = exports.cancelRequestToJoinRoom = exports.requestToJoinRoom = exports.joinRoom = exports.disconnect = exports.connect = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _selectors = require("../event-info/selectors");

var _selectors2 = require("../user/selectors");

var _constants = require("./constants");

var connect = (0, _toolkit.createAsyncThunk)(_constants.CONNECT, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(_, _ref) {
    var networkingService, getState, token, eventId;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            networkingService = _ref.extra.networkingService, getState = _ref.getState;
            token = (0, _selectors2.getFirebaseAccessToken)(getState());
            eventId = (0, _selectors.selectEventInfoId)(getState());
            _context.next = 5;
            return networkingService.connect({
              token: token,
              eventId: eventId
            });

          case 5:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.connect = connect;
var disconnect = (0, _toolkit.createAsyncThunk)(_constants.DISCONNECT, function () {
  var _ref4 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(_, _ref3) {
    var networkingService;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            networkingService = _ref3.extra.networkingService;
            _context2.next = 3;
            return networkingService.disconnect();

          case 3:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function (_x3, _x4) {
    return _ref4.apply(this, arguments);
  };
}());
exports.disconnect = disconnect;
var joinRoom = (0, _toolkit.createAsyncThunk)(_constants.JOIN, function () {
  var _ref6 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(data, _ref5) {
    var networkingService, dispatch, result;
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            networkingService = _ref5.extra.networkingService, dispatch = _ref5.dispatch;
            dispatch(subscribeParticipantsChanges(data.roomId));
            dispatch(subscribeParticipantRequestsChanges(data.roomId));
            dispatch(subscribeRoomChanges(data.roomId));
            dispatch(subscribeKickedFromRoom(data.roomId));
            _context3.next = 7;
            return networkingService.joinRoom(data);

          case 7:
            result = _context3.sent;
            return _context3.abrupt("return", result);

          case 9:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));

  return function (_x5, _x6) {
    return _ref6.apply(this, arguments);
  };
}());
exports.joinRoom = joinRoom;
var requestToJoinRoom = (0, _toolkit.createAsyncThunk)(_constants.REQUEST_TO_JOIN_ROOM, function () {
  var _ref8 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(data, _ref7) {
    var networkingService, dispatch, result;
    return _regenerator.default.wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            networkingService = _ref7.extra.networkingService, dispatch = _ref7.dispatch;
            dispatch(subscribeParticipantRequestsChanges(data.roomId));
            dispatch(subscribeAllowedAccessToRoom(data.roomId));
            dispatch(subscribeDeniedAccessToRoom(data.roomId));
            dispatch(subscribeParticipantsChanges(data.roomId));
            dispatch(subscribeRoomChanges(data.roomId));
            _context4.next = 8;
            return networkingService.requestToJoinRoom(data);

          case 8:
            result = _context4.sent;
            return _context4.abrupt("return", result);

          case 10:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4);
  }));

  return function (_x7, _x8) {
    return _ref8.apply(this, arguments);
  };
}());
exports.requestToJoinRoom = requestToJoinRoom;
var cancelRequestToJoinRoom = (0, _toolkit.createAsyncThunk)(_constants.CANCEL_REQUEST_TO_JOIN_ROOM, function () {
  var _ref10 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(roomId, _ref9) {
    var networkingService, dispatch;
    return _regenerator.default.wrap(function _callee5$(_context5) {
      while (1) {
        switch (_context5.prev = _context5.next) {
          case 0:
            networkingService = _ref9.extra.networkingService, dispatch = _ref9.dispatch;
            dispatch(unsubscribeParticipantRequestsChanges(roomId));
            dispatch(unsubscribeParticipantsChanges(roomId));
            dispatch(unsubscribeRoomChanges(roomId));
            _context5.next = 6;
            return networkingService.cancelRequestToJoinRoom(roomId);

          case 6:
            return _context5.abrupt("return", null);

          case 7:
          case "end":
            return _context5.stop();
        }
      }
    }, _callee5);
  }));

  return function (_x9, _x10) {
    return _ref10.apply(this, arguments);
  };
}());
exports.cancelRequestToJoinRoom = cancelRequestToJoinRoom;
var allowRequestToJoinRoom = (0, _toolkit.createAsyncThunk)(_constants.ALLOW_REQUEST_TO_JOIN_ROOM, function () {
  var _ref13 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee6(_ref11, _ref12) {
    var roomId, participantId, networkingService;
    return _regenerator.default.wrap(function _callee6$(_context6) {
      while (1) {
        switch (_context6.prev = _context6.next) {
          case 0:
            roomId = _ref11.roomId, participantId = _ref11.participantId;
            networkingService = _ref12.extra.networkingService;
            _context6.next = 4;
            return networkingService.allowRequestToJoinRoom(roomId, participantId);

          case 4:
          case "end":
            return _context6.stop();
        }
      }
    }, _callee6);
  }));

  return function (_x11, _x12) {
    return _ref13.apply(this, arguments);
  };
}());
exports.allowRequestToJoinRoom = allowRequestToJoinRoom;
var denyRequestToJoinRoom = (0, _toolkit.createAsyncThunk)(_constants.DENY_REQUEST_TO_JOIN_ROOM, function () {
  var _ref16 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee7(_ref14, _ref15) {
    var roomId, participantId, networkingService;
    return _regenerator.default.wrap(function _callee7$(_context7) {
      while (1) {
        switch (_context7.prev = _context7.next) {
          case 0:
            roomId = _ref14.roomId, participantId = _ref14.participantId;
            networkingService = _ref15.extra.networkingService;
            _context7.next = 4;
            return networkingService.denyRequestToJoinRoom(roomId, participantId);

          case 4:
          case "end":
            return _context7.stop();
        }
      }
    }, _callee7);
  }));

  return function (_x13, _x14) {
    return _ref16.apply(this, arguments);
  };
}());
exports.denyRequestToJoinRoom = denyRequestToJoinRoom;
var kickParticipantFromRoom = (0, _toolkit.createAsyncThunk)(_constants.KICK_FROM_ROOM, function () {
  var _ref19 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee8(_ref17, _ref18) {
    var roomId, participantId, networkingService;
    return _regenerator.default.wrap(function _callee8$(_context8) {
      while (1) {
        switch (_context8.prev = _context8.next) {
          case 0:
            roomId = _ref17.roomId, participantId = _ref17.participantId;
            networkingService = _ref18.extra.networkingService;
            _context8.next = 4;
            return networkingService.kickParticipantFromRoom({
              roomId: roomId,
              participantId: participantId
            });

          case 4:
          case "end":
            return _context8.stop();
        }
      }
    }, _callee8);
  }));

  return function (_x15, _x16) {
    return _ref19.apply(this, arguments);
  };
}());
exports.kickParticipantFromRoom = kickParticipantFromRoom;
var leaveRoom = (0, _toolkit.createAsyncThunk)(_constants.LEAVE, function () {
  var _ref21 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee9(roomId, _ref20) {
    var networkingService, dispatch;
    return _regenerator.default.wrap(function _callee9$(_context9) {
      while (1) {
        switch (_context9.prev = _context9.next) {
          case 0:
            networkingService = _ref20.extra.networkingService, dispatch = _ref20.dispatch;
            _context9.next = 3;
            return networkingService.leaveRoom(roomId);

          case 3:
            dispatch(unsubscribeParticipantsChanges(roomId));
            dispatch(unsubscribeRoomChanges(roomId));
            dispatch(unsubscribeKickFromRoom(roomId));

          case 6:
          case "end":
            return _context9.stop();
        }
      }
    }, _callee9);
  }));

  return function (_x17, _x18) {
    return _ref21.apply(this, arguments);
  };
}());
exports.leaveRoom = leaveRoom;
var watchRoom = (0, _toolkit.createAsyncThunk)(_constants.WATCH, function () {
  var _ref23 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee10(data, _ref22) {
    var networkingService, dispatch, result;
    return _regenerator.default.wrap(function _callee10$(_context10) {
      while (1) {
        switch (_context10.prev = _context10.next) {
          case 0:
            networkingService = _ref22.extra.networkingService, dispatch = _ref22.dispatch;
            dispatch(subscribeParticipantsChanges(data.roomId));
            dispatch(subscribeRoomChanges(data.roomId));

            if (data.mainRoom) {
              dispatch(subscribeBreakoutRoomsStarted(data.roomId));
            }

            _context10.next = 6;
            return networkingService.watch(data);

          case 6:
            result = _context10.sent;
            return _context10.abrupt("return", result);

          case 8:
          case "end":
            return _context10.stop();
        }
      }
    }, _callee10);
  }));

  return function (_x19, _x20) {
    return _ref23.apply(this, arguments);
  };
}());
exports.watchRoom = watchRoom;
var unwatchRoom = (0, _toolkit.createAsyncThunk)(_constants.UNWATCH, function () {
  var _ref25 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee11(roomId, _ref24) {
    var networkingService, dispatch;
    return _regenerator.default.wrap(function _callee11$(_context11) {
      while (1) {
        switch (_context11.prev = _context11.next) {
          case 0:
            networkingService = _ref24.extra.networkingService, dispatch = _ref24.dispatch;
            _context11.next = 3;
            return networkingService.unwatch(roomId);

          case 3:
            dispatch(unsubscribeParticipantsChanges(roomId));
            dispatch(unsubscribeRoomChanges(roomId));
            dispatch(unsubscribeBreakoutRoomsStarted(roomId));

          case 6:
          case "end":
            return _context11.stop();
        }
      }
    }, _callee11);
  }));

  return function (_x21, _x22) {
    return _ref25.apply(this, arguments);
  };
}());
exports.unwatchRoom = unwatchRoom;
var startBreakoutRooms = (0, _toolkit.createAsyncThunk)(_constants.START_BREAKOUT_ROOMS, function () {
  var _ref27 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee12(roomId, _ref26) {
    var networkingService;
    return _regenerator.default.wrap(function _callee12$(_context12) {
      while (1) {
        switch (_context12.prev = _context12.next) {
          case 0:
            networkingService = _ref26.extra.networkingService;
            _context12.next = 3;
            return networkingService.startBreakoutRooms(roomId);

          case 3:
          case "end":
            return _context12.stop();
        }
      }
    }, _callee12);
  }));

  return function (_x23, _x24) {
    return _ref27.apply(this, arguments);
  };
}());
exports.startBreakoutRooms = startBreakoutRooms;
var stopBreakoutRooms = (0, _toolkit.createAsyncThunk)(_constants.STOP_BREAKOUT_ROOMS, function () {
  var _ref29 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee13(roomId, _ref28) {
    var networkingService;
    return _regenerator.default.wrap(function _callee13$(_context13) {
      while (1) {
        switch (_context13.prev = _context13.next) {
          case 0:
            networkingService = _ref28.extra.networkingService;
            _context13.next = 3;
            return networkingService.stopBreakoutRooms(roomId);

          case 3:
          case "end":
            return _context13.stop();
        }
      }
    }, _callee13);
  }));

  return function (_x25, _x26) {
    return _ref29.apply(this, arguments);
  };
}());
exports.stopBreakoutRooms = stopBreakoutRooms;
var disconnected = (0, _toolkit.createAction)(_constants.DISCONNECTED);
exports.disconnected = disconnected;
var subscribeParticipantsChanges = (0, _toolkit.createAction)(_constants.SUBSCRIBE_PARTICIPANTS_CHANGES);
exports.subscribeParticipantsChanges = subscribeParticipantsChanges;
var subscribeParticipantRequestsChanges = (0, _toolkit.createAction)(_constants.SUBSCRIBE_PARTICIPANT_REQUESTS_CHANGES);
exports.subscribeParticipantRequestsChanges = subscribeParticipantRequestsChanges;
var unsubscribeParticipantRequestsChanges = (0, _toolkit.createAction)(_constants.UNSUBSCRIBE_PARTICIPANT_REQUESTS_CHANGES);
exports.unsubscribeParticipantRequestsChanges = unsubscribeParticipantRequestsChanges;
var subscribeRoomChanges = (0, _toolkit.createAction)(_constants.SUBSCRIBE_ROOM_CHANGES);
exports.subscribeRoomChanges = subscribeRoomChanges;
var unsubscribeParticipantsChanges = (0, _toolkit.createAction)(_constants.UNSUBSCRIBE_PARTICIPANTS_CHANGES);
exports.unsubscribeParticipantsChanges = unsubscribeParticipantsChanges;
var unsubscribeRoomChanges = (0, _toolkit.createAction)(_constants.UNSUBSCRIBE_ROOM_CHANGES);
exports.unsubscribeRoomChanges = unsubscribeRoomChanges;
var unsubscribeKickFromRoom = (0, _toolkit.createAction)(_constants.UNSUBSCRIBE_KICKED_FROM_ROOM);
exports.unsubscribeKickFromRoom = unsubscribeKickFromRoom;
var roomChanged = (0, _toolkit.createAction)(_constants.ROOM_CHANGED);
exports.roomChanged = roomChanged;
var participantsChanged = (0, _toolkit.createAction)(_constants.PARTICIPANTS_CHANGED);
exports.participantsChanged = participantsChanged;
var participantRequestsChanged = (0, _toolkit.createAction)(_constants.PARTICIPANT_REQUESTS_CHANGED);
exports.participantRequestsChanged = participantRequestsChanged;
var subscribeAllowedAccessToRoom = (0, _toolkit.createAction)(_constants.SUBSCRIBE_ALLOW_ACCESS_TO_ROOM);
exports.subscribeAllowedAccessToRoom = subscribeAllowedAccessToRoom;
var subscribeDeniedAccessToRoom = (0, _toolkit.createAction)(_constants.SUBSCRIBE_DENIED_ACCESS_TO_ROOM);
exports.subscribeDeniedAccessToRoom = subscribeDeniedAccessToRoom;
var subscribeKickedFromRoom = (0, _toolkit.createAction)(_constants.SUBSCRIBE_KICKED_FROM_ROOM);
exports.subscribeKickedFromRoom = subscribeKickedFromRoom;
var allowedAccessToRoom = (0, _toolkit.createAction)(_constants.ALLOWED_ACCESS_TO_ROOM);
exports.allowedAccessToRoom = allowedAccessToRoom;
var deniedAccessToRoom = (0, _toolkit.createAction)(_constants.DENIED_ACCESS_TO_ROOM);
exports.deniedAccessToRoom = deniedAccessToRoom;
var kickedFromRoom = (0, _toolkit.createAction)(_constants.KICKED_FROM_ROOM);
exports.kickedFromRoom = kickedFromRoom;
var subscribeBreakoutRoomsStarted = (0, _toolkit.createAction)(_constants.SUBSCRIBE_BREAKOUT_ROOMS_STARTED);
exports.subscribeBreakoutRoomsStarted = subscribeBreakoutRoomsStarted;
var unsubscribeBreakoutRoomsStarted = (0, _toolkit.createAction)(_constants.UNSUBSCRIBE_BREAKOUT_ROOMS_STARTED);
exports.unsubscribeBreakoutRoomsStarted = unsubscribeBreakoutRoomsStarted;
var joinBreakoutRoom = (0, _toolkit.createAction)(_constants.JOIN_BREAKOUT_ROOM);
exports.joinBreakoutRoom = joinBreakoutRoom;
var joinLiveRequest = (0, _toolkit.createAsyncThunk)(_constants.JOIN_LIVE_REQUEST, function () {
  var _ref31 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee14(roomId, _ref30) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee14$(_context14) {
      while (1) {
        switch (_context14.prev = _context14.next) {
          case 0:
            networkingService = _ref30.extra.networkingService;
            _context14.next = 3;
            return networkingService.joinLiveRequest(roomId);

          case 3:
            result = _context14.sent;
            return _context14.abrupt("return", result);

          case 5:
          case "end":
            return _context14.stop();
        }
      }
    }, _callee14);
  }));

  return function (_x27, _x28) {
    return _ref31.apply(this, arguments);
  };
}());
exports.joinLiveRequest = joinLiveRequest;
var cancelLiveRequest = (0, _toolkit.createAsyncThunk)(_constants.CANCEL_LIVE_REQUEST, function () {
  var _ref33 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee15(roomId, _ref32) {
    var networkingService;
    return _regenerator.default.wrap(function _callee15$(_context15) {
      while (1) {
        switch (_context15.prev = _context15.next) {
          case 0:
            networkingService = _ref32.extra.networkingService;
            _context15.next = 3;
            return networkingService.cancelLiveRequest(roomId);

          case 3:
          case "end":
            return _context15.stop();
        }
      }
    }, _callee15);
  }));

  return function (_x29, _x30) {
    return _ref33.apply(this, arguments);
  };
}());
exports.cancelLiveRequest = cancelLiveRequest;
var leaveLive = (0, _toolkit.createAsyncThunk)(_constants.LEAVE_LIVE, function () {
  var _ref35 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee16(roomId, _ref34) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee16$(_context16) {
      while (1) {
        switch (_context16.prev = _context16.next) {
          case 0:
            networkingService = _ref34.extra.networkingService;
            _context16.next = 3;
            return networkingService.leaveLive(roomId);

          case 3:
            result = _context16.sent;
            return _context16.abrupt("return", result);

          case 5:
          case "end":
            return _context16.stop();
        }
      }
    }, _callee16);
  }));

  return function (_x31, _x32) {
    return _ref35.apply(this, arguments);
  };
}());
exports.leaveLive = leaveLive;
var startLive = (0, _toolkit.createAsyncThunk)(_constants.START_LIVE, function () {
  var _ref37 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee17(roomId, _ref36) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee17$(_context17) {
      while (1) {
        switch (_context17.prev = _context17.next) {
          case 0:
            networkingService = _ref36.extra.networkingService;
            _context17.next = 3;
            return networkingService.startLive(roomId);

          case 3:
            result = _context17.sent;
            return _context17.abrupt("return", result);

          case 5:
          case "end":
            return _context17.stop();
        }
      }
    }, _callee17);
  }));

  return function (_x33, _x34) {
    return _ref37.apply(this, arguments);
  };
}());
exports.startLive = startLive;
var stopLive = (0, _toolkit.createAsyncThunk)(_constants.STOP_LIVE, function () {
  var _ref39 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee18(roomId, _ref38) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee18$(_context18) {
      while (1) {
        switch (_context18.prev = _context18.next) {
          case 0:
            networkingService = _ref38.extra.networkingService;
            _context18.next = 3;
            return networkingService.stopLive(roomId);

          case 3:
            result = _context18.sent;
            return _context18.abrupt("return", result);

          case 5:
          case "end":
            return _context18.stop();
        }
      }
    }, _callee18);
  }));

  return function (_x35, _x36) {
    return _ref39.apply(this, arguments);
  };
}());
exports.stopLive = stopLive;
var allowLiveRequest = (0, _toolkit.createAsyncThunk)(_constants.ALLOW_LIVE_REQUEST, function () {
  var _ref42 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee19(_ref40, _ref41) {
    var roomId, speakerId, networkingService, result;
    return _regenerator.default.wrap(function _callee19$(_context19) {
      while (1) {
        switch (_context19.prev = _context19.next) {
          case 0:
            roomId = _ref40.roomId, speakerId = _ref40.speakerId;
            networkingService = _ref41.extra.networkingService;
            _context19.next = 4;
            return networkingService.allowLiveRequest(roomId, speakerId);

          case 4:
            result = _context19.sent;
            return _context19.abrupt("return", result);

          case 6:
          case "end":
            return _context19.stop();
        }
      }
    }, _callee19);
  }));

  return function (_x37, _x38) {
    return _ref42.apply(this, arguments);
  };
}());
exports.allowLiveRequest = allowLiveRequest;
var declineLiveRequest = (0, _toolkit.createAsyncThunk)(_constants.DECLINE_LIVE_REQUEST, function () {
  var _ref45 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee20(_ref43, _ref44) {
    var roomId, speakerId, networkingService, result;
    return _regenerator.default.wrap(function _callee20$(_context20) {
      while (1) {
        switch (_context20.prev = _context20.next) {
          case 0:
            roomId = _ref43.roomId, speakerId = _ref43.speakerId;
            networkingService = _ref44.extra.networkingService;
            _context20.next = 4;
            return networkingService.declineLiveRequest(roomId, speakerId);

          case 4:
            result = _context20.sent;
            return _context20.abrupt("return", result);

          case 6:
          case "end":
            return _context20.stop();
        }
      }
    }, _callee20);
  }));

  return function (_x39, _x40) {
    return _ref45.apply(this, arguments);
  };
}());
exports.declineLiveRequest = declineLiveRequest;
var kickSpeakerFromLive = (0, _toolkit.createAsyncThunk)(_constants.KICK_SPEAKER_FROM_LIVE, function () {
  var _ref48 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee21(_ref46, _ref47) {
    var roomId, speakerId, networkingService, result;
    return _regenerator.default.wrap(function _callee21$(_context21) {
      while (1) {
        switch (_context21.prev = _context21.next) {
          case 0:
            roomId = _ref46.roomId, speakerId = _ref46.speakerId;
            networkingService = _ref47.extra.networkingService;
            _context21.next = 4;
            return networkingService.kickSpeakerFromLive(roomId, speakerId);

          case 4:
            result = _context21.sent;
            return _context21.abrupt("return", result);

          case 6:
          case "end":
            return _context21.stop();
        }
      }
    }, _callee21);
  }));

  return function (_x41, _x42) {
    return _ref48.apply(this, arguments);
  };
}());
exports.kickSpeakerFromLive = kickSpeakerFromLive;
var startScreenShare = (0, _toolkit.createAsyncThunk)(_constants.START_SCREEN_SHARE, function () {
  var _ref50 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee22(roomId, _ref49) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee22$(_context22) {
      while (1) {
        switch (_context22.prev = _context22.next) {
          case 0:
            networkingService = _ref49.extra.networkingService;
            _context22.next = 3;
            return networkingService.startScreenShare(roomId);

          case 3:
            result = _context22.sent;
            return _context22.abrupt("return", result);

          case 5:
          case "end":
            return _context22.stop();
        }
      }
    }, _callee22);
  }));

  return function (_x43, _x44) {
    return _ref50.apply(this, arguments);
  };
}());
exports.startScreenShare = startScreenShare;
var stopScreenShare = (0, _toolkit.createAsyncThunk)(_constants.STOP_SCREEN_SHARE, function () {
  var _ref52 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee23(roomId, _ref51) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee23$(_context23) {
      while (1) {
        switch (_context23.prev = _context23.next) {
          case 0:
            networkingService = _ref51.extra.networkingService;
            _context23.next = 3;
            return networkingService.stopScreenShare(roomId);

          case 3:
            result = _context23.sent;
            return _context23.abrupt("return", result);

          case 5:
          case "end":
            return _context23.stop();
        }
      }
    }, _callee23);
  }));

  return function (_x45, _x46) {
    return _ref52.apply(this, arguments);
  };
}());
exports.stopScreenShare = stopScreenShare;
var muteUnmuteSpeaker = (0, _toolkit.createAsyncThunk)(_constants.MUTE_UNMUTE_SPEAKER, function () {
  var _ref55 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee24(_ref53, _ref54) {
    var roomId, speakerId, _ref53$requestingUser, requestingUserId, networkingService, result;

    return _regenerator.default.wrap(function _callee24$(_context24) {
      while (1) {
        switch (_context24.prev = _context24.next) {
          case 0:
            roomId = _ref53.roomId, speakerId = _ref53.speakerId, _ref53$requestingUser = _ref53.requestingUserId, requestingUserId = _ref53$requestingUser === void 0 ? null : _ref53$requestingUser;
            networkingService = _ref54.extra.networkingService;
            _context24.next = 4;
            return networkingService.muteUnmuteSpeaker(roomId, {
              speakerId: speakerId,
              requestingUserId: requestingUserId
            });

          case 4:
            result = _context24.sent;
            return _context24.abrupt("return", result);

          case 6:
          case "end":
            return _context24.stop();
        }
      }
    }, _callee24);
  }));

  return function (_x47, _x48) {
    return _ref55.apply(this, arguments);
  };
}());
exports.muteUnmuteSpeaker = muteUnmuteSpeaker;
var setNoSpaceAvailable = (0, _toolkit.createAction)(_constants.NO_SPACE_AVAILABLE);
exports.setNoSpaceAvailable = setNoSpaceAvailable;