var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FacebookLoginButtonInternal = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNativeFbsdk = _interopRequireWildcard(require("react-native-fbsdk"));

var _common = require("../common");

var _user = _interopRequireDefault(require("../../core/user"));

var _iconButton = _interopRequireDefault(require("design-system/actions/icon-button"));

var _Analytics = require("../../core/services/Analytics");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var FacebookLoginButtonInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(FacebookLoginButtonInternal, _React$PureComponent);

  var _super = _createSuper(FacebookLoginButtonInternal);

  function FacebookLoginButtonInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, FacebookLoginButtonInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      inProgress: false
    };
    _this.pendingLogin = void 0;

    _this.handlePress = function () {
      (0, _Analytics.trackEvent)('login_press', {
        provider: _user.default.constants.LOGIN_PROVIDERS.FACEBOOK
      });

      _this.setState({
        inProgress: true
      });

      var permissions = [];

      if (process.env.ENVIRONMENT === 'live') {
        permissions = ['public_profile', 'user_link'];
      } else {
        permissions = ['public_profile'];
      }

      _reactNativeFbsdk.LoginManager.logOut();

      _this.pendingLogin = (0, _common.makeCancelable)(_reactNativeFbsdk.LoginManager.logInWithPermissions(permissions).then(function (result) {
        if (!result.isCancelled) {
          return _reactNativeFbsdk.AccessToken.getCurrentAccessToken().then(function (tokenProvider) {
            if (tokenProvider) {
              return _this.props.login(_user.default.constants.LOGIN_PROVIDERS.FACEBOOK, tokenProvider.accessToken).then(function () {
                if (_this.props.onSuccess) {
                  _this.props.onSuccess();
                }
              });
            }
          });
        }
      }));

      _this.pendingLogin.promise.then(function () {
        _this.setState({
          inProgress: false
        });
      }).catch(function (_ref) {
        var isCancelled = _ref.isCancelled;

        if (!isCancelled) {
          _this.setState({
            inProgress: false
          });
        }
      });
    };

    _this.responseFacebook = function (response) {
      _this.props.login(_user.default.constants.LOGIN_PROVIDERS.FACEBOOK, response.accessToken).then(function () {
        if (_this.props.onSuccess) {
          _this.props.onSuccess();
        }
      });
    };

    _this.getButtonProps = function () {
      return {
        color: '#3968AF',
        iconColor: '#fff',
        icon: 'facebook',
        circleButton: true,
        size: 'large',
        disabled: _this.state.inProgress,
        style: _this.props.style
      };
    };

    return _this;
  }

  (0, _createClass2.default)(FacebookLoginButtonInternal, [{
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.pendingLogin) {
        this.pendingLogin.cancel();
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      if (_reactNative.Platform.OS === 'web') {
        return _react.default.createElement(_reactNativeFbsdk.default, {
          appId: process.env.FACEBOOK_APP_ID,
          autoLoad: false,
          onRequest: function onRequest() {
            return _this2.setState({
              inProgress: true
            });
          },
          buttonProps: this.getButtonProps(),
          fields: "name,email,picture",
          callback: this.responseFacebook
        });
      } else {
        return _react.default.createElement(_iconButton.default, (0, _extends2.default)({
          testID: 'FacebookAuthButton',
          onPress: this.handlePress
        }, this.getButtonProps()));
      }
    }
  }]);
  return FacebookLoginButtonInternal;
}(_react.default.PureComponent);

exports.FacebookLoginButtonInternal = FacebookLoginButtonInternal;

var _default = (0, _reactRedux.connect)(null, {
  login: _user.default.actions.loginWithProvider
})(FacebookLoginButtonInternal);

exports.default = _default;