var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _DesignSystem = require("../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _window = window,
    height = _window.innerHeight;
var useSideMenuStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: _objectSpread(_objectSpread({
      maxHeight: 'calc(100vh - 175px)',
      position: 'absolute',
      left: 0,
      top: 0,
      width: theme.spacing.leftMenu,
      borderColor: theme.colors.borderColor,
      borderRightWidth: 1,
      borderTopWidth: 1,
      borderBottomWidth: 1
    }, theme.shape.cards), {}, {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginTop: 8
    }),
    footer: {
      display: 'none'
    },
    closeIcon: {
      display: 'none'
    },
    buttonStyle: {
      width: '100%'
    },
    buttonTextStyle: {
      textAlign: 'center'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    container: {
      width: '100vw',
      minHeight: height,
      marginTop: 0,
      borderRadius: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0
    },
    innerContainer: {
      minWidth: '100vw',
      minHeight: height,
      flex: 1,
      padding: theme.spacing.l,
      display: 'flex',
      flexDirection: 'column'
    },
    footer: {
      display: 'block',
      marginTop: 'auto'
    },
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
      cursor: 'pointer',
      padding: theme.spacing.xl,
      zIndex: 90,
      display: 'block'
    },
    icon: {
      minWidth: theme.spacing.xl
    }
  };
}).buildHook();
var _default = useSideMenuStyles;
exports.default = _default;