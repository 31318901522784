var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _dateFormatter = require("../../../utils/date-formatter");

var _DesignSystem = require("../../../theme/DesignSystem");

var _core = require("@material-ui/core");

var _styles = _interopRequireDefault(require("./styles"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var RecordedVideoItem = function RecordedVideoItem(_ref) {
  var video = _ref.video;
  var navigation = (0, _useNavigationExtended.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      shape = _useTheme.shape;

  var classes = (0, _styles.default)();
  return _react.default.createElement(_core.Box, {
    p: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    width: "100%",
    maxWidth: 342,
    boxSizing: "border-box",
    className: classes.card
  }, _react.default.createElement(_reactNative.TouchableHighlight, {
    underlayColor: "".concat(colors.buttonHover, "66"),
    onPress: function onPress() {
      navigation.navigate(_routesConsts.Routes.RECORDED_VIDEO, {
        recordedVideoId: video.id
      });
    }
  }, _react.default.createElement(_reactNative.View, null, _react.default.createElement(_core.Box, {
    className: classes.videoContainer
  }, _react.default.createElement(_core.Box, {
    className: classes.innerContainer
  }, _react.default.createElement(_reactNative.ImageBackground, {
    class: "imgBg",
    imageStyle: _objectSpread({}, shape.cards),
    source: "https://image.mux.com/".concat(video.assetAccessId, "/thumbnail.png")
  }), _react.default.createElement(_reactNative.Image, {
    style: {
      width: 60,
      height: 60
    },
    source: {
      uri: 'https://firebasestorage.googleapis.com/v0/b/dev-eventmix.appspot.com/o/assets%2Fplay-icon.png?alt=media&token=334651cd-82cd-4b85-944c-d92770069d19'
    },
    resizeMode: "contain"
  }))))), video && _react.default.createElement(_core.Box, {
    mt: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_core.Typography, {
    variant: "h4"
  }, video.name), video.startDateTime && _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, "".concat((0, _dateFormatter.printHoursAndMinutes)(video.startDateTime.seconds), " - ").concat((0, _dateFormatter.printHoursAndMinutes)(video.endDateTime.seconds)))));
};

var _default = RecordedVideoItem;
exports.default = _default;