var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _DesignSystem = require("../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useLobbyPageStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      marginLeft: theme.spacing.leftMenu
    },
    header: {
      paddingBottom: theme.spacing.m
    },
    imgBackground: _objectSpread({
      flex: 1,
      justifyContent: 'center'
    }, theme.shape.cards),
    imageStyle: _objectSpread({}, theme.shape.cards),
    imgBackgroundBlack: _objectSpread(_objectSpread({
      backgroundColor: 'black',
      position: 'absolute',
      opacity: 0.75,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }, theme.shape.cards), {}, {
      zIndex: -1
    }),
    headerContainer: _objectSpread(_objectSpread({
      padding: theme.spacing.base * 6,
      alignItems: 'center'
    }, theme.shape.cards), {}, {
      flexDirection: 'row'
    }),
    headerContainerNoVideo: _objectSpread(_objectSpread({
      padding: theme.spacing.base * 6,
      alignItems: 'flex-start'
    }, theme.shape.cards), {}, {
      flexDirection: 'column'
    }),
    descriptionText: {
      zIndex: -1
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.lg;
}, function () {
  return {};
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.bg;
}, function (theme) {
  return {
    lobbyCard: {
      margin: theme.spacing.s,
      alignItems: 'stretch'
    }
  };
}).buildHook();
var _default = useLobbyPageStyles;
exports.default = _default;