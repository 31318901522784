var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FooterLoadingView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _footerLoadingStyles = _interopRequireWildcard(require("./footer-loading-styles"));

function FooterLoadingView() {
  return _react.default.createElement(_reactNative.View, {
    style: _footerLoadingStyles.default.container
  }, _react.default.createElement(_reactNative.ActivityIndicator, {
    color: _footerLoadingStyles.customStyles.loaderColor,
    animating: true,
    size: _footerLoadingStyles.customStyles.loaderSize
  }));
}