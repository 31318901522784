var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactNative = require("react-native");

var _spacing = require("../../../design-system/spacing");

var _colors = require("../../../design-system/theme/colors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = _reactNative.StyleSheet.create({
  container: {
    paddingTop: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  centeredContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 1200,
    padding: _spacing.Spacing.webLoose
  },
  error: {
    color: '#999',
    fontSize: 50,
    fontWeight: 'bold',
    marginBottom: _spacing.Spacing.loose
  },
  errorMessage: {
    fontSize: 20,
    paddingVertical: 6,
    marginLeft: 25,
    marginRight: 25
  },
  bold: {
    fontWeight: 'bold'
  },
  button: _objectSpread({
    margin: _spacing.Spacing.base,
    backgroundColor: _colors.Color.primary,
    padding: _spacing.Spacing.tight,
    borderRadius: 4,
    maxWidth: 300,
    width: '100%'
  }, _reactNative.Platform.select({
    web: {
      cursor: 'pointer'
    }
  })),
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: _spacing.Spacing.tight
  },
  topMargin: {
    marginTop: _spacing.Spacing.webLoose
  }
});

exports.default = _default;