var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createApi;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _qs = _interopRequireDefault(require("qs"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var checkResponseStatus = function checkResponseStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var message = response.statusText;

    if (message === null || message === undefined || message.length === 0) {
      message = response.status + ' Error';
    }

    var error = new Error(message);
    error.response = response;
    throw error;
  }
};

var processApiPromise = function processApiPromise(responsePromise, request) {
  return responsePromise.then(function (response) {
    return checkResponseStatus(response);
  }).then(function (okResponse) {
    if (okResponse.status === 204 || request.params.method === 'HEAD') {
      return {
        data: null,
        headers: okResponse.headers
      };
    }

    return okResponse.json().then(function (data) {
      return {
        data: data,
        headers: okResponse.headers
      };
    });
  }).catch(function (error) {
    if (error.response) {
      error.invalidData = error.response.status === 422;
      error.unauthenticated = error.response.status === 401;
      error.unauthorized = error.response.status === 403;
      error.redirect = error.response.status >= 300 && error.response.status < 400;
      error.clientError = error.response.status >= 400 && error.response.status < 500;
      error.serverError = error.response.status >= 500 && error.response.status < 600;
      error.maintenance = error.response.status === 503;
    }

    if (error instanceof SyntaxError) {
      error.serverError = true;
    }

    if (error instanceof TypeError) {
      error.noInternetConnection = true;
      error.serverError = true;
      error.message = 'There is no Internet connection';
    }

    throw error;
  }).catch(function (error) {
    if (error.clientError || error.redirect) {
      return new Promise(function (resolve, reject) {
        error.response.json().then(function (errorDetails) {
          error.data = errorDetails;
          error.message = Array.isArray(errorDetails) ? errorDetails[0].message : errorDetails.message;
          reject(error);
        }).catch(function () {
          return reject(error);
        });
      });
    }

    throw error;
  });
};

var Api = function () {
  function Api(options) {
    (0, _classCallCheck2.default)(this, Api);
    this.navigation = options.navigation;
    this.accessToken = null;
  }

  (0, _createClass2.default)(Api, [{
    key: "setAccessToken",
    value: function setAccessToken(token) {
      this.accessToken = token;
    }
  }, {
    key: "getAccessToken",
    value: function getAccessToken() {
      return this.accessToken;
    }
  }, {
    key: "getContentRange",
    value: function getContentRange(headers) {
      var contentRangeHeader = headers.get('X-Content-Range');
      var regex = new RegExp('(\\d+)-(\\d+)/(\\d+)', 'g');

      if (contentRangeHeader) {
        var match = regex.exec(contentRangeHeader);

        if (match) {
          return {
            from: parseInt(match[1], 10),
            to: parseInt(match[2], 10),
            total: parseInt(match[3], 10)
          };
        }
      }

      return {
        from: -1,
        to: -1,
        total: -1
      };
    }
  }, {
    key: "callApi",
    value: function callApi(action, data) {
      var _this = this;

      var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';
      var query = arguments.length > 3 ? arguments[3] : undefined;
      var headers = arguments.length > 4 ? arguments[4] : undefined;
      var userInitiated = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;
      var url = process.env.SOUNDMIX_API_HOST + action;

      var _q = _qs.default.stringify(query);

      if (_q.length > 0) {
        url += '?' + _q;
      }

      var params = {
        method: method,
        headers: {
          Accept: 'application/json'
        }
      };

      if (method !== 'GET' && data) {
        params.headers['Content-Type'] = 'application/json';
        params.body = JSON.stringify(data);
      }

      if (this.accessToken) {
        params.headers.Authorization = "Bearer ".concat(this.accessToken);
      }

      if (headers) {
        params.headers = _objectSpread(_objectSpread({}, params.headers), headers);
      }

      return processApiPromise(new Promise(function (resolve, reject) {
        try {
          resolve(fetch(url, params));
        } catch (error) {
          reject(error);
        }
      }), {
        url: url,
        params: params
      }).catch(function (originalError) {
        if (originalError.unauthenticated && userInitiated) {
          return new Promise(function (resolve, reject) {
            _this.navigation.navigate('Login', {
              resolve: resolve,
              reject: reject
            });
          }).then(function () {
            return _this.callApi(action, data, method, query, headers, userInitiated);
          }, function () {
            throw originalError;
          });
        }

        throw originalError;
      }).catch(function (error) {
        if (!error.unauthenticated && !error.invalidData && !error.maintenance && !error.redirect) {}

        throw error;
      });
    }
  }, {
    key: "post",
    value: function post(par) {
      return this.callApi(par.action, par.data, 'POST', par.params, par.headers, par.userInitiated);
    }
  }, {
    key: "get",
    value: function get(par) {
      return this.callApi(par.action, par.data, 'GET', par.params, par.headers, par.userInitiated);
    }
  }, {
    key: "put",
    value: function put(par) {
      return this.callApi(par.action, par.data, 'PUT', par.params, par.headers, par.userInitiated);
    }
  }, {
    key: "delete",
    value: function _delete(par) {
      return this.callApi(par.action, par.data, 'DELETE', par.params, par.headers, par.userInitiated);
    }
  }, {
    key: "head",
    value: function head(par) {
      return this.callApi(par.action, par.data, 'HEAD', par.params, par.headers, par.userInitiated);
    }
  }]);
  return Api;
}();

function createApi() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new Api(options);
}