var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SmartDate = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _ParagraphSmall = _interopRequireDefault(require("./typography_v1/ParagraphSmall"));

var _moment = _interopRequireDefault(require("moment"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var SmartDate = function (_React$PureComponent) {
  (0, _inherits2.default)(SmartDate, _React$PureComponent);

  var _super = _createSuper(SmartDate);

  function SmartDate() {
    var _this;

    (0, _classCallCheck2.default)(this, SmartDate);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      now: Date.now()
    };
    return _this;
  }

  (0, _createClass2.default)(SmartDate, [{
    key: "render",
    value: function render() {
      var date = (0, _moment.default)(this.props.date);
      var displayDirection = this.props.displayDirection;
      var flexDirection, separator;

      if (displayDirection === 'column') {
        flexDirection = 'column';
      } else if (displayDirection === 'row') {
        flexDirection = 'row';
        separator = ', ';
      } else {
        flexDirection = 'column';
      }

      return React.createElement(_reactNative.View, {
        style: [styles.container, {
          flexDirection: flexDirection
        }, this.props.style]
      }, React.createElement(_ParagraphSmall.default, {
        style: [styles.itemDate, {
          fontSize: this.props.fontSize,
          lineHeight: this.props.fontSize * 130 / 100,
          color: this.props.color
        }]
      }, date.calendar(this.state.now, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'D MMMM',
        sameElse: 'D MMMM',
        lastDay: '[Yesterday]',
        lastWeek: 'D MMMM'
      }), separator), React.createElement(_ParagraphSmall.default, {
        style: [styles.itemDate, {
          fontSize: this.props.fontSize,
          lineHeight: this.props.fontSize * 130 / 100,
          color: this.props.color
        }]
      }, date.format('hh:mm A')));
    }
  }]);
  return SmartDate;
}(React.PureComponent);

exports.SmartDate = SmartDate;

var styles = _reactNative.StyleSheet.create({
  container: {
    flexDirection: 'column'
  },
  itemDate: {
    color: 'rgba(255,255,255,0.6)',
    lineHeight: 17
  }
});

var _default = SmartDate;
exports.default = _default;