var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.AppleSignInButtonInternal = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var AppleAuthentication = _interopRequireWildcard(require("expo-apple-authentication"));

var _user = _interopRequireDefault(require("../../core/user"));

var _Analytics = require("../../core/services/Analytics");

var _common = require("../common");

var _iconButton = _interopRequireDefault(require("design-system/actions/icon-button"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var AppleSignInButtonInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(AppleSignInButtonInternal, _React$PureComponent);

  var _super = _createSuper(AppleSignInButtonInternal);

  function AppleSignInButtonInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, AppleSignInButtonInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      inProgress: false,
      isAvailableAppleAuth: false
    };
    _this.pendingLogin = void 0;
    _this.pendingCheckAvailability = void 0;

    _this.handlePress = function () {
      (0, _Analytics.trackEvent)('login_press', {
        provider: _user.default.constants.LOGIN_PROVIDERS.APPLE
      });

      if (_this.state.inProgress) {
        return;
      }

      _this.setState({
        inProgress: true
      });

      _this.pendingLogin = (0, _common.makeCancelable)(AppleAuthentication.signInAsync({
        requestedScopes: [AppleAuthentication.AppleAuthenticationScope.FULL_NAME, AppleAuthentication.AppleAuthenticationScope.EMAIL]
      }).then(function (credentials) {
        if (credentials.identityToken) {
          var _credentials$fullName = credentials.fullName,
              familyName = _credentials$fullName.familyName,
              givenName = _credentials$fullName.givenName,
              middleName = _credentials$fullName.middleName;
          return _this.props.login(_user.default.constants.LOGIN_PROVIDERS.APPLE, credentials.identityToken, familyName + (givenName ? " ".concat(givenName) : '') + (middleName ? " ".concat(middleName) : '')).then(function () {
            if (_this.props.onSuccess) {
              _this.props.onSuccess();
            }
          });
        }
      }));

      _this.pendingLogin.promise.then(function () {
        _this.setState({
          inProgress: false
        });
      }).catch(function (e) {
        if (!e.isCancelled) {
          _this.setState({
            inProgress: false
          });
        }

        if (e.code === 'ERR_CANCELED') {}
      });
    };

    _this.getButtonProps = function () {
      return {
        color: '#fff',
        iconColor: 'black',
        icon: 'apple',
        circleButton: true,
        size: 'large',
        disabled: _this.state.inProgress
      };
    };

    _this.getButtonActions = function () {
      return {
        onPress: _this.handlePress
      };
    };

    return _this;
  }

  (0, _createClass2.default)(AppleSignInButtonInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      this.pendingCheckAvailability = (0, _common.makeCancelable)(AppleAuthentication.isAvailableAsync());
      this.pendingCheckAvailability.promise.then(function (isAvailableAppleAuth) {
        _this2.setState({
          isAvailableAppleAuth: isAvailableAppleAuth
        });
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.pendingLogin) {
        this.pendingLogin.cancel();
      }

      if (this.pendingCheckAvailability) {
        this.pendingCheckAvailability.cancel();
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react.default.createElement(_reactNative.View, {
        style: [this.state.isAvailableAppleAuth ? {} : styles.hidden, this.props.style]
      }, _react.default.createElement(_iconButton.default, (0, _extends2.default)({
        testID: 'AppleAuthButton'
      }, this.getButtonProps(), this.getButtonActions())));
    }
  }]);
  return AppleSignInButtonInternal;
}(_react.default.PureComponent);

exports.AppleSignInButtonInternal = AppleSignInButtonInternal;

var styles = _reactNative.StyleSheet.create({
  hidden: {
    width: 0,
    height: 0,
    position: 'absolute'
  }
});

var _default = (0, _reactRedux.connect)(null, {
  login: _user.default.actions.loginWithProvider
})(AppleSignInButtonInternal);

exports.default = _default;