var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SettingsScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _notifications = _interopRequireDefault(require("../../core/notifications"));

var _user = _interopRequireDefault(require("../../core/user"));

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _Button = _interopRequireDefault(require("../common/Button"));

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _Title = _interopRequireDefault(require("../common/typography_v1/Title"));

var _NavigationButtons = require("../../navigation/NavigationButtons");

var _constants = require("../../../constants");

var _reactNativeIntercom = _interopRequireDefault(require("react-native-intercom"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var SettingsScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(SettingsScreenInternal, _React$PureComponent);

  var _super = _createSuper(SettingsScreenInternal);

  function SettingsScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, SettingsScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.handleLogout = function () {
      _this.props.logout();
    };

    _this.handleContact = function () {
      if (_this.props.userId) {
        var userId = _this.props.userId.toString();

        _reactNativeIntercom.default.registerIdentifiedUser({
          userId: userId
        });

        _reactNativeIntercom.default.updateUser({
          user_id: userId,
          name: _this.props.userName
        });
      }

      _reactNativeIntercom.default.displayMessageComposer();
    };

    return _this;
  }

  (0, _createClass2.default)(SettingsScreenInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {}
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var colors = this.props.theme.colors;
      return _react.default.createElement(_reactNative.ScrollView, {
        style: {
          backgroundColor: colors.background
        },
        testID: "SettingsScreen"
      }, _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_reactNative.View, {
        style: styles.settingsSection
      }, _react.default.createElement(_Title.default, {
        style: styles.itemTitle
      }, "ACCOUNT"), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "giftsPaymentsButton",
        borderless: false,
        onPress: function onPress() {
          _this2.props.navigation.navigate('GiftsPayments');
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.item
      }, _react.default.createElement(_Paragraph.default, null, "Beats balance"))), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "blockedUsersButton",
        borderless: false,
        onPress: function onPress() {
          _this2.props.navigation.navigate('BlockedUsers');
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.item
      }, _react.default.createElement(_Paragraph.default, null, "Blocked Users"))), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "djButton",
        borderless: false,
        onPress: function onPress() {
          _reactNative.Linking.openURL(_constants.DJ_PLATFORM);
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.item
      }, _react.default.createElement(_Paragraph.default, null, "I want to broadcast")))), _react.default.createElement(_reactNative.View, {
        style: styles.settingsSection
      }, _react.default.createElement(_Paragraph.default, {
        style: styles.itemTitle
      }, "ABOUT"), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "aboutButton",
        borderless: false,
        onPress: function onPress() {
          _reactNative.Linking.openURL(_constants.MAIN_WEBSITE);
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.item
      }, _react.default.createElement(_Paragraph.default, null, "About Soundmix"), _react.default.createElement(_reactNative.View, {
        style: styles.chip
      }, _react.default.createElement(_Paragraph.default, null, "Beta")))), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "privacyButton",
        borderless: false,
        onPress: function onPress() {
          _reactNative.Linking.openURL(_constants.PRIVACY_POLICY);
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.item
      }, _react.default.createElement(_Paragraph.default, null, "Privacy Policy"))), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "termsButton",
        borderless: false,
        onPress: function onPress() {
          _reactNative.Linking.openURL(_constants.TERMS_OF_USE);
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.item
      }, _react.default.createElement(_Paragraph.default, null, "Terms of Service")))), _react.default.createElement(_reactNative.View, {
        style: styles.settingsSection
      }, _react.default.createElement(_Paragraph.default, {
        style: styles.itemTitle
      }, "NEED HELP?"), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "contactButton",
        borderless: false,
        onPress: this.handleContact
      }, _react.default.createElement(_reactNative.View, {
        style: styles.item
      }, _react.default.createElement(_Paragraph.default, null, "Contact Us"))), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "reportProblemButton",
        borderless: false,
        onPress: function onPress() {}
      }, _react.default.createElement(_reactNative.View, {
        style: styles.item
      }, _react.default.createElement(_Paragraph.default, null, "Report a Problem")))), this.props.isLoggedIn && _react.default.createElement(_reactNative.View, {
        style: styles.settingsSection
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "logoutButton",
        borderless: false,
        onPress: this.handleLogout
      }, _react.default.createElement(_reactNative.View, {
        style: styles.item
      }, _react.default.createElement(_Paragraph.default, null, "LOG OUT - ", this.props.userName)))), this.props.hasNewerVersion && _react.default.createElement(_reactNative.View, {
        style: styles.newVersionSection
      }, _react.default.createElement(_Paragraph.default, {
        style: {
          textAlign: 'center',
          fontSize: 12,
          marginBottom: 16
        }
      }, "A new app version is available. Update it now."), _react.default.createElement(_Button.default, {
        mode: "contained",
        color: colors.white,
        buttonSize: "large",
        onPress: function onPress() {
          _reactNative.Linking.canOpenURL(_this2.props.upgradeUrl).then(function (supported) {
            if (supported) {
              return _reactNative.Linking.openURL(_this2.props.upgradeUrl);
            }
          }).catch();
        },
        style: styles.actionButton
      }, "Update Soundmix")), _react.default.createElement(_reactNative.View, {
        style: styles.itemVersion
      }, _react.default.createElement(_reactNative.Image, {
        source: require('../common/logo/verticalWhiteLogo.png'),
        style: {
          height: 72,
          width: 60,
          marginBottom: 8
        }
      }), _react.default.createElement(_Paragraph.default, {
        style: {
          textAlign: 'center',
          fontSize: 12,
          opacity: 0.5
        }
      }, "Version ", _reactNativeDeviceInfo.default.getVersion()))));
    }
  }]);
  return SettingsScreenInternal;
}(_react.default.PureComponent);

exports.SettingsScreenInternal = SettingsScreenInternal;

SettingsScreenInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  return {
    title: 'Settings',
    headerTintColor: '#fff',
    headerTitleStyle: {
      flex: 1,
      textAlign: 'center',
      alignSelf: 'center',
      fontSize: 15
    },
    headerStyle: {
      backgroundColor: '#000',
      borderBottomColor: '#000'
    },
    headerLeft: (0, _NavigationButtons.backButton)(navigation),
    headerRight: _react.default.createElement(_reactNative.View, null)
  };
};

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  settingsSection: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderBottomWidth: 1,
    borderColor: 'rgba(255,255,255,0.2)',
    marginVertical: 12,
    paddingBottom: 16
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 16
  },
  itemTitle: {
    paddingHorizontal: 16,
    fontWeight: 'bold',
    marginBottom: 16
  },
  chip: {
    backgroundColor: 'rgba(255,255,255, 0.5)',
    borderRadius: 30,
    paddingHorizontal: 16
  },
  newVersionSection: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24
  },
  itemVersion: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 16
  },
  actionButton: {
    width: '100%',
    maxWidth: 400
  }
});

function mapStateToProps(state) {
  return {
    hasNewerVersion: _notifications.default.selectors.hasNewerVersion(state),
    userId: _user.default.selectors.getFirebaseUserUid(state),
    userName: _user.default.selectors.getUserName(state),
    userEmail: _user.default.selectors.getUserEmail(state),
    isLoggedIn: _user.default.selectors.isFirebaseUserLoggedIn(state),
    upgradeUrl: _notifications.default.selectors.getLatestAvailableVersionUrl(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  logout: _user.default.actions.userLogout
})((0, _reactNativePaper.withTheme)(SettingsScreenInternal));

exports.default = _default;