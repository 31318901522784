var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStandardizedFormField = createStandardizedFormField;
exports.createNonstandardFormField = createNonstandardFormField;
exports.createFormikSchema = createFormikSchema;
exports._renderInput = _renderInput;
exports.PASSWORDLESS_REGISTRATION_FORM = exports.REGISTRATION_FORM = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactFeather = require("react-feather");

var _react = _interopRequireDefault(require("react"));

var _yupUtils = require("../../../../utils/yup-utils");

var yup = _interopRequireWildcard(require("yup"));

var _Input = _interopRequireDefault(require("../../../../theme/components/inputs/Input"));

var _checkbox = _interopRequireDefault(require("../../../../theme/components/inputs/checkbox"));

var _file = _interopRequireDefault(require("../../../../theme/components/inputs/file.web"));

var _fileService = require("../../../../core/services/fileService");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _asyncUtils = require("../../../../utils/async-utils");

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _TextButton = _interopRequireDefault(require("../../../../theme/components/buttons/TextButton"));

var _OutlinedButton = _interopRequireDefault(require("../../../../theme/components/buttons/OutlinedButton"));

var _reactNative = require("react-native");

var _select = _interopRequireDefault(require("../../../../theme/components/inputs/select.web"));

var _multiSelect = _interopRequireDefault(require("../../../../theme/components/inputs/multiSelect.web"));

var _styles = _interopRequireDefault(require("./styles"));

var _FIELD_INITIAL_VALUES;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function createStandardizedFormField(nonStandardFormField) {
  var id = nonStandardFormField.id,
      label = nonStandardFormField.label,
      isMandatory = nonStandardFormField.isMandatory,
      fieldType = nonStandardFormField.selectedCustomFieldType.id;
  return {
    id: id,
    label: "".concat(label).concat(isMandatory ? '*' : ''),
    placeholder: '',
    iconLeft: null,
    customFields: nonStandardFormFieldCustomFields(nonStandardFormField)[fieldType],
    type: nonstandardFieldTypes[fieldType],
    validationType: nonStandardFieldValidationTypes[fieldType],
    validations: nonStandardFieldValidations(isMandatory)[fieldType]
  };
}

function nonStandardFormFieldCustomFields(nonStandardField) {
  return {
    '0': '',
    '1': '',
    '2': {
      options: nonStandardField.dropdownValues
    },
    '3': {
      buttonText: nonStandardField.buttonText
    },
    '4': {
      multiSelectOptions: nonStandardField.multiSelectValues
    }
  };
}

function nonStandardFieldValidations(isMandatory) {
  return {
    '0': [_yupUtils.VALIDATIONS.trim, isMandatory && _yupUtils.VALIDATIONS.required(['contactInformation.fieldRequired'])],
    '1': [isMandatory && _yupUtils.VALIDATIONS.oneOf([[true], 'contactInformation.fieldRequired'])],
    '2': [isMandatory && _yupUtils.VALIDATIONS.required(['contactInformation.fieldRequired'])],
    '3': [_yupUtils.VALIDATIONS.nullable, isMandatory && _yupUtils.VALIDATIONS.test(['is-empty', 'contactInformation.fieldRequired', function (value) {
      return value !== null;
    }])],
    '4': [isMandatory && _yupUtils.VALIDATIONS.required(['contactInformation.fieldRequired'])]
  };
}

var nonstandardFieldTypes = {
  '0': _yupUtils.INPUT_TYPES.TEXT,
  '1': _yupUtils.INPUT_TYPES.CHECKBOX,
  '2': _yupUtils.INPUT_TYPES.SELECT,
  '3': _yupUtils.INPUT_TYPES.FILE,
  '4': _yupUtils.INPUT_TYPES.MULTISELECT
};
var nonStandardFieldValidationTypes = {
  '0': _yupUtils.VALIDATION_TYPES.STRING,
  '1': _yupUtils.VALIDATION_TYPES.BOOLEAN,
  '2': _yupUtils.VALIDATION_TYPES.STRING,
  '3': _yupUtils.VALIDATION_TYPES.OBJECT,
  '4': _yupUtils.VALIDATION_TYPES.ARRAY
};

function createNonstandardFormField(values) {
  return function (_ref) {
    var id = _ref.id,
        label = _ref.label,
        type = _ref.selectedCustomFieldType.id,
        dropdownValues = _ref.dropdownValues,
        multiSelectValues = _ref.multiSelectValues;
    return {
      id: id,
      label: label,
      value: transformStandardValues({
        id: id,
        type: type,
        values: values,
        options: dropdownValues.reduce(function (accum, _ref2) {
          var id = _ref2.id,
              value = _ref2.value;
          return _objectSpread(_objectSpread({}, accum), {}, (0, _defineProperty2.default)({}, id, value));
        }, {}),
        multiSelectOptions: multiSelectValues ? multiSelectValues.reduce(function (accum, _ref3) {
          var id = _ref3.id,
              value = _ref3.value;
          return _objectSpread(_objectSpread({}, accum), {}, (0, _defineProperty2.default)({}, id, value));
        }, {}) : []
      }),
      type: type
    };
  };
}

function transformStandardValues(_ref4) {
  var id = _ref4.id,
      type = _ref4.type,
      values = _ref4.values,
      options = _ref4.options;

  switch (type) {
    case '0':
      return values[id] || '';

    case '1':
      return values[id] || false;

    case '2':
      return options[values[id]] || '';

    case '3':
      return values[id] || '';

    case '4':
      return values[id] || [];

    default:
      return '';
  }
}

var FIELD_INITIAL_VALUES = (_FIELD_INITIAL_VALUES = {}, (0, _defineProperty2.default)(_FIELD_INITIAL_VALUES, _yupUtils.INPUT_TYPES.TEXT, ''), (0, _defineProperty2.default)(_FIELD_INITIAL_VALUES, _yupUtils.INPUT_TYPES.FILE, null), (0, _defineProperty2.default)(_FIELD_INITIAL_VALUES, _yupUtils.INPUT_TYPES.PASSWORD, ''), (0, _defineProperty2.default)(_FIELD_INITIAL_VALUES, _yupUtils.INPUT_TYPES.CHECKBOX, false), (0, _defineProperty2.default)(_FIELD_INITIAL_VALUES, _yupUtils.INPUT_TYPES.SELECT, ''), (0, _defineProperty2.default)(_FIELD_INITIAL_VALUES, _yupUtils.INPUT_TYPES.MULTISELECT, []), _FIELD_INITIAL_VALUES);

function createFormikSchema(fields, field) {
  return _objectSpread(_objectSpread({}, fields), {}, (0, _defineProperty2.default)({}, field.id, FIELD_INITIAL_VALUES[field.type]));
}

var REGISTRATION_FORM = [{
  id: 'username',
  label: 'contactInformation.username',
  placeholder: 'John Doe',
  iconLeft: _reactFeather.User,
  type: _yupUtils.INPUT_TYPES.TEXT,
  validationType: _yupUtils.VALIDATION_TYPES.STRING,
  validations: [_yupUtils.VALIDATIONS.trim, _yupUtils.VALIDATIONS.required(['contactInformation.usernameRequired'])]
}, {
  id: 'email',
  label: 'contactInformation.email',
  placeholder: 'john@doe.com',
  iconLeft: _reactFeather.Mail,
  type: _yupUtils.INPUT_TYPES.TEXT,
  validationType: _yupUtils.VALIDATION_TYPES.STRING,
  validations: [_yupUtils.VALIDATIONS.trim, _yupUtils.VALIDATIONS.required(['contactInformation.emailRequired']), _yupUtils.VALIDATIONS.email(['contactInformation.emailInvalid'])]
}, {
  id: 'password',
  label: 'contactInformation.password',
  placeholder: '********',
  iconLeft: _reactFeather.Key,
  type: _yupUtils.INPUT_TYPES.PASSWORD,
  validationType: _yupUtils.VALIDATION_TYPES.STRING,
  validations: [_yupUtils.VALIDATIONS.trim, _yupUtils.VALIDATIONS.required(['contactInformation.passwordRequired']), _yupUtils.VALIDATIONS.min([6, 'contactInformation.passwordMinCharacters'])]
}, {
  id: 'passwordConfirm',
  label: 'contactInformation.passwordConfirm',
  placeholder: '********',
  iconLeft: _reactFeather.Key,
  type: _yupUtils.INPUT_TYPES.PASSWORD,
  validationType: _yupUtils.VALIDATION_TYPES.STRING,
  validations: [_yupUtils.VALIDATIONS.trim, _yupUtils.VALIDATIONS.required(['contactInformation.passwordConfirmRequired']), _yupUtils.VALIDATIONS.oneOf([[yup.ref('password'), null], 'contactInformation.passwordMatch'])]
}];
exports.REGISTRATION_FORM = REGISTRATION_FORM;
var PASSWORDLESS_REGISTRATION_FORM = [{
  id: 'username',
  label: 'contactInformation.username',
  placeholder: 'John Doe',
  iconLeft: _reactFeather.User,
  type: _yupUtils.INPUT_TYPES.TEXT,
  validationType: _yupUtils.VALIDATION_TYPES.STRING,
  validations: [_yupUtils.VALIDATIONS.trim, _yupUtils.VALIDATIONS.required(['contactInformation.usernameRequired'])]
}, {
  id: 'email',
  label: 'contactInformation.email',
  placeholder: 'john@doe.com',
  iconLeft: _reactFeather.Mail,
  type: _yupUtils.INPUT_TYPES.TEXT,
  validationType: _yupUtils.VALIDATION_TYPES.STRING,
  validations: [_yupUtils.VALIDATIONS.trim, _yupUtils.VALIDATIONS.required(['contactInformation.emailRequired']), _yupUtils.VALIDATIONS.email(['contactInformation.emailInvalid'])]
}];
exports.PASSWORDLESS_REGISTRATION_FORM = PASSWORDLESS_REGISTRATION_FORM;

function _renderInput(_ref5) {
  var type = _ref5.type,
      id = _ref5.id,
      label = _ref5.label,
      value = _ref5.value,
      handleChange = _ref5.handleChange,
      submitForm = _ref5.submitForm,
      error = _ref5.error,
      touched = _ref5.touched,
      setFieldTouched = _ref5.setFieldTouched,
      setFieldValue = _ref5.setFieldValue,
      placeholder = _ref5.placeholder,
      handleBlur = _ref5.handleBlur,
      customFields = _ref5.customFields,
      IconLeft = _ref5.IconLeft,
      setFieldError = _ref5.setFieldError,
      eventId = _ref5.eventId,
      registrationId = _ref5.registrationId;
  var classes = (0, _styles.default)();
  if (value === undefined) return null;

  switch (type) {
    case _yupUtils.INPUT_TYPES.TEXT:
      return _react.default.createElement(_Input.default, {
        color: "alternativeText",
        shaped: true,
        type: type,
        value: value,
        onChange: handleChange,
        onKeyPress: function onKeyPress(_ref6) {
          var key = _ref6.nativeEvent.key;
          return key === 'Enter' && submitForm();
        },
        name: id,
        returnKeyType: 'next',
        error: !!error && touched,
        onBlur: function onBlur() {
          return setFieldTouched(id);
        },
        placeholder: placeholder || label,
        leftIcon: IconLeft && _react.default.createElement(IconLeft, null),
        dense: true
      });

    case _yupUtils.INPUT_TYPES.PASSWORD:
      return _react.default.createElement(_Input.default, {
        color: "alternativeText",
        shaped: true,
        type: type,
        value: value,
        onChange: handleChange,
        onKeyPress: function onKeyPress(_ref7) {
          var key = _ref7.nativeEvent.key;
          return key === 'Enter' && submitForm();
        },
        name: id,
        returnKeyType: 'next',
        error: !!error && touched,
        onBlur: function onBlur() {
          return setFieldTouched(id);
        },
        placeholder: placeholder,
        secureTextEntry: true,
        leftIcon: IconLeft && _react.default.createElement(IconLeft, null),
        dense: true
      });

    case _yupUtils.INPUT_TYPES.CHECKBOX:
      return _react.default.createElement(_checkbox.default, {
        value: value,
        name: id,
        activeColor: "button",
        label: label
      });

    case _yupUtils.INPUT_TYPES.FILE:
      return _react.default.createElement(_file.default, {
        accept: "image/*,.pdf,.doc,.docx",
        parser: function () {
          var _ref8 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(files, setLoading) {
            var _files$, name, type, size, basePath, path, _yield$handle, _yield$handle2, _yield$handle2$, downloadUrl, fileRef, uploadError, _yield$handle3, _yield$handle4, _, docCreationError;

            return _regenerator.default.wrap(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    _files$ = files[0], name = _files$.name, type = _files$.type, size = _files$.size;
                    basePath = "events/".concat(eventId, "/registration/").concat(registrationId);
                    path = "".concat(basePath, "/files/").concat(name);

                    if (!(size / 1024 / 1024 > 50)) {
                      _context.next = 6;
                      break;
                    }

                    setFieldError(id, _i18n.default.translate('contactInformation.error.fileSize'));
                    return _context.abrupt("return");

                  case 6:
                    setLoading(true);
                    _context.next = 9;
                    return (0, _asyncUtils.handle)((0, _fileService.firebaseFileUpload)(path, files[0], type));

                  case 9:
                    _yield$handle = _context.sent;
                    _yield$handle2 = (0, _slicedToArray2.default)(_yield$handle, 2);
                    _yield$handle2$ = (0, _slicedToArray2.default)(_yield$handle2[0], 2);
                    downloadUrl = _yield$handle2$[0];
                    fileRef = _yield$handle2$[1];
                    uploadError = _yield$handle2[1];

                    if (!uploadError) {
                      _context.next = 19;
                      break;
                    }

                    setLoading(false);
                    setFieldError(id, _i18n.default.translate('contactInformation.error.upload'));
                    return _context.abrupt("return");

                  case 19:
                    _context.next = 21;
                    return (0, _asyncUtils.handle)((0, _fileService.createCleanupFileDoc)(registrationId, id, path, basePath, eventId));

                  case 21:
                    _yield$handle3 = _context.sent;
                    _yield$handle4 = (0, _slicedToArray2.default)(_yield$handle3, 2);
                    _ = _yield$handle4[0];
                    docCreationError = _yield$handle4[1];

                    if (!docCreationError) {
                      _context.next = 30;
                      break;
                    }

                    fileRef.delete();
                    setLoading(false);
                    setFieldError(id, _i18n.default.translate('contactInformation.error.upload'));
                    return _context.abrupt("return");

                  case 30:
                    setLoading(false);
                    setFieldValue(id, {
                      name: name,
                      type: type,
                      size: size,
                      value: downloadUrl,
                      filePath: path
                    });

                  case 32:
                  case "end":
                    return _context.stop();
                }
              }
            }, _callee);
          }));

          return function (_x, _x2) {
            return _ref8.apply(this, arguments);
          };
        }()
      }, function (_, __, inputRef, loading) {
        return value ? _react.default.createElement(_Spacing.default, {
          alignItems: "center",
          justifyContent: "space-between"
        }, _react.default.createElement(_core.Typography, {
          className: classes.textTruncate
        }, value.name || value.type || value.size), _react.default.createElement(_TextButton.default, {
          onPress: function onPress() {
            return setFieldValue(id, null);
          },
          color: "alternativeText"
        }, _react.default.createElement(_reactFeather.Trash, null))) : _react.default.createElement(_OutlinedButton.default, {
          color: error && touched ? 'errorColor' : 'alternativeText',
          onPress: function onPress() {
            inputRef.current.focus();
            inputRef.current.click();
            setFieldTouched(id);
          }
        }, !loading ? customFields.buttonText : _react.default.createElement(_reactNative.ActivityIndicator, null));
      });

    case _yupUtils.INPUT_TYPES.SELECT:
      return _react.default.createElement(_select.default, {
        error: !!error && touched,
        rightIcon: _react.default.createElement(_reactFeather.ChevronDown, null),
        name: id,
        options: customFields.options,
        dense: true
      });

    case _yupUtils.INPUT_TYPES.MULTISELECT:
      return _react.default.createElement(_multiSelect.default, {
        error: error && touched ? true : false,
        name: id,
        value: value,
        onChange: handleChange,
        onBlur: handleBlur,
        onKeyPress: function onKeyPress(_ref9) {
          var key = _ref9.nativeEvent.key;
          return key === 'Enter' && submitForm();
        },
        options: customFields.multiSelectOptions
      });

    default:
      return null;
  }
}