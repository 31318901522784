var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleKick;

var _react = require("react");

var _reactRedux = require("react-redux");

var _actions = require("../../../core/user-rooms/actions");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _routesConsts = require("../../../navigation/client/routes-consts");

function HandleKick(_ref) {
  var shouldBeKicked = _ref.shouldBeKicked,
      roomId = _ref.roomId;
  var dispatch = (0, _reactRedux.useDispatch)();
  var navigation = (0, _useNavigationExtended.default)();
  (0, _react.useEffect)(function () {
    if (shouldBeKicked) {
      dispatch((0, _actions.leaveRoom)(roomId));
      navigation.navigate(_routesConsts.Routes.NETWORKING_PAGE);
    }
  }, [shouldBeKicked, roomId]);
  return null;
}