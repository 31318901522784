var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _DefaultHTMLrenderer = _interopRequireDefault(require("../../common/DefaultHTMLrenderer"));

var _Modal = _interopRequireDefault(require("../../../theme/components/modal/Modal"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _core = require("@material-ui/core");

var _dateFormatter = require("../../../utils/date-formatter");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _footer = _interopRequireDefault(require("../../common/footer"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _lobbyPageStyles = _interopRequireDefault(require("./lobby-page-styles"));

var _actions = require("../../../core/chat/actions");

var _selectors = require("../../../core/event-info/selectors");

var _displayNameModal = _interopRequireDefault(require("./display-name-modal"));

var _partners = _interopRequireDefault(require("../../partners"));

var _BaseVideo = _interopRequireDefault(require("../../common/audio-video/BaseVideo"));

var _selectors2 = require("../../../core/session/selectors");

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _AddToCalendarButton = _interopRequireDefault(require("../../../theme/components/buttons/AddToCalendarButton"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _selectors3 = require("../../../core/recordedVideos/selectors");

var _lobbyPageRightMenu = _interopRequireDefault(require("./lobby-page-right-menu"));

var _sessions = _interopRequireDefault(require("../../sessions"));

var _actions2 = require("../../../core/user/actions");

var _userActivityConstants = require("../../../constants/user-activity-constants");

var _styles = _interopRequireDefault(require("./styles"));

var _clsx = _interopRequireDefault(require("clsx"));

var LobbyPageContainer = function LobbyPageContainer(_ref) {
  var profile = _ref.profile,
      eventInfo = _ref.eventInfo,
      firstSession = _ref.firstSession,
      onDisplayNameUpdate = _ref.onDisplayNameUpdate;
  var eventDates = (0, _reactRedux.useSelector)(_selectors.getEventDates);

  var _React$useState = _react.default.useState({
    days: 0,
    hours: 0,
    minutes: 0
  }),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      date = _React$useState2[0],
      setDate = _React$useState2[1];

  var _timer;

  var session = firstSession || {
    start: eventInfo.startDate,
    end: eventInfo.endDate
  };
  var Styles = (0, _lobbyPageStyles.default)();
  var classes = (0, _styles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var now = new Date();
  var startDate = new Date(eventInfo.startDate);
  var endDate = new Date(eventInfo.endDate);
  var sessions = (0, _reactRedux.useSelector)(_selectors2.selectSessionsWithSpeakers, _reactRedux.shallowEqual);
  var lobbyVideo = (0, _reactRedux.useSelector)(_selectors3.selectLobbyPresentationVideo);
  var isLoading = (0, _reactRedux.useSelector)(_selectors3.selectRecordedVideosIsLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_selectors3.selectRecordedVideosIsLoaded);
  var mobileResolution = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.bg;
  });

  _react.default.useEffect(function () {
    setDate((0, _dateFormatter.getRemainingTime)(session.start));
    counter();
  }, [firstSession]);

  _react.default.useEffect(function () {
    if (eventInfo.id) {
      dispatch((0, _actions.sendRoomChange)(eventInfo.id));
    }
  }, [dispatch, eventInfo.id]);

  _react.default.useEffect(function () {
    (0, _actions2.trackUserActivity)({
      event: eventInfo,
      eventType: _userActivityConstants.TRACKABLE_EVENTS.LOBBY_PAGE_ACCESSED
    });
  }, []);

  _react.default.useEffect(function () {
    return function () {
      _timer && clearTimeout(_timer);
    };
  }, []);

  var counter = function counter() {
    _timer = setTimeout(function () {
      setDate((0, _dateFormatter.getRemainingTime)(session.start));
    }, 60 * 1000);
  };

  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });
  var showDisplayNameModal = !!profile && !(profile === null || profile === void 0 ? void 0 : profile.displayName) && !(profile === null || profile === void 0 ? void 0 : profile.fullName);

  var renderContent = function renderContent() {
    return _react.default.createElement(_core.Box, {
      m: 1,
      p: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      className: classes.sectionContainer,
      component: _core.Paper,
      elevation: 0
    }, now < startDate && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Typography, {
      align: "center",
      variant: "h3"
    }, _i18n.default.t('lobby.eventStartIn')), _react.default.createElement(_core.Typography, {
      align: "center",
      variant: "h3"
    }, "".concat(date.days, " ").concat(_i18n.default.t('global.days'), ", ").concat(date.hours, " ").concat(_i18n.default.t('global.hours'), ", ").concat(date.minutes, " ").concat(_i18n.default.t('global.minutes')))), now >= startDate && now <= endDate && _react.default.createElement(_core.Typography, {
      align: "center",
      variant: "h3"
    }, _i18n.default.t('lobby.eventStarted')), now > startDate && now > endDate && _react.default.createElement(_core.Typography, {
      align: "center",
      variant: "h3"
    }, _i18n.default.t('lobby.eventEnded')));
  };

  var eventStartDate = sessions && sessions.length > 0 ? (0, _momentTimezone.default)(sessions[0].start) : eventInfo.startDate;
  var eventEndDate = sessions && sessions.length > 0 ? (0, _momentTimezone.default)(sessions[sessions.length - 1].end) : eventInfo.endDate;

  var heroContent = _react.default.createElement(_react.default.Fragment, null, renderContent(), _react.default.createElement(_core.Box, {
    mx: 1
  }, _react.default.createElement(_reactNative.ImageBackground, {
    source: eventInfo.bannerUrl,
    style: Styles.imgBackground,
    imageStyle: Styles.imageStyle
  }, (typeof eventInfo.showHeaderOverlay === 'undefined' || eventInfo.showHeaderOverlay) && _react.default.createElement(_reactNative.View, {
    style: Styles.imgBackgroundBlack
  }), _react.default.createElement(_core.Box, {
    p: 3,
    display: "flex",
    className: lobbyVideo ? classes.headerContainer : classes.headerContainerNoVideo
  }, _react.default.createElement(_core.Box, {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    className: classes.columnContainer
  }, _react.default.createElement(_core.Box, {
    mb: 2
  }, _react.default.createElement(_core.Typography, {
    variant: "h1",
    className: (0, _clsx.default)(classes.textWhite, classes.title)
  }, eventInfo.headline)), _react.default.createElement(_core.Box, {
    mb: 2,
    display: "flex",
    alignItems: "center"
  }, _react.default.createElement(_core.Typography, {
    className: (0, _clsx.default)(classes.date, classes.textWhite),
    variant: "h4"
  }, eventDates)), _react.default.createElement(_core.Box, {
    mb: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_AddToCalendarButton.default, {
    start: (0, _momentTimezone.default)(eventStartDate).format(_dateFormatter.longDateTimeFormat),
    end: (0, _momentTimezone.default)(eventEndDate).format(_dateFormatter.longDateTimeFormat),
    timezone: eventInfo.timezone,
    title: eventInfo.name,
    description: eventInfo.description,
    location: window.location.href
  })), !!eventInfo.description && _react.default.createElement(_core.Box, {
    className: classes.descriptionText
  }, _react.default.createElement(_core.Typography, {
    variant: "body1",
    className: classes.textWhite
  }, _react.default.createElement(_DefaultHTMLrenderer.default, {
    source: eventInfo.description
  })))), !isLoading && isLoaded && lobbyVideo && lobbyVideo.uploadStatus !== 'UPLOADING' && _react.default.createElement(_core.Box, {
    flex: "1",
    className: classes.columnContainer
  }, _react.default.createElement(_BaseVideo.default, {
    src: lobbyVideo.downloadUrl,
    loopVideo: lobbyVideo.loopVideo,
    lobbyVideo: lobbyVideo.displayOnLobby
  }))))));

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%'
    }
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "stretch",
    className: classes.container
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    alignItems: "stretch"
  }, _react.default.createElement(_Section.default, {
    shaped: true
  }, heroContent, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    mx: 2,
    my: 2
  }, _react.default.createElement(_sessions.default, {
    improvedWithPreview: true,
    showFullSchedule: false
  })), mobileResolution && _react.default.createElement(_lobbyPageRightMenu.default, {
    eventInfo: eventInfo
  }), _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    mx: 2,
    my: 2
  }, _react.default.createElement(_partners.default, null)))), !mobileResolution && _react.default.createElement(_lobbyPageRightMenu.default, {
    eventInfo: eventInfo
  })), _react.default.createElement(_reactNative.View, {
    style: Styles.footer
  }, _react.default.createElement(_footer.default, null))), showDisplayNameModal && _react.default.createElement(_Modal.default, {
    content: _react.default.createElement(_displayNameModal.default, {
      onDisplayNameUpdate: onDisplayNameUpdate
    }),
    autoOpen: true
  }, function () {
    return _react.default.createElement(_react.default.Fragment, null);
  }));
};

var _default = LobbyPageContainer;
exports.default = _default;