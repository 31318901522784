var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _rightSideMenuContainer.default;
  }
});
Object.defineProperty(exports, "MenuBuilder", {
  enumerable: true,
  get: function get() {
    return _rightSideMenuContainer.MenuBuilder;
  }
});

var _rightSideMenuContainer = _interopRequireWildcard(require("./right-side-menu-container"));