var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnalyticsService = exports.trackMultipleEvents = exports.trackKeyEvent = exports.trackEvent = exports.service = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _asyncStorage = _interopRequireDefault(require("@react-native-community/async-storage"));

var _reactNativeAppsflyer = _interopRequireDefault(require("react-native-appsflyer"));

var _SqsAnalyticsService = require("./SqsAnalyticsService");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var KeyEventQueue = function KeyEventQueue(_ref) {
  var _this = this;

  var handleRequest = _ref.handleRequest,
      _ref$storageKey = _ref.storageKey,
      storageKey = _ref$storageKey === void 0 ? this.storageKey : _ref$storageKey,
      _ref$flushInterval = _ref.flushInterval,
      flushInterval = _ref$flushInterval === void 0 ? this.flushInterval : _ref$flushInterval;
  (0, _classCallCheck2.default)(this, KeyEventQueue);
  this.eventBuffer = [];
  this.handleRequest = void 0;
  this.storageKey = '@analytics_eventQueue';
  this.flushTimer = null;
  this.flushInterval = 1000;

  this.sendKeyEvent = function (eventId) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    params.timestamp = Date.now();

    _this.eventBuffer.push({
      eventId: eventId,
      params: params
    });

    _this.storeCurrentBuffer();

    if (!_this.flushTimer) {
      _this.flushQueue();
    }
  };

  this.flushQueue = function () {
    _this.flushTimer = setTimeout(function () {
      _this.eventBuffer.forEach(function (event) {
        _this.handleRequest(event.eventId, event.params).then(function () {
          _this.eventBuffer.splice(_this.eventBuffer.indexOf(event), 1);

          _this.storeCurrentBuffer();
        }).catch(function () {});
      });

      if (!_this.eventBuffer.length) {
        _this.flushTimer = null;
      } else {
        _this.flushQueue();
      }
    }, 1000);
  };

  this.loadBufferFromStorage = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
    var eventBuffer;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            _context.prev = 0;
            _context.next = 3;
            return _asyncStorage.default.getItem(_this.storageKey);

          case 3:
            eventBuffer = _context.sent;
            return _context.abrupt("return", JSON.parse(eventBuffer || '[]'));

          case 7:
            _context.prev = 7;
            _context.t0 = _context["catch"](0);
            throw _context.t0;

          case 10:
          case "end":
            return _context.stop();
        }
      }
    }, _callee, null, [[0, 7]]);
  }));
  this.storeCurrentBuffer = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2() {
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            _context2.prev = 0;
            _context2.next = 3;
            return _asyncStorage.default.setItem(_this.storageKey, JSON.stringify(_this.eventBuffer || []));

          case 3:
            return _context2.abrupt("return", _context2.sent);

          case 6:
            _context2.prev = 6;
            _context2.t0 = _context2["catch"](0);
            throw _context2.t0;

          case 9:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2, null, [[0, 6]]);
  }));
  this.clearStorage = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3() {
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            _context3.prev = 0;
            _context3.next = 3;
            return _asyncStorage.default.removeItem(_this.storageKey);

          case 3:
            return _context3.abrupt("return", _context3.sent);

          case 6:
            _context3.prev = 6;
            _context3.t0 = _context3["catch"](0);

          case 8:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3, null, [[0, 6]]);
  }));

  this.clearFlushTimer = function () {
    clearTimeout(_this.flushTimer);
  };

  this.destroy = function () {
    _this.clearStorage();
  };

  this.handleRequest = handleRequest;
  this.storageKey = storageKey;
  this.flushInterval = flushInterval;
  this.loadBufferFromStorage().then(function (storedBuffer) {
    if (storedBuffer.length) {
      _this.eventBuffer = storedBuffer;

      _this.flushQueue();
    }
  });
};

var AnalyticsService = function () {
  function AnalyticsService() {
    var _this2 = this;

    (0, _classCallCheck2.default)(this, AnalyticsService);
    this.SQSEventQueue = void 0;

    this.destroy = function () {
      _this2.SQSEventQueue.clearFlushTimer();
    };

    this.SQSEventQueue = new KeyEventQueue({
      handleRequest: this.logSQS,
      storageKey: '@analytics_SQSEventQueue',
      flushInterval: 1000
    });
  }

  (0, _createClass2.default)(AnalyticsService, [{
    key: "sanitize",
    value: function sanitize(value) {
      return value.replace(/[^\w]/gm, '_').substring(0, 40);
    }
  }, {
    key: "trackKeyEvent",
    value: function trackKeyEvent(eventId, params) {
      eventId = this.sanitize(eventId);
      this.trackEvent(eventId, _objectSpread({}, params));
      this.SQSEventQueue.sendKeyEvent(eventId, _objectSpread({}, params));
    }
  }, {
    key: "trackEvent",
    value: function trackEvent(eventId, params) {
      eventId = this.sanitize(eventId);
      this.logFirebase(eventId, params);
      this.logAppsFlyer(eventId, params);
    }
  }, {
    key: "logFirebase",
    value: function logFirebase(eventId, params) {
      _reactNativeFirebase.default.analytics().logEvent(eventId, params);
    }
  }, {
    key: "logAppsFlyer",
    value: function logAppsFlyer(eventId, params) {
      _reactNativeAppsflyer.default.trackEvent(eventId, params).then(function () {}).catch(function () {});
    }
  }, {
    key: "logSQS",
    value: function logSQS(eventId, params) {
      return _SqsAnalyticsService.SQSAnalytics.logEvent(eventId, params);
    }
  }]);
  return AnalyticsService;
}();

exports.AnalyticsService = AnalyticsService;
var service = new AnalyticsService();
exports.service = service;

var trackEvent = function trackEvent(eventId) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  service.trackEvent(eventId, params);
};

exports.trackEvent = trackEvent;

var trackKeyEvent = function trackKeyEvent(eventId) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  service.trackKeyEvent(eventId, params);
};

exports.trackKeyEvent = trackKeyEvent;

var trackMultipleEvents = function trackMultipleEvents() {
  var events = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  events.forEach(function (eventId) {
    if (eventId) {
      service.trackEvent("".concat(eventId), params);
    }
  });
};

exports.trackMultipleEvents = trackMultipleEvents;