var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DjBaseInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNavigation = require("react-navigation");

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _AvatarImage = _interopRequireDefault(require("../avatar/AvatarImage"));

var _Title = _interopRequireDefault(require("../typography_v1/Title"));

var _Paragraph = _interopRequireDefault(require("../typography_v1/Paragraph"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var DjBaseInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(DjBaseInternal, _React$PureComponent);

  var _super = _createSuper(DjBaseInternal);

  function DjBaseInternal() {
    (0, _classCallCheck2.default)(this, DjBaseInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(DjBaseInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this = this;

      if (this.props.djExplore) {
        this.props.loadDjs().then(function () {
          if (!_this.props.onHasLoaded()) {
            _this.props.onHasLoaded();
          }
        });
      } else {
        this.props.loadDjsMusicGenre(this.props.hashtags);
      }
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var _this2 = this;

      if (this.props.djExplore) {
        if (this.props.reloading && this.props.reloading !== prevProps.reloading) {
          this.props.loadDjs().then(function () {
            if (!_this2.props.onHasLoaded()) {
              _this2.props.onHasLoaded();
            }
          });
        }
      }
    }
  }, {
    key: "_renderItem",
    value: function _renderItem(item) {
      var _this3 = this;

      var name = item.getIn(['profile', 'displayName']);
      var nameInitials = name ? name.split(' ', 2).map(function (i) {
        return i.charAt(0);
      }) : '';
      var id = item.getIn(['profile', 'id']);
      var image = item.getIn(['profile', 'image', 'contentUrl']);
      return React.createElement(_reactNative.TouchableHighlight, {
        testID: 'TapDj' + id,
        key: id,
        onPress: function onPress() {
          _this3.props.navigation.navigate('Profile', {
            userId: id.toString(),
            djName: name,
            avatar: image
          });
        },
        style: styles.sectionItem
      }, React.createElement(_reactNative.View, {
        style: styles.userContainer
      }, React.createElement(_AvatarImage.default, {
        size: 90,
        source: {
          uri: image
        },
        defaultLabel: nameInitials
      }), React.createElement(_Paragraph.default, {
        numberOfLines: 2,
        ellipsizeMode: "tail",
        style: styles.userName
      }, name)));
    }
  }, {
    key: "render",
    value: function render() {
      var _this4 = this;

      return React.createElement(React.Fragment, null, this.props.djs.length > 0 && React.createElement(_reactNative.View, {
        style: styles.sectionContainer
      }, React.createElement(_Title.default, {
        style: styles.sectionTitle
      }, this.props.titleSection), React.createElement(_reactNative.ScrollView, {
        testID: "DjList",
        horizontal: true,
        directionalLockEnabled: true,
        pinchGestureEnabled: false,
        showsHorizontalScrollIndicator: false,
        contentContainerStyle: {
          paddingLeft: 16
        }
      }, this.props.djs.map(function (el) {
        return _this4._renderItem(el);
      }))));
    }
  }]);
  return DjBaseInternal;
}(React.PureComponent);

exports.DjBaseInternal = DjBaseInternal;

var styles = _reactNative.StyleSheet.create({
  sectionContainer: {
    marginVertical: 20
  },
  userContainer: {
    width: 90,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sectionItem: {
    marginRight: 16
  },
  userName: {
    lineHeight: 20,
    color: 'white',
    textAlign: 'center',
    marginTop: 8
  },
  sectionTitle: {
    lineHeight: 17,
    color: 'white',
    paddingHorizontal: 16,
    marginBottom: 16
  }
});

var _default = (0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(DjBaseInternal));

exports.default = _default;