var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _live = _interopRequireDefault(require("../live"));

var _user = _interopRequireDefault(require("../user"));

var _events = _interopRequireDefault(require("../events"));

var constants = _interopRequireWildcard(require("./constants"));

var _actions = require("./actions");

var _actionTypes = require("./actionTypes");

function createMiddleware(socket) {
  return function (store) {
    socket.on(_live.default.constants.WEBSOCKET_WELCOME_EVENT, onNumClapsChanged(socket, store));
    socket.on(constants.WEBSOCKET_NEW_FOLLOWER_EVENT, onNewFollower(socket, store));
    socket.on(constants.WEBSOCKET_LOST_FOLLOWER_EVENT, onLostFollower(socket, store));
    socket.on(constants.WEBSOCKET_NUMBER_OF_CLAPS_CHANGED_EVENT, onNumClapsChanged(socket, store));
    socket.on(constants.WEBSOCKET_TIP_EVENT, onNewTip(socket, store));
    return function (next) {
      return function (action) {
        var result = next(action);

        switch (action.type) {
          case _user.default.constants.LOGIN_COMPLETED:
            break;

          case _actionTypes.FOLLOW_COMPLETED:
            if (action.userId === _live.default.selectors.getDjId(store.getState())) {
              socket.emit(constants.WEBSOCKET_NEW_FOLLOWER_EVENT, {
                forUserId: action.userId
              });
            }

            break;

          case _actionTypes.UNFOLLOW_COMPLETED:
            if (action.userId === _live.default.selectors.getDjId(store.getState())) {
              socket.emit(constants.WEBSOCKET_LOST_FOLLOWER_EVENT, {
                forUserId: action.userId
              });
            }

            break;

          case _actionTypes.CLAP:
            socket.emit(constants.WEBSOCKET_CLAP_EVENT);
            break;

          case _actionTypes.TIP_COMPLETED:
            socket.emit(constants.WEBSOCKET_TIP_EVENT);
            break;

          case _events.default.constants.LOAD_SCHEDULED_EVENTS_COMPLETED:
            for (var i in action.events) {
              store.dispatch((0, _actions.loadNumberOfFollowers)(action.events[i].dj.id));
            }

            break;

          default:
            break;
        }

        return result;
      };
    };
  };
}

var onNewFollower = function onNewFollower(socket, store) {
  return function (response) {
    store.dispatch((0, _actions.newFollower)(response.forUserId));
  };
};

var onLostFollower = function onLostFollower(socket, store) {
  return function (response) {
    store.dispatch((0, _actions.lostFollower)(response.forUserId));
  };
};

var onNumClapsChanged = function onNumClapsChanged(socket, store) {
  return function (response) {
    store.dispatch((0, _actions.refreshClaps)(response.numClaps));
  };
};

var onNewTip = function onNewTip(socket, store) {
  return function (userData) {
    store.dispatch((0, _actions.receiveTip)(userData));
  };
};