var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactNative = require("react-native");

var _reactNativeMediaQueries = require("react-native-media-queries");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var base = {
  container: {
    flex: 1
  },
  KeyboardAvoidingViewContainer: {
    flex: 1,
    position: 'relative',
    justifyContent: 'flex-end'
  },
  commentsContainer: {
    flex: 1,
    paddingHorizontal: 10
  },
  commentsList: {
    flex: 1
  },
  commentContainer: {
    width: '100%'
  },
  comment: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 4,
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: 8,
    borderRadius: 10,
    overflow: 'hidden'
  },
  message: _objectSpread({}, _reactNative.Platform.select({
    web: {
      wordBreak: 'break-word'
    }
  })),
  commentText: {
    marginLeft: 8,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexShrink: 1
  },
  userName: {
    fontWeight: 'bold',
    lineHeight: 18,
    alignSelf: 'flex-start',
    marginBottom: 0
  },
  commentUser: {
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  commentAvatar: {
    borderColor: '#181D2B',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 34,
    width: 34,
    height: 34
  },
  commentActions: {
    paddingHorizontal: 10,
    paddingBottom: 2,
    borderRadius: 10,
    marginTop: 4
  },
  bold: {
    fontWeight: 'bold'
  },
  userJoined: {
    backgroundColor: 'rgba(255,255,255,0.5)'
  },
  unreadMessagesWrapper: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  jumpToEnd: {
    backgroundColor: 'white',
    borderRadius: 50,
    paddingHorizontal: 20,
    paddingVertical: 6,
    opacity: 0.9
  },
  jumpToEndText: {
    color: 'black',
    fontSize: 12
  },
  bottomBar: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 10
  },
  textInputWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  textInputStyle: _objectSpread({
    flex: 1,
    paddingHorizontal: 10,
    height: 45,
    marginRight: 10,
    alignSelf: 'center',
    color: 'black'
  }, _reactNative.Platform.select({
    web: {
      outlineStyle: 'none'
    }
  })),
  textInputButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 0,
    height: 40,
    borderRadius: 0
  },
  textInputCloseButton: {
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center'
  },
  textInputSendButton: {
    height: 40,
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center'
  },
  textInputSendButtonText: {
    fontWeight: 'bold'
  },
  iconButton: {
    backgroundColor: 'rgba(0,0,0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    width: 40,
    height: 40,
    margin: 0,
    marginLeft: 10
  },
  clapsButton: {
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#676767',
    width: 40,
    height: 40,
    margin: 0,
    marginLeft: 10
  },
  tipButton: {
    borderRadius: 4
  },
  clapsNrContainer: {
    position: 'absolute',
    width: 40,
    height: 40,
    bottom: 16,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50
  },
  clapsNrText: {
    color: 'white',
    fontSize: 12
  },
  clapsNumber: {
    position: 'absolute',
    bottom: 56,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center'
  }
};
var Styles = (0, _reactNativeMediaQueries.createStyles)(base, (0, _reactNativeMediaQueries.maxWidth)(768, {
  bottomBar: {
    marginBottom: 0
  }
}));
var _default = Styles;
exports.default = _default;