var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onTableChangedById = exports.subscribeTableChangeById = exports.onTablesChanged = exports.subscribeTablesChanges = exports.getTables = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _firebaseUtils = require("../../utils/firebase-utils");

var _selectors = require("../event-info/selectors");

var _constants = require("./constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getTables = (0, _toolkit.createAsyncThunk)(_constants.GET_TABLES, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(_, _ref) {
    var getState, eventId, tables;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            _context.next = 6;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.networkingTables(eventId));

          case 6:
            tables = _context.sent;
            return _context.abrupt("return", tables);

          case 8:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getTables = getTables;
var subscribeTablesChanges = (0, _toolkit.createAsyncThunk)(_constants.SUBSCRIBE_TABLES_CHANGES, function () {
  var _ref4 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(_, _ref3) {
    var getState, dispatch, eventId, unsubscribe;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            getState = _ref3.getState, dispatch = _ref3.dispatch;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context2.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            _context2.next = 6;
            return new Promise(function (resolve, reject) {
              var _unsubscribe = _firebaseUtils.firebaseEntities.networkingTables(eventId).orderBy('modifiedAt', 'desc').limit(1).onSnapshot(function (_ref5) {
                var docs = _ref5.docs;
                dispatch(onTablesChanged(docs.map(function (doc) {
                  return _objectSpread({
                    id: doc.id
                  }, doc.data());
                })));
              }, function (err) {
                return reject(err);
              });

              resolve(_unsubscribe);
            });

          case 6:
            unsubscribe = _context2.sent;
            return _context2.abrupt("return", unsubscribe);

          case 8:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function (_x3, _x4) {
    return _ref4.apply(this, arguments);
  };
}());
exports.subscribeTablesChanges = subscribeTablesChanges;
var onTablesChanged = (0, _toolkit.createAction)(_constants.ON_TABLES_CHANGED);
exports.onTablesChanged = onTablesChanged;
var subscribeTableChangeById = (0, _toolkit.createAsyncThunk)(_constants.SUBSCRIBE_TABLE_CHANGE_BY_ID, function () {
  var _ref7 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(tableId, _ref6) {
    var getState, dispatch, eventId, unsubscribe;
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            getState = _ref6.getState, dispatch = _ref6.dispatch;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context3.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            _context3.next = 6;
            return new Promise(function (resolve, reject) {
              resolve(_firebaseUtils.firebaseEntities.networkingTables(eventId).doc(tableId).onSnapshot(function (snapShot) {
                return dispatch(onTableChangedById(_objectSpread({
                  id: snapShot.id
                }, snapShot.data())));
              }, function (err) {
                return reject(err);
              }));
            });

          case 6:
            unsubscribe = _context3.sent;
            return _context3.abrupt("return", unsubscribe);

          case 8:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));

  return function (_x5, _x6) {
    return _ref7.apply(this, arguments);
  };
}());
exports.subscribeTableChangeById = subscribeTableChangeById;
var onTableChangedById = (0, _toolkit.createAction)(_constants.ON_TABLE_CHANGED_BY_ID);
exports.onTableChangedById = onTableChangedById;