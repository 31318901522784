Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useSessionListStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    description: {
      marginVertical: theme.spacing.s,
      fontSize: theme.fontSize
    },
    avatars: {
      alignItems: 'flex-start'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, function (theme) {
  return {
    avatars: {
      alignItems: 'center'
    }
  };
}).buildHook();
var _default = useSessionListStyles;
exports.default = _default;