Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var usePartnerStyle = new _DesignSystem.MakeStyle().addConstantStyle({
  image: {
    width: '100%',
    paddingBottom: '56.25%'
  }
}).addThemeBasedStyle(function (theme) {
  return {
    container: {
      width: 171,
      margin: 24
    }
  };
}).buildHook();
var _default = usePartnerStyle;
exports.default = _default;