var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../../theme/components/typography/Typography"));

var _actions = require("../../../core/user-rooms/actions");

var _selectors = require("../../../core/user-rooms/selectors");

var _speakerRequest = _interopRequireDefault(require("../../backstage/menu/speaker-requests/speaker-request"));

var ParticipantRequests = function ParticipantRequests() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var roomId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.TABLE_ID);
  var requestingUsers = (0, _reactRedux.useSelector)((0, _selectors.roomRequestingUsers)(roomId));
  var requestingUsersArray = Object.values(requestingUsers);

  var admitParticipantRequest = function () {
    var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(userId) {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              return _context.abrupt("return", dispatch((0, _actions.allowRequestToJoinRoom)({
                roomId: roomId,
                participantId: userId
              })));

            case 1:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function admitParticipantRequest(_x) {
      return _ref.apply(this, arguments);
    };
  }();

  var denyParticipantRequest = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(userId) {
      return _regenerator.default.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              return _context2.abrupt("return", dispatch((0, _actions.denyRequestToJoinRoom)({
                roomId: roomId,
                participantId: userId
              })));

            case 1:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    }));

    return function denyParticipantRequest(_x2) {
      return _ref2.apply(this, arguments);
    };
  }();

  if (!requestingUsersArray.length) return _react.default.createElement(_reactNative.ScrollView, {
    direction: "column",
    alignItems: "stretch",
    flex: 1,
    showsHorizontalScrollIndicator: false
  }, _react.default.createElement(_Spacing.default, {
    flex: 1,
    alignItems: "center",
    direction: "column",
    pSpacing: "l",
    justifyContent: "center"
  }, _react.default.createElement(_Typography.default, {
    color: "alternativeText",
    textAlign: "center"
  }, _i18n.default.translate('backstage.noRequests'))));
  return _react.default.createElement(_reactNative.ScrollView, {
    direction: "column",
    alignItems: "stretch",
    flex: 1,
    showsHorizontalScrollIndicator: false
  }, Object.values(requestingUsersArray).map(function (_ref3) {
    var uid = _ref3.uid,
        name = _ref3.name,
        picture = _ref3.picture;
    return _react.default.createElement(_speakerRequest.default, {
      id: uid,
      name: name,
      picture: picture,
      onAdmit: admitParticipantRequest,
      onDeny: denyParticipantRequest,
      key: uid
    });
  }));
};

var _default = ParticipantRequests;
exports.default = _default;