var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _reactNavigation = require("react-navigation");

var _queryParameter = require("../utils/queryParameter");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var NavigationService = function () {
  function NavigationService() {
    (0, _classCallCheck2.default)(this, NavigationService);
    this._navigator = null;
  }

  (0, _createClass2.default)(NavigationService, [{
    key: "setTopLevelNavigator",
    value: function setTopLevelNavigator(navigatorRef) {
      this._navigator = navigatorRef;
    }
  }, {
    key: "navigate",
    value: function navigate(routeName, params) {
      if (this._navigator) {
        this._navigator.dispatch(_reactNavigation.NavigationActions.navigate({
          routeName: routeName,
          params: params
        }));
      } else {
        throw new Error('You need to set the top level navigator first');
      }
    }
  }, {
    key: "navigateExtended",
    value: function navigateExtended(routeName) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var eventId = (0, _queryParameter.getEventIdParam)(window.location.href);

      if (this._navigator) {
        this._navigator.dispatch(_reactNavigation.NavigationActions.navigate({
          routeName: routeName,
          params: _objectSpread({
            eventId: eventId
          }, params)
        }));
      } else {
        throw new Error('You need to set the top level navigator first');
      }
    }
  }, {
    key: "resetNavigation",
    value: function resetNavigation(routeName, eventId) {
      var resetAction = _reactNavigation.StackActions.reset({
        index: 0,
        actions: [_reactNavigation.NavigationActions.navigate({
          routeName: routeName,
          params: {
            eventId: eventId
          }
        })],
        key: undefined
      });

      this._navigator.dispatch(resetAction);
    }
  }]);
  return NavigationService;
}();

var instance = new NavigationService();
var _default = instance;
exports.default = _default;