var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireWildcard(require("immutable"));

var _actionTypes = require("./actionTypes");

var InitialState = _immutable.default.fromJS({
  accessToken: null,
  hasIncompleteProfile: false,
  isBlockedUsersLoading: false,
  firebaseUser: null,
  isLoading: false,
  blockedUsers: (0, _immutable.List)(),
  confirmResult: null
});

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.LOGIN_COMPLETED:
      newState = newState.set('accessToken', action.accessToken);
      newState = newState.set('profile', _immutable.default.fromJS(action.user));
      break;

    case _actionTypes.USER_LOGOUT:
      newState = InitialState;
      break;

    case _actionTypes.USER_INCOMPLETE_PROFILE:
      newState = newState.set('hasIncompleteProfile', action.hasIncompleteProfile);
      break;

    case _actionTypes.PAYMENT_TICKET_COMPLETED:
      newState = newState.set('profile', _immutable.default.fromJS(action.user));
      break;

    case _actionTypes.SKIP_LOGIN:
      newState = newState.set('loginSkipped', action.skipLogin);
      break;

    case _actionTypes.FIREBASE_AUTH_STATE_LISTENING:
      newState = newState.set('isLoading', true);
      break;

    case _actionTypes.STOP_AUTH_STATE_LOADING:
      newState = newState.set('isLoading', false);
      break;

    case _actionTypes.FIREBASE_LOGIN_IN_PROGRESS:
      newState = newState.set('isLoading', true);
      break;

    case _actionTypes.FIREBASE_LOGIN_COMPLETED:
      newState = newState.set('firebaseUser', action.user);
      newState = newState.set('isLoading', false);
      break;

    case _actionTypes.FIREBASE_PHONE_AUTH_COMPLETED:
      newState = newState.set('confirmResult', action.confirmResult);
      break;

    case _actionTypes.FIREBASE_AUTH_STATE_CHANGED:
      newState = newState.set('firebaseUser', action.user);
      newState = newState.set('isLoading', false);
      break;

    case _actionTypes.FIREBASE_USER_PROFILE_UPDATE:
      newState = newState.set('firebaseUser', action.user);
      break;

    default:
      break;
  }

  return newState;
}