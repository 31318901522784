var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactContentLoader = _interopRequireDefault(require("react-content-loader"));

var _reactRedux = require("react-redux");

var _benefits = _interopRequireDefault(require("../../core/benefits"));

var _benefit = _interopRequireDefault(require("./benefit"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _core = require("@material-ui/core");

var _eventSettings = _interopRequireDefault(require("../../core/event-settings"));

var _styles = _interopRequireDefault(require("./styles"));

var Benefits = function Benefits() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var classes = (0, _styles.default)();
  var benefitsArray = (0, _reactRedux.useSelector)(_benefits.default.selectors.selectBenefitsList);
  var isLoading = (0, _reactRedux.useSelector)(_benefits.default.selectors.selectBenefitsIsLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_benefits.default.selectors.selectBenefitsIsLoaded);

  var _useSelector = (0, _reactRedux.useSelector)(_eventSettings.default.selectors.labelsSettingsSelector),
      benefitsTitle = _useSelector.Benefits;

  _react.default.useEffect(function () {
    dispatch(_benefits.default.actions.getBenefits());
  }, [dispatch]);

  return _react.default.createElement(_react.default.Fragment, null, isLoaded && benefitsArray.length > 0 && _react.default.createElement(_core.Box, {
    display: "flex",
    pb: 2
  }, _react.default.createElement(_core.Typography, {
    variant: "h2",
    className: classes.textUppercase
  }, benefitsTitle || _i18n.default.t('header.benefits'))), _react.default.createElement(_core.Box, {
    display: "flex",
    flexWrap: "wrap",
    className: classes.benefits
  }, benefitsArray.map(function (benefit) {
    return _react.default.createElement(_core.Box, {
      display: "flex",
      flexDirection: "column",
      className: classes.benefit,
      m: 3,
      key: benefit.id
    }, _react.default.createElement(_benefit.default, {
      benefit: benefit
    }));
  }), isLoading && !isLoaded && Array(4).fill('').map(function (_, index) {
    return _react.default.createElement(_core.Box, {
      key: index,
      m: 3
    }, _react.default.createElement(_reactContentLoader.default, {
      viewBox: "0 0 171 96",
      height: 96,
      width: 171,
      backgroundColor: "#e0e0e0"
    }, _react.default.createElement("rect", {
      x: "0",
      y: "0",
      rx: "0",
      ry: "0",
      width: "171",
      height: "96"
    })));
  })));
};

var _default = Benefits;
exports.default = _default;