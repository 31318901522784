var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLiveSessionState = useLiveSessionState;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _moment = _interopRequireDefault(require("moment"));

var _dateFormatter = require("../date-formatter");

var _useTimeout = _interopRequireDefault(require("./useTimeout"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function useLiveSessionState(stage) {
  var _React$useState = _react.default.useState(null),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      live = _React$useState2[0],
      setLive = _React$useState2[1];

  _react.default.useEffect(function () {
    setLive(calculateLiveState(stage));
  }, [setLive, stage]);

  var recalculate = _react.default.useCallback(function () {
    setLive(calculateLiveState(stage));
  }, [stage, setLive]);

  var timeout = _react.default.useMemo(function () {
    var now = new Date();

    if (!live) {
      return 6e4;
    }

    if (live.currentSession) {
      return new Date(live.currentSession.end) - now + 500;
    }

    if (live.nextSession) {
      return new Date(live.nextSession.start) - now + 500;
    }

    return 6e4;
  }, [live]);

  (0, _useTimeout.default)(recalculate, timeout);
  return live;
}

function calculateLiveState(stage) {
  if (!stage) return stage;
  var sessions = stage.sessions;
  var currentSession = sessions.find(function (session) {
    return (0, _dateFormatter.isNowInDateTimeRange)(session.start, session.end);
  });
  var now = (0, _moment.default)();
  var nextSession = sessions.sort(function (a, b) {
    return (0, _moment.default)(a.start) - (0, _moment.default)(b.start);
  }).find(function (session) {
    return (0, _moment.default)(session.start) >= now;
  });

  var live = _objectSpread(_objectSpread({}, stage), {}, {
    currentSession: currentSession,
    nextSession: nextSession
  });

  return live;
}