var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireWildcard(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _lodash = _interopRequireDefault(require("lodash"));

var _actions = require("../../../core/global/actions");

var _selectors = require("../../../core/networking/selectors");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _Dialog = _interopRequireDefault(require("../../../theme/components/dialog/Dialog"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _footer = _interopRequireDefault(require("../../common/footer"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _networkingTablePageStyles = _interopRequireDefault(require("./networking-table-page-styles"));

var _networkingTableView = _interopRequireDefault(require("./networking-table-view.web"));

var _selectors2 = require("../../../core/event-info/selectors");

var _actions2 = require("../../../core/user-rooms/actions");

var _userActivityConstants = require("../../../constants/user-activity-constants");

var _actions3 = require("../../../core/user/actions");

var _agoraConstants = require("../../../constants/agora-constants");

var _selectors3 = require("../../../core/user-roles/selectors");

var _featureNamesConstants = require("../../../constants/feature-names-constants");

var _styles = _interopRequireDefault(require("./styles"));

var _HandleBreakoutRoomStatus = _interopRequireDefault(require("../../common/HandleBreakoutRoomStatus"));

var _selectors4 = require("../../../core/user-rooms/selectors");

var NetworkingTablePageContainer = function NetworkingTablePageContainer() {
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });
  var tableId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.TABLE_ID);
  var event = (0, _reactRedux.useSelector)(_selectors2.selectEventInfo);
  var eventId = event.id;
  var table = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.tableByIdSelector)(state, tableId);
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  var navigation = (0, _useNavigationExtended.default)();
  var error = (0, _reactRedux.useSelector)(_selectors.networkingErrorSelector);
  var isApproved = (0, _reactRedux.useSelector)((0, _selectors4.isApprovedSelector)(tableId));
  var Styles = (0, _networkingTablePageStyles.default)();
  var classes = (0, _styles.default)();
  var hasUserAccessToTable = (0, _reactRedux.useSelector)((0, _selectors3.hasUserFeatureAccessSelector)(_featureNamesConstants.JOIN_RESTRICTED_NETWORKING_TABLE_BY_TABLE, tableId));
  var shouldRequestJoin = table.isRestricted && !hasUserAccessToTable && table.requestToJoin;
  (0, _react.useEffect)(function () {
    (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!shouldRequestJoin || isApproved) {
                dispatch((0, _actions2.joinRoom)({
                  roomId: tableId,
                  path: "events/".concat(eventId, "/networking-tables/").concat(tableId),
                  type: _agoraConstants.TYPES.NETWORKING,
                  event: _agoraConstants.JOIN_EVENT
                }));
              } else {
                dispatch((0, _actions2.requestToJoinRoom)({
                  roomId: tableId,
                  path: "events/".concat(eventId, "/networking-tables/").concat(tableId),
                  type: _agoraConstants.TYPES.NETWORKING,
                  event: _agoraConstants.REQUEST_TO_JOIN_ROOM_EVENT
                }));
              }

            case 1:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }))();
    return function () {
      dispatch((0, _actions2.leaveRoom)(tableId));
      dispatch((0, _actions2.cancelRequestToJoinRoom)(tableId));
    };
  }, [dispatch, tableId, eventId]);
  (0, _react.useEffect)(function () {
    (0, _actions3.trackUserActivity)({
      event: event,
      eventType: _userActivityConstants.TRACKABLE_EVENTS.NETWORKING_ROOM_ACCESSED,
      networking: {
        name: table === null || table === void 0 ? void 0 : table.name,
        id: tableId
      }
    });
  }, []);
  var leave = (0, _react.useCallback)(function () {
    return navigation.navigate(_routesConsts.Routes.NETWORKING_PAGE);
  }, [navigation]);
  (0, _react.useEffect)(function () {
    if (error) {
      var close = function close() {
        (0, _reactRedux.batch)(function () {
          dispatch((0, _actions.closeDialog)(error.message));
        });
        leave();
      };

      dispatch((0, _actions.openDialog)({
        id: error.message,
        content: _react.default.createElement(_Dialog.default, {
          onBackdropClick: close
        }, _react.default.createElement(_Spacing.default, {
          shaping: "cards",
          color: "pageBackground",
          direction: "column",
          style: Styles.dialog,
          alignItems: "stretch",
          mSpacing: ['m'],
          pSpacing: ['m', 'l']
        }, _react.default.createElement(_core.Typography, {
          variant: "h3"
        }, _i18n.default.translate("networking.".concat(_lodash.default.camelCase(error.message)))), _react.default.createElement(_Spacing.default, {
          mSpacing: ['m', 0],
          direction: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start"
        }, _react.default.createElement(_core.Typography, {
          className: classes.indicator
        }, _react.default.createElement(_reactFeather.AlertCircle, null)), _react.default.createElement(_core.Typography, {
          className: classes.description
        }, _i18n.default.translate("networking.error.".concat(_lodash.default.camelCase(error.message))))), _react.default.createElement(_Spacing.default, {
          direction: "row",
          alignItems: "center",
          justifyContent: "flex-end"
        }, _react.default.createElement(_ContainedButton.default, {
          onPress: function onPress() {
            return dispatch(close);
          }
        }, _i18n.default.translate('networking.ok'))))),
        index: 0
      }));
    }
  }, [error, dispatch, leave]);
  return _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%',
      flexGrow: 1
    }
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.container,
    direction: "row",
    alignItems: "stretch",
    wrap: "wrap",
    flex: 1
  }, _react.default.createElement(_HandleBreakoutRoomStatus.default, {
    roomId: tableId
  }, function (isBreakoutRoomsStarted) {
    return _react.default.createElement(_networkingTableView.default, {
      eventId: eventId,
      roomId: tableId,
      tableName: table === null || table === void 0 ? void 0 : table.name,
      event: event,
      isMainTable: table.mainRoom,
      isBreakoutRoomsStarted: isBreakoutRoomsStarted,
      isApproved: isApproved
    });
  })), _react.default.createElement(_footer.default, null));
};

var _default = NetworkingTablePageContainer;
exports.default = _default;