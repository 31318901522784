var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.VipBadgeInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _events = _interopRequireDefault(require("../../../core/events"));

var _festival = _interopRequireDefault(require("../../../core/festival"));

var _flags = _interopRequireDefault(require("../../../core/flags"));

var _user = _interopRequireDefault(require("../../../core/user"));

var _reactNativePaper = require("react-native-paper");

var _CustomIcon = _interopRequireDefault(require("../../../assets/icons/CustomIcon.js"));

var _reactNavigation = require("react-navigation");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var VipBadgeInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(VipBadgeInternal, _React$PureComponent);

  var _super = _createSuper(VipBadgeInternal);

  function VipBadgeInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, VipBadgeInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.handlePress = function () {
      if (!_this.props.hasAccess) {
        _this.props.navigation.navigate('VipPopup', {
          ticket: _this.props.ticket
        });
      }
    };

    return _this;
  }

  (0, _createClass2.default)(VipBadgeInternal, [{
    key: "render",
    value: function render() {
      return _react.default.createElement(_react.default.Fragment, null, !this.props.disablePaymentRegionFestivalFlag && _react.default.createElement(_reactNativePaper.TouchableRipple, {
        testID: 'badgeTouch',
        borderless: false,
        onPress: this.handlePress,
        style: [styles.touchContainer, this.props.style]
      }, _react.default.createElement(_reactNative.View, {
        style: [styles.vipContainer, this.props.style]
      }, _react.default.createElement(_reactNative.Text, {
        style: {
          color: this.props.festivalThemeColor,
          fontSize: 11,
          lineHeight: 15,
          paddingBottom: 8
        }
      }, 'VIP'), _react.default.createElement(_CustomIcon.default, {
        name: "vip-badge",
        size: 18,
        color: this.props.festivalThemeColor
      }))));
    }
  }]);
  return VipBadgeInternal;
}(_react.default.PureComponent);

exports.VipBadgeInternal = VipBadgeInternal;

var styles = _reactNative.StyleSheet.create({
  vipContainer: {
    alignSelf: 'flex-start',
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderRadius: 4,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 8,
    paddingVertical: 8
  },
  touchContainer: {
    alignSelf: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 8,
    position: 'absolute'
  }
});

function mapStateToProps(state, ownProps) {
  var eventId = ownProps.eventId;

  var festivalId = _events.default.selectors.getFestivalId(state, eventId);

  var ticket = _events.default.selectors.getAccessTicket(state, eventId);

  var ticketId = ticket.get('id');
  return {
    festivalThemeColor: _festival.default.selectors.getThemeColor(state, festivalId),
    disablePaymentRegionFestivalFlag: _flags.default.selectors.disablePaymentRegionFestivalFlag(state),
    ticket: ticket,
    hasAccess: _user.default.selectors.hasTicketAccess(state, ticketId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(VipBadgeInternal)));

exports.default = _default;