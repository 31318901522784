var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleError;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user-rooms/selectors");

var _toaster = _interopRequireDefault(require("../../common/toaster/toaster"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

function HandleError(_ref) {
  var roomId = _ref.roomId;
  var error = (0, _reactRedux.useSelector)((0, _selectors.roomErrorSelector)(roomId));

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      hasError = _useState2[0],
      setHasError = _useState2[1];

  (0, _react.useEffect)(function () {
    if (error) {
      setHasError(true);
    }
  }, [setHasError, error]);

  function handleClose() {
    setHasError(false);
  }

  return _react.default.createElement(_toaster.default, {
    visible: hasError,
    onClose: handleClose,
    title: _i18n.default.translate('backstage.error.somethingWentWrong'),
    text: _i18n.default.translate("backstage.error.".concat(error === null || error === void 0 ? void 0 : error.message)),
    duration: 6000,
    severity: "error"
  });
}