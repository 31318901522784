Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    card: {
      '& > div:first-of-type': {
        overflow: 'hidden',
        borderRadius: 10
      }
    },
    container: {
      '@media(max-width: 425px)': {
        justifyContent: 'center'
      }
    },
    lobbyCard: {
      '@media(max-width: 960px)': {
        margin: theme.spacing(1)
      }
    },
    innerContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > div:first-of-type': {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'black'
      }
    },
    videoContainer: {
      height: 0,
      width: '100%',
      paddingBottom: '56.25%',
      position: 'relative',
      borderRadius: 10,
      overflow: 'auto'
    }
  };
});
var _default = styles;
exports.default = _default;