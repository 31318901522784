var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sessionByNetworkingTableId = exports.isUserHostForSession = exports.selectSessionsLength = exports.selectLastSession = exports.selectFirstSessionWithSpeaker = exports.selectFirstSession = exports.selectSessionsWithSpeakers = exports.selectSessionById = exports.selectSessionsIsLoaded = exports.selectSessionsIsLoading = exports.selectSessionsList = exports.simpleSelectors = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _selectors = require("../speakers/selectors");

var _selectors2 = require("../user/selectors");

var _constants = require("./constants");

var _reducer = require("./reducer");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var selectSessionsState = function selectSessionsState(state) {
  return state[_constants.NAME];
};

var simpleSelectors = _reducer.sessionEntityAdapter.getSelectors(selectSessionsState);

exports.simpleSelectors = simpleSelectors;
var selectSessionsList = simpleSelectors.selectAll;
exports.selectSessionsList = selectSessionsList;
var selectSessionsIsLoading = (0, _toolkit.createSelector)(selectSessionsState, function (state) {
  return state.loading;
});
exports.selectSessionsIsLoading = selectSessionsIsLoading;
var selectSessionsIsLoaded = (0, _toolkit.createSelector)(selectSessionsState, function (state) {
  return state.loaded;
});
exports.selectSessionsIsLoaded = selectSessionsIsLoaded;

var selectSessionById = _reducer.sessionEntityAdapter.getSelectors(selectSessionsState).selectById;

exports.selectSessionById = selectSessionById;
var selectSessionsWithSpeakers = (0, _toolkit.createSelector)(selectSessionsList, _selectors.selectSpeakersList, function (sessions, speakers) {
  return sessions.map(function (session) {
    return _objectSpread(_objectSpread({}, session), {}, {
      speakers: speakers.filter(function (speaker) {
        return session.selectedSpeakers.map(function (selectedSpeaker) {
          return selectedSpeaker.id;
        }).includes(speaker.id);
      })
    });
  });
});
exports.selectSessionsWithSpeakers = selectSessionsWithSpeakers;
var selectFirstSession = (0, _toolkit.createSelector)(selectSessionsList, function (sessions) {
  return sessions.find(function () {
    return true;
  });
});
exports.selectFirstSession = selectFirstSession;
var selectFirstSessionWithSpeaker = (0, _toolkit.createSelector)(selectSessionsWithSpeakers, function (sessions) {
  return sessions.find(function () {
    return true;
  });
});
exports.selectFirstSessionWithSpeaker = selectFirstSessionWithSpeaker;
var selectLastSession = (0, _toolkit.createSelector)(selectSessionsList, function (sessions) {
  if (sessions && sessions.length > 0) return sessions[sessions.length - 1];
});
exports.selectLastSession = selectLastSession;
var selectSessionsLength = (0, _toolkit.createSelector)(selectSessionsList, function (state) {
  return state.length;
});
exports.selectSessionsLength = selectSessionsLength;

var isUserHostForSession = function isUserHostForSession(state, session) {
  var userId = (0, _selectors2.getFirebaseUserUid)(state);
  var speakers = (0, _selectors.selectSpeakersList)(state);

  if (!userId || !session || !speakers) {
    return;
  }

  var speaker = speakers.find(function (speaker) {
    return speaker.userId === userId;
  });

  if (!speaker) {
    return;
  }

  return !!session && !!(session === null || session === void 0 ? void 0 : session.hosts.find(function (host) {
    return host === speaker.id;
  }));
};

exports.isUserHostForSession = isUserHostForSession;

var sessionByNetworkingTableId = function sessionByNetworkingTableId(tableId) {
  return (0, _toolkit.createSelector)(selectSessionsList, function (sessions) {
    return sessions.find(function (session) {
      return session.networkTable === tableId;
    });
  });
};

exports.sessionByNetworkingTableId = sessionByNetworkingTableId;