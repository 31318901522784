var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LiveShareButtonReduxInternal = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _live = _interopRequireDefault(require("../../core/live"));

var _events = _interopRequireDefault(require("../../core/events"));

var _LiveShareButton = _interopRequireDefault(require("./LiveShareButton"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var LiveShareButtonReduxInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(LiveShareButtonReduxInternal, _React$PureComponent);

  var _super = _createSuper(LiveShareButtonReduxInternal);

  function LiveShareButtonReduxInternal() {
    (0, _classCallCheck2.default)(this, LiveShareButtonReduxInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(LiveShareButtonReduxInternal, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
          liveId = _this$props.liveId,
          djId = _this$props.djId,
          djName = _this$props.djName,
          djPicture = _this$props.djPicture,
          hashTags = _this$props.hashTags,
          restProps = (0, _objectWithoutProperties2.default)(_this$props, ["liveId", "djId", "djName", "djPicture", "hashTags"]);
      return _react.default.createElement(_LiveShareButton.default, (0, _extends2.default)({
        liveId: liveId,
        djId: djId,
        djName: djName,
        djPicture: djPicture,
        hashTags: hashTags
      }, restProps));
    }
  }]);
  return LiveShareButtonReduxInternal;
}(_react.default.PureComponent);

exports.LiveShareButtonReduxInternal = LiveShareButtonReduxInternal;

function mapStateToProps(state) {
  var liveId = _live.default.selectors.getLiveId(state);

  return {
    liveId: liveId,
    djId: _live.default.selectors.getDjId(state),
    djName: _live.default.selectors.getDjName(state),
    djPicture: _live.default.selectors.getCoverImage(state),
    hashTags: _events.default.selectors.getEventHashtags(state, liveId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(LiveShareButtonReduxInternal);

exports.default = _default;