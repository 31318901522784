var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useClientIsConnected = useClientIsConnected;
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

function useClientState(client) {
  var _React$useState = _react.default.useState('DISCONNECTED'),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      state = _React$useState2[0],
      setState = _React$useState2[1];

  var _React$useState3 = _react.default.useState('DISCONNECTED'),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      prevState = _React$useState4[0],
      setPrevState = _React$useState4[1];

  _react.default.useEffect(function () {
    if (client) {
      function onStateChanged(currentState, prevState) {
        setState(currentState);
        setPrevState(prevState);
      }

      client.on('connection-state-change', onStateChanged);
      return function () {
        client.off('connection-state-change', onStateChanged);
      };
    }
  }, [client, setState, setPrevState]);

  return [prevState, state];
}

function useClientIsConnected(client) {
  var _useClientState = useClientState(client),
      _useClientState2 = (0, _slicedToArray2.default)(_useClientState, 2),
      _ = _useClientState2[0],
      state = _useClientState2[1];

  var _React$useState5 = _react.default.useState(false),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      connected = _React$useState6[0],
      setConnected = _React$useState6[1];

  _react.default.useEffect(function () {
    if (state === 'CONNECTED') {
      setConnected(true);
    } else {
      setConnected(false);
    }
  }, [state, setConnected]);

  return connected;
}

var _default = useClientState;
exports.default = _default;