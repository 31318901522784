var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DeprecatedBannerInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _moment = _interopRequireDefault(require("moment"));

var _notifications = _interopRequireDefault(require("../../core/notifications"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var DeprecatedBannerInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(DeprecatedBannerInternal, _React$PureComponent);

  var _super = _createSuper(DeprecatedBannerInternal);

  function DeprecatedBannerInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, DeprecatedBannerInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.upgrade = function () {
      _reactNative.Linking.openURL(_this.props.upgradeUrl).catch(function () {});
    };

    _this.readMore = function () {
      _reactNative.Linking.openURL(_this.props.readMoreUrl).catch(function () {});
    };

    return _this;
  }

  (0, _createClass2.default)(DeprecatedBannerInternal, [{
    key: "render",
    value: function render() {
      var colors = this.props.theme.colors;

      if (!this.props.isDeprecated) {
        return null;
      }

      var action;
      var bannerText = 'This is an out-of-date app version that ' + (this.props.cutOffDate ? 'will become obsolete on ' + (0, _moment.default)(this.props.cutOffDate).format('MMMM Do YYYY') : 'sooner or later will become obsolete');

      if (this.props.hasNewerVersion) {
        bannerText += '. Update it to keep on having the best music experience.';
        action = {
          label: 'Upgrade',
          color: colors.accent,
          onPress: this.upgrade
        };
      } else {
        bannerText += '. Read about the options you have.';
        action = {
          label: 'Learn more',
          color: colors.accent,
          onPress: this.readMore
        };
      }

      return _react.default.createElement(_reactNativePaper.Banner, {
        style: {
          backgroundColor: colors.surface
        },
        visible: true,
        actions: [{
          label: 'Dismiss',
          color: colors.accent,
          onPress: this.props.snooze
        }, action]
      }, bannerText);
    }
  }]);
  return DeprecatedBannerInternal;
}(_react.default.PureComponent);

exports.DeprecatedBannerInternal = DeprecatedBannerInternal;

function mapStateToProps(state) {
  return {
    isDeprecated: _notifications.default.selectors.isAppDeprecated(state),
    hasNewerVersion: _notifications.default.selectors.hasNewerVersion(state),
    latestAvailableVersion: _notifications.default.selectors.getLatestAvailableVersion(state),
    upgradeUrl: _notifications.default.selectors.getLatestAvailableVersionUrl(state),
    readMoreUrl: _notifications.default.selectors.getLatestAvailableVersionReadMoreUrl(state),
    cutOffDate: _notifications.default.selectors.getAppCutOffDate(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  snooze: _notifications.default.actions.snoozeDeprecationWarning
})((0, _reactNativePaper.withTheme)(DeprecatedBannerInternal));

exports.default = _default;