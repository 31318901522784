var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _liveSessionStyles = _interopRequireDefault(require("../../../live/components/live-session-styles"));

var _DynamicSection = _interopRequireDefault(require("../../../../theme/components/section/DynamicSection"));

var _core = require("@material-ui/core");

var _colorUtils = _interopRequireDefault(require("../../../../utils/color-utils"));

var _DefaultGifBackground = _interopRequireDefault(require("../../../protected-pages/live-page/components/DefaultGifBackground"));

var _styles = _interopRequireDefault(require("./styles"));

var RefreshScreen = function RefreshScreen(_ref) {
  var clearErrors = _ref.clearErrors;
  var Styles = (0, _liveSessionStyles.default)();
  var classes = (0, _styles.default)();
  return _react.default.createElement(_DefaultGifBackground.default, null, _react.default.createElement(_reactNative.View, {
    style: Styles.absoluteContainer
  }, _react.default.createElement(_DynamicSection.default, {
    shaped: true,
    shaping: "cards",
    lightColor: "#FFFFFF",
    darkColor: "#000000",
    parentBackground: (0, _colorUtils.default)('#FFFFFF', '#FFFFFF', '#000000'),
    style: Styles.innerContainer
  }, function (innerTextColor) {
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Typography, {
      variant: "body1",
      align: "center",
      style: {
        color: innerTextColor
      },
      className: classes.textInfo
    }, _i18n.default.t('liveNow.prerecordedVideoUnavailable')), _react.default.createElement(_ContainedButton.default, {
      onPress: clearErrors
    }, _i18n.default.t('liveNow.refresh')));
  })));
};

var _default = RefreshScreen;
exports.default = _default;