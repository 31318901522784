var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _reactNativePaper = require("react-native-paper");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var TagButton = function (_React$PureComponent) {
  (0, _inherits2.default)(TagButton, _React$PureComponent);

  var _super = _createSuper(TagButton);

  function TagButton() {
    var _this;

    (0, _classCallCheck2.default)(this, TagButton);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      active: false
    };

    _this.onPress = function () {
      _this.setState({
        active: !_this.state.active
      });
    };

    _this.updateState = function (newState) {
      _this.setState({
        active: newState
      });
    };

    return _this;
  }

  (0, _createClass2.default)(TagButton, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate() {
      if (this.props.selectedFilters === false) {
        this.updateState(false);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      return React.createElement(_reactNative.View, null, this.props.tagItem && React.createElement(_reactNativePaper.TouchableRipple, {
        testID: 'HashTagButton' + this.props.tagItem.get('priority'),
        key: this.props.tagItem.get('priority'),
        onPress: function onPress() {
          _this2.props.onSelect(_this2.props.tagItem.get('name'));

          _this2.onPress();
        },
        borderless: false,
        style: {
          marginRight: 8
        }
      }, React.createElement(_reactNative.View, {
        style: [styles.hashtagButton, this.state.active && this.props.selectedFilters ? styles.hashtagButtonActive : null]
      }, React.createElement(_Paragraph.default, {
        style: [styles.hashtagSign, this.state.active && this.props.selectedFilters ? styles.hashtagSignActive : null]
      }, "#"), React.createElement(_Paragraph.default, {
        style: [styles.hashtagText, this.state.active && this.props.selectedFilters ? styles.hashtagTextActive : null]
      }, this.props.tagItem.get('name').toLowerCase()))));
    }
  }]);
  return TagButton;
}(React.PureComponent);

var styles = _reactNative.StyleSheet.create({
  hashtagButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.14)',
    borderRadius: 4,
    paddingHorizontal: 12,
    height: 36
  },
  hashtagSign: {
    marginVertical: 0,
    marginRight: 6,
    lineHeight: 15,
    height: 15,
    color: '#fff'
  },
  hashtagSignActive: {
    color: '#fff',
    opacity: 0.4
  },
  hashtagText: {
    marginVertical: 0,
    lineHeight: 15,
    height: 16
  },
  hashtagTextActive: {
    color: 'rgba(255,255,255,1)',
    opacity: 0.6
  },
  hashtagButtonActive: {
    backgroundColor: 'rgba(255,255,255,0.08)'
  }
});

var _default = TagButton;
exports.default = _default;