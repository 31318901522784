var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactContentLoader = _interopRequireDefault(require("react-content-loader"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _expo = _interopRequireDefault(require("../../core/expo"));

var _category = _interopRequireDefault(require("../../core/category"));

var _partners = _interopRequireDefault(require("../../core/partners"));

var _core = require("@material-ui/core");

var _expo2 = _interopRequireDefault(require("./expo"));

var _styles = _interopRequireDefault(require("./styles"));

var Expos = function Expos() {
  var groupBy = function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  var exposArray = groupBy((0, _reactRedux.useSelector)(_expo.default.selectors.selectExposList), 'selectedCategoryId');
  var categoriesArray = (0, _reactRedux.useSelector)(_category.default.selectors.selectCategoriesList);
  var sponsorsArray = (0, _reactRedux.useSelector)(_partners.default.selectors.selectPartnersList);
  var isLoading = (0, _reactRedux.useSelector)(_expo.default.selectors.selectExposLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_expo.default.selectors.selectExposLoaded);
  var isLoadingCategories = (0, _reactRedux.useSelector)(_category.default.selectors.selectCategoriesLoading);
  var isLoadedCategories = (0, _reactRedux.useSelector)(_category.default.selectors.selectCategoriesLoaded);
  var classes = (0, _styles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();

  _react.default.useEffect(function () {
    dispatch(_expo.default.actions.getExpos());
    dispatch(_category.default.actions.getCategories());
  }, [dispatch]);

  var groupedCategories = {};

  if (categoriesArray && categoriesArray.length > 0) {
    groupedCategories = categoriesArray.reduce(function (accumulator, current) {
      accumulator[current.id] = current.sort;
      return accumulator;
    }, {});
  }

  return _react.default.createElement(_react.default.Fragment, null, Object.entries(exposArray).sort(function (a, b) {
    var A = a[1][0];
    var B = b[1][0];

    if (A && B && A.selectedCategoryId && B.selectedCategoryId) {
      var catA = A.selectedCategoryId;
      var catB = B.selectedCategoryId;
      if (groupedCategories[catA] > groupedCategories[catB]) return 1;else if (groupedCategories[catA] < groupedCategories[catB]) return -1;else return 0;
    }

    return 0;
  }).map(function (_ref) {
    var _categoriesArray$find;

    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return _react.default.createElement(_reactNative.View, {
      key: key
    }, key !== 'null' && _react.default.createElement(_core.Box, {
      textAlign: "left",
      mb: 2
    }, _react.default.createElement(_core.Typography, {
      variant: "h2",
      className: classes.textUppercase
    }, (_categoriesArray$find = categoriesArray.find(function (s) {
      return s.id === key;
    })) === null || _categoriesArray$find === void 0 ? void 0 : _categoriesArray$find.name)), _react.default.createElement(_core.Box, {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      className: classes.container
    }, value.map(function (expo) {
      return _react.default.createElement(_expo2.default, {
        key: expo.id,
        expo: expo,
        category: categoriesArray.find(function (s) {
          return s.id === key;
        }),
        sponsor: sponsorsArray.find(function (s) {
          return s.id === expo.selectedSponsorId;
        })
      });
    }), isLoading && !isLoaded && isLoadingCategories && !isLoadedCategories && Array(4).fill('').map(function (_, index) {
      return _react.default.createElement(_reactContentLoader.default, {
        key: index,
        viewBox: "0 0 171 96",
        height: 96,
        width: 171,
        backgroundColor: "#e0e0e0"
      }, _react.default.createElement("rect", {
        x: "0",
        y: "0",
        rx: "0",
        ry: "0",
        width: "171",
        height: "96"
      }));
    })));
  }));
};

var _default = Expos;
exports.default = _default;