var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OutlinedButton;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../DesignSystem");

var _Spacing = _interopRequireDefault(require("../spacing/Spacing"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function OutlinedButton(_ref) {
  var iconLeft = _ref.iconLeft,
      iconRight = _ref.iconRight,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      _ref$elevation = _ref.elevation,
      elevation = _ref$elevation === void 0 ? 0 : _ref$elevation,
      children = _ref.children,
      onPress = _ref.onPress,
      _ref$badge = _ref.badge,
      badge = _ref$badge === void 0 ? null : _ref$badge,
      _ref$badgePosition = _ref.badgePosition,
      badgePosition = _ref$badgePosition === void 0 ? 'topRight' : _ref$badgePosition,
      style = _ref.style,
      _ref$spacing = _ref.spacing,
      spacing = _ref$spacing === void 0 ? 's' : _ref$spacing,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'button' : _ref$color,
      _ref$backgroundColor = _ref.backgroundColor,
      backgroundColor = _ref$backgroundColor === void 0 ? 'none' : _ref$backgroundColor;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      shape = _useTheme.shape,
      shadows = _useTheme.shadows;

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      hover = _React$useState2[0],
      setHover = _React$useState2[1];

  var badgeStyle = {};

  switch (badgePosition) {
    case 'topRight':
      badgeStyle = {
        top: -10,
        right: -10
      };
      break;
  }

  return _react.default.createElement(_reactNative.TouchableHighlight, (0, _extends2.default)({
    disabled: disabled
  }, _reactNative.Platform.select({
    web: {
      onMouseOver: function onMouseOver() {
        return setHover(true);
      },
      onMouseOut: function onMouseOut() {
        return setHover(false);
      }
    }
  }), {
    onPress: onPress,
    underlayColor: "".concat(colors[color] || color, "44"),
    style: [_objectSpread(_objectSpread({}, shape.buttons), {}, {
      boxShadow: shadows[elevation],
      alignItems: 'center',
      justifyContent: 'center',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: colors[color] || color
    }), disabled && {
      opacity: 0.3
    }, style]
  }), _react.default.createElement(_reactNative.View, {
    style: {
      position: 'relative'
    }
  }, badge && _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    justifyContent: "center",
    color: "button",
    style: [{
      borderRadius: '50%',
      height: 25,
      width: 25,
      position: 'absolute'
    }, badgeStyle]
  }, badge), _react.default.createElement(_Spacing.default, {
    direction: "row",
    wrap: "nowrap",
    pSpacing: [spacing, children ? 'm' : spacing, spacing, children ? 'm' : spacing],
    alignItems: "center",
    justifyContent: "center"
  }, iconLeft && _react.default.createElement(_Spacing.default, {
    self: "middle",
    alignItems: "center",
    justifyContent: "center",
    pSpacing: [0, children ? 's' : 0, 0, 0]
  }, _react.default.createElement(_reactNative.View, {
    color: color
  }, iconLeft)), children && _react.default.createElement(_reactNative.View, {
    color: color
  }, children) || null, iconRight && _react.default.createElement(_Spacing.default, {
    self: "middle",
    alignItems: "center",
    justifyContent: "center",
    pSpacing: [0, 0, 0, children ? 's' : 0]
  }, _react.default.createElement(_reactNative.View, {
    color: color
  }, iconRight)))));
}