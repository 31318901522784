var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _core = require("@material-ui/core");

var _clsx = _interopRequireDefault(require("clsx"));

var _react = _interopRequireDefault(require("react"));

var _styles = _interopRequireDefault(require("./styles"));

var HeaderLink = function HeaderLink(_ref) {
  var children = _ref.children,
      isActive = _ref.isActive,
      props = (0, _objectWithoutProperties2.default)(_ref, ["children", "isActive"]);
  var classes = (0, _styles.default)();
  return _react.default.createElement(_core.Button, (0, _extends2.default)({
    className: classes.button
  }, props), _react.default.createElement(_core.Box, {
    className: (0, _clsx.default)(classes.buttonLabel, isActive && classes.active)
  }, _react.default.createElement(_core.Typography, {
    variant: "body1",
    color: "inherit",
    className: classes.header
  }, children)));
};

var _default = HeaderLink;
exports.default = _default;