var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FestivalLiveNowScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _stages = _interopRequireDefault(require("../../../../core/stages"));

var _StageContainer = _interopRequireDefault(require("./StageContainer"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var FestivalLiveNowScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(FestivalLiveNowScreenInternal, _React$PureComponent);

  var _super = _createSuper(FestivalLiveNowScreenInternal);

  function FestivalLiveNowScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, FestivalLiveNowScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      screenWidth: 0,
      stages: _this.props.orderedStages
    };

    _this.updateStages = function (prevProp) {
      var orderedStages = _this.props.orderedStages;

      if (orderedStages && prevProp !== orderedStages) {
        _this.setState({
          stages: orderedStages
        });
      }
    };

    return _this;
  }

  (0, _createClass2.default)(FestivalLiveNowScreenInternal, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      this.updateStages(prevProps.orderedStages);
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      return React.createElement(React.Fragment, null, this.state.stages && this.state.stages.map(function (el) {
        return React.createElement(_StageContainer.default, {
          key: el.get('id'),
          stageId: el.get('id'),
          stageName: el.get('name'),
          stagePriority: el.get('priority'),
          stageType: el.get('type'),
          festivalId: _this2.props.festivalId
        });
      }));
    }
  }]);
  return FestivalLiveNowScreenInternal;
}(React.PureComponent);

exports.FestivalLiveNowScreenInternal = FestivalLiveNowScreenInternal;

function mapStateToProps(state, ownProps) {
  var id = ownProps.festivalId;
  return {
    orderedStages: _stages.default.selectors.getOrderedStages(state, id)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(FestivalLiveNowScreenInternal);

exports.default = _default;