var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AudioVideoDeviceSelector;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _DeviceSelector = _interopRequireDefault(require("./DeviceSelector"));

var _reactFeather = require("react-feather");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _reactNative = require("react-native");

var _DeviceSettings = _interopRequireDefault(require("./DeviceSettings"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _agoraUtils = require("../../../utils/agora-utils");

var _Dialog = _interopRequireDefault(require("@material-ui/core/Dialog"));

var _LocalVideo = _interopRequireDefault(require("./LocalVideo"));

var _useAgoraAudioVideoDevice = require("../../../utils/hooks/useAgoraAudioVideoDevice");

var _useSafeState = _interopRequireDefault(require("../../../utils/hooks/useSafeState"));

var _agoraConstants = require("../../../constants/agora-constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function AudioVideoDeviceSelector(_ref) {
  var open = _ref.open,
      _ref$onClose = _ref.onClose,
      onClose = _ref$onClose === void 0 ? function () {} : _ref$onClose;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      videoTrack = _useAudioVideoContext.videoTrack,
      audioTrack = _useAudioVideoContext.audioTrack,
      sinkId = _useAudioVideoContext.sinkId,
      setAudioOutput = _useAudioVideoContext.setAudioOutput,
      videoEnabled = _useAudioVideoContext.videoEnabled,
      audioEnabled = _useAudioVideoContext.audioEnabled,
      enableVideo = _useAudioVideoContext.enableVideo,
      disableVideo = _useAudioVideoContext.disableVideo,
      enableAudio = _useAudioVideoContext.enableAudio,
      disableAudio = _useAudioVideoContext.disableAudio;

  var devices = (0, _useAgoraAudioVideoDevice.useAgoraDevices)();
  var videoDeviceId = (0, _agoraUtils.getDeviceIdFromTrack)(devices, videoTrack, 'video');
  var audioDeviceId = (0, _agoraUtils.getDeviceIdFromTrack)(devices, audioTrack, 'audio');
  var setChange = (0, _useSafeState.default)(_react.default.useState(''))[1];
  return _react.default.createElement(_Dialog.default, {
    open: open,
    onClose: onClose,
    scroll: "body",
    keepMounted: false
  }, _react.default.createElement(_DeviceSettings.default, {
    onClose: onClose
  }, function (_ref2) {
    var videoInputDevices = _ref2.videoInputDevices,
        audioInputDevices = _ref2.audioInputDevices,
        audioOutputDevices = _ref2.audioOutputDevices;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
      shaping: "cards",
      color: "#000",
      style: {
        height: '0',
        paddingBottom: '56.25%',
        position: 'relative',
        overflow: 'hidden'
      }
    }, _react.default.createElement(_LocalVideo.default, {
      absoluteFill: true,
      height: '100%',
      width: '100%'
    }), !videoEnabled && _react.default.createElement(_Spacing.default, {
      alignItems: "center",
      justifyContent: "center",
      style: _reactNative.StyleSheet.absoluteFillObject
    }, _react.default.createElement(_reactFeather.VideoOff, {
      color: "#FFF"
    })), !videoTrack && _react.default.createElement(_reactNative.ActivityIndicator, {
      style: _objectSpread({}, _reactNative.StyleSheet.absoluteFillObject)
    })), _react.default.createElement(_Spacing.default, {
      mSpacing: ['s', 0]
    }), _react.default.createElement(_DeviceSelector.default, {
      hasOff: true,
      disabled: !videoEnabled,
      devices: videoInputDevices,
      value: videoEnabled ? videoDeviceId ? videoDeviceId : '' : '',
      onChange: function () {
        var _ref3 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(deviceId) {
          return _regenerator.default.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  if (deviceId) {
                    _context.next = 5;
                    break;
                  }

                  _context.next = 3;
                  return disableVideo();

                case 3:
                  _context.next = 11;
                  break;

                case 5:
                  if (videoEnabled) {
                    _context.next = 8;
                    break;
                  }

                  _context.next = 8;
                  return enableVideo();

                case 8:
                  _context.next = 10;
                  return videoTrack.setDevice(deviceId);

                case 10:
                  localStorage.setItem(_agoraConstants.DEFAULT_VIDEO_INPUT_DEVICE, deviceId);

                case 11:
                  setChange(new Date());

                case 12:
                case "end":
                  return _context.stop();
              }
            }
          }, _callee);
        }));

        return function (_x) {
          return _ref3.apply(this, arguments);
        };
      }()
    }, _react.default.createElement(_reactFeather.Video, null)), _react.default.createElement(_Spacing.default, {
      mSpacing: ['s', 0]
    }), _react.default.createElement(_DeviceSelector.default, {
      hasOff: true,
      disabled: !audioEnabled,
      devices: audioInputDevices,
      value: audioEnabled ? audioDeviceId ? audioDeviceId : '' : '',
      onChange: function () {
        var _ref4 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(deviceId) {
          return _regenerator.default.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  if (deviceId) {
                    _context2.next = 5;
                    break;
                  }

                  _context2.next = 3;
                  return disableAudio();

                case 3:
                  _context2.next = 11;
                  break;

                case 5:
                  if (audioEnabled) {
                    _context2.next = 8;
                    break;
                  }

                  _context2.next = 8;
                  return enableAudio();

                case 8:
                  _context2.next = 10;
                  return audioTrack.setDevice(deviceId);

                case 10:
                  localStorage.setItem(_agoraConstants.DEFAULT_AUDIO_INPUT_DEVICE, deviceId);

                case 11:
                  setChange(new Date());

                case 12:
                case "end":
                  return _context2.stop();
              }
            }
          }, _callee2);
        }));

        return function (_x2) {
          return _ref4.apply(this, arguments);
        };
      }()
    }, _react.default.createElement(_reactFeather.Mic, null)), _react.default.createElement(_Spacing.default, {
      mSpacing: ['s', 0]
    }), _react.default.createElement(_DeviceSelector.default, {
      devices: audioOutputDevices,
      value: sinkId || '',
      onChange: function onChange(deviceId) {
        localStorage.setItem(_agoraConstants.DEFAULT_AUDIO_OUTPUT_DEVICE, deviceId);
        setAudioOutput(deviceId);
      }
    }, _react.default.createElement(_reactFeather.Headphones, null)), _react.default.createElement(_Spacing.default, {
      mSpacing: ['s', 0]
    }), _react.default.createElement(_ContainedButton.default, {
      onPress: onClose
    }, _i18n.default.translate('networking.done')));
  }));
}