var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OptimizeLocalVideo;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = require("react");

var _AudioVideoProvider = require("./AudioVideoProvider");

var _useDownlinkNetworkQuality = _interopRequireDefault(require("../../../utils/hooks/useDownlinkNetworkQuality"));

var _logger = _interopRequireDefault(require("../../../core/services/logger"));

var _agoraVideoProfiles = require("../../../utils/agoraVideoProfiles");

var SET_CONFIG_DELAY = 10 * 1000;

function OptimizeLocalVideo() {
  var timeoutId = (0, _react.useRef)(null);

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      videoTrack = _useAudioVideoContext.videoTrack;

  var quality = (0, _useDownlinkNetworkQuality.default)();
  (0, _react.useEffect)(function () {
    if (videoTrack) {
      clearTimeout(timeoutId.current);

      var setConfig = function () {
        var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
          var config;
          return _regenerator.default.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  config = _agoraVideoProfiles.VIDEO_ENCODER_CONFIGURATIONS[quality];
                  _context.next = 3;
                  return videoTrack.setEncoderConfiguration(config.values);

                case 3:
                  _logger.default.debug("Local video quality change: ".concat(config.name));

                case 4:
                case "end":
                  return _context.stop();
              }
            }
          }, _callee);
        }));

        return function setConfig() {
          return _ref.apply(this, arguments);
        };
      }();

      timeoutId.current = setTimeout(setConfig, SET_CONFIG_DELAY);
    }
  }, [videoTrack, quality]);
  (0, _react.useEffect)(function () {
    return function () {
      clearTimeout(timeoutId.current);
    };
  }, []);
  return null;
}