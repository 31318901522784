var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AddToCalendarButton;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _core = require("@material-ui/core");

var _DesignSystem = require("../../DesignSystem");

var _Spacing = _interopRequireDefault(require("../spacing/Spacing"));

var _clsx = _interopRequireDefault(require("clsx"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _reactFeather = require("react-feather");

var _styles = _interopRequireDefault(require("./styles"));

var useStyles = (0, _core.makeStyles)(function () {
  return {
    iconButton: {
      boxShadow: 'none',
      backgroundColor: '#f6f6f6 !important',
      borderRadius: function borderRadius(_ref) {
        var _borderRadius = _ref.borderRadius;
        return "".concat(_borderRadius, "px !important");
      },
      '&:hover': {
        backgroundColor: '#dcdcdc !important'
      },
      padding: '10px !important',
      '& .addeventatc_dropdown': {
        top: '-200px  !important',
        left: 'auto !important',
        right: '-20px !important',
        '@media(max-width: 600px)': {
          left: '50% !important',
          transform: 'translateX(-50%) !important'
        }
      },
      '& svg': {
        pointerEvents: 'none'
      }
    },
    textButton: {
      boxShadow: 'none',
      backgroundColor: function backgroundColor(_ref2) {
        var _backgroundColor = _ref2.backgroundColor;
        return "".concat(_backgroundColor, " !important");
      },
      borderRadius: function borderRadius(_ref3) {
        var _borderRadius2 = _ref3.borderRadius;
        return "".concat(_borderRadius2, "px !important");
      },
      '&:hover': {
        backgroundColor: function backgroundColor(_ref4) {
          var hoverBackground = _ref4.hoverBackground;
          return "".concat(hoverBackground, " !important");
        }
      },
      padding: '10px !important',
      '& .addeventatc_dropdown': {
        top: '-150px  !important',
        left: '10px !important'
      }
    }
  };
});

function AddToCalendarButton(_ref5) {
  var start = _ref5.start,
      end = _ref5.end,
      timezone = _ref5.timezone,
      title = _ref5.title,
      description = _ref5.description,
      location = _ref5.location,
      _ref5$iconButton = _ref5.iconButton,
      iconButton = _ref5$iconButton === void 0 ? false : _ref5$iconButton;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      shape = _useTheme.shape;

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      hover = _React$useState2[0],
      setHover = _React$useState2[1];

  var Styles = useStyles({
    backgroundColor: colors.button,
    borderRadius: shape.buttons.borderRadius,
    hoverBackground: colors.buttonHover
  });
  var classes = (0, _styles.default)();
  (0, _react.useEffect)(function () {
    if (window.addeventatc) window.addeventatc.refresh();
  }, []);
  return _react.default.createElement("div", {
    className: (0, _clsx.default)('addeventatc', iconButton ? Styles.iconButton : Styles.textButton)
  }, _react.default.createElement(_reactNative.View, {
    style: {
      display: 'flex'
    }
  }, _react.default.createElement(_Spacing.default, null, _react.default.createElement(_reactFeather.Calendar, {
    size: "1.5rem",
    color: iconButton ? colors.pageText : colors.containerBackground
  }), !iconButton && _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 0, 'm'],
    style: {
      flex: 1
    }
  }, _react.default.createElement(_core.Typography, {
    className: classes.textWhite,
    variant: "body1"
  }, _i18n.default.t('lobby.addToCalendar'))), _react.default.createElement("span", {
    className: "client"
  }, process.env.ADD_EVENT_CLIENT_ID), _react.default.createElement("span", {
    className: "start"
  }, start), _react.default.createElement("span", {
    className: "end"
  }, end), _react.default.createElement("span", {
    className: "timezone"
  }, timezone), _react.default.createElement("span", {
    className: "title"
  }, title), _react.default.createElement("span", {
    className: "description"
  }, description), _react.default.createElement("span", {
    className: "location"
  }, location))));
}