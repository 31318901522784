var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.TipTheDjAnimationInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _appraisal = _interopRequireDefault(require("../../core/appraisal"));

var _AvatarImage = _interopRequireDefault(require("../common/avatar/AvatarImage"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var TipTheDjAnimationInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(TipTheDjAnimationInternal, _React$PureComponent);

  var _super = _createSuper(TipTheDjAnimationInternal);

  function TipTheDjAnimationInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, TipTheDjAnimationInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      animatedIntroValue: new _reactNative.Animated.Value(0)
    };
    return _this;
  }

  (0, _createClass2.default)(TipTheDjAnimationInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (!this.props.tip) {
        return null;
      }

      _reactNative.Animated.stagger(3000, [_reactNative.Animated.timing(this.state.animatedIntroValue, {
        toValue: 1,
        duration: 500,
        easing: _reactNative.Easing.elastic(2)
      }), _reactNative.Animated.timing(this.state.animatedIntroValue, {
        toValue: 0,
        duration: 500,
        easing: _reactNative.Easing.linear
      })]).start(this.props.done);
    }
  }, {
    key: "render",
    value: function render() {
      if (!this.props.tip) {
        return null;
      }

      var name = 'Somebody';
      var nameInitials = 'X';
      var avatar = this.props.tip.get('avt');

      if (this.props.tip.get('fnm')) {
        name = this.props.tip.get('fnm').split(' ')[0];
        nameInitials = this.props.tip.get('fnm').split(' ', 2).map(function (i) {
          return i.charAt(0);
        });
      }

      var introAnimation = this.state.animatedIntroValue.interpolate({
        inputRange: [0, 1],
        outputRange: [-300, 10]
      });
      return _react.default.createElement(_reactNative.Animated.View, {
        style: [styles.tipTheDJ, {
          transform: [{
            translateX: introAnimation
          }]
        }]
      }, _react.default.createElement(_reactNativePaper.Surface, {
        style: styles.tipTheDJSurface
      }, this.props.tip.get('avt') || this.props.tip.get('fnm') ? _react.default.createElement(_AvatarImage.default, {
        size: 54,
        source: {
          uri: avatar
        },
        defaultLabel: nameInitials,
        style: styles.tipAvatar
      }) : _react.default.createElement(_reactNative.View, {
        style: styles.userIncognito
      }, _react.default.createElement(_CustomIcon.default, {
        name: "mask",
        size: 32,
        color: "white"
      })), _react.default.createElement(_reactNative.View, null, _react.default.createElement(_reactNativePaper.Subheading, {
        style: styles.name
      }, name), _react.default.createElement(_reactNativePaper.Paragraph, {
        style: styles.tipText
      }, "Tipped the DJ")), _react.default.createElement(_reactNative.View, {
        style: styles.tipCoins
      }, _react.default.createElement(_CustomIcon.default, {
        name: "coins-outline",
        size: 32,
        color: "black"
      }))));
    }
  }]);
  return TipTheDjAnimationInternal;
}(_react.default.PureComponent);

exports.TipTheDjAnimationInternal = TipTheDjAnimationInternal;

var styles = _reactNative.StyleSheet.create({
  tipTheDJ: {
    position: 'absolute',
    bottom: 64
  },
  tipTheDJSurface: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 50,
    backgroundColor: 'white',
    paddingHorizontal: 5,
    paddingVertical: 5,
    elevation: 4
  },
  userIncognito: {
    backgroundColor: '#111',
    borderRadius: 50,
    width: 54,
    height: 54,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10
  },
  tipAvatar: {
    alignItems: 'center',
    marginRight: 10
  },
  name: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: 0
  },
  tipText: {
    color: 'black',
    marginTop: 0
  },
  tipCoins: {
    paddingHorizontal: 20
  }
});

function mapStateToProps(state) {
  var tip = _appraisal.default.selectors.getCurrentTip(state);

  return {
    key: tip ? [tip.get('fnm'), tip.get('iat'), tip.get('avt')].join('_') : undefined,
    tip: tip
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  done: _appraisal.default.actions.consumeTip
})(TipTheDjAnimationInternal);

exports.default = _default;