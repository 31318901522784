Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textUppercase: {
      textTransform: 'uppercase'
    },
    container: {
      display: 'flex',
      margin: -theme.spacing(3),
      '@media(max-width: 600px)': {
        flexDirection: 'column',
        justifyContent: 'center'
      }
    },
    noCategoryMargin: {
      marginVertical: -theme.spacing(2.5),
      marginLeft: -theme.spacing(3)
    },
    header: {
      paddingBottom: theme.spacing(2)
    }
  };
});
var _default = styles;
exports.default = _default;