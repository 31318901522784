var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ReportOrBlockModal;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _blockView = _interopRequireDefault(require("./block-view"));

var _reportOrBlockStyles = _interopRequireDefault(require("./report-or-block-styles"));

var _reportView = _interopRequireDefault(require("./report-view"));

function ReportOrBlockModal(props) {
  var reportMessage = props.reportMessage,
      blockUser = props.blockUser,
      userId = props.userId,
      fullName = props.fullName,
      message = props.message,
      avatar = props.avatar,
      messageId = props.messageId;
  var navigation = (0, _useNavigationExtended.default)();

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      showBlockModal = _useState2[0],
      setShowBlockModal = _useState2[1];

  var handlePress = (0, _react.useCallback)(function () {
    reportMessage(userId, fullName, message, messageId);
    navigation.goBack();
  }, [navigation, reportMessage, userId, fullName, message, messageId]);
  var handleBlockUser = (0, _react.useCallback)(function () {
    setShowBlockModal(!showBlockModal);
  }, [showBlockModal]);
  var handleBlockConfirm = (0, _react.useCallback)(function () {
    setShowBlockModal(!showBlockModal);
    blockUser({
      id: userId,
      displayName: fullName,
      image: {
        contentUrl: avatar
      }
    });
    navigation.goBack();
  }, [showBlockModal, blockUser, userId, fullName, avatar, navigation]);
  return _react.default.createElement(_reactNative.View, {
    style: _reportOrBlockStyles.default.container
  }, _react.default.createElement(_reactNative.View, {
    style: _reportOrBlockStyles.default.surface
  }, showBlockModal ? _react.default.createElement(_blockView.default, (0, _extends2.default)({}, props, {
    testID: 'BlockView',
    onPressBlockConfirm: handleBlockConfirm
  })) : _react.default.createElement(_reportView.default, (0, _extends2.default)({}, props, {
    testID: 'ReportView',
    onPressReport: handlePress,
    onPressBlock: handleBlockUser
  }))));
}