var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPES = exports.REQUEST_TO_JOIN_ROOM_EVENT = exports.WATCH_EVENT = exports.JOIN_EVENT = exports.UNSUBSCRIBE_ALL_EVENT = exports.UNSUBSCRIBE_EVENT = exports.SUBSCRIBE_EVENT = exports.subscriptionObserver = exports.SCREEN_SHARE = exports.ASPECT_RATIO = exports.DEFAULT_AUDIO_OUTPUT_DEVICE = exports.DEFAULT_VIDEO_INPUT_DEVICE = exports.DEFAULT_AUDIO_INPUT_DEVICE = exports.NO_SCREEN_SHARE_PARTICIPANT = exports.REMAINED_PARTICIPANTS = exports.GHOST_PARTICIPANT = exports.SCREEN_SHARE_PARTICIPANT = void 0;

var _logger = _interopRequireDefault(require("../core/services/logger"));

var _createObserverUtils = _interopRequireDefault(require("../utils/createObserver-utils"));

var SCREEN_SHARE_PARTICIPANT = 'SCREEN_SHARE_PARTICIPANT';
exports.SCREEN_SHARE_PARTICIPANT = SCREEN_SHARE_PARTICIPANT;
var GHOST_PARTICIPANT = 'GHOST_PARTICIPANT';
exports.GHOST_PARTICIPANT = GHOST_PARTICIPANT;
var REMAINED_PARTICIPANTS = 'REMAINED_PARTICIPANTS';
exports.REMAINED_PARTICIPANTS = REMAINED_PARTICIPANTS;
var NO_SCREEN_SHARE_PARTICIPANT = 'NO_SCREEN_SHARE_PARTICIPANT';
exports.NO_SCREEN_SHARE_PARTICIPANT = NO_SCREEN_SHARE_PARTICIPANT;
var DEFAULT_AUDIO_INPUT_DEVICE = 'DEFAULT_AUDIO_INPUT_DEVICE';
exports.DEFAULT_AUDIO_INPUT_DEVICE = DEFAULT_AUDIO_INPUT_DEVICE;
var DEFAULT_VIDEO_INPUT_DEVICE = 'DEFAULT_VIDEO_INPUT_DEVICE';
exports.DEFAULT_VIDEO_INPUT_DEVICE = DEFAULT_VIDEO_INPUT_DEVICE;
var DEFAULT_AUDIO_OUTPUT_DEVICE = 'DEFAULT_AUDIO_OUTPUT_DEVICE';
exports.DEFAULT_AUDIO_OUTPUT_DEVICE = DEFAULT_AUDIO_OUTPUT_DEVICE;
var ASPECT_RATIO = 16 / 9;
exports.ASPECT_RATIO = ASPECT_RATIO;
var SCREEN_SHARE = {
  VISIBLE_PARTICIPANTS: 8,
  MAIN_PORTION: 7 / 8,
  PARTICIPANTS_PORTION: 1 / 8
};
exports.SCREEN_SHARE = SCREEN_SHARE;
var subscriptionObserver = (0, _createObserverUtils.default)(_logger.default.debug);
exports.subscriptionObserver = subscriptionObserver;
var SUBSCRIBE_EVENT = 'SUBSCRIBE_EVENT';
exports.SUBSCRIBE_EVENT = SUBSCRIBE_EVENT;
var UNSUBSCRIBE_EVENT = 'UNSUBSCRIBE_EVENT';
exports.UNSUBSCRIBE_EVENT = UNSUBSCRIBE_EVENT;
var UNSUBSCRIBE_ALL_EVENT = 'UNSUBSCRIBE_ALL_EVENT';
exports.UNSUBSCRIBE_ALL_EVENT = UNSUBSCRIBE_ALL_EVENT;
var JOIN_EVENT = 'joinRoom';
exports.JOIN_EVENT = JOIN_EVENT;
var WATCH_EVENT = 'watchRoom';
exports.WATCH_EVENT = WATCH_EVENT;
var REQUEST_TO_JOIN_ROOM_EVENT = 'requestToJoinRoom';
exports.REQUEST_TO_JOIN_ROOM_EVENT = REQUEST_TO_JOIN_ROOM_EVENT;
var TYPES = {
  BACKSTAGE: 'BACKSTAGE',
  NETWORKING: 'NETWORKING'
};
exports.TYPES = TYPES;