var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EnableNotificationsRequestInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _Subheading = _interopRequireDefault(require("../common/typography/Subheading"));

var _Paragraph = _interopRequireDefault(require("../common/typography/Paragraph"));

var _Button = _interopRequireDefault(require("../common/Button"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _activity = _interopRequireDefault(require("../../core/activity"));

var _Analytics = require("../../core/services/Analytics");

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var EnableNotificationsRequestInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(EnableNotificationsRequestInternal, _React$PureComponent);

  var _super = _createSuper(EnableNotificationsRequestInternal);

  function EnableNotificationsRequestInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, EnableNotificationsRequestInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      inProgress: false
    };

    _this.handlePress = function () {
      _this.setState({
        inProgress: true
      });

      _reactNativeFirebase.default.messaging().requestPermission().then(function () {
        _this.props.hasSeenPushNotificationSuggestion();

        (0, _Analytics.trackEvent)('enable_notifications_approved');
      }, function () {
        _this.props.hasSeenPushNotificationSuggestion();

        (0, _Analytics.trackEvent)('enable_notifications_dismiss');
      });
    };

    return _this;
  }

  (0, _createClass2.default)(EnableNotificationsRequestInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _reactNativeFirebase.default.messaging().hasPermission().then(function (enabled) {
        if (enabled) {
          _this2.props.hasSeenPushNotificationSuggestion();

          (0, _Analytics.trackEvent)('enable_notifications_approved');
        }
      });

      (0, _Analytics.trackEvent)('enable_notifications_open');
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      (0, _Analytics.trackEvent)('enable_notifications_closed');
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      return React.createElement(_reactNative.View, {
        style: styles.container
      }, React.createElement(_reactNativeSafeAreaView.default, {
        style: styles.container,
        forceInset: {
          top: 'always',
          bottom: 'always'
        }
      }, React.createElement(_reactNative.View, {
        style: styles.skipButton
      }, React.createElement(_reactNativePaper.TouchableRipple, {
        borderless: true,
        style: {
          paddingHorizontal: 8
        },
        onPress: function onPress() {
          _this3.props.hasSeenPushNotificationSuggestion();

          (0, _Analytics.trackEvent)('enable_notifications_dismiss');
        }
      }, React.createElement(_Paragraph.default, null, "Skip"))), React.createElement(_reactNative.View, {
        style: styles.illustrationContainer
      }, React.createElement(_reactNative.Image, {
        source: require('../../assets/images/notifIllustration.png')
      })), React.createElement(_reactNative.View, {
        style: styles.contentContainer
      }, React.createElement(_reactNative.View, {
        style: styles.notifTextContainer
      }, React.createElement(_Subheading.default, {
        style: {
          textAlign: 'center'
        }
      }, "Don't miss any live sessions"), React.createElement(_Paragraph.default, {
        style: {
          textAlign: 'center'
        }
      }, "Enable notifications so you don't miss amazing live events from new rising stars.")), React.createElement(_Button.default, {
        mode: "contained",
        buttonSize: "large",
        onPress: this.handlePress,
        style: {
          width: '100%',
          maxWidth: 400,
          marginBottom: 16
        },
        disabled: this.state.inProgress
      }, "Enable notifications"))));
    }
  }]);
  return EnableNotificationsRequestInternal;
}(React.PureComponent);

exports.EnableNotificationsRequestInternal = EnableNotificationsRequestInternal;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#000'
  },
  illustrationContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 16
  },
  notifTextContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 32,
    maxWidth: 560,
    backgroundColor: '#000'
  },
  skipButton: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginRight: 16
  }
});

var _default = (0, _reactRedux.connect)(null, {
  hasSeenPushNotificationSuggestion: _activity.default.actions.hasSeenPushNotificationSuggestion
})(EnableNotificationsRequestInternal);

exports.default = _default;