var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FeaturedEventsFeedInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNavigation = require("react-navigation");

var _events = _interopRequireDefault(require("../../core/events"));

var _hashtags = _interopRequireDefault(require("../../core/hashtags"));

var _EventHashtags = _interopRequireDefault(require("./EventHashtags"));

var _Title = _interopRequireDefault(require("../common/typography_v1/Title"));

var _eventCard = _interopRequireDefault(require("../common/event/event-card"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var FeaturedEventsFeedInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(FeaturedEventsFeedInternal, _React$PureComponent);

  var _super = _createSuper(FeaturedEventsFeedInternal);

  function FeaturedEventsFeedInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, FeaturedEventsFeedInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      filters: [],
      filterList: _this.props.hashtags,
      eventHashtagsLoaded: false,
      screenWidth: _reactNative.Dimensions.get('window').width
    };

    _this.handleContainerLayout = function (event) {
      _this.setState({
        screenWidth: event.nativeEvent.layout.width
      });
    };

    _this.updateState = function () {
      _this.setState({
        filterList: _this.props.hashtags
      });
    };

    _this.handlePress = function (tag) {
      var list = _this.state.filters;
      var newList;
      list.indexOf(tag) > -1 ? list.splice(list.indexOf(tag), 1) : list.push(tag);

      _this.setState({
        filters: list
      });

      list.length === 0 ? newList = _this.props.hashtags : newList = _this.props.hashtags.filter(function (elem) {
        return list.includes(elem.get('name'));
      });
      var x = 0;
      newList.toArray().map(function (el) {
        return _this.props.events.map(function (ev) {
          return ev.get('hashTags').toArray().includes(el.get('name')) ? x++ : x;
        });
      });

      if (x === 0) {
        _this.setState({
          filterList: _this.props.hashtags,
          filters: []
        });

        _this.props.navigation.navigate('NoEventsPopup');
      } else {
        _this.setState({
          filterList: newList
        });
      }
    };

    return _this;
  }

  (0, _createClass2.default)(FeaturedEventsFeedInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      this.props.loadScheduledEvents().then(function () {
        if (!_this2.props.onHasLoaded() && _this2.state.eventHashtagsLoaded) {
          _this2.props.onHasLoaded();
        }
      });
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var _this3 = this;

      if (prevProps.hashtags !== this.props.hashtags) {
        this.updateState();
      }

      if (this.props.reloading && this.props.reloading !== prevProps.reloading) {
        this.props.loadScheduledEvents().then(function () {
          if (!_this3.props.onHasLoaded()) {
            _this3.props.onHasLoaded();
          }
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this4 = this;

      var cardWidth = this.state.screenWidth / 2 - 24;
      return React.createElement(React.Fragment, null, this.props.events.length > 0 && React.createElement(_reactNative.View, {
        style: styles.sectionContainer,
        onLayout: this.handleContainerLayout
      }, React.createElement(_Title.default, {
        style: styles.sectionTitle
      }, "Featured Shows"), React.createElement(_reactNative.View, {
        testID: "FestivalFeed"
      }, this.props.hasHashTags && React.createElement(_EventHashtags.default, {
        testID: 'EventHashTagsContainer',
        onPress: this.handlePress,
        selectedFilters: this.state.filters.length > 0
      }), React.createElement(_reactNative.View, {
        style: styles.masonryColsContainer
      }, this.props.hasHashTags ? this.state.filterList.toArray().map(function (el) {
        var x = 0;
        return React.createElement(React.Fragment, null, React.createElement(_reactNative.View, {
          testID: 'HashTagView',
          key: el.get('name'),
          style: [styles.masonryColumn]
        }, _this4.props.events.map(function (event, index) {
          return event.get('hashTags').toArray().includes(el.get('name')) && (x = x + 1) && index % 2 === 0 && React.createElement(_eventCard.default, {
            key: event.get('id'),
            eventId: event.get('id'),
            eventItem: event,
            tag: el.get('name'),
            x: x,
            index: index,
            isReplay: true,
            style: [styles.eventCardContainer, styles.eventCardOdd, {
              width: cardWidth
            }]
          });
        })), React.createElement(_reactNative.View, {
          key: el.get('name'),
          style: styles.masonryColumn
        }, _this4.props.events.map(function (event, index) {
          return event.get('hashTags').toArray().includes(el.get('name')) && (x = x + 1) && index % 2 === 1 && React.createElement(_eventCard.default, {
            key: event.get('id'),
            eventId: event.get('id'),
            eventItem: event,
            tag: el.get('name'),
            x: x,
            index: index,
            isReplay: true,
            style: [styles.eventCardContainer, styles.eventCardOdd, {
              width: cardWidth
            }]
          });
        })));
      }) : React.createElement(React.Fragment, null, React.createElement(_reactNative.View, {
        style: styles.masonryColumn
      }, this.props.events.map(function (el, index) {
        return index % 2 === 0 && React.createElement(_eventCard.default, {
          key: el.get('id'),
          eventId: el.get('id'),
          eventItem: el,
          index: index,
          isReplay: true,
          style: [styles.eventCardContainer, styles.eventCardOdd, {
            width: cardWidth
          }]
        });
      })), React.createElement(_reactNative.View, {
        style: styles.masonryColumn
      }, this.props.events.map(function (el, index) {
        return index % 2 === 1 && React.createElement(_eventCard.default, {
          key: el.get('id'),
          eventId: el.get('id'),
          eventItem: el,
          index: index,
          isReplay: true,
          style: [styles.eventCardContainer, styles.eventCardEven, {
            width: cardWidth
          }]
        });
      })))))));
    }
  }]);
  return FeaturedEventsFeedInternal;
}(React.PureComponent);

exports.FeaturedEventsFeedInternal = FeaturedEventsFeedInternal;

var styles = _reactNative.StyleSheet.create({
  sectionContainer: {
    marginVertical: 20
  },
  sectionTitle: {
    lineHeight: 17,
    color: 'white',
    marginBottom: 16,
    paddingHorizontal: 16
  },
  masonryColsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 16
  },
  masonryColumn: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start'
  },
  eventCardContainer: {
    marginBottom: 16,
    marginRight: 16
  },
  eventCardOdd: {
    aspectRatio: 0.66
  },
  eventCardEven: {
    aspectRatio: 0.6
  }
});

function mapStateToProps(state) {
  return {
    events: _events.default.selectors.getEvents(state),
    hashtags: _hashtags.default.selectors.getAllHashTags(state),
    hasHashTags: _hashtags.default.selectors.hasHashTagsAvailable(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadScheduledEvents: _events.default.actions.loadScheduledEvents
})((0, _reactNavigation.withNavigation)(FeaturedEventsFeedInternal));

exports.default = _default;