Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    container: {
      marginLeft: 90,
      '@media(max-width: 1026px)': {
        marginLeft: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    },
    innerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      '@media(max-width: 1026px)': {
        justifyContent: 'center'
      }
    }
  };
});
var _default = styles;
exports.default = _default;