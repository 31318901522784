var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ThemeProvider;

var _react = _interopRequireDefault(require("react"));

var _DesignSystem = require("./DesignSystem");

var _core = require("@material-ui/core");

function ThemeProvider(_ref) {
  var children = _ref.children;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      _useTheme$colors = _useTheme.colors,
      button = _useTheme$colors.button,
      link = _useTheme$colors.link,
      borderColor = _useTheme$colors.borderColor,
      alternativeBackground = _useTheme$colors.alternativeBackground,
      pageBackground = _useTheme$colors.pageBackground,
      buttonText = _useTheme$colors.buttonText,
      errorColor = _useTheme$colors.errorColor,
      alternativeText = _useTheme$colors.alternativeText,
      containerBackground = _useTheme$colors.containerBackground,
      pageText = _useTheme$colors.pageText,
      headerText = _useTheme$colors.headerText,
      topMenuBackground = _useTheme$colors.topMenuBackground,
      linkHover = _useTheme$colors.linkHover,
      approveBarColor = _useTheme$colors.approveBarColor,
      badgeUpcoming = _useTheme$colors.badgeUpcoming,
      successColor = _useTheme$colors.successColor,
      fontFamily = _useTheme.typography.fontFamily;

  var muiTheme = (0, _core.createMuiTheme)({
    overrides: {
      MuiPaper: {
        rounded: {
          borderRadius: 10
        }
      }
    },
    props: {
      MuiTypography: {
        component: 'span',
        align: 'left'
      }
    },
    palette: {
      primary: {
        main: button
      },
      secondary: {
        main: link
      },
      borderColor: {
        main: borderColor
      },
      alternativeBackground: {
        main: alternativeBackground
      },
      alternativeText: {
        main: alternativeText
      },
      pageBackground: {
        main: pageBackground
      },
      pageText: {
        main: pageText
      },
      containerBackground: {
        main: containerBackground
      },
      buttonText: {
        main: buttonText
      },
      error: {
        main: errorColor
      },
      headerText: {
        main: headerText
      },
      topMenuBackground: {
        main: topMenuBackground
      },
      linkHover: {
        main: linkHover
      },
      button: {
        main: button
      },
      approveBarColor: {
        main: approveBarColor
      },
      badgeUpcoming: {
        main: badgeUpcoming
      },
      successColor: {
        main: successColor
      }
    },
    typography: {
      fontSize: 16,
      allVariants: {
        lineHeight: 1.5,
        letterSpacing: '0em'
      },
      h1: {
        fontFamily: fontFamily,
        fontWeight: 'bold',
        fontSize: '2rem'
      },
      h2: {
        fontFamily: fontFamily,
        fontWeight: 'bold',
        fontSize: '1.5rem'
      },
      h3: {
        fontFamily: fontFamily,
        fontWeight: 'bold',
        fontSize: '1.25rem'
      },
      h4: {
        fontFamily: fontFamily,
        fontWeight: 'bold',
        fontSize: '1.125rem',
        lineHeight: '1.56'
      },
      body1: {
        fontFamily: fontFamily,
        fontSize: '1rem'
      },
      caption: {
        fontFamily: fontFamily,
        fontWeight: '400',
        fontSize: '0.875rem',
        lineHeight: '1.43'
      },
      overline: {
        fontFamily: fontFamily,
        fontWeight: '400',
        fontSize: '0.75rem'
      },
      body2: {
        fontFamily: fontFamily,
        fontSize: '1.125rem',
        lineHeight: '1.56'
      },
      largeText: {
        fontFamily: fontFamily,
        fontSize: '1.5rem'
      },
      fontFamily: fontFamily
    }
  });

  var theme = _react.default.useMemo(function () {
    return muiTheme;
  }, [button, link, fontFamily, borderColor, alternativeBackground, alternativeText, buttonText, errorColor, pageBackground, containerBackground, pageText]);

  return _react.default.createElement(_core.ThemeProvider, {
    theme: theme
  }, children);
}