var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var ColoredCircularProgress = (0, _core.withStyles)({
  root: {
    color: function color(_ref) {
      var customColor = _ref.customColor;
      return customColor;
    },
    '&:hover': {
      color: function color(_ref2) {
        var customColor = _ref2.customColor;
        return "".concat(customColor, "88 !important");
      }
    }
  }
})(function (_ref3) {
  var customColor = _ref3.customColor,
      props = (0, _objectWithoutProperties2.default)(_ref3, ["customColor"]);
  return _react.default.createElement(_core.CircularProgress, props);
});
var _default = ColoredCircularProgress;
exports.default = _default;