var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ContactInformationSection;

var _react = _interopRequireDefault(require("react"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _core = require("@material-ui/core");

var _formik = require("formik");

var _contactInformationStepUtils = require("./contact-information-step-utils");

var _yupUtils = require("../../../../utils/yup-utils");

var _contactInformationStepStyle = _interopRequireDefault(require("./contact-information-step-style"));

var _eventRegisterProvider = require("../../event-register-provider");

function ContactInformationSection() {
  var classes = (0, _contactInformationStepStyle.default)();

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      eventId = _useEventRegisterCont.eventId,
      registrationId = _useEventRegisterCont.registrationId,
      passwordLessRegistration = _useEventRegisterCont.passwordLessRegistration;

  var _useFormikContext = (0, _formik.useFormikContext)(),
      values = _useFormikContext.values,
      handleChange = _useFormikContext.handleChange,
      setFieldTouched = _useFormikContext.setFieldTouched,
      handleBlur = _useFormikContext.handleBlur,
      errors = _useFormikContext.errors,
      touched = _useFormikContext.touched,
      submitForm = _useFormikContext.submitForm,
      setFieldValue = _useFormikContext.setFieldValue,
      setFieldError = _useFormikContext.setFieldError;

  var registrationForm = passwordLessRegistration ? _contactInformationStepUtils.PASSWORDLESS_REGISTRATION_FORM : _contactInformationStepUtils.REGISTRATION_FORM;
  return _react.default.createElement(_core.Box, {
    className: classes.userInfo
  }, registrationForm.map(function (_ref) {
    var id = _ref.id,
        label = _ref.label,
        IconLeft = _ref.iconLeft,
        type = _ref.type,
        placeholder = _ref.placeholder,
        customFields = _ref.customFields;
    return _react.default.createElement(_core.Box, {
      key: id,
      className: "".concat(classes.inputContainer, " ").concat(classes.inputWidth)
    }, _react.default.createElement(_core.Box, {
      className: classes.labelContainer
    }, type !== _yupUtils.INPUT_TYPES.CHECKBOX && _react.default.createElement(_core.Typography, {
      variant: "body1"
    }, _i18n.default.translate(label, {
      defaultValue: label
    }))), (0, _contactInformationStepUtils._renderInput)({
      id: id,
      label: label,
      IconLeft: IconLeft,
      type: type,
      placeholder: placeholder,
      error: errors[id],
      touched: touched[id],
      submitForm: submitForm,
      setFieldTouched: setFieldTouched,
      setFieldValue: setFieldValue,
      handleChange: handleChange,
      value: values[id],
      customFields: customFields,
      handleBlur: handleBlur,
      setFieldError: setFieldError,
      eventId: eventId,
      registrationId: registrationId
    }), !!errors[id] && touched[id] && _react.default.createElement(_core.Box, {
      className: classes.errorContainer
    }, _react.default.createElement(_core.Typography, {
      variant: "caption",
      className: classes.errorColor
    }, _i18n.default.translate(errors[id], {
      defaultValue: errors[id]
    }))));
  }));
}