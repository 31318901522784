var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.UsersListScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _AvatarImage = _interopRequireDefault(require("../common/avatar/AvatarImage"));

var _reactNativePaper = require("react-native-paper");

var _FollowUserButton = _interopRequireDefault(require("../common/follow/FollowUserButton"));

var _reactNavigation = require("react-navigation");

var _Paragraph = _interopRequireDefault(require("../common/typography/Paragraph"));

var _common = require("../common");

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var UsersListScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(UsersListScreenInternal, _React$PureComponent);

  var _super = _createSuper(UsersListScreenInternal);

  function UsersListScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, UsersListScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      hasLoaded: false
    };
    _this.pendingLoadUsers = void 0;
    return _this;
  }

  (0, _createClass2.default)(UsersListScreenInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      this.pendingLoadUsers = (0, _common.makeCancelable)(this.props.load(this.props.userId));
      this.pendingLoadUsers.promise.then(function () {
        _this2.setState({
          hasLoaded: true
        });
      });
      this.props.navigation.setParams({
        title: this.props.title
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.pendingLoadUsers) {
        this.pendingLoadUsers.cancel();
      }
    }
  }, {
    key: "_renderItem",
    value: function _renderItem(item) {
      var _this3 = this;

      var id = item.get('id');
      var displayName = item.get('displayName');
      var nameInitials = item.get('displayName').split(' ', 2).map(function (i) {
        return i.charAt(0);
      });
      var avatar = item.getIn(['image', 'contentUrl']);
      return _react.default.createElement(_reactNative.View, {
        style: styles.userContainer,
        key: item.get('id')
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: 'avatarTouch',
        borderless: true,
        onPress: function onPress() {
          return _this3.props.navigation.push('Profile', {
            userId: id,
            djName: displayName,
            avatar: avatar
          });
        }
      }, _react.default.createElement(_AvatarImage.default, {
        size: 48,
        source: {
          uri: avatar
        },
        defaultLabel: nameInitials,
        style: styles.userAvatar
      })), _react.default.createElement(_reactNative.View, {
        style: styles.userName
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        borderless: false,
        name: 'displayNameTouch',
        onPress: function onPress() {
          return _this3.props.navigation.push('Profile', {
            userId: id,
            djName: displayName,
            avatar: avatar
          });
        }
      }, _react.default.createElement(_Paragraph.default, {
        color: "#ffffff",
        style: {
          fontWeight: 'bold'
        },
        numberOfLines: 1
      }, displayName))), _react.default.createElement(_FollowUserButton.default, {
        id: id
      }));
    }
  }, {
    key: "render",
    value: function render() {
      var _this4 = this;

      if (this.props.users === undefined) {
        return _react.default.createElement(_reactNative.View, {
          style: styles.loading
        }, _react.default.createElement(_reactNative.ActivityIndicator, {
          size: "large",
          color: "#fff"
        }));
      }

      if (this.state.hasLoaded === false && this.props.users.size === 0) {
        return _react.default.createElement(_reactNative.View, {
          style: styles.loading
        }, _react.default.createElement(_reactNative.ActivityIndicator, {
          size: "large",
          color: "#fff"
        }));
      }

      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.StatusBar, {
        barStyle: "light-content",
        hidden: false,
        backgroundColor: "#000",
        translucent: false
      }), _react.default.createElement(_reactNative.ScrollView, {
        alwaysBounceVertical: true,
        directionalLockEnabled: true,
        pinchGestureEnabled: false,
        contentContainerStyle: {
          paddingHorizontal: 16,
          paddingBottom: 24
        },
        style: {
          backgroundColor: 'black'
        }
      }, this.props.users.toArray().map(function (el) {
        return _this4._renderItem(el);
      })), this.state.hasLoaded === false && _react.default.createElement(_reactNative.View, {
        style: styles.loading
      }, _react.default.createElement(_reactNative.ActivityIndicator, {
        size: "small",
        color: "#fff"
      })));
    }
  }]);
  return UsersListScreenInternal;
}(_react.default.PureComponent);

exports.UsersListScreenInternal = UsersListScreenInternal;

UsersListScreenInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  var state = navigation.state;
  return {
    title: state.params.title,
    headerTintColor: '#fff',
    headerTitleStyle: {
      flex: 1,
      textAlign: 'center',
      alignSelf: 'center',
      fontSize: 13
    },
    headerStyle: {
      backgroundColor: '#000',
      borderBottomColor: '#000'
    },
    headerLeft: _react.default.createElement(_reactNativePaper.TouchableRipple, {
      borderless: true,
      style: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        top: 0
      },
      onPress: function onPress() {
        return navigation.goBack();
      }
    }, _react.default.createElement(_CustomIcon.default, {
      name: "chevron-left",
      size: 16,
      color: "#ffffff"
    })),
    headerRight: _react.default.createElement(_reactNative.View, null)
  };
};

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 16
  },
  userContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginVertical: 12
  },
  userAvatar: {
    marginRight: 8
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000'
  },
  userName: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingRight: 16,
    overflow: 'hidden'
  }
});

var _default = (0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(UsersListScreenInternal));

exports.default = _default;