var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EventRegistrationStepper;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _eventRegisterProvider = require("../../event-register-provider");

var _clsx = _interopRequireDefault(require("clsx"));

var useStyle = (0, _core.makeStyles)(function (theme) {
  var _theme$palette, _theme$palette$pageBa, _indexContainer;

  return {
    stepsContainer: {
      gridArea: 'stepper',
      boxSizing: 'border-box',
      width: '100%',
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-between'
    },
    step: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      opacity: 0.4
    },
    activeStep: {
      opacity: 1
    },
    titleContainer: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 16
    },
    indexContainer: (_indexContainer = {
      display: 'flex',
      width: 48,
      height: 48,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: (_theme$palette = theme.palette) === null || _theme$palette === void 0 ? void 0 : (_theme$palette$pageBa = _theme$palette.pageBackground) === null || _theme$palette$pageBa === void 0 ? void 0 : _theme$palette$pageBa.main
    }, (0, _defineProperty2.default)(_indexContainer, theme.breakpoints.down('sm'), {
      width: 32,
      height: 32
    }), (0, _defineProperty2.default)(_indexContainer, theme.breakpoints.down('xs'), {
      width: 24,
      height: 24
    }), _indexContainer),
    responsiveFont: (0, _defineProperty2.default)({}, theme.breakpoints.down('sm'), {
      fontSize: '1rem'
    })
  };
});

function EventRegistrationStepper(_ref) {
  var fullScreen = _ref.fullScreen;
  var classes = useStyle();

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      steps = _useEventRegisterCont.steps,
      activeStep = _useEventRegisterCont.activeStep,
      getEnabledSteps = _useEventRegisterCont.getEnabledSteps;

  var enabledSteps = getEnabledSteps();

  var showStepTitle = function showStepTitle(index) {
    return !fullScreen || activeStep === index;
  };

  return _react.default.createElement(_core.Box, {
    className: classes.stepsContainer
  }, steps.map(function (step, index) {
    var correctedIndex = enabledSteps.findIndex(function (filteredStep) {
      return filteredStep.id === step.id;
    });
    return (step === null || step === void 0 ? void 0 : step.isEnabled) ? _react.default.createElement(_core.Box, {
      className: (0, _clsx.default)([index === activeStep ? classes.activeStep : '', classes.step]),
      key: index
    }, _react.default.createElement(_core.Box, {
      className: classes.indexContainer
    }, _react.default.createElement(_core.Typography, {
      className: classes.responsiveFont,
      variant: "h2"
    }, correctedIndex + 1)), showStepTitle(index) ? _react.default.createElement(_core.Box, {
      className: classes.titleContainer
    }, _react.default.createElement(_core.Typography, {
      className: classes.responsiveFont,
      variant: "h2"
    }, _i18n.default.translate(step.title))) : null) : null;
  }));
}