Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseIframeUrl = exports.isAnEmailAddress = void 0;

var isAnEmailAddress = function isAnEmailAddress(text) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(text).toLowerCase());
};

exports.isAnEmailAddress = isAnEmailAddress;

var parseIframeUrl = function parseIframeUrl(toReplace, url) {
  toReplace.forEach(function (_ref) {
    var parameter = _ref.parameter,
        value = _ref.value;
    url = url.replace("{{".concat(parameter, "}}"), value);
  });
  return url;
};

exports.parseIframeUrl = parseIframeUrl;