var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FollowBaseButtonInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _color = _interopRequireDefault(require("color"));

var _reactNativePaper = require("react-native-paper");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _reactNavigation = require("react-navigation");

var _Button = _interopRequireDefault(require("../Button"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var FollowBaseButtonInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(FollowBaseButtonInternal, _React$PureComponent);

  var _super = _createSuper(FollowBaseButtonInternal);

  function FollowBaseButtonInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, FollowBaseButtonInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.handlePress = function () {
      if (!_this.props.isFollowing) {
        _reactNativeFirebase.default.messaging().requestPermission().then(function () {
          _this.props.follow(_this.props.id);

          if (_this.props.isFromFestival && !_this.props.isFollowingFestival) {
            _this.props.followFestival(parseInt(_this.props.festivalId, 10));
          }
        }, function () {
          return _this.props.navigation.navigate('PermissionsInstructions');
        });
      } else {
        _this.props.unfollow(_this.props.id);
      }
    };

    return _this;
  }

  (0, _createClass2.default)(FollowBaseButtonInternal, [{
    key: "render",
    value: function render() {
      var colors = this.props.theme.colors;
      var isFollowingBgColor = (0, _color.default)(colors.white).alpha(0.2).rgb().string();
      return _react.default.createElement(_Button.default, {
        testID: 'FollowButton',
        mode: !this.props.isFollowing ? 'outlined' : 'contained',
        dark: this.props.isFollowing ? true : false,
        color: this.props.isFollowing ? isFollowingBgColor : this.props.color,
        onPress: this.handlePress,
        disabled: this.props.id === undefined ? true : this.props.inProgress,
        uppercase: this.props.uppercase,
        buttonSize: this.props.buttonSize,
        isFixedButton: this.props.isFixedButton,
        style: this.props.style
      }, this.props.label ? this.props.label : !this.props.isFollowing ? 'Follow' : 'Following');
    }
  }]);
  return FollowBaseButtonInternal;
}(_react.default.PureComponent);

exports.FollowBaseButtonInternal = FollowBaseButtonInternal;

var _default = (0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(FollowBaseButtonInternal));

exports.default = _default;