Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    container: {
      '@media(max-width: 1280px)': {
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    avatarContainer: {
      '@media(max-width: 1280px)': {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: theme.spacing(3)
      }
    }
  };
});
var _default = styles;
exports.default = _default;