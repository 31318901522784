var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectQuestionsList = exports.selectAllQuestions = exports.simpleSelectors = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var _selectors = require("../user/selectors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var selectQAndAState = function selectQAndAState(state) {
  return state[_constants.NAME];
};

var simpleSelectors = _reducer.qAndAEntityAdapter.getSelectors(selectQAndAState);

exports.simpleSelectors = simpleSelectors;
var selectAllQuestions = simpleSelectors.selectAll;
exports.selectAllQuestions = selectAllQuestions;
var selectQuestionsList = (0, _toolkit.createSelector)(selectAllQuestions, _selectors.getFirebaseUserUid, function (questions, userId) {
  return questions.sort(function (a, b) {
    var _b$createdAt, _a$createdAt;

    var aCount = a.likedBy.length - a.dislikedBy.length;
    var bCount = b.likedBy.length - b.dislikedBy.length;
    return b.isPinned - a.isPinned || bCount - aCount || ((_b$createdAt = b.createdAt) === null || _b$createdAt === void 0 ? void 0 : _b$createdAt.seconds) - ((_a$createdAt = a.createdAt) === null || _a$createdAt === void 0 ? void 0 : _a$createdAt.seconds);
  }).map(function (question) {
    var liked = question.likedBy.includes(userId);
    var disliked = question.dislikedBy.includes(userId);
    var reactions = question.likedBy.length - question.dislikedBy.length;
    var reorderedAnswers = question.answers.slice().sort(function (a, b) {
      return b.isPinned - a.isPinned;
    });
    return _objectSpread(_objectSpread({}, question), {}, {
      answers: reorderedAnswers,
      liked: liked,
      disliked: disliked,
      reactions: reactions
    });
  });
});
exports.selectQuestionsList = selectQuestionsList;