var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectSelectedStage = exports.selectLiveNowSession = exports.selectEventStageWithSessionsById = exports.selectEventStagesWithSessions = exports.selectEventStageById = exports.selectEventStagesLoaded = exports.selectEventStagesLoading = exports.selectEventStagesList = exports.selectEventStagesState = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _moment = _interopRequireDefault(require("moment"));

var _toolkit = require("@reduxjs/toolkit");

var _reducer = require("./reducer");

var _constants = require("./constants");

var _selectors = require("../session/selectors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var selectEventStagesState = function selectEventStagesState(state) {
  return state[_constants.NAME];
};

exports.selectEventStagesState = selectEventStagesState;

var selectEventStagesList = _reducer.eventStagesEntityAdapter.getSelectors(selectEventStagesState).selectAll;

exports.selectEventStagesList = selectEventStagesList;
var selectEventStagesLoading = (0, _toolkit.createSelector)(selectEventStagesState, function (state) {
  return state.loading;
});
exports.selectEventStagesLoading = selectEventStagesLoading;
var selectEventStagesLoaded = (0, _toolkit.createSelector)(selectEventStagesState, function (state) {
  return state.loaded;
});
exports.selectEventStagesLoaded = selectEventStagesLoaded;

var selectEventStageById = _reducer.eventStagesEntityAdapter.getSelectors(selectEventStagesState).selectById;

exports.selectEventStageById = selectEventStageById;
var selectEventStagesWithSessions = (0, _toolkit.createSelector)(selectEventStagesList, _selectors.selectSessionsWithSpeakers, function (stages, sessions) {
  return stages.map(function (stage) {
    return _objectSpread(_objectSpread({}, stage), {}, {
      sessions: sessions.filter(function (session) {
        return session.resourceId === stage.id;
      })
    });
  });
});
exports.selectEventStagesWithSessions = selectEventStagesWithSessions;
var selectEventStageWithSessionsById = (0, _toolkit.createSelector)(selectEventStageById, _selectors.selectSessionsWithSpeakers, function (stage, sessions) {
  return stage ? _objectSpread(_objectSpread({}, stage), {}, {
    sessions: sessions.filter(function (session) {
      return session.resourceId === stage.id;
    })
  }) : null;
});
exports.selectEventStageWithSessionsById = selectEventStageWithSessionsById;
var selectLiveNowSession = (0, _toolkit.createSelector)(selectEventStageWithSessionsById, function (stage) {
  if (!(stage === null || stage === void 0 ? void 0 : stage.sessions)) {
    return;
  }

  var now = new Date();
  return stage.sessions.sort(function (a, b) {
    return (0, _moment.default)(a.start) - (0, _moment.default)(b.start);
  }).find(function (session) {
    return (0, _moment.default)(session.end) > now;
  });
});
exports.selectLiveNowSession = selectLiveNowSession;
var selectSelectedStage = (0, _toolkit.createSelector)(selectEventStagesState, function (state) {
  return state.selectedStage;
});
exports.selectSelectedStage = selectSelectedStage;