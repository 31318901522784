var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativeImageCacheHoc = _interopRequireDefault(require("react-native-image-cache-hoc"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var CacheableImage = (0, _reactNativeImageCacheHoc.default)(_reactNative.Image, {
  fileDirName: 'eventmix-live-cached-images',
  cachePruneTriggerLimit: 1024 * 1024 * 100,
  defaultPlaceholder: {
    component: _reactNative.ActivityIndicator,
    props: {
      color: '#fff',
      size: 'small',
      style: {
        flex: 1,
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.1)'
      }
    }
  }
});

var CustomCachedImage = function (_React$PureComponent) {
  (0, _inherits2.default)(CustomCachedImage, _React$PureComponent);

  var _super = _createSuper(CustomCachedImage);

  function CustomCachedImage() {
    var _this;

    (0, _classCallCheck2.default)(this, CustomCachedImage);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this._root = void 0;

    _this.uriHasValidProtocol = function () {
      var _this$props$source = _this.props.source,
          source = _this$props$source === void 0 ? {} : _this$props$source;
      var uriProtocol = '';

      if (source.uri !== undefined && source.uri !== null) {
        uriProtocol = source.uri.substring(0, 5);
      }

      var validUri = uriProtocol === 'https';
      return validUri;
    };

    return _this;
  }

  (0, _createClass2.default)(CustomCachedImage, [{
    key: "setNativeProps",
    value: function setNativeProps(nativeProps) {
      if (this._root && _reactNative.Platform.OS === 'web') {
        this._root.setNativeProps(nativeProps);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return this.uriHasValidProtocol() ? React.createElement(CacheableImage, this.props) : React.createElement(_reactNative.Image, this.props);
    }
  }]);
  return CustomCachedImage;
}(React.PureComponent);

exports.default = CustomCachedImage;