var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactContentLoader = _interopRequireDefault(require("react-content-loader"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _reactNativeGestureHandler = require("react-native-gesture-handler");

var _reactNativePaperV = require("react-native-paper-v3");

var _reactRedux = require("react-redux");

var _testimonials = _interopRequireDefault(require("../../core/testimonials"));

var _core = require("@material-ui/core");

var _testimonial = _interopRequireDefault(require("./testimonial"));

var _testimonialsStyles = _interopRequireDefault(require("./testimonials-styles"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _eventSettings = _interopRequireDefault(require("../../core/event-settings"));

var _styles = _interopRequireDefault(require("./styles"));

var Testimonials = function Testimonials() {
  var Styles = (0, _testimonialsStyles.default)();
  var classes = (0, _styles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var testimonialArray = (0, _reactRedux.useSelector)(_testimonials.default.selectors.selectTestimonialsList);
  var isLoading = (0, _reactRedux.useSelector)(_testimonials.default.selectors.selectTestimonialsIsLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_testimonials.default.selectors.selectTestimonialsIsLoaded);

  var _useSelector = (0, _reactRedux.useSelector)(_eventSettings.default.selectors.labelsSettingsSelector),
      testimonialsTitle = _useSelector.Testimonials;

  var _React$useState = _react.default.useState(0),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      xPosition = _React$useState2[0],
      setXPosition = _React$useState2[1];

  var _React$useState3 = _react.default.useState(0),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      containerWidth = _React$useState4[0],
      setContainerWidth = _React$useState4[1];

  var ref = _react.default.createRef();

  var next = function next() {
    var scrollX = xPosition + 300;

    if (scrollX > containerWidth) {
      ref.current.scrollToEnd();
      setXPosition(containerWidth);
    } else {
      ref.current.scrollTo({
        x: scrollX,
        y: 0
      });
      setXPosition(scrollX);
    }
  };

  var previous = function previous() {
    var scrollX = xPosition - 300;

    if (scrollX < 0) {
      ref.current.scrollTo({
        x: 0,
        y: 0
      });
      setXPosition(0);
    } else {
      ref.current.scrollTo({
        x: scrollX,
        y: 0
      });
      setXPosition(scrollX);
    }
  };

  _react.default.useEffect(function () {
    dispatch(_testimonials.default.actions.getTestimonials());
  }, [dispatch]);

  if (testimonialArray.length === 0) return null;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Box, {
    display: "flex",
    pb: 2
  }, _react.default.createElement(_core.Typography, {
    variant: "h2",
    className: classes.textUppercase
  }, testimonialsTitle || _i18n.default.t('header.testimonials'))), _react.default.createElement(_core.Box, {
    className: classes.container
  }, _react.default.createElement(_core.Box, {
    px: 3,
    className: classes.controlContainer
  }, containerWidth !== 0 && _react.default.createElement(_reactNativePaperV.TouchableRipple, {
    style: [Styles.iconButton, Styles.left],
    onPress: function onPress() {
      return previous();
    }
  }, _react.default.createElement(_core.Typography, null, _react.default.createElement(_reactFeather.ChevronLeft, null))), isLoaded && testimonialArray.length && _react.default.createElement(_reactNativeGestureHandler.ScrollView, {
    showsHorizontalScrollIndicator: false,
    onLayout: function onLayout(_ref) {
      var _ref$nativeEvent = _ref.nativeEvent,
          width = _ref$nativeEvent.target.scrollWidth,
          elementWidth = _ref$nativeEvent.layout.width;
      return setContainerWidth(Math.max(width - elementWidth, 345));
    },
    ref: ref,
    pagingEnabled: true,
    horizontal: true
  }, testimonialArray.map(function (testimonial, i) {
    return _react.default.createElement(_reactNative.View, {
      style: Styles.slide,
      key: testimonial.id
    }, _react.default.createElement(_testimonial.default, testimonial));
  })), containerWidth !== 0 && _react.default.createElement(_reactNativePaperV.TouchableRipple, {
    style: [Styles.iconButton, Styles.right],
    onPress: function onPress() {
      return next();
    }
  }, _react.default.createElement(_core.Typography, null, _react.default.createElement(_reactFeather.ChevronRight, null)))), isLoading && !isLoaded && Array(4).fill('').map(function (_, index) {
    return _react.default.createElement(_core.Box, {
      display: "flex",
      m: 3
    }, _react.default.createElement(_reactContentLoader.default, {
      key: index,
      viewBox: "0 0 171 96",
      height: 96,
      width: 171,
      backgroundColor: "#e0e0e0"
    }, _react.default.createElement("rect", {
      x: "0",
      y: "0",
      rx: "0",
      ry: "0",
      width: "171",
      height: "96"
    })));
  })));
};

var _default = Testimonials;
exports.default = _default;