var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _ColoredCircularProgress = _interopRequireDefault(require("../../../theme/components/loaders/ColoredCircularProgress"));

var _ClientState = _interopRequireDefault(require("../../common/audio-video/ClientState"));

var _BackstageState = _interopRequireDefault(require("./BackstageState"));

var _core = require("@material-ui/core");

var _NavigateNext = _interopRequireDefault(require("@material-ui/icons/NavigateNext"));

var BackstageHeaderStepper = function BackstageHeaderStepper() {
  var backstage = _i18n.default.t('backstage.header.backstage');

  var greenRoom = _i18n.default.t('backstage.header.greenRoom');

  var live = _i18n.default.t('backstage.header.session');

  var backstageComponents = [backstage];
  var greenRoomComponents = [backstage, greenRoom];
  var liveComponents = [backstage, greenRoom, live];

  var breadcrumb = function breadcrumb(items) {
    return _react.default.createElement(_core.Breadcrumbs, {
      key: "B-".concat(Math.random(100)),
      separator: _react.default.createElement(_NavigateNext.default, {
        fontSize: "small"
      }),
      "aria-label": "breadcrumb"
    }, items.map(function (item, index) {
      return _react.default.createElement(_core.Box, {
        key: item,
        ml: 1
      }, _react.default.createElement(_core.Typography, {
        color: index === items.length - 1 ? 'textPrimary' : 'inherit'
      }, item));
    }));
  };

  return _react.default.createElement(_core.Box, {
    display: "flex",
    justifyContent: "center"
  }, _react.default.createElement(_ClientState.default, {
    connectedState: _react.default.createElement(_BackstageState.default, {
      key: "stepper",
      requestState: breadcrumb(greenRoomComponents),
      greenRoomState: breadcrumb(greenRoomComponents),
      liveRoomState: breadcrumb(liveComponents),
      liveLoadingState: breadcrumb(liveComponents),
      liveState: breadcrumb(liveComponents)
    }),
    disconnectedState: breadcrumb(backstageComponents),
    connectingState: breadcrumb(greenRoomComponents),
    disconnectingState: breadcrumb(greenRoomComponents),
    reconnectingOverlay: _react.default.createElement(_ColoredCircularProgress.default, {
      key: "reconnecting",
      size: 20
    })
  }));
};

var _default = BackstageHeaderStepper;
exports.default = _default;