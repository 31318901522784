var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Typography;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function Typography(_ref) {
  var _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? 'paragraph' : _ref$variant,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'pageText' : _ref$color,
      style = _ref.style,
      children = _ref.children,
      _ref$textAlign = _ref.textAlign,
      textAlign = _ref$textAlign === void 0 ? 'left' : _ref$textAlign,
      _ref$transform = _ref.transform,
      transform = _ref$transform === void 0 ? 'none' : _ref$transform,
      rest = (0, _objectWithoutProperties2.default)(_ref, ["variant", "color", "style", "children", "textAlign", "transform"]);

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      typography = _useTheme.typography;

  return _react.default.createElement(_reactNative.Text, (0, _extends2.default)({}, rest, {
    style: [_objectSpread({}, typography[variant] || typography.paragraph), {
      color: colors[color] || color,
      textAlign: textAlign,
      wordBreak: 'break-word',
      textTransform: transform
    }, style]
  }), children);
}