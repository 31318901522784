var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventLiveInternal = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _moment = _interopRequireDefault(require("moment"));

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var _reactNativeVideo = _interopRequireDefault(require("react-native-video"));

var _reactNavigation = require("react-navigation");

var _reactRedux = require("react-redux");

var _eventInfo = _interopRequireDefault(require("../../core/event-info"));

var _live = _interopRequireDefault(require("../../core/live"));

var _constants = require("../../core/polls/constants");

var _Analytics = require("../../core/services/Analytics");

var _user = _interopRequireDefault(require("../../core/user"));

var _Redirect = _interopRequireDefault(require("../../navigation/client/Redirect"));

var _routesConsts = require("../../navigation/client/routes-consts");

var _ContainedButton = _interopRequireDefault(require("../../theme/components/buttons/ContainedButton"));

var _DynamicSection = _interopRequireDefault(require("../../theme/components/section/DynamicSection"));

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../theme/components/typography/Typography"));

var _colorUtils = _interopRequireDefault(require("../../utils/color-utils"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _AutoConnect = _interopRequireDefault(require("../chat/AutoConnect"));

var _blockedUsersList = _interopRequireDefault(require("../chat/blocked-users-list"));

var _Chat = _interopRequireDefault(require("../chat/Chat"));

var _usersList = _interopRequireDefault(require("../chat/users-list"));

var _avatars = _interopRequireDefault(require("../common/avatars"));

var _polls = _interopRequireDefault(require("../common/polls"));

var _rightSideMenu = require("../common/right-side-menu");

var _menuItem = _interopRequireDefault(require("../common/right-side-menu/menu-item/menu-item"));

var _SmartCountDown = _interopRequireDefault(require("../common/SmartCountDown"));

var _noAccessView = _interopRequireDefault(require("./components/no-access-view"));

var _ResponsiveLiveContainer = _interopRequireDefault(require("./components/ResponsiveLiveContainer"));

var _ResponsiveWebVideoContainer = _interopRequireDefault(require("./components/ResponsiveWebVideoContainer"));

var _sessionTitleContainer = _interopRequireDefault(require("./components/session-title/session-title-container"));

var _EventLiveFullscreenOverlay = _interopRequireDefault(require("./EventLiveFullscreenOverlay.web"));

var _EventLiveOverlay = _interopRequireDefault(require("./EventLiveOverlay.web"));

var _style = _interopRequireDefault(require("./style"));

var _sessionTypeConstants = require("./../../constants/session-type-constants");

var _QAndA = _interopRequireDefault(require("../../core/QAndA"));

var _selectors = require("../../core/QAndA/selectors");

var _core = require("@material-ui/core");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var EventLiveInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(EventLiveInternal, _React$PureComponent);

  var _super = _createSuper(EventLiveInternal);

  function EventLiveInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, EventLiveInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      isBuffering: false,
      hasError: false,
      fullScreen: false,
      fullScreenOverlayVisible: true,
      autoStarted: false,
      withDelay: true,
      error: null,
      pausedAt: null,
      isMuted: false,
      isVideoEnded: false
    };
    _this.videoRef = _react.default.createRef();
    _this.compIsMounted = false;
    _this.playingChunkTimeStarted = 0;
    _this.totalBackgroundTime = 0;
    _this.totalForegroundTime = 0;
    _this.currentProgressValue = 0;
    _this.watch15sTimeout = null;
    _this.timer = undefined;
    _this.muxMetaDataInfo = null;

    _this.sendAnalytics = function () {
      var _currentSession$sessi;

      if (!_this.compIsMounted) {
        return;
      }

      _this.sendAnalyticsEvent('join_live_show');

      _this.watch15sTimeout = setTimeout(function () {
        _this.watch15sTimeout = null;

        _this.sendAnalyticsEvent('watch_live_show_15s');
      }, 15000);
      var _this$props = _this.props,
          live = _this$props.live,
          currentUser = _this$props.currentUser,
          eventInfo = _this$props.eventInfo;
      var currentSession = live.currentSession;
      _this.muxMetaDataInfo = {
        page_type: 'watchpage',
        viewer_user_id: currentUser.uid,
        video_series: eventInfo.id,
        video_id: live.id,
        video_title: currentSession === null || currentSession === void 0 ? void 0 : currentSession.title,
        video_stream_type: live.type === 'Pre-recorded video streaming' || (currentSession === null || currentSession === void 0 ? void 0 : (_currentSession$sessi = currentSession.sessionType) === null || _currentSession$sessi === void 0 ? void 0 : _currentSession$sessi.id) === 2 ? 'on-demand' : 'live'
      };
    };

    _this.sendAnalyticsData = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.sendAnalytics();

            case 1:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    _this.toggleFullscreen = function () {
      if (_this.state.fullScreen) {
        _this.setState({
          fullScreen: false
        });
      } else {
        _this.setState({
          fullScreen: true
        });
      }
    };

    _this.handleClose = function () {
      _this.props.navigation.dispatch(_reactNavigation.NavigationActions.back());
    };

    _this.openLiveDetailsModal = function () {
      _this.props.navigation.navigate('LiveDetailsModal');
    };

    _this._handleAppStateChange = function (nextAppState) {
      var now = parseInt(Date.now() / 1000, 10);

      if (nextAppState === 'active') {
        _this.totalBackgroundTime += now - _this.playingChunkTimeStarted;
      } else if (nextAppState === 'background') {
        _this.totalForegroundTime += now - _this.playingChunkTimeStarted;

        _this.sendAnalyticsEvent('watch_live_show', {
          time: _this.totalForegroundTime + _this.totalBackgroundTime,
          foreground_time: _this.totalForegroundTime,
          background_time: _this.totalBackgroundTime
        }, function () {
          _this.totalForegroundTime = 0;
          _this.totalBackgroundTime = 0;
        });
      }

      _this.playingChunkTimeStarted = now;
    };

    _this.allowFullscreen = function () {
      var elem = document.getElementsByClassName('live-video')[0];

      if (elem) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen().catch(function (err) {
            alert("Error attempting to enable full-screen mode: ".concat(err.message, " (").concat(err.name, ")"));
          });
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      }
    };

    _this.exitFullscreen = function () {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    };

    _this.reloadTimeout = null;

    _this.handleBuffering = function (e) {
      _this.setState({
        isBuffering: e.reason === 'loading'
      });
    };

    _this.handleCasting = function (castObj) {
      try {
        if (castObj.available && castObj.active) {
          setTimeout(function () {
            _this.setState({
              fullScreenOverlayVisible: false
            });
          }, 1500);
        } else {
          setTimeout(function () {
            if (!_this.state.fullScreenOverlayVisible) {
              _this.jumpToLive();
            }

            _this.setState({
              fullScreenOverlayVisible: true
            });
          }, 1500);
        }
      } catch (error) {
        console.log(error);
      }
    };

    _this.handleBufferChange = function () {};

    _this.handlePlay = function () {
      _this.setState({
        isBuffering: false,
        isVideoEnded: false
      });

      _this.jumpToLive();
    };

    _this.handleAutoStart = function () {
      if (!_this.state.autoStarted) {
        _this.jumpToLive();

        _this.setState({
          autoStarted: true
        });
      }
    };

    _this.handlePause = function () {
      _this.setState({
        pausedAt: new Date()
      });
    };

    _this.handleResume = function () {
      var pauseTime = _this.state.pausedAt ? new Date() - _this.state.pausedAt : 0;

      if (pauseTime > 5000) {
        _this.jumpToLive();
      }

      _this.setState({
        pausedAt: null
      });
    };

    _this.handleReady = function () {
      _this.setState({
        autoStarted: false
      });
    };

    _this.jumpToLive = function () {
      var _session$sessionType;

      var _this$props$live = _this.props.live,
          currentSession = _this$props$live.currentSession,
          delayedCurrentSession = _this$props$live.delayedCurrentSession;
      var session = !_this.state.isVideoEnded ? delayedCurrentSession : null;

      if (_this.props.live.type === _sessionTypeConstants.SESSION_TYPE_PRERECORDED_NAME || (session === null || session === void 0 ? void 0 : (_session$sessionType = session.sessionType) === null || _session$sessionType === void 0 ? void 0 : _session$sessionType.id) === _sessionTypeConstants.SESSION_TYPE_PRERECORDED_ID) {
        if (session) {
          var now = (0, _moment.default)();
          var elapsedTime = now - (0, _moment.default)(session.start);
          var timeLeftUntilEnd = (0, _moment.default)(session.end) - now;

          if (elapsedTime > 10000 && timeLeftUntilEnd > 0) {
            var timePassedFromStart = Math.round(Math.abs(elapsedTime) / 1000);

            if (_this.videoRef.current && _this.videoRef.current.player) {
              _this.videoRef.current.player.seek(timePassedFromStart);
            }
          }
        }
      }
    };

    _this.handleProgress = function (progress) {
      if (progress.currentTime > _this.currentProgressValue && _this.state.isBuffering) {
        _this.setState({
          isBuffering: false
        });
      } else if (progress.currentTime <= _this.currentProgressValue && !_this.state.isBuffering) {
        _this.setState({
          isBuffering: true
        });
      }

      _this.currentProgressValue = progress.currentTime;
    };

    _this.getLiveStartOrEndMessage = function (live) {
      var sessions = live.sessions;
      if (!sessions) return _i18n.default.t('liveNow.sessionEnded');
      var now = (0, _moment.default)();
      var session = sessions.find(function (s) {
        return (0, _moment.default)(s.start).diff(now, 'days') === 0 && (0, _moment.default)(s.start).diff(now, 'minutes') < 0 && (0, _moment.default)(s.end).diff(now, 'minutes') > 0;
      });

      if (session) {
        return _i18n.default.t('liveNow.sessionWillStart');
      }

      return _i18n.default.t('liveNow.sessionEnded');
    };

    return _this;
  }

  (0, _createClass2.default)(EventLiveInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.compIsMounted = true;
      this.sendAnalyticsData();
      this.playingChunkTimeStarted = parseInt(Date.now() / 1000, 10);
      this.totalBackgroundTime = 0;
      this.totalForegroundTime = 0;

      _reactNative.AppState.addEventListener('change', this._handleAppStateChange);

      document.addEventListener('fullscreenchange', this.toggleFullscreen);
      document.addEventListener('mozfullscreenchange', this.toggleFullscreen);
      document.addEventListener('webkitfullscreenchange', this.toggleFullscreen);
      document.addEventListener('msfullscreenchange', this.toggleFullscreen);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.compIsMounted = false;

      if (this.reloadTimeout) {
        clearTimeout(this.reloadTimeout);
      }

      if (this.watch15sTimeout) {
        clearTimeout(this.watch15sTimeout);
      }

      if (this.timer) {
        clearTimeout(this.timer);
      }

      if (this.liveTimeCounter) {
        clearInterval(this.liveTimeCounter);
      }

      var now = parseInt(Date.now() / 1000, 10);

      if (_reactNative.AppState.currentState === 'active') {
        this.totalForegroundTime += now - this.playingChunkTimeStarted;
      } else {
        this.totalBackgroundTime += now - this.playingChunkTimeStarted;
      }

      this.sendAnalyticsEvent('watch_live_show', {
        time: this.totalForegroundTime + this.totalBackgroundTime,
        foreground_time: this.totalForegroundTime,
        background_time: this.totalBackgroundTime
      });

      _reactNative.AppState.removeEventListener('change', this._handleAppStateChange);

      document.removeEventListener('fullscreenchange', this.toggleFullscreen);
      document.removeEventListener('mozfullscreenchange', this.toggleFullscreen);
      document.removeEventListener('webkitfullscreenchange', this.toggleFullscreen);
      document.removeEventListener('msfullscreenchange', this.toggleFullscreen);
      this.props.joinLive(this.props.live.id);
    }
  }, {
    key: "sendAnalyticsEvent",
    value: function sendAnalyticsEvent(eventId) {
      var _navigator;

      var extraParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var callback = arguments.length > 2 ? arguments[2] : undefined;
      var _this$props2 = this.props,
          live = _this$props2.live,
          currentUser = _this$props2.currentUser;
      var currentSession = live.currentSession,
          nextSession = live.nextSession;

      var params = _objectSpread({
        event_id: live.id,
        device_id: _reactNativeDeviceInfo.default.getUniqueId(),
        browser_id: (_navigator = navigator) === null || _navigator === void 0 ? void 0 : _navigator.appVersion,
        user_id: currentUser.uid
      }, extraParams);

      var festivalId = this.props.eventInfo.id;
      var festivalName = this.props.eventInfo.name;
      params = _objectSpread(_objectSpread({}, params), {}, {
        festival_id: festivalId,
        festival_name: festivalName
      });
      (0, _Analytics.trackEvent)("".concat(eventId, "_").concat(festivalName, "_").concat(festivalId).replace(' ', '_'), params);
      (0, _Analytics.trackKeyEvent)(eventId, params);
      (0, _Analytics.trackEvent)("".concat(eventId, "_").concat(currentSession && currentSession.name || nextSession && nextSession.name || 'NO_SESSION').replace(' ', '_'), params);
      callback && callback();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {}
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.props.leaveLive();
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props$currentUs,
          _this$props$eventInfo,
          _currentSession$sessi2,
          _this2 = this,
          _currentSession$sessi3,
          _currentSession$sessi4,
          _currentSession$sessi5;

      if (!this.props.isFocused) {
        return _react.default.createElement(_reactNative.ActivityIndicator, null);
      }

      var isAdmin = ((_this$props$currentUs = this.props.currentUser) === null || _this$props$currentUs === void 0 ? void 0 : _this$props$currentUs.uid) === ((_this$props$eventInfo = this.props.eventInfo) === null || _this$props$eventInfo === void 0 ? void 0 : _this$props$eventInfo.createdBy);
      var live = this.props.live;
      var nextSession = live.nextSession,
          delayedCurrentSession = live.delayedCurrentSession,
          currentSession = live.currentSession;
      var session = !this.state.isVideoEnded ? delayedCurrentSession : null;
      var firstSession = live.sessions ? live.sessions[0] : null;
      if (!session && !nextSession && (live.type === _sessionTypeConstants.SESSION_TYPE_PRERECORDED_NAME || (currentSession === null || currentSession === void 0 ? void 0 : (_currentSession$sessi2 = currentSession.sessionType) === null || _currentSession$sessi2 === void 0 ? void 0 : _currentSession$sessi2.id) === _sessionTypeConstants.SESSION_TYPE_PRERECORDED_ID)) return _react.default.createElement(_Redirect.default, {
        to: _routesConsts.Routes.LIVE_NOW_PAGE
      });
      var questionsAndAnswers = (0, _reactRedux.useSelector)(_selectors.selectQuestionsList);
      var numberOfQuestions = questionsAndAnswers.length;

      var startCountdown = nextSession && _react.default.createElement(_DynamicSection.default, {
        shaped: true,
        lightColor: "#FFFFFF",
        darkColor: "#000000",
        style: _style.default.countDownContainer
      }, function (textColor) {
        return _react.default.createElement(_reactNative.View, {
          style: {
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center'
          }
        }, _react.default.createElement(_DynamicSection.default, {
          shaped: true,
          parentBackground: (0, _colorUtils.default)(textColor, '#FFFFFF', '#000000'),
          lightColor: "#FFFFFF",
          darkColor: "#000000",
          style: _style.default.countDownInnerContainer
        }, function (innerTextColor) {
          return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_avatars.default, {
            urls: nextSession.speakers.map(function (_ref2) {
              var id = _ref2.id,
                  photo = _ref2.photo;
              return {
                id: id,
                photo: photo
              };
            })
          }), _react.default.createElement(_Spacing.default, {
            mSpacing: "s"
          }, _react.default.createElement(_Typography.default, {
            variant: "description",
            color: innerTextColor
          }, _i18n.default.t('liveNow.sessionStarts'))), _react.default.createElement(_SmartCountDown.default, {
            untilDate: new Date(nextSession.start),
            color: innerTextColor,
            size: 30,
            style: _style.default.countdownTimer,
            timeElapsedMessage: "in a few moments",
            digitsOnly: true,
            onFinish: function onFinish() {
              _this2.props.preRecordedStart();

              _this2.setState({
                isVideoEnded: false
              });
            }
          }));
        }));
      });

      var videoContent = live.type === _sessionTypeConstants.SESSION_TYPE_PRERECORDED_NAME || (currentSession === null || currentSession === void 0 ? void 0 : (_currentSession$sessi3 = currentSession.sessionType) === null || _currentSession$sessi3 === void 0 ? void 0 : _currentSession$sessi3.id) === _sessionTypeConstants.SESSION_TYPE_PRERECORDED_ID ? session && session.liveVideoUrl && !this.state.hasError ? _react.default.createElement(_reactNativeVideo.default, {
        ref: this.videoRef,
        style: _style.default.webVideo,
        source: {
          uri: session.liveVideoUrl
        },
        onBuffer: this.handleBuffering,
        onBufferChange: this.handleBufferChange,
        onPlay: this.handlePlay,
        onReady: this.handleReady,
        onResume: this.handleResume,
        onPause: this.handlePause,
        onAutoStart: this.handleAutoStart,
        onProgress: this.handleProgress,
        key: this.props.live.id,
        onCast: function onCast(castObj) {
          return _this2.handleCasting(castObj);
        },
        onOneHundredPercent: function onOneHundredPercent() {
          _this2.props.preRecordedStart();

          _this2.setState({
            isVideoEnded: !(session === null || session === void 0 ? void 0 : session.delay)
          });
        },
        autoPlay: true,
        onMute: function onMute() {
          _this2.setState({
            isMuted: true
          });
        },
        onUnmute: function onUnmute() {
          _this2.setState({
            isMuted: false
          });
        },
        muted: this.state.isMuted,
        jwplayer: true,
        muxMetaDataInfo: this.muxMetaDataInfo
      }) : _react.default.createElement(_DynamicSection.default, {
        shaped: true,
        style: {
          alignItems: 'center',
          paddingBottom: '56.25%',
          height: 0,
          position: 'relative'
        }
      }, function (textColor) {
        return _react.default.createElement(_reactNative.View, {
          style: {
            maxWidth: 240,
            position: 'absolute',
            top: '50%'
          }
        }, _react.default.createElement(_Typography.default, {
          color: textColor
        }, _this2.state.hasError ? _i18n.default.t('errors.somethingWentWrong') : _i18n.default.t('liveNow.sessionEnded')));
      }) : !this.state.hasError && live.started && _react.default.createElement(_reactNativeVideo.default, {
        ref: this.videoRef,
        style: _style.default.webVideo,
        source: {
          uri: live.streamingPageUrl
        },
        onBuffer: this.handleBuffering,
        onBufferChange: this.handleBufferChange,
        onPlay: this.handlePlay,
        onReady: this.handleReady,
        onResume: this.handleResume,
        onMute: function onMute() {
          _this2.setState({
            isMuted: true
          });
        },
        onUnmute: function onUnmute() {
          _this2.setState({
            isMuted: false
          });
        },
        onPause: this.handlePause,
        onAutoStart: this.handleAutoStart,
        onProgress: this.handleProgress,
        onError: function onError(e) {
          if (e && (e.code === 232412 || e.code === 230001 || e.code === 224003 || e.code === 220001)) {
            _this2.setState({
              hasError: true,
              error: _i18n.default.t('liveNow.tryAgain')
            });
          }
        },
        key: this.props.live.id,
        onCast: function onCast(castObj) {
          return _this2.handleCasting(castObj);
        },
        autoPlay: true,
        muted: this.state.isMuted,
        jwplayer: true,
        muxMetaDataInfo: this.muxMetaDataInfo
      }) || _react.default.createElement(_reactNative.View, {
        style: {
          alignItems: 'center',
          paddingBottom: '56.25%',
          height: 0,
          position: 'relative',
          backgroundColor: 'black',
          borderRadius: 10
        }
      }, _react.default.createElement(_reactNative.View, {
        style: {
          maxWidth: 240,
          position: 'absolute',
          top: '50%'
        }
      }, _react.default.createElement(_reactNative.Text, {
        style: {
          color: 'white'
        }
      }, this.getLiveStartOrEndMessage(live)), this.state.error && _react.default.createElement(_ContainedButton.default, {
        onPress: function onPress() {
          _this2.setState({
            hasError: false,
            error: null
          });
        }
      }, "Refresh")));
      localStorage.setItem('jwplayer.enableShortcuts', false);
      var showLiveOverlay = session && (live.type === _sessionTypeConstants.SESSION_TYPE_PRERECORDED_NAME || (currentSession === null || currentSession === void 0 ? void 0 : (_currentSession$sessi4 = currentSession.sessionType) === null || _currentSession$sessi4 === void 0 ? void 0 : _currentSession$sessi4.id) === _sessionTypeConstants.SESSION_TYPE_PRERECORDED_ID) || (live.type !== _sessionTypeConstants.SESSION_TYPE_PRERECORDED_NAME || (currentSession === null || currentSession === void 0 ? void 0 : (_currentSession$sessi5 = currentSession.sessionType) === null || _currentSession$sessi5 === void 0 ? void 0 : _currentSession$sessi5.id) !== _sessionTypeConstants.SESSION_TYPE_PRERECORDED_ID) && live.started && !this.state.hasError;
      var menuBuilder = new _rightSideMenu.MenuBuilder();
      menuBuilder.add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.CHAT, function (_ref3) {
        var isSelected = _ref3.isSelected,
            onSelect = _ref3.onSelect;
        return _react.default.createElement(_menuItem.default, {
          key: "Chat",
          onPress: onSelect,
          icon: _react.default.createElement(_reactFeather.MessageCircle, null),
          color: isSelected ? 'button' : 'alternativeText'
        }, _i18n.default.translate('menuItems.chat'));
      }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.CHAT, _react.default.createElement(_AutoConnect.default, {
        id: this.props.live.id
      }, _react.default.createElement(_Chat.default, {
        rejoinId: this.props.live.id,
        disabled: this.props.chatDisabled,
        disabledMessage: this.props.chatDisabledMessage
      }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.CHAT_PARTICIPANTS, function (_ref4) {
        var isSelected = _ref4.isSelected,
            onSelect = _ref4.onSelect;
        return _react.default.createElement(_menuItem.default, {
          key: "People",
          onPress: onSelect,
          icon: _react.default.createElement(_reactFeather.Users, null),
          color: isSelected ? 'button' : 'alternativeText'
        }, _i18n.default.translate('menuItems.people'));
      }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.CHAT_PARTICIPANTS, _react.default.createElement(_AutoConnect.default, {
        id: this.props.live.id
      }, _react.default.createElement(_usersList.default, null))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.QA, function (_ref5) {
        var isSelected = _ref5.isSelected,
            onSelect = _ref5.onSelect;
        return _react.default.createElement(_menuItem.default, {
          key: "QandA",
          onPress: onSelect,
          icon: _react.default.createElement(_core.Badge, {
            badgeContent: numberOfQuestions,
            color: "primary"
          }, _react.default.createElement(_reactFeather.HelpCircle, null)),
          color: isSelected ? 'button' : 'alternativeText'
        }, _i18n.default.translate('menuItems.qa'));
      }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.QA, _react.default.createElement(_QAndA.default, null)).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.POLLS, function (_ref6) {
        var isSelected = _ref6.isSelected,
            onSelect = _ref6.onSelect;
        return _react.default.createElement(_menuItem.default, {
          key: "Polls",
          onPress: onSelect,
          icon: _react.default.createElement(_reactFeather.BarChart, null),
          color: isSelected ? 'button' : 'alternativeText'
        }, _i18n.default.translate('menuItems.polls'));
      }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.POLLS, _react.default.createElement(_polls.default, {
        location: _constants.POLL_TYPES.LIVE
      })).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PARTICIPANTS, function (_ref7) {
        var isSelected = _ref7.isSelected,
            onSelect = _ref7.onSelect;
        return _react.default.createElement(_menuItem.default, {
          key: "Abuse",
          onPress: onSelect,
          icon: _react.default.createElement(_reactFeather.Shield, null),
          color: isSelected ? 'button' : 'alternativeText'
        }, _i18n.default.translate('menuItems.abuse'));
      }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PARTICIPANTS, _react.default.createElement(_AutoConnect.default, {
        id: this.props.live.id
      }, _react.default.createElement(_blockedUsersList.default, null)));
      var menu;

      if (this.props.chatDisabled) {
        menu = menuBuilder.buildDisabledChatMenu();
      } else if (isAdmin || this.props.adminAccess) {
        menu = menuBuilder.buildAdminMenu();
      } else {
        menu = menuBuilder.buildClientMenu();
      }

      var _renderVideo = function _renderVideo() {
        var now = (0, _moment.default)();

        var countDown = _react.default.createElement("div", {
          className: "live-video"
        }, startCountdown);

        if (firstSession && (0, _moment.default)(firstSession.start).diff(now, 'days') >= 1) {
          return countDown;
        }

        if (!_this2.props.stageAccess && !_this2.props.adminAccess) {
          return _react.default.createElement(_noAccessView.default, null);
        }

        if (!_this2.props.dayAccess && !_this2.props.adminAccess) {
          var _currentSession$sessi6;

          if (live.type !== _sessionTypeConstants.SESSION_TYPE_PRERECORDED_NAME || (currentSession === null || currentSession === void 0 ? void 0 : (_currentSession$sessi6 = currentSession.sessionType) === null || _currentSession$sessi6 === void 0 ? void 0 : _currentSession$sessi6.id) !== _sessionTypeConstants.SESSION_TYPE_PRERECORDED_ID) {
            return live.started ? _react.default.createElement(_noAccessView.default, null) : countDown;
          } else {
            return _react.default.createElement(_noAccessView.default, null);
          }
        }

        if (!session && nextSession && !live.started) return countDown;
        if (live.started && !session && nextSession) return countDown;
        return _renderVideoContent();
      };

      var _renderVideoContent = function _renderVideoContent() {
        var _currentSession$sessi7, _currentSession$sessi8;

        return _react.default.createElement("div", {
          className: "live-video"
        }, videoContent, showLiveOverlay && _react.default.createElement(_EventLiveOverlay.default, {
          startDate: session ? (0, _moment.default)(session.start) : new Date()
        }), session && (live.type === _sessionTypeConstants.SESSION_TYPE_PRERECORDED_NAME || (currentSession === null || currentSession === void 0 ? void 0 : (_currentSession$sessi7 = currentSession.sessionType) === null || _currentSession$sessi7 === void 0 ? void 0 : _currentSession$sessi7.id) === _sessionTypeConstants.SESSION_TYPE_PRERECORDED_ID) || (live.type !== _sessionTypeConstants.SESSION_TYPE_PRERECORDED_NAME || (currentSession === null || currentSession === void 0 ? void 0 : (_currentSession$sessi8 = currentSession.sessionType) === null || _currentSession$sessi8 === void 0 ? void 0 : _currentSession$sessi8.id) !== _sessionTypeConstants.SESSION_TYPE_PRERECORDED_ID) && live.started && !window.navigator.userAgent.match(/iphone|ipod/i) && _react.default.createElement(_EventLiveFullscreenOverlay.default, {
          enableFullscreen: _this2.allowFullscreen,
          disableFullscreen: _this2.exitFullscreen,
          fullScreen: _this2.state.fullScreen,
          started: true,
          fullScreenOverlayVisible: _this2.state.fullScreenOverlayVisible
        }));
      };

      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_ResponsiveLiveContainer.default, null, _react.default.createElement(_ResponsiveWebVideoContainer.default, null, _react.default.createElement(_Spacing.default, {
        direction: "column",
        alignItems: "stretch",
        style: _style.default.liveVideoContainer
      }, _renderVideo()), _react.default.createElement(_sessionTitleContainer.default, null)), menu));
    }
  }]);
  return EventLiveInternal;
}(_react.default.PureComponent);

exports.EventLiveInternal = EventLiveInternal;

function mapStateToProps(state, ownProps) {
  return _objectSpread(_objectSpread({}, ownProps), {}, {
    currentUser: _user.default.selectors.getFirebaseUser(state),
    eventInfo: _eventInfo.default.selectors.selectEventInfo(state)
  });
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  joinLive: _live.default.actions.join,
  leaveLive: _live.default.actions.leave
})((0, _reactNavigation.withNavigationFocus)(EventLiveInternal));

exports.default = _default;