var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BreakoutRoomsButton;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _ColoredIconButton = _interopRequireDefault(require("../../../theme/components/buttons/ColoredIconButton"));

var _reactFeather = require("react-feather");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _audioVideoStyles = _interopRequireDefault(require("./audio-video-styles"));

var _reactRedux = require("react-redux");

var _actions = require("../../../core/user-rooms/actions");

var _selectors = require("../../../core/user-roles/selectors");

var _featureNames = require("../../../../functions/constants/feature-names");

function BreakoutRoomsButton(_ref) {
  var color = _ref.color,
      isMainRoom = _ref.isMainRoom,
      roomId = _ref.roomId,
      isBreakoutRoomsStarted = _ref.isBreakoutRoomsStarted;
  var Styles = (0, _audioVideoStyles.default)();
  var theme = (0, _core.useTheme)();
  var match = (0, _core.useMediaQuery)(theme.breakpoints.down('sm'));
  var dispatch = (0, _reactRedux.useDispatch)();
  var hasAccess = (0, _reactRedux.useSelector)((0, _selectors.hasUserFeatureAccessSelector)(_featureNames.START_STOP_BREAKOUT_ROOMS, roomId));

  var onClick = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (isBreakoutRoomsStarted) {
                dispatch((0, _actions.stopBreakoutRooms)(roomId));
              } else {
                dispatch((0, _actions.startBreakoutRooms)(roomId));
              }

            case 1:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function onClick() {
      return _ref2.apply(this, arguments);
    };
  }();

  if (!isMainRoom) return null;
  if (match) return _react.default.createElement(_ColoredIconButton.default, {
    disabled: !hasAccess,
    customColor: color,
    onClick: onClick
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.buttonSize
  }, _react.default.createElement(_reactFeather.Rss, null)));
  return _react.default.createElement(_ContainedButton.default, {
    disabled: !hasAccess,
    onPress: onClick
  }, _i18n.default.translate("".concat(isBreakoutRoomsStarted ? 'networking.stopBreakoutRooms' : 'networking.startBreakoutRooms')));
}