var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleJoinRequestAccepted;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = require("react");

var _reactRedux = require("react-redux");

var _agoraConstants = require("../../../constants/agora-constants");

var _actions = require("../../../core/user-rooms/actions");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _routesConsts = require("../../../navigation/client/routes-consts");

function HandleJoinRequestAccepted(_ref) {
  var isApproved = _ref.isApproved,
      uid = _ref.uid,
      roomId = _ref.roomId,
      eventId = _ref.eventId,
      token = _ref.token;
  var dispatch = (0, _reactRedux.useDispatch)();

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      loading = _useAudioVideoContext.loading,
      join = _useAudioVideoContext.join,
      clearRequestAccess = _useAudioVideoContext.clearRequestAccess;

  var navigation = (0, _useNavigationExtended.default)();
  (0, _react.useEffect)(function () {
    var onJoinApproval = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                if (!isApproved) {
                  _context.next = 4;
                  break;
                }

                dispatch((0, _actions.unsubscribeParticipantRequestsChanges)(roomId));
                _context.next = 4;
                return dispatch((0, _actions.joinRoom)({
                  roomId: roomId,
                  path: "events/".concat(eventId, "/networking-tables/").concat(roomId),
                  type: _agoraConstants.TYPES.NETWORKING,
                  event: _agoraConstants.JOIN_EVENT
                }));

              case 4:
                if (typeof isApproved === 'boolean' && !isApproved) {
                  clearRequestAccess();
                  dispatch((0, _actions.cancelRequestToJoinRoom)(roomId));
                  navigation.navigate(_routesConsts.Routes.NETWORKING_PAGE);
                }

              case 5:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      return function onJoinApproval() {
        return _ref2.apply(this, arguments);
      };
    }();

    onJoinApproval();
  }, [isApproved, uid, roomId]);
  (0, _react.useEffect)(function () {
    if (token && !loading) {
      var onTokenReceived = function () {
        var _ref3 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2() {
          return _regenerator.default.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _context2.next = 2;
                  return join(roomId, token, uid);

                case 2:
                case "end":
                  return _context2.stop();
              }
            }
          }, _callee2);
        }));

        return function onTokenReceived() {
          return _ref3.apply(this, arguments);
        };
      }();

      onTokenReceived();
    }
  }, [token, roomId, uid]);
  return null;
}