var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Ticket;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _ticketStyles = _interopRequireDefault(require("./ticket-styles"));

function Ticket(_ref) {
  var disabled = _ref.disabled,
      onPress = _ref.onPress,
      selected = _ref.selected,
      parsedPriceLabel = _ref.parsedPriceLabel,
      name = _ref.name,
      available = _ref.available,
      _ref$description = _ref.description,
      description = _ref$description === void 0 ? null : _ref$description,
      rest = (0, _objectWithoutProperties2.default)(_ref, ["disabled", "onPress", "selected", "parsedPriceLabel", "name", "available", "description"]);
  var Styles = (0, _ticketStyles.default)();
  return _react.default.createElement(_Spacing.default, {
    mSpacing: ['xs', 0],
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_reactNative.TouchableHighlight, {
    disabled: disabled,
    style: Styles.shaped,
    onPress: onPress
  }, _react.default.createElement(_reactNative.View, null, _react.default.createElement(_Section.default, (0, _extends2.default)({}, rest, {
    style: [disabled ? {
      opacity: 0.5
    } : {}, disabled ? {} : Styles.container],
    color: "alternativeBackground",
    shaping: "buttons",
    shaped: true
  }), _react.default.createElement(_Spacing.default, {
    pSpacing: 'm',
    direction: "row",
    wrap: "nowrap",
    justifyContent: "space-between"
  }, _react.default.createElement(_Section.default, {
    color: "button",
    style: Styles.circle
  }, selected && _react.default.createElement(_Section.default, {
    color: "buttonText",
    style: Styles.innerCircle
  })), _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 'l'],
    flex: 1,
    direction: "column",
    alignItems: "flex-start"
  }, _react.default.createElement(_core.Typography, {
    variant: "body1"
  }, _react.default.createElement("strong", null, name)), description && _react.default.createElement(_Spacing.default, {
    mSpacing: ['s', 0, 0, 0]
  }, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, description)) || null), _react.default.createElement(_core.Typography, {
    variant: "h3"
  }, parsedPriceLabel))))));
}