var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LoadingScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _lottieReactNative = _interopRequireDefault(require("lottie-react-native"));

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var LoadingScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(LoadingScreenInternal, _React$PureComponent);

  var _super = _createSuper(LoadingScreenInternal);

  function LoadingScreenInternal() {
    (0, _classCallCheck2.default)(this, LoadingScreenInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(LoadingScreenInternal, [{
    key: "render",
    value: function render() {
      return _react.default.createElement(_reactNativeSafeAreaView.default, {
        style: styles.safearea,
        forceInset: {
          top: 'never',
          bottom: 'never'
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_lottieReactNative.default, {
        source: require('./common/loading/loading-animation.json'),
        style: {},
        autoPlay: true,
        loop: true
      })));
    }
  }]);
  return LoadingScreenInternal;
}(_react.default.PureComponent);

exports.LoadingScreenInternal = LoadingScreenInternal;

var styles = _reactNative.StyleSheet.create({
  safearea: {
    flex: 1
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'black'
  }
});

var _default = LoadingScreenInternal;
exports.default = _default;