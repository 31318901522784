Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasNoAdsTicket = exports.hasTicketAccess = exports.getDisplayName = exports.isLoggedUserDj = exports.getUserTickets = exports.getUserEmail = exports.getUserPicture = exports.getUserName = exports.getUserId = exports.getFirebaseAccessToken = exports.hasFirebaseUserDisplayNameSelector = exports.getFirebaseUserAvatar = exports.getFirebaseUserName = exports.getFirebaseUserUid = exports.getFirebaseUser = exports.isFirebaseUserLoggedIn = exports.confirmResult = exports.isLoading = exports.isLoggedIn = exports.hasIncompleteProfile = exports.getAccessToken = exports.isLoginSkipped = exports.selectUserState = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var selectUserState = function selectUserState(state) {
  return state[_constants.NAME];
};

exports.selectUserState = selectUserState;

var isLoginSkipped = function isLoginSkipped(state) {
  return state[_constants.NAME].get('loginSkipped');
};

exports.isLoginSkipped = isLoginSkipped;

var getAccessToken = function getAccessToken(state) {
  return state[_constants.NAME].get('accessToken');
};

exports.getAccessToken = getAccessToken;

var hasIncompleteProfile = function hasIncompleteProfile(state) {
  return state[_constants.NAME].get('hasIncompleteProfile');
};

exports.hasIncompleteProfile = hasIncompleteProfile;

var isLoggedIn = function isLoggedIn(state) {
  return state[_constants.NAME].get('accessToken', null) !== null;
};

exports.isLoggedIn = isLoggedIn;
var isLoading = (0, _toolkit.createSelector)(selectUserState, function (userState) {
  return userState.get('isLoading', false);
});
exports.isLoading = isLoading;

var confirmResult = function confirmResult(state) {
  return state[_constants.NAME].get('confirmResult');
};

exports.confirmResult = confirmResult;
var isFirebaseUserLoggedIn = (0, _toolkit.createSelector)(selectUserState, function (userState) {
  return !!userState.get('firebaseUser', null);
});
exports.isFirebaseUserLoggedIn = isFirebaseUserLoggedIn;
var getFirebaseUser = (0, _toolkit.createSelector)(selectUserState, function (userState) {
  return userState.get('firebaseUser');
});
exports.getFirebaseUser = getFirebaseUser;
var getFirebaseUserUid = (0, _toolkit.createSelector)(getFirebaseUser, function (user) {
  return user && user.uid;
});
exports.getFirebaseUserUid = getFirebaseUserUid;
var getFirebaseUserName = (0, _toolkit.createSelector)(getFirebaseUser, function (user) {
  return (user === null || user === void 0 ? void 0 : user.displayName) || (user === null || user === void 0 ? void 0 : user.email) || '';
});
exports.getFirebaseUserName = getFirebaseUserName;
var getFirebaseUserAvatar = (0, _toolkit.createSelector)(getFirebaseUser, function (user) {
  return (user === null || user === void 0 ? void 0 : user.photoURL) || '';
});
exports.getFirebaseUserAvatar = getFirebaseUserAvatar;
var hasFirebaseUserDisplayNameSelector = (0, _toolkit.createSelector)(getFirebaseUser, function (user) {
  return !(user === null || user === void 0 ? void 0 : user.displayName);
});
exports.hasFirebaseUserDisplayNameSelector = hasFirebaseUserDisplayNameSelector;
var getFirebaseAccessToken = (0, _toolkit.createSelector)(getFirebaseUser, function (user) {
  return user && user.stsTokenManager.accessToken;
});
exports.getFirebaseAccessToken = getFirebaseAccessToken;

var getUserId = function getUserId(state) {
  return state[_constants.NAME].getIn(['profile', 'uid']);
};

exports.getUserId = getUserId;

var getUserName = function getUserName(state) {
  return state[_constants.NAME].getIn(['profile', 'displayName']);
};

exports.getUserName = getUserName;

var getUserPicture = function getUserPicture(state) {
  return state[_constants.NAME].getIn(['profile', 'image', 'contentUrl']);
};

exports.getUserPicture = getUserPicture;

var getUserEmail = function getUserEmail(state) {
  return state[_constants.NAME].getIn(['profile', 'email']);
};

exports.getUserEmail = getUserEmail;

var getUserTickets = function getUserTickets(state) {
  return state[_constants.NAME].getIn(['profile', 'virtualTickets']);
};

exports.getUserTickets = getUserTickets;

var isLoggedUserDj = function isLoggedUserDj(state) {
  return state[_constants.NAME].getIn(['profile', 'dj']);
};

exports.isLoggedUserDj = isLoggedUserDj;

var getDisplayName = function getDisplayName(state) {
  var _state$NAME$get, _state$NAME$get2;

  return ((_state$NAME$get = state[_constants.NAME].get('firebaseUser')) === null || _state$NAME$get === void 0 ? void 0 : _state$NAME$get.displayName) || ((_state$NAME$get2 = state[_constants.NAME].get('firebaseUser')) === null || _state$NAME$get2 === void 0 ? void 0 : _state$NAME$get2.email);
};

exports.getDisplayName = getDisplayName;

var hasTicketAccess = function hasTicketAccess(state, ticketId) {
  if (ticketId === undefined || state[_constants.NAME].getIn(['profile', 'virtualTickets']) === undefined) {
    return true;
  }

  return state[_constants.NAME].getIn(['profile', 'virtualTickets']).filter(function (element) {
    return element.get('id') === ticketId;
  }).size > 0;
};

exports.hasTicketAccess = hasTicketAccess;

var hasNoAdsTicket = function hasNoAdsTicket(state, ticketId) {
  if (ticketId === undefined || state[_constants.NAME].getIn(['profile', 'virtualTickets']) === undefined) {
    return true;
  }

  return state[_constants.NAME].getIn(['profile', 'virtualTickets']).filter(function (element) {
    return element.get('id') === ticketId;
  }).size > 0;
};

exports.hasNoAdsTicket = hasNoAdsTicket;