var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LeaderboardCountdown;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _SmartCountDown = _interopRequireDefault(require("../../../common/SmartCountDown"));

var _Title = _interopRequireDefault(require("../../../common/typography_v1/Title"));

var _leaderboardCountdownStyles = _interopRequireWildcard(require("./leaderboard-countdown-styles"));

var _headerStyles = _interopRequireDefault(require("../utils/header-styles"));

function LeaderboardCountdown(props) {
  var onFinish = props.onFinish,
      untilDate = props.untilDate;
  return _react.default.createElement(_reactNative.View, {
    style: _headerStyles.default.announcingWinnersContainer
  }, _react.default.createElement(_Title.default, {
    style: _leaderboardCountdownStyles.default.announcingWinnersTitle
  }, "Announcing winners "), _react.default.createElement(_SmartCountDown.default, {
    untilDate: untilDate,
    onFinish: onFinish,
    color: _leaderboardCountdownStyles.countdownColor,
    size: _leaderboardCountdownStyles.countdownSize,
    style: _leaderboardCountdownStyles.default.leaderboardCountdown,
    timeElapsedMessage: "in a few moments"
  }));
}