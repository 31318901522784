var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ReactionsSection;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _reactFeather = require("react-feather");

var _reactRedux = require("react-redux");

var _actions = require("../../../../core/QAndA/actions");

var _clsx = _interopRequireDefault(require("clsx"));

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    reactionsMainContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& svg': {
        cursor: 'pointer'
      }
    },
    reactionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around'
    },
    reactionsCounter: {
      width: 35,
      height: 35,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      color: theme.palette.headerText.main,
      justifyContent: 'center',
      alignItems: 'center'
    },
    unactive: {
      color: theme.palette.pageText.main
    },
    active: {
      color: theme.palette.primary.main
    }
  };
});

function ReactionsSection(_ref) {
  var question = _ref.question;
  var dispatch = (0, _reactRedux.useDispatch)();
  var classes = useStyles();

  var handleLike = function handleLike() {
    dispatch((0, _actions.likeQuestion)(question.id));
  };

  var handleDislike = function handleDislike() {
    dispatch((0, _actions.dislikeQuestion)(question.id));
  };

  return _react.default.createElement(_core.Box, {
    className: classes.reactionsMainContainer
  }, _react.default.createElement(_core.Box, {
    className: classes.reactionsContainer
  }, _react.default.createElement(_reactFeather.ArrowUpCircle, {
    size: 24,
    className: (0, _clsx.default)(question.liked ? classes.active : classes.unactive),
    onClick: handleLike
  }), _react.default.createElement(_core.Box, {
    className: classes.reactionsCounter,
    my: 1
  }, question.reactions), _react.default.createElement(_reactFeather.ArrowDownCircle, {
    size: 24,
    className: (0, _clsx.default)(question.disliked ? classes.active : classes.unactive),
    onClick: handleDislike
  })));
}