var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MuteUnmuteSpeaker;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactFeather = require("react-feather");

var _ColoredIconButton = _interopRequireDefault(require("../../../theme/components/buttons/ColoredIconButton"));

var _audioVideoStyles = _interopRequireDefault(require("./audio-video-styles"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _reactRedux = require("react-redux");

var _actions = require("../../../core/user-rooms/actions");

function MuteUnmuteSpeaker(_ref) {
  var color = _ref.color,
      speakerId = _ref.speakerId,
      roomId = _ref.roomId,
      participantHasAudioTrack = _ref.participantHasAudioTrack;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      reserved = _useState2[0],
      setReserved = _useState2[1];

  var dispatch = (0, _reactRedux.useDispatch)();
  var Styles = (0, _audioVideoStyles.default)();

  var handleMuteUnmuteSpeaker = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              setReserved(true);
              _context.next = 3;
              return dispatch((0, _actions.muteUnmuteSpeaker)({
                speakerId: speakerId,
                roomId: roomId
              }));

            case 3:
              setReserved(false);

            case 4:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function handleMuteUnmuteSpeaker() {
      return _ref2.apply(this, arguments);
    };
  }();

  return _react.default.createElement(_ColoredIconButton.default, {
    style: {
      width: '1em',
      height: '1em'
    },
    customColor: color,
    disabled: reserved,
    onClick: handleMuteUnmuteSpeaker
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.buttonSize
  }, participantHasAudioTrack ? _react.default.createElement(_reactFeather.Mic, {
    color: "#FFF",
    width: "36px",
    height: "36px"
  }) : _react.default.createElement(_reactFeather.MicOff, {
    width: "36px",
    height: "36px",
    color: "#FFF"
  })));
}