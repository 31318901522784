var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.AvatarImageInternal = exports.white = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _CustomCachedImage = _interopRequireDefault(require("../CustomCachedImage"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var white = '#ffffff';
exports.white = white;

var AvatarImageInternal = function (_React$Component) {
  (0, _inherits2.default)(AvatarImageInternal, _React$Component);

  var _super = _createSuper(AvatarImageInternal);

  function AvatarImageInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, AvatarImageInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this._root = void 0;
    _this.state = {
      hasImage: false
    };

    _this.handleErrorLoading = function () {
      _this.setState({
        hasImage: false
      });
    };

    return _this;
  }

  (0, _createClass2.default)(AvatarImageInternal, [{
    key: "setNativeProps",
    value: function setNativeProps(nativeProps) {
      if (this._root && _reactNative.Platform.OS === 'web') {
        this._root.setNativeProps(nativeProps);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
          defaultLabel = _this$props.defaultLabel,
          size = _this$props.size,
          source = _this$props.source,
          shape = _this$props.shape,
          style = _this$props.style,
          theme = _this$props.theme;
      var colors = theme.colors;
      var borderRadius;
      var backgroundColor = this.props.backgroundColor || colors.surface;

      if (shape === 'square') {
        borderRadius = 0;
      } else {
        borderRadius = size / 2;
      }

      var avatarStyle = {
        width: size,
        height: size,
        backgroundColor: backgroundColor,
        borderRadius: borderRadius
      };
      var avatarTextStyle = {
        color: colors.white,
        fontSize: size * 0.3,
        height: size,
        lineHeight: size * 0.95
      };
      return React.createElement(_reactNative.View, {
        style: [avatarStyle, styles.container, style]
      }, this.state.hasImage ? React.createElement(_CustomCachedImage.default, {
        source: source,
        style: avatarStyle,
        onError: this.handleErrorLoading
      }) : React.createElement(_reactNativePaper.Text, {
        style: [avatarTextStyle, styles.textAlign],
        numberOfLines: 1
      }, defaultLabel));
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(props, state) {
      var _props$source = props.source,
          source = _props$source === void 0 ? {} : _props$source;

      if (source.uri) {
        return {
          hasImage: true
        };
      } else {
        return null;
      }
    }
  }]);
  return AvatarImageInternal;
}(React.Component);

exports.AvatarImageInternal = AvatarImageInternal;
AvatarImageInternal.displayName = 'Avatar.Image';
AvatarImageInternal.defaultProps = {
  defaultLabel: '',
  shape: 'round',
  size: 64
};

var styles = _reactNative.StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  textAlign: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

var _default = (0, _reactNativePaper.withTheme)(AvatarImageInternal);

exports.default = _default;