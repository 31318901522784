var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _baseSelect = _interopRequireDefault(require("../../../../theme/components/inputs/baseSelect.web"));

var _reactFeather = require("react-feather");

var _reactRedux = require("react-redux");

var _selectors = require("../../../../core/event-stages/selectors");

var _reactNative = require("react-native");

var _queryParameter = require("../../../../utils/queryParameter");

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _NavigationService = _interopRequireDefault(require("../../../../navigation/NavigationService"));

var _selectors2 = require("../../../../core/user-roles/selectors");

var _intersection = _interopRequireDefault(require("lodash/intersection"));

var _featureNamesConstants = require("../../../../constants/feature-names-constants");

var _logger = _interopRequireDefault(require("../../../../core/services/logger"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _reactUse = require("react-use");

var _sumBy = _interopRequireDefault(require("lodash/sumBy"));

var _core = require("@material-ui/core");

var LOBBY_ID = 'lobby';

var LOBBY_LABEL = _i18n.default.t("header.lobbyPage");

var ALLOWED_FEATURES = [_featureNamesConstants.SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT, _featureNamesConstants.SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_STAGE, _featureNamesConstants.SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_SESSION];

var BackstageSelectorContainer = function BackstageSelectorContainer(_ref) {
  var onChange = _ref.onChange;

  var _useLocation = (0, _reactUse.useLocation)(),
      href = _useLocation.href;

  var _useState = (0, _react.useState)((0, _queryParameter.getBackstageIdParam)(href) || LOBBY_ID),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      selectedBackstageId = _useState2[0],
      setSelectedBackstageId = _useState2[1];

  var backstages = (0, _reactRedux.useSelector)(_selectors.selectEventStagesList);
  var hasAccess = (0, _reactRedux.useSelector)((0, _selectors2.hasUserFeatureGlobalAccessSelector)(ALLOWED_FEATURES));
  var featuresByBackstage = (0, _reactRedux.useSelector)(_selectors2.userResourceFeaturesSelector);
  var hasFeatureAccess = Boolean((0, _sumBy.default)(Object.values(featuresByBackstage || {}), function (features) {
    return features.length;
  }));
  var backstagesLoading = (0, _reactRedux.useSelector)(_selectors.selectEventStagesLoading);
  var userFeaturesLoading = (0, _reactRedux.useSelector)(_selectors2.userRolesLoadingSelector);
  var backstagesWithAccess = backstages.filter(function (stage) {
    return (0, _intersection.default)(featuresByBackstage[stage.id] || [], ALLOWED_FEATURES).length || hasAccess;
  });

  _react.default.useEffect(function () {
    _logger.default.debug("Changed ".concat(href));

    setSelectedBackstageId((0, _queryParameter.getBackstageIdParam)(href) || LOBBY_ID);
  }, [href, setSelectedBackstageId]);

  var onSelect = function onSelect(backstage) {
    setSelectedBackstageId(backstage.id);

    if (backstage.id === LOBBY_ID) {
      onChange('');

      _NavigationService.default.navigateExtended(_routesConsts.Routes.LOBBY);

      onChange('');
    } else {
      onChange(selectedBackstageId);

      _NavigationService.default.navigateExtended(_routesConsts.Routes.BACKSTAGE, {
        backstageId: backstage.id
      });

      onChange(selectedBackstageId);
    }
  };

  if (backstagesLoading || userFeaturesLoading) {
    return _react.default.createElement(_reactNative.ActivityIndicator, {
      size: "small"
    });
  }

  if (!hasAccess && !hasFeatureAccess) {
    return null;
  }

  if (backstagesWithAccess.length < 1) return null;
  var lobbyOption = {
    id: LOBBY_ID,
    disabled: false,
    value: LOBBY_LABEL
  };
  return _react.default.createElement(_core.Box, {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    flexDirection: "column",
    flex: "1"
  }, _react.default.createElement(_baseSelect.default, {
    style: {
      border: 'none',
      cursor: 'pointer',
      paddingHorizontal: 10
    },
    rightIcon: _react.default.createElement(_reactFeather.ChevronDown, null),
    options: [lobbyOption].concat((0, _toConsumableArray2.default)(backstagesWithAccess.map(function (stage) {
      return {
        id: stage.id,
        disabled: stage.disabled,
        value: "".concat(stage.name, " - Backstage")
      };
    }))),
    value: selectedBackstageId,
    onChange: function onChange(id) {
      var selected = backstages.filter(function (stage) {
        return stage.id === id;
      });

      if (selected && selected[0]) {
        onSelect(selected[0]);
      } else {
        onSelect(lobbyOption);
      }
    },
    name: "backstageOptions",
    hasEmptyOption: false,
    color: "alternativeText"
  }));
};

var _default = BackstageSelectorContainer;
exports.default = _default;