var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _lodash = _interopRequireDefault(require("lodash"));

var _sprintfJs = require("sprintf-js");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var DEFAULT_BG_COLOR = '#FAB913';
var DEFAULT_TIME_TXT_COLOR = '#000';
var DEFAULT_DIGIT_TXT_COLOR = '#000';
var DEFAULT_TIME_TO_SHOW = ['D', 'H', 'M', 'S'];
var DEFAULT_DIGIT_SEPARATOR = ':';

var CountDown = function (_React$Component) {
  (0, _inherits2.default)(CountDown, _React$Component);

  var _super = _createSuper(CountDown);

  function CountDown() {
    var _this;

    (0, _classCallCheck2.default)(this, CountDown);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      until: Math.max(_this.props.until, 0),
      wentBackgroundAt: 0
    };
    _this.timer = undefined;
    _this._onFinishWrapper = undefined;

    _this._handleAppStateChange = function (currentAppState) {
      var _this$state = _this.state,
          until = _this$state.until,
          wentBackgroundAt = _this$state.wentBackgroundAt;

      if (currentAppState === 'active' && wentBackgroundAt) {
        var diff = (Date.now() - wentBackgroundAt) / 1000.0;

        _this.setState({
          until: Math.max(0, until - diff)
        });

        _this.timer = setInterval(_this.updateTimer, 1000);
      }

      if (currentAppState === 'background') {
        _this.setState({
          wentBackgroundAt: Date.now()
        });

        clearInterval(_this.timer);
      }
    };

    _this.getTimeLeft = function () {
      var until = _this.state.until;
      return {
        seconds: until % 60,
        minutes: parseInt(until / 60, 10) % 60,
        hours: parseInt(until / (60 * 60), 10) % 24,
        days: parseInt(until / (60 * 60 * 24), 10)
      };
    };

    _this.updateTimer = function () {
      var until = _this.state.until;

      if (until <= 1) {
        clearInterval(_this.timer);

        _this.setState({
          until: 0
        });

        if (_this._onFinishWrapper) {
          _this._onFinishWrapper();
        }
      } else {
        _this.setState({
          until: until - 1
        });
      }
    };

    _this.renderDigit = function (d) {
      var _this$props = _this.props,
          digitBgColor = _this$props.digitBgColor,
          digitTxtColor = _this$props.digitTxtColor,
          size = _this$props.size;
      return _react.default.createElement(_reactNative.View, {
        style: [styles.digitCont, {
          backgroundColor: digitBgColor
        }]
      }, _react.default.createElement(_reactNative.Text, {
        style: [styles.digitTxt, {
          fontSize: size
        }, {
          fontWeight: 'normal'
        }, {
          color: digitTxtColor
        }]
      }, d));
    };

    _this.renderDigitSeparator = function (digitSeparator) {
      var _this$props2 = _this.props,
          digitTxtColor = _this$props2.digitTxtColor,
          size = _this$props2.size;
      return _react.default.createElement(_reactNative.View, {
        style: [styles.digitCont, {
          height: 42
        }]
      }, _react.default.createElement(_reactNative.Text, {
        style: [styles.digitTxt, {
          fontSize: size
        }, {
          color: digitTxtColor
        }]
      }, digitSeparator));
    };

    _this.renderDoubleDigits = function (label, digits) {
      var _this$props3 = _this.props,
          timeTxtColor = _this$props3.timeTxtColor,
          size = _this$props3.size;
      return _react.default.createElement(_reactNative.View, {
        style: styles.doubleDigitCont
      }, _react.default.createElement(_reactNative.View, {
        style: styles.timeInnerCont
      }, _this.renderDigit(digits)), _react.default.createElement(_reactNative.Text, {
        style: [styles.timeTxt, {
          fontSize: size
        }, {
          color: timeTxtColor
        }]
      }, label));
    };

    _this.renderCountDown = function () {
      var timeToShow = _this.props.timeToShow;

      var _this$getTimeLeft = _this.getTimeLeft(),
          days = _this$getTimeLeft.days,
          hours = _this$getTimeLeft.hours,
          minutes = _this$getTimeLeft.minutes,
          seconds = _this$getTimeLeft.seconds;

      var newTime = (0, _sprintfJs.sprintf)('%02d:%02d:%02d:%02d', days, hours, minutes, seconds).split(':');
      return _react.default.createElement(_reactNative.View, {
        style: styles.timeCont
      }, _lodash.default.includes(timeToShow, 'D') ? _react.default.createElement(_react.default.Fragment, null, _this.renderDoubleDigits(_this.props.labelD, newTime[0])) : null, _lodash.default.includes(timeToShow, 'H') ? _react.default.createElement(_react.default.Fragment, null, _this.renderDoubleDigits(_this.props.labelH, newTime[1])) : null, _lodash.default.includes(timeToShow, 'M') ? _react.default.createElement(_react.default.Fragment, null, _this.renderDoubleDigits(_this.props.labelM, newTime[2])) : null, _lodash.default.includes(timeToShow, 'S') ? _this.renderDoubleDigits(_this.props.labelS, newTime[3]) : null);
    };

    return _this;
  }

  (0, _createClass2.default)(CountDown, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.onFinish) {
        this._onFinishWrapper = _lodash.default.once(this.props.onFinish);
      }

      this.timer = setInterval(this.updateTimer, 1000);

      _reactNative.AppState.addEventListener('change', this._handleAppStateChange);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      clearInterval(this.timer);

      _reactNative.AppState.removeEventListener('change', this._handleAppStateChange);
    }
  }, {
    key: "render",
    value: function render() {
      return _react.default.createElement(_reactNative.View, {
        style: this.props.style
      }, this.state.until > 0 || !this.props.timeElapsedMessage ? this.renderCountDown() : _react.default.createElement(_reactNative.Text, {
        style: [styles.timeTxt, {
          fontSize: this.props.size
        }, {
          color: this.props.timeTxtColor
        }, this.props.timeElapsedStyle]
      }, this.props.timeElapsedMessage));
    }
  }]);
  return CountDown;
}(_react.default.Component);

CountDown.defaultProps = {
  digitBgColor: DEFAULT_BG_COLOR,
  digitTxtColor: DEFAULT_DIGIT_TXT_COLOR,
  timeTxtColor: DEFAULT_TIME_TXT_COLOR,
  timeToShow: DEFAULT_TIME_TO_SHOW,
  digitSeparator: DEFAULT_DIGIT_SEPARATOR,
  labelD: 'Days',
  labelH: 'Hours',
  labelM: 'Minutes',
  labelS: 'Seconds',
  until: 0,
  size: 15
};

var styles = _reactNative.StyleSheet.create({
  timeCont: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  timeTxt: {
    color: 'white',
    marginVertical: 2,
    backgroundColor: 'transparent'
  },
  timeInnerCont: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  digitCont: {
    borderRadius: 5,
    marginRight: 2,
    alignItems: 'center',
    justifyContent: 'center'
  },
  doubleDigitCont: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 6
  },
  digitTxt: {
    color: 'white',
    fontWeight: 'normal',
    fontVariant: ['tabular-nums']
  }
});

var _default = CountDown;
exports.default = _default;