var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../core/event-info/selectors");

var _selectors2 = require("../../core/event-stages/selectors");

var _selectors3 = require("../../core/session/selectors");

var _selectors4 = require("../../core/tickets/selectors");

var _routesConsts = require("../../navigation/client/routes-consts");

var _useNavigationExtended = _interopRequireDefault(require("../../utils/hooks/useNavigationExtended"));

var _sessionsCalendarView = _interopRequireDefault(require("./sessions-calendar-view"));

var _videoTypeConstants = require("../../constants/video-type-constants");

var _moment = _interopRequireDefault(require("moment"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var SessionsCalendarContainer = function SessionsCalendarContainer(_ref) {
  var _ref$improvedWithPrev = _ref.improvedWithPreview,
      improvedWithPreview = _ref$improvedWithPrev === void 0 ? false : _ref$improvedWithPrev,
      _ref$showJoinLiveButt = _ref.showJoinLiveButton,
      showJoinLiveButton = _ref$showJoinLiveButt === void 0 ? true : _ref$showJoinLiveButt,
      _ref$showFullSchedule = _ref.showFullSchedule,
      showFullSchedule = _ref$showFullSchedule === void 0 ? true : _ref$showFullSchedule;
  var navigation = (0, _useNavigationExtended.default)();
  var sessions = (0, _reactRedux.useSelector)(_selectors3.selectSessionsWithSpeakers, _reactRedux.shallowEqual);
  var stages = (0, _reactRedux.useSelector)(_selectors2.selectEventStagesList, _reactRedux.shallowEqual);
  var eventInfo = (0, _reactRedux.useSelector)(_selectors.selectEventInfo);
  var hasTicket = (0, _reactRedux.useSelector)(_selectors4.userHasTicketsForEvent, _reactRedux.shallowEqual);
  var sessionsFromNow = sessions.filter(function (session) {
    return (0, _moment.default)(session.start).isAfter((0, _moment.default)()) || (0, _moment.default)().isAfter(session.start) && (0, _moment.default)().isBefore(session.end);
  });
  var startDate = (0, _react.useMemo)(function () {
    if (!eventInfo) return new Date();
    return new Date(eventInfo.startDate);
  }, [eventInfo]);
  var endDate = (0, _react.useMemo)(function () {
    if (!eventInfo) return new Date();
    return new Date(eventInfo.endDate);
  }, [eventInfo]);
  var onAvatarNavigation = (0, _react.useCallback)(function (speakerId) {
    return navigation.navigate(_routesConsts.Routes.SPEAKER_PAGE, {
      speakerId: speakerId
    });
  }, [navigation]);
  var onLiveSessionNavigation = (0, _react.useCallback)(function (liveId) {
    return navigation.navigate(_routesConsts.Routes.EVENT_LIVE, {
      liveId: liveId
    });
  }, [navigation]);
  var onNetworkingSessionNavigation = (0, _react.useCallback)(function (tableId) {
    if (tableId === _videoTypeConstants.NETWORKING_AREA) {
      navigation.navigate(_routesConsts.Routes.NETWORKING_PAGE);
    } else {
      navigation.navigate(_routesConsts.Routes.NETWORKING_TABLE_LIVE_PAGE, {
        tableId: tableId
      });
    }
  }, [navigation]);
  return _react.default.createElement(_sessionsCalendarView.default, {
    sessions: showFullSchedule ? sessions : sessionsFromNow,
    stages: stages.map(function (stage) {
      return _objectSpread(_objectSpread({}, stage), {}, {
        sessions: sessions.filter(function (s) {
          return s.resourceId === stage.id;
        })
      });
    }),
    startDate: startDate,
    endDate: endDate,
    onAvatarNavigation: onAvatarNavigation,
    onLiveSessionNavigation: onLiveSessionNavigation,
    onNetworkingSessionNavigation: onNetworkingSessionNavigation,
    hasTicket: hasTicket,
    eventName: eventInfo === null || eventInfo === void 0 ? void 0 : eventInfo.name,
    eventTimezone: eventInfo === null || eventInfo === void 0 ? void 0 : eventInfo.timezone,
    eventDateFormat: eventInfo === null || eventInfo === void 0 ? void 0 : eventInfo.dateFormat,
    improvedWithPreview: improvedWithPreview,
    showJoinLiveButton: showJoinLiveButton
  });
};

var _default = SessionsCalendarContainer;
exports.default = _default;