var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("../user/actionTypes");

var _actions = require("../user/actions");

var _actions2 = require("./actions");

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var _reactNative = require("react-native");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function createMiddleware(gsSocket) {
  return function (store) {
    var dispatchInitCompleted = function dispatchInitCompleted(deviceId) {
      store.dispatch((0, _actions2.gameSparksInitCompleted)(gsSocket.getLoggedUser(), gsSocket.getAuthToken(), deviceId));
    };

    var getSetupConfig = function getSetupConfig(options) {
      var opts = _objectSpread({
        onError: function onError(err) {
          return store.dispatch((0, _actions2.setGsError)(err));
        }
      }, options);

      return opts;
    };

    return function (next) {
      return function () {
        var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(action) {
          var result;
          return _regenerator.default.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  result = next(action);
                  _context.t0 = action.type;
                  _context.next = _context.t0 === _actionTypes.USER_UNAUTHENTICATED ? 4 : _context.t0 === _actionTypes.LOGIN_COMPLETED ? 5 : _context.t0 === _actionTypes.USER_LOGOUT ? 6 : 7;
                  break;

                case 4:
                  return _context.abrupt("break", 8);

                case 5:
                  return _context.abrupt("break", 8);

                case 6:
                  return _context.abrupt("break", 8);

                case 7:
                  return _context.abrupt("break", 8);

                case 8:
                  return _context.abrupt("return", result);

                case 9:
                case "end":
                  return _context.stop();
              }
            }
          }, _callee);
        }));

        return function (_x) {
          return _ref.apply(this, arguments);
        };
      }();
    };
  };
}