var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Response;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _UserSection = _interopRequireDefault(require("./UserSection"));

var _selectors = require("../../../../core/event-info/selectors");

var _reactRedux = require("react-redux");

var _actions = require("../../../../core/QAndA/actions");

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    responseContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    message: {
      fontSize: 17,
      textAlign: 'start',
      marginLeft: 50
    }
  };
});

function Response(_ref) {
  var questionId = _ref.questionId,
      answer = _ref.answer,
      isAdmin = _ref.isAdmin,
      userInfo = _ref.userInfo,
      isLast = _ref.isLast;
  var classes = useStyles();
  var dispatch = (0, _reactRedux.useDispatch)();

  var handlePin = function handlePin() {
    dispatch((0, _actions.togglePinAnswer)({
      questionId: questionId,
      answerId: answer.id,
      pinState: answer.isPinned
    }));
  };

  var handleDelete = function handleDelete() {
    dispatch((0, _actions.deleteAnswer)({
      questionId: questionId,
      answerId: answer.id
    }));
  };

  var createdBy = (0, _reactRedux.useSelector)(_selectors.eventCreatorIdSelector);
  return _react.default.createElement(_core.Box, {
    mt: 1,
    mb: 2
  }, _react.default.createElement(_core.Box, {
    className: classes.responseContainer,
    mx: 1
  }, _react.default.createElement(_UserSection.default, {
    createdByAdmin: answer.createdBy === createdBy,
    createdAt: answer.createdAt,
    userInfo: userInfo,
    isPinned: answer.isPinned,
    isAdmin: isAdmin,
    onPin: handlePin,
    onDelete: handleDelete
  })), _react.default.createElement(_core.Box, {
    className: classes.message,
    mt: 1,
    mx: 1
  }, _react.default.createElement(_core.Typography, null, answer.message)), !isLast && _react.default.createElement(_core.Box, {
    my: 2
  }, _react.default.createElement(_core.Divider, null)));
}