var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createSocket;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _socket = _interopRequireDefault(require("socket.io-client"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function createSocket(host) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var socket = (0, _socket.default)(host, (0, _extends2.default)({
    autoConnect: false,
    transports: ['websocket'],
    secure: true
  }, options));

  socket.setupConfig = function (opts) {
    socket.io.opts = _objectSpread(_objectSpread({}, socket.io.opts), opts);
  };

  socket.asyncDisconnect = function () {
    var _this = this;

    return new Promise(function (resolve) {
      _this.once('disconnect', resolve);

      _this.disconnect();
    });
  };

  socket.asyncEmit = function (event) {
    var _this2 = this;

    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return new Promise(function (resolve, reject) {
      _this2.emit(event, params, function (_ref) {
        var data = _ref.data,
            error = _ref.error;

        if (data) {
          resolve(data);
        }

        if (error) {
          reject(error);
        }
      });
    });
  };

  socket.asyncConnect = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
    var _this3 = this;

    var params,
        _args = arguments;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            params = _args.length > 0 && _args[0] !== undefined ? _args[0] : {};
            _context.next = 3;
            return new Promise(function (resolve, reject) {
              if (_this3.connected) {
                reject({
                  reason: 'already_connected'
                });
              }

              if (_this3.connecting) {
                reject({
                  reason: 'already_connecting'
                });
              }

              _this3.connecting = true;

              _this3.once('connect', function () {
                _this3.connecting = false;
                resolve();
              });

              _this3.once('connect_error', function (err) {
                _this3.connecting = false;
                reject(err);
              });

              _this3.once('connect_timeout', function () {
                _this3.connecting = false;
                reject({
                  reason: 'timeout',
                  error: null
                });
              });

              _this3.io.opts.query = params;

              _this3.connect();
            });

          case 3:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));
  return socket;
}