var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = URLImage;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

var _canvasUtils = require("../../../../utils/canvas-utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function URLImage(_ref) {
  var src = _ref.src,
      width = _ref.width,
      height = _ref.height,
      x = _ref.x,
      y = _ref.y,
      _ref$objectFit = _ref.objectFit,
      objectFit = _ref$objectFit === void 0 ? 'center-middle' : _ref$objectFit;

  var _React$useState = _react.default.useState({
    width: width,
    height: height,
    x: x,
    y: y
  }),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      size = _React$useState2[0],
      setSize = _React$useState2[1];

  var _React$useState3 = _react.default.useState(false),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      loaded = _React$useState4[0],
      setLoaded = _React$useState4[1];

  var image = _react.default.useMemo(function () {
    var _image = document.createElement('img');

    _image.crossOrigin = 'Anonymous';
    return _image;
  }, []);

  _react.default.useEffect(function () {
    setSize((0, _canvasUtils.alignImage)(image, {
      width: width,
      height: height
    }, objectFit));

    var handleImageLoad = function handleImageLoad() {
      setSize((0, _canvasUtils.alignImage)(image, {
        width: width,
        height: height
      }, objectFit));
      setLoaded(true);
    };

    image.addEventListener('load', handleImageLoad);
    return function () {
      image.removeEventListener('load', handleImageLoad);
    };
  }, [width, height, objectFit, image, setLoaded]);

  _react.default.useEffect(function () {
    image.src = src || '';
  }, [src, image]);

  if (!loaded) return null;
  return _react.default.createElement(_reactKonva.Image, {
    fill: "#fff",
    width: width,
    height: height,
    crop: _objectSpread({}, size),
    image: image
  });
}