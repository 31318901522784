var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EmailLoginView;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _formik = require("formik");

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var yup = _interopRequireWildcard(require("yup"));

var _eventLogo = _interopRequireDefault(require("../../common/event-logo"));

var _messageBlock = _interopRequireDefault(require("../../common/message-block"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _SelectedTicket = _interopRequireDefault(require("../../common/auth/ticket/SelectedTicket"));

var _RegistrationStepper = _interopRequireWildcard(require("../../common/auth/registration-stepper/RegistrationStepper"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _core = require("@material-ui/core");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Input = _interopRequireDefault(require("../../../theme/components/inputs/Input"));

var _reactFeather = require("react-feather");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _authStyles = _interopRequireDefault(require("../auth-styles"));

var _authOptions = require("../auth-options");

var _reactRedux = require("react-redux");

var _DesignSystem = require("../../../theme/DesignSystem");

var _styles = _interopRequireDefault(require("./styles"));

var _MuiLinkButton = _interopRequireDefault(require("./../../common/custom-design-components/MuiLinkButton"));

var _selectors = require("../../../core/checkout/selectors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function EmailLoginView(_ref) {
  var checkoutIntent = _ref.checkoutIntent,
      onLogin = _ref.onLogin,
      goToRegister = _ref.goToRegister,
      goToResetPassword = _ref.goToResetPassword,
      isLoading = _ref.isLoading,
      isLoginMailSent = _ref.isLoginMailSent,
      loginFeedback = _ref.loginFeedback,
      isRequesting = _ref.isRequesting;
  var Styles = (0, _authStyles.default)();
  var classes = (0, _styles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var hash = window.location.hash.substr(1);

  var _useState = (0, _react.useState)(_authOptions.AVAILABLE_AUTH_OPTIONS.includes(hash) ? hash : _authOptions.EMAIL_AUTH),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      loginType = _useState2[0],
      setLoginType = _useState2[1];

  var tickets = (0, _reactRedux.useSelector)(_selectors.validTicketsSelector);

  var getLoginButton = function getLoginButton() {
    return loginType === _authOptions.EMAIL_PASS_AUTH ? _i18n.default.t('login.loginButton') : _i18n.default.t('login.sendLoginEmailButton');
  };

  var disableButton = isLoading || isRequesting || isLoginMailSent;
  var showRegisterButton = tickets.length >= 1;
  return _react.default.createElement(_RegistrationStepper.default, {
    type: _RegistrationStepper.stepperType.login,
    children: function children(_ref2) {
      var Stepper = _ref2.Stepper;
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Box, {
        className: classes.logo
      }, _react.default.createElement(_eventLogo.default, null)), isLoginMailSent && _react.default.createElement(_core.Box, {
        component: _core.Paper,
        elevation: 0,
        className: classes.loginMailSent,
        m: 2,
        py: 1,
        px: 3
      }, _react.default.createElement(_core.Typography, {
        variant: "caption",
        align: "center",
        className: classes.textWhite
      }, _i18n.default.translate('login.emailWithLoginUrlSent'))), !!Stepper && Stepper, _react.default.createElement(_core.Box, {
        className: classes.container,
        mb: 8
      }, _react.default.createElement(_core.Card, {
        elevation: 0,
        className: classes.bgWhite
      }, _react.default.createElement(_core.Box, {
        py: 5,
        px: 8,
        component: _core.Paper,
        elevation: 0
      }, loginFeedback && _react.default.createElement(_core.Box, {
        mb: 5
      }, _react.default.createElement(_messageBlock.default, {
        level: "warning"
      }, _i18n.default.translate("errors.".concat(loginFeedback), {
        defaultValue: loginFeedback
      }))), _react.default.createElement(_core.Box, {
        mb: 3,
        align: "center"
      }, checkoutIntent ? _react.default.createElement(_SelectedTicket.default, checkoutIntent) : _react.default.createElement(_core.Typography, {
        variant: "h2",
        className: classes.fontWeightNormal
      }, _i18n.default.translate('login.login'))), _react.default.createElement(_formik.Formik, {
        initialValues: {
          email: '',
          password: ''
        },
        onSubmit: function onSubmit(values) {
          return onLogin(_objectSpread(_objectSpread({}, values), {}, {
            email: values.email.trim()
          }), loginType);
        },
        validationSchema: loginType === _authOptions.EMAIL_PASS_AUTH ? yup.object().shape({
          email: yup.string().trim().email(_i18n.default.t('login.emailValid')).required(_i18n.default.t('login.emailRequired')),
          password: yup.string().required(_i18n.default.t('login.passwordRequired'))
        }) : yup.object().shape({
          email: yup.string().trim().email(_i18n.default.t('login.emailValid')).required(_i18n.default.t('login.emailRequired'))
        })
      }, function (_ref3) {
        var values = _ref3.values,
            handleChange = _ref3.handleChange,
            errors = _ref3.errors,
            setFieldTouched = _ref3.setFieldTouched,
            touched = _ref3.touched,
            isValid = _ref3.isValid,
            submitForm = _ref3.submitForm;
        return _react.default.createElement(_react.Fragment, null, _react.default.createElement(_core.Box, {
          mb: 3
        }, _react.default.createElement(_Input.default, {
          shaped: true,
          type: "email",
          color: "alternativeText",
          value: values.email,
          onChange: handleChange,
          onKeyPress: function onKeyPress(_ref4) {
            var key = _ref4.nativeEvent.key;
            return key === 'Enter' && submitForm();
          },
          name: "email",
          returnKeyType: 'next',
          error: !!errors.email && touched.email,
          onBlur: function onBlur() {
            return setFieldTouched('email');
          },
          placeholder: _i18n.default.translate('login.email'),
          leftIcon: _react.default.createElement(_reactFeather.Mail, null)
        }), !!errors.email && touched.email && _react.default.createElement(_core.Box, {
          mt: 1,
          align: "left"
        }, _react.default.createElement(_core.Typography, {
          variant: "caption",
          color: "error"
        }, errors.email))), loginType === _authOptions.EMAIL_PASS_AUTH && _react.default.createElement(_core.Box, {
          mb: 3
        }, _react.default.createElement(_Input.default, {
          shaped: true,
          color: "alternativeText",
          value: values.password,
          onChange: handleChange,
          onKeyPress: function onKeyPress(_ref5) {
            var key = _ref5.nativeEvent.key;
            return key === 'Enter' && submitForm();
          },
          name: "password",
          secureTextEntry: true,
          placeholder: _i18n.default.translate('login.password'),
          returnKeyType: 'next',
          error: !!errors.password && touched.password,
          onBlur: function onBlur() {
            return setFieldTouched('password');
          },
          leftIcon: _react.default.createElement(_reactFeather.Key, null)
        }), !!errors.password && touched.password && _react.default.createElement(_core.Box, {
          mt: 1,
          align: "left"
        }, _react.default.createElement(_core.Typography, {
          variant: "caption",
          color: "error"
        }, errors.password))), _react.default.createElement(_core.Box, {
          mb: 4,
          className: classes.buttonContainer
        }, _react.default.createElement(_ContainedButton.default, {
          disabled: disableButton,
          onPress: function onPress() {
            if (isValid) {
              submitForm();
            }
          }
        }, isRequesting || isLoading ? _react.default.createElement(_reactNative.ActivityIndicator, {
          size: "small",
          color: "#fff"
        }) : getLoginButton())), _react.default.createElement(_core.Box, {
          my: 2,
          className: classes.textContainer
        }, loginType === _authOptions.EMAIL_PASS_AUTH ? _react.default.createElement(_core.Typography, {
          variant: "caption",
          align: "center"
        }, _i18n.default.translate('login.emailPassText'), _react.default.createElement(_MuiLinkButton.default, {
          translationKey: "login.emailPassTextLink",
          onClick: function onClick() {
            return setLoginType(_authOptions.EMAIL_AUTH);
          },
          variant: "caption"
        })) : _react.default.createElement(_core.Typography, {
          variant: "caption",
          align: "center"
        }, _i18n.default.translate('login.emailOnlyText'), _react.default.createElement(_MuiLinkButton.default, {
          translationKey: "login.emailOnlyTextLink",
          onClick: function onClick() {
            return setLoginType(_authOptions.EMAIL_PASS_AUTH);
          },
          variant: "caption"
        }))));
      }))), _react.default.createElement(_core.Box, {
        my: 3,
        className: classes.navLinks
      }, loginType === _authOptions.EMAIL_PASS_AUTH && _react.default.createElement(_core.Box, {
        my: 1
      }, _react.default.createElement(_MuiLinkButton.default, {
        translationKey: "login.forgotPassword",
        onClick: goToResetPassword,
        variant: "caption"
      })), showRegisterButton && _react.default.createElement(_core.Box, {
        my: 1
      }, _react.default.createElement(_MuiLinkButton.default, {
        translationKey: "login.createNewAccount",
        onClick: goToRegister,
        variant: "caption"
      })))));
    }
  });
}