module.exports = {
  JOIN_BACKSTAGE: 'joinBackstage',
  JOIN_BACKSTAGE_BY_STAGE: 'joinBackstageByStage',
  JOIN_BACKSTAGE_BY_SESSION: 'joinBackstageBySession',
  LEAVE_BACKSTAGE: 'leaveBackstage',
  LEAVE_BACKSTAGE_LIVE: 'leaveBackstageLive',
  JOIN_BACKSTAGE_LIVE_REQUEST: 'joinBackstageLiveRequest',
  JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW: 'joinBackstageLiveRequestWithAllow',
  JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW_BY_STAGE: 'joinBackstageLiveRequestWithAllowByStage',
  JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW_BY_SESSION: 'joinBackstageLiveRequestWithAllowBySession',
  KICK_OUT_SPEAKER_FROM_LIVE: 'kickOutSpeakerFromLive',
  KICK_OUT_SPEAKER_FROM_LIVE_BY_STAGE: 'kickOutSpeakerFromLiveByStage',
  KICK_OUT_SPEAKER_FROM_LIVE_BY_SESSION: 'kickOutSpeakerFromLiveBySession',
  ALLOW_JOIN_LIVE_REQUEST: 'allowJoinLiveRequest',
  ALLOW_JOIN_LIVE_REQUEST_BY_STAGE: 'allowJoinLiveRequestByStage',
  ALLOW_JOIN_LIVE_REQUEST_BY_SESSION: 'allowJoinLiveRequestBySession',
  START_STOP_LIVE_SESSION: 'startStopLiveSession',
  START_STOP_LIVE_SESSION_BY_STAGE: 'startStopLiveSessionByStage',
  START_STOP_LIVE_SESSION_BY_SESSION: 'startStopLiveSessionBySession',
  SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS: 'showJoinLiveRequestUiComponents',
  SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_STAGE: 'showJoinLiveRequestUiComponentsByStage',
  SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_SESSION: 'showJoinLiveRequestUiComponentsBySession',
  SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS: 'showKickOutSpeakerFromLiveUiComponents',
  SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_STAGE: 'showKickOutSpeakerFromLiveUiComponentsByStage',
  SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_SESSION: 'showKickOutSpeakerFromLiveUiComponentsBySession',
  DECLINE_JOIN_LIVE_REQUEST: 'declineJoinLiveRequest',
  CANCEL_JOIN_LIVE_REQUEST: 'cancelJoinLiveRequest',
  DECLINE_JOIN_LIVE_REQUEST_BY_STAGE: 'declineJoinLiveRequestByStage',
  DECLINE_JOIN_LIVE_REQUEST_BY_SESSION: 'declineJoinLiveRequestBySession',
  SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT: 'showBackstageSelectorUiComponent',
  SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_STAGE: 'showBackstageSelectorUiComponentByStage',
  SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_SESSION: 'showBackstageSelectorUiComponentBySession',
  MUTE_SPEAKER: 'muteSpeaker',
  JOIN_RESTRICTED_NETWORKING_TABLE: 'joinRestrictedNetworkingTable',
  JOIN_RESTRICTED_NETWORKING_TABLE_BY_TABLE: 'joinRestrictedNetworkingTableByTable',
  ALLOW_RESTRICTED_NETWORKING_TABLE_REQUEST: 'allowRestrictedNetworkingTableRequest',
  DENY_RESTRICTED_NETWORKING_TABLE_REQUEST: 'denyRestrictedNetworkingTableRequest',
  KICK_PARTICIPANT_FROM_NETWORKING_TABLE: 'kickParticipantFromNetworkingTable',
  START_STOP_BREAKOUT_ROOMS: 'startStopBreakoutRooms'
};