var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.skipLogin = skipLogin;
exports.userIsUnauthenticated = userIsUnauthenticated;
exports.hasIncompleteProfile = hasIncompleteProfile;
exports.getLDVariationsWithoutUser = getLDVariationsWithoutUser;
exports.loginInProgress = loginInProgress;
exports.loginWithProvider = loginWithProvider;
exports.loginWithAccessToken = loginWithAccessToken;
exports.loginCompleted = loginCompleted;
exports.userLogout = userLogout;
exports.ticketPaymentProcessCompleted = ticketPaymentProcessCompleted;
exports.firebaseLoginCompleted = firebaseLoginCompleted;
exports.firebaseLogin = firebaseLogin;
exports.firebaseEmailLogin = firebaseEmailLogin;
exports.startFirebaseAuthListening = startFirebaseAuthListening;
exports.updateFirebaseUserProfile = updateFirebaseUserProfile;
exports.trackUserActivity = trackUserActivity;
exports.stopAuthStateLoading = exports.firebaseAuthStateChangedInProgress = exports.firebaseAuthStateChanged = exports.userRolesFound = exports.firebaseLoginMailSent = exports.firebaseLoginInProgress = exports.firebaseUserDoesNotExistError = exports.firebaseLoginError = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var Platform = _interopRequireWildcard(require("react-native"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _package = require("../../../package.json");

var _routesConsts = require("../../navigation/client/routes-consts");

var _NavigationService = _interopRequireDefault(require("../../navigation/NavigationService"));

var _actions = require("../chat/actions");

var _actions2 = require("../checkout/actions");

var _selectors = require("../event-info/selectors");

var _flags = _interopRequireDefault(require("../flags"));

var _Analytics = require("../services/Analytics");

var _actions3 = require("../tickets/actions");

var _user = _interopRequireDefault(require("../user"));

var _actionTypes = require("./actionTypes");

var _constants = require("./constants");

var _selectors2 = require("../chat/selectors");

var _actions4 = require("../user-profile/actions");

var _authOptions = require("../../components/auth/auth-options");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function skipLogin() {
  return {
    type: _actionTypes.SKIP_LOGIN,
    skipLogin: true
  };
}

function userIsUnauthenticated() {
  return {
    type: _actionTypes.USER_UNAUTHENTICATED
  };
}

function hasIncompleteProfile(value) {
  return {
    type: _actionTypes.USER_INCOMPLETE_PROFILE,
    hasIncompleteProfile: value
  };
}

function getLDVariationsWithoutUser() {
  return function (dispatch) {
    dispatch(_flags.default.actions.getLDVariations());
  };
}

function loginInProgress() {
  return {
    type: _actionTypes.LOGIN_IN_PROGRESS
  };
}

function loginWithProvider(provider, providerAccessToken, userName) {
  return function (dispatch, getState, _ref) {
    var api = _ref.api;
    dispatch(loginInProgress());
    return api.post({
      action: "/soundmix_users/auth/".concat(provider),
      data: {
        accessToken: providerAccessToken,
        name: userName || '',
        app: process.env.ENVIRONMENT
      }
    }).then(function (_ref2) {
      var loginResponse = _ref2.data;
      return api.get({
        action: "/soundmix_users/me?appVersion=".concat(_package.version),
        headers: {
          Authorization: "Bearer ".concat(loginResponse.accessToken)
        }
      }).then(function (_ref3) {
        var userInfo = _ref3.data;
        (0, _Analytics.trackEvent)('login_completed', {
          provider: provider
        });
        dispatch(loginCompleted(loginResponse.accessToken, userInfo));
      });
    });
  };
}

function loginWithAccessToken(accessToken) {
  return function (dispatch, getState, _ref4) {
    var api = _ref4.api;
    dispatch(loginInProgress());
    return api.get({
      action: "/soundmix_users/me?appVersion=".concat(_package.version),
      headers: {
        Authorization: "Bearer ".concat(accessToken)
      },
      userInitiated: false
    }).then(function (_ref5) {
      var userInfo = _ref5.data;
      dispatch(loginCompleted(accessToken, userInfo));
    });
  };
}

function loginCompleted(accessToken, user) {
  return {
    type: _actionTypes.LOGIN_COMPLETED,
    accessToken: accessToken,
    user: user
  };
}

function userLogout(noRedirect) {
  return function (dispatch, getState) {
    dispatch({
      type: _actionTypes.START_USER_LOGOUT
    });

    _reactNativeFirebase.default.auth().signOut().then(function () {
      if (Platform.OS === 'web') {
        if (!noRedirect) {
          _NavigationService.default.resetNavigation(_routesConsts.Routes.HOME, {
            eventId: (0, _selectors.selectEventInfoId)(getState())
          });
        }
      }

      dispatch({
        type: _actionTypes.USER_LOGOUT
      });
    });

    dispatch((0, _actions.sendDisconnect)());
  };
}

function ticketPaymentProcessCompleted(user) {
  return {
    type: _actionTypes.PAYMENT_TICKET_COMPLETED,
    user: user
  };
}

function firebaseLoginCompleted(firebaseUser) {
  return {
    type: _actionTypes.FIREBASE_LOGIN_COMPLETED,
    user: firebaseUser
  };
}

var firebaseLoginError = function firebaseLoginError(error) {
  return {
    type: _actionTypes.FIREBASE_LOGIN_FAILED,
    error: error
  };
};

exports.firebaseLoginError = firebaseLoginError;

var firebaseUserDoesNotExistError = function firebaseUserDoesNotExistError() {
  return {
    type: _constants.FIREBASE_USER_DOES_NOT_EXIST_ERROR,
    error: 'auth/user-not-found'
  };
};

exports.firebaseUserDoesNotExistError = firebaseUserDoesNotExistError;

var firebaseLoginInProgress = function firebaseLoginInProgress() {
  return {
    type: _actionTypes.FIREBASE_LOGIN_IN_PROGRESS
  };
};

exports.firebaseLoginInProgress = firebaseLoginInProgress;

var firebaseLoginMailSent = function firebaseLoginMailSent() {
  return {
    type: _actionTypes.FIREBASE_LOGIN_MAIL_SENT
  };
};

exports.firebaseLoginMailSent = firebaseLoginMailSent;

var userRolesFound = function userRolesFound(userRoles) {
  return {
    type: _actionTypes.ASSIGNED_USER_ROLES,
    roles: userRoles
  };
};

exports.userRolesFound = userRolesFound;

function firebaseLogin(credentials, eventId, loginType) {
  if (loginType === _authOptions.EMAIL_PASS_AUTH) {
    return function (dispatch) {
      dispatch(firebaseLoginInProgress());

      _reactNativeFirebase.default.auth().signInWithEmailAndPassword(credentials.email, credentials.password).then(function (userCredential) {
        dispatch(firebaseLoginCompleted(userCredential.user.toJSON()));
        dispatch((0, _actions3.getTickets)(userCredential.user.uid)).then(function () {
          dispatch((0, _actions2.finishCheckout)());
        });
        dispatch((0, _actions4.getUserProfile)());
      }).catch(function (error) {
        return dispatch(firebaseLoginError(error));
      });
    };
  } else {
    return function () {
      var _ref6 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(dispatch) {
        var _yield$firebase$funct, userExists, sendTheEmailWithLoginLink, urlParams, redirectLocation;

        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return _reactNativeFirebase.default.functions.httpsCallable('auth-registration-emailAlreadyExists')({
                  email: credentials.email
                });

              case 2:
                _yield$firebase$funct = _context.sent;
                userExists = _yield$firebase$funct.data;

                if (userExists) {
                  _context.next = 7;
                  break;
                }

                dispatch(firebaseUserDoesNotExistError());
                return _context.abrupt("return", false);

              case 7:
                dispatch(firebaseLoginInProgress());
                sendTheEmailWithLoginLink = _reactNativeFirebase.default.functions.httpsCallable('emails-sendTheEmailWithLoginLink');
                urlParams = new URLSearchParams(window.location.search);
                redirectLocation = urlParams.get('redirectTo');
                _context.next = 13;
                return sendTheEmailWithLoginLink({
                  email: credentials.email,
                  eventId: eventId,
                  redirectTo: redirectLocation || null
                });

              case 13:
                window.localStorage.setItem('emailForSignIn', credentials.email);
                dispatch(firebaseLoginMailSent());
                return _context.abrupt("return", true);

              case 16:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      return function (_x) {
        return _ref6.apply(this, arguments);
      };
    }();
  }
}

function firebaseEmailLogin(email) {
  return function (dispatch) {
    dispatch(firebaseLoginInProgress());

    _reactNativeFirebase.default.auth().signInWithEmailLink(email, window.location.href).then(function (userCredential) {
      dispatch(firebaseLoginCompleted(userCredential.user.toJSON()));
      dispatch((0, _actions3.getTickets)(userCredential.user.uid)).then(function () {
        dispatch((0, _actions2.finishCheckout)());
      });
      dispatch((0, _actions4.getUserProfile)());
    }).catch(function (error) {
      return dispatch(firebaseLoginError(error));
    });
  };
}

function startFirebaseAuthListening() {
  return function (dispatch, getState) {
    var state = getState();

    var isFirebaseUserLoggedIn = _user.default.selectors.isFirebaseUserLoggedIn(state);

    dispatch(firebaseAuthStateChangedInProgress());

    _reactNativeFirebase.default.auth().onIdTokenChanged(function (authState) {
      if (authState) {
        dispatch((0, _actions.sendConnection)());
        dispatch(firebaseAuthStateChanged(authState.toJSON()));
      } else {
        dispatch(stopAuthStateLoading());

        if (isFirebaseUserLoggedIn) {
          dispatch(userLogout(true));
        }
      }
    }, function (err) {
      return dispatch(firebaseAuthStateChangedInProgress());
    });
  };
}

var firebaseAuthStateChanged = function firebaseAuthStateChanged(user) {
  return {
    type: _actionTypes.FIREBASE_AUTH_STATE_CHANGED,
    user: user
  };
};

exports.firebaseAuthStateChanged = firebaseAuthStateChanged;

var firebaseAuthStateChangedInProgress = function firebaseAuthStateChangedInProgress() {
  return {
    type: _actionTypes.FIREBASE_AUTH_STATE_LISTENING
  };
};

exports.firebaseAuthStateChangedInProgress = firebaseAuthStateChangedInProgress;

var stopAuthStateLoading = function stopAuthStateLoading() {
  return {
    type: _actionTypes.STOP_AUTH_STATE_LOADING
  };
};

exports.stopAuthStateLoading = stopAuthStateLoading;

function updateFirebaseUserProfile(profileChanges) {
  return function (dispatch, getState) {
    var state = getState();
    var currentRoom = (0, _selectors2.currentRoomSelector)(state);

    var currentFirebaseUserProfile = _user.default.selectors.getFirebaseUser(state);

    var firebaseUser = {
      displayName: profileChanges.displayName ? profileChanges.displayName : currentFirebaseUserProfile.displayName,
      photoURL: profileChanges.photoURL ? profileChanges.photoURL : currentFirebaseUserProfile.photoURL
    };

    _reactNativeFirebase.default.auth().currentUser.updateProfile(profileChanges).then((0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2() {
      return _regenerator.default.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return _reactNativeFirebase.default.auth().currentUser.getIdToken(true);

            case 2:
              dispatch({
                type: _constants.FIREBASE_USER_PROFILE_UPDATE,
                user: _objectSpread(_objectSpread({}, currentFirebaseUserProfile), firebaseUser)
              });

              if (currentRoom) {
                dispatch((0, _actions.sendDisconnect)());
                dispatch((0, _actions.sendConnection)());
              }

            case 4:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    }))).catch(function (error) {
      console.log('Error', error);
    });
  };
}

function trackUserActivity(_x2) {
  return _trackUserActivity.apply(this, arguments);
}

function _trackUserActivity() {
  _trackUserActivity = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(data) {
    var trackActivity;
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            trackActivity = _reactNativeFirebase.default.functions.httpsCallable('custom-trackUserActivity');
            _context3.next = 3;
            return trackActivity(data);

          case 3:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));
  return _trackUserActivity.apply(this, arguments);
}