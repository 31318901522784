var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _moment = _interopRequireDefault(require("moment"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-info/selectors");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _core = require("@material-ui/core");

var _externalLinkUtils = require("../../../utils/external-link-utils");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _DefaultHTMLrenderer = _interopRequireDefault(require("../DefaultHTMLrenderer"));

var _styles = _interopRequireDefault(require("./styles"));

var _MuiLinkButton = _interopRequireDefault(require("./../../common/custom-design-components/MuiLinkButton"));

var Footer = function Footer() {
  var navigation = (0, _useNavigationExtended.default)();

  var _useSelector = (0, _reactRedux.useSelector)(_selectors.selectEventInfo),
      name = _useSelector.name,
      footer = _useSelector.footer;

  var classes = (0, _styles.default)();
  var copyrightContent = (footer === null || footer === void 0 ? void 0 : footer.copyrightText) ? _react.default.createElement(_DefaultHTMLrenderer.default, {
    source: footer === null || footer === void 0 ? void 0 : footer.copyrightText
  }) : _react.default.createElement("strong", null, "\xA9 ", (0, _moment.default)().year(), " ", name, ".");
  return _react.default.createElement(_core.Box, {
    className: classes.border
  }, _react.default.createElement(_core.Box, {
    my: 1,
    mr: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
    className: classes.footerSpacing
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    alignItems: "baseline",
    my: 1,
    mx: 2,
    className: classes.footerText
  }, _react.default.createElement(_core.Typography, {
    variant: "body1"
  }, copyrightContent), (footer === null || footer === void 0 ? void 0 : footer.showPoweredByEventMix) && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, "\xA0Powered by\xA0"), _react.default.createElement(_MuiLinkButton.default, {
    translationKey: "footer.owner",
    onClick: function onClick() {
      return (0, _externalLinkUtils.openExternalUrl)('http://eventmix.live/');
    },
    variant: "body1"
  }))), _react.default.createElement(_core.Box, {
    display: "flex",
    flexWrap: "wrap",
    my: 1,
    ml: 2,
    className: classes.footerLinks
  }, (footer === null || footer === void 0 ? void 0 : footer.showTermsAndConditions) && _react.default.createElement(_core.Box, {
    display: "flex"
  }, _react.default.createElement(_MuiLinkButton.default, {
    translationKey: "footer.termsAndConditions",
    onClick: function onClick() {
      navigation.navigate(_routesConsts.Routes.TERMS);
    },
    variant: "body1"
  })), (footer === null || footer === void 0 ? void 0 : footer.showPrivacyPolicy) && _react.default.createElement(_core.Box, {
    className: classes.footerPrivacy
  }, _react.default.createElement(_MuiLinkButton.default, {
    translationKey: "footer.privacyPolicy",
    onClick: function onClick() {
      navigation.navigate(_routesConsts.Routes.PRIVACY_POLICY);
    },
    variant: "body1"
  })))));
};

var _default = Footer;
exports.default = _default;