var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EventStateForLive;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-info/selectors");

var _useIsDateBetween = _interopRequireWildcard(require("../../../utils/hooks/useIsDateBetween"));

function EventStateForLive(_ref) {
  var beforeEvent = _ref.beforeEvent,
      runningEvent = _ref.runningEvent,
      afterEvent = _ref.afterEvent,
      loading = _ref.loading;
  var event = (0, _reactRedux.useSelector)(_selectors.selectEventInfo);
  var state = (0, _useIsDateBetween.default)(event.startDate, event.endDate);
  var content = [];

  switch (state) {
    case _useIsDateBetween.STATUS.BEFORE:
      content.push(beforeEvent);
      break;

    case _useIsDateBetween.STATUS.DURING:
      content.push(runningEvent);
      break;

    case _useIsDateBetween.STATUS.AFTER:
      content.push(afterEvent);
      break;

    default:
      content.push(loading);
  }

  return _react.default.createElement(_react.default.Fragment, null, content);
}