Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textUppercase: {
      textTransform: 'uppercase'
    },
    container: {
      marginTop: -theme.spacing(3),
      marginLeft: -theme.spacing(3),
      marginRight: -theme.spacing(3),
      marginBottom: theme.spacing(5),
      alignItems: 'stretch',
      '@media(max-width: 1280px)': {
        justifyContent: 'center'
      },
      '@media(max-width: 600px)': {
        flexDirection: 'column'
      }
    },
    header: {
      paddingBottom: theme.spacing(2)
    }
  };
});
var _default = styles;
exports.default = _default;