var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SimilarBaseInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Title = _interopRequireDefault(require("../../typography_v1/Title"));

var _EventItem = _interopRequireDefault(require("../../../event/EventItem"));

var _LiveEventItem = _interopRequireDefault(require("../../event/LiveEventItem"));

var _constants = require("../../../../core/events/constants");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var SimilarBaseInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(SimilarBaseInternal, _React$PureComponent);

  var _super = _createSuper(SimilarBaseInternal);

  function SimilarBaseInternal() {
    (0, _classCallCheck2.default)(this, SimilarBaseInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(SimilarBaseInternal, [{
    key: "render",
    value: function render() {
      var countOneEvent = this.props.events.length === 1 ? true : false;
      return _react.default.createElement(_react.default.Fragment, null, this.props.events.length > 0 && _react.default.createElement(_reactNative.View, {
        style: styles.sectionContainer
      }, _react.default.createElement(_Title.default, {
        style: styles.sectionTitle
      }, this.props.sectionTitle), _react.default.createElement(_reactNative.ScrollView, {
        horizontal: countOneEvent ? false : true,
        directionalLockEnabled: true,
        pinchGestureEnabled: false,
        showsHorizontalScrollIndicator: false,
        contentContainerStyle: {
          paddingLeft: 16
        }
      }, this.props.events.map(function (el) {
        if (el.get('status') === _constants.LIVE_STATUS.PLAYING) {
          return _react.default.createElement(_LiveEventItem.default, {
            key: el.get('id'),
            eventId: el.get('id'),
            countOneLiveEvent: countOneEvent
          });
        } else {
          return _react.default.createElement(_EventItem.default, {
            key: el.get('id'),
            eventId: el.get('id'),
            countOneEvent: countOneEvent
          });
        }
      }))));
    }
  }]);
  return SimilarBaseInternal;
}(_react.default.PureComponent);

exports.SimilarBaseInternal = SimilarBaseInternal;

var styles = _reactNative.StyleSheet.create({
  sectionContainer: {
    marginVertical: 20
  },
  sectionTitle: {
    lineHeight: 17,
    color: 'white',
    paddingHorizontal: 16,
    marginBottom: 16
  }
});

var _default = SimilarBaseInternal;
exports.default = _default;