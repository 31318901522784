var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var FullscreenButtonOverlay = function FullscreenButtonOverlay(props) {
  var toggleFullscreen = props.toggleFullscreen,
      overlaySize = props.overlaySize;
  return React.createElement(_reactNative.View, null, React.createElement(_reactNativePaper.TouchableRipple, {
    onPress: toggleFullscreen,
    style: overlaySize === 'small' ? styles.smallOverlay : styles.largeOverlay
  }, React.createElement(_reactNative.View, null)));
};

var _default = FullscreenButtonOverlay;
exports.default = _default;

var styles = _reactNative.StyleSheet.create({
  largeOverlay: {
    height: 60,
    width: 60,
    position: 'absolute',
    bottom: 20,
    right: 48,
    cursor: 'pointer'
  },
  smallOverlay: {
    height: 42,
    width: 44,
    position: 'absolute',
    bottom: 2,
    right: 12,
    cursor: 'pointer'
  }
});