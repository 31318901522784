Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    menuItem: {
      padding: theme.spacing(1),
      display: 'flex',
      justifyContent: 'flex-start',
      borderRadius: 0
    },
    login: {
      borderBottomColor: theme.palette.alternativeText.main,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid'
    }
  };
});
var _default = styles;
exports.default = _default;