var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResponsesList;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _Response = _interopRequireDefault(require("./Response"));

var _ChatInput = _interopRequireDefault(require("../../ChatInput"));

var _i18nJs = _interopRequireDefault(require("i18n-js"));

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    responsesListContainer: {
      backgroundColor: theme.palette.containerBackground.main,
      width: '100%'
    }
  };
});

function ResponsesList(_ref) {
  var onAnswer = _ref.onAnswer,
      isAdmin = _ref.isAdmin,
      question = _ref.question;
  var classes = useStyles();
  return _react.default.createElement(_core.Box, {
    className: classes.responsesListContainer
  }, _react.default.createElement(_core.Box, {
    mx: 1,
    mb: question.answers.length >= 1 ? 3 : 1
  }, _react.default.createElement(_ChatInput.default, {
    placeholder: _i18nJs.default.t('qa.respond'),
    hasMargin: false,
    onMessage: onAnswer
  })), question.answers.map(function (answer, index) {
    return _react.default.createElement(_Response.default, {
      answer: answer,
      isAdmin: isAdmin,
      key: answer.id,
      userInfo: answer.userInfo,
      questionId: question.id,
      isLast: index === question.answers.length - 1
    });
  }));
}