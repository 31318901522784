var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userHighestRoleSelector = exports.hasUserAccessByRolesSelector = exports.hasUserFeatureAccessSelector = exports.hasUserFeatureGlobalAccessSelector = exports.userFeaturesByResourceSelector = exports.userRolesByResourceSelector = exports.userResourceFeaturesSelector = exports.userResourceRolesSelector = exports.userRolesSelector = exports.userFeaturesSelector = exports.userRolesLoadedSelector = exports.userRolesLoadingSelector = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _roleConstants = require("../../constants/role-constants");

var _maxBy = _interopRequireDefault(require("lodash/maxBy"));

var _constants = require("./constants");

var selectUserRolesState = function selectUserRolesState(state) {
  return state[_constants.NAME];
};

var userRolesLoadingSelector = (0, _toolkit.createSelector)(selectUserRolesState, function (state) {
  return state.loading;
});
exports.userRolesLoadingSelector = userRolesLoadingSelector;
var userRolesLoadedSelector = (0, _toolkit.createSelector)(selectUserRolesState, function (state) {
  return state.loaded;
});
exports.userRolesLoadedSelector = userRolesLoadedSelector;
var userFeaturesSelector = (0, _toolkit.createSelector)(selectUserRolesState, function (state) {
  return state.features;
});
exports.userFeaturesSelector = userFeaturesSelector;
var userRolesSelector = (0, _toolkit.createSelector)(selectUserRolesState, function (state) {
  return state.roles;
});
exports.userRolesSelector = userRolesSelector;
var userResourceRolesSelector = (0, _toolkit.createSelector)(selectUserRolesState, function (state) {
  return state.rolesByResource;
});
exports.userResourceRolesSelector = userResourceRolesSelector;
var userResourceFeaturesSelector = (0, _toolkit.createSelector)(selectUserRolesState, function (state) {
  return state.featuresByResource;
});
exports.userResourceFeaturesSelector = userResourceFeaturesSelector;

var userRolesByResourceSelector = function userRolesByResourceSelector(resourceId) {
  return (0, _toolkit.createSelector)(userResourceRolesSelector, function (rolesByResource) {
    return rolesByResource[resourceId] || [];
  });
};

exports.userRolesByResourceSelector = userRolesByResourceSelector;

var userFeaturesByResourceSelector = function userFeaturesByResourceSelector(resourceId) {
  return (0, _toolkit.createSelector)(userResourceFeaturesSelector, function (featuresByResource) {
    return featuresByResource[resourceId] || [];
  });
};

exports.userFeaturesByResourceSelector = userFeaturesByResourceSelector;

var hasUserFeatureGlobalAccessSelector = function hasUserFeatureGlobalAccessSelector(allowedWithFeatures) {
  return (0, _toolkit.createSelector)(userFeaturesSelector, function (features) {
    if (Boolean(features.find(function (f) {
      return allowedWithFeatures.includes(f);
    }))) {
      return true;
    }

    return false;
  });
};

exports.hasUserFeatureGlobalAccessSelector = hasUserFeatureGlobalAccessSelector;

var hasUserFeatureAccessSelector = function hasUserFeatureAccessSelector(allowedWithFeatures, resourceId) {
  return (0, _toolkit.createSelector)(userFeaturesSelector, userFeaturesByResourceSelector(resourceId), function (features, featuresByResource) {
    if (Boolean(features.find(function (f) {
      return allowedWithFeatures.includes(f);
    }))) {
      return true;
    }

    if (Boolean(featuresByResource.find(function (f) {
      return allowedWithFeatures.includes(f);
    }))) {
      return true;
    }

    return false;
  });
};

exports.hasUserFeatureAccessSelector = hasUserFeatureAccessSelector;
var hasUserAccessByRolesSelector = (0, _toolkit.createSelector)(userRolesSelector, userResourceRolesSelector, function (roles, rolesByResource) {
  if (roles.length) {
    return true;
  }

  for (var id in rolesByResource) {
    if (rolesByResource[id].length) {
      return true;
    }
  }

  return false;
});
exports.hasUserAccessByRolesSelector = hasUserAccessByRolesSelector;

var userHighestRoleSelector = function userHighestRoleSelector(resourceId) {
  return (0, _toolkit.createSelector)(userRolesSelector, userRolesByResourceSelector(resourceId), function (roles, rolesByResource) {
    var role = (0, _maxBy.default)(roles, function (role) {
      return _roleConstants.USER_ROLES_HIERARCHY[role];
    });

    if (resourceId) {
      var roleByResource = (0, _maxBy.default)(rolesByResource, function (roleByResource) {
        return _roleConstants.USER_ROLES_HIERARCHY[roleByResource];
      });
      if (!roleByResource) return role;
      return _roleConstants.USER_ROLES_HIERARCHY[role] > _roleConstants.USER_ROLES_HIERARCHY[roleByResource] ? role : roleByResource;
    } else {
      return role;
    }
  });
};

exports.userHighestRoleSelector = userHighestRoleSelector;