var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../../theme/components/typography/Typography"));

var _getInitials = _interopRequireDefault(require("../../common/avatar/getInitials"));

var _DefaultHTMLrenderer = _interopRequireDefault(require("../../common/DefaultHTMLrenderer"));

var _speakerViewStyles = _interopRequireDefault(require("./speaker-view-styles"));

var Speaker = function Speaker(_ref) {
  var organization = _ref.organization,
      name = _ref.name,
      photo = _ref.photo,
      position = _ref.position,
      shortBio = _ref.shortBio,
      isModerator = _ref.isModerator,
      onNavigate = _ref.onNavigate,
      containerStyle = _ref.containerStyle,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'pageText' : _ref$color;
  var Styles = (0, _speakerViewStyles.default)();
  return _react.default.createElement(_reactNative.TouchableHighlight, {
    underlayColor: "#FFFFFF55",
    style: [Styles.container, containerStyle],
    onPress: onNavigate
  }, _react.default.createElement(_reactNative.View, null, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }, _react.default.createElement(_reactNative.Image, {
    style: Styles.avatar,
    source: {
      uri: photo || "https://via.placeholder.com/128/000000/FFFFFF/?text=".concat((0, _getInitials.default)(name))
    }
  }), isModerator && _react.default.createElement(_reactNative.Text, {
    style: Styles.moderator
  }, "Moderator")), _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }, _react.default.createElement(_Typography.default, {
    color: color,
    style: {
      fontWeight: 'bold'
    },
    textAlign: "center"
  }, name), _react.default.createElement(_core.Box, {
    mt: 0.5,
    mb: 1
  }, _react.default.createElement(_Typography.default, {
    color: color,
    variant: "description",
    textAlign: "center"
  }, position, organization ? ", ".concat(organization) : '')), !!shortBio && _react.default.createElement(_Typography.default, {
    color: color,
    variant: "description",
    textAlign: "center"
  }, _react.default.createElement(_DefaultHTMLrenderer.default, {
    source: shortBio
  })))));
};

var _default = Speaker;
exports.default = _default;