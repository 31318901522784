var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TicketAddonsStep;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = _interopRequireDefault(require("react"));

var _lab = require("@material-ui/lab");

var _core = require("@material-ui/core");

var _eventRegisterProvider = require("../../event-register-provider");

var _checkoutItemsList = _interopRequireDefault(require("../../event-registration-components/common/checkout-items-list"));

var _addonsVariationsList = _interopRequireDefault(require("./addons-variations-list"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

function TicketAddonsStep() {
  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      availableTicketAddons = _useEventRegisterCont.availableTicketAddons,
      ticketQuantity = _useEventRegisterCont.ticketQuantity,
      onCheckedAddonsVariationsChange = _useEventRegisterCont.onCheckedAddonsVariationsChange;

  var getAddonVariation = function getAddonVariation(variation, addon) {
    return {
      id: variation.id,
      name: variation.name,
      price: variation.price,
      addonId: addon.id
    };
  };

  var handleChange = function handleChange(event, variation, addon) {
    if (addon === null || addon === void 0 ? void 0 : addon.selectMultiple) {
      onCheckedAddonsVariationsChange(function (prev) {
        return event.target.checked ? [].concat((0, _toConsumableArray2.default)(prev), [getAddonVariation(variation, addon)]) : prev.filter(function (item) {
          return item.id !== variation.id;
        });
      });
    } else {
      onCheckedAddonsVariationsChange(function (prev) {
        if (!prev.find(function (prevAddon) {
          return event.target.value === prevAddon.id;
        })) {
          var newState = prev.filter(function (item) {
            return item.addonId !== addon.id;
          });
          return [].concat((0, _toConsumableArray2.default)(newState), [getAddonVariation(variation, addon)]);
        } else {
          return prev.filter(function (item) {
            return item.id !== variation.id;
          });
        }
      });
    }
  };

  return _react.default.createElement(_react.default.Fragment, null, ticketQuantity > 1 && _react.default.createElement(_core.Box, {
    my: 2
  }, _react.default.createElement(_lab.Alert, {
    severity: "info"
  }, _i18n.default.translate('addOns.multipleAddonsWarning'))), _react.default.createElement(_checkoutItemsList.default, {
    checkoutItems: availableTicketAddons,
    isTogglable: true
  }, _react.default.createElement(_addonsVariationsList.default, {
    handleChange: handleChange
  })));
}