var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = QuestionsList;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _Question = _interopRequireDefault(require("./components/Question"));

var _ChatInput = _interopRequireDefault(require("../ChatInput"));

var _reactRedux = require("react-redux");

var _actions = require("../../../core/QAndA/actions");

var _selectors = require("../../../core/QAndA/selectors");

var _i18nJs = _interopRequireDefault(require("i18n-js"));

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    qAndAContainer: {
      height: '100%',
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    emptyQuestionsBox: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        textAlign: 'center'
      }
    },
    questionsContainer: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'auto',
      overflowX: 'hidden',
      overflowY: 'scroll'
    }
  };
});

function QuestionsList() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var questions = (0, _reactRedux.useSelector)(_selectors.selectQuestionsList);

  var handleAskQuestion = function handleAskQuestion(message) {
    dispatch((0, _actions.createQuestion)(message));
  };

  var classes = useStyles();
  return _react.default.createElement(_core.Box, {
    className: classes.qAndAContainer,
    mx: 1
  }, _react.default.createElement(_core.Box, {
    className: classes.questionsContainer
  }, questions.length === 0 && _react.default.createElement(_core.Box, {
    className: classes.emptyQuestionsBox,
    mx: 1
  }, _react.default.createElement(_core.Typography, {
    variant: "body1"
  }, _i18nJs.default.t('qa.noQuestionsText'))), questions.map(function (question) {
    return _react.default.createElement(_Question.default, {
      key: question.id,
      question: question
    });
  })), _react.default.createElement(_core.Box, {
    m: 1
  }, _react.default.createElement(_ChatInput.default, {
    placeholder: _i18nJs.default.t('qa.askSomething'),
    onMessage: handleAskQuestion
  })));
}