var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireWildcard(require("react"));

var _blockedUsersView = _interopRequireDefault(require("./blocked-users-view"));

var _reactNative = require("react-native");

var _NavigationButtons = require("../../../navigation/NavigationButtons");

function BlockedUsersContainer(props) {
  var getBlockedUsers = props.getBlockedUsers,
      unblockUser = props.unblockUser;
  (0, _react.useEffect)(function () {
    function loadData() {
      return _loadData.apply(this, arguments);
    }

    function _loadData() {
      _loadData = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                getBlockedUsers();

              case 1:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));
      return _loadData.apply(this, arguments);
    }

    loadData();
  }, [getBlockedUsers]);
  var handleUnblock = (0, _react.useCallback)(function (id) {
    return unblockUser({
      id: id
    });
  }, [unblockUser]);
  return _react.default.createElement(_blockedUsersView.default, (0, _extends2.default)({}, props, {
    testID: 'BlockedUsersView',
    onPressUnblock: handleUnblock
  }));
}

BlockedUsersContainer.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  return {
    title: 'Your blocked list',
    headerTintColor: '#fff',
    headerTitleStyle: {
      flex: 1,
      textAlign: 'center',
      alignSelf: 'center',
      fontSize: 15
    },
    headerStyle: {
      backgroundColor: '#000',
      borderBottomColor: '#000'
    },
    headerLeft: (0, _NavigationButtons.backButton)(navigation),
    headerRight: _react.default.createElement(_reactNative.View, null)
  };
};

var _default = BlockedUsersContainer;
exports.default = _default;