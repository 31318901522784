var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AdminOptions;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var React = _interopRequireWildcard(require("react"));

var _core = require("@material-ui/core");

var _i18nJs = _interopRequireDefault(require("i18n-js"));

var _reactFeather = require("react-feather");

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    menuContainer: {
      '& svg': {
        marginRight: 5
      }
    }
  };
});

function AdminOptions(_ref) {
  var onDelete = _ref.onDelete,
      onPin = _ref.onPin,
      isPinned = _ref.isPinned;

  var _React$useState = React.useState(null),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      anchorEl = _React$useState2[0],
      setAnchorEl = _React$useState2[1];

  var classes = useStyles();
  var open = Boolean(anchorEl);

  var handleClick = function handleClick(event) {
    setAnchorEl(event.currentTarget);
  };

  var handleClose = function handleClose() {
    setAnchorEl(null);
  };

  var handlePin = function handlePin() {
    handleClose();
    onPin();
  };

  var handleDelete = function handleDelete() {
    handleClose();
    onDelete();
  };

  return React.createElement(_core.Box, {
    display: "flex"
  }, React.createElement(_reactFeather.MoreVertical, {
    size: 24,
    id: "demo-positioned-button",
    "aria-controls": open ? 'demo-positioned-menu' : undefined,
    "aria-haspopup": "true",
    "aria-expanded": open ? 'true' : undefined,
    onClick: handleClick,
    style: {
      cursor: 'pointer '
    }
  }), React.createElement(_core.Menu, {
    id: "demo-positioned-menu",
    "aria-labelledby": "demo-positioned-button",
    anchorEl: anchorEl,
    open: open,
    onClose: handleClose,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  }, React.createElement(_core.MenuItem, {
    onClick: handlePin,
    className: classes.menuContainer
  }, React.createElement(_reactFeather.MapPin, {
    size: 20
  }), isPinned ? _i18nJs.default.t('qa.unpin') : _i18nJs.default.t('qa.pin')), React.createElement(_core.MenuItem, {
    onClick: handleDelete,
    className: classes.menuContainer
  }, React.createElement(_reactFeather.Trash2, {
    size: 20
  }), _i18nJs.default.t('qa.delete'))));
}