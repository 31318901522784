var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EmailLoginContainer;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/auth/selectors");

var _selectors2 = require("../../../core/checkout/selectors");

var _actions = require("../../../core/user/actions");

var _reactNative = require("react-native");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _authStyles = _interopRequireDefault(require("../auth-styles"));

var _homeButton = _interopRequireDefault(require("../home-button"));

var _loginView = _interopRequireDefault(require("./login-view"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _actions2 = require("../../../core/auth/actions");

var _selectors3 = require("../../../core/event-info/selectors");

var _actions3 = require("../../../core/global/actions");

var _eventRegistration = _interopRequireDefault(require("../../event-registration"));

var _actions4 = require("../../../core/tickets/actions");

var _actions5 = require("../../../core/session/actions");

function EmailLoginContainer() {
  var error = (0, _reactRedux.useSelector)(_selectors.getLoginError);
  var isLoading = (0, _reactRedux.useSelector)(_selectors.isLoginLoading);
  var loginMailSent = (0, _reactRedux.useSelector)(_selectors.isLoginMailSent);
  var checkoutIntent = (0, _reactRedux.useSelector)(_selectors2.checkoutIntentSelector);
  var eventId = (0, _reactRedux.useSelector)(_selectors3.selectEventInfoId);
  var navigation = (0, _useNavigationExtended.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var Styles = (0, _authStyles.default)();

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      isRequesting = _useState2[0],
      setIsRequesting = _useState2[1];

  _react.default.useEffect(function () {
    dispatch((0, _actions2.removeLoginError)());
    dispatch((0, _actions2.removeLoginMailMessage)());
  }, []);

  if (error && _reactNative.Platform.OS === 'web' && error.indexOf("Failed to execute 'transaction' on 'IDBDatabase'") > -1) {
    window.location.reload();
  }

  var currentUrl = window.location.origin + window.location.pathname;
  (0, _useHeader.default)({
    visible: false,
    withScroll: false,
    withLeftMenu: false
  });

  var goToRegister = function goToRegister() {
    dispatch((0, _actions3.openDialog)({
      id: 'register',
      content: _react.default.createElement(_eventRegistration.default, {
        closeDialog: function closeDialog() {
          dispatch((0, _actions3.closeDialog)('register'));
        },
        callBacks: {
          goToLogin: function goToLogin() {
            navigation.navigate(_routesConsts.Routes.LOGIN);
          },
          onPaymentSuccess: function () {
            var _onPaymentSuccess = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
              return _regenerator.default.wrap(function _callee$(_context) {
                while (1) {
                  switch (_context.prev = _context.next) {
                    case 0:
                      _context.next = 2;
                      return dispatch((0, _actions4.getTickets)());

                    case 2:
                      _context.next = 4;
                      return dispatch((0, _actions5.getSessions)());

                    case 4:
                      _context.next = 6;
                      return dispatch((0, _actions4.getBoughtTickets)());

                    case 6:
                    case "end":
                      return _context.stop();
                  }
                }
              }, _callee);
            }));

            function onPaymentSuccess() {
              return _onPaymentSuccess.apply(this, arguments);
            }

            return onPaymentSuccess;
          }()
        }
      })
    }));
  };

  return _react.default.createElement(_Section.default, {
    style: {
      flex: 1
    },
    color: "pageBackground"
  }, _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: Styles.scrollView
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 0, 0, 0]
  }, _react.default.createElement(_homeButton.default, null)), _react.default.createElement(_loginView.default, {
    checkoutIntent: checkoutIntent,
    onLogin: function () {
      var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(values, loginType) {
        return _regenerator.default.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                setIsRequesting(true);
                dispatch((0, _actions2.removeLoginMailMessage)());
                _context2.next = 4;
                return dispatch((0, _actions.firebaseLogin)(values, eventId, loginType, currentUrl));

              case 4:
                setIsRequesting(false);

              case 5:
              case "end":
                return _context2.stop();
            }
          }
        }, _callee2);
      }));

      return function (_x, _x2) {
        return _ref.apply(this, arguments);
      };
    }(),
    goToRegister: goToRegister,
    goToResetPassword: function goToResetPassword() {
      return navigation.navigate(_routesConsts.Routes.RESET_PASSWORD);
    },
    loginFeedback: error,
    isLoading: isLoading,
    isLoginMailSent: loginMailSent,
    isRequesting: isRequesting
  })));
}