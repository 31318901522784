Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    border: {
      borderTopColor: theme.palette.borderColor.main,
      borderTopStyle: 'solid',
      borderTopWidth: 1,
      backgroundColor: theme.palette.pageBackground.main
    },
    footerPrivacy: {
      marginLeft: theme.spacing(3),
      '@media(max-width: 425px)': {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(2)
      }
    },
    footerLinks: {
      marginRight: 100,
      '@media(max-width: 425px)': {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    footerSpacing: {
      marginLeft: 90,
      '@media(max-width: 1026px)': {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
      },
      '@media(max-width: 425px)': {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    }
  };
});
var _default = styles;
exports.default = _default;