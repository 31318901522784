var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createUserProfile = exports.updateUserProfile = exports.getUserProfile = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _selectors = require("../user/selectors");

var _constants = require("./constants");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _actions = require("../user/actions");

var _actions2 = require("../chat-user/actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getUserProfile = (0, _toolkit.createAsyncThunk)(_constants.GET_USER_PROFILE, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(uid, _ref) {
    var getState, userId, profile;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            userId = (0, _selectors.getFirebaseUserUid)(getState()) || uid;

            if (userId) {
              _context.next = 4;
              break;
            }

            throw Error('No logged in user!');

          case 4:
            _context.next = 6;
            return _reactNativeFirebase.default.firestore().collection('users').doc(userId).get();

          case 6:
            profile = _context.sent;
            return _context.abrupt("return", _objectSpread(_objectSpread({}, profile.data()), {}, {
              id: userId
            }));

          case 8:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getUserProfile = getUserProfile;
var updateUserProfile = (0, _toolkit.createAsyncThunk)(_constants.UPDATE_USER_PROFILE, function () {
  var _ref5 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(_ref3, _ref4) {
    var imageUpload, rest, getState, dispatch, userId, filePath, fileRef, uploadTask;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            imageUpload = _ref3.imageUpload, rest = (0, _objectWithoutProperties2.default)(_ref3, ["imageUpload"]);
            getState = _ref4.getState, dispatch = _ref4.dispatch;
            userId = (0, _selectors.getFirebaseUserUid)(getState());

            if (userId) {
              _context2.next = 5;
              break;
            }

            throw Error('No logged in user!');

          case 5:
            if (!imageUpload) {
              _context2.next = 14;
              break;
            }

            filePath = "users/".concat(userId, "/avatar");
            fileRef = _reactNativeFirebase.default.storage().ref(filePath);
            _context2.next = 10;
            return fileRef.put(imageUpload.file, {
              contentType: imageUpload.type
            });

          case 10:
            uploadTask = _context2.sent;
            _context2.next = 13;
            return uploadTask.ref.getDownloadURL();

          case 13:
            rest.photo = _context2.sent;

          case 14:
            dispatch((0, _actions.updateFirebaseUserProfile)({
              displayName: rest.displayName,
              photoURL: rest.photo
            }));
            _context2.next = 17;
            return _reactNativeFirebase.default.firestore().collection('users').doc(userId).set(rest, {
              merge: true
            });

          case 17:
            dispatch((0, _actions2.getChatUserProfileInfo)(userId));
            return _context2.abrupt("return", rest);

          case 19:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function (_x3, _x4) {
    return _ref5.apply(this, arguments);
  };
}());
exports.updateUserProfile = updateUserProfile;
var createUserProfile = (0, _toolkit.createAsyncThunk)(_constants.UPDATE_USER_PROFILE, function () {
  var _ref7 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(_ref6) {
    var userId, rest;
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            userId = _ref6.userId, rest = (0, _objectWithoutProperties2.default)(_ref6, ["userId"]);
            _context3.next = 3;
            return _reactNativeFirebase.default.firestore().collection('users').doc(userId).set(rest, {
              merge: true
            });

          case 3:
            return _context3.abrupt("return", rest);

          case 4:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));

  return function (_x5) {
    return _ref7.apply(this, arguments);
  };
}());
exports.createUserProfile = createUserProfile;