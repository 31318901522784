var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _blankSlateStyles = _interopRequireWildcard(require("./blank-slate-styles"));

var _CustomIcon = _interopRequireDefault(require("../../../../assets/icons/CustomIcon.js"));

function LeaderboardBlankSlate() {
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.View, {
    style: _blankSlateStyles.default.artworkContainer
  }, _react.default.createElement(_CustomIcon.default, {
    name: "trophy",
    size: _blankSlateStyles.iconSize,
    color: _blankSlateStyles.iconColor
  })));
}

var _default = LeaderboardBlankSlate;
exports.default = _default;