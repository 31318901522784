var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _stringFormatter = require("../../utils/string-formatter");

var _DefaultHTMLrenderer = _interopRequireDefault(require("../common/DefaultHTMLrenderer"));

var _letterStyles = _interopRequireDefault(require("./letter-styles"));

var _styles = _interopRequireDefault(require("./styles"));

var LetterView = function LetterView(_ref) {
  var organization = _ref.organization,
      name = _ref.name,
      photo = _ref.photo,
      position = _ref.position,
      letter = _ref.letter;
  var Styles = (0, _letterStyles.default)();
  var classes = (0, _styles.default)();
  return _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    className: classes.container
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    className: classes.avatarContainer,
    mr: 8
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column"
  }, _react.default.createElement(_reactNative.Image, {
    style: Styles.avatar,
    source: {
      uri: photo || "https://via.placeholder.com/128/000000/FFFFFF/?text=".concat((0, _stringFormatter.getInitials)(name))
    }
  })), _react.default.createElement(_core.Box, {
    display: "flex",
    alignItems: "center",
    my: 3
  }, _react.default.createElement(_core.Typography, {
    variant: "h3",
    align: "center"
  }, name)), _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, position), _react.default.createElement(_core.Typography, {
    align: "center"
  }, organization))), !!letter.length && _react.default.createElement(_core.Typography, null, _react.default.createElement(_DefaultHTMLrenderer.default, {
    source: letter
  })));
};

var _default = LetterView;
exports.default = _default;