Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var liveStreamVideoContainerStyle = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    webVideo: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  };
}).buildHook();
var _default = liveStreamVideoContainerStyle;
exports.default = _default;