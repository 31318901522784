var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.WelcomeScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactNativeVideo = _interopRequireDefault(require("react-native-video"));

var _verticalWhiteLogo = _interopRequireDefault(require("../common/logo/verticalWhiteLogo.png"));

var _horizontalWhiteNewLogo = _interopRequireDefault(require("../common/logo/horizontalWhiteNewLogo.png"));

var _reactNativeSnapCarousel = require("react-native-snap-carousel");

var _FacebookLoginButton = _interopRequireDefault(require("../user/FacebookLoginButton"));

var _GoogleLoginButton = _interopRequireDefault(require("../user/GoogleLoginButton"));

var _phoneLogin = _interopRequireDefault(require("../user/phoneLogin"));

var _emailLogin = _interopRequireDefault(require("../user/emailLogin"));

var _AppleSignInButton = _interopRequireDefault(require("../user/AppleSignInButton"));

var _referral = _interopRequireDefault(require("../../core/referral"));

var _user = _interopRequireDefault(require("../../core/user"));

var _flags = _interopRequireDefault(require("../../core/flags"));

var _AvatarImage = _interopRequireDefault(require("../common/avatar/AvatarImage"));

var _constants = require("../../../constants");

var _Analytics = require("../../core/services/Analytics");

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

var _section = _interopRequireDefault(require("design-system/structure/section"));

var _headingView = _interopRequireDefault(require("design-system/typography/heading-view"));

var _subheadingView = _interopRequireDefault(require("design-system/typography/subheading-view"));

var _textView = _interopRequireDefault(require("design-system/typography/text-view"));

var _button = _interopRequireDefault(require("design-system/actions/button"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var WelcomeScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(WelcomeScreenInternal, _React$PureComponent);

  var _super = _createSuper(WelcomeScreenInternal);

  function WelcomeScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, WelcomeScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      screenWidth: 1,
      screenHeight: 1,
      activeSlide: 0,
      entries: [{
        title: 'Watch live streams from DJs and festivals, together with friends'
      }, {
        title: 'Unique live content from all electronic music genres'
      }, {
        title: 'Connect with other electronic music fans and your favorite DJs'
      }],
      inProgress: false,
      renderPhoneLogin: false,
      renderEmailLogin: false
    };

    _this.handleSetLayoutSize = function (event) {
      _this.setState({
        screenWidth: event.nativeEvent.layout.width,
        screenHeight: event.nativeEvent.layout.height
      });
    };

    _this.handleSkipLoginPressed = function () {
      (0, _Analytics.trackEvent)('login_skip');

      _this.props.skipLogin();
    };

    _this.handlePhoneLogInState = function () {
      _this.setState(function (prevState) {
        return {
          renderPhoneLogin: !prevState.renderPhoneLogin
        };
      });
    };

    _this.handleEmailLogInState = function () {
      _this.setState(function (prevState) {
        return {
          renderEmailLogin: !prevState.renderEmailLogin
        };
      });
    };

    return _this;
  }

  (0, _createClass2.default)(WelcomeScreenInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      (0, _Analytics.trackEvent)('welcome_screen_open');
    }
  }, {
    key: "renderSkipLoginButton",
    value: function renderSkipLoginButton() {
      var showSkipLogin = this.props.showSkipLogin;
      return showSkipLogin ? React.createElement(_button.default, {
        textButton: true,
        name: "skipLoginButton",
        buttonSize: "small",
        onPress: this.handleSkipLoginPressed,
        style: this.props.style,
        disabled: false
      }, "Not now") : null;
    }
  }, {
    key: "renderEmailLoginButton",
    value: function renderEmailLoginButton() {
      return React.createElement(_button.default, {
        type: "phone",
        name: "LoginButton",
        testID: "LoginButton",
        secondary: true,
        size: "large",
        onPress: this.handleEmailLogInState,
        style: this.props.style,
        fontSize: 15,
        disabled: false
      }, "Sign in with your e-mail");
    }
  }, {
    key: "renderPhoneLoginButton",
    value: function renderPhoneLoginButton() {
      var buttonStyles = _reactNative.Platform.OS === 'web' ? styles.phoneLoginWeb : this.props.style;
      return React.createElement(_button.default, {
        type: "phone",
        name: "LoginButton",
        testID: "PhoneLoginButton",
        secondary: true,
        size: "large",
        fontSize: 15,
        onPress: this.handlePhoneLogInState,
        style: buttonStyles,
        disabled: false
      }, "Sign in with your phone number");
    }
  }, {
    key: "_renderItem",
    value: function _renderItem(_ref) {
      var item = _ref.item;
      return React.createElement(_reactNative.View, {
        style: styles.slide
      }, React.createElement(_subheadingView.default, {
        style: {
          textAlign: 'center'
        }
      }, item.title));
    }
  }, {
    key: "render",
    value: function render() {
      var welcomeVideo = '';

      if (_reactNative.Platform.OS === 'ios') {
        welcomeVideo = 'welcome.mp4';
      }

      if (_reactNative.Platform.OS === 'android') {
        welcomeVideo = 'asset://assets/welcome.mp4';
      }

      if (_reactNative.Platform.OS === 'web') {
        welcomeVideo = require('../../assets/video/welcome.mp4');
      }

      return this.state.renderEmailLogin ? React.createElement(_emailLogin.default, {
        onGoBack: this.handleEmailLogInState
      }) : this.state.renderPhoneLogin ? React.createElement(_phoneLogin.default, {
        onGoBack: this.handlePhoneLogInState
      }) : React.createElement(_reactNativeSafeAreaView.default, {
        style: styles.safeArea,
        forceInset: {
          top: 'always',
          bottom: 'always',
          horizontal: 'always'
        },
        testID: "WelcomeScreen"
      }, React.createElement(_reactNative.View, {
        style: styles.container
      }, React.createElement(_section.default, null), React.createElement(_section.default, null, React.createElement(_section.default, {
        spacingVertical: "extraLoose"
      }, React.createElement(_Stack.default, {
        distribution: "center"
      }, React.createElement(_reactNative.View, null, React.createElement(_reactNative.Image, {
        source: _horizontalWhiteNewLogo.default
      }), React.createElement(_textView.default, {
        style: styles.applicationMessage
      }, "all your events in on place")))), React.createElement(_section.default, {
        spacingVertical: "extraLoose",
        spacing: "loose"
      }, React.createElement(_section.default, {
        spacingHorizontal: "extraLoose"
      }, this.renderEmailLoginButton()), React.createElement(_section.default, {
        spacingVertical: "extraLoose"
      }, React.createElement(_reactNative.View, {
        style: styles.semiDividerContainer
      }, React.createElement(_reactNative.View, {
        style: styles.semiDividerLine
      }), React.createElement(_textView.default, {
        style: styles.dividerText
      }, 'OR'), React.createElement(_reactNative.View, {
        style: styles.semiDividerLine
      }))), React.createElement(_section.default, {
        spacingHorizontal: "extraLoose"
      }, this.renderPhoneLoginButton()))), React.createElement(_section.default, null, this.props.referralName && React.createElement(_section.default, {
        spacing: "base"
      }, React.createElement(_reactNative.View, {
        style: styles.referralContainer
      }, React.createElement(_AvatarImage.default, {
        size: 48,
        source: {
          uri: this.props.referralAvatar
        },
        defaultLabel: this.props.referralName.split(' ', 2).map(function (i) {
          return i.charAt(0);
        }),
        style: styles.referralAvatar
      }), React.createElement(_reactNative.View, null, React.createElement(_headingView.default, {
        inverted: true
      }, this.props.referralName), React.createElement(_textView.default, {
        inverted: true
      }, "Hello! Join me on Eventmix.")))), React.createElement(_section.default, {
        spacingVertical: "loose"
      }, React.createElement(_reactNative.View, {
        style: styles.dividerContainer
      }, React.createElement(_reactNative.View, {
        style: styles.dividerLine
      }))), React.createElement(_section.default, {
        spacing: "base"
      }, React.createElement(_reactNative.View, null), React.createElement(_textView.default, {
        style: {
          textAlign: 'center'
        }
      }, "By continuing you agree with our"), React.createElement(_reactNative.View, {
        style: {
          flexDirection: 'row',
          justifyContent: 'center'
        }
      }, React.createElement(_reactNativePaper.TouchableRipple, {
        name: 'termsTouch',
        borderless: false,
        onPress: function onPress() {
          _reactNative.Linking.openURL(_constants.TERMS_OF_USE);
        }
      }, React.createElement(_textView.default, {
        style: styles.agreementMessageLink
      }, "Terms of Use")), React.createElement(_textView.default, {
        style: styles.agreementMessage
      }, "and"), React.createElement(_reactNativePaper.TouchableRipple, {
        name: 'privacyTouch',
        borderless: false,
        onPress: function onPress() {
          _reactNative.Linking.openURL(_constants.PRIVACY_POLICY);
        }
      }, React.createElement(_textView.default, {
        style: styles.agreementMessageLink
      }, "Privacy Policy")))))));
    }
  }, {
    key: "pagination",
    get: function get() {
      var _this$state = this.state,
          entries = _this$state.entries,
          activeSlide = _this$state.activeSlide;
      return React.createElement(_reactNativeSnapCarousel.Pagination, {
        dotsLength: entries.length,
        activeDotIndex: activeSlide,
        containerStyle: {
          backgroundColor: 'transparent',
          paddingTop: 0,
          paddingBottom: 40
        },
        dotStyle: {
          width: 15,
          height: 2,
          borderRadius: 2,
          marginHorizontal: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.92)'
        },
        inactiveDotOpacity: 0.4,
        inactiveDotScale: 1
      });
    }
  }]);
  return WelcomeScreenInternal;
}(React.PureComponent);

exports.WelcomeScreenInternal = WelcomeScreenInternal;
WelcomeScreenInternal.defaultProps = {
  showSkipLogin: false
};

var styles = _reactNative.StyleSheet.create({
  safeArea: {
    flex: 1
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  videoContainer: {
    position: 'absolute',
    overflow: 'hidden',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  linearGradient: {
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  video: {
    width: '100%',
    height: '100%'
  },
  referralContainer: {
    backgroundColor: '#ffffff',
    padding: 4,
    paddingRight: 24,
    borderRadius: 100,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  referralAvatar: {
    marginRight: 8
  },
  referralName: {
    color: '#000',
    fontWeight: 'bold',
    marginBottom: 4,
    lineHeight: 30
  },
  referralMessage: {
    color: '#000',
    margin: 0,
    lineHeight: 13
  },
  applicationMessage: {
    textAlign: 'center',
    color: '#fff',
    marginTop: 16
  },
  agreementMessage: {
    color: '#fff',
    margin: 2
  },
  agreementMessageLink: {
    color: '#fff',
    margin: 2,
    textDecorationLine: 'underline'
  },
  logInMessage: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 2
  },
  logInOrMessage: {
    color: '#fff',
    fontSize: 16,
    margin: 2
  },
  actionButton: {
    width: '100%',
    maxWidth: 400
  },
  slide: {
    padding: 24
  },
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dividerLine: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: 'rgba(255, 255, 255, 1)',
    opacity: 0.2
  },
  semiDividerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  semiDividerLine: {
    flex: 0.5,
    borderBottomWidth: 1,
    borderColor: 'rgba(255, 255, 255, 1)',
    opacity: 0.2
  },
  dividerText: {
    fontSize: 16,
    paddingHorizontal: 16
  },
  phoneLoginWeb: {
    alignSelf: 'center',
    paddingHorizontal: 40
  }
});

function mapStateToProps(state) {
  return {
    referralAvatar: _referral.default.selectors.getReferrerPicture(state),
    referralName: _referral.default.selectors.getReferrerName(state),
    showSkipLogin: _flags.default.selectors.skipLoginFlag(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  skipLogin: _user.default.actions.skipLogin
})(WelcomeScreenInternal);

exports.default = _default;