var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireWildcard(require("immutable"));

var _actionTypes = require("./actionTypes");

var _user = _interopRequireDefault(require("../user"));

var InitialState = _immutable.default.fromJS({
  lastReadMessages: (0, _immutable.Map)(),
  hasSeenPushNotificationSuggestion: false
});

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.READ_NEW_MESSAGES:
      newState = newState.setIn(['lastReadMessages', action.liveId], action.lastMessageKey);
      break;

    case _actionTypes.HAS_SEEN_PUSH_NOTIFICATION_SUGGESTION:
      newState = newState.set('hasSeenPushNotificationSuggestion', true);
      break;

    case _user.default.constants.USER_LOGOUT:
      newState = InitialState;
      break;

    case _actionTypes.TIME_SPENT_IN_LIVE:
      newState = newState.updateIn([action.eventId, 'freeWatchTime'], action.timeSpent, function (value) {
        return value + action.timeSpent;
      });
      break;

    default:
      break;
  }

  return newState;
}