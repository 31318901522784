Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    userName: {
      marginLeft: theme.spacing(1),
      textTransform: 'capitalize'
    },
    headerLeft: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(3)
    },
    burgerBtn: {
      marginRight: theme.spacing(2),
      '@media(min-width: 1026px)': {
        display: 'none'
      }
    },
    container: {
      height: 64,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      userSelect: 'none',
      position: 'sticky',
      top: 0,
      zIndex: 2,
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      backgroundColor: theme.palette.topMenuBackground.main,
      '@media(max-width: 1026px)': {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
      }
    },
    burgerButton: {
      minWidth: 32,
      width: 32,
      height: 32,
      padding: 0
    },
    menuHeader: {},
    logoBtn: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    buttonContainer: {
      flex: 2,
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
      '@media(max-width: 1280px)': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      },
      '@media(max-width: 1026px)': {
        display: 'none'
      }
    },
    menuButton: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      borderColor: theme.palette.alternativeText.main
    },
    menuContainerBackstage: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 10
    },
    menuContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      zIndex: 10
    }
  };
});
var _default = styles;
exports.default = _default;