var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ContainedButton;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../DesignSystem");

var _Spacing = _interopRequireDefault(require("../spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../typography/Typography"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function ContainedButton(_ref) {
  var iconLeft = _ref.iconLeft,
      iconRight = _ref.iconRight,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      _ref$elevation = _ref.elevation,
      elevation = _ref$elevation === void 0 ? 0 : _ref$elevation,
      children = _ref.children,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 'default' : _ref$size,
      style = _ref.style,
      onPress = _ref.onPress;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      shape = _useTheme.shape,
      shadows = _useTheme.shadows,
      spacing = _useTheme.spacing;

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      hover = _React$useState2[0],
      setHover = _React$useState2[1];

  return _react.default.createElement(_reactNative.TouchableHighlight, (0, _extends2.default)({}, _reactNative.Platform.select({
    web: {
      onMouseOver: function onMouseOver() {
        return !disabled && setHover(true);
      },
      onMouseOut: function onMouseOut() {
        return !disabled && setHover(false);
      }
    }
  }), {
    disabled: disabled,
    activeOpacity: 0.8,
    onPress: onPress,
    underlayColor: colors.buttonHover,
    style: [_objectSpread(_objectSpread({}, shape.buttons), {}, {
      boxShadow: shadows[elevation],
      backgroundColor: hover && !disabled ? colors.buttonHover : colors.button,
      height: size === 'large' ? 60 : size === 'small' ? 30 : 45,
      alignItems: 'center',
      justifyContent: 'center'
    }), disabled && {
      opacity: 0.3
    }, style]
  }), _react.default.createElement(_reactNative.View, null, _react.default.createElement(_Spacing.default, {
    direction: "row",
    wrap: "nowrap",
    pSpacing: ['s', iconRight && children ? 'm' : 'l', 's', iconLeft && children ? 'm' : 'l'],
    alignItems: "center",
    justifyContent: "center"
  }, iconLeft && _react.default.createElement(_Spacing.default, {
    self: "middle",
    alignItems: "center",
    justifyContent: "center",
    style: {
      height: spacing.l
    },
    pSpacing: [0, children ? 's' : 0, 0, 0]
  }, _react.default.createElement(_Typography.default, {
    variant: "paragraph",
    color: hover ? 'buttonTextHover' : 'buttonText',
    style: {
      display: 'flex'
    }
  }, iconLeft)), children && _react.default.createElement(_Typography.default, {
    variant: "paragraph",
    color: hover ? 'buttonTextHover' : 'buttonText',
    style: {
      textAlign: 'center',
      wordBreak: 'keep-all'
    }
  }, children), iconRight && _react.default.createElement(_Spacing.default, {
    self: "middle",
    alignItems: "center",
    justifyContent: "center",
    style: {
      height: spacing.l
    },
    pSpacing: [0, 0, 0, children ? 's' : 0]
  }, _react.default.createElement(_Typography.default, {
    variant: "paragraph",
    color: hover ? 'buttonTextHover' : 'buttonText',
    style: {
      display: 'flex'
    }
  }, iconRight)))));
}