var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isEventInfoLoading = isEventInfoLoading;
exports.eventInfoLoaded = eventInfoLoaded;
exports.eventSlugFound = eventSlugFound;
exports.getEventInfo = getEventInfo;
exports.setDocumentTitle = setDocumentTitle;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _routesConsts = require("../../navigation/client/routes-consts");

var _NavigationService = _interopRequireDefault(require("../../navigation/NavigationService"));

var _dateFormatter = require("../../utils/date-formatter");

var _eventData2 = require("../firebase/event-data");

var _paymentService = _interopRequireDefault(require("../services/paymentService.web"));

var _actionTypes = require("./actionTypes");

var _DesignSystem = _interopRequireDefault(require("../../theme/DesignSystem"));

var _webfontloader = _interopRequireDefault(require("webfontloader"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _eventData3 = require("../../core/firebase/event-data");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function isEventInfoLoading() {
  return {
    loading: true,
    type: _actionTypes.IS_EVENT_INFO_LOADING
  };
}

function eventInfoLoaded(event) {
  return {
    event: event,
    type: _actionTypes.LOAD_EVENT_INFO_COMPLETED
  };
}

function eventSlugFound(eventSlug) {
  return {
    eventSlug: eventSlug,
    type: _actionTypes.LOAD_EVENT_SLUG_COMPLETED
  };
}

function redirectToSluggedVersion(_x, _x2) {
  return _redirectToSluggedVersion.apply(this, arguments);
}

function _redirectToSluggedVersion() {
  _redirectToSluggedVersion = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(dispatch, eventId) {
    var mandatoryRedirect,
        eventSlug,
        _args2 = arguments;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            mandatoryRedirect = _args2.length > 2 && _args2[2] !== undefined ? _args2[2] : false;
            _context2.next = 3;
            return (0, _eventData3.findCustomEventSlugForEventId)(eventId);

          case 3:
            eventSlug = _context2.sent;

            if (eventId !== eventSlug || mandatoryRedirect) {
              _NavigationService.default.navigate(_routesConsts.Routes.HOME, {
                eventId: eventSlug
              });
            }

            dispatch(eventSlugFound(eventSlug));

          case 6:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));
  return _redirectToSluggedVersion.apply(this, arguments);
}

function getEventInfo(eventId, domain) {
  return function () {
    var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(dispatch) {
      var rightEventId, eventData, _addon, _eventData, id, name, headline, about, description, startDate, endDate, dateFormat, timezone, checkoutBlock, currency, logoUrl, bannerUrl, connectedAccountId, customization, footer, languageId, createdBy, hideUsersLiveCount, showHeaderOverlay, useModalCheckout, externalPaymentSuccessPage, addonType, addon, passwordLessRegistration;

      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              dispatch(isEventInfoLoading());

              if (!eventId) {
                _context.next = 14;
                break;
              }

              _context.next = 4;
              return redirectToSluggedVersion(dispatch, eventId);

            case 4:
              _context.next = 6;
              return (0, _eventData3.findEventIdFromCustomEventSlug)(eventId);

            case 6:
              eventId = _context.sent;
              _context.next = 9;
              return (0, _eventData2.detectEventDomainMismatch)(eventId, domain);

            case 9:
              rightEventId = _context.sent;

              if (!(eventId !== rightEventId)) {
                _context.next = 14;
                break;
              }

              eventId = rightEventId;
              _context.next = 14;
              return redirectToSluggedVersion(dispatch, eventId);

            case 14:
              if (!eventId) {
                _context.next = 20;
                break;
              }

              _context.next = 17;
              return (0, _eventData2.getEventData)(eventId);

            case 17:
              _context.t0 = _context.sent;
              _context.next = 23;
              break;

            case 20:
              _context.next = 22;
              return (0, _eventData2.getEventDataFromDomain)(domain);

            case 22:
              _context.t0 = _context.sent;

            case 23:
              eventData = _context.t0;

              if (!(!eventId && eventData)) {
                _context.next = 27;
                break;
              }

              _context.next = 27;
              return redirectToSluggedVersion(dispatch, eventData.id, true);

            case 27:
              if (eventData) {
                _context.next = 29;
                break;
              }

              return _context.abrupt("return", Promise.reject(eventData));

            case 29:
              if (!eventData.addonType) {
                _context.next = 34;
                break;
              }

              _context.next = 32;
              return (0, _eventData2.getAddonData)(eventData.addonType);

            case 32:
              _addon = _context.sent;
              eventData = _objectSpread(_objectSpread({}, eventData), {}, {
                addon: _addon
              }, _addon.eventCustomization);

            case 34:
              _eventData = eventData, id = _eventData.id, name = _eventData.name, headline = _eventData.headline, about = _eventData.about, description = _eventData.description, startDate = _eventData.startDate, endDate = _eventData.endDate, dateFormat = _eventData.dateFormat, timezone = _eventData.timezone, checkoutBlock = _eventData.checkoutBlock, currency = _eventData.currency, logoUrl = _eventData.logoUrl, bannerUrl = _eventData.bannerUrl, connectedAccountId = _eventData.connectedAccountId, customization = _eventData.customization, footer = _eventData.footer, languageId = _eventData.languageId, createdBy = _eventData.createdBy, hideUsersLiveCount = _eventData.hideUsersLiveCount, showHeaderOverlay = _eventData.showHeaderOverlay, useModalCheckout = _eventData.useModalCheckout, externalPaymentSuccessPage = _eventData.externalPaymentSuccessPage, addonType = _eventData.addonType, addon = _eventData.addon, passwordLessRegistration = _eventData.passwordLessRegistration;

              if (languageId) {
                _i18n.default.locale = languageId;
                _reactNativeFirebase.default.auth().languageCode = languageId;
              } else {
                _i18n.default.locale = 'en';
              }

              if (eventData) setDocumentTitle(eventData);

              if (connectedAccountId) {
                _paymentService.default.initStripe(connectedAccountId);
              }

              if (customization && customization.fontFamily && customization.colors) {
                _DesignSystem.default.setThemeConfig({
                  colors: _objectSpread({}, customization.colors),
                  typography: {
                    h1: {
                      fontFamily: customization.fontFamily
                    },
                    h2: {
                      fontFamily: customization.fontFamily
                    },
                    h3: {
                      fontFamily: customization.fontFamily
                    },
                    h4: {
                      fontFamily: customization.fontFamily
                    },
                    paragraph: {
                      fontFamily: customization.fontFamily
                    },
                    description: {
                      fontFamily: customization.fontFamily
                    },
                    fontFamily: customization.fontFamily
                  },
                  shape: _objectSpread({}, customization.shape),
                  links: _objectSpread({}, customization.links)
                });

                _webfontloader.default.load({
                  google: {
                    families: [customization.fontFamily]
                  }
                });
              }

              dispatch(eventInfoLoaded({
                id: id,
                name: name,
                headline: headline,
                about: about,
                description: description,
                startDate: startDate,
                endDate: endDate,
                dateFormat: dateFormat,
                timezone: timezone,
                checkoutBlock: checkoutBlock,
                currency: currency,
                logoUrl: logoUrl,
                bannerUrl: bannerUrl,
                connectedAccountId: connectedAccountId,
                customization: customization,
                footer: footer,
                createdBy: createdBy,
                hideUsersLiveCount: hideUsersLiveCount,
                showHeaderOverlay: showHeaderOverlay,
                useModalCheckout: useModalCheckout,
                externalPaymentSuccessPage: externalPaymentSuccessPage,
                addonType: addonType,
                addon: addon,
                passwordLessRegistration: passwordLessRegistration
              }));
              return _context.abrupt("return", Promise.resolve());

            case 41:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function (_x3) {
      return _ref.apply(this, arguments);
    };
  }();
}

function setDocumentTitle(eventData) {
  if (eventData) {
    var title = "".concat(eventData.name, ", ").concat((0, _dateFormatter.getDateBetween)(eventData.startDate, eventData.endDate, eventData.timezone, eventData.dateFormat));
    document.title = title;
  }
}