var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Comment;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Avatar = _interopRequireDefault(require("../../theme/components/avatar/Avatar"));

var _Section = _interopRequireDefault(require("../../theme/components/section/Section"));

var _LinkButton = _interopRequireDefault(require("../../theme/components/buttons/LinkButton"));

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _chatStyles = _interopRequireDefault(require("./chat-styles"));

var _reactNative = require("react-native");

var _stringFormatter = require("../../utils/string-formatter");

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _relativeModal = _interopRequireDefault(require("../../theme/components/relative-modal/relative-modal"));

var _profileCardModal = _interopRequireDefault(require("./profile-card-modal"));

var _clsx = _interopRequireDefault(require("clsx"));

var _styles = _interopRequireDefault(require("./styles"));

var _MuiLinkButton = _interopRequireDefault(require("./../common/custom-design-components/MuiLinkButton"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function Comment(_ref) {
  var elapsedTime = _ref.elapsedTime,
      message = _ref.message,
      userName = _ref.userName,
      userId = _ref.userId,
      role = _ref.role,
      onDelete = _ref.onDelete,
      onBlock = _ref.onBlock,
      _ref$hasAccess = _ref.hasAccess,
      hasAccess = _ref$hasAccess === void 0 ? false : _ref$hasAccess,
      loading = _ref.loading,
      avatar = _ref.avatar,
      isBlocked = _ref.isBlocked,
      failed = _ref.failed,
      rest = (0, _objectWithoutProperties2.default)(_ref, ["elapsedTime", "message", "userName", "userId", "role", "onDelete", "onBlock", "hasAccess", "loading", "avatar", "isBlocked", "failed"]);

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      selected = _React$useState2[0],
      setSelected = _React$useState2[1];

  var Styles = (0, _chatStyles.default)();
  var classes = (0, _styles.default)();
  return _react.default.createElement(_Section.default, (0, _extends2.default)({}, rest, {
    style: {
      flexDirection: 'column',
      alignItems: 'stretch',
      margin: 5,
      position: 'relative'
    },
    color: !!role ? 'alternativeBackground' : 'containerBackground',
    shaped: true,
    shaping: "cards"
  }), failed && _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    justifyContent: "center",
    style: _objectSpread(_objectSpread({}, _reactNative.StyleSheet.absoluteFillObject), {}, {
      zIndex: 2,
      opacity: 0.8
    }),
    color: "alternativeBackground",
    shaping: "cards"
  }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('chat.failed'))), loading && _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    justifyContent: "center",
    style: _objectSpread(_objectSpread({}, _reactNative.StyleSheet.absoluteFillObject), {}, {
      zIndex: 2,
      opacity: 0.8
    }),
    color: "alternativeBackground",
    shaping: "cards"
  }, _react.default.createElement(_core.Typography, null, loading)), _react.default.createElement(_Spacing.default, {
    onClick: function onClick() {
      return (onDelete || onBlock) && hasAccess && setSelected(function (oldState) {
        return !oldState;
      });
    },
    style: {
      position: 'relative',
      cursor: 'pointer'
    },
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    direction: "row",
    mSpacing: "m"
  }, _react.default.createElement(_relativeModal.default, {
    content: _react.default.createElement(_profileCardModal.default, {
      userId: userId,
      displayName: userName
    })
  }, function (_ref2) {
    var onOpen = _ref2.onOpen;
    return _react.default.createElement(_Avatar.default, {
      onPress: function onPress(e) {
        var messagePositions = e.target.getBoundingClientRect();
        e.preventDefault();
        e.stopPropagation();
        onOpen(messagePositions);
      },
      source: avatar || "https://via.placeholder.com/40/000000/FFFFFF/?text=".concat((0, _stringFormatter.getInitials)(userName)),
      name: userName,
      size: 40
    });
  }), _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 0, 'm'],
    flex: 1,
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'xs', 0],
    direction: "row",
    wrap: "nowrap",
    flex: 1
  }, _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: (0, _clsx.default)(classes.userNameText, classes.textTruncate)
  }, userName), _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: classes.elapsedTimeText
  }, "- ".concat(elapsedTime)), _react.default.createElement(_Section.default, {
    color: "containerBackground",
    shaped: true,
    style: {
      position: 'relative',
      top: -5,
      right: -5,
      marginLeft: 'auto'
    }
  }, role && _react.default.createElement(_Spacing.default, {
    style: Styles.position,
    mSpacing: "xs"
  }, _react.default.createElement(_core.Typography, {
    className: classes.roleSplitText,
    variant: "caption"
  }, role.split('_').join(' '))))), _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, message), selected && (onBlock || onDelete) && _react.default.createElement(_Spacing.default, {
    mSpacing: ['xs', 0, 0, 0],
    direction: "row",
    wrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between"
  }, onDelete && _react.default.createElement(_MuiLinkButton.default, {
    color: "error",
    translationKey: "chat.deleteComment",
    onClick: function onClick() {
      onDelete();
      setSelected(false);
    },
    variant: "overline"
  }), onBlock && !isBlocked && _react.default.createElement(_MuiLinkButton.default, {
    color: "error",
    translationKey: "chat.blockUser",
    onClick: function onClick() {
      onBlock();
      setSelected(false);
    },
    variant: "overline"
  })))));
}