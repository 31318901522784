var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Table;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../../theme/DesignSystem");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _useNavigationExtended = _interopRequireDefault(require("../../../../utils/hooks/useNavigationExtended"));

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _speakerView = _interopRequireDefault(require("./speaker/speaker-view"));

var _styles = _interopRequireDefault(require("./styles"));

var _selectors = require("../../../../core/user-roles/selectors");

var _featureNamesConstants = require("../../../../constants/feature-names-constants");

function Table(_ref) {
  var id = _ref.id,
      participants = _ref.participants,
      maxParticipants = _ref.maxParticipants,
      name = _ref.name,
      loading = _ref.loading,
      disabled = _ref.disabled,
      selected = _ref.selected,
      logoUrl = _ref.logoUrl,
      isRestricted = _ref.isRestricted,
      requestToJoin = _ref.requestToJoin,
      othersCanWatch = _ref.othersCanWatch,
      _ref$onJoin = _ref.onJoin,
      onJoin = _ref$onJoin === void 0 ? function () {} : _ref$onJoin;
  var classes = (0, _styles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var navigation = (0, _useNavigationExtended.default)();
  var hasUserAccessToTable = (0, _reactRedux.useSelector)((0, _selectors.hasUserFeatureAccessSelector)(_featureNamesConstants.JOIN_RESTRICTED_NETWORKING_TABLE_BY_TABLE, id));
  var shouldDisableJoin = isRestricted && !hasUserAccessToTable && !requestToJoin;
  var shouldRequestToJoin = isRestricted && !hasUserAccessToTable && requestToJoin;
  var participantsCount = Object.keys(participants || {}).length;
  var isFull = participantsCount >= maxParticipants;
  var orderedParticipants = participants ? Object.values(participants).sort(function (a, b) {
    return new Date(a.joinedAt) - new Date(b.joinedAt);
  }) : [];
  var participantsTop = [];
  var participantsBottom = [];
  var participantsLeft = [];
  var participantsRight = [];

  if (maxParticipants === 2) {
    participantsLeft = [0];
    participantsRight = [1];
  } else {
    var fullCircle = Math.floor(maxParticipants / 4);
    var plus = maxParticipants % 4;
    var leftNumber = plus > 0 ? fullCircle + 1 : fullCircle;
    var topNumber = plus > 1 ? fullCircle + 1 : fullCircle;
    var rightNumber = plus > 2 ? fullCircle + 1 : fullCircle;

    for (var i = 0; i < maxParticipants; i++) {
      if (i < leftNumber) {
        participantsLeft.unshift(i);
        continue;
      }

      if (i < leftNumber + topNumber) {
        participantsTop.push(i);
        continue;
      }

      if (i < leftNumber + topNumber + rightNumber) {
        participantsRight.unshift(i);
        continue;
      }

      if (i < maxParticipants) {
        participantsBottom.unshift(i);
        continue;
      }
    }
  }

  var placeholder = function placeholder() {
    return _react.default.createElement(_core.Box, {
      key: Math.random(10000),
      className: classes.placeholder,
      p: 3
    });
  };

  var renderParticipants = function renderParticipants(side) {
    var participantsArray = [];

    switch (side) {
      case 'top':
        participantsArray = [].concat(participantsTop);
        break;

      case 'left':
        participantsArray = (0, _toConsumableArray2.default)(participantsLeft);
        break;

      case 'right':
        participantsArray = (0, _toConsumableArray2.default)(participantsRight);
        break;

      case 'bottom':
        participantsArray = [].concat(participantsBottom);
        break;
    }

    return participantsArray.map(function (p) {
      return orderedParticipants[p] ? _react.default.createElement(_speakerView.default, {
        participant: orderedParticipants[p],
        key: p
      }) : placeholder();
    });
  };

  var disableWatch = participantsCount <= 0 || !othersCanWatch;
  var diableJoin = isFull || loading || disabled || selected || shouldDisableJoin;
  return _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-start",
    p: 3,
    className: classes.container
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_core.Box, {
    className: isFull ? classes.outerContainerFull : classes.outerContainer,
    pt: 8,
    px: 8,
    pb: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center"
  }, _react.default.createElement(_core.Box, {
    className: classes.image
  }, _react.default.createElement(_reactNative.ImageBackground, {
    source: logoUrl || ' ',
    resizeMode: "contain"
  }, _react.default.createElement(_core.Box, {
    className: classes.leftColumn,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center"
  }, renderParticipants('left')), _react.default.createElement(_core.Box, {
    className: classes.centerColumn,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    justifyContent: "space-around",
    minWidth: "100%"
  }, renderParticipants('top')), _react.default.createElement(_core.Box, {
    display: "flex",
    justifyContent: "space-around",
    minWidth: "100%"
  }), _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    minWidth: "100%"
  }, renderParticipants('bottom'))), _react.default.createElement(_core.Box, {
    className: classes.rightColumn,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center"
  }, renderParticipants('right')))), _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    className: classes.titleContainer
  }, _react.default.createElement(_core.Typography, {
    variant: "h4",
    className: classes.title
  }, name), _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, isFull ? _i18n.default.translate('networking.tableIsFull') : shouldDisableJoin ? _i18n.default.translate('networking.accessRestricted') : _i18n.default.translate('networking.takeASeat')))), _react.default.createElement(_core.Box, {
    m: 0.5,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  }, _react.default.createElement(_core.Box, {
    m: 0.5
  }, _react.default.createElement(_ContainedButton.default, {
    disabled: disableWatch,
    onPress: function onPress() {
      navigation.navigate(_routesConsts.Routes.NETWORKING_TABLE_LIVE_PAGE, {
        tableId: id
      });
    }
  }, _i18n.default.translate('networking.viewTable'))), _react.default.createElement(_core.Box, {
    m: 0.5
  }, _react.default.createElement(_ContainedButton.default, {
    iconLeft: loading && _react.default.createElement(_reactNative.ActivityIndicator, {
      size: "small",
      color: colors.buttonText
    }) || null,
    disabled: diableJoin,
    onPress: function onPress() {
      return onJoin(id);
    }
  }, _i18n.default.translate(isFull ? 'networking.tableIsFull' : shouldRequestToJoin ? 'networking.requestToJoin' : 'networking.joinTable'))))));
}