Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SLIDE_WIDTH = void 0;

var _DesignSystem = require("../../theme/DesignSystem");

var SLIDE_WIDTH = 300;
exports.SLIDE_WIDTH = SLIDE_WIDTH;
var useTestimonialsStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  slide: {
    width: SLIDE_WIDTH + 48,
    minWidth: SLIDE_WIDTH + 48,
    maxWidth: SLIDE_WIDTH + 48
  },
  iconButton: {
    position: 'absolute'
  },
  left: {
    left: 0,
    zIndex: 1
  },
  right: {
    right: 0,
    zIndex: 1
  }
}).addThemeBasedStyle(function (theme) {
  return {
    slide: {
      paddingHorizontal: theme.spacing.m
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, function (theme) {
  return {
    slide: {
      paddingHorizontal: theme.spacing.m,
      width: SLIDE_WIDTH,
      minWidth: SLIDE_WIDTH,
      maxWidth: SLIDE_WIDTH
    }
  };
}).buildHook();
var _default = useTestimonialsStyles;
exports.default = _default;