module.exports = {
  USERS: 'users',
  EVENTS: 'events',
  VIDEOS: 'videos',
  ATTENDEES: 'attendees',
  ATTENDEES_STATS: 'attendeesStats',
  SESSIONS: 'sessions',
  STAGES: 'stages',
  EVENT_STARTING_EMAIL_LOGS: 'eventStartingEmailLogs',
  PRE_EVENT_REMINDER_EMAIL_LOGS: 'pre-event-reminder-email-logs',
  CUSTOM_FIELDS: 'custom-registration-fields',
  TICKETS: 'tickets',
  PROCESSING_FREE_TICKETS: 'processingFreeTickets',
  TEAM_MEMBERS: 'teams',
  ROLES: 'roles',
  USER_ROLES: 'userRoles',
  BACKSTAGES: 'backstages',
  SPEAKERS: 'speakers',
  NETWORKING: 'networking-tables',
  CLEANUPS: 'cleanups',
  BILLING: 'billing-informations',
  BILLING_INFO: 'billingInformation',
  ROLES_BY_RESOURCE: 'rolesByResource',
  APPS: 'apps',
  CONFIG: 'config',
  CREDITS: 'credits',
  CREDITS_PACKAGES: 'creditsPackages',
  PACKAGES: 'packages',
  CREDITS_HISTORY: 'creditsHistory',
  HISTORY: 'history',
  UNASSIGNED_TICKETS: 'unassignedTickets',
  TICKET_TRANSACTIONS: 'ticketTransactions'
};