Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    text: {
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(2)
    },
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2)
    },
    networkingText: {
      marginBottom: theme.spacing(2)
    },
    liveNow: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.headerText.main
    },
    upcoming: {
      backgroundColor: theme.palette.badgeUpcoming.main,
      color: theme.palette.headerText.main
    },
    videoSection: {
      flexDirection: 'row',
      margin: -theme.spacing(2),
      '@media(max-width: 1280px)': {
        flexDirection: 'column'
      }
    },
    detailsSection: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '@media(max-width: 1280px)': {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    }
  };
});
var _default = styles;
exports.default = _default;