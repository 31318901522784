Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textWhite: {
      color: theme.palette.headerText.main
    },
    descriptionText: {
      zIndex: -1
    },
    columnContainer: {
      padding: theme.spacing(2),
      '@media(max-width: 1026px)': {
        justifyContent: 'center'
      }
    },
    container: {
      marginLeft: 90,
      '@media(max-width: 1026px)': {
        marginLeft: 0,
        flexDirection: 'column'
      }
    },
    sectionContainer: {
      borderColor: theme.palette.borderColor.main,
      border: '1px solid'
    },
    headerContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      '@media(max-width: 1280px)': {
        flexDirection: 'column'
      }
    }
  };
});
var _default = styles;
exports.default = _default;