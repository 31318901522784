var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _DesignSystem = require("../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useRightSideMenuStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (_ref) {
  var colors = _ref.colors,
      spacing = _ref.spacing;
  return {
    container: {
      height: 'calc(100vh - 110px)',
      position: 'relative',
      borderWidth: 1,
      borderColor: colors.borderColor,
      borderStyle: 'solid',
      borderRightWidth: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    itemContainer: {
      flexGrow: 1,
      minWidth: 90
    },
    content: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: '100%'
    },
    menuItem: {
      paddingVertical: spacing.m
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
  var breakpoints = _ref2.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    container: _objectSpread(_objectSpread({
      height: 'calc(100vh - 85px)',
      flexDirection: 'column',
      alignItems: 'stretch'
    }, theme.shape.cards), {}, {
      borderTopRightRadius: theme.shape.cards.borderRadius,
      borderBottomRightRadius: theme.shape.cards.borderRadius,
      borderWidth: 0,
      marginTop: theme.spacing.s,
      marginLeft: theme.spacing.s,
      marginRight: theme.spacing.s,
      marginBottom: theme.spacing.s,
      maxWidth: theme.spacing.rightSidebar
    }),
    itemContainer: {
      minWidth: 'initial',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },
    content: {},
    collapse: {
      display: 'none'
    },
    menuItem: {
      paddingVertical: 0,
      paddingHorizontal: theme.spacing.s
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref3) {
  var breakpoints = _ref3.breakpoints;
  return breakpoints.bg;
}, function () {
  return {
    container: {
      height: 450,
      maxWidth: 'auto'
    },
    menuItem: {
      width: 90
    }
  };
}).buildHook();
var _default = useRightSideMenuStyles;
exports.default = _default;