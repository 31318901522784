var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MultiSelect;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../DesignSystem");

var StyledSelect = (0, _core.withStyles)({
  root: {
    backgroundColor: 'transparent',
    '& *': {
      backgroundColor: 'transparent !important',
      fontFamily: function fontFamily(_ref) {
        var _fontFamily = _ref.fontFamily;
        return _fontFamily;
      }
    },
    borderRadius: function borderRadius(_ref2) {
      var _borderRadius = _ref2.borderRadius;
      return _borderRadius;
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 2,
      borderColor: function borderColor(_ref3) {
        var defaultColor = _ref3.defaultColor;
        return defaultColor;
      }
    },
    '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
      borderColor: function borderColor(_ref4) {
        var _borderColor = _ref4.borderColor;
        return _borderColor;
      }
    }
  }
})(function (_ref5) {
  var classes = _ref5.classes,
      borderRadius = _ref5.borderRadius,
      borderColor = _ref5.borderColor,
      fontFamily = _ref5.fontFamily,
      defaultColor = _ref5.defaultColor,
      rest = (0, _objectWithoutProperties2.default)(_ref5, ["classes", "borderRadius", "borderColor", "fontFamily", "defaultColor"]);
  return _react.default.createElement(_core.Select, (0, _extends2.default)({
    MenuProps: {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      PaperProps: {
        style: {
          maxHeight: 300
        }
      }
    },
    className: classes.root
  }, rest));
});

function MultiSelect(_ref6) {
  var onChange = _ref6.onChange,
      values = _ref6.value,
      _ref6$options = _ref6.options,
      options = _ref6$options === void 0 ? [] : _ref6$options,
      name = _ref6.name,
      rest = (0, _objectWithoutProperties2.default)(_ref6, ["onChange", "value", "options", "name"]);

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      buttons = _useTheme.shape.buttons,
      fontFamily = _useTheme.typography.fontFamily;

  return _react.default.createElement(StyledSelect, (0, _extends2.default)({}, rest, {
    variant: "outlined",
    borderRadius: buttons.borderRadius,
    borderColor: colors.button,
    defaultColor: colors.pageText,
    fontFamily: fontFamily,
    id: name,
    labelId: name,
    name: name,
    multiple: true,
    renderValue: function renderValue(selected) {
      return selected.join(',');
    },
    value: values,
    onChange: onChange
  }), options.map(function (_ref7) {
    var id = _ref7.id,
        value = _ref7.value;
    return _react.default.createElement(_core.MenuItem, {
      style: {
        fontFamily: fontFamily,
        whiteSpace: 'normal'
      },
      value: value,
      id: id,
      key: id
    }, _react.default.createElement(_core.Checkbox, {
      color: 'default',
      checked: (values === null || values === void 0 ? void 0 : values.indexOf(value)) > -1
    }), _react.default.createElement(_core.ListItemText, {
      primaryTypographyProps: {
        style: {
          fontFamily: fontFamily
        }
      },
      style: {
        fontFamily: fontFamily
      }
    }, value));
  }));
}