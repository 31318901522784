var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var Paragraph = function (_React$PureComponent) {
  (0, _inherits2.default)(Paragraph, _React$PureComponent);

  var _super = _createSuper(Paragraph);

  function Paragraph() {
    var _this;

    (0, _classCallCheck2.default)(this, Paragraph);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this._root = void 0;
    return _this;
  }

  (0, _createClass2.default)(Paragraph, [{
    key: "setNativeProps",
    value: function setNativeProps(nativeProps) {
      if (this._root && _reactNative.Platform.OS === 'web') {
        this._root.setNativeProps(nativeProps);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(_reactNativePaper.Paragraph, (0, _extends2.default)({}, this.props, {
        style: [styles.text, this.props.style]
      }));
    }
  }]);
  return Paragraph;
}(React.PureComponent);

var _default = Paragraph;
exports.default = _default;

var styles = _reactNative.StyleSheet.create({
  text: {
    fontSize: 13,
    lineHeight: 21,
    marginVertical: 2
  }
});