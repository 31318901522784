var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _core = require("@material-ui/core");

var _react = _interopRequireWildcard(require("react"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _Avatar = _interopRequireDefault(require("../../../common/audio-video/Avatar"));

var _MediaStreamVideoPlayer = _interopRequireDefault(require("../../../common/audio-video/MediaStreamVideoPlayer"));

var _stringFormatter = require("../../../../utils/string-formatter");

var _speakerRequestStyles = require("./speaker-request-styles");

var _KickOutButton = _interopRequireDefault(require("../../../common/audio-video/KickOutButton"));

var SpeakerRequest = function SpeakerRequest(_ref) {
  var id = _ref.id,
      name = _ref.name,
      picture = _ref.picture,
      videoTrack = _ref.videoTrack,
      onAdmit = _ref.onAdmit,
      onDeny = _ref.onDeny;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      isLoadingAdmit = _useState2[0],
      setIsLoadingAdmit = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      isLoadingReject = _useState4[0],
      setIsLoadingReject = _useState4[1];

  var styles = (0, _speakerRequestStyles.useSpeakerRequestStyles)();

  var handleAdmit = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              setIsLoadingAdmit(true);
              _context.next = 3;
              return onAdmit(id);

            case 3:
              setIsLoadingAdmit(false);

            case 4:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function handleAdmit() {
      return _ref2.apply(this, arguments);
    };
  }();

  var handleDeny = function () {
    var _ref3 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2() {
      return _regenerator.default.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              setIsLoadingReject(true);
              _context2.next = 3;
              return onDeny(id);

            case 3:
              setIsLoadingReject(false);

            case 4:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    }));

    return function handleDeny() {
      return _ref3.apply(this, arguments);
    };
  }();

  return _react.default.createElement(_Spacing.default, {
    style: styles.container,
    direction: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    mSpacing: "s",
    pSpacing: "s"
  }, _react.default.createElement(_KickOutButton.default, {
    isLoading: isLoadingReject,
    onClick: function onClick() {
      return handleDeny(id);
    },
    disabled: isLoadingAdmit || isLoadingReject
  }), _react.default.createElement(_MediaStreamVideoPlayer.default, {
    videoTrack: videoTrack,
    isLocal: false,
    key: "video",
    absoluteFill: true
  }, _react.default.createElement(_Spacing.default, {
    style: styles.avatarContainer
  }, _react.default.createElement(_Avatar.default, {
    sizes: "64px",
    src: picture,
    alt: name
  }, (0, _stringFormatter.getInitials)(name)))), _react.default.createElement(_Spacing.default, {
    direction: "row",
    justifyContent: "space-between",
    flex: 1,
    mSpacing: ['s', 0, 0]
  }, _react.default.createElement(_core.Typography, {
    variant: "h4"
  }, name), _react.default.createElement(_ContainedButton.default, {
    disabled: isLoadingAdmit || isLoadingReject,
    iconLeft: isLoadingAdmit ? _react.default.createElement(_core.CircularProgress, {
      size: 20,
      style: {
        color: '#fff'
      }
    }) : undefined,
    onPress: function onPress() {
      return handleAdmit(id);
    }
  }, _i18n.default.translate('requests.admit'))));
};

var _default = SpeakerRequest;
exports.default = _default;