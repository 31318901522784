var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeRoomAccessTokens = exports.getRoomAccessToken = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _selectors = require("../user/selectors");

var _constants = require("./constants");

var userTokenService = _interopRequireWildcard(require("../services/userTokenService"));

var getRoomAccessToken = (0, _toolkit.createAsyncThunk)(_constants.GET_ROOM_ACCESS_TOKENS, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(room, _ref) {
    var getState, authToken, data;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;

            if (room) {
              _context.next = 3;
              break;
            }

            throw Error('No specified room!');

          case 3:
            authToken = (0, _selectors.getFirebaseAccessToken)(getState());

            if (authToken) {
              _context.next = 6;
              break;
            }

            throw Error('Unauthenticated!');

          case 6:
            _context.next = 8;
            return userTokenService.getRoomAccessToken({
              room: room,
              authToken: authToken
            });

          case 8:
            data = _context.sent;
            return _context.abrupt("return", {
              data: data,
              room: room
            });

          case 10:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getRoomAccessToken = getRoomAccessToken;
var removeRoomAccessTokens = (0, _toolkit.createAction)(_constants.REMOVE_ROOM_ACCESS_TOKENS);
exports.removeRoomAccessTokens = removeRoomAccessTokens;