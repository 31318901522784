Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textUppercase: {
      textTransform: 'uppercase'
    },
    dividerContainer: {
      borderTopColor: theme.palette.borderColor.main,
      borderTopWidth: '1px',
      borderTopStyle: 'solid'
    },
    checkoutSpacing: {
      marginRight: 440 + theme.spacing(8),
      '@media(max-width: 1152px)': {
        marginRight: 0
      },
      '@media(max-width: 425px)': {
        marginRight: 0
      }
    },
    container: {
      '@media(max-width: 600px)': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
      }
    }
  };
});
var _default = styles;
exports.default = _default;