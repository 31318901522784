var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactLottie = _interopRequireDefault(require("react-lottie"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function () { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

var LottieView = function (_Component) {
  (0, _inherits2.default)(LottieView, _Component);

  var _super = _createSuper(LottieView);

  function LottieView() {
    (0, _classCallCheck2.default)(this, LottieView);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(LottieView, [{
    key: "setNativeProps",
    value: function setNativeProps(props) {
      this.gradientRef.setNativeProps(props);
    }
  }, {
    key: "render",
    value: function render() {
      var defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: this.props.source
      };
      return _react.default.createElement(_reactLottie.default, {
        options: defaultOptions,
        style: this.props.style
      });
    }
  }]);
  return LottieView;
}(_react.Component);

exports.default = LottieView;
module.exports = LottieView;